import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { HeaderSubNavContentTilesCollection, HeaderSubNavHeaderSubNavGroupsCollection } from '@/types/generated';

import { headerSubNavMenuContainerStyle, headerSubNavMenuGridContainerStyle } from './NavigationMenuItem.styles';
import NavigationSubMenuContent from './NavigationSubMenuContent';

export interface INavigationSubMenu {
	dataTestId?: string;
	subNavMenuGroups?: HeaderSubNavHeaderSubNavGroupsCollection;
	contentTilesCollection?: HeaderSubNavContentTilesCollection | null;
	subNavMenuAbsolutePosition?: string;
}

export default function NavigationSubMenu({
	subNavMenuGroups,
	contentTilesCollection,
	subNavMenuAbsolutePosition = 'left',
}: INavigationSubMenu) {
	const theme = useTheme();
	const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'));
	const dataTestId = isLargeDevice
		? `header-sub-nav-menu-content-${subNavMenuAbsolutePosition}`
		: 'header-sub-nav-menu-content';

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={headerSubNavMenuContainerStyle(theme, subNavMenuAbsolutePosition)}
		>
			<Grid
				container
				sx={headerSubNavMenuGridContainerStyle}
				data-test-id="header-sub-nav-menu-container-grid"
				justifyContent="flex-start"
				alignItems="flex-start"
			>
				{/* nav links */}
				<NavigationSubMenuContent
					dataTestId={dataTestId}
					subNavMenuGroups={subNavMenuGroups}
					contentTilesCollection={contentTilesCollection}
				/>
			</Grid>
		</Container>
	);
}
