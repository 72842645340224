import { createContext } from 'react';

import { useSafeContext } from '@aspendental/shared-utils/react';

import useFacilities from '@/services/useFacilities';
import { Maybe, TAGBrand } from '@/types';
import { FacilityBrand } from '@/types/generated';
import { IUser } from '@/constants';

import { useAppContext } from './AppContextProvider.ctx';

const FacilityContext = createContext<ReturnType<typeof useFacilities>>({
	officeInfo: null,
	facilityCode: undefined,
	facilityBrand: FacilityBrand.AspenDental,
	locationState: {
		nearByOfficeLocations: null,
		closestOfficeInfo: null,
		distance: undefined,
		schedulingSystem: null,
		user: {
			coordinates: {
				latitude: 0,
				longitude: 0,
			},
			hasIpBasedCoordinates: false,
		},
	},
});
FacilityContext.displayName = 'FacilityContext';
interface IFacilityContextProvider {
	/**
	 * appName
	 * used for API headers in order to identify the application name
	 *
	 * @type {string}
	 * @memberof IFacilityContextProvider
	 * @deprecated use `AppContextProvider` instead
	 */
	appName?: string;
	/**
	 * appVersion
	 * used for API headers in order to identify the application version
	 *
	 * @type {string}
	 * @memberof IFacilityContextProvider
	 * @deprecated use `AppContextProvider` instead
	 */
	appVersion?: string;
	brand: TAGBrand;
	user?: Maybe<IUser>;
	onPageScheduling?: boolean;
	facilityCode?: string;
	children: React.ReactNode;
	defaultToCdn?: boolean;
}

export function FacilityContextProvider({
	brand,
	user,
	onPageScheduling,
	facilityCode,
	children,
	defaultToCdn = false,
}: IFacilityContextProvider) {
	const appConfig = useAppContext();
	const { config, environment, appName, appVersion } = appConfig;

	const enterpriseGraphqlUrl =
		environment === 'prod'
			? config.services.prod.ENTERPRISE_API_GRAPHQL
			: config.services.nonprod.ENTERPRISE_API_GRAPHQL;
	const bffGraphqlUrl =
		environment === 'prod' ? config.services.prod.BEFFE_GRAPHQL_URL : config.services.nonprod.BEFFE_GRAPHQL_URL;
	const facilities = useFacilities(
		appName,
		appVersion,
		brand,
		enterpriseGraphqlUrl,
		bffGraphqlUrl,
		config.featureFlags.useFacilityBeffeDataSource,
		user,
		onPageScheduling,
		facilityCode,
		defaultToCdn,
		config.featureFlags.transitionallyUseBeffe
	);

	return <FacilityContext.Provider value={facilities}>{children}</FacilityContext.Provider>;
}

export function useFacilityContext() {
	return useSafeContext(FacilityContext);
}
