import { Box, Typography } from '@mui/material';

import FocalPointImage, { BrandFolderImage, FocalPointData } from '@/components/FocalPointImage';
import TAGLink from '@/components/TAGLink';
import { Maybe, Page } from '@/types/generated';

import { tileContentBoxStyles, tileLinkWrapperStyles } from './ContentTile.styles';

export interface ITileImage {
	image: BrandFolderImage;
	focalPoint: FocalPointData;
}

export interface IContentTile {
	image: ITileImage;
	title?: string | null;
	description?: string | null;
	internalPage?: Maybe<Page>;
	externalUrl?: Maybe<string>;
}

export default function ContentTile({ image, title, description, internalPage, externalUrl }: IContentTile) {
	const isInternal = internalPage && internalPage?.path;
	const href = isInternal ? internalPage?.path : externalUrl;
	const linkTitle = title || '';

	return (
		<TAGLink title={linkTitle} href={href || '#'} openInNewTab={!isInternal} linkSx={tileLinkWrapperStyles}>
			<FocalPointImage
				brandFolderImage={image?.image}
				focalPoint={image?.focalPoint}
				dataTestId={`header_sub_nav_menu_content_tile_image_${linkTitle}`}
			/>
			<Box sx={tileContentBoxStyles}>
				{title && (
					<Typography
						variant="bodyMediumBold"
						color="text.light"
						data-test-id={`header_sub_nav_menu_content_tile_title_${linkTitle}`}
					>
						{title}
					</Typography>
				)}
				{description && (
					<Typography
						variant="bodySmallBook"
						color="text.light"
						data-test-id={`header_sub_nav_menu_content_tile_desc_${linkTitle}`}
					>
						{description}
					</Typography>
				)}
			</Box>
		</TAGLink>
	);
}
