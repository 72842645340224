import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	IconButton,
	SxProps,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { dialogActionsStyles, dialogContentStyles, dialogWrapperStyles, iconStyles } from './TAGDialog.styles';

export interface ITAGDialog extends DialogProps {
	/**
	 * Whether the dialog is open or not.
	 *
	 * @type {boolean}
	 * @memberof ITAGDialog
	 */
	open: boolean;
	darkScrim?: boolean;
	/**
	 * Callback to be called when the dialog is closed.
	 *
	 * @memberof ITAGDialog
	 */
	handleClose: () => void;
	/**
	 * Whether the close icon should be shown or not.
	 *
	 * @type {boolean}
	 * @memberof ITAGDialog
	 */
	showCloseIcon?: boolean;
	/**
	 * Optional styles to be applied to the dialog wrapper.
	 *
	 * @type {SxProps}
	 * @memberof ITAGDialog
	 */
	dialogSx?: SxProps;
	/**
	 * Optional dialog title. This is a simple string.
	 *
	 * @type {string}
	 * @memberof ITAGDialog
	 */
	title?: string;
	/**
	 * Optional render function to render the dialog title.
	 *
	 * @memberof ITAGDialog
	 */
	renderTitle?: () => React.ReactNode;
	/**
	 * Optional render function to render the simple text as a content of the dialog.
	 *
	 * @memberof ITAGDialog
	 */
	renderTextContent?: () => React.ReactNode;
	/**
	 * Optional render function to render more complex dialog content, such as rich text.
	 *
	 * @memberof ITAGDialog
	 */
	renderContent?: () => React.ReactNode;
	/**
	 * Optional render function to render the dialog actions.
	 *
	 * @memberof ITAGDialog
	 */
	renderActions?: () => React.ReactNode;
}

export function CloseButton({ onClose }: { onClose: () => void }) {
	return (
		<IconButton aria-label="close" onClick={onClose} sx={iconStyles} data-test-id="button_close_dialog">
			<CloseIcon />
		</IconButton>
	);
}

function TAGDialogTitle({ title, renderTitle }: Pick<ITAGDialog, 'title' | 'renderTitle'>) {
	if (title) {
		return (
			<DialogTitle data-test-id="text_dialog_title">
				<Typography variant="header2">{title}</Typography>
			</DialogTitle>
		);
	}

	if (renderTitle) {
		return <DialogTitle data-test-id="text_dialog_title">{renderTitle()}</DialogTitle>;
	}

	return null;
}

function TAGDialogTextContent({ renderTextContent }: Pick<ITAGDialog, 'renderTextContent'>) {
	if (!renderTextContent) return null;

	return (
		<DialogContent sx={dialogContentStyles} data-test-id="text_dialog_text_content">
			<DialogContentText>{renderTextContent()}</DialogContentText>
		</DialogContent>
	);
}

function TAGDialogActions({ renderActions }: Pick<ITAGDialog, 'renderActions'>) {
	if (!renderActions) return null;

	return (
		<DialogActions sx={dialogActionsStyles} data-test-id="group_dialog_actions">
			{renderActions()}
		</DialogActions>
	);
}

export default function TAGDialog({
	handleClose,
	open = false,
	showCloseIcon = false,
	disableScrollLock = false,
	dialogSx,
	title,
	renderTitle,
	renderTextContent,
	renderActions,
}: ITAGDialog) {
	const dialogStyles = { ...dialogWrapperStyles, ...dialogSx };

	return (
		<Dialog
			open={open}
			disableScrollLock={disableScrollLock}
			scroll="paper"
			onClose={handleClose}
			sx={dialogStyles}
			data-test-id="section_tag_dialog"
		>
			{showCloseIcon && <CloseButton onClose={handleClose} />}

			<TAGDialogTitle title={title} renderTitle={renderTitle} />

			<TAGDialogTextContent renderTextContent={renderTextContent} />

			<TAGDialogActions renderActions={renderActions} />
		</Dialog>
	);
}
