import { Box, SxProps } from '@mui/material';

import { Header } from '@/types/generated';
import LanguageToggle from '@/components/LanguageToggle';

import NavigationBarItem from './NavigationBarItem';

interface INavigationBar {
	testIdSuffix?: string;
	navigationItems: Header['navigationLinksCollection'] | Header['topNavigationLinksCollection'];
	isSmallScreen?: boolean;
	onClose?: () => void;
	navigationSx?: SxProps;
	isUtilityNavigation?: boolean;
	isDarkHeader?: boolean;
	showLanguageToggle?: boolean;
}

export default function NavigationBar({
	testIdSuffix,
	navigationItems,
	isSmallScreen = false,
	onClose,
	navigationSx,
	isUtilityNavigation,
	isDarkHeader = false,
	showLanguageToggle = false,
}: INavigationBar) {
	return (
		<Box data-test-id={`section_${testIdSuffix?.concat('', '_') || ''}navigation_bar`} sx={navigationSx}>
			{showLanguageToggle && <LanguageToggle isLargeDevice isDarkHeader={isDarkHeader} />}
			{navigationItems?.items?.map((item, index) =>
				item ? (
					<NavigationBarItem
						onClose={onClose}
						isSmallScreen={isSmallScreen}
						key={item.sys.id}
						item={item}
						itemIndex={index}
						isUtilityNavigation={isUtilityNavigation}
						isDarkHeader={isDarkHeader}
					/>
				) : null
			)}
		</Box>
	);
}
