import React, { useState, useEffect } from 'react';

import { Typography, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { HeaderNavigationLinksItem, Page } from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { incorrectUsageWarning } from '@/utils/miscUtils';
import NavigationLink from '@/components/NavigationLink';

import MobileMenu from '../../HeaderMobile/MobileMenu/MobileMenu';

import {
	getNavItemStyles,
	dropMenuStyles,
	getNavItemTypographyVariant,
	mobileNavGroupStyles,
	navGroupLinkStyles,
} from './NavigationBarItem.styles';
import {
	itemIsNavigationLink,
	itemIsPage,
	itemIsNavigationGroup,
	itemIsFooterNavigationGroup,
} from './NavigationBarItem.helpers';

interface INavigationBarItem {
	item: HeaderNavigationLinksItem;
	isSmallScreen: boolean;
	itemIndex: number;
	onClose?: () => void;
	isUtilityNavigation?: boolean;
	isDarkHeader: boolean;
}

/**
 * @deprecated `Page` link is deprecated. Use NavigationLink instead.
 */
function renderPageLink(
	item: Page,
	isSmallScreen: boolean,
	isUtilityNavigation: boolean,
	itemIndex: number,
	onClose: () => void,
	isDarkHeader: boolean
) {
	return (
		<TAGLink
			href={item.path as string}
			title={item.title as string}
			dataTestId={`link_page_nav_item_${item.sys.id}`}
			onClick={onClose}
		>
			<Typography
				variant={getNavItemTypographyVariant(isSmallScreen, isUtilityNavigation)}
				color={isDarkHeader ? 'text.light' : 'text.interactive'}
				sx={getNavItemStyles({ isSmallScreen, isUtilityNavigation, isDarkHeader })}
				borderTop={!isUtilityNavigation && isSmallScreen && itemIndex === 0 ? '1px solid' : 'none'}
			>
				{item.breadcrumbTitle || item.title}
			</Typography>
		</TAGLink>
	);
}

export default function NavigationBarItem({
	item,
	isSmallScreen,
	itemIndex,
	onClose,
	isUtilityNavigation,
	isDarkHeader,
}: INavigationBarItem) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setAnchorEl(null);
	};

	useEffect(() => {
		const handleScroll = () => {
			setAnchorEl(null);
		};
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (itemIsNavigationLink(item)) {
		return (
			<NavigationLink
				{...item}
				isInternal={item.isInternal || false}
				itemIndex={itemIndex}
				dataTestId={`link_navlink_nav_item_${item.sys.id}`}
				isUtilityNavigation={isUtilityNavigation}
				eventType="navigation"
				navigationType={isUtilityNavigation ? 'Utility bar' : 'Main Navigation'}
				isTextColorLight={isDarkHeader}
			/>
		);
	}

	if (itemIsNavigationGroup(item)) {
		return (
			<>
				{item.groupLink?.linkText && (
					<TAGLink
						dataTestId={`link_page_nav_group_item_${item.sys.id}`}
						onClick={handleClick}
						aria-controls={`header_navigation_group_menu_${itemIndex}`}
						aria-haspopup="true"
						analyticsContext={item.groupLink?.analyticsContext}
					>
						<Typography
							variant={getNavItemTypographyVariant(isSmallScreen, isUtilityNavigation)}
							color={isDarkHeader ? 'text.light' : 'text.interactive'}
							sx={[
								navGroupLinkStyles.groupLinkTitleStyles,
								getNavItemStyles({ isSmallScreen, isDarkHeader }),
							]}
						>
							{item.groupLink?.linkText}
							{isSmallScreen ? (
								<KeyboardArrowRightIcon sx={navGroupLinkStyles.groupLinkIconMobileStyles} />
							) : (
								<KeyboardArrowDownIcon sx={navGroupLinkStyles.groupLinkIconStyles} />
							)}
						</Typography>
					</TAGLink>
				)}

				{!isSmallScreen ? (
					<Menu
						id={`header_navigation_group_menu_${itemIndex}`}
						disableScrollLock={true}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						sx={dropMenuStyles}
						transitionDuration={1}
					>
						{item.linksCollection?.items?.map((item, index) =>
							item ? (
								<MenuItem key={item.sys.id}>
									<NavigationLink
										{...item}
										isInternal={item.isInternal || false}
										itemIndex={index}
										dataTestId={`link_navlink_nav_item_${item.sys.id}`}
										isUtilityNavigation={isUtilityNavigation}
										disableUnderline
									/>
								</MenuItem>
							) : null
						)}
					</Menu>
				) : (
					<MobileMenu
						open={open}
						onClose={handleClose}
						isFullScreenMenu
						testIdSuffix={`header_navigation_group_menu_${itemIndex}`}
					>
						<TAGLink
							dataTestId="secondary_menu_main_menu_link"
							onClick={handleClose}
							linkSx={mobileNavGroupStyles.breadCrumbLinkStyles}
						>
							<KeyboardArrowLeftIcon fontSize="small" sx={{ pr: '0.25rem' }} />
							<Typography color="inherit" variant="lowestLevelBook">
								Main Menu
							</Typography>
						</TAGLink>
						<Typography variant="header2" sx={mobileNavGroupStyles.navGroupHeaderTitleStyles}>
							{item.groupLink?.linkText}
						</Typography>
						{item.linksCollection?.items?.map((item, index) =>
							item ? (
								<MenuItem key={item.sys.id} sx={mobileNavGroupStyles.menuItemStyles}>
									<NavigationLink
										{...item}
										isInternal={item.isInternal || false}
										itemIndex={index}
										dataTestId={`link_navlink_nav_item_${item.sys.id}`}
										isUtilityNavigation={isUtilityNavigation}
										disableUnderline
										compactMobileMenuStyle
									/>
								</MenuItem>
							) : null
						)}
					</MobileMenu>
				)}
			</>
		);
	}

	if (itemIsPage(item)) {
		return renderPageLink(
			item,
			isSmallScreen,
			isUtilityNavigation || false,
			itemIndex,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onClose || (() => {}),
			isDarkHeader
		);
	}

	if (itemIsFooterNavigationGroup(item)) {
		return (
			<>
				{item.navigationGroupLink?.title && (
					<TAGLink
						dataTestId={`link_page_nav_group_item_${item.sys.id}`}
						onClick={handleClick}
						aria-controls={`header_navigation_group_menu_${itemIndex}`}
						aria-haspopup="true"
						linkSx={navGroupLinkStyles.groupLinkStyles}
						analyticsContext={item.navigationGroupLink?.analyticsContext}
					>
						<Typography
							variant={getNavItemTypographyVariant(isSmallScreen, isUtilityNavigation)}
							color={isDarkHeader ? 'text.light' : 'inherit'}
							sx={[
								navGroupLinkStyles.groupLinkTitleStyles,
								getNavItemStyles({ isSmallScreen, isDarkHeader }),
							]}
						>
							{item.navigationGroupLink?.title}
							{isSmallScreen ? (
								<KeyboardArrowRightIcon sx={navGroupLinkStyles.groupLinkIconMobileStyles} />
							) : (
								<KeyboardArrowDownIcon sx={navGroupLinkStyles.groupLinkIconStyles} />
							)}
						</Typography>
					</TAGLink>
				)}

				{!isSmallScreen ? (
					<Menu
						id={`header_navigation_group_menu_${itemIndex}`}
						disableScrollLock={true}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						sx={dropMenuStyles}
						transitionDuration={1}
					>
						{item.navigationLinksForGroupCollection?.items?.map((navItem, _) =>
							navItem ? (
								<MenuItem key={navItem.sys.id}>
									<TAGLink noLinkStyle href={`${(navItem as Page).path as string}`}>
										<Typography variant="bodySmallBook" color="text.interactive">
											{(navItem as Page).breadcrumbTitle || (navItem as Page).title}
										</Typography>
									</TAGLink>
								</MenuItem>
							) : null
						)}
					</Menu>
				) : (
					<MobileMenu
						open={open}
						onClose={handleClose}
						isFullScreenMenu
						testIdSuffix={`header_navigation_group_menu_${itemIndex}`}
					>
						<TAGLink
							dataTestId="secondary_menu_main_menu_link"
							onClick={handleClose}
							linkSx={mobileNavGroupStyles.breadCrumbLinkStyles}
						>
							<KeyboardArrowLeftIcon fontSize="small" sx={{ pr: '0.25rem' }} />
							<Typography color="inherit" variant="lowestLevelBook">
								Main Menu
							</Typography>
						</TAGLink>
						<Typography variant="header2" sx={mobileNavGroupStyles.navGroupHeaderTitleStyles}>
							{item.navigationGroupLink?.title}
						</Typography>
						{item.navigationLinksForGroupCollection?.items?.map((navItem, _) =>
							navItem ? (
								<MenuItem key={navItem.sys.id} sx={mobileNavGroupStyles.menuItemStyles}>
									<TAGLink
										noLinkStyle
										href={`${(navItem as Page).path as string}`}
										analyticsContext={navItem.analyticsContext}
									>
										<Typography variant="bodyLargeSemiBold" color="text.interactive">
											{(navItem as Page).breadcrumbTitle || (navItem as Page).title}
										</Typography>
									</TAGLink>
								</MenuItem>
							) : null
						)}
					</MobileMenu>
				)}
			</>
		);
	}

	incorrectUsageWarning(
		'NavigationBarItem',
		`Unsupported Content Type for HeaderNavigationLinksItem type passed to the NavigationBar. NavigationBarItem must be of type NavigationLink or NavigationGroup. (Page or FooterNavigationGroup support would be removed once all content are using NavigationLink and NavigationGroup).`
	);

	return null;
}
