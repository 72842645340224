// @ts-nocheck
import { getSessionId } from '@aspendental/shared-utils/utils';

import { Maybe, IglobalThis } from '@/types';

import { objectKeyToSnake } from './camelCaseToSnake';

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace globalThis {
		let dataLayer: Array<Record<string, unknown>>;
		let dispatchDataLayer: (config: IDataLayerEventConfig) => void;
	}
}

globalThis.dispatchDataLayer = (config: IDataLayerEventConfig) => {
	dataLayerConfig({
		...config,
	});
};

export interface IDataLayerEventConfig {
	[key: string]: Maybe<string | boolean | number | undefined | Record<string, unknown>>;
}

export const dataLayerConfig = (eventConfig: IDataLayerEventConfig = { event: '' }) => {
	// transform object keys that come from contentful to snake case because InfoTrust
	// newConfig should be the same as eventConfig, just snake_case
	const newConfig = objectKeyToSnake(eventConfig);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	globalThis.dataLayer = (globalThis as IglobalThis).dataLayer || [];

	const defaultConfig = {
		tag_session_id: getSessionId(),
		platform: 'NextJS',
	};

	// Removing contentful_metadata and sys are temporary
	// those keys are only present due to how the manual events are needed to be used
	// will be deprecated during a Contentful clean up
	Object.keys(newConfig).forEach(
		(key) =>
			(key === '__typename' ||
				key === 'contentful_metadata' ||
				key === 'sys' ||
				newConfig[key] === undefined ||
				newConfig[key] === null ||
				newConfig[key] === '') &&
			delete newConfig[key]
	);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
	globalThis.dataLayer.push({
		...defaultConfig,
		...newConfig,
	});
};
