import { z } from 'zod';

import getResultsFromDentrinoAI from '@/services/getResultsFromDentrinoAI';
import { logError } from '@/utils/miscUtils';
import { dataLayerConfig } from '@/utils';

const CryptoJS = () => import('crypto-js');
const Buffer = () => import('buffer').then((mod) => mod.Buffer);

// hardcoded ui content
// need to replace it with values from contentful

// first instructions step content
export const instructionsTitle = 'Smile, snap, submit';
export const instructionsSubTitle = 'Share a selfie to simulate what your new smile could look like.';
export const instructionsSubTitle2 = "Don't worry, we won't save or use your image at any time.";
export const instructionsAndHelperImageSrc =
	'https://cdn.bfldr.com/R1VIIMUY/as/pv5znprmn8nk2mkg9qnrpm/Rectangle_5790?auto=webp&format=png';
export const instructionsStepsTitle = 'Whether you snap a new pic, or upload one, get the best view by:';

// helper content in camera and upload steps with instructions
export const imageHelperTitle = 'For your best results';
export const imageHelperSteps = [
	'Make sure your face is well-lit',
	'Look directly into your camera',
	'Smile fully so your teeth are visible',
];
export const imageHelperExampleTitle = 'Example:';
export const imageHelperImageSrc =
	'https://cdn.bfldr.com/R1VIIMUY/as/pv5znprmn8nk2mkg9qnrpm/Rectangle_5790?auto=webp&format=png';

// image upload help steps
export const imageUploadHelpSteps = [
	"Choose a photo that's well lit",
	"Make sure you're looking directly into your camera and your teeth are visible",
];

// qr code step content
export const qrCodeHelpTitle = 'Use Smile Try-On from your phone';
export const qrCodeHelpSteps = [
	"Open your phone's camera app",
	'Point your camera at the QR code',
	'Tap the notification or banner that appears on your screen',
	'Click "Try it out" once Smile Try-On opens in your phone\'s web browser',
];
export const qrCodeIssueHelpTitle = 'Link not showing?';
export const qrCodeIssueHelpText = 'Tap your phone’s screen while pointing at the code to focus your camera.';
export const qrCodeUrl = 'https://www.aspendental.com/smile-try-on/';

// image crop step content
export const imageCropHelpText = 'Adjust your photo so your face stays centered and level';

// user details step content
export const blurredResultImageText = 'Your smile is almost ready!';
export const legalNoticeText =
	'Virtual smile simulations are not a guarantee of exact dental results or a particular outcome.';
export const legalNoticeText2 =
	'Your results will only be available to you, and will not be saved or used by Aspen Dental.';

// results page content
export const resultsPageTitle = 'Get started on your new smile';
export const resultsSubTitle = 'Ready to make it real? See how we can help.';

export const cameraAccessErrorMessage =
	'We couldn’t detect a camera. Please grant access to your webcam and refresh the page.';
export const apiErrorMessageToDisplay =
	"There's been an issue simulating your new smile. Try again by taking a new selfie, or re-uploading your photo.";

// interfaces for all steps
export interface CroppedImage {
	blob: Blob | null;
	url: string;
	height: number;
	width: number;
}

export interface DentrinoApiResult {
	success: boolean;
	simulation: {
		storage: {
			beforeUrl: string;
			concatUrl: string;
			resultUrl: string;
		};
	};
}

export interface IDentrinoTryOnStateObject {
	formType:
		| 'Instructions'
		| 'ImageFromUpload'
		| 'ImageFromCamera'
		| 'ImageCrop'
		| 'QRCodeScan'
		| 'UserInfo'
		| 'Results'
		| 'Error';
	selectedFileUrl: string;
	croppedImage: CroppedImage;
	resultsFromDentrino: DentrinoApiResult | null;
	showHelperTipsInMobile: boolean;
	isLoading: boolean;
	previousStep: 'ImageFromCamera' | 'ImageFromUpload' | '';
	errorMessage: string;
	cameraAccessError: boolean;
}

// helper functions, values to encrypt and call dentrino api

const dataJson = {
	mixFactor: 0.275,
	predict_lv: true,
	whiten: 0.01,
	brightness: 0.01,
	styleMode: 'mix_manual',
};

export const md5 = async (data: string) => {
	return (await CryptoJS()).MD5(data).toString();
};

export const base64 = async (data: string) => {
	return (await Buffer()).from(data).toString('base64');
};

export const hmacSHA256 = async (message: string, key: string) => {
	const cryptoJS = await CryptoJS();
	const _key = cryptoJS.enc.Utf8.parse(key);
	const _message = cryptoJS.enc.Utf8.parse(message);
	const hmac = cryptoJS.HmacSHA256(_message, _key);
	return hmac.toString(cryptoJS.enc.Hex);
};

export const fileMD5 = async (fileContent: Blob) => {
	const fileContentArrayBuffer = await fileContent.arrayBuffer();
	const cryptoJS = await CryptoJS();
	const wordArray = cryptoJS.lib.WordArray.create(fileContentArrayBuffer);
	const hash = cryptoJS.MD5(wordArray).toString();
	return hash;
};

const generateToken = async (formData: FormData, dentrinoClientId: string, dentrinoClientSecret: string) => {
	const clientId: string = dentrinoClientId || process.env.DENTRINO_CLIENT_ID || '';
	const clientSecret: string = dentrinoClientSecret || process.env.DENTRINO_CLIENT_SECRET || '';

	if (!clientId || !clientSecret) {
		logError('DENTRINO_ERROR_GENERATE_TOKEN: CLIENT_ID_OR_SECRET_MISSING');
		return;
	}

	const imageFileMD5 = await fileMD5(formData.get('imgPhoto') as Blob);

	const CLAIMS_JSON = {
		clientId,
		paramsHashed: {
			imgPhoto: imageFileMD5,
			data: md5(formData.get('data')?.toString() || ''),
		},
	};

	const [ENCODED_CLAIMS, CLAIMS_SIGNATURE] = await Promise.all([
		base64(JSON.stringify(CLAIMS_JSON)),
		hmacSHA256(JSON.stringify(CLAIMS_JSON), clientSecret),
	]);

	return `${ENCODED_CLAIMS}:${CLAIMS_SIGNATURE}`;
};

export const getResultsFromDentrino = async (
	croppedImage: CroppedImage,
	url: string,
	dentrinoClientId: string,
	dentrinoClientSecret: string
) => {
	if (!croppedImage.blob) {
		return;
	}

	const formDataForApi = new FormData();
	formDataForApi.append('imgPhoto', croppedImage.blob);
	formDataForApi.append('data', JSON.stringify(dataJson));

	const authToken = await generateToken(formDataForApi, dentrinoClientId, dentrinoClientSecret);

	if (!authToken) {
		return;
	}

	const response = await getResultsFromDentrinoAI(authToken, formDataForApi, url);
	return response;
};

export type TUserInfo = {
	label: string;
	name: string;
	required: boolean;
	unique_label: string;
	type: 'Text' | 'Checkbox';
	value: null | string;
};

export type TUsersInfo = Array<TUserInfo>;

export const userForm = {
	title: 'Get your results',
	fields: [
		{
			label: 'First name',
			name: 'firstName',
			required: true,
			unique_label: 'first_name',
			type: 'Text',
			value: null,
		},
		{
			label: 'Last name',
			name: 'lastName',
			required: true,
			unique_label: 'last_name',
			type: 'Text',
			value: null,
		},
		{
			label: 'Email',
			name: 'email',
			required: true,
			unique_label: 'email_address',
			type: 'Text',
			value: null,
		},
		{
			label: 'By checking this box, I verify that I am a legal guardian or 18 years of age or older.',
			name: 'legalAgreement',
			required: true,
			unique_label: 'legal_agreement_text',
			type: 'Checkbox',
			value: null,
		},
	] as TUsersInfo,
};

export const schema = z.object({
	firstName: z
		.string({ invalid_type_error: 'Required' })
		.regex(/^[a-z]+$/i, 'First Name is required')
		.max(30),
	lastName: z
		.string({ invalid_type_error: 'Required' })
		.regex(/^[a-z]+$/i, 'Last Name is required')
		.max(30),
	email: z.string().email('Valid Email required'),
	legalAgreement: z.literal(true),
});

export const defaultValues = {
	firstName: '',
	lastName: '',
	email: '',
};

export const dentrinoGA4DataLayerPush = (brand: string, event: string) => {
	dataLayerConfig({
		event: 'formSubmission',
		form_data: {
			brand: brand,
			product_line: 'Tooth Replacement',
			submission_date: new Date().toISOString(),
			form_title: 'Smile Try-On',
			form_description: 'Dentrino Virtual Try-On',
			form_type: 'Interactive Form',
			form_result: 'N/A',
			form_step: event,
		},
	});
};
