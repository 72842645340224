import { useEffect } from 'react';

import { initializeSessionId, STORAGE_SESSION_ID_KEY } from '@aspendental/shared-utils/utils';

import { IglobalThis } from '@/types';

/**
 * To be used to set the application data on a global level. Such as event listeners, analytics and sessionId initialization.
 *
 * @return {*}
 */
export default function Session({ children }: { children: React.ReactNode }) {
	useEffect(() => {
		initializeSessionId({ storageSessionIdKey: STORAGE_SESSION_ID_KEY, uuid: true });

		// invoca run - script will be loaded from GTM when DOM ready
		const customGlobalThis: IglobalThis = globalThis;
		if (customGlobalThis.Invoca) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
			customGlobalThis.Invoca?.PNAPI?.run();
		}
		// re-engage salesforce
		if (customGlobalThis.Evergage) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
			customGlobalThis.Evergage?.reinit();
		}
	}, []);

	return <>{children}</>;
}
