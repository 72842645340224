import { useRouter } from 'next/router';
import { DialogActions, DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useHookForm } from '@aspendental/shared-forms-web/lib/hooks/useHookForm';
import { Form, MaskedPhoneNumberInput, TextInput } from '@aspendental/shared-forms-web';
import { getSessionId } from '@aspendental/shared-utils/utils';
import { FieldValues } from 'react-hook-form';

import TAGButton from '@/components/TAGButton/TAGButton';
import TAGSvgIcon from '@/components/TAGSvgIcon/TAGSvgIcon';
import postQuiz from '@/services/postQuiz';
import { useAppContext } from '@/context';

import { dialogActionsStyles } from '../Quiz.styles';
import {
	APIFormDataAD,
	APIFormDataCC,
	getPartialOrFullPath,
	getResultType,
	IStateObject,
	quizGA4DataLayerPush,
	TOption,
	TQuestion,
	TUser,
	TUsers,
} from '../Quiz.helpers';

import { defaultValues, schema } from './UserDetailsForm.helpers';

export interface IUserDetailsForm {
	state: IStateObject;
	setState: (newState: Partial<IStateObject>) => void;
	submitEndpointUrl: string;
	showInDialogOrDrawer: boolean;
}

interface IUserForm {
	title: string;
	description: string;
	fields: TUsers;
	submitButtonLabel: string;
}

interface IUserFormContentsWrapper {
	userForm: IUserForm;
	isSmallScreen: boolean;
}

interface IUserFormActionButtonsWrapper {
	userForm: IUserForm;
	disableSubmitButton: boolean;
	handleBack: () => void;
}

const UserFormContentsWrapper = ({ userForm, isSmallScreen }: IUserFormContentsWrapper) => {
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" gap={2} marginTop="2rem">
			<Grid item>
				<Typography variant="header2" color="text.primary" textAlign="center">
					{userForm.title}
				</Typography>
			</Grid>

			{userForm.description ? (
				<Grid item textAlign="center">
					<Typography variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}>
						{userForm.description}
					</Typography>
				</Grid>
			) : null}

			<Grid item width="100%">
				<Grid
					container
					direction="column"
					spacing={2}
					justifyContent="center"
					alignItems="center"
					marginTop="2.5rem"
					marginBottom="2.5rem"
				>
					{userForm.fields.map((field: TUser) => {
						return (
							<Grid item width={isSmallScreen ? '100%' : '50%'} key={field.name}>
								{field.type === 'Text' && (
									<TextInput
										name={field.name}
										label={field.label}
										dataTestId={`user_details_form_element_${field.name}`}
										enforceAlphaNumeric={false}
										required={field.required}
									/>
								)}
								{field.type === 'Phone' && (
									<MaskedPhoneNumberInput
										name={field.name}
										label={field.label}
										required={field.required}
									/>
								)}
							</Grid>
						);
					})}
				</Grid>
			</Grid>
		</Grid>
	);
};

const UserFormActionButtonsWrapper = ({ userForm, disableSubmitButton, handleBack }: IUserFormActionButtonsWrapper) => {
	return (
		<Grid container spacing={2} justifyContent="center" alignItems="center">
			<Grid item>
				<TAGButton
					size="M"
					variant="tertiaryDefault"
					onClick={handleBack}
					startIcon={<TAGSvgIcon icon={'ChevronLeftFunc'} size={25} />}
				>
					Back
				</TAGButton>
			</Grid>
			<Grid item>
				<TAGButton size="M" variant="primaryDefault" type="submit" disabled={disableSubmitButton}>
					{userForm.submitButtonLabel}
				</TAGButton>
			</Grid>
		</Grid>
	);
};

export default function UserDetailsForm({
	state,
	setState,
	showInDialogOrDrawer = false,
	submitEndpointUrl,
}: Readonly<IUserDetailsForm>) {
	const { formData } = state;

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const { environment, config } = useAppContext();
	const router = useRouter();

	const form = useHookForm({
		schema: schema(config.name),
		defaultValues: defaultValues(config.name),
		mode: 'onBlur',
	});

	if (!formData) {
		return null;
	}

	const formQuestions = formData.form.form_questions;
	const userForm = formData.form.user_form;
	const disableSubmitButton = !form.formState.isValid || form.formState.isSubmitting || form.formState.isSubmitted;

	const handleSubmit = async (fields: FieldValues) => {
		/* determine results based on answers */
		const isPartialPathOrFullPath = getPartialOrFullPath(formQuestions);
		const resultType = getResultType(formQuestions, isPartialPathOrFullPath, config.name);

		const url =
			environment === 'prod' ? config.services.prod.FORM_EVENTS_URL : config.services.nonprod.FORM_EVENTS_URL;
		const formQuestionsPayload = formQuestions.map((formQuestion: TQuestion) => {
			return {
				question: formQuestion.question,
				answer: Array.isArray(formQuestion.answer)
					? formQuestion.answer?.map((answer) => answer.value).join('|')
					: formQuestion.answer?.value,
			};
		});
		const userFormDetailsData = userForm.fields.map((userField: TUser) => {
			return {
				question: userField.label,
				answer: fields[userField.name] as string,
				unique_label: userField.unique_label,
			};
		});
		await postQuiz(url, {}, {
			form: {
				brand: formData.form.brand,
				product_line: formData.form.product_line,
				form_title: formData.form.form_title,
				form_type: formData.form.form_type,
				form_result: resultType.title,
				session_id: getSessionId() || 'storybook',
				form_channel: formData.form.form_channel,
				form_questions: formQuestionsPayload.concat(userFormDetailsData),
			},
		} as APIFormDataAD);

		quizGA4DataLayerPush(config.name, 'submit', resultType.title, formData);
		void router.push(resultType.resultPage);
	};

	const handleSubmitCC = async (fields: FieldValues) => {
		const isPartialPathOrFullPath = getPartialOrFullPath(formQuestions);
		const resultType = getResultType(formQuestions, isPartialPathOrFullPath, config.name);

		const questionFields = formQuestions.reduce<{ [key: string]: string | string[] | null }>(
			(acc, question: TQuestion) => {
				acc[question.questionId] = Array.isArray(question.answer)
					? question.answer.map((answer: TOption) => answer.id)
					: (question.answer as TOption).id;
				return acc;
			},
			{}
		);

		const contactFields = userForm.fields.reduce<{ [key: string]: string | null }>((acc, field: TUser) => {
			const fieldName = field.name;
			acc[fieldName] = fieldName in fields ? (fields[fieldName] as string | null) : null;
			return acc;
		}, {});

		const payload = {
			subject: 'leads',
			answers: {
				...questionFields,
				...contactFields,
			},
		};

		const headers = {
			'x-api-key': process.env.NEXT_PUBLIC_CC_API_KEY ?? '',
			'x-api-sig': process.env.NEXT_PUBLIC_CC_API_SIG ?? '',
			'Content-Type': 'application/json',
		};

		await postQuiz(submitEndpointUrl, headers, payload as APIFormDataCC);
		quizGA4DataLayerPush(config.name, 'submit', resultType.title, formData);
		void router.push(resultType.resultPage);
	};

	const handleBack = () => {
		setState({
			formType: 'Questions',
		});
	};

	return (
		<Form
			form={form}
			onSubmit={config.name === 'ClearChoice' ? handleSubmitCC : handleSubmit}
			data-test-id="user_details_form"
		>
			{showInDialogOrDrawer ? (
				<>
					<DialogContent>
						<UserFormContentsWrapper userForm={userForm} isSmallScreen={isSmallScreen} />
					</DialogContent>
					<DialogActions sx={dialogActionsStyles}>
						<UserFormActionButtonsWrapper
							userForm={userForm}
							disableSubmitButton={disableSubmitButton}
							handleBack={handleBack}
						/>
					</DialogActions>
				</>
			) : (
				<>
					<UserFormContentsWrapper userForm={userForm} isSmallScreen={isSmallScreen} />
					<UserFormActionButtonsWrapper
						userForm={userForm}
						disableSubmitButton={disableSubmitButton}
						handleBack={handleBack}
					/>
				</>
			)}
		</Form>
	);
}
