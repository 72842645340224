import { Theme } from '@mui/material';

export const officeNameStyles = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	'> span': {
		display: 'flex',
	},
	'&:hover': { opacity: 0.8 },
};

export const getPopperArrowStyles = (theme: Theme) => ({
	position: 'absolute',
	fontSize: 7,
	width: '3em',
	height: '1em',
	top: 0,
	left: 0,
	marginTop: '-2.9em',
	zIndex: 1000,
	'&::before': {
		content: '""',
		margin: 'auto',
		display: 'block',
		width: 0,
		height: 0,
		borderStyle: 'solid',
		borderWidth: '0 calc(3em + 1px) calc(3em + 1px) calc(3em + 1px)',
		borderColor: `transparent transparent ${theme.palette.neutrals?.disabled} transparent`,
		position: 'relative',
		top: '-1px',
	},
	'&::after': {
		content: '""',
		margin: 'auto',
		display: 'block',
		width: 0,
		height: 0,
		borderStyle: 'solid',
		borderWidth: '0 3em 3em 3em',
		borderColor: `transparent transparent ${theme.palette.backgrounds?.white} transparent`,
		position: 'relative',
		top: 'calc(-3em - 1px)',
		left: '1px',
	},
});

export const locationMenuWrapperStyles = {
	borderRadius: '0.5rem',
	padding: '0.625rem 1.875rem',
	backgroundColor: 'backgrounds.white',
	border: '1px solid',
	borderColor: 'neutrals.disabled',
	minWidth: '37.5rem',
	boxShadow: '0 15px 12px rgb(0 0 0 / 22%), 0 19px 38px rgb(0 0 0 / 30%)',
	position: 'relative',
	left: '1rem',
	zIndex: 999, // needed for popperArrow triangle
};

export const locationContainerStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '1rem',
	padding: '1rem 0.5rem',
	paddingBottom: '0',
};

export const locationInfoContainerStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	gap: '1rem',
};

export const closeLocationInfoIconStyles = {
	padding: 0,
	position: 'absolute',
	right: '0.5rem',
	top: '0.5rem',
};

export const getODPLinksWrapperStyles = (isSmallScreen: boolean) => ({
	display: 'flex',
	gap: isSmallScreen ? 0 : '1.25rem',
	width: '100%',
	paddingTop: isSmallScreen ? 0 : '1rem',
	flexDirection: isSmallScreen ? 'column' : 'row',
	justifyContent: isSmallScreen ? 'flex-start' : 'space-around',
	...(isSmallScreen && { borderTop: '1px solid', borderColor: 'neutrals.primary' }),
});

export const getLocationLinksWrapperStyles = (isSmallScreen: boolean) => ({
	display: 'flex',
	gap: isSmallScreen ? 0 : '1.25rem',
	width: '100%',
	paddingTop: isSmallScreen ? 0 : '1rem',
	flexDirection: isSmallScreen ? 'column' : 'row',
	justifyContent: 'flex-start',
	...(isSmallScreen && { borderTop: '1px solid', borderColor: 'neutrals.primary' }),
});

export const findOfficeButtonStyles = {
	width: '100%',
	maxWidth: { md: '16rem' },
};

const ODPLinkStyles = {
	textDecoration: 'none',
	'> span': {
		display: 'flex',
		padding: '17px 20px 18px 0',
	},
	'&:hover': { opacity: 0.9 },
};

const ODPLinkStylesMobile = {
	fontSize: '1rem',
	borderBottom: '1px solid',
	borderColor: 'neutrals.primary',
	display: 'inline-block',
	height: '3.25rem',
	lineHeight: '3.25rem',
	width: '100%',
	textIndent: '1.25rem',
	textDecoration: 'none',
	'&:hover, &:active': { opacity: 0.8 },
};

export const getODPLinkStyles = (isSmallScreen: boolean) => {
	return isSmallScreen ? ODPLinkStylesMobile : ODPLinkStyles;
};
