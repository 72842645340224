import { Maybe } from '@/types';

export const generateFacilityUrl = (state?: Maybe<string>, city?: Maybe<string>, line1?: Maybe<string>) => {
	if (!state || !city || !line1) return '';

	const _state = state.toLowerCase();
	const _city = city.toLowerCase().replace(/ /gi, '-');
	const _line1 = line1?.toLowerCase().replace(/ /gi, '-').replace(/[,#.]/gi, '');

	if (!_state || !_city || !_line1) return '';

	return `${_state}/${_city}/${_line1}`;
};
