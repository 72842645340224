export const getNavMenuStyles = (isMobile?: boolean) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: '0.5rem 0',
	...(isMobile && { width: '100%' }),
});

export const getLinkStyles = (isMobile?: boolean) => ({
	textDecoration: 'none',
	lineHeight: isMobile ? '1rem' : '2.5rem',
	...(isMobile && { width: '50%' }),
});
