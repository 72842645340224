/* eslint-disable */
// @ts-nocheck
import { BffBrandType } from '@/gqlConstants';
import { FacilityBrand } from '@/gqlConstants';
import { FacilityServiceBrand } from '@/gqlConstants';
import { FacilityServiceType } from '@/gqlConstants';
import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** Represents a phone number by Aspen requirements. Digits only with length of 10. */
	AspenPhoneNumberType: any;
	/** CityUid represents custom scalar type that should be the value of City.CityUid field. */
	CityUid: any;
	/** The `Date` scalar represents an ISO-8601 compliant date type. */
	Date: any;
	/**
	 * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
	 *     compliant with the 'date-time' format outlined in section 5.6 of
	 *     the RFC 3339 profile of the ISO 8601 standard for representation
	 *     of dates and times using the Gregorian calendar.
	 */
	DateTime: any;
	/** The built-in `Decimal` scalar type. */
	Decimal: any;
	/** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
	Dimension: any;
	/** The EmailAddress scalar type constitutes a valid email address, represented as a UTF-8 character sequence. The scalar follows the specification defined by the HTML Spec https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
	EmailAddress: any;
	/** The 'HexColor' type represents color in `rgb:ffffff` string format. */
	HexColor: any;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSON: any;
	/** The LocalTime scalar type is a local time string (i.e., with no associated timezone) in 24-hr HH:mm:ss. */
	LocalTime: any;
	/** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
	Long: any;
	/** The multiplier path scalar represents a valid GraphQL multiplier path string. */
	MultiplierPath: any;
	/** The NonEmptyString scalar type represents non empty textual data, represented as UTF‐8 character sequences with at least one character */
	NonEmptyString: any;
	/** The NonNegativeInt scalar type represents a unsigned 32-bit numeric non-fractional value equal to or greater than 0. */
	NonNegativeInt: any;
	/** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
	Quality: any;
	UUID: any;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type Accordions = Entry &
	_Node & {
		__typename?: 'Accordions';
		_id: Scalars['ID'];
		accordionsCollection?: Maybe<AccordionsAccordionsCollection>;
		accordionsContentSectionsCollection?: Maybe<AccordionsAccordionsContentSectionsCollection>;
		accordionsHeading?: Maybe<Scalars['String']>;
		backgroundColor?: Maybe<Scalars['String']>;
		backgroundImage?: Maybe<Asset>;
		buttonCollectionAlignment?: Maybe<Scalars['String']>;
		buttonsCollection?: Maybe<AccordionsButtonsCollection>;
		centerAlign?: Maybe<Scalars['Boolean']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		expandIconPosition?: Maybe<Scalars['String']>;
		expandIconType?: Maybe<Scalars['String']>;
		fullWidth?: Maybe<Scalars['Boolean']>;
		image?: Maybe<FocalPointImage>;
		imagePositionRight?: Maybe<Scalars['Boolean']>;
		imageRoundedCorner?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<AccordionsLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		roundedCornersSize?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleAlignment?: Maybe<Scalars['String']>;
		titleColor?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
		withInnerGap?: Maybe<Scalars['Boolean']>;
	};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsAccordionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AccordionsAccordionsFilter>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsAccordionsContentSectionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsAccordionsContentSectionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ContentSectionFilter>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsAccordionsHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsBackgroundImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsButtonCollectionAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsCenterAlignArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsExpandIconPositionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsExpandIconTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsFullWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsImagePositionRightArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsImageRoundedCornerArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsRoundedCornersSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsTitleAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsTitleColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Group allowed Templates in an Accordion experience [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/accordions) */
export type AccordionsWithInnerGapArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AccordionsAccordionsCollection = {
	__typename?: 'AccordionsAccordionsCollection';
	items: Array<Maybe<AccordionsAccordionsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AccordionsAccordionsContentSectionsCollection = {
	__typename?: 'AccordionsAccordionsContentSectionsCollection';
	items: Array<Maybe<ContentSection>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AccordionsAccordionsContentSectionsCollectionOrder =
	| 'alignment_ASC'
	| 'alignment_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'titleIcon_ASC'
	| 'titleIcon_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AccordionsAccordionsFilter = {
	AND?: InputMaybe<Array<InputMaybe<AccordionsAccordionsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AccordionsAccordionsFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AccordionsAccordionsItem = AgendaStepper | ContentTypeRichText;

export type AccordionsButtonsCollection = {
	__typename?: 'AccordionsButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AccordionsButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type AccordionsCollection = {
	__typename?: 'AccordionsCollection';
	items: Array<Maybe<Accordions>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AccordionsFilter = {
	AND?: InputMaybe<Array<InputMaybe<AccordionsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AccordionsFilter>>>;
	accordions?: InputMaybe<CfaccordionsMultiTypeNestedFilter>;
	accordionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	accordionsContentSections?: InputMaybe<CfContentSectionNestedFilter>;
	accordionsContentSectionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	accordionsHeading?: InputMaybe<Scalars['String']>;
	accordionsHeading_contains?: InputMaybe<Scalars['String']>;
	accordionsHeading_exists?: InputMaybe<Scalars['Boolean']>;
	accordionsHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	accordionsHeading_not?: InputMaybe<Scalars['String']>;
	accordionsHeading_not_contains?: InputMaybe<Scalars['String']>;
	accordionsHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
	buttonCollectionAlignment?: InputMaybe<Scalars['String']>;
	buttonCollectionAlignment_contains?: InputMaybe<Scalars['String']>;
	buttonCollectionAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	buttonCollectionAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonCollectionAlignment_not?: InputMaybe<Scalars['String']>;
	buttonCollectionAlignment_not_contains?: InputMaybe<Scalars['String']>;
	buttonCollectionAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign?: InputMaybe<Scalars['Boolean']>;
	centerAlign_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign_not?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	expandIconPosition?: InputMaybe<Scalars['String']>;
	expandIconPosition_contains?: InputMaybe<Scalars['String']>;
	expandIconPosition_exists?: InputMaybe<Scalars['Boolean']>;
	expandIconPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	expandIconPosition_not?: InputMaybe<Scalars['String']>;
	expandIconPosition_not_contains?: InputMaybe<Scalars['String']>;
	expandIconPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	expandIconType?: InputMaybe<Scalars['String']>;
	expandIconType_contains?: InputMaybe<Scalars['String']>;
	expandIconType_exists?: InputMaybe<Scalars['Boolean']>;
	expandIconType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	expandIconType_not?: InputMaybe<Scalars['String']>;
	expandIconType_not_contains?: InputMaybe<Scalars['String']>;
	expandIconType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	image?: InputMaybe<CfFocalPointImageNestedFilter>;
	imagePositionRight?: InputMaybe<Scalars['Boolean']>;
	imagePositionRight_exists?: InputMaybe<Scalars['Boolean']>;
	imagePositionRight_not?: InputMaybe<Scalars['Boolean']>;
	imageRoundedCorner?: InputMaybe<Scalars['String']>;
	imageRoundedCorner_contains?: InputMaybe<Scalars['String']>;
	imageRoundedCorner_exists?: InputMaybe<Scalars['Boolean']>;
	imageRoundedCorner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageRoundedCorner_not?: InputMaybe<Scalars['String']>;
	imageRoundedCorner_not_contains?: InputMaybe<Scalars['String']>;
	imageRoundedCorner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	roundedCornersSize?: InputMaybe<Scalars['String']>;
	roundedCornersSize_contains?: InputMaybe<Scalars['String']>;
	roundedCornersSize_exists?: InputMaybe<Scalars['Boolean']>;
	roundedCornersSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	roundedCornersSize_not?: InputMaybe<Scalars['String']>;
	roundedCornersSize_not_contains?: InputMaybe<Scalars['String']>;
	roundedCornersSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleAlignment?: InputMaybe<Scalars['String']>;
	titleAlignment_contains?: InputMaybe<Scalars['String']>;
	titleAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	titleAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleAlignment_not?: InputMaybe<Scalars['String']>;
	titleAlignment_not_contains?: InputMaybe<Scalars['String']>;
	titleAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleColor?: InputMaybe<Scalars['String']>;
	titleColor_contains?: InputMaybe<Scalars['String']>;
	titleColor_exists?: InputMaybe<Scalars['Boolean']>;
	titleColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleColor_not?: InputMaybe<Scalars['String']>;
	titleColor_not_contains?: InputMaybe<Scalars['String']>;
	titleColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	withInnerGap?: InputMaybe<Scalars['Boolean']>;
	withInnerGap_exists?: InputMaybe<Scalars['Boolean']>;
	withInnerGap_not?: InputMaybe<Scalars['Boolean']>;
};

export type AccordionsLinkingCollections = {
	__typename?: 'AccordionsLinkingCollections';
	columnsCollection?: Maybe<ColumnsCollection>;
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
	tabCollection?: Maybe<TabCollection>;
};

export type AccordionsLinkingCollectionsColumnsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsLinkingCollectionsColumnsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionsLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionsLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionsLinkingCollectionsProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsLinkingCollectionsProviderDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionsLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionsLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionsLinkingCollectionsTabCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsLinkingCollectionsTabCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionsLinkingCollectionsColumnsCollectionOrder =
	| 'allowEqualHeights_ASC'
	| 'allowEqualHeights_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'mobileDisplayAccordion_ASC'
	| 'mobileDisplayAccordion_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AccordionsLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AccordionsLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AccordionsLinkingCollectionsProviderDetailsPageCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AccordionsLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AccordionsLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type AccordionsLinkingCollectionsTabCollectionOrder =
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AccordionsOrder =
	| 'accordionsHeading_ASC'
	| 'accordionsHeading_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonCollectionAlignment_ASC'
	| 'buttonCollectionAlignment_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'expandIconPosition_ASC'
	| 'expandIconPosition_DESC'
	| 'expandIconType_ASC'
	| 'expandIconType_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundedCorner_ASC'
	| 'imageRoundedCorner_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'roundedCornersSize_ASC'
	| 'roundedCornersSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleAlignment_ASC'
	| 'titleAlignment_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'withInnerGap_ASC'
	| 'withInnerGap_DESC';

/** Account Information */
export type Account = {
	__typename?: 'Account';
	accountNumber?: Maybe<Scalars['Long']>;
	birthDate?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	firstName?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
	phoneNumber?: Maybe<Scalars['String']>;
	username?: Maybe<Scalars['String']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooter = Entry &
	_Node & {
		__typename?: 'AdFooter';
		_id: Scalars['ID'];
		about?: Maybe<AdFooterAbout>;
		bookNowButton?: Maybe<CallToAction>;
		componentName?: Maybe<Scalars['String']>;
		contactNumber?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		copyright?: Maybe<Scalars['String']>;
		disclaimer?: Maybe<DisclaimerV2>;
		exclusivelyAt?: Maybe<Entry>;
		footerPolicyGroupCollection?: Maybe<AdFooterFooterPolicyGroupCollection>;
		isLightFooter?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<AdFooterLinkingCollections>;
		logo?: Maybe<Asset>;
		navigationGroupsCollection?: Maybe<AdFooterNavigationGroupsCollection>;
		socialNetworksCollection?: Maybe<AdFooterSocialNetworksCollection>;
		stickyFooterButton?: Maybe<Button>;
		stickyFooterButtonsCollection?: Maybe<AdFooterStickyFooterButtonsCollection>;
		sys: Sys;
		useMobileStickyFooter?: Maybe<Scalars['Boolean']>;
	};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterAboutArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterBookNowButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<CallToActionFilter>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterComponentNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterContactNumberArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterCopyrightArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterDisclaimerArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<DisclaimerV2Filter>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterExclusivelyAtArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterFooterPolicyGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AdFooterFooterPolicyGroupCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NavigationLinkFilter>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterIsLightFooterArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterLogoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterNavigationGroupsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AdFooterNavigationGroupsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FooterNavigationGroupFilter>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterSocialNetworksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterStickyFooterButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterStickyFooterButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AdFooterStickyFooterButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Footer Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/adFooter) */
export type AdFooterUseMobileStickyFooterArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AdFooterAbout = {
	__typename?: 'AdFooterAbout';
	json: Scalars['JSON'];
	links: AdFooterAboutLinks;
};

export type AdFooterAboutAssets = {
	__typename?: 'AdFooterAboutAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type AdFooterAboutEntries = {
	__typename?: 'AdFooterAboutEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type AdFooterAboutLinks = {
	__typename?: 'AdFooterAboutLinks';
	assets: AdFooterAboutAssets;
	entries: AdFooterAboutEntries;
	resources: AdFooterAboutResources;
};

export type AdFooterAboutResources = {
	__typename?: 'AdFooterAboutResources';
	block: Array<AdFooterAboutResourcesBlock>;
	hyperlink: Array<AdFooterAboutResourcesHyperlink>;
	inline: Array<AdFooterAboutResourcesInline>;
};

export type AdFooterAboutResourcesBlock = ResourceLink & {
	__typename?: 'AdFooterAboutResourcesBlock';
	sys: ResourceSys;
};

export type AdFooterAboutResourcesHyperlink = ResourceLink & {
	__typename?: 'AdFooterAboutResourcesHyperlink';
	sys: ResourceSys;
};

export type AdFooterAboutResourcesInline = ResourceLink & {
	__typename?: 'AdFooterAboutResourcesInline';
	sys: ResourceSys;
};

export type AdFooterCollection = {
	__typename?: 'AdFooterCollection';
	items: Array<Maybe<AdFooter>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AdFooterFilter = {
	AND?: InputMaybe<Array<InputMaybe<AdFooterFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AdFooterFilter>>>;
	about_contains?: InputMaybe<Scalars['String']>;
	about_exists?: InputMaybe<Scalars['Boolean']>;
	about_not_contains?: InputMaybe<Scalars['String']>;
	bookNowButton?: InputMaybe<CfCallToActionNestedFilter>;
	bookNowButton_exists?: InputMaybe<Scalars['Boolean']>;
	componentName?: InputMaybe<Scalars['String']>;
	componentName_contains?: InputMaybe<Scalars['String']>;
	componentName_exists?: InputMaybe<Scalars['Boolean']>;
	componentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName_not?: InputMaybe<Scalars['String']>;
	componentName_not_contains?: InputMaybe<Scalars['String']>;
	componentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contactNumber?: InputMaybe<Scalars['String']>;
	contactNumber_contains?: InputMaybe<Scalars['String']>;
	contactNumber_exists?: InputMaybe<Scalars['Boolean']>;
	contactNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contactNumber_not?: InputMaybe<Scalars['String']>;
	contactNumber_not_contains?: InputMaybe<Scalars['String']>;
	contactNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	copyright?: InputMaybe<Scalars['String']>;
	copyright_contains?: InputMaybe<Scalars['String']>;
	copyright_exists?: InputMaybe<Scalars['Boolean']>;
	copyright_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	copyright_not?: InputMaybe<Scalars['String']>;
	copyright_not_contains?: InputMaybe<Scalars['String']>;
	copyright_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disclaimer?: InputMaybe<CfDisclaimerV2NestedFilter>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	exclusivelyAt_exists?: InputMaybe<Scalars['Boolean']>;
	footerPolicyGroup?: InputMaybe<CfNavigationLinkNestedFilter>;
	footerPolicyGroupCollection_exists?: InputMaybe<Scalars['Boolean']>;
	isLightFooter?: InputMaybe<Scalars['Boolean']>;
	isLightFooter_exists?: InputMaybe<Scalars['Boolean']>;
	isLightFooter_not?: InputMaybe<Scalars['Boolean']>;
	logo_exists?: InputMaybe<Scalars['Boolean']>;
	navigationGroups?: InputMaybe<CfFooterNavigationGroupNestedFilter>;
	navigationGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	socialNetworksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	stickyFooterButton?: InputMaybe<CfButtonNestedFilter>;
	stickyFooterButton_exists?: InputMaybe<Scalars['Boolean']>;
	stickyFooterButtons?: InputMaybe<CfButtonNestedFilter>;
	stickyFooterButtonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	useMobileStickyFooter?: InputMaybe<Scalars['Boolean']>;
	useMobileStickyFooter_exists?: InputMaybe<Scalars['Boolean']>;
	useMobileStickyFooter_not?: InputMaybe<Scalars['Boolean']>;
};

export type AdFooterFooterPolicyGroupCollection = {
	__typename?: 'AdFooterFooterPolicyGroupCollection';
	items: Array<Maybe<NavigationLink>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AdFooterFooterPolicyGroupCollectionOrder =
	| 'externalUrl_ASC'
	| 'externalUrl_DESC'
	| 'isInternal_ASC'
	| 'isInternal_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'linkText_ASC'
	| 'linkText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'openInNewTab_ASC'
	| 'openInNewTab_DESC'
	| 'openInNewWindow_ASC'
	| 'openInNewWindow_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AdFooterLinkingCollections = {
	__typename?: 'AdFooterLinkingCollections';
	appCollection?: Maybe<AppCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type AdFooterLinkingCollectionsAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AdFooterLinkingCollectionsAppCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AdFooterLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AdFooterLinkingCollectionsAppCollectionOrder =
	| 'brandImageLink_ASC'
	| 'brandImageLink_DESC'
	| 'branding_ASC'
	| 'branding_DESC'
	| 'isDeprecated_ASC'
	| 'isDeprecated_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AdFooterNavigationGroupsCollection = {
	__typename?: 'AdFooterNavigationGroupsCollection';
	items: Array<Maybe<FooterNavigationGroup>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AdFooterNavigationGroupsCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'navigationGroupTitle_ASC'
	| 'navigationGroupTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AdFooterOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'isLightFooter_ASC'
	| 'isLightFooter_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useMobileStickyFooter_ASC'
	| 'useMobileStickyFooter_DESC';

export type AdFooterSocialNetworksCollection = {
	__typename?: 'AdFooterSocialNetworksCollection';
	items: Array<Maybe<Entry>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AdFooterStickyFooterButtonsCollection = {
	__typename?: 'AdFooterStickyFooterButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AdFooterStickyFooterButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type AddPatientAuthorizationErrors = InvalidFieldError;

export type AddPatientAuthorizationInput = {
	facilityCode: Scalars['NonEmptyString'];
	financialPoliciesAuthorization: Scalars['Boolean'];
	medicalHistoryObtainingAuthorization: Scalars['Boolean'];
	privacyPracticesAuthorization: Scalars['Boolean'];
	releaseOfInfoToInsurersAuthorization: Scalars['Boolean'];
	/** PNG format image base64 encoded string */
	signature: Scalars['NonEmptyString'];
	thirdPartyInformationSharingAuthorizationInput?: InputMaybe<ThirdPartyInformationSharingAuthorizationInput>;
};

export type AddPatientAuthorizationPayload = {
	__typename?: 'AddPatientAuthorizationPayload';
	errors?: Maybe<Array<AddPatientAuthorizationErrors>>;
	result?: Maybe<PatientAuthorizations>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaModal) */
export type AgendaModal = Entry &
	_Node & {
		__typename?: 'AgendaModal';
		_id: Scalars['ID'];
		bodyText?: Maybe<ContentTypeRichText>;
		contentfulMetadata: ContentfulMetadata;
		headlineHeading?: Maybe<Scalars['String']>;
		headlineText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<AgendaModalLinkingCollections>;
		modalName?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaModal) */
export type AgendaModalBodyTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaModal) */
export type AgendaModalHeadlineHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaModal) */
export type AgendaModalHeadlineTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaModal) */
export type AgendaModalLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaModal) */
export type AgendaModalModalNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AgendaModalCollection = {
	__typename?: 'AgendaModalCollection';
	items: Array<Maybe<AgendaModal>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AgendaModalFilter = {
	AND?: InputMaybe<Array<InputMaybe<AgendaModalFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AgendaModalFilter>>>;
	bodyText?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	bodyText_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headlineHeading?: InputMaybe<Scalars['String']>;
	headlineHeading_contains?: InputMaybe<Scalars['String']>;
	headlineHeading_exists?: InputMaybe<Scalars['Boolean']>;
	headlineHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineHeading_not?: InputMaybe<Scalars['String']>;
	headlineHeading_not_contains?: InputMaybe<Scalars['String']>;
	headlineHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText?: InputMaybe<Scalars['String']>;
	headlineText_contains?: InputMaybe<Scalars['String']>;
	headlineText_exists?: InputMaybe<Scalars['Boolean']>;
	headlineText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText_not?: InputMaybe<Scalars['String']>;
	headlineText_not_contains?: InputMaybe<Scalars['String']>;
	headlineText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	modalName?: InputMaybe<Scalars['String']>;
	modalName_contains?: InputMaybe<Scalars['String']>;
	modalName_exists?: InputMaybe<Scalars['Boolean']>;
	modalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	modalName_not?: InputMaybe<Scalars['String']>;
	modalName_not_contains?: InputMaybe<Scalars['String']>;
	modalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type AgendaModalLinkingCollections = {
	__typename?: 'AgendaModalLinkingCollections';
	agendaStepperCollection?: Maybe<AgendaStepperCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type AgendaModalLinkingCollectionsAgendaStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AgendaModalLinkingCollectionsAgendaStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AgendaModalLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AgendaModalLinkingCollectionsAgendaStepperCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'hideStepperDot_ASC'
	| 'hideStepperDot_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AgendaModalOrder =
	| 'headlineHeading_ASC'
	| 'headlineHeading_DESC'
	| 'headlineText_ASC'
	| 'headlineText_DESC'
	| 'modalName_ASC'
	| 'modalName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepper = Entry &
	_Node & {
		__typename?: 'AgendaStepper';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		backgroundColor?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<ContentTypeRichText>;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		fullWidth?: Maybe<Scalars['Boolean']>;
		hideStepperDot?: Maybe<Scalars['Boolean']>;
		icon?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<AgendaStepperLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		stepsCollection?: Maybe<AgendaStepperStepsCollection>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperFullWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperHideStepperDotArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperStepsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AgendaStepperStepsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AgendaModalFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/agendaStepper) */
export type AgendaStepperTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AgendaStepperCollection = {
	__typename?: 'AgendaStepperCollection';
	items: Array<Maybe<AgendaStepper>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AgendaStepperFilter = {
	AND?: InputMaybe<Array<InputMaybe<AgendaStepperFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AgendaStepperFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	hideStepperDot?: InputMaybe<Scalars['Boolean']>;
	hideStepperDot_exists?: InputMaybe<Scalars['Boolean']>;
	hideStepperDot_not?: InputMaybe<Scalars['Boolean']>;
	icon?: InputMaybe<Scalars['String']>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	steps?: InputMaybe<CfAgendaModalNestedFilter>;
	stepsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AgendaStepperLinkingCollections = {
	__typename?: 'AgendaStepperLinkingCollections';
	accordionsCollection?: Maybe<AccordionsCollection>;
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type AgendaStepperLinkingCollectionsAccordionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AgendaStepperLinkingCollectionsAccordionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AgendaStepperLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AgendaStepperLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AgendaStepperLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AgendaStepperLinkingCollectionsAccordionsCollectionOrder =
	| 'accordionsHeading_ASC'
	| 'accordionsHeading_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonCollectionAlignment_ASC'
	| 'buttonCollectionAlignment_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'expandIconPosition_ASC'
	| 'expandIconPosition_DESC'
	| 'expandIconType_ASC'
	| 'expandIconType_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundedCorner_ASC'
	| 'imageRoundedCorner_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'roundedCornersSize_ASC'
	| 'roundedCornersSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleAlignment_ASC'
	| 'titleAlignment_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'withInnerGap_ASC'
	| 'withInnerGap_DESC';

export type AgendaStepperLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AgendaStepperOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'hideStepperDot_ASC'
	| 'hideStepperDot_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AgendaStepperStepsCollection = {
	__typename?: 'AgendaStepperStepsCollection';
	items: Array<Maybe<AgendaModal>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AgendaStepperStepsCollectionOrder =
	| 'headlineHeading_ASC'
	| 'headlineHeading_DESC'
	| 'headlineText_ASC'
	| 'headlineText_DESC'
	| 'modalName_ASC'
	| 'modalName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/alert) */
export type Alert = Entry &
	_Node & {
		__typename?: 'Alert';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		content?: Maybe<AlertContent>;
		contentfulMetadata: ContentfulMetadata;
		facilities?: Maybe<Array<Maybe<Scalars['String']>>>;
		linkedFrom?: Maybe<AlertLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		priority?: Maybe<Scalars['Int']>;
		sys: Sys;
		table?: Maybe<AlertTable>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/alert) */
export type AlertBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/alert) */
export type AlertContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/alert) */
export type AlertFacilitiesArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/alert) */
export type AlertLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/alert) */
export type AlertNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/alert) */
export type AlertPriorityArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/alert) */
export type AlertTableArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AlertCollection = {
	__typename?: 'AlertCollection';
	items: Array<Maybe<Alert>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AlertContent = {
	__typename?: 'AlertContent';
	json: Scalars['JSON'];
	links: AlertContentLinks;
};

export type AlertContentAssets = {
	__typename?: 'AlertContentAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type AlertContentEntries = {
	__typename?: 'AlertContentEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type AlertContentLinks = {
	__typename?: 'AlertContentLinks';
	assets: AlertContentAssets;
	entries: AlertContentEntries;
	resources: AlertContentResources;
};

export type AlertContentResources = {
	__typename?: 'AlertContentResources';
	block: Array<AlertContentResourcesBlock>;
	hyperlink: Array<AlertContentResourcesHyperlink>;
	inline: Array<AlertContentResourcesInline>;
};

export type AlertContentResourcesBlock = ResourceLink & {
	__typename?: 'AlertContentResourcesBlock';
	sys: ResourceSys;
};

export type AlertContentResourcesHyperlink = ResourceLink & {
	__typename?: 'AlertContentResourcesHyperlink';
	sys: ResourceSys;
};

export type AlertContentResourcesInline = ResourceLink & {
	__typename?: 'AlertContentResourcesInline';
	sys: ResourceSys;
};

export type AlertFilter = {
	AND?: InputMaybe<Array<InputMaybe<AlertFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AlertFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	content_contains?: InputMaybe<Scalars['String']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	content_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	facilities_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilities_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilities_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilities_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	priority?: InputMaybe<Scalars['Int']>;
	priority_exists?: InputMaybe<Scalars['Boolean']>;
	priority_gt?: InputMaybe<Scalars['Int']>;
	priority_gte?: InputMaybe<Scalars['Int']>;
	priority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	priority_lt?: InputMaybe<Scalars['Int']>;
	priority_lte?: InputMaybe<Scalars['Int']>;
	priority_not?: InputMaybe<Scalars['Int']>;
	priority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	sys?: InputMaybe<SysFilter>;
	table_contains?: InputMaybe<Scalars['String']>;
	table_exists?: InputMaybe<Scalars['Boolean']>;
	table_not_contains?: InputMaybe<Scalars['String']>;
};

export type AlertLinkingCollections = {
	__typename?: 'AlertLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type AlertLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AlertOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AlertTable = {
	__typename?: 'AlertTable';
	json: Scalars['JSON'];
	links: AlertTableLinks;
};

export type AlertTableAssets = {
	__typename?: 'AlertTableAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type AlertTableEntries = {
	__typename?: 'AlertTableEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type AlertTableLinks = {
	__typename?: 'AlertTableLinks';
	assets: AlertTableAssets;
	entries: AlertTableEntries;
	resources: AlertTableResources;
};

export type AlertTableResources = {
	__typename?: 'AlertTableResources';
	block: Array<AlertTableResourcesBlock>;
	hyperlink: Array<AlertTableResourcesHyperlink>;
	inline: Array<AlertTableResourcesInline>;
};

export type AlertTableResourcesBlock = ResourceLink & {
	__typename?: 'AlertTableResourcesBlock';
	sys: ResourceSys;
};

export type AlertTableResourcesHyperlink = ResourceLink & {
	__typename?: 'AlertTableResourcesHyperlink';
	sys: ResourceSys;
};

export type AlertTableResourcesInline = ResourceLink & {
	__typename?: 'AlertTableResourcesInline';
	sys: ResourceSys;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContext = Entry &
	_Node & {
		__typename?: 'AnalyticsContext';
		_id: Scalars['ID'];
		appointmentSelectedFacilityId?: Maybe<Scalars['String']>;
		appointmentSelectedFacilityName?: Maybe<Scalars['String']>;
		brand?: Maybe<Scalars['String']>;
		clickName?: Maybe<Scalars['String']>;
		clickPosition?: Maybe<Scalars['String']>;
		clickType?: Maybe<Scalars['String']>;
		componentName?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		errorDetails?: Maybe<Scalars['String']>;
		errorStatus?: Maybe<Scalars['String']>;
		errorType?: Maybe<Scalars['String']>;
		event?: Maybe<Scalars['String']>;
		eventName?: Maybe<Scalars['String']>;
		linkText?: Maybe<Scalars['String']>;
		linkUrl?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<AnalyticsContextLinkingCollections>;
		navigationType?: Maybe<Scalars['String']>;
		pageName?: Maybe<Scalars['String']>;
		pageUrl?: Maybe<Scalars['String']>;
		siteSection?: Maybe<Scalars['String']>;
		socialMediaName?: Maybe<Scalars['String']>;
		sys: Sys;
		virtualPageTitle?: Maybe<Scalars['String']>;
		virtualPageUrl?: Maybe<Scalars['String']>;
	};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextAppointmentSelectedFacilityIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextAppointmentSelectedFacilityNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextBrandArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextClickNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextClickPositionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextClickTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextComponentNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextErrorDetailsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextErrorStatusArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextErrorTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextEventArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextEventNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextLinkTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextLinkUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextNavigationTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextPageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextPageUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextSiteSectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextSocialMediaNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextVirtualPageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for adding analytics data to certain components for various events such as page views, link/button clicks, CTA interactions, etc. Not all fields are relevant to every brand. Relevant brands will be noted in helper text e.g. (CC), (WN), etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/analyticsContext) */
export type AnalyticsContextVirtualPageUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AnalyticsContextCollection = {
	__typename?: 'AnalyticsContextCollection';
	items: Array<Maybe<AnalyticsContext>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AnalyticsContextFilter = {
	AND?: InputMaybe<Array<InputMaybe<AnalyticsContextFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AnalyticsContextFilter>>>;
	appointmentSelectedFacilityId?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityId_contains?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityId_exists?: InputMaybe<Scalars['Boolean']>;
	appointmentSelectedFacilityId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appointmentSelectedFacilityId_not?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityId_not_contains?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appointmentSelectedFacilityName?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityName_contains?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityName_exists?: InputMaybe<Scalars['Boolean']>;
	appointmentSelectedFacilityName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appointmentSelectedFacilityName_not?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityName_not_contains?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	brand?: InputMaybe<Scalars['String']>;
	brand_contains?: InputMaybe<Scalars['String']>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	brand_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	brand_not?: InputMaybe<Scalars['String']>;
	brand_not_contains?: InputMaybe<Scalars['String']>;
	brand_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickName?: InputMaybe<Scalars['String']>;
	clickName_contains?: InputMaybe<Scalars['String']>;
	clickName_exists?: InputMaybe<Scalars['Boolean']>;
	clickName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickName_not?: InputMaybe<Scalars['String']>;
	clickName_not_contains?: InputMaybe<Scalars['String']>;
	clickName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickPosition?: InputMaybe<Scalars['String']>;
	clickPosition_contains?: InputMaybe<Scalars['String']>;
	clickPosition_exists?: InputMaybe<Scalars['Boolean']>;
	clickPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickPosition_not?: InputMaybe<Scalars['String']>;
	clickPosition_not_contains?: InputMaybe<Scalars['String']>;
	clickPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickType?: InputMaybe<Scalars['String']>;
	clickType_contains?: InputMaybe<Scalars['String']>;
	clickType_exists?: InputMaybe<Scalars['Boolean']>;
	clickType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickType_not?: InputMaybe<Scalars['String']>;
	clickType_not_contains?: InputMaybe<Scalars['String']>;
	clickType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName?: InputMaybe<Scalars['String']>;
	componentName_contains?: InputMaybe<Scalars['String']>;
	componentName_exists?: InputMaybe<Scalars['Boolean']>;
	componentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName_not?: InputMaybe<Scalars['String']>;
	componentName_not_contains?: InputMaybe<Scalars['String']>;
	componentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	errorDetails?: InputMaybe<Scalars['String']>;
	errorDetails_contains?: InputMaybe<Scalars['String']>;
	errorDetails_exists?: InputMaybe<Scalars['Boolean']>;
	errorDetails_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorDetails_not?: InputMaybe<Scalars['String']>;
	errorDetails_not_contains?: InputMaybe<Scalars['String']>;
	errorDetails_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorStatus?: InputMaybe<Scalars['String']>;
	errorStatus_contains?: InputMaybe<Scalars['String']>;
	errorStatus_exists?: InputMaybe<Scalars['Boolean']>;
	errorStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorStatus_not?: InputMaybe<Scalars['String']>;
	errorStatus_not_contains?: InputMaybe<Scalars['String']>;
	errorStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorType?: InputMaybe<Scalars['String']>;
	errorType_contains?: InputMaybe<Scalars['String']>;
	errorType_exists?: InputMaybe<Scalars['Boolean']>;
	errorType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorType_not?: InputMaybe<Scalars['String']>;
	errorType_not_contains?: InputMaybe<Scalars['String']>;
	errorType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	event?: InputMaybe<Scalars['String']>;
	eventName?: InputMaybe<Scalars['String']>;
	eventName_contains?: InputMaybe<Scalars['String']>;
	eventName_exists?: InputMaybe<Scalars['Boolean']>;
	eventName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eventName_not?: InputMaybe<Scalars['String']>;
	eventName_not_contains?: InputMaybe<Scalars['String']>;
	eventName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	event_contains?: InputMaybe<Scalars['String']>;
	event_exists?: InputMaybe<Scalars['Boolean']>;
	event_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	event_not?: InputMaybe<Scalars['String']>;
	event_not_contains?: InputMaybe<Scalars['String']>;
	event_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkText?: InputMaybe<Scalars['String']>;
	linkText_contains?: InputMaybe<Scalars['String']>;
	linkText_exists?: InputMaybe<Scalars['Boolean']>;
	linkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkText_not?: InputMaybe<Scalars['String']>;
	linkText_not_contains?: InputMaybe<Scalars['String']>;
	linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkUrl?: InputMaybe<Scalars['String']>;
	linkUrl_contains?: InputMaybe<Scalars['String']>;
	linkUrl_exists?: InputMaybe<Scalars['Boolean']>;
	linkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkUrl_not?: InputMaybe<Scalars['String']>;
	linkUrl_not_contains?: InputMaybe<Scalars['String']>;
	linkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationType?: InputMaybe<Scalars['String']>;
	navigationType_contains?: InputMaybe<Scalars['String']>;
	navigationType_exists?: InputMaybe<Scalars['Boolean']>;
	navigationType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationType_not?: InputMaybe<Scalars['String']>;
	navigationType_not_contains?: InputMaybe<Scalars['String']>;
	navigationType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageName?: InputMaybe<Scalars['String']>;
	pageName_contains?: InputMaybe<Scalars['String']>;
	pageName_exists?: InputMaybe<Scalars['Boolean']>;
	pageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageName_not?: InputMaybe<Scalars['String']>;
	pageName_not_contains?: InputMaybe<Scalars['String']>;
	pageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageUrl?: InputMaybe<Scalars['String']>;
	pageUrl_contains?: InputMaybe<Scalars['String']>;
	pageUrl_exists?: InputMaybe<Scalars['Boolean']>;
	pageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageUrl_not?: InputMaybe<Scalars['String']>;
	pageUrl_not_contains?: InputMaybe<Scalars['String']>;
	pageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	siteSection?: InputMaybe<Scalars['String']>;
	siteSection_contains?: InputMaybe<Scalars['String']>;
	siteSection_exists?: InputMaybe<Scalars['Boolean']>;
	siteSection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	siteSection_not?: InputMaybe<Scalars['String']>;
	siteSection_not_contains?: InputMaybe<Scalars['String']>;
	siteSection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	socialMediaName?: InputMaybe<Scalars['String']>;
	socialMediaName_contains?: InputMaybe<Scalars['String']>;
	socialMediaName_exists?: InputMaybe<Scalars['Boolean']>;
	socialMediaName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	socialMediaName_not?: InputMaybe<Scalars['String']>;
	socialMediaName_not_contains?: InputMaybe<Scalars['String']>;
	socialMediaName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	virtualPageTitle?: InputMaybe<Scalars['String']>;
	virtualPageTitle_contains?: InputMaybe<Scalars['String']>;
	virtualPageTitle_exists?: InputMaybe<Scalars['Boolean']>;
	virtualPageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	virtualPageTitle_not?: InputMaybe<Scalars['String']>;
	virtualPageTitle_not_contains?: InputMaybe<Scalars['String']>;
	virtualPageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	virtualPageUrl?: InputMaybe<Scalars['String']>;
	virtualPageUrl_contains?: InputMaybe<Scalars['String']>;
	virtualPageUrl_exists?: InputMaybe<Scalars['Boolean']>;
	virtualPageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	virtualPageUrl_not?: InputMaybe<Scalars['String']>;
	virtualPageUrl_not_contains?: InputMaybe<Scalars['String']>;
	virtualPageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnalyticsContextLinkingCollections = {
	__typename?: 'AnalyticsContextLinkingCollections';
	agendaStepperCollection?: Maybe<AgendaStepperCollection>;
	buttonCollection?: Maybe<ButtonCollection>;
	cardCollection?: Maybe<CardCollection>;
	categoryCollection?: Maybe<CategoryCollection>;
	contentTypeRichTextCollection?: Maybe<ContentTypeRichTextCollection>;
	entryCollection?: Maybe<EntryCollection>;
	error404Collection?: Maybe<Error404Collection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	medicalServiceCollection?: Maybe<MedicalServiceCollection>;
	navigationLinkCollection?: Maybe<NavigationLinkCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type AnalyticsContextLinkingCollectionsAgendaStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsAgendaStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsButtonCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsCardCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsCategoryCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsContentTypeRichTextCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsContentTypeRichTextCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsError404CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsError404CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsMedicalServiceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsMedicalServiceCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsNavigationLinkCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsNavigationLinkCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AnalyticsContextLinkingCollectionsAgendaStepperCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'hideStepperDot_ASC'
	| 'hideStepperDot_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AnalyticsContextLinkingCollectionsButtonCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type AnalyticsContextLinkingCollectionsCardCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AnalyticsContextLinkingCollectionsCategoryCollectionOrder =
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'link_ASC'
	| 'link_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AnalyticsContextLinkingCollectionsContentTypeRichTextCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'contained_ASC'
	| 'contained_DESC'
	| 'contentMaxWidth_ASC'
	| 'contentMaxWidth_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'id_ASC'
	| 'id_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AnalyticsContextLinkingCollectionsError404CollectionOrder =
	| 'buttonLabel_ASC'
	| 'buttonLabel_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'heading_ASC'
	| 'heading_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subtitle_ASC'
	| 'subtitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AnalyticsContextLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AnalyticsContextLinkingCollectionsMedicalServiceCollectionOrder =
	| 'iconName_ASC'
	| 'iconName_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AnalyticsContextLinkingCollectionsNavigationLinkCollectionOrder =
	| 'externalUrl_ASC'
	| 'externalUrl_DESC'
	| 'isInternal_ASC'
	| 'isInternal_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'linkText_ASC'
	| 'linkText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'openInNewTab_ASC'
	| 'openInNewTab_DESC'
	| 'openInNewWindow_ASC'
	| 'openInNewWindow_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AnalyticsContextLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AnalyticsContextLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type AnalyticsContextOrder =
	| 'appointmentSelectedFacilityId_ASC'
	| 'appointmentSelectedFacilityId_DESC'
	| 'appointmentSelectedFacilityName_ASC'
	| 'appointmentSelectedFacilityName_DESC'
	| 'brand_ASC'
	| 'brand_DESC'
	| 'clickName_ASC'
	| 'clickName_DESC'
	| 'clickPosition_ASC'
	| 'clickPosition_DESC'
	| 'clickType_ASC'
	| 'clickType_DESC'
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'errorDetails_ASC'
	| 'errorDetails_DESC'
	| 'errorStatus_ASC'
	| 'errorStatus_DESC'
	| 'errorType_ASC'
	| 'errorType_DESC'
	| 'eventName_ASC'
	| 'eventName_DESC'
	| 'event_ASC'
	| 'event_DESC'
	| 'linkText_ASC'
	| 'linkText_DESC'
	| 'linkUrl_ASC'
	| 'linkUrl_DESC'
	| 'navigationType_ASC'
	| 'navigationType_DESC'
	| 'pageName_ASC'
	| 'pageName_DESC'
	| 'pageUrl_ASC'
	| 'pageUrl_DESC'
	| 'siteSection_ASC'
	| 'siteSection_DESC'
	| 'socialMediaName_ASC'
	| 'socialMediaName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'virtualPageTitle_ASC'
	| 'virtualPageTitle_DESC'
	| 'virtualPageUrl_ASC'
	| 'virtualPageUrl_DESC';

export type AnsweredQuestionnaireQuestionInput = {
	answer: QuestionnaireAnswerInput;
	questionId: Scalars['NonEmptyString'];
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type App = Entry &
	_Node & {
		__typename?: 'App';
		_id: Scalars['ID'];
		brandImageLink?: Maybe<Scalars['String']>;
		branding?: Maybe<Scalars['String']>;
		breadcrumbsPosition?: Maybe<Array<Maybe<Scalars['String']>>>;
		config?: Maybe<Scalars['JSON']>;
		contentfulMetadata: ContentfulMetadata;
		featureFlags?: Maybe<FeatureFlags>;
		footer?: Maybe<AdFooter>;
		header?: Maybe<Header>;
		isDeprecated?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<AppLinkingCollections>;
		logo?: Maybe<Asset>;
		logoMobile?: Maybe<Asset>;
		name?: Maybe<Scalars['String']>;
		notFoundPage?: Maybe<Error404>;
		pagesCollection?: Maybe<AppPagesCollection>;
		sys: Sys;
	};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppBrandImageLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppBrandingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppBreadcrumbsPositionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppConfigArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppFeatureFlagsArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FeatureFlagsFilter>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppFooterArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AdFooterFilter>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppHeaderArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<HeaderFilter>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppIsDeprecatedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppLogoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppLogoMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppNotFoundPageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<Error404Filter>;
};

/** Used for MicroFrontend Applications. For both Gatsby and NextJS Apps.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/app) */
export type AppPagesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AppPagesCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PageFilter>;
};

export type AppCollection = {
	__typename?: 'AppCollection';
	items: Array<Maybe<App>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

/** The identifier of an app [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appDetails) */
export type AppDetails = Entry &
	_Node & {
		__typename?: 'AppDetails';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		id?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<AppDetailsLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** The identifier of an app [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appDetails) */
export type AppDetailsIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The identifier of an app [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appDetails) */
export type AppDetailsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The identifier of an app [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appDetails) */
export type AppDetailsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AppDetailsCollection = {
	__typename?: 'AppDetailsCollection';
	items: Array<Maybe<AppDetails>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AppDetailsFilter = {
	AND?: InputMaybe<Array<InputMaybe<AppDetailsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AppDetailsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	id?: InputMaybe<Scalars['String']>;
	id_contains?: InputMaybe<Scalars['String']>;
	id_exists?: InputMaybe<Scalars['Boolean']>;
	id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_not?: InputMaybe<Scalars['String']>;
	id_not_contains?: InputMaybe<Scalars['String']>;
	id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type AppDetailsLinkingCollections = {
	__typename?: 'AppDetailsLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	featureFlagCollection?: Maybe<FeatureFlagCollection>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
};

export type AppDetailsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AppDetailsLinkingCollectionsFeatureFlagCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AppDetailsLinkingCollectionsFeatureFlagCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AppDetailsLinkingCollectionsProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AppDetailsLinkingCollectionsProviderDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AppDetailsLinkingCollectionsFeatureFlagCollectionOrder =
	| 'description_ASC'
	| 'description_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'value_ASC'
	| 'value_DESC';

export type AppDetailsLinkingCollectionsProviderDetailsPageCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AppDetailsOrder =
	| 'id_ASC'
	| 'id_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AppFilter = {
	AND?: InputMaybe<Array<InputMaybe<AppFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AppFilter>>>;
	brandImageLink?: InputMaybe<Scalars['String']>;
	brandImageLink_contains?: InputMaybe<Scalars['String']>;
	brandImageLink_exists?: InputMaybe<Scalars['Boolean']>;
	brandImageLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	brandImageLink_not?: InputMaybe<Scalars['String']>;
	brandImageLink_not_contains?: InputMaybe<Scalars['String']>;
	brandImageLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	branding?: InputMaybe<Scalars['String']>;
	branding_contains?: InputMaybe<Scalars['String']>;
	branding_exists?: InputMaybe<Scalars['Boolean']>;
	branding_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	branding_not?: InputMaybe<Scalars['String']>;
	branding_not_contains?: InputMaybe<Scalars['String']>;
	branding_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbsPosition_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbsPosition_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbsPosition_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbsPosition_exists?: InputMaybe<Scalars['Boolean']>;
	config_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	featureFlags?: InputMaybe<CfFeatureFlagsNestedFilter>;
	featureFlags_exists?: InputMaybe<Scalars['Boolean']>;
	footer?: InputMaybe<CfAdFooterNestedFilter>;
	footer_exists?: InputMaybe<Scalars['Boolean']>;
	header?: InputMaybe<CfHeaderNestedFilter>;
	header_exists?: InputMaybe<Scalars['Boolean']>;
	isDeprecated?: InputMaybe<Scalars['Boolean']>;
	isDeprecated_exists?: InputMaybe<Scalars['Boolean']>;
	isDeprecated_not?: InputMaybe<Scalars['Boolean']>;
	logoMobile_exists?: InputMaybe<Scalars['Boolean']>;
	logo_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	notFoundPage?: InputMaybe<CfError404NestedFilter>;
	notFoundPage_exists?: InputMaybe<Scalars['Boolean']>;
	pages?: InputMaybe<CfPageNestedFilter>;
	pagesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type AppLinkingCollections = {
	__typename?: 'AppLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type AppLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AppOrder =
	| 'brandImageLink_ASC'
	| 'brandImageLink_DESC'
	| 'branding_ASC'
	| 'branding_DESC'
	| 'isDeprecated_ASC'
	| 'isDeprecated_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type AppPagesCollection = {
	__typename?: 'AppPagesCollection';
	items: Array<Maybe<Page>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AppPagesCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ApplyPolicy = 'AFTER_RESOLVER' | 'BEFORE_RESOLVER' | 'VALIDATION';

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPage = Entry &
	_Node & {
		__typename?: 'AppointmentBookingPage';
		_id: Scalars['ID'];
		bottomContentCollection?: Maybe<AppointmentBookingPageBottomContentCollection>;
		brand?: Maybe<Brand>;
		canonical?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		featuredContent?: Maybe<SingleMessage>;
		indexed?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<AppointmentBookingPageLinkingCollections>;
		metaDescription?: Maybe<Scalars['String']>;
		metaTitle?: Maybe<Scalars['String']>;
		name?: Maybe<Scalars['String']>;
		path?: Maybe<Scalars['String']>;
		sys: Sys;
		theme?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
	};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageBottomContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AppointmentBookingPageBottomContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CategoryPickerFilter>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageBrandArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BrandFilter>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageCanonicalArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageFeaturedContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<SingleMessageFilter>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageIndexedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageMetaDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageMetaTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPagePathArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageThemeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Full Page Template for Appointment Booking [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/appointmentBookingPage) */
export type AppointmentBookingPageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AppointmentBookingPageBottomContentCollection = {
	__typename?: 'AppointmentBookingPageBottomContentCollection';
	items: Array<Maybe<CategoryPicker>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AppointmentBookingPageBottomContentCollectionOrder =
	| 'eyebrowHeading_ASC'
	| 'eyebrowHeading_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'mobileThreshold_ASC'
	| 'mobileThreshold_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'setTwoRows_ASC'
	| 'setTwoRows_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'transparentCategories_ASC'
	| 'transparentCategories_DESC';

export type AppointmentBookingPageCollection = {
	__typename?: 'AppointmentBookingPageCollection';
	items: Array<Maybe<AppointmentBookingPage>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AppointmentBookingPageFilter = {
	AND?: InputMaybe<Array<InputMaybe<AppointmentBookingPageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AppointmentBookingPageFilter>>>;
	bottomContent?: InputMaybe<CfCategoryPickerNestedFilter>;
	bottomContentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	brand?: InputMaybe<CfBrandNestedFilter>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	canonical?: InputMaybe<Scalars['String']>;
	canonical_contains?: InputMaybe<Scalars['String']>;
	canonical_exists?: InputMaybe<Scalars['Boolean']>;
	canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical_not?: InputMaybe<Scalars['String']>;
	canonical_not_contains?: InputMaybe<Scalars['String']>;
	canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	featuredContent?: InputMaybe<CfSingleMessageNestedFilter>;
	featuredContent_exists?: InputMaybe<Scalars['Boolean']>;
	indexed?: InputMaybe<Scalars['Boolean']>;
	indexed_exists?: InputMaybe<Scalars['Boolean']>;
	indexed_not?: InputMaybe<Scalars['Boolean']>;
	metaDescription?: InputMaybe<Scalars['String']>;
	metaDescription_contains?: InputMaybe<Scalars['String']>;
	metaDescription_exists?: InputMaybe<Scalars['Boolean']>;
	metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaDescription_not?: InputMaybe<Scalars['String']>;
	metaDescription_not_contains?: InputMaybe<Scalars['String']>;
	metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaTitle?: InputMaybe<Scalars['String']>;
	metaTitle_contains?: InputMaybe<Scalars['String']>;
	metaTitle_exists?: InputMaybe<Scalars['Boolean']>;
	metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaTitle_not?: InputMaybe<Scalars['String']>;
	metaTitle_not_contains?: InputMaybe<Scalars['String']>;
	metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path?: InputMaybe<Scalars['String']>;
	path_contains?: InputMaybe<Scalars['String']>;
	path_exists?: InputMaybe<Scalars['Boolean']>;
	path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path_not?: InputMaybe<Scalars['String']>;
	path_not_contains?: InputMaybe<Scalars['String']>;
	path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	theme?: InputMaybe<Scalars['String']>;
	theme_contains?: InputMaybe<Scalars['String']>;
	theme_exists?: InputMaybe<Scalars['Boolean']>;
	theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	theme_not?: InputMaybe<Scalars['String']>;
	theme_not_contains?: InputMaybe<Scalars['String']>;
	theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AppointmentBookingPageLinkingCollections = {
	__typename?: 'AppointmentBookingPageLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type AppointmentBookingPageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AppointmentBookingPageOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
	__typename?: 'Asset';
	contentType?: Maybe<Scalars['String']>;
	contentfulMetadata: ContentfulMetadata;
	description?: Maybe<Scalars['String']>;
	fileName?: Maybe<Scalars['String']>;
	height?: Maybe<Scalars['Int']>;
	linkedFrom?: Maybe<AssetLinkingCollections>;
	size?: Maybe<Scalars['Int']>;
	sys: Sys;
	title?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
	width?: Maybe<Scalars['Int']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
	transform?: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type AssetCollection = {
	__typename?: 'AssetCollection';
	items: Array<Maybe<Asset>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type AssetFilter = {
	AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
	contentType?: InputMaybe<Scalars['String']>;
	contentType_contains?: InputMaybe<Scalars['String']>;
	contentType_exists?: InputMaybe<Scalars['Boolean']>;
	contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentType_not?: InputMaybe<Scalars['String']>;
	contentType_not_contains?: InputMaybe<Scalars['String']>;
	contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fileName?: InputMaybe<Scalars['String']>;
	fileName_contains?: InputMaybe<Scalars['String']>;
	fileName_exists?: InputMaybe<Scalars['Boolean']>;
	fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fileName_not?: InputMaybe<Scalars['String']>;
	fileName_not_contains?: InputMaybe<Scalars['String']>;
	fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	height?: InputMaybe<Scalars['Int']>;
	height_exists?: InputMaybe<Scalars['Boolean']>;
	height_gt?: InputMaybe<Scalars['Int']>;
	height_gte?: InputMaybe<Scalars['Int']>;
	height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	height_lt?: InputMaybe<Scalars['Int']>;
	height_lte?: InputMaybe<Scalars['Int']>;
	height_not?: InputMaybe<Scalars['Int']>;
	height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	size?: InputMaybe<Scalars['Int']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_gt?: InputMaybe<Scalars['Int']>;
	size_gte?: InputMaybe<Scalars['Int']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	size_lt?: InputMaybe<Scalars['Int']>;
	size_lte?: InputMaybe<Scalars['Int']>;
	size_not?: InputMaybe<Scalars['Int']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	url?: InputMaybe<Scalars['String']>;
	url_contains?: InputMaybe<Scalars['String']>;
	url_exists?: InputMaybe<Scalars['Boolean']>;
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	url_not?: InputMaybe<Scalars['String']>;
	url_not_contains?: InputMaybe<Scalars['String']>;
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	width?: InputMaybe<Scalars['Int']>;
	width_exists?: InputMaybe<Scalars['Boolean']>;
	width_gt?: InputMaybe<Scalars['Int']>;
	width_gte?: InputMaybe<Scalars['Int']>;
	width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	width_lt?: InputMaybe<Scalars['Int']>;
	width_lte?: InputMaybe<Scalars['Int']>;
	width_not?: InputMaybe<Scalars['Int']>;
	width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssetLinkingCollections = {
	__typename?: 'AssetLinkingCollections';
	accordionsCollection?: Maybe<AccordionsCollection>;
	adFooterCollection?: Maybe<AdFooterCollection>;
	appCollection?: Maybe<AppCollection>;
	callToActionCollection?: Maybe<CallToActionCollection>;
	cardCollection?: Maybe<CardCollection>;
	entryCollection?: Maybe<EntryCollection>;
	error404Collection?: Maybe<Error404Collection>;
	focalPointImageCollection?: Maybe<FocalPointImageCollection>;
	footerV2Collection?: Maybe<FooterV2Collection>;
	formFieldCollection?: Maybe<FormFieldCollection>;
	googleMapTemplateCollection?: Maybe<GoogleMapTemplateCollection>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	navigationLinkCollection?: Maybe<NavigationLinkCollection>;
	onPageSchedulingCollection?: Maybe<OnPageSchedulingCollection>;
	pageCollection?: Maybe<PageCollection>;
	patientReviewV2Collection?: Maybe<PatientReviewV2Collection>;
	serviceCollection?: Maybe<ServiceCollection>;
	smallScreenContentCollection?: Maybe<SmallScreenContentCollection>;
	staffCollection?: Maybe<StaffCollection>;
	successCardCollection?: Maybe<SuccessCardCollection>;
	videoCollection?: Maybe<VideoCollection>;
};

export type AssetLinkingCollectionsAccordionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsAdFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsCallToActionCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsError404CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFocalPointImageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFooterV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFormFieldCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsGoogleMapTemplateCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsNavigationLinkCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsOnPageSchedulingCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsPatientReviewV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsServiceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsSmallScreenContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsStaffCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsSuccessCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsVideoCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type AssetOrder =
	| 'contentType_ASC'
	| 'contentType_DESC'
	| 'fileName_ASC'
	| 'fileName_DESC'
	| 'height_ASC'
	| 'height_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'url_ASC'
	| 'url_DESC'
	| 'width_ASC'
	| 'width_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/badge) */
export type Badge = Entry &
	_Node & {
		__typename?: 'Badge';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<BadgeLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		variant?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/badge) */
export type BadgeLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/badge) */
export type BadgeLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/badge) */
export type BadgeNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/badge) */
export type BadgeVariantArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type BadgeCollection = {
	__typename?: 'BadgeCollection';
	items: Array<Maybe<Badge>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BadgeFilter = {
	AND?: InputMaybe<Array<InputMaybe<BadgeFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BadgeFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	variant?: InputMaybe<Scalars['String']>;
	variant_contains?: InputMaybe<Scalars['String']>;
	variant_exists?: InputMaybe<Scalars['Boolean']>;
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	variant_not?: InputMaybe<Scalars['String']>;
	variant_not_contains?: InputMaybe<Scalars['String']>;
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BadgeLinkingCollections = {
	__typename?: 'BadgeLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	mediaSliderCollection?: Maybe<MediaSliderCollection>;
};

export type BadgeLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BadgeLinkingCollectionsMediaSliderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BadgeLinkingCollectionsMediaSliderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BadgeLinkingCollectionsMediaSliderCollectionOrder =
	| 'aspectRatio_ASC'
	| 'aspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type BadgeOrder =
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type BffAdditionalInfo = {
	__typename?: 'BffAdditionalInfo';
	available_categories?: Maybe<Scalars['String']>;
	available_services?: Maybe<Scalars['String']>;
	can_book?: Maybe<Scalars['Boolean']>;
	cancellation_fee_duration?: Maybe<Scalars['Int']>;
	categories_available?: Maybe<Scalars['Boolean']>;
	center_amenities?: Maybe<Scalars['String']>;
	collect_feedback?: Maybe<Scalars['String']>;
	cst?: Maybe<Scalars['String']>;
	feedback_label?: Maybe<Scalars['String']>;
	feedback_link?: Maybe<Scalars['String']>;
	is_add_ons_available?: Maybe<Scalars['Boolean']>;
	is_auto_pay_enabled_at_center?: Maybe<Scalars['Boolean']>;
	is_center_amenities_enabled?: Maybe<Scalars['Boolean']>;
	is_global_tokenization_supported?: Maybe<Scalars['Boolean']>;
	service_tax_no?: Maybe<Scalars['String']>;
	services_available?: Maybe<Scalars['Boolean']>;
	tin?: Maybe<Scalars['String']>;
	unavailable_categories?: Maybe<Scalars['String']>;
	unavailable_services?: Maybe<Scalars['String']>;
	vat?: Maybe<Scalars['String']>;
};

export type BffAddress = {
	__typename?: 'BffAddress';
	address_1?: Maybe<Scalars['String']>;
	address_2?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	zip_code?: Maybe<Scalars['String']>;
};

export type BffBrand = {
	__typename?: 'BffBrand';
	description?: Maybe<Scalars['String']>;
	id?: Maybe<BffBrandType>;
	name?: Maybe<Scalars['String']>;
};

export { BffBrandType };

export type BffCacheStats = {
	__typename?: 'BffCacheStats';
	expires?: Maybe<Scalars['String']>;
	hits?: Maybe<Scalars['Float']>;
	keys?: Maybe<Scalars['Float']>;
	keysSize?: Maybe<Scalars['Float']>;
	misses?: Maybe<Scalars['Float']>;
	name?: Maybe<Scalars['String']>;
	now?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	valuesSize?: Maybe<Scalars['Float']>;
};

export type BffCenter = {
	__typename?: 'BffCenter';
	additional_info?: Maybe<BffAdditionalInfo>;
	address_info?: Maybe<BffAddress>;
	code?: Maybe<Scalars['String']>;
	contact_info?: Maybe<BffContactInfo>;
	country?: Maybe<BffCountry>;
	culture_code_at_center?: Maybe<Scalars['String']>;
	currency?: Maybe<BffCurrency>;
	description?: Maybe<Scalars['String']>;
	display_name?: Maybe<Scalars['String']>;
	enable_parallel_services_at_center?: Maybe<Scalars['Boolean']>;
	id?: Maybe<Scalars['String']>;
	is_fbe_enabled?: Maybe<Scalars['Boolean']>;
	is_hc_call_center?: Maybe<Scalars['Boolean']>;
	location?: Maybe<BffLocation>;
	name?: Maybe<Scalars['String']>;
	online_booking_start_date?: Maybe<Scalars['String']>;
	settings?: Maybe<Scalars['String']>;
	state?: Maybe<BffState>;
	working_hours?: Maybe<Array<Maybe<BffWorkinghours>>>;
};

export type BffCenters = {
	__typename?: 'BffCenters';
	centers?: Maybe<Array<Maybe<BffCenter>>>;
	error?: Maybe<Scalars['String']>;
};

export type BffCity = {
	__typename?: 'BffCity';
	city?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
	slugXml?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	stateAbbreviation?: Maybe<Scalars['String']>;
};

export type BffContactInfo = {
	__typename?: 'BffContactInfo';
	email?: Maybe<Scalars['String']>;
	phone_1?: Maybe<BffPhoneNumber>;
	phone_2?: Maybe<BffPhoneNumber>;
};

export type BffCountry = {
	__typename?: 'BffCountry';
	code?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	nationality?: Maybe<Scalars['String']>;
	phone_code?: Maybe<Scalars['Int']>;
};

export type BffCurrency = {
	__typename?: 'BffCurrency';
	code?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	symbol?: Maybe<Scalars['String']>;
};

export type BffDamAsset = {
	__typename?: 'BffDamAsset';
	height?: Maybe<Scalars['Int']>;
	mimetype?: Maybe<Scalars['String']>;
	ok?: Maybe<Scalars['Boolean']>;
	url?: Maybe<Scalars['String']>;
	width?: Maybe<Scalars['Int']>;
};

/**
 * An office facility
 * Brick and Mortar part of **business** [API](http://example.com)!
 */
export type BffFacility = {
	__typename?: 'BffFacility';
	address?: Maybe<BffFacilityAddress>;
	anytimeAppointingDenture?: Maybe<Scalars['Boolean']>;
	anytimeAppointingMotto?: Maybe<Scalars['Boolean']>;
	attributes?: Maybe<Array<Maybe<NamedAttribute>>>;
	birdeyeBusinessId?: Maybe<Scalars['String']>;
	/** Brand where the data is from */
	brand?: Maybe<Scalars['String']>;
	clockwiseId?: Maybe<Scalars['String']>;
	/** Facility Code or Id */
	code: Scalars['String'];
	comingSoon?: Maybe<Scalars['Boolean']>;
	description?: Maybe<Scalars['String']>;
	displayName?: Maybe<Scalars['String']>;
	dma?: Maybe<Scalars['String']>;
	dnis?: Maybe<Scalars['String']>;
	doctors?: Maybe<Array<Maybe<BffProvider>>>;
	email?: Maybe<Scalars['String']>;
	emergencyPhoneNumber?: Maybe<Scalars['String']>;
	faxNumber?: Maybe<Scalars['String']>;
	firstThreeOctets?: Maybe<Scalars['String']>;
	geomodifier?: Maybe<Scalars['String']>;
	implants?: Maybe<Scalars['String']>;
	insurance?: Maybe<Array<Maybe<BffInsurance>>>;
	insuranceNetwork?: Maybe<Array<Maybe<BffInsurance>>>;
	legalBillingName?: Maybe<Scalars['String']>;
	location?: Maybe<BffFacilityLocation>;
	minimumPatientAge?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	needsBasedQuestions?: Maybe<Scalars['Boolean']>;
	onHVP?: Maybe<Scalars['Boolean']>;
	onMotto?: Maybe<Scalars['Boolean']>;
	onP3?: Maybe<Scalars['Boolean']>;
	openDate?: Maybe<Scalars['String']>;
	originalBrand?: Maybe<Scalars['String']>;
	phoneNumber?: Maybe<Scalars['String']>;
	pilot1?: Maybe<Scalars['String']>;
	region?: Maybe<Scalars['String']>;
	revspringAccountId?: Maybe<Scalars['String']>;
	revspringLocationValue?: Maybe<Scalars['String']>;
	scheduleAppointmentURL?: Maybe<Scalars['String']>;
	schedulingSystem?: Maybe<Scalars['String']>;
	services?: Maybe<Array<Maybe<BffService>>>;
	siteGroup?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
	slugXml?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	workingHours?: Maybe<Array<Maybe<BffWorkingHours>>>;
};

export type BffFacilityAddress = {
	__typename?: 'BffFacilityAddress';
	address1?: Maybe<Scalars['String']>;
	address2?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	stateCode?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
};

export type BffFacilityCollection = {
	__typename?: 'BffFacilityCollection';
	items?: Maybe<Array<Maybe<BffFacility>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	total?: Maybe<Scalars['Int']>;
};

export type BffFacilityLocation = {
	__typename?: 'BffFacilityLocation';
	directions?: Maybe<Scalars['String']>;
	distance?: Maybe<Scalars['Float']>;
	landmarks?: Maybe<Scalars['String']>;
	latitude?: Maybe<Scalars['Float']>;
	longitude?: Maybe<Scalars['Float']>;
	neighboringAreas?: Maybe<Scalars['String']>;
	timeZone?: Maybe<Scalars['String']>;
	timezoneIANA?: Maybe<Scalars['String']>;
};

export type BffFutureDays = {
	__typename?: 'BffFutureDays';
	Day?: Maybe<Scalars['String']>;
	HolidayType?: Maybe<Scalars['String']>;
	IsAvailable?: Maybe<Scalars['Boolean']>;
};

export type BffInsurance = {
	__typename?: 'BffInsurance';
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
};

export type BffLocation = {
	__typename?: 'BffLocation';
	latitude?: Maybe<Scalars['String']>;
	longitude?: Maybe<Scalars['String']>;
	time_zone?: Maybe<BffTimezone>;
};

export type BffPhoneNumber = {
	__typename?: 'BffPhoneNumber';
	country_id?: Maybe<Scalars['Int']>;
	display_number?: Maybe<Scalars['String']>;
	number?: Maybe<Scalars['String']>;
};

export type BffPriceInfo = {
	__typename?: 'BffPriceInfo';
	currency_id?: Maybe<Scalars['Int']>;
	demand_group_id?: Maybe<Scalars['String']>;
	include_tax?: Maybe<Scalars['Boolean']>;
	sale_price?: Maybe<Scalars['Float']>;
	ssg?: Maybe<Scalars['Int']>;
	tax_id?: Maybe<Scalars['String']>;
};

export type BffProduct = {
	__typename?: 'BffProduct';
	brand: Scalars['String'];
	brandfolderImageAssetUrl?: Maybe<Scalars['String']>;
	category?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	name: Scalars['String'];
	procedures?: Maybe<Array<Maybe<BffProductProcedure>>>;
	subCategory?: Maybe<Scalars['String']>;
};

export type BffProductProcedure = {
	__typename?: 'BffProductProcedure';
	/** The price for the procedure, it can be average if not sought by facility, or specific to the facility selected. */
	price: Scalars['Float'];
	procedureCode: Scalars['String'];
	procedureDescription: Scalars['String'];
};

export type BffProvider = {
	__typename?: 'BffProvider';
	active?: Maybe<Scalars['Boolean']>;
	associations?: Maybe<Array<Maybe<Scalars['String']>>>;
	biography?: Maybe<Scalars['String']>;
	collegeCity?: Maybe<Scalars['String']>;
	collegeName?: Maybe<Scalars['String']>;
	collegeState?: Maybe<Scalars['String']>;
	degreeName?: Maybe<Scalars['String']>;
	employeeStatus?: Maybe<Scalars['String']>;
	ethnicity?: Maybe<Scalars['String']>;
	firstName?: Maybe<Scalars['String']>;
	gender?: Maybe<Scalars['String']>;
	graduationDate?: Maybe<Scalars['String']>;
	hireDate?: Maybe<Scalars['String']>;
	jobTitle?: Maybe<Scalars['String']>;
	languages?: Maybe<Array<Maybe<Scalars['String']>>>;
	lastName?: Maybe<Scalars['String']>;
	licenses?: Maybe<Array<Maybe<BffProviderLicense>>>;
	name?: Maybe<Scalars['String']>;
	npi?: Maybe<Scalars['String']>;
	photoUrl?: Maybe<Scalars['String']>;
	providerId?: Maybe<Scalars['String']>;
	providerType?: Maybe<Scalars['String']>;
	specialties?: Maybe<Scalars['String']>;
	volunteerism?: Maybe<Scalars['String']>;
};

export type BffProviderLicense = {
	__typename?: 'BffProviderLicense';
	state?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
	value?: Maybe<Scalars['String']>;
};

export type BffRating = {
	__typename?: 'BffRating';
	rating?: Maybe<Scalars['Float']>;
	reviewCount?: Maybe<Scalars['Int']>;
};

export type BffReview = {
	__typename?: 'BffReview';
	comments?: Maybe<Scalars['String']>;
	rating?: Maybe<Scalars['Float']>;
	reviewId?: Maybe<Scalars['String']>;
	reviewTimestamp?: Maybe<Scalars['String']>;
	reviewer?: Maybe<BffReviewReviewer>;
	source?: Maybe<Scalars['String']>;
	tags?: Maybe<Array<Maybe<Scalars['String']>>>;
	title?: Maybe<Scalars['String']>;
};

export type BffReviewReviewer = {
	__typename?: 'BffReviewReviewer';
	firstName?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
};

export type BffReviews = {
	__typename?: 'BffReviews';
	averageRating?: Maybe<Scalars['Float']>;
	businessId?: Maybe<Scalars['String']>;
	ratings?: Maybe<Array<Maybe<BffRating>>>;
	reviewCount?: Maybe<Scalars['Int']>;
	reviews?: Maybe<Array<Maybe<BffReview>>>;
};

export type BffService = {
	__typename?: 'BffService';
	activeFlag?: Maybe<Scalars['Boolean']>;
	add_ons_info?: Maybe<Scalars['String']>;
	additional_info?: Maybe<Scalars['String']>;
	catalog_info?: Maybe<Scalars['String']>;
	code?: Maybe<Scalars['String']>;
	dentureCouponCode?: Maybe<Scalars['String']>;
	dentureFee?: Maybe<Scalars['Int']>;
	dentureFlag?: Maybe<Scalars['Boolean']>;
	description?: Maybe<Scalars['String']>;
	duration?: Maybe<Scalars['Int']>;
	finishing_services_info?: Maybe<Scalars['String']>;
	formula?: Maybe<Scalars['String']>;
	generalDentFee?: Maybe<Scalars['Int']>;
	generalDentistryCouponCode?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	image_paths?: Maybe<Scalars['String']>;
	is_couple_service?: Maybe<Scalars['Boolean']>;
	name?: Maybe<Scalars['String']>;
	parallel_groups?: Maybe<Scalars['String']>;
	parallel_service_groups?: Maybe<Scalars['String']>;
	prerequisites_info?: Maybe<Scalars['String']>;
	price_info?: Maybe<BffPriceInfo>;
	procedureCategory?: Maybe<Scalars['String']>;
	procedureCode?: Maybe<Scalars['String']>;
	procedureDescription?: Maybe<Scalars['String']>;
	promotionalPrice?: Maybe<Scalars['Int']>;
	recovery_time?: Maybe<Scalars['Int']>;
	variants_info?: Maybe<Scalars['String']>;
};

export type BffServices = {
	__typename?: 'BffServices';
	error?: Maybe<Scalars['String']>;
	services?: Maybe<Array<Maybe<BffService>>>;
};

export type BffSession = {
	__typename?: 'BffSession';
	Error?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
};

export type BffSlot = {
	__typename?: 'BffSlot';
	Available?: Maybe<Scalars['Boolean']>;
	Priority?: Maybe<Scalars['Int']>;
	SalePrice?: Maybe<Scalars['String']>;
	Time?: Maybe<Scalars['String']>;
	Warnings?: Maybe<Scalars['String']>;
};

export type BffSlots = {
	__typename?: 'BffSlots';
	Error?: Maybe<Scalars['String']>;
	future_days?: Maybe<Array<Maybe<BffFutureDays>>>;
	next_available_day?: Maybe<Scalars['String']>;
	slots?: Maybe<Array<Maybe<BffSlot>>>;
};

export type BffState = {
	__typename?: 'BffState';
	abbreviation?: Maybe<Scalars['String']>;
	code?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	short_name?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
	slugXml?: Maybe<Scalars['String']>;
};

export type BffTimeSpan = {
	__typename?: 'BffTimeSpan';
	/** The LocalTime scalar type is a local time string (i.e., with no associated timezone) in 24-hr HH:mm:ss. */
	from?: Maybe<Scalars['String']>;
	to?: Maybe<Scalars['String']>;
};

export type BffTimezone = {
	__typename?: 'BffTimezone';
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	standard_name?: Maybe<Scalars['String']>;
	symbol?: Maybe<Scalars['String']>;
};

export type BffUsCity = {
	__typename?: 'BffUSCity';
	city?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
	slugXml?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	stateAbbreviation?: Maybe<Scalars['String']>;
};

export type BffWorkingHours = {
	__typename?: 'BffWorkingHours';
	date?: Maybe<Scalars['String']>;
	open?: Maybe<BffTimeSpan>;
};

export type BffWorkinghours = {
	__typename?: 'BffWorkinghours';
	day_of_week?: Maybe<Scalars['String']>;
	end_time?: Maybe<Scalars['String']>;
	is_closed?: Maybe<Scalars['Boolean']>;
	off_peak_end_time?: Maybe<Scalars['String']>;
	off_peak_start_time?: Maybe<Scalars['String']>;
	offline_end_time?: Maybe<Scalars['String']>;
	offline_start_time?: Maybe<Scalars['String']>;
	start_time?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/birdEyeReviews) */
export type BirdEyeReviews = Entry &
	_Node & {
		__typename?: 'BirdEyeReviews';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<BirdEyeReviewsLinkingCollections>;
		mediaContent?: Maybe<BirdEyeReviewsMediaContent>;
		name?: Maybe<Scalars['String']>;
		ratings?: Maybe<Array<Maybe<Scalars['String']>>>;
		sys: Sys;
		tags?: Maybe<Array<Maybe<Scalars['String']>>>;
		title?: Maybe<Scalars['String']>;
		titleHeading?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/birdEyeReviews) */
export type BirdEyeReviewsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/birdEyeReviews) */
export type BirdEyeReviewsMediaContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BirdEyeReviewsMediaContentFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/birdEyeReviews) */
export type BirdEyeReviewsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/birdEyeReviews) */
export type BirdEyeReviewsRatingsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/birdEyeReviews) */
export type BirdEyeReviewsTagsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/birdEyeReviews) */
export type BirdEyeReviewsTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/birdEyeReviews) */
export type BirdEyeReviewsTitleHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type BirdEyeReviewsCollection = {
	__typename?: 'BirdEyeReviewsCollection';
	items: Array<Maybe<BirdEyeReviews>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BirdEyeReviewsFilter = {
	AND?: InputMaybe<Array<InputMaybe<BirdEyeReviewsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BirdEyeReviewsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	mediaContent?: InputMaybe<CfmediaContentMultiTypeNestedFilter>;
	mediaContent_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ratings_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ratings_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ratings_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ratings_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	tags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tags_exists?: InputMaybe<Scalars['Boolean']>;
	title?: InputMaybe<Scalars['String']>;
	titleHeading?: InputMaybe<Scalars['String']>;
	titleHeading_contains?: InputMaybe<Scalars['String']>;
	titleHeading_exists?: InputMaybe<Scalars['Boolean']>;
	titleHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHeading_not?: InputMaybe<Scalars['String']>;
	titleHeading_not_contains?: InputMaybe<Scalars['String']>;
	titleHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BirdEyeReviewsLinkingCollections = {
	__typename?: 'BirdEyeReviewsLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type BirdEyeReviewsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BirdEyeReviewsLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BirdEyeReviewsLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BirdEyeReviewsLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BirdEyeReviewsLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BirdEyeReviewsLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BirdEyeReviewsLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BirdEyeReviewsLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BirdEyeReviewsLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BirdEyeReviewsLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BirdEyeReviewsMediaContent = FocalPointImage | Video;

export type BirdEyeReviewsMediaContentFilter = {
	AND?: InputMaybe<Array<InputMaybe<BirdEyeReviewsMediaContentFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BirdEyeReviewsMediaContentFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type BirdEyeReviewsOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** The author of a blog. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogAuthor) */
export type BlogAuthor = Entry &
	_Node & {
		__typename?: 'BlogAuthor';
		_id: Scalars['ID'];
		authorBioSummary?: Maybe<BlogAuthorAuthorBioSummary>;
		bioSummary?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		image?: Maybe<Scalars['JSON']>;
		linkedFrom?: Maybe<BlogAuthorLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** The author of a blog. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogAuthor) */
export type BlogAuthorAuthorBioSummaryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The author of a blog. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogAuthor) */
export type BlogAuthorBioSummaryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The author of a blog. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogAuthor) */
export type BlogAuthorImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The author of a blog. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogAuthor) */
export type BlogAuthorLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The author of a blog. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogAuthor) */
export type BlogAuthorNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The author of a blog. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogAuthor) */
export type BlogAuthorTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type BlogAuthorAuthorBioSummary = {
	__typename?: 'BlogAuthorAuthorBioSummary';
	json: Scalars['JSON'];
	links: BlogAuthorAuthorBioSummaryLinks;
};

export type BlogAuthorAuthorBioSummaryAssets = {
	__typename?: 'BlogAuthorAuthorBioSummaryAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type BlogAuthorAuthorBioSummaryEntries = {
	__typename?: 'BlogAuthorAuthorBioSummaryEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type BlogAuthorAuthorBioSummaryLinks = {
	__typename?: 'BlogAuthorAuthorBioSummaryLinks';
	assets: BlogAuthorAuthorBioSummaryAssets;
	entries: BlogAuthorAuthorBioSummaryEntries;
	resources: BlogAuthorAuthorBioSummaryResources;
};

export type BlogAuthorAuthorBioSummaryResources = {
	__typename?: 'BlogAuthorAuthorBioSummaryResources';
	block: Array<BlogAuthorAuthorBioSummaryResourcesBlock>;
	hyperlink: Array<BlogAuthorAuthorBioSummaryResourcesHyperlink>;
	inline: Array<BlogAuthorAuthorBioSummaryResourcesInline>;
};

export type BlogAuthorAuthorBioSummaryResourcesBlock = ResourceLink & {
	__typename?: 'BlogAuthorAuthorBioSummaryResourcesBlock';
	sys: ResourceSys;
};

export type BlogAuthorAuthorBioSummaryResourcesHyperlink = ResourceLink & {
	__typename?: 'BlogAuthorAuthorBioSummaryResourcesHyperlink';
	sys: ResourceSys;
};

export type BlogAuthorAuthorBioSummaryResourcesInline = ResourceLink & {
	__typename?: 'BlogAuthorAuthorBioSummaryResourcesInline';
	sys: ResourceSys;
};

export type BlogAuthorCollection = {
	__typename?: 'BlogAuthorCollection';
	items: Array<Maybe<BlogAuthor>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BlogAuthorFilter = {
	AND?: InputMaybe<Array<InputMaybe<BlogAuthorFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BlogAuthorFilter>>>;
	authorBioSummary_contains?: InputMaybe<Scalars['String']>;
	authorBioSummary_exists?: InputMaybe<Scalars['Boolean']>;
	authorBioSummary_not_contains?: InputMaybe<Scalars['String']>;
	bioSummary?: InputMaybe<Scalars['String']>;
	bioSummary_contains?: InputMaybe<Scalars['String']>;
	bioSummary_exists?: InputMaybe<Scalars['Boolean']>;
	bioSummary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bioSummary_not?: InputMaybe<Scalars['String']>;
	bioSummary_not_contains?: InputMaybe<Scalars['String']>;
	bioSummary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BlogAuthorLinkingCollections = {
	__typename?: 'BlogAuthorLinkingCollections';
	blogDetailsPageCollection?: Maybe<BlogDetailsPageCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type BlogAuthorLinkingCollectionsBlogDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogAuthorLinkingCollectionsBlogDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogAuthorLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogAuthorLinkingCollectionsBlogDetailsPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'date_ASC'
	| 'date_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'timeToRead_ASC'
	| 'timeToRead_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BlogAuthorOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** Blog main category [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogCategory) */
export type BlogCategory = Entry &
	_Node & {
		__typename?: 'BlogCategory';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<BlogCategoryLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		slug?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** Blog main category [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogCategory) */
export type BlogCategoryLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Blog main category [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogCategory) */
export type BlogCategoryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog main category [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogCategory) */
export type BlogCategorySlugArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type BlogCategoryCollection = {
	__typename?: 'BlogCategoryCollection';
	items: Array<Maybe<BlogCategory>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BlogCategoryFilter = {
	AND?: InputMaybe<Array<InputMaybe<BlogCategoryFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BlogCategoryFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	slug?: InputMaybe<Scalars['String']>;
	slug_contains?: InputMaybe<Scalars['String']>;
	slug_exists?: InputMaybe<Scalars['Boolean']>;
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	slug_not?: InputMaybe<Scalars['String']>;
	slug_not_contains?: InputMaybe<Scalars['String']>;
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type BlogCategoryLinkingCollections = {
	__typename?: 'BlogCategoryLinkingCollections';
	blogDetailsPageCollection?: Maybe<BlogDetailsPageCollection>;
	blogListCollection?: Maybe<BlogListCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type BlogCategoryLinkingCollectionsBlogDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogCategoryLinkingCollectionsBlogDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogCategoryLinkingCollectionsBlogListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogCategoryLinkingCollectionsBlogListCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogCategoryLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogCategoryLinkingCollectionsBlogDetailsPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'date_ASC'
	| 'date_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'timeToRead_ASC'
	| 'timeToRead_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BlogCategoryLinkingCollectionsBlogListCollectionOrder =
	| 'buttonLabel_ASC'
	| 'buttonLabel_DESC'
	| 'displayType_ASC'
	| 'displayType_DESC'
	| 'loadMoreButtonLabel_ASC'
	| 'loadMoreButtonLabel_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type BlogCategoryOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'slug_ASC'
	| 'slug_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPage = Entry &
	_Node & {
		__typename?: 'BlogDetailsPage';
		_id: Scalars['ID'];
		author?: Maybe<BlogAuthor>;
		blogImage?: Maybe<Scalars['JSON']>;
		brand?: Maybe<Brand>;
		canonical?: Maybe<Scalars['String']>;
		category?: Maybe<BlogCategory>;
		contentfulMetadata: ContentfulMetadata;
		date?: Maybe<Scalars['DateTime']>;
		imageAsset?: Maybe<ImageAsset>;
		indexed?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<BlogDetailsPageLinkingCollections>;
		metaDescription?: Maybe<Scalars['String']>;
		metaTitle?: Maybe<Scalars['String']>;
		name?: Maybe<Scalars['String']>;
		path?: Maybe<Scalars['String']>;
		subCategory?: Maybe<BlogSubCategory>;
		summary?: Maybe<BlogDetailsPageSummary>;
		sys: Sys;
		text?: Maybe<BlogDetailsPageText>;
		theme?: Maybe<Scalars['String']>;
		timeToRead?: Maybe<Scalars['Int']>;
		title?: Maybe<Scalars['String']>;
	};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageAuthorArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BlogAuthorFilter>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageBlogImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageBrandArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BrandFilter>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageCanonicalArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageCategoryArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BlogCategoryFilter>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageDateArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageImageAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageIndexedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageMetaDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageMetaTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPagePathArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageSubCategoryArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BlogSubCategoryFilter>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageSummaryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageThemeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageTimeToReadArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content to describe a blog entry in its entirety [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogDetailsPage) */
export type BlogDetailsPageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type BlogDetailsPageCollection = {
	__typename?: 'BlogDetailsPageCollection';
	items: Array<Maybe<BlogDetailsPage>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BlogDetailsPageFilter = {
	AND?: InputMaybe<Array<InputMaybe<BlogDetailsPageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BlogDetailsPageFilter>>>;
	author?: InputMaybe<CfBlogAuthorNestedFilter>;
	author_exists?: InputMaybe<Scalars['Boolean']>;
	blogImage_exists?: InputMaybe<Scalars['Boolean']>;
	brand?: InputMaybe<CfBrandNestedFilter>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	canonical?: InputMaybe<Scalars['String']>;
	canonical_contains?: InputMaybe<Scalars['String']>;
	canonical_exists?: InputMaybe<Scalars['Boolean']>;
	canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical_not?: InputMaybe<Scalars['String']>;
	canonical_not_contains?: InputMaybe<Scalars['String']>;
	canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	category?: InputMaybe<CfBlogCategoryNestedFilter>;
	category_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	date?: InputMaybe<Scalars['DateTime']>;
	date_exists?: InputMaybe<Scalars['Boolean']>;
	date_gt?: InputMaybe<Scalars['DateTime']>;
	date_gte?: InputMaybe<Scalars['DateTime']>;
	date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	date_lt?: InputMaybe<Scalars['DateTime']>;
	date_lte?: InputMaybe<Scalars['DateTime']>;
	date_not?: InputMaybe<Scalars['DateTime']>;
	date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	imageAsset?: InputMaybe<CfImageAssetNestedFilter>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	indexed?: InputMaybe<Scalars['Boolean']>;
	indexed_exists?: InputMaybe<Scalars['Boolean']>;
	indexed_not?: InputMaybe<Scalars['Boolean']>;
	metaDescription?: InputMaybe<Scalars['String']>;
	metaDescription_contains?: InputMaybe<Scalars['String']>;
	metaDescription_exists?: InputMaybe<Scalars['Boolean']>;
	metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaDescription_not?: InputMaybe<Scalars['String']>;
	metaDescription_not_contains?: InputMaybe<Scalars['String']>;
	metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaTitle?: InputMaybe<Scalars['String']>;
	metaTitle_contains?: InputMaybe<Scalars['String']>;
	metaTitle_exists?: InputMaybe<Scalars['Boolean']>;
	metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaTitle_not?: InputMaybe<Scalars['String']>;
	metaTitle_not_contains?: InputMaybe<Scalars['String']>;
	metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path?: InputMaybe<Scalars['String']>;
	path_contains?: InputMaybe<Scalars['String']>;
	path_exists?: InputMaybe<Scalars['Boolean']>;
	path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path_not?: InputMaybe<Scalars['String']>;
	path_not_contains?: InputMaybe<Scalars['String']>;
	path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subCategory?: InputMaybe<CfBlogSubCategoryNestedFilter>;
	subCategory_exists?: InputMaybe<Scalars['Boolean']>;
	summary_contains?: InputMaybe<Scalars['String']>;
	summary_exists?: InputMaybe<Scalars['Boolean']>;
	summary_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	text_contains?: InputMaybe<Scalars['String']>;
	text_exists?: InputMaybe<Scalars['Boolean']>;
	text_not_contains?: InputMaybe<Scalars['String']>;
	theme?: InputMaybe<Scalars['String']>;
	theme_contains?: InputMaybe<Scalars['String']>;
	theme_exists?: InputMaybe<Scalars['Boolean']>;
	theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	theme_not?: InputMaybe<Scalars['String']>;
	theme_not_contains?: InputMaybe<Scalars['String']>;
	theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	timeToRead?: InputMaybe<Scalars['Int']>;
	timeToRead_exists?: InputMaybe<Scalars['Boolean']>;
	timeToRead_gt?: InputMaybe<Scalars['Int']>;
	timeToRead_gte?: InputMaybe<Scalars['Int']>;
	timeToRead_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	timeToRead_lt?: InputMaybe<Scalars['Int']>;
	timeToRead_lte?: InputMaybe<Scalars['Int']>;
	timeToRead_not?: InputMaybe<Scalars['Int']>;
	timeToRead_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BlogDetailsPageLinkingCollections = {
	__typename?: 'BlogDetailsPageLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type BlogDetailsPageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogDetailsPageOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'date_ASC'
	| 'date_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'timeToRead_ASC'
	| 'timeToRead_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BlogDetailsPageSummary = {
	__typename?: 'BlogDetailsPageSummary';
	json: Scalars['JSON'];
	links: BlogDetailsPageSummaryLinks;
};

export type BlogDetailsPageSummaryAssets = {
	__typename?: 'BlogDetailsPageSummaryAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type BlogDetailsPageSummaryEntries = {
	__typename?: 'BlogDetailsPageSummaryEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type BlogDetailsPageSummaryLinks = {
	__typename?: 'BlogDetailsPageSummaryLinks';
	assets: BlogDetailsPageSummaryAssets;
	entries: BlogDetailsPageSummaryEntries;
	resources: BlogDetailsPageSummaryResources;
};

export type BlogDetailsPageSummaryResources = {
	__typename?: 'BlogDetailsPageSummaryResources';
	block: Array<BlogDetailsPageSummaryResourcesBlock>;
	hyperlink: Array<BlogDetailsPageSummaryResourcesHyperlink>;
	inline: Array<BlogDetailsPageSummaryResourcesInline>;
};

export type BlogDetailsPageSummaryResourcesBlock = ResourceLink & {
	__typename?: 'BlogDetailsPageSummaryResourcesBlock';
	sys: ResourceSys;
};

export type BlogDetailsPageSummaryResourcesHyperlink = ResourceLink & {
	__typename?: 'BlogDetailsPageSummaryResourcesHyperlink';
	sys: ResourceSys;
};

export type BlogDetailsPageSummaryResourcesInline = ResourceLink & {
	__typename?: 'BlogDetailsPageSummaryResourcesInline';
	sys: ResourceSys;
};

export type BlogDetailsPageText = {
	__typename?: 'BlogDetailsPageText';
	json: Scalars['JSON'];
	links: BlogDetailsPageTextLinks;
};

export type BlogDetailsPageTextAssets = {
	__typename?: 'BlogDetailsPageTextAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type BlogDetailsPageTextEntries = {
	__typename?: 'BlogDetailsPageTextEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type BlogDetailsPageTextLinks = {
	__typename?: 'BlogDetailsPageTextLinks';
	assets: BlogDetailsPageTextAssets;
	entries: BlogDetailsPageTextEntries;
	resources: BlogDetailsPageTextResources;
};

export type BlogDetailsPageTextResources = {
	__typename?: 'BlogDetailsPageTextResources';
	block: Array<BlogDetailsPageTextResourcesBlock>;
	hyperlink: Array<BlogDetailsPageTextResourcesHyperlink>;
	inline: Array<BlogDetailsPageTextResourcesInline>;
};

export type BlogDetailsPageTextResourcesBlock = ResourceLink & {
	__typename?: 'BlogDetailsPageTextResourcesBlock';
	sys: ResourceSys;
};

export type BlogDetailsPageTextResourcesHyperlink = ResourceLink & {
	__typename?: 'BlogDetailsPageTextResourcesHyperlink';
	sys: ResourceSys;
};

export type BlogDetailsPageTextResourcesInline = ResourceLink & {
	__typename?: 'BlogDetailsPageTextResourcesInline';
	sys: ResourceSys;
};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogList = Entry &
	_Node & {
		__typename?: 'BlogList';
		_id: Scalars['ID'];
		brand?: Maybe<Brand>;
		buttonLabel?: Maybe<Scalars['String']>;
		category?: Maybe<BlogCategory>;
		contentfulMetadata: ContentfulMetadata;
		displayType?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<BlogListLinkingCollections>;
		loadMoreButtonLabel?: Maybe<Scalars['String']>;
		name?: Maybe<Scalars['String']>;
		subCategory?: Maybe<BlogSubCategory>;
		sys: Sys;
	};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogListBrandArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BrandFilter>;
};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogListButtonLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogListCategoryArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BlogCategoryFilter>;
};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogListDisplayTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogListLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogListLoadMoreButtonLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogListNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List content type which includes the title for the list, number of blogs, brand, category etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogList) */
export type BlogListSubCategoryArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BlogSubCategoryFilter>;
};

export type BlogListCollection = {
	__typename?: 'BlogListCollection';
	items: Array<Maybe<BlogList>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BlogListFilter = {
	AND?: InputMaybe<Array<InputMaybe<BlogListFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BlogListFilter>>>;
	brand?: InputMaybe<CfBrandNestedFilter>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLabel?: InputMaybe<Scalars['String']>;
	buttonLabel_contains?: InputMaybe<Scalars['String']>;
	buttonLabel_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLabel_not?: InputMaybe<Scalars['String']>;
	buttonLabel_not_contains?: InputMaybe<Scalars['String']>;
	buttonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	category?: InputMaybe<CfBlogCategoryNestedFilter>;
	category_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	displayType?: InputMaybe<Scalars['String']>;
	displayType_contains?: InputMaybe<Scalars['String']>;
	displayType_exists?: InputMaybe<Scalars['Boolean']>;
	displayType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	displayType_not?: InputMaybe<Scalars['String']>;
	displayType_not_contains?: InputMaybe<Scalars['String']>;
	displayType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	loadMoreButtonLabel?: InputMaybe<Scalars['String']>;
	loadMoreButtonLabel_contains?: InputMaybe<Scalars['String']>;
	loadMoreButtonLabel_exists?: InputMaybe<Scalars['Boolean']>;
	loadMoreButtonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	loadMoreButtonLabel_not?: InputMaybe<Scalars['String']>;
	loadMoreButtonLabel_not_contains?: InputMaybe<Scalars['String']>;
	loadMoreButtonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subCategory?: InputMaybe<CfBlogSubCategoryNestedFilter>;
	subCategory_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type BlogListLinkingCollections = {
	__typename?: 'BlogListLinkingCollections';
	blogListPageCollection?: Maybe<BlogListPageCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type BlogListLinkingCollectionsBlogListPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogListLinkingCollectionsBlogListPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogListLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogListLinkingCollectionsBlogListPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BlogListOrder =
	| 'buttonLabel_ASC'
	| 'buttonLabel_DESC'
	| 'displayType_ASC'
	| 'displayType_DESC'
	| 'loadMoreButtonLabel_ASC'
	| 'loadMoreButtonLabel_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPage = Entry &
	_Node & {
		__typename?: 'BlogListPage';
		_id: Scalars['ID'];
		blogList?: Maybe<BlogList>;
		brand?: Maybe<Brand>;
		canonical?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		indexed?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<BlogListPageLinkingCollections>;
		metaDescription?: Maybe<Scalars['String']>;
		metaTitle?: Maybe<Scalars['String']>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageBlogListArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BlogListFilter>;
};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageBrandArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BrandFilter>;
};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageCanonicalArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageIndexedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageMetaDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageMetaTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog List Page to display the list of blogs for an app with other contents like Title, Hero etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogListPage) */
export type BlogListPageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type BlogListPageCollection = {
	__typename?: 'BlogListPageCollection';
	items: Array<Maybe<BlogListPage>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BlogListPageFilter = {
	AND?: InputMaybe<Array<InputMaybe<BlogListPageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BlogListPageFilter>>>;
	blogList?: InputMaybe<CfBlogListNestedFilter>;
	blogList_exists?: InputMaybe<Scalars['Boolean']>;
	brand?: InputMaybe<CfBrandNestedFilter>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	canonical?: InputMaybe<Scalars['String']>;
	canonical_contains?: InputMaybe<Scalars['String']>;
	canonical_exists?: InputMaybe<Scalars['Boolean']>;
	canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical_not?: InputMaybe<Scalars['String']>;
	canonical_not_contains?: InputMaybe<Scalars['String']>;
	canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	indexed?: InputMaybe<Scalars['Boolean']>;
	indexed_exists?: InputMaybe<Scalars['Boolean']>;
	indexed_not?: InputMaybe<Scalars['Boolean']>;
	metaDescription?: InputMaybe<Scalars['String']>;
	metaDescription_contains?: InputMaybe<Scalars['String']>;
	metaDescription_exists?: InputMaybe<Scalars['Boolean']>;
	metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaDescription_not?: InputMaybe<Scalars['String']>;
	metaDescription_not_contains?: InputMaybe<Scalars['String']>;
	metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaTitle?: InputMaybe<Scalars['String']>;
	metaTitle_contains?: InputMaybe<Scalars['String']>;
	metaTitle_exists?: InputMaybe<Scalars['Boolean']>;
	metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	metaTitle_not?: InputMaybe<Scalars['String']>;
	metaTitle_not_contains?: InputMaybe<Scalars['String']>;
	metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BlogListPageLinkingCollections = {
	__typename?: 'BlogListPageLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type BlogListPageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogListPageOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** Blog Sub Category [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogSubCategory) */
export type BlogSubCategory = Entry &
	_Node & {
		__typename?: 'BlogSubCategory';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<BlogSubCategoryLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		slug?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** Blog Sub Category [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogSubCategory) */
export type BlogSubCategoryLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Blog Sub Category [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogSubCategory) */
export type BlogSubCategoryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Blog Sub Category [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/blogSubCategory) */
export type BlogSubCategorySlugArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type BlogSubCategoryCollection = {
	__typename?: 'BlogSubCategoryCollection';
	items: Array<Maybe<BlogSubCategory>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BlogSubCategoryFilter = {
	AND?: InputMaybe<Array<InputMaybe<BlogSubCategoryFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BlogSubCategoryFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	slug?: InputMaybe<Scalars['String']>;
	slug_contains?: InputMaybe<Scalars['String']>;
	slug_exists?: InputMaybe<Scalars['Boolean']>;
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	slug_not?: InputMaybe<Scalars['String']>;
	slug_not_contains?: InputMaybe<Scalars['String']>;
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type BlogSubCategoryLinkingCollections = {
	__typename?: 'BlogSubCategoryLinkingCollections';
	blogDetailsPageCollection?: Maybe<BlogDetailsPageCollection>;
	blogListCollection?: Maybe<BlogListCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type BlogSubCategoryLinkingCollectionsBlogDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogSubCategoryLinkingCollectionsBlogDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogSubCategoryLinkingCollectionsBlogListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogSubCategoryLinkingCollectionsBlogListCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogSubCategoryLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BlogSubCategoryLinkingCollectionsBlogDetailsPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'date_ASC'
	| 'date_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'timeToRead_ASC'
	| 'timeToRead_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BlogSubCategoryLinkingCollectionsBlogListCollectionOrder =
	| 'buttonLabel_ASC'
	| 'buttonLabel_DESC'
	| 'displayType_ASC'
	| 'displayType_DESC'
	| 'loadMoreButtonLabel_ASC'
	| 'loadMoreButtonLabel_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type BlogSubCategoryOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'slug_ASC'
	| 'slug_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Brands within The Aspen Group [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/brand) */
export type Brand = Entry &
	_Node & {
		__typename?: 'Brand';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<BrandLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** Brands within The Aspen Group [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/brand) */
export type BrandLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Brands within The Aspen Group [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/brand) */
export type BrandNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Brands within The Aspen Group [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/brand) */
export type BrandTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type BrandCollection = {
	__typename?: 'BrandCollection';
	items: Array<Maybe<Brand>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type BrandFilter = {
	AND?: InputMaybe<Array<InputMaybe<BrandFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<BrandFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BrandLinkingCollections = {
	__typename?: 'BrandLinkingCollections';
	appointmentBookingPageCollection?: Maybe<AppointmentBookingPageCollection>;
	blogDetailsPageCollection?: Maybe<BlogDetailsPageCollection>;
	blogListCollection?: Maybe<BlogListCollection>;
	blogListPageCollection?: Maybe<BlogListPageCollection>;
	entryCollection?: Maybe<EntryCollection>;
	productCollection?: Maybe<ProductCollection>;
	quizCollection?: Maybe<QuizCollection>;
	reusableContentCollection?: Maybe<ReusableContentCollection>;
};

export type BrandLinkingCollectionsAppointmentBookingPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BrandLinkingCollectionsAppointmentBookingPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BrandLinkingCollectionsBlogDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BrandLinkingCollectionsBlogDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BrandLinkingCollectionsBlogListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BrandLinkingCollectionsBlogListCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BrandLinkingCollectionsBlogListPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BrandLinkingCollectionsBlogListPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BrandLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BrandLinkingCollectionsProductCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BrandLinkingCollectionsProductCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BrandLinkingCollectionsQuizCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BrandLinkingCollectionsQuizCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BrandLinkingCollectionsReusableContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BrandLinkingCollectionsReusableContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type BrandLinkingCollectionsAppointmentBookingPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BrandLinkingCollectionsBlogDetailsPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'date_ASC'
	| 'date_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'timeToRead_ASC'
	| 'timeToRead_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BrandLinkingCollectionsBlogListCollectionOrder =
	| 'buttonLabel_ASC'
	| 'buttonLabel_DESC'
	| 'displayType_ASC'
	| 'displayType_DESC'
	| 'loadMoreButtonLabel_ASC'
	| 'loadMoreButtonLabel_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type BrandLinkingCollectionsBlogListPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type BrandLinkingCollectionsProductCollectionOrder =
	| 'brandfolderImageAssetUrl_ASC'
	| 'brandfolderImageAssetUrl_DESC'
	| 'category_ASC'
	| 'category_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'id_ASC'
	| 'id_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subCategory_ASC'
	| 'subCategory_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type BrandLinkingCollectionsQuizCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'quizType_ASC'
	| 'quizType_DESC'
	| 'submitEndpointUrl_ASC'
	| 'submitEndpointUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type BrandLinkingCollectionsReusableContentCollectionOrder =
	| 'component_ASC'
	| 'component_DESC'
	| 'disclaimer_ASC'
	| 'disclaimer_DESC'
	| 'mainTitle_ASC'
	| 'mainTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'textValue_ASC'
	| 'textValue_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type BrandOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type Button = Entry &
	_Node & {
		__typename?: 'Button';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		buttonLink?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		icon?: Maybe<Scalars['String']>;
		iconPosition?: Maybe<Scalars['String']>;
		iconSize?: Maybe<Scalars['String']>;
		isLocalized?: Maybe<Scalars['Boolean']>;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ButtonLinkingCollections>;
		mobileButton?: Maybe<MobileButton>;
		name?: Maybe<Scalars['String']>;
		navigationDetails?: Maybe<NavigationLink>;
		quizDetails?: Maybe<Quiz>;
		quizSelector?: Maybe<Scalars['String']>;
		size?: Maybe<Scalars['String']>;
		sys: Sys;
		variant?: Maybe<Scalars['String']>;
	};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonButtonLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonIconPositionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonIconSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonIsLocalizedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonMobileButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<MobileButtonFilter>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonNavigationDetailsArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<NavigationLinkFilter>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonQuizDetailsArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<QuizFilter>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonQuizSelectorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/button) */
export type ButtonVariantArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ButtonCollection = {
	__typename?: 'ButtonCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ButtonFilter = {
	AND?: InputMaybe<Array<InputMaybe<ButtonFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ButtonFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLink?: InputMaybe<Scalars['String']>;
	buttonLink_contains?: InputMaybe<Scalars['String']>;
	buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLink_not?: InputMaybe<Scalars['String']>;
	buttonLink_not_contains?: InputMaybe<Scalars['String']>;
	buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	icon?: InputMaybe<Scalars['String']>;
	iconPosition?: InputMaybe<Scalars['String']>;
	iconPosition_contains?: InputMaybe<Scalars['String']>;
	iconPosition_exists?: InputMaybe<Scalars['Boolean']>;
	iconPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconPosition_not?: InputMaybe<Scalars['String']>;
	iconPosition_not_contains?: InputMaybe<Scalars['String']>;
	iconPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconSize?: InputMaybe<Scalars['String']>;
	iconSize_contains?: InputMaybe<Scalars['String']>;
	iconSize_exists?: InputMaybe<Scalars['Boolean']>;
	iconSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconSize_not?: InputMaybe<Scalars['String']>;
	iconSize_not_contains?: InputMaybe<Scalars['String']>;
	iconSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	isLocalized?: InputMaybe<Scalars['Boolean']>;
	isLocalized_exists?: InputMaybe<Scalars['Boolean']>;
	isLocalized_not?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileButton?: InputMaybe<CfMobileButtonNestedFilter>;
	mobileButton_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationDetails?: InputMaybe<CfNavigationLinkNestedFilter>;
	navigationDetails_exists?: InputMaybe<Scalars['Boolean']>;
	quizDetails?: InputMaybe<CfQuizNestedFilter>;
	quizDetails_exists?: InputMaybe<Scalars['Boolean']>;
	quizSelector?: InputMaybe<Scalars['String']>;
	quizSelector_contains?: InputMaybe<Scalars['String']>;
	quizSelector_exists?: InputMaybe<Scalars['Boolean']>;
	quizSelector_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quizSelector_not?: InputMaybe<Scalars['String']>;
	quizSelector_not_contains?: InputMaybe<Scalars['String']>;
	quizSelector_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size?: InputMaybe<Scalars['String']>;
	size_contains?: InputMaybe<Scalars['String']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size_not?: InputMaybe<Scalars['String']>;
	size_not_contains?: InputMaybe<Scalars['String']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	variant?: InputMaybe<Scalars['String']>;
	variant_contains?: InputMaybe<Scalars['String']>;
	variant_exists?: InputMaybe<Scalars['Boolean']>;
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	variant_not?: InputMaybe<Scalars['String']>;
	variant_not_contains?: InputMaybe<Scalars['String']>;
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ButtonLinkingCollections = {
	__typename?: 'ButtonLinkingCollections';
	accordionsCollection?: Maybe<AccordionsCollection>;
	adFooterCollection?: Maybe<AdFooterCollection>;
	cardCollection?: Maybe<CardCollection>;
	categoryPickerCollection?: Maybe<CategoryPickerCollection>;
	contentTypeRichTextCollection?: Maybe<ContentTypeRichTextCollection>;
	entryCollection?: Maybe<EntryCollection>;
	footerV2Collection?: Maybe<FooterV2Collection>;
	googleMapTemplateCollection?: Maybe<GoogleMapTemplateCollection>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	multiCardV2Collection?: Maybe<MultiCardV2Collection>;
	odpHeroCollection?: Maybe<OdpHeroCollection>;
	patientReviewsV2Collection?: Maybe<PatientReviewsV2Collection>;
	productFeatureCollection?: Maybe<ProductFeatureCollection>;
	quickLinksCollection?: Maybe<QuickLinksCollection>;
	serviceCollection?: Maybe<ServiceCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
	singleMessageCollection?: Maybe<SingleMessageCollection>;
	videoCarouselCollection?: Maybe<VideoCarouselCollection>;
	videoWithContentCollection?: Maybe<VideoWithContentCollection>;
};

export type ButtonLinkingCollectionsAccordionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsAccordionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsAdFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsAdFooterCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsCardCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsCategoryPickerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsCategoryPickerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsContentTypeRichTextCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsContentTypeRichTextCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsFooterV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsFooterV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsGoogleMapTemplateCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsGoogleMapTemplateCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsHalfScreenCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsHeroV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsMultiCardV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsMultiCardV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsOdpHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsOdpHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsPatientReviewsV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsPatientReviewsV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsProductFeatureCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsProductFeatureCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsQuickLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsQuickLinksCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsServiceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsServiceCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsSingleMessageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsSingleMessageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsVideoCarouselCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsVideoCarouselCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsVideoWithContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsVideoWithContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsAccordionsCollectionOrder =
	| 'accordionsHeading_ASC'
	| 'accordionsHeading_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonCollectionAlignment_ASC'
	| 'buttonCollectionAlignment_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'expandIconPosition_ASC'
	| 'expandIconPosition_DESC'
	| 'expandIconType_ASC'
	| 'expandIconType_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundedCorner_ASC'
	| 'imageRoundedCorner_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'roundedCornersSize_ASC'
	| 'roundedCornersSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleAlignment_ASC'
	| 'titleAlignment_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'withInnerGap_ASC'
	| 'withInnerGap_DESC';

export type ButtonLinkingCollectionsAdFooterCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'isLightFooter_ASC'
	| 'isLightFooter_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useMobileStickyFooter_ASC'
	| 'useMobileStickyFooter_DESC';

export type ButtonLinkingCollectionsCardCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ButtonLinkingCollectionsCategoryPickerCollectionOrder =
	| 'eyebrowHeading_ASC'
	| 'eyebrowHeading_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'mobileThreshold_ASC'
	| 'mobileThreshold_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'setTwoRows_ASC'
	| 'setTwoRows_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'transparentCategories_ASC'
	| 'transparentCategories_DESC';

export type ButtonLinkingCollectionsContentTypeRichTextCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'contained_ASC'
	| 'contained_DESC'
	| 'contentMaxWidth_ASC'
	| 'contentMaxWidth_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'id_ASC'
	| 'id_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ButtonLinkingCollectionsFooterV2CollectionOrder =
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ButtonLinkingCollectionsGoogleMapTemplateCollectionOrder =
	| 'desktopZoom_ASC'
	| 'desktopZoom_DESC'
	| 'facilityBadgeLabel_ASC'
	| 'facilityBadgeLabel_DESC'
	| 'facilityBasePath_ASC'
	| 'facilityBasePath_DESC'
	| 'facilityButtonLabel_ASC'
	| 'facilityButtonLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'holidayHoursMessageText_ASC'
	| 'holidayHoursMessageText_DESC'
	| 'listTabIsHorizontalScrolling_ASC'
	| 'listTabIsHorizontalScrolling_DESC'
	| 'locationScheduleButtonUrl_ASC'
	| 'locationScheduleButtonUrl_DESC'
	| 'mobileZoom_ASC'
	| 'mobileZoom_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'searchRadius_ASC'
	| 'searchRadius_DESC'
	| 'searchResultLimit_ASC'
	| 'searchResultLimit_DESC'
	| 'service_ASC'
	| 'service_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'useUsaCountryMap_ASC'
	| 'useUsaCountryMap_DESC';

export type ButtonLinkingCollectionsHalfScreenCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'hideContentHalf_ASC'
	| 'hideContentHalf_DESC'
	| 'isInsetLayout_ASC'
	| 'isInsetLayout_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC'
	| 'useStaticImageSize_ASC'
	| 'useStaticImageSize_DESC';

export type ButtonLinkingCollectionsHeroV2CollectionOrder =
	| 'backgroundColorSmallScreen_ASC'
	| 'backgroundColorSmallScreen_DESC'
	| 'bodySize_ASC'
	| 'bodySize_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentPositionSmallScreen_ASC'
	| 'contentPositionSmallScreen_DESC'
	| 'contentPosition_ASC'
	| 'contentPosition_DESC'
	| 'contentWidth_ASC'
	| 'contentWidth_DESC'
	| 'darkOverlay_ASC'
	| 'darkOverlay_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'headingSize_ASC'
	| 'headingSize_DESC'
	| 'heightStyle_ASC'
	| 'heightStyle_DESC'
	| 'mediaAspectRatioSmallScreen_ASC'
	| 'mediaAspectRatioSmallScreen_DESC'
	| 'promoHeadlineSize_ASC'
	| 'promoHeadlineSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type ButtonLinkingCollectionsMultiCardV2CollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'bigScreenBackground_ASC'
	| 'bigScreenBackground_DESC'
	| 'cardsAlignment_ASC'
	| 'cardsAlignment_DESC'
	| 'displayAsStepper_ASC'
	| 'displayAsStepper_DESC'
	| 'enableMobileCarouselNavigation_ASC'
	| 'enableMobileCarouselNavigation_DESC'
	| 'enableResourceCardMode_ASC'
	| 'enableResourceCardMode_DESC'
	| 'eyebrowColor_ASC'
	| 'eyebrowColor_DESC'
	| 'eyebrowSx_ASC'
	| 'eyebrowSx_DESC'
	| 'eyebrowTextTransform_ASC'
	| 'eyebrowTextTransform_DESC'
	| 'fixBackgroundToBottom_ASC'
	| 'fixBackgroundToBottom_DESC'
	| 'forMobile_ASC'
	| 'forMobile_DESC'
	| 'headerSpacing_ASC'
	| 'headerSpacing_DESC'
	| 'lastRowCardsAlignment_ASC'
	| 'lastRowCardsAlignment_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'numberOfCardsInLineDesktop_ASC'
	| 'numberOfCardsInLineDesktop_DESC'
	| 'roundedImage_ASC'
	| 'roundedImage_DESC'
	| 'sectionEyebrow_ASC'
	| 'sectionEyebrow_DESC'
	| 'sectionInfoAlignment_ASC'
	| 'sectionInfoAlignment_DESC'
	| 'sectionTitleHtag_ASC'
	| 'sectionTitleHtag_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ButtonLinkingCollectionsOdpHeroCollectionOrder =
	| 'appointmentDataSource_ASC'
	| 'appointmentDataSource_DESC'
	| 'callCenterLabel_ASC'
	| 'callCenterLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'officeHoursLabel_ASC'
	| 'officeHoursLabel_DESC'
	| 'promoTitle_ASC'
	| 'promoTitle_DESC'
	| 'showAddress_ASC'
	| 'showAddress_DESC'
	| 'showEmail_ASC'
	| 'showEmail_DESC'
	| 'showFax_ASC'
	| 'showFax_DESC'
	| 'showHours_ASC'
	| 'showHours_DESC'
	| 'showLocationStatusBadge_ASC'
	| 'showLocationStatusBadge_DESC'
	| 'showPhoneNumber_ASC'
	| 'showPhoneNumber_DESC'
	| 'showReviews_ASC'
	| 'showReviews_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ButtonLinkingCollectionsPatientReviewsV2CollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowLargeScreens_ASC'
	| 'eyebrowLargeScreens_DESC'
	| 'eyebrowSmallScreens_ASC'
	| 'eyebrowSmallScreens_DESC'
	| 'maxReviewsLargeScreens_ASC'
	| 'maxReviewsLargeScreens_DESC'
	| 'maxReviewsSmallScreens_ASC'
	| 'maxReviewsSmallScreens_DESC'
	| 'mode_ASC'
	| 'mode_DESC'
	| 'scoreIcon_ASC'
	| 'scoreIcon_DESC'
	| 'subtitleLargeScreens_ASC'
	| 'subtitleLargeScreens_DESC'
	| 'subtitleSmallScreens_ASC'
	| 'subtitleSmallScreens_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'titleLargeScreens_ASC'
	| 'titleLargeScreens_DESC'
	| 'titleSmallScreens_ASC'
	| 'titleSmallScreens_DESC'
	| 'trimThreshold_ASC'
	| 'trimThreshold_DESC';

export type ButtonLinkingCollectionsProductFeatureCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ButtonLinkingCollectionsQuickLinksCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'displayAsTitles_ASC'
	| 'displayAsTitles_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC';

export type ButtonLinkingCollectionsServiceCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'contained_ASC'
	| 'contained_DESC'
	| 'direction_ASC'
	| 'direction_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageLayout_ASC'
	| 'imageLayout_DESC'
	| 'mediaType_ASC'
	| 'mediaType_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'youTubeVideoId_ASC'
	| 'youTubeVideoId_DESC';

export type ButtonLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type ButtonLinkingCollectionsSingleMessageCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentFillVerticalSpace_ASC'
	| 'contentFillVerticalSpace_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isBannerImage_ASC'
	| 'isBannerImage_DESC'
	| 'isInlineLayout_ASC'
	| 'isInlineLayout_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subText_ASC'
	| 'subText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateHeight_ASC'
	| 'templateHeight_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleVariant_ASC'
	| 'titleVariant_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'useRoundedCorners_ASC'
	| 'useRoundedCorners_DESC';

export type ButtonLinkingCollectionsVideoCarouselCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'desktopVideosAspectRatio_ASC'
	| 'desktopVideosAspectRatio_DESC'
	| 'mobileVideosAspectRatio_ASC'
	| 'mobileVideosAspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ButtonLinkingCollectionsVideoWithContentCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ButtonOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/callToAction) */
export type CallToAction = Entry &
	_Node & {
		__typename?: 'CallToAction';
		_id: Scalars['ID'];
		buttonLink?: Maybe<Scalars['String']>;
		buttonText?: Maybe<Scalars['String']>;
		buttonType?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		downloadFile?: Maybe<Asset>;
		linkedFrom?: Maybe<CallToActionLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/callToAction) */
export type CallToActionButtonLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/callToAction) */
export type CallToActionButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/callToAction) */
export type CallToActionButtonTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/callToAction) */
export type CallToActionDownloadFileArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/callToAction) */
export type CallToActionLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/callToAction) */
export type CallToActionNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/callToAction) */
export type CallToActionTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type CallToActionCollection = {
	__typename?: 'CallToActionCollection';
	items: Array<Maybe<CallToAction>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CallToActionFilter = {
	AND?: InputMaybe<Array<InputMaybe<CallToActionFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CallToActionFilter>>>;
	buttonLink?: InputMaybe<Scalars['String']>;
	buttonLink_contains?: InputMaybe<Scalars['String']>;
	buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLink_not?: InputMaybe<Scalars['String']>;
	buttonLink_not_contains?: InputMaybe<Scalars['String']>;
	buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType?: InputMaybe<Scalars['String']>;
	buttonType_contains?: InputMaybe<Scalars['String']>;
	buttonType_exists?: InputMaybe<Scalars['Boolean']>;
	buttonType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType_not?: InputMaybe<Scalars['String']>;
	buttonType_not_contains?: InputMaybe<Scalars['String']>;
	buttonType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	downloadFile_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CallToActionLinkingCollections = {
	__typename?: 'CallToActionLinkingCollections';
	adFooterCollection?: Maybe<AdFooterCollection>;
	entryCollection?: Maybe<EntryCollection>;
	headerCollection?: Maybe<HeaderCollection>;
	locationStepCollection?: Maybe<LocationStepCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type CallToActionLinkingCollectionsAdFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsAdFooterCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CallToActionLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CallToActionLinkingCollectionsHeaderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsHeaderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CallToActionLinkingCollectionsLocationStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsLocationStepCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CallToActionLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CallToActionLinkingCollectionsAdFooterCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'isLightFooter_ASC'
	| 'isLightFooter_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useMobileStickyFooter_ASC'
	| 'useMobileStickyFooter_DESC';

export type CallToActionLinkingCollectionsHeaderCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'darkHeader_ASC'
	| 'darkHeader_DESC'
	| 'hideFullSiteSearch_ASC'
	| 'hideFullSiteSearch_DESC'
	| 'hideHamburgerMenuToggleButton_ASC'
	| 'hideHamburgerMenuToggleButton_DESC'
	| 'locationBarFindOfficeUrl_ASC'
	| 'locationBarFindOfficeUrl_DESC'
	| 'locationBarWithOfficeTitle_ASC'
	| 'locationBarWithOfficeTitle_DESC'
	| 'locationBarWithOutOfficeTitle_ASC'
	| 'locationBarWithOutOfficeTitle_DESC'
	| 'myAccountLabel_ASC'
	| 'myAccountLabel_DESC'
	| 'profileLinkUrl_ASC'
	| 'profileLinkUrl_DESC'
	| 'schedulingButtonTextMobile_ASC'
	| 'schedulingButtonTextMobile_DESC'
	| 'schedulingButtonText_ASC'
	| 'schedulingButtonText_DESC'
	| 'schedulingButtonUrlMobile_ASC'
	| 'schedulingButtonUrlMobile_DESC'
	| 'schedulingButtonUrl_ASC'
	| 'schedulingButtonUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useHeaderLocationCards_ASC'
	| 'useHeaderLocationCards_DESC'
	| 'useNewLayout_ASC'
	| 'useNewLayout_DESC'
	| 'usePopupScheduling_ASC'
	| 'usePopupScheduling_DESC';

export type CallToActionLinkingCollectionsLocationStepCollectionOrder =
	| 'currentLocationLabel_ASC'
	| 'currentLocationLabel_DESC'
	| 'inputLabel_ASC'
	| 'inputLabel_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CallToActionLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CallToActionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CancelTimeslotReservationError = NotAvailableSlotError;

export type CancelTimeslotReservationInput = {
	scheduleSlotUid: Scalars['NonEmptyString'];
};

export type CancelTimeslotReservationPayload = {
	__typename?: 'CancelTimeslotReservationPayload';
	errors?: Maybe<Array<CancelTimeslotReservationError>>;
	result?: Maybe<CanceledTimeslotReservation>;
};

export type CancelVisitError = NonExistingVisitError | VisitNotCancelableError;

export type CancelVisitInput = {
	visitId: Scalars['String'];
};

export type CancelVisitPayload = {
	__typename?: 'CancelVisitPayload';
	canceledVisit?: Maybe<CanceledVisit>;
	errors?: Maybe<Array<CancelVisitError>>;
};

export type CanceledTimeslotReservation = {
	__typename?: 'CanceledTimeslotReservation';
	scheduleSlotUid: Scalars['String'];
};

export type CanceledVisit = {
	__typename?: 'CanceledVisit';
	visitId: Scalars['String'];
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type Card = Entry &
	_Node & {
		__typename?: 'Card';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		backgroundColor?: Maybe<Scalars['String']>;
		buttonIcon?: Maybe<Scalars['String']>;
		buttonIconPosition?: Maybe<Scalars['String']>;
		buttonIconSize?: Maybe<Scalars['String']>;
		buttonSize?: Maybe<Scalars['String']>;
		buttonText?: Maybe<Scalars['String']>;
		buttonUrl?: Maybe<Scalars['String']>;
		buttonVariant?: Maybe<Scalars['String']>;
		buttonsCollection?: Maybe<CardButtonsCollection>;
		cardIcon?: Maybe<Scalars['String']>;
		cardIconAlignment?: Maybe<Scalars['String']>;
		cardTextAlignment?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		eventType?: Maybe<Scalars['String']>;
		eyebrow?: Maybe<Scalars['String']>;
		focalPointImage?: Maybe<FocalPointImage>;
		image?: Maybe<Asset>;
		imageAsset?: Maybe<ImageAsset>;
		imageRatio?: Maybe<Scalars['String']>;
		imageUrl?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<CardLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		pdfDownloadTitle?: Maybe<Scalars['String']>;
		pdfFileLink?: Maybe<Asset>;
		priority?: Maybe<Scalars['Int']>;
		richTextDescription?: Maybe<CardRichTextDescription>;
		showBorder?: Maybe<Scalars['Boolean']>;
		slot?: Maybe<Scalars['Int']>;
		stepName?: Maybe<Scalars['String']>;
		sys: Sys;
		tc?: Maybe<TermsAndConditions>;
		tcText?: Maybe<Scalars['String']>;
		tcUrl?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardButtonIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardButtonIconPositionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardButtonIconSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardButtonSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardButtonVariantArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CardButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardCardIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardCardIconAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardCardTextAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardEventTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardFocalPointImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardImageAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardImageRatioArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardImageUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardPdfDownloadTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardPdfFileLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardPriorityArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardRichTextDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardShowBorderArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardSlotArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardStepNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardTcArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<TermsAndConditionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardTcTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardTcUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/card) */
export type CardTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type CardButtonsCollection = {
	__typename?: 'CardButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CardButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type CardCollection = {
	__typename?: 'CardCollection';
	items: Array<Maybe<Card>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CardFilter = {
	AND?: InputMaybe<Array<InputMaybe<CardFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CardFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIcon?: InputMaybe<Scalars['String']>;
	buttonIconPosition?: InputMaybe<Scalars['String']>;
	buttonIconPosition_contains?: InputMaybe<Scalars['String']>;
	buttonIconPosition_exists?: InputMaybe<Scalars['Boolean']>;
	buttonIconPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIconPosition_not?: InputMaybe<Scalars['String']>;
	buttonIconPosition_not_contains?: InputMaybe<Scalars['String']>;
	buttonIconPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIconSize?: InputMaybe<Scalars['String']>;
	buttonIconSize_contains?: InputMaybe<Scalars['String']>;
	buttonIconSize_exists?: InputMaybe<Scalars['Boolean']>;
	buttonIconSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIconSize_not?: InputMaybe<Scalars['String']>;
	buttonIconSize_not_contains?: InputMaybe<Scalars['String']>;
	buttonIconSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIcon_contains?: InputMaybe<Scalars['String']>;
	buttonIcon_exists?: InputMaybe<Scalars['Boolean']>;
	buttonIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIcon_not?: InputMaybe<Scalars['String']>;
	buttonIcon_not_contains?: InputMaybe<Scalars['String']>;
	buttonIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonSize?: InputMaybe<Scalars['String']>;
	buttonSize_contains?: InputMaybe<Scalars['String']>;
	buttonSize_exists?: InputMaybe<Scalars['Boolean']>;
	buttonSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonSize_not?: InputMaybe<Scalars['String']>;
	buttonSize_not_contains?: InputMaybe<Scalars['String']>;
	buttonSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonVariant?: InputMaybe<Scalars['String']>;
	buttonVariant_contains?: InputMaybe<Scalars['String']>;
	buttonVariant_exists?: InputMaybe<Scalars['Boolean']>;
	buttonVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonVariant_not?: InputMaybe<Scalars['String']>;
	buttonVariant_not_contains?: InputMaybe<Scalars['String']>;
	buttonVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	cardIcon?: InputMaybe<Scalars['String']>;
	cardIconAlignment?: InputMaybe<Scalars['String']>;
	cardIconAlignment_contains?: InputMaybe<Scalars['String']>;
	cardIconAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	cardIconAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardIconAlignment_not?: InputMaybe<Scalars['String']>;
	cardIconAlignment_not_contains?: InputMaybe<Scalars['String']>;
	cardIconAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardIcon_contains?: InputMaybe<Scalars['String']>;
	cardIcon_exists?: InputMaybe<Scalars['Boolean']>;
	cardIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardIcon_not?: InputMaybe<Scalars['String']>;
	cardIcon_not_contains?: InputMaybe<Scalars['String']>;
	cardIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardTextAlignment?: InputMaybe<Scalars['String']>;
	cardTextAlignment_contains?: InputMaybe<Scalars['String']>;
	cardTextAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	cardTextAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardTextAlignment_not?: InputMaybe<Scalars['String']>;
	cardTextAlignment_not_contains?: InputMaybe<Scalars['String']>;
	cardTextAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eventType?: InputMaybe<Scalars['String']>;
	eventType_contains?: InputMaybe<Scalars['String']>;
	eventType_exists?: InputMaybe<Scalars['Boolean']>;
	eventType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eventType_not?: InputMaybe<Scalars['String']>;
	eventType_not_contains?: InputMaybe<Scalars['String']>;
	eventType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImage?: InputMaybe<CfFocalPointImageNestedFilter>;
	focalPointImage_exists?: InputMaybe<Scalars['Boolean']>;
	imageAsset?: InputMaybe<CfImageAssetNestedFilter>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	imageRatio?: InputMaybe<Scalars['String']>;
	imageRatio_contains?: InputMaybe<Scalars['String']>;
	imageRatio_exists?: InputMaybe<Scalars['Boolean']>;
	imageRatio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageRatio_not?: InputMaybe<Scalars['String']>;
	imageRatio_not_contains?: InputMaybe<Scalars['String']>;
	imageRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageUrl?: InputMaybe<Scalars['String']>;
	imageUrl_contains?: InputMaybe<Scalars['String']>;
	imageUrl_exists?: InputMaybe<Scalars['Boolean']>;
	imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageUrl_not?: InputMaybe<Scalars['String']>;
	imageUrl_not_contains?: InputMaybe<Scalars['String']>;
	imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pdfDownloadTitle?: InputMaybe<Scalars['String']>;
	pdfDownloadTitle_contains?: InputMaybe<Scalars['String']>;
	pdfDownloadTitle_exists?: InputMaybe<Scalars['Boolean']>;
	pdfDownloadTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pdfDownloadTitle_not?: InputMaybe<Scalars['String']>;
	pdfDownloadTitle_not_contains?: InputMaybe<Scalars['String']>;
	pdfDownloadTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pdfFileLink_exists?: InputMaybe<Scalars['Boolean']>;
	priority?: InputMaybe<Scalars['Int']>;
	priority_exists?: InputMaybe<Scalars['Boolean']>;
	priority_gt?: InputMaybe<Scalars['Int']>;
	priority_gte?: InputMaybe<Scalars['Int']>;
	priority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	priority_lt?: InputMaybe<Scalars['Int']>;
	priority_lte?: InputMaybe<Scalars['Int']>;
	priority_not?: InputMaybe<Scalars['Int']>;
	priority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	richTextDescription_contains?: InputMaybe<Scalars['String']>;
	richTextDescription_exists?: InputMaybe<Scalars['Boolean']>;
	richTextDescription_not_contains?: InputMaybe<Scalars['String']>;
	showBorder?: InputMaybe<Scalars['Boolean']>;
	showBorder_exists?: InputMaybe<Scalars['Boolean']>;
	showBorder_not?: InputMaybe<Scalars['Boolean']>;
	slot?: InputMaybe<Scalars['Int']>;
	slot_exists?: InputMaybe<Scalars['Boolean']>;
	slot_gt?: InputMaybe<Scalars['Int']>;
	slot_gte?: InputMaybe<Scalars['Int']>;
	slot_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	slot_lt?: InputMaybe<Scalars['Int']>;
	slot_lte?: InputMaybe<Scalars['Int']>;
	slot_not?: InputMaybe<Scalars['Int']>;
	slot_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	stepName?: InputMaybe<Scalars['String']>;
	stepName_contains?: InputMaybe<Scalars['String']>;
	stepName_exists?: InputMaybe<Scalars['Boolean']>;
	stepName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepName_not?: InputMaybe<Scalars['String']>;
	stepName_not_contains?: InputMaybe<Scalars['String']>;
	stepName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tc?: InputMaybe<CfTermsAndConditionsNestedFilter>;
	tcText?: InputMaybe<Scalars['String']>;
	tcText_contains?: InputMaybe<Scalars['String']>;
	tcText_exists?: InputMaybe<Scalars['Boolean']>;
	tcText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcText_not?: InputMaybe<Scalars['String']>;
	tcText_not_contains?: InputMaybe<Scalars['String']>;
	tcText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcUrl?: InputMaybe<Scalars['String']>;
	tcUrl_contains?: InputMaybe<Scalars['String']>;
	tcUrl_exists?: InputMaybe<Scalars['Boolean']>;
	tcUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcUrl_not?: InputMaybe<Scalars['String']>;
	tcUrl_not_contains?: InputMaybe<Scalars['String']>;
	tcUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tc_exists?: InputMaybe<Scalars['Boolean']>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CardLinkingCollections = {
	__typename?: 'CardLinkingCollections';
	carouselCollection?: Maybe<CarouselCollection>;
	entryCollection?: Maybe<EntryCollection>;
	multiCardCollection?: Maybe<MultiCardCollection>;
	multiCardV2Collection?: Maybe<MultiCardV2Collection>;
	promotionsAndOfferingCollection?: Maybe<PromotionsAndOfferingCollection>;
	promotionsCollection?: Maybe<PromotionsCollection>;
};

export type CardLinkingCollectionsCarouselCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CardLinkingCollectionsCarouselCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CardLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CardLinkingCollectionsMultiCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CardLinkingCollectionsMultiCardCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CardLinkingCollectionsMultiCardV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CardLinkingCollectionsMultiCardV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CardLinkingCollectionsPromotionsAndOfferingCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CardLinkingCollectionsPromotionsAndOfferingCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CardLinkingCollectionsPromotionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CardLinkingCollectionsPromotionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CardLinkingCollectionsCarouselCollectionOrder =
	| 'arrowsColor_ASC'
	| 'arrowsColor_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRounded_ASC'
	| 'imageRounded_DESC'
	| 'imageWidth_ASC'
	| 'imageWidth_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quotesColor_ASC'
	| 'quotesColor_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CardLinkingCollectionsMultiCardCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'bigScreenBackground_ASC'
	| 'bigScreenBackground_DESC'
	| 'cardsAlignment_ASC'
	| 'cardsAlignment_DESC'
	| 'forMobile_ASC'
	| 'forMobile_DESC'
	| 'lastRowCardsAlignment_ASC'
	| 'lastRowCardsAlignment_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'roundedImage_ASC'
	| 'roundedImage_DESC'
	| 'sectionEyebrow_ASC'
	| 'sectionEyebrow_DESC'
	| 'sectionInfoAlignment_ASC'
	| 'sectionInfoAlignment_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type CardLinkingCollectionsMultiCardV2CollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'bigScreenBackground_ASC'
	| 'bigScreenBackground_DESC'
	| 'cardsAlignment_ASC'
	| 'cardsAlignment_DESC'
	| 'displayAsStepper_ASC'
	| 'displayAsStepper_DESC'
	| 'enableMobileCarouselNavigation_ASC'
	| 'enableMobileCarouselNavigation_DESC'
	| 'enableResourceCardMode_ASC'
	| 'enableResourceCardMode_DESC'
	| 'eyebrowColor_ASC'
	| 'eyebrowColor_DESC'
	| 'eyebrowSx_ASC'
	| 'eyebrowSx_DESC'
	| 'eyebrowTextTransform_ASC'
	| 'eyebrowTextTransform_DESC'
	| 'fixBackgroundToBottom_ASC'
	| 'fixBackgroundToBottom_DESC'
	| 'forMobile_ASC'
	| 'forMobile_DESC'
	| 'headerSpacing_ASC'
	| 'headerSpacing_DESC'
	| 'lastRowCardsAlignment_ASC'
	| 'lastRowCardsAlignment_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'numberOfCardsInLineDesktop_ASC'
	| 'numberOfCardsInLineDesktop_DESC'
	| 'roundedImage_ASC'
	| 'roundedImage_DESC'
	| 'sectionEyebrow_ASC'
	| 'sectionEyebrow_DESC'
	| 'sectionInfoAlignment_ASC'
	| 'sectionInfoAlignment_DESC'
	| 'sectionTitleHtag_ASC'
	| 'sectionTitleHtag_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type CardLinkingCollectionsPromotionsAndOfferingCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type CardLinkingCollectionsPromotionsCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type CardOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CardRichTextDescription = {
	__typename?: 'CardRichTextDescription';
	json: Scalars['JSON'];
	links: CardRichTextDescriptionLinks;
};

export type CardRichTextDescriptionAssets = {
	__typename?: 'CardRichTextDescriptionAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type CardRichTextDescriptionEntries = {
	__typename?: 'CardRichTextDescriptionEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type CardRichTextDescriptionLinks = {
	__typename?: 'CardRichTextDescriptionLinks';
	assets: CardRichTextDescriptionAssets;
	entries: CardRichTextDescriptionEntries;
	resources: CardRichTextDescriptionResources;
};

export type CardRichTextDescriptionResources = {
	__typename?: 'CardRichTextDescriptionResources';
	block: Array<CardRichTextDescriptionResourcesBlock>;
	hyperlink: Array<CardRichTextDescriptionResourcesHyperlink>;
	inline: Array<CardRichTextDescriptionResourcesInline>;
};

export type CardRichTextDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'CardRichTextDescriptionResourcesBlock';
	sys: ResourceSys;
};

export type CardRichTextDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'CardRichTextDescriptionResourcesHyperlink';
	sys: ResourceSys;
};

export type CardRichTextDescriptionResourcesInline = ResourceLink & {
	__typename?: 'CardRichTextDescriptionResourcesInline';
	sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type Carousel = Entry &
	_Node & {
		__typename?: 'Carousel';
		_id: Scalars['ID'];
		arrowsColor?: Maybe<Scalars['String']>;
		backgroundColor?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		eyebrow?: Maybe<Scalars['String']>;
		imageRounded?: Maybe<Scalars['String']>;
		imageWidth?: Maybe<Scalars['Int']>;
		linkedFrom?: Maybe<CarouselLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		quotesColor?: Maybe<Scalars['String']>;
		rotationContentCollection?: Maybe<CarouselRotationContentCollection>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselArrowsColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselImageRoundedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselImageWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselQuotesColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselRotationContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CarouselRotationContentFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/carousel) */
export type CarouselTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type CarouselCollection = {
	__typename?: 'CarouselCollection';
	items: Array<Maybe<Carousel>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CarouselFilter = {
	AND?: InputMaybe<Array<InputMaybe<CarouselFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CarouselFilter>>>;
	arrowsColor?: InputMaybe<Scalars['String']>;
	arrowsColor_contains?: InputMaybe<Scalars['String']>;
	arrowsColor_exists?: InputMaybe<Scalars['Boolean']>;
	arrowsColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	arrowsColor_not?: InputMaybe<Scalars['String']>;
	arrowsColor_not_contains?: InputMaybe<Scalars['String']>;
	arrowsColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageRounded?: InputMaybe<Scalars['String']>;
	imageRounded_contains?: InputMaybe<Scalars['String']>;
	imageRounded_exists?: InputMaybe<Scalars['Boolean']>;
	imageRounded_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageRounded_not?: InputMaybe<Scalars['String']>;
	imageRounded_not_contains?: InputMaybe<Scalars['String']>;
	imageRounded_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageWidth?: InputMaybe<Scalars['Int']>;
	imageWidth_exists?: InputMaybe<Scalars['Boolean']>;
	imageWidth_gt?: InputMaybe<Scalars['Int']>;
	imageWidth_gte?: InputMaybe<Scalars['Int']>;
	imageWidth_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	imageWidth_lt?: InputMaybe<Scalars['Int']>;
	imageWidth_lte?: InputMaybe<Scalars['Int']>;
	imageWidth_not?: InputMaybe<Scalars['Int']>;
	imageWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quotesColor?: InputMaybe<Scalars['String']>;
	quotesColor_contains?: InputMaybe<Scalars['String']>;
	quotesColor_exists?: InputMaybe<Scalars['Boolean']>;
	quotesColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quotesColor_not?: InputMaybe<Scalars['String']>;
	quotesColor_not_contains?: InputMaybe<Scalars['String']>;
	quotesColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	rotationContent?: InputMaybe<CfrotationContentMultiTypeNestedFilter>;
	rotationContentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarouselLinkingCollections = {
	__typename?: 'CarouselLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type CarouselLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CarouselLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CarouselLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CarouselLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CarouselOrder =
	| 'arrowsColor_ASC'
	| 'arrowsColor_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRounded_ASC'
	| 'imageRounded_DESC'
	| 'imageWidth_ASC'
	| 'imageWidth_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quotesColor_ASC'
	| 'quotesColor_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CarouselRotationContentCollection = {
	__typename?: 'CarouselRotationContentCollection';
	items: Array<Maybe<CarouselRotationContentItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CarouselRotationContentFilter = {
	AND?: InputMaybe<Array<InputMaybe<CarouselRotationContentFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CarouselRotationContentFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tc_exists?: InputMaybe<Scalars['Boolean']>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarouselRotationContentItem = Card | Service | SingleMessage;

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type Category = Entry &
	_Node & {
		__typename?: 'Category';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		contentfulMetadata: ContentfulMetadata;
		icon?: Maybe<Scalars['String']>;
		imageAsset?: Maybe<ImageAsset>;
		isLocalized?: Maybe<Scalars['Boolean']>;
		link?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<CategoryLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type CategoryAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type CategoryIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type CategoryImageAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type CategoryIsLocalizedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type CategoryLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type CategoryLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type CategoryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A Category for the Categories Array within the Category Picker [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/category) */
export type CategoryTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type CategoryCollection = {
	__typename?: 'CategoryCollection';
	items: Array<Maybe<Category>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CategoryFilter = {
	AND?: InputMaybe<Array<InputMaybe<CategoryFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CategoryFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	icon?: InputMaybe<Scalars['String']>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAsset?: InputMaybe<CfImageAssetNestedFilter>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	isLocalized?: InputMaybe<Scalars['Boolean']>;
	isLocalized_exists?: InputMaybe<Scalars['Boolean']>;
	isLocalized_not?: InputMaybe<Scalars['Boolean']>;
	link?: InputMaybe<Scalars['String']>;
	link_contains?: InputMaybe<Scalars['String']>;
	link_exists?: InputMaybe<Scalars['Boolean']>;
	link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	link_not?: InputMaybe<Scalars['String']>;
	link_not_contains?: InputMaybe<Scalars['String']>;
	link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CategoryLinkingCollections = {
	__typename?: 'CategoryLinkingCollections';
	categoryPickerCollection?: Maybe<CategoryPickerCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type CategoryLinkingCollectionsCategoryPickerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryLinkingCollectionsCategoryPickerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CategoryLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CategoryLinkingCollectionsCategoryPickerCollectionOrder =
	| 'eyebrowHeading_ASC'
	| 'eyebrowHeading_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'mobileThreshold_ASC'
	| 'mobileThreshold_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'setTwoRows_ASC'
	| 'setTwoRows_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'transparentCategories_ASC'
	| 'transparentCategories_DESC';

export type CategoryOrder =
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'link_ASC'
	| 'link_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPicker = Entry &
	_Node & {
		__typename?: 'CategoryPicker';
		_id: Scalars['ID'];
		buttonsCollection?: Maybe<CategoryPickerButtonsCollection>;
		categoriesCollection?: Maybe<CategoryPickerCategoriesCollection>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<ContentTypeRichText>;
		eyebrow?: Maybe<Scalars['String']>;
		eyebrowHeading?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<CategoryPickerLinkingCollections>;
		mobileThreshold?: Maybe<Scalars['Int']>;
		name?: Maybe<Scalars['String']>;
		setTwoRows?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleHeading?: Maybe<Scalars['String']>;
		transparentCategories?: Maybe<Scalars['Boolean']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryPickerButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerCategoriesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryPickerCategoriesCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CategoryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerEyebrowHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerMobileThresholdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerSetTwoRowsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerTitleHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/categoryPicker) */
export type CategoryPickerTransparentCategoriesArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type CategoryPickerButtonsCollection = {
	__typename?: 'CategoryPickerButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CategoryPickerButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type CategoryPickerCategoriesCollection = {
	__typename?: 'CategoryPickerCategoriesCollection';
	items: Array<Maybe<Category>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CategoryPickerCategoriesCollectionOrder =
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'link_ASC'
	| 'link_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CategoryPickerCollection = {
	__typename?: 'CategoryPickerCollection';
	items: Array<Maybe<CategoryPicker>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CategoryPickerFilter = {
	AND?: InputMaybe<Array<InputMaybe<CategoryPickerFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CategoryPickerFilter>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	categories?: InputMaybe<CfCategoryNestedFilter>;
	categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrowHeading?: InputMaybe<Scalars['String']>;
	eyebrowHeading_contains?: InputMaybe<Scalars['String']>;
	eyebrowHeading_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowHeading_not?: InputMaybe<Scalars['String']>;
	eyebrowHeading_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileThreshold?: InputMaybe<Scalars['Int']>;
	mobileThreshold_exists?: InputMaybe<Scalars['Boolean']>;
	mobileThreshold_gt?: InputMaybe<Scalars['Int']>;
	mobileThreshold_gte?: InputMaybe<Scalars['Int']>;
	mobileThreshold_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	mobileThreshold_lt?: InputMaybe<Scalars['Int']>;
	mobileThreshold_lte?: InputMaybe<Scalars['Int']>;
	mobileThreshold_not?: InputMaybe<Scalars['Int']>;
	mobileThreshold_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	setTwoRows?: InputMaybe<Scalars['Boolean']>;
	setTwoRows_exists?: InputMaybe<Scalars['Boolean']>;
	setTwoRows_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHeading?: InputMaybe<Scalars['String']>;
	titleHeading_contains?: InputMaybe<Scalars['String']>;
	titleHeading_exists?: InputMaybe<Scalars['Boolean']>;
	titleHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHeading_not?: InputMaybe<Scalars['String']>;
	titleHeading_not_contains?: InputMaybe<Scalars['String']>;
	titleHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	transparentCategories?: InputMaybe<Scalars['Boolean']>;
	transparentCategories_exists?: InputMaybe<Scalars['Boolean']>;
	transparentCategories_not?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryPickerLinkingCollections = {
	__typename?: 'CategoryPickerLinkingCollections';
	appointmentBookingPageCollection?: Maybe<AppointmentBookingPageCollection>;
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type CategoryPickerLinkingCollectionsAppointmentBookingPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryPickerLinkingCollectionsAppointmentBookingPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CategoryPickerLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CategoryPickerLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryPickerLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CategoryPickerLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryPickerLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CategoryPickerLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryPickerLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CategoryPickerLinkingCollectionsAppointmentBookingPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CategoryPickerLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CategoryPickerLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CategoryPickerLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CategoryPickerOrder =
	| 'eyebrowHeading_ASC'
	| 'eyebrowHeading_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'mobileThreshold_ASC'
	| 'mobileThreshold_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'setTwoRows_ASC'
	| 'setTwoRows_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'transparentCategories_ASC'
	| 'transparentCategories_DESC';

export type CitiesFilterInput = {
	/** ISO 3166-1 alpha-2 code as described at: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements. Currently US only supported. */
	countryISOCode?: InputMaybe<Scalars['String']>;
	zipCode?: InputMaybe<Scalars['NonEmptyString']>;
};

export type CitiesList = {
	__typename?: 'CitiesList';
	items: Array<City>;
};

export type City = {
	__typename?: 'City';
	cityUid: Scalars['String'];
	/** ISO 3166-1 alpha-2 code as described at: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements */
	countryIsoCode: Scalars['String'];
	name: Scalars['String'];
	/** ISO 3166-2 code as described at: https://en.wikipedia.org/wiki/ISO_3166-2 */
	stateIsoCode?: Maybe<Scalars['String']>;
	zipCode: Scalars['String'];
};

/** A container component for city links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cityLinks) */
export type CityLinks = Entry &
	_Node & {
		__typename?: 'CityLinks';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		groupByState?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<CityLinksLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sectionTitle?: Maybe<Scalars['String']>;
		sys: Sys;
		useDisplayName?: Maybe<Scalars['Boolean']>;
	};

/** A container component for city links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cityLinks) */
export type CityLinksGroupByStateArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A container component for city links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cityLinks) */
export type CityLinksLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A container component for city links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cityLinks) */
export type CityLinksNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A container component for city links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cityLinks) */
export type CityLinksSectionTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A container component for city links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cityLinks) */
export type CityLinksUseDisplayNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type CityLinksCollection = {
	__typename?: 'CityLinksCollection';
	items: Array<Maybe<CityLinks>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CityLinksFilter = {
	AND?: InputMaybe<Array<InputMaybe<CityLinksFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CityLinksFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	groupByState?: InputMaybe<Scalars['Boolean']>;
	groupByState_exists?: InputMaybe<Scalars['Boolean']>;
	groupByState_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle?: InputMaybe<Scalars['String']>;
	sectionTitle_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_exists?: InputMaybe<Scalars['Boolean']>;
	sectionTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle_not?: InputMaybe<Scalars['String']>;
	sectionTitle_not_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	useDisplayName?: InputMaybe<Scalars['Boolean']>;
	useDisplayName_exists?: InputMaybe<Scalars['Boolean']>;
	useDisplayName_not?: InputMaybe<Scalars['Boolean']>;
};

export type CityLinksLinkingCollections = {
	__typename?: 'CityLinksLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type CityLinksLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CityLinksLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CityLinksLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CityLinksLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CityLinksOrder =
	| 'groupByState_ASC'
	| 'groupByState_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useDisplayName_ASC'
	| 'useDisplayName_DESC';

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type Columns = Entry &
	_Node & {
		__typename?: 'Columns';
		_id: Scalars['ID'];
		allowEqualHeights?: Maybe<Scalars['Boolean']>;
		backgroundColor?: Maybe<Scalars['String']>;
		columnsCollection?: Maybe<ColumnsColumnsCollection>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		eyebrow?: Maybe<Scalars['String']>;
		fullWidth?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<ColumnsLinkingCollections>;
		mobileDisplayAccordion?: Maybe<Scalars['Boolean']>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
	};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsAllowEqualHeightsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsColumnsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ColumnsColumnsFilter>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsFullWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsMobileDisplayAccordionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Create a multi-column experience. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/columns) */
export type ColumnsTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ColumnsCollection = {
	__typename?: 'ColumnsCollection';
	items: Array<Maybe<Columns>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ColumnsColumnsCollection = {
	__typename?: 'ColumnsColumnsCollection';
	items: Array<Maybe<ColumnsColumnsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ColumnsColumnsFilter = {
	AND?: InputMaybe<Array<InputMaybe<ColumnsColumnsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ColumnsColumnsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type ColumnsColumnsItem = Accordions | ContentTypeRichText | SchemaStep | SingleMessage;

export type ColumnsFilter = {
	AND?: InputMaybe<Array<InputMaybe<ColumnsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ColumnsFilter>>>;
	allowEqualHeights?: InputMaybe<Scalars['Boolean']>;
	allowEqualHeights_exists?: InputMaybe<Scalars['Boolean']>;
	allowEqualHeights_not?: InputMaybe<Scalars['Boolean']>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	columns?: InputMaybe<CfcolumnsMultiTypeNestedFilter>;
	columnsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	mobileDisplayAccordion?: InputMaybe<Scalars['Boolean']>;
	mobileDisplayAccordion_exists?: InputMaybe<Scalars['Boolean']>;
	mobileDisplayAccordion_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ColumnsLinkingCollections = {
	__typename?: 'ColumnsLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type ColumnsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ColumnsLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ColumnsLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ColumnsLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ColumnsLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ColumnsLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ColumnsLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ColumnsLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ColumnsLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ColumnsLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ColumnsOrder =
	| 'allowEqualHeights_ASC'
	| 'allowEqualHeights_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'mobileDisplayAccordion_ASC'
	| 'mobileDisplayAccordion_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/compare) */
export type Compare = Entry &
	_Node & {
		__typename?: 'Compare';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		contentsCollection?: Maybe<CompareContentsCollection>;
		linkedFrom?: Maybe<CompareLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		tabs?: Maybe<Tabs>;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/compare) */
export type CompareContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CompareContentsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TableFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/compare) */
export type CompareLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/compare) */
export type CompareNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/compare) */
export type CompareTabsArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<TabsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/compare) */
export type CompareTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type CompareCollection = {
	__typename?: 'CompareCollection';
	items: Array<Maybe<Compare>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CompareContentsCollection = {
	__typename?: 'CompareContentsCollection';
	items: Array<Maybe<Table>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CompareContentsCollectionOrder =
	| 'border_ASC'
	| 'border_DESC'
	| 'firstColumnSticky_ASC'
	| 'firstColumnSticky_DESC'
	| 'fontSize_ASC'
	| 'fontSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type CompareFilter = {
	AND?: InputMaybe<Array<InputMaybe<CompareFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CompareFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contents?: InputMaybe<CfTableNestedFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tabs?: InputMaybe<CfTabsNestedFilter>;
	tabs_exists?: InputMaybe<Scalars['Boolean']>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CompareLinkingCollections = {
	__typename?: 'CompareLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type CompareLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CompareLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CompareLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CompareLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CompareOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ConfirmPatientVisitError = NonConfirmablePastVisitError | NonExistingVisitError;

export type ConfirmPatientVisitInput = {
	visitId: Scalars['NonEmptyString'];
};

export type ConfirmPatientVisitPayload = {
	__typename?: 'ConfirmPatientVisitPayload';
	errors?: Maybe<Array<ConfirmPatientVisitError>>;
	result?: Maybe<ConfirmedPatientVisit>;
};

export type ConfirmVisitError =
	| MutationError
	| NonConfirmablePastVisitError
	| NonExistingVisitError
	| TemporaryUnavailableError;

export type ConfirmVisitInput = {
	digiactNumber: Scalars['NonEmptyString'];
	facilityCode: Scalars['NonEmptyString'];
	scheduledVisitId: Scalars['NonEmptyString'];
};

export type ConfirmVisitPayload = {
	__typename?: 'ConfirmVisitPayload';
	confirmedVisit?: Maybe<ConfirmedVisit>;
	errors?: Maybe<Array<ConfirmVisitError>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStep = Entry &
	_Node & {
		__typename?: 'ConfirmationStep';
		_id: Scalars['ID'];
		accountNumberText?: Maybe<Scalars['String']>;
		cardInfoText?: Maybe<Scalars['String']>;
		confirmationPageName?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		copyTextButtonText?: Maybe<Scalars['String']>;
		footerText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ConfirmationStepLinkingCollections>;
		stepNumber?: Maybe<Scalars['Int']>;
		successCardsCollection?: Maybe<ConfirmationStepSuccessCardsCollection>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStepAccountNumberTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStepCardInfoTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStepConfirmationPageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStepCopyTextButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStepFooterTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStepLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStepStepNumberArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/confirmationStep) */
export type ConfirmationStepSuccessCardsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ConfirmationStepSuccessCardsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SuccessCardFilter>;
};

export type ConfirmationStepCollection = {
	__typename?: 'ConfirmationStepCollection';
	items: Array<Maybe<ConfirmationStep>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ConfirmationStepFilter = {
	AND?: InputMaybe<Array<InputMaybe<ConfirmationStepFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ConfirmationStepFilter>>>;
	accountNumberText?: InputMaybe<Scalars['String']>;
	accountNumberText_contains?: InputMaybe<Scalars['String']>;
	accountNumberText_exists?: InputMaybe<Scalars['Boolean']>;
	accountNumberText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	accountNumberText_not?: InputMaybe<Scalars['String']>;
	accountNumberText_not_contains?: InputMaybe<Scalars['String']>;
	accountNumberText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardInfoText?: InputMaybe<Scalars['String']>;
	cardInfoText_contains?: InputMaybe<Scalars['String']>;
	cardInfoText_exists?: InputMaybe<Scalars['Boolean']>;
	cardInfoText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardInfoText_not?: InputMaybe<Scalars['String']>;
	cardInfoText_not_contains?: InputMaybe<Scalars['String']>;
	cardInfoText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	confirmationPageName?: InputMaybe<Scalars['String']>;
	confirmationPageName_contains?: InputMaybe<Scalars['String']>;
	confirmationPageName_exists?: InputMaybe<Scalars['Boolean']>;
	confirmationPageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	confirmationPageName_not?: InputMaybe<Scalars['String']>;
	confirmationPageName_not_contains?: InputMaybe<Scalars['String']>;
	confirmationPageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	copyTextButtonText?: InputMaybe<Scalars['String']>;
	copyTextButtonText_contains?: InputMaybe<Scalars['String']>;
	copyTextButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	copyTextButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	copyTextButtonText_not?: InputMaybe<Scalars['String']>;
	copyTextButtonText_not_contains?: InputMaybe<Scalars['String']>;
	copyTextButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	footerText?: InputMaybe<Scalars['String']>;
	footerText_contains?: InputMaybe<Scalars['String']>;
	footerText_exists?: InputMaybe<Scalars['Boolean']>;
	footerText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	footerText_not?: InputMaybe<Scalars['String']>;
	footerText_not_contains?: InputMaybe<Scalars['String']>;
	footerText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepNumber?: InputMaybe<Scalars['Int']>;
	stepNumber_exists?: InputMaybe<Scalars['Boolean']>;
	stepNumber_gt?: InputMaybe<Scalars['Int']>;
	stepNumber_gte?: InputMaybe<Scalars['Int']>;
	stepNumber_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	stepNumber_lt?: InputMaybe<Scalars['Int']>;
	stepNumber_lte?: InputMaybe<Scalars['Int']>;
	stepNumber_not?: InputMaybe<Scalars['Int']>;
	stepNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	successCards?: InputMaybe<CfSuccessCardNestedFilter>;
	successCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type ConfirmationStepLinkingCollections = {
	__typename?: 'ConfirmationStepLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	stepperCollection?: Maybe<StepperCollection>;
};

export type ConfirmationStepLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ConfirmationStepLinkingCollectionsStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ConfirmationStepLinkingCollectionsStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ConfirmationStepLinkingCollectionsStepperCollectionOrder =
	| 'confirmButtonText_ASC'
	| 'confirmButtonText_DESC'
	| 'continueButtonText_ASC'
	| 'continueButtonText_DESC'
	| 'headingScheduledText_ASC'
	| 'headingScheduledText_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'needAssistanceText_ASC'
	| 'needAssistanceText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ConfirmationStepOrder =
	| 'accountNumberText_ASC'
	| 'accountNumberText_DESC'
	| 'cardInfoText_ASC'
	| 'cardInfoText_DESC'
	| 'confirmationPageName_ASC'
	| 'confirmationPageName_DESC'
	| 'copyTextButtonText_ASC'
	| 'copyTextButtonText_DESC'
	| 'stepNumber_ASC'
	| 'stepNumber_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ConfirmationStepSuccessCardsCollection = {
	__typename?: 'ConfirmationStepSuccessCardsCollection';
	items: Array<Maybe<SuccessCard>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ConfirmationStepSuccessCardsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'imageAltText_ASC'
	| 'imageAltText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleText_ASC'
	| 'titleText_DESC';

export type ConfirmedPatientVisit = {
	__typename?: 'ConfirmedPatientVisit';
	accountNumber: Scalars['String'];
	facility?: Maybe<Facility>;
	scheduledDateTime?: Maybe<Scalars['DateTime']>;
	visitId: Scalars['String'];
};

export type ConfirmedVisit = {
	__typename?: 'ConfirmedVisit';
	facility?: Maybe<Facility>;
	firstName?: Maybe<Scalars['String']>;
	scheduledDateTime?: Maybe<Scalars['DateTime']>;
	visitId: Scalars['String'];
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSection = Entry &
	_Node & {
		__typename?: 'ContentSection';
		_id: Scalars['ID'];
		alignment?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<ContentSectionDescription>;
		eyebrow?: Maybe<Scalars['String']>;
		eyebrowHtag?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ContentSectionLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
		titleIcon?: Maybe<Scalars['String']>;
	};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionEyebrowHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for common section fields like eyebrow, title, description and choosing heading levels [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentSection) */
export type ContentSectionTitleIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ContentSectionCollection = {
	__typename?: 'ContentSectionCollection';
	items: Array<Maybe<ContentSection>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ContentSectionDescription = {
	__typename?: 'ContentSectionDescription';
	json: Scalars['JSON'];
	links: ContentSectionDescriptionLinks;
};

export type ContentSectionDescriptionAssets = {
	__typename?: 'ContentSectionDescriptionAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ContentSectionDescriptionEntries = {
	__typename?: 'ContentSectionDescriptionEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ContentSectionDescriptionLinks = {
	__typename?: 'ContentSectionDescriptionLinks';
	assets: ContentSectionDescriptionAssets;
	entries: ContentSectionDescriptionEntries;
	resources: ContentSectionDescriptionResources;
};

export type ContentSectionDescriptionResources = {
	__typename?: 'ContentSectionDescriptionResources';
	block: Array<ContentSectionDescriptionResourcesBlock>;
	hyperlink: Array<ContentSectionDescriptionResourcesHyperlink>;
	inline: Array<ContentSectionDescriptionResourcesInline>;
};

export type ContentSectionDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'ContentSectionDescriptionResourcesBlock';
	sys: ResourceSys;
};

export type ContentSectionDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'ContentSectionDescriptionResourcesHyperlink';
	sys: ResourceSys;
};

export type ContentSectionDescriptionResourcesInline = ResourceLink & {
	__typename?: 'ContentSectionDescriptionResourcesInline';
	sys: ResourceSys;
};

export type ContentSectionFilter = {
	AND?: InputMaybe<Array<InputMaybe<ContentSectionFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ContentSectionFilter>>>;
	alignment?: InputMaybe<Scalars['String']>;
	alignment_contains?: InputMaybe<Scalars['String']>;
	alignment_exists?: InputMaybe<Scalars['Boolean']>;
	alignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	alignment_not?: InputMaybe<Scalars['String']>;
	alignment_not_contains?: InputMaybe<Scalars['String']>;
	alignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrowHtag?: InputMaybe<Scalars['String']>;
	eyebrowHtag_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowHtag_not?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleIcon?: InputMaybe<Scalars['String']>;
	titleIcon_contains?: InputMaybe<Scalars['String']>;
	titleIcon_exists?: InputMaybe<Scalars['Boolean']>;
	titleIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleIcon_not?: InputMaybe<Scalars['String']>;
	titleIcon_not_contains?: InputMaybe<Scalars['String']>;
	titleIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentSectionLinkingCollections = {
	__typename?: 'ContentSectionLinkingCollections';
	accordionsCollection?: Maybe<AccordionsCollection>;
	entryCollection?: Maybe<EntryCollection>;
	videoCarouselCollection?: Maybe<VideoCarouselCollection>;
	videoWithContentCollection?: Maybe<VideoWithContentCollection>;
};

export type ContentSectionLinkingCollectionsAccordionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentSectionLinkingCollectionsAccordionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentSectionLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentSectionLinkingCollectionsVideoCarouselCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentSectionLinkingCollectionsVideoCarouselCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentSectionLinkingCollectionsVideoWithContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentSectionLinkingCollectionsVideoWithContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentSectionLinkingCollectionsAccordionsCollectionOrder =
	| 'accordionsHeading_ASC'
	| 'accordionsHeading_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonCollectionAlignment_ASC'
	| 'buttonCollectionAlignment_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'expandIconPosition_ASC'
	| 'expandIconPosition_DESC'
	| 'expandIconType_ASC'
	| 'expandIconType_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundedCorner_ASC'
	| 'imageRoundedCorner_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'roundedCornersSize_ASC'
	| 'roundedCornersSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleAlignment_ASC'
	| 'titleAlignment_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'withInnerGap_ASC'
	| 'withInnerGap_DESC';

export type ContentSectionLinkingCollectionsVideoCarouselCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'desktopVideosAspectRatio_ASC'
	| 'desktopVideosAspectRatio_DESC'
	| 'mobileVideosAspectRatio_ASC'
	| 'mobileVideosAspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentSectionLinkingCollectionsVideoWithContentCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentSectionOrder =
	| 'alignment_ASC'
	| 'alignment_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'titleIcon_ASC'
	| 'titleIcon_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** Content tile with an Image, Title and Description which is used to display in the Header Navigation Menu [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentTile) */
export type ContentTile = Entry &
	_Node & {
		__typename?: 'ContentTile';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		externalUrl?: Maybe<Scalars['String']>;
		image?: Maybe<Scalars['JSON']>;
		internalPage?: Maybe<Page>;
		linkedFrom?: Maybe<ContentTileLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** Content tile with an Image, Title and Description which is used to display in the Header Navigation Menu [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentTile) */
export type ContentTileDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content tile with an Image, Title and Description which is used to display in the Header Navigation Menu [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentTile) */
export type ContentTileExternalUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content tile with an Image, Title and Description which is used to display in the Header Navigation Menu [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentTile) */
export type ContentTileImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content tile with an Image, Title and Description which is used to display in the Header Navigation Menu [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentTile) */
export type ContentTileInternalPageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<PageFilter>;
};

/** Content tile with an Image, Title and Description which is used to display in the Header Navigation Menu [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentTile) */
export type ContentTileLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content tile with an Image, Title and Description which is used to display in the Header Navigation Menu [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentTile) */
export type ContentTileNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content tile with an Image, Title and Description which is used to display in the Header Navigation Menu [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/contentTile) */
export type ContentTileTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ContentTileCollection = {
	__typename?: 'ContentTileCollection';
	items: Array<Maybe<ContentTile>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ContentTileFilter = {
	AND?: InputMaybe<Array<InputMaybe<ContentTileFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ContentTileFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	externalUrl?: InputMaybe<Scalars['String']>;
	externalUrl_contains?: InputMaybe<Scalars['String']>;
	externalUrl_exists?: InputMaybe<Scalars['Boolean']>;
	externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	externalUrl_not?: InputMaybe<Scalars['String']>;
	externalUrl_not_contains?: InputMaybe<Scalars['String']>;
	externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	internalPage?: InputMaybe<CfPageNestedFilter>;
	internalPage_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentTileLinkingCollections = {
	__typename?: 'ContentTileLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	headerSubNavCollection?: Maybe<HeaderSubNavCollection>;
};

export type ContentTileLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTileLinkingCollectionsHeaderSubNavCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTileLinkingCollectionsHeaderSubNavCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTileLinkingCollectionsHeaderSubNavCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentTileOrder =
	| 'description_ASC'
	| 'description_DESC'
	| 'externalUrl_ASC'
	| 'externalUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichText = Entry &
	_Node & {
		__typename?: 'ContentTypeRichText';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		backgroundColor?: Maybe<Scalars['String']>;
		buttonsCollection?: Maybe<ContentTypeRichTextButtonsCollection>;
		centerAlign?: Maybe<Scalars['Boolean']>;
		contained?: Maybe<Scalars['Boolean']>;
		content?: Maybe<ContentTypeRichTextContent>;
		contentMaxWidth?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		fullWidth?: Maybe<Scalars['Boolean']>;
		id?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ContentTypeRichTextLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		secondaryContent?: Maybe<ContentTypeRichTextSecondaryContent>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleHeading?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextCenterAlignArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextContainedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextContentMaxWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextFacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextFullWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextSecondaryContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richText) */
export type ContentTypeRichTextTitleHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ContentTypeRichTextButtonsCollection = {
	__typename?: 'ContentTypeRichTextButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ContentTypeRichTextButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type ContentTypeRichTextCollection = {
	__typename?: 'ContentTypeRichTextCollection';
	items: Array<Maybe<ContentTypeRichText>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ContentTypeRichTextContent = {
	__typename?: 'ContentTypeRichTextContent';
	json: Scalars['JSON'];
	links: ContentTypeRichTextContentLinks;
};

export type ContentTypeRichTextContentAssets = {
	__typename?: 'ContentTypeRichTextContentAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ContentTypeRichTextContentEntries = {
	__typename?: 'ContentTypeRichTextContentEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ContentTypeRichTextContentLinks = {
	__typename?: 'ContentTypeRichTextContentLinks';
	assets: ContentTypeRichTextContentAssets;
	entries: ContentTypeRichTextContentEntries;
	resources: ContentTypeRichTextContentResources;
};

export type ContentTypeRichTextContentResources = {
	__typename?: 'ContentTypeRichTextContentResources';
	block: Array<ContentTypeRichTextContentResourcesBlock>;
	hyperlink: Array<ContentTypeRichTextContentResourcesHyperlink>;
	inline: Array<ContentTypeRichTextContentResourcesInline>;
};

export type ContentTypeRichTextContentResourcesBlock = ResourceLink & {
	__typename?: 'ContentTypeRichTextContentResourcesBlock';
	sys: ResourceSys;
};

export type ContentTypeRichTextContentResourcesHyperlink = ResourceLink & {
	__typename?: 'ContentTypeRichTextContentResourcesHyperlink';
	sys: ResourceSys;
};

export type ContentTypeRichTextContentResourcesInline = ResourceLink & {
	__typename?: 'ContentTypeRichTextContentResourcesInline';
	sys: ResourceSys;
};

export type ContentTypeRichTextFilter = {
	AND?: InputMaybe<Array<InputMaybe<ContentTypeRichTextFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ContentTypeRichTextFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign?: InputMaybe<Scalars['Boolean']>;
	centerAlign_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign_not?: InputMaybe<Scalars['Boolean']>;
	contained?: InputMaybe<Scalars['Boolean']>;
	contained_exists?: InputMaybe<Scalars['Boolean']>;
	contained_not?: InputMaybe<Scalars['Boolean']>;
	contentMaxWidth?: InputMaybe<Scalars['String']>;
	contentMaxWidth_contains?: InputMaybe<Scalars['String']>;
	contentMaxWidth_exists?: InputMaybe<Scalars['Boolean']>;
	contentMaxWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentMaxWidth_not?: InputMaybe<Scalars['String']>;
	contentMaxWidth_not_contains?: InputMaybe<Scalars['String']>;
	contentMaxWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	content_contains?: InputMaybe<Scalars['String']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	content_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['String']>;
	id_contains?: InputMaybe<Scalars['String']>;
	id_exists?: InputMaybe<Scalars['Boolean']>;
	id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_not?: InputMaybe<Scalars['String']>;
	id_not_contains?: InputMaybe<Scalars['String']>;
	id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	secondaryContent_contains?: InputMaybe<Scalars['String']>;
	secondaryContent_exists?: InputMaybe<Scalars['Boolean']>;
	secondaryContent_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHeading?: InputMaybe<Scalars['String']>;
	titleHeading_contains?: InputMaybe<Scalars['String']>;
	titleHeading_exists?: InputMaybe<Scalars['Boolean']>;
	titleHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHeading_not?: InputMaybe<Scalars['String']>;
	titleHeading_not_contains?: InputMaybe<Scalars['String']>;
	titleHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentTypeRichTextLinkingCollections = {
	__typename?: 'ContentTypeRichTextLinkingCollections';
	accordionsCollection?: Maybe<AccordionsCollection>;
	agendaModalCollection?: Maybe<AgendaModalCollection>;
	agendaStepperCollection?: Maybe<AgendaStepperCollection>;
	categoryPickerCollection?: Maybe<CategoryPickerCollection>;
	columnsCollection?: Maybe<ColumnsCollection>;
	disclaimerCollection?: Maybe<DisclaimerCollection>;
	disclaimerV2Collection?: Maybe<DisclaimerV2Collection>;
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	localizedServiceTitleCollection?: Maybe<LocalizedServiceTitleCollection>;
	nameValuesCollection?: Maybe<NameValuesCollection>;
	odpHeroCollection?: Maybe<OdpHeroCollection>;
	pageCollection?: Maybe<PageCollection>;
	productFeatureCollection?: Maybe<ProductFeatureCollection>;
	productFeatureDescriptionListItemCollection?: Maybe<ProductFeatureDescriptionListItemCollection>;
	richTextBlockSeoCollection?: Maybe<RichTextBlockSeoCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
};

export type ContentTypeRichTextLinkingCollectionsAccordionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsAccordionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsAgendaModalCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsAgendaModalCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsAgendaStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsAgendaStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsCategoryPickerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsCategoryPickerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsColumnsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsColumnsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsDisclaimerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsDisclaimerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsDisclaimerV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsDisclaimerV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsLocalizedServiceTitleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsLocalizedServiceTitleCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsNameValuesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsNameValuesCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsOdpHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsOdpHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsProductFeatureCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsProductFeatureCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsProductFeatureDescriptionListItemCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<
		Array<InputMaybe<ContentTypeRichTextLinkingCollectionsProductFeatureDescriptionListItemCollectionOrder>>
	>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsRichTextBlockSeoCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsRichTextBlockSeoCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeRichTextLinkingCollectionsAccordionsCollectionOrder =
	| 'accordionsHeading_ASC'
	| 'accordionsHeading_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonCollectionAlignment_ASC'
	| 'buttonCollectionAlignment_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'expandIconPosition_ASC'
	| 'expandIconPosition_DESC'
	| 'expandIconType_ASC'
	| 'expandIconType_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundedCorner_ASC'
	| 'imageRoundedCorner_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'roundedCornersSize_ASC'
	| 'roundedCornersSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleAlignment_ASC'
	| 'titleAlignment_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'withInnerGap_ASC'
	| 'withInnerGap_DESC';

export type ContentTypeRichTextLinkingCollectionsAgendaModalCollectionOrder =
	| 'headlineHeading_ASC'
	| 'headlineHeading_DESC'
	| 'headlineText_ASC'
	| 'headlineText_DESC'
	| 'modalName_ASC'
	| 'modalName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentTypeRichTextLinkingCollectionsAgendaStepperCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'hideStepperDot_ASC'
	| 'hideStepperDot_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ContentTypeRichTextLinkingCollectionsCategoryPickerCollectionOrder =
	| 'eyebrowHeading_ASC'
	| 'eyebrowHeading_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'mobileThreshold_ASC'
	| 'mobileThreshold_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'setTwoRows_ASC'
	| 'setTwoRows_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'transparentCategories_ASC'
	| 'transparentCategories_DESC';

export type ContentTypeRichTextLinkingCollectionsColumnsCollectionOrder =
	| 'allowEqualHeights_ASC'
	| 'allowEqualHeights_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'mobileDisplayAccordion_ASC'
	| 'mobileDisplayAccordion_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ContentTypeRichTextLinkingCollectionsDisclaimerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentTypeRichTextLinkingCollectionsDisclaimerV2CollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type ContentTypeRichTextLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ContentTypeRichTextLinkingCollectionsLocalizedServiceTitleCollectionOrder =
	| 'callCenterLabel_ASC'
	| 'callCenterLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'officeHoursLabel_ASC'
	| 'officeHoursLabel_DESC'
	| 'showAddress_ASC'
	| 'showAddress_DESC'
	| 'showHours_ASC'
	| 'showHours_DESC'
	| 'showPhoneNumber_ASC'
	| 'showPhoneNumber_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ContentTypeRichTextLinkingCollectionsNameValuesCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentTypeRichTextLinkingCollectionsOdpHeroCollectionOrder =
	| 'appointmentDataSource_ASC'
	| 'appointmentDataSource_DESC'
	| 'callCenterLabel_ASC'
	| 'callCenterLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'officeHoursLabel_ASC'
	| 'officeHoursLabel_DESC'
	| 'promoTitle_ASC'
	| 'promoTitle_DESC'
	| 'showAddress_ASC'
	| 'showAddress_DESC'
	| 'showEmail_ASC'
	| 'showEmail_DESC'
	| 'showFax_ASC'
	| 'showFax_DESC'
	| 'showHours_ASC'
	| 'showHours_DESC'
	| 'showLocationStatusBadge_ASC'
	| 'showLocationStatusBadge_DESC'
	| 'showPhoneNumber_ASC'
	| 'showPhoneNumber_DESC'
	| 'showReviews_ASC'
	| 'showReviews_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ContentTypeRichTextLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ContentTypeRichTextLinkingCollectionsProductFeatureCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentTypeRichTextLinkingCollectionsProductFeatureDescriptionListItemCollectionOrder =
	| 'icon_ASC'
	| 'icon_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentTypeRichTextLinkingCollectionsRichTextBlockSeoCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentTypeRichTextLinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ContentTypeRichTextLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ContentTypeRichTextLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type ContentTypeRichTextOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'contained_ASC'
	| 'contained_DESC'
	| 'contentMaxWidth_ASC'
	| 'contentMaxWidth_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'id_ASC'
	| 'id_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ContentTypeRichTextSecondaryContent = {
	__typename?: 'ContentTypeRichTextSecondaryContent';
	json: Scalars['JSON'];
	links: ContentTypeRichTextSecondaryContentLinks;
};

export type ContentTypeRichTextSecondaryContentAssets = {
	__typename?: 'ContentTypeRichTextSecondaryContentAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ContentTypeRichTextSecondaryContentEntries = {
	__typename?: 'ContentTypeRichTextSecondaryContentEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ContentTypeRichTextSecondaryContentLinks = {
	__typename?: 'ContentTypeRichTextSecondaryContentLinks';
	assets: ContentTypeRichTextSecondaryContentAssets;
	entries: ContentTypeRichTextSecondaryContentEntries;
	resources: ContentTypeRichTextSecondaryContentResources;
};

export type ContentTypeRichTextSecondaryContentResources = {
	__typename?: 'ContentTypeRichTextSecondaryContentResources';
	block: Array<ContentTypeRichTextSecondaryContentResourcesBlock>;
	hyperlink: Array<ContentTypeRichTextSecondaryContentResourcesHyperlink>;
	inline: Array<ContentTypeRichTextSecondaryContentResourcesInline>;
};

export type ContentTypeRichTextSecondaryContentResourcesBlock = ResourceLink & {
	__typename?: 'ContentTypeRichTextSecondaryContentResourcesBlock';
	sys: ResourceSys;
};

export type ContentTypeRichTextSecondaryContentResourcesHyperlink = ResourceLink & {
	__typename?: 'ContentTypeRichTextSecondaryContentResourcesHyperlink';
	sys: ResourceSys;
};

export type ContentTypeRichTextSecondaryContentResourcesInline = ResourceLink & {
	__typename?: 'ContentTypeRichTextSecondaryContentResourcesInline';
	sys: ResourceSys;
};

export type ContentfulMetadata = {
	__typename?: 'ContentfulMetadata';
	concepts: Array<Maybe<TaxonomyConcept>>;
	tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataConceptsDescendantsFilter = {
	id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentfulMetadataConceptsFilter = {
	descendants?: InputMaybe<ContentfulMetadataConceptsDescendantsFilter>;
	id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentfulMetadataFilter = {
	concepts?: InputMaybe<ContentfulMetadataConceptsFilter>;
	concepts_exists?: InputMaybe<Scalars['Boolean']>;
	tags?: InputMaybe<ContentfulMetadataTagsFilter>;
	tags_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ContentfulMetadataTagsFilter = {
	id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
	__typename?: 'ContentfulTag';
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cookie) */
export type Cookie = Entry &
	_Node & {
		__typename?: 'Cookie';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<CookieLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cookie) */
export type CookieDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cookie) */
export type CookieLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cookie) */
export type CookieNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/cookie) */
export type CookieTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type CookieCollection = {
	__typename?: 'CookieCollection';
	items: Array<Maybe<Cookie>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type CookieFilter = {
	AND?: InputMaybe<Array<InputMaybe<CookieFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CookieFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CookieLinkingCollections = {
	__typename?: 'CookieLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type CookieLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type CookieOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type CreateTimeslotReservationError = NotAvailableSlotError | TimeslotAlreadyReservedError;

export type CreateTimeslotReservationInput = {
	scheduleSlotUid: Scalars['NonEmptyString'];
};

export type CreateTimeslotReservationPayload = {
	__typename?: 'CreateTimeslotReservationPayload';
	errors?: Maybe<Array<CreateTimeslotReservationError>>;
	result?: Maybe<CreatedTimeslotReservation>;
};

export type CreatedTimeslotReservation = {
	__typename?: 'CreatedTimeslotReservation';
	scheduleSlotUid: Scalars['String'];
};

export type DayOfWeek = 'FRIDAY' | 'MONDAY' | 'SATURDAY' | 'SUNDAY' | 'THURSDAY' | 'TUESDAY' | 'WEDNESDAY';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/disclaimer) */
export type Disclaimer = Entry &
	_Node & {
		__typename?: 'Disclaimer';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		disclaimer?: Maybe<ContentTypeRichText>;
		entryName?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<DisclaimerLinkingCollections>;
		size?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/disclaimer) */
export type DisclaimerDisclaimerArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/disclaimer) */
export type DisclaimerEntryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/disclaimer) */
export type DisclaimerLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/disclaimer) */
export type DisclaimerSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type DisclaimerCollection = {
	__typename?: 'DisclaimerCollection';
	items: Array<Maybe<Disclaimer>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type DisclaimerFilter = {
	AND?: InputMaybe<Array<InputMaybe<DisclaimerFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<DisclaimerFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disclaimer?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	entryName?: InputMaybe<Scalars['String']>;
	entryName_contains?: InputMaybe<Scalars['String']>;
	entryName_exists?: InputMaybe<Scalars['Boolean']>;
	entryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	entryName_not?: InputMaybe<Scalars['String']>;
	entryName_not_contains?: InputMaybe<Scalars['String']>;
	entryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size?: InputMaybe<Scalars['String']>;
	size_contains?: InputMaybe<Scalars['String']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size_not?: InputMaybe<Scalars['String']>;
	size_not_contains?: InputMaybe<Scalars['String']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type DisclaimerLinkingCollections = {
	__typename?: 'DisclaimerLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type DisclaimerLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/DisclaimerV2) */
export type DisclaimerV2 = Entry &
	_Node & {
		__typename?: 'DisclaimerV2';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		disclaimer?: Maybe<ContentTypeRichText>;
		entryName?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<DisclaimerV2LinkingCollections>;
		size?: Maybe<Scalars['String']>;
		sys: Sys;
		templateWidth?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/DisclaimerV2) */
export type DisclaimerV2DisclaimerArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/DisclaimerV2) */
export type DisclaimerV2EntryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/DisclaimerV2) */
export type DisclaimerV2LinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/DisclaimerV2) */
export type DisclaimerV2SizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/DisclaimerV2) */
export type DisclaimerV2TemplateWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type DisclaimerV2Collection = {
	__typename?: 'DisclaimerV2Collection';
	items: Array<Maybe<DisclaimerV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type DisclaimerV2Filter = {
	AND?: InputMaybe<Array<InputMaybe<DisclaimerV2Filter>>>;
	OR?: InputMaybe<Array<InputMaybe<DisclaimerV2Filter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disclaimer?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	entryName?: InputMaybe<Scalars['String']>;
	entryName_contains?: InputMaybe<Scalars['String']>;
	entryName_exists?: InputMaybe<Scalars['Boolean']>;
	entryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	entryName_not?: InputMaybe<Scalars['String']>;
	entryName_not_contains?: InputMaybe<Scalars['String']>;
	entryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size?: InputMaybe<Scalars['String']>;
	size_contains?: InputMaybe<Scalars['String']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size_not?: InputMaybe<Scalars['String']>;
	size_not_contains?: InputMaybe<Scalars['String']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DisclaimerV2LinkingCollections = {
	__typename?: 'DisclaimerV2LinkingCollections';
	adFooterCollection?: Maybe<AdFooterCollection>;
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	pageCollection?: Maybe<PageCollection>;
	productFeatureCollection?: Maybe<ProductFeatureCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type DisclaimerV2LinkingCollectionsAdFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerV2LinkingCollectionsAdFooterCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerV2LinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerV2LinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerV2LinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerV2LinkingCollectionsHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerV2LinkingCollectionsHalfScreenCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerV2LinkingCollectionsHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerV2LinkingCollectionsHeroV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerV2LinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerV2LinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerV2LinkingCollectionsProductFeatureCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerV2LinkingCollectionsProductFeatureCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerV2LinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerV2LinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type DisclaimerV2LinkingCollectionsAdFooterCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'isLightFooter_ASC'
	| 'isLightFooter_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useMobileStickyFooter_ASC'
	| 'useMobileStickyFooter_DESC';

export type DisclaimerV2LinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type DisclaimerV2LinkingCollectionsHalfScreenCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'hideContentHalf_ASC'
	| 'hideContentHalf_DESC'
	| 'isInsetLayout_ASC'
	| 'isInsetLayout_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC'
	| 'useStaticImageSize_ASC'
	| 'useStaticImageSize_DESC';

export type DisclaimerV2LinkingCollectionsHeroV2CollectionOrder =
	| 'backgroundColorSmallScreen_ASC'
	| 'backgroundColorSmallScreen_DESC'
	| 'bodySize_ASC'
	| 'bodySize_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentPositionSmallScreen_ASC'
	| 'contentPositionSmallScreen_DESC'
	| 'contentPosition_ASC'
	| 'contentPosition_DESC'
	| 'contentWidth_ASC'
	| 'contentWidth_DESC'
	| 'darkOverlay_ASC'
	| 'darkOverlay_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'headingSize_ASC'
	| 'headingSize_DESC'
	| 'heightStyle_ASC'
	| 'heightStyle_DESC'
	| 'mediaAspectRatioSmallScreen_ASC'
	| 'mediaAspectRatioSmallScreen_DESC'
	| 'promoHeadlineSize_ASC'
	| 'promoHeadlineSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type DisclaimerV2LinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type DisclaimerV2LinkingCollectionsProductFeatureCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type DisclaimerV2LinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type DisclaimerV2Order =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

/** Emergency Contact Details */
export type EmergencyContact = {
	__typename?: 'EmergencyContact';
	firstName?: Maybe<Scalars['String']>;
	fullName?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
	phoneNumber?: Maybe<Scalars['String']>;
};

export type Entry = {
	contentfulMetadata: ContentfulMetadata;
	sys: Sys;
};

export type EntryCollection = {
	__typename?: 'EntryCollection';
	items: Array<Maybe<Entry>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type EntryFilter = {
	AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type EntryOrder =
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type Error = {
	message: Scalars['String'];
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404 = Entry &
	_Node & {
		__typename?: 'Error404';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		backgroundImage?: Maybe<Asset>;
		backgroundImageMobile?: Maybe<Asset>;
		body?: Maybe<Scalars['String']>;
		buttonLabel?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		darkTextColor?: Maybe<Scalars['Boolean']>;
		heading?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<Error404LinkingCollections>;
		name?: Maybe<Scalars['String']>;
		subtitle?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404AnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404BackgroundImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404BackgroundImageMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404BodyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404ButtonLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404DarkTextColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404HeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404LinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404NameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This will display when there is an error. It is equivalent to a 404 page one might expect when visiting a URL that doesn't exist. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/error404) */
export type Error404SubtitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type Error404Collection = {
	__typename?: 'Error404Collection';
	items: Array<Maybe<Error404>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type Error404Filter = {
	AND?: InputMaybe<Array<InputMaybe<Error404Filter>>>;
	OR?: InputMaybe<Array<InputMaybe<Error404Filter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
	body?: InputMaybe<Scalars['String']>;
	body_contains?: InputMaybe<Scalars['String']>;
	body_exists?: InputMaybe<Scalars['Boolean']>;
	body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	body_not?: InputMaybe<Scalars['String']>;
	body_not_contains?: InputMaybe<Scalars['String']>;
	body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLabel?: InputMaybe<Scalars['String']>;
	buttonLabel_contains?: InputMaybe<Scalars['String']>;
	buttonLabel_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLabel_not?: InputMaybe<Scalars['String']>;
	buttonLabel_not_contains?: InputMaybe<Scalars['String']>;
	buttonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	darkTextColor?: InputMaybe<Scalars['Boolean']>;
	darkTextColor_exists?: InputMaybe<Scalars['Boolean']>;
	darkTextColor_not?: InputMaybe<Scalars['Boolean']>;
	heading?: InputMaybe<Scalars['String']>;
	heading_contains?: InputMaybe<Scalars['String']>;
	heading_exists?: InputMaybe<Scalars['Boolean']>;
	heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	heading_not?: InputMaybe<Scalars['String']>;
	heading_not_contains?: InputMaybe<Scalars['String']>;
	heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subtitle?: InputMaybe<Scalars['String']>;
	subtitle_contains?: InputMaybe<Scalars['String']>;
	subtitle_exists?: InputMaybe<Scalars['Boolean']>;
	subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subtitle_not?: InputMaybe<Scalars['String']>;
	subtitle_not_contains?: InputMaybe<Scalars['String']>;
	subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type Error404LinkingCollections = {
	__typename?: 'Error404LinkingCollections';
	appCollection?: Maybe<AppCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type Error404LinkingCollectionsAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<Error404LinkingCollectionsAppCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type Error404LinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type Error404LinkingCollectionsAppCollectionOrder =
	| 'brandImageLink_ASC'
	| 'brandImageLink_DESC'
	| 'branding_ASC'
	| 'branding_DESC'
	| 'isDeprecated_ASC'
	| 'isDeprecated_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type Error404Order =
	| 'buttonLabel_ASC'
	| 'buttonLabel_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'heading_ASC'
	| 'heading_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subtitle_ASC'
	| 'subtitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FacilitiesFilterInput = {
	brand?: InputMaybe<FacilityBrand>;
};

export type FacilitiesList = {
	__typename?: 'FacilitiesList';
	items: Array<Facility>;
	total: Scalars['Int'];
};

export type Facility = {
	__typename?: 'Facility';
	/** @deprecated Use address fields from facility.Location */
	address: FacilityAddress;
	brand: FacilityBrand;
	code: Scalars['String'];
	comingSoon: Scalars['Boolean'];
	googleCategories?: Maybe<Array<Scalars['String']>>;
	googlePrimaryCategory?: Maybe<Scalars['String']>;
	insuranceInfo?: Maybe<FacilityInsuranceInfo>;
	location?: Maybe<FacilityLocation>;
	name: Scalars['String'];
	nearbyFacilities?: Maybe<NearbyFacilitiesList>;
	nearbyRescheduleFacilities?: Maybe<NearbyFacilitiesList>;
	/** @deprecated Use Facility.WorkingHours field. */
	officeWorkingHours: Array<WorkingHours>;
	openDate?: Maybe<Scalars['Date']>;
	/** @deprecated Use phone number from facility.TechnicalInfrastructure. */
	phoneNumber?: Maybe<Scalars['String']>;
	procedurePrices?: Maybe<ProcedurePricesList>;
	providers?: Maybe<Array<Provider>>;
	rescheduleSlots?: Maybe<RescheduleSlotsList>;
	reviews?: Maybe<ReviewWithSummary>;
	scheduling?: Maybe<FacilityScheduling>;
	/** @deprecated It is moved to Facility.Scheduling. */
	schedulingUrl?: Maybe<Scalars['String']>;
	services?: Maybe<Array<Service>>;
	shortName?: Maybe<Scalars['String']>;
	specialHandling?: Maybe<Scalars['String']>;
	subBrand?: Maybe<Scalars['String']>;
	technicalInfrastructure?: Maybe<FacilityTechnicalInfrastructure>;
	/** Working hours for next seven days. */
	workingHours?: Maybe<Array<FacilityDailyWorkingHours>>;
};

export type FacilityNearbyFacilitiesArgs = {
	filter: NearbyFacilitiesFilter;
	skip?: Scalars['NonNegativeInt'];
	take?: Scalars['NonNegativeInt'];
};

export type FacilityNearbyRescheduleFacilitiesArgs = {
	filter: NearbyRescheduleFacilitiesFilter;
	take?: Scalars['NonNegativeInt'];
};

export type FacilityProcedurePricesArgs = {
	filter?: InputMaybe<ProceduresPricesFilterInput>;
	skip?: Scalars['NonNegativeInt'];
	take?: Scalars['NonNegativeInt'];
};

export type FacilityRescheduleSlotsArgs = {
	filter: RescheduleSlotsFilter;
};

export type FacilityAddress = {
	__typename?: 'FacilityAddress';
	address1?: Maybe<Scalars['String']>;
	address2?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	stateCode?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
};

export { FacilityBrand };

export type FacilityDailyWorkingHours = {
	__typename?: 'FacilityDailyWorkingHours';
	date: Scalars['Date'];
	open?: Maybe<FacilityWorkingHours>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPage = Entry &
	_Node & {
		__typename?: 'FacilityDetailsPage';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		appName?: Maybe<Scalars['String']>;
		breadcrumbTitle?: Maybe<Scalars['String']>;
		breadcrumbsCollection?: Maybe<FacilityDetailsPageBreadcrumbsCollection>;
		canonical?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		contentsCollection?: Maybe<FacilityDetailsPageContentsCollection>;
		description?: Maybe<Scalars['String']>;
		facilityId?: Maybe<Scalars['String']>;
		indexed?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<FacilityDetailsPageLinkingCollections>;
		mobileBreadcrumbTitle?: Maybe<Scalars['String']>;
		moreTextSeo?: Maybe<FacilityDetailsPageMoreTextSeo>;
		name?: Maybe<Scalars['String']>;
		pageTitle?: Maybe<Scalars['String']>;
		path?: Maybe<Scalars['String']>;
		sys: Sys;
		textSeo?: Maybe<FacilityDetailsPageTextSeo>;
		title?: Maybe<Scalars['String']>;
	};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageAppNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageBreadcrumbTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageBreadcrumbsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FacilityDetailsPageBreadcrumbsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PageFilter>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageCanonicalArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FacilityDetailsPageContentsFilter>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageFacilityIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageIndexedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageMobileBreadcrumbTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageMoreTextSeoArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPagePageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPagePathArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageTextSeoArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This page defines meta data and contents of facility.  [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/facilityDetailsPage) */
export type FacilityDetailsPageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type FacilityDetailsPageBreadcrumbsCollection = {
	__typename?: 'FacilityDetailsPageBreadcrumbsCollection';
	items: Array<Maybe<Page>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FacilityDetailsPageBreadcrumbsCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FacilityDetailsPageCollection = {
	__typename?: 'FacilityDetailsPageCollection';
	items: Array<Maybe<FacilityDetailsPage>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FacilityDetailsPageContentsCollection = {
	__typename?: 'FacilityDetailsPageContentsCollection';
	items: Array<Maybe<FacilityDetailsPageContentsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FacilityDetailsPageContentsFilter = {
	AND?: InputMaybe<Array<InputMaybe<FacilityDetailsPageContentsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FacilityDetailsPageContentsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type FacilityDetailsPageContentsItem =
	| Accordions
	| BirdEyeReviews
	| CategoryPicker
	| Columns
	| ContentTypeRichText
	| DisclaimerV2
	| GoogleMapTemplate
	| HalfScreen
	| HeroV2
	| Marquee
	| MeetOurDoctors
	| MeetOurStaff
	| MultiCardV2
	| OdpHero
	| OdpLocalizedSecondaryFooter
	| PatientReviewsV2
	| ProductFeature
	| QuickLinks
	| RichTextBlockSeo
	| Schema
	| SegmentsContainer
	| ServiceLineHero
	| SingleMessage
	| Spacer
	| Tabs;

export type FacilityDetailsPageFilter = {
	AND?: InputMaybe<Array<InputMaybe<FacilityDetailsPageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FacilityDetailsPageFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	appName?: InputMaybe<Scalars['String']>;
	appName_contains?: InputMaybe<Scalars['String']>;
	appName_exists?: InputMaybe<Scalars['Boolean']>;
	appName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appName_not?: InputMaybe<Scalars['String']>;
	appName_not_contains?: InputMaybe<Scalars['String']>;
	appName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbTitle?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_contains?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_exists?: InputMaybe<Scalars['Boolean']>;
	breadcrumbTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbTitle_not?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_not_contains?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbs?: InputMaybe<CfPageNestedFilter>;
	breadcrumbsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	canonical?: InputMaybe<Scalars['String']>;
	canonical_contains?: InputMaybe<Scalars['String']>;
	canonical_exists?: InputMaybe<Scalars['Boolean']>;
	canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical_not?: InputMaybe<Scalars['String']>;
	canonical_not_contains?: InputMaybe<Scalars['String']>;
	canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityId?: InputMaybe<Scalars['String']>;
	facilityId_contains?: InputMaybe<Scalars['String']>;
	facilityId_exists?: InputMaybe<Scalars['Boolean']>;
	facilityId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityId_not?: InputMaybe<Scalars['String']>;
	facilityId_not_contains?: InputMaybe<Scalars['String']>;
	facilityId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	indexed?: InputMaybe<Scalars['Boolean']>;
	indexed_exists?: InputMaybe<Scalars['Boolean']>;
	indexed_not?: InputMaybe<Scalars['Boolean']>;
	mobileBreadcrumbTitle?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_contains?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_exists?: InputMaybe<Scalars['Boolean']>;
	mobileBreadcrumbTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileBreadcrumbTitle_not?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_not_contains?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	moreTextSeo_contains?: InputMaybe<Scalars['String']>;
	moreTextSeo_exists?: InputMaybe<Scalars['Boolean']>;
	moreTextSeo_not_contains?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTitle?: InputMaybe<Scalars['String']>;
	pageTitle_contains?: InputMaybe<Scalars['String']>;
	pageTitle_exists?: InputMaybe<Scalars['Boolean']>;
	pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTitle_not?: InputMaybe<Scalars['String']>;
	pageTitle_not_contains?: InputMaybe<Scalars['String']>;
	pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path?: InputMaybe<Scalars['String']>;
	path_contains?: InputMaybe<Scalars['String']>;
	path_exists?: InputMaybe<Scalars['Boolean']>;
	path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path_not?: InputMaybe<Scalars['String']>;
	path_not_contains?: InputMaybe<Scalars['String']>;
	path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	textSeo_contains?: InputMaybe<Scalars['String']>;
	textSeo_exists?: InputMaybe<Scalars['Boolean']>;
	textSeo_not_contains?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FacilityDetailsPageLinkingCollections = {
	__typename?: 'FacilityDetailsPageLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type FacilityDetailsPageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FacilityDetailsPageMoreTextSeo = {
	__typename?: 'FacilityDetailsPageMoreTextSeo';
	json: Scalars['JSON'];
	links: FacilityDetailsPageMoreTextSeoLinks;
};

export type FacilityDetailsPageMoreTextSeoAssets = {
	__typename?: 'FacilityDetailsPageMoreTextSeoAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type FacilityDetailsPageMoreTextSeoEntries = {
	__typename?: 'FacilityDetailsPageMoreTextSeoEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type FacilityDetailsPageMoreTextSeoLinks = {
	__typename?: 'FacilityDetailsPageMoreTextSeoLinks';
	assets: FacilityDetailsPageMoreTextSeoAssets;
	entries: FacilityDetailsPageMoreTextSeoEntries;
	resources: FacilityDetailsPageMoreTextSeoResources;
};

export type FacilityDetailsPageMoreTextSeoResources = {
	__typename?: 'FacilityDetailsPageMoreTextSeoResources';
	block: Array<FacilityDetailsPageMoreTextSeoResourcesBlock>;
	hyperlink: Array<FacilityDetailsPageMoreTextSeoResourcesHyperlink>;
	inline: Array<FacilityDetailsPageMoreTextSeoResourcesInline>;
};

export type FacilityDetailsPageMoreTextSeoResourcesBlock = ResourceLink & {
	__typename?: 'FacilityDetailsPageMoreTextSeoResourcesBlock';
	sys: ResourceSys;
};

export type FacilityDetailsPageMoreTextSeoResourcesHyperlink = ResourceLink & {
	__typename?: 'FacilityDetailsPageMoreTextSeoResourcesHyperlink';
	sys: ResourceSys;
};

export type FacilityDetailsPageMoreTextSeoResourcesInline = ResourceLink & {
	__typename?: 'FacilityDetailsPageMoreTextSeoResourcesInline';
	sys: ResourceSys;
};

export type FacilityDetailsPageOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FacilityDetailsPageTextSeo = {
	__typename?: 'FacilityDetailsPageTextSeo';
	json: Scalars['JSON'];
	links: FacilityDetailsPageTextSeoLinks;
};

export type FacilityDetailsPageTextSeoAssets = {
	__typename?: 'FacilityDetailsPageTextSeoAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type FacilityDetailsPageTextSeoEntries = {
	__typename?: 'FacilityDetailsPageTextSeoEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type FacilityDetailsPageTextSeoLinks = {
	__typename?: 'FacilityDetailsPageTextSeoLinks';
	assets: FacilityDetailsPageTextSeoAssets;
	entries: FacilityDetailsPageTextSeoEntries;
	resources: FacilityDetailsPageTextSeoResources;
};

export type FacilityDetailsPageTextSeoResources = {
	__typename?: 'FacilityDetailsPageTextSeoResources';
	block: Array<FacilityDetailsPageTextSeoResourcesBlock>;
	hyperlink: Array<FacilityDetailsPageTextSeoResourcesHyperlink>;
	inline: Array<FacilityDetailsPageTextSeoResourcesInline>;
};

export type FacilityDetailsPageTextSeoResourcesBlock = ResourceLink & {
	__typename?: 'FacilityDetailsPageTextSeoResourcesBlock';
	sys: ResourceSys;
};

export type FacilityDetailsPageTextSeoResourcesHyperlink = ResourceLink & {
	__typename?: 'FacilityDetailsPageTextSeoResourcesHyperlink';
	sys: ResourceSys;
};

export type FacilityDetailsPageTextSeoResourcesInline = ResourceLink & {
	__typename?: 'FacilityDetailsPageTextSeoResourcesInline';
	sys: ResourceSys;
};

export type FacilityInsuranceInfo = {
	__typename?: 'FacilityInsuranceInfo';
	companies: Array<InsuranceCompany>;
	networks: Array<InsuranceCompaniesNetwork>;
};

export type FacilityLocation = {
	__typename?: 'FacilityLocation';
	address1?: Maybe<Scalars['String']>;
	address2?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	directions?: Maybe<Scalars['String']>;
	dst: Scalars['Boolean'];
	ianaTimeZone?: Maybe<Scalars['String']>;
	landmarks?: Maybe<Scalars['String']>;
	latitude: Scalars['Decimal'];
	longitude: Scalars['Decimal'];
	neighbouringAreas?: Maybe<Scalars['String']>;
	stateCode?: Maybe<Scalars['String']>;
	timeZone?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
};

export type FacilityScheduling = {
	__typename?: 'FacilityScheduling';
	/** Represents adult visit duration in minutes. */
	adultExamDuration?: Maybe<Scalars['Int']>;
	/** Represents facility's max number of days in future for querying slots. */
	bookingWindowLimit?: Maybe<Scalars['Int']>;
	/** Represents child visit duration in minutes. */
	childExamDuration?: Maybe<Scalars['Int']>;
	minimalPatientAge?: Maybe<Scalars['Int']>;
	schedulingUrl?: Maybe<Scalars['String']>;
};

export type FacilityService = 'HEALTHY_MOUTH_MOVEMENT' | 'INVISALIGN' | 'MOTTO';

export { FacilityServiceBrand };

export { FacilityServiceType };

export type FacilityTechnicalInfrastructure = {
	__typename?: 'FacilityTechnicalInfrastructure';
	email?: Maybe<Scalars['String']>;
	emergencyPhoneNumber?: Maybe<Scalars['String']>;
	faxNumber?: Maybe<Scalars['String']>;
	phoneNumber?: Maybe<Scalars['String']>;
};

export type FacilityWithScheduleSlots = {
	__typename?: 'FacilityWithScheduleSlots';
	facility: Facility;
	slots?: Maybe<ScheduleSlotsList>;
};

export type FacilityWorkingHours = {
	__typename?: 'FacilityWorkingHours';
	from?: Maybe<Scalars['LocalTime']>;
	fromTimestamp?: Maybe<Scalars['DateTime']>;
	to?: Maybe<Scalars['LocalTime']>;
	toTimestamp?: Maybe<Scalars['DateTime']>;
};

/** Controls the behavior of application code. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlag) */
export type FeatureFlag = Entry &
	_Node & {
		__typename?: 'FeatureFlag';
		_id: Scalars['ID'];
		appCollection?: Maybe<FeatureFlagAppCollection>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<FeatureFlagLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		value?: Maybe<Scalars['Boolean']>;
	};

/** Controls the behavior of application code. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlag) */
export type FeatureFlagAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FeatureFlagAppCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AppDetailsFilter>;
};

/** Controls the behavior of application code. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlag) */
export type FeatureFlagDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Controls the behavior of application code. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlag) */
export type FeatureFlagLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Controls the behavior of application code. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlag) */
export type FeatureFlagNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Controls the behavior of application code. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlag) */
export type FeatureFlagValueArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type FeatureFlagAppCollection = {
	__typename?: 'FeatureFlagAppCollection';
	items: Array<Maybe<AppDetails>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FeatureFlagAppCollectionOrder =
	| 'id_ASC'
	| 'id_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FeatureFlagCollection = {
	__typename?: 'FeatureFlagCollection';
	items: Array<Maybe<FeatureFlag>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FeatureFlagFilter = {
	AND?: InputMaybe<Array<InputMaybe<FeatureFlagFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FeatureFlagFilter>>>;
	app?: InputMaybe<CfAppDetailsNestedFilter>;
	appCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	value?: InputMaybe<Scalars['Boolean']>;
	value_exists?: InputMaybe<Scalars['Boolean']>;
	value_not?: InputMaybe<Scalars['Boolean']>;
};

export type FeatureFlagLinkingCollections = {
	__typename?: 'FeatureFlagLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type FeatureFlagLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FeatureFlagOrder =
	| 'description_ASC'
	| 'description_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'value_ASC'
	| 'value_DESC';

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlags = Entry &
	_Node & {
		__typename?: 'FeatureFlags';
		_id: Scalars['ID'];
		ameliaChat?: Maybe<Array<Maybe<Scalars['String']>>>;
		contentfulMetadata: ContentfulMetadata;
		continentalUsMapFallbackThreshold?: Maybe<Scalars['Int']>;
		defaultToCdn?: Maybe<Scalars['Boolean']>;
		enforceContinentalUsZoom?: Maybe<Scalars['Boolean']>;
		featureList?: Maybe<Array<Maybe<Scalars['String']>>>;
		hideOdpHeaderLinks?: Maybe<Scalars['Boolean']>;
		hideSearchWithLocationCards?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<FeatureFlagsLinkingCollections>;
		loadFacilityReviewsFromBeffe?: Maybe<Scalars['Boolean']>;
		name?: Maybe<Scalars['String']>;
		onPageScheduling?: Maybe<Scalars['Boolean']>;
		onPageSchedulingLocations?: Maybe<Array<Maybe<Scalars['String']>>>;
		providerHeroTheme?: Maybe<Scalars['String']>;
		salesForceEvergageScript?: Maybe<Scalars['String']>;
		showHeaderWithLocationNav?: Maybe<Scalars['Boolean']>;
		showMobileHeaderLocationBar?: Maybe<Scalars['Boolean']>;
		showProviderPageCtaInMeetOurStaff?: Maybe<Scalars['Boolean']>;
		showReadMoreForBioInMeetOurStaff?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		transitionallyUseBeffe?: Maybe<Scalars['Boolean']>;
		useFacilityBeffeDataSource?: Maybe<Scalars['Boolean']>;
		useFilterForOdpDetailsContent?: Maybe<Scalars['Boolean']>;
		useInlineOneTrust?: Maybe<Scalars['Boolean']>;
	};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsAmeliaChatArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsContinentalUsMapFallbackThresholdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsDefaultToCdnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsEnforceContinentalUsZoomArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsFeatureListArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsHideOdpHeaderLinksArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsHideSearchWithLocationCardsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsLoadFacilityReviewsFromBeffeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsOnPageSchedulingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsOnPageSchedulingLocationsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsProviderHeroThemeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsSalesForceEvergageScriptArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsShowHeaderWithLocationNavArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsShowMobileHeaderLocationBarArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsShowProviderPageCtaInMeetOurStaffArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsShowReadMoreForBioInMeetOurStaffArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsTransitionallyUseBeffeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsUseFacilityBeffeDataSourceArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsUseFilterForOdpDetailsContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Concentrating all feature flags for an app in one model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/featureFlags) */
export type FeatureFlagsUseInlineOneTrustArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type FeatureFlagsCollection = {
	__typename?: 'FeatureFlagsCollection';
	items: Array<Maybe<FeatureFlags>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FeatureFlagsFilter = {
	AND?: InputMaybe<Array<InputMaybe<FeatureFlagsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FeatureFlagsFilter>>>;
	ameliaChat_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ameliaChat_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ameliaChat_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ameliaChat_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	continentalUsMapFallbackThreshold?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_exists?: InputMaybe<Scalars['Boolean']>;
	continentalUsMapFallbackThreshold_gt?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_gte?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	continentalUsMapFallbackThreshold_lt?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_lte?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_not?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	defaultToCdn?: InputMaybe<Scalars['Boolean']>;
	defaultToCdn_exists?: InputMaybe<Scalars['Boolean']>;
	defaultToCdn_not?: InputMaybe<Scalars['Boolean']>;
	enforceContinentalUsZoom?: InputMaybe<Scalars['Boolean']>;
	enforceContinentalUsZoom_exists?: InputMaybe<Scalars['Boolean']>;
	enforceContinentalUsZoom_not?: InputMaybe<Scalars['Boolean']>;
	featureList_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	featureList_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	featureList_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	featureList_exists?: InputMaybe<Scalars['Boolean']>;
	hideOdpHeaderLinks?: InputMaybe<Scalars['Boolean']>;
	hideOdpHeaderLinks_exists?: InputMaybe<Scalars['Boolean']>;
	hideOdpHeaderLinks_not?: InputMaybe<Scalars['Boolean']>;
	hideSearchWithLocationCards?: InputMaybe<Scalars['Boolean']>;
	hideSearchWithLocationCards_exists?: InputMaybe<Scalars['Boolean']>;
	hideSearchWithLocationCards_not?: InputMaybe<Scalars['Boolean']>;
	loadFacilityReviewsFromBeffe?: InputMaybe<Scalars['Boolean']>;
	loadFacilityReviewsFromBeffe_exists?: InputMaybe<Scalars['Boolean']>;
	loadFacilityReviewsFromBeffe_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onPageScheduling?: InputMaybe<Scalars['Boolean']>;
	onPageSchedulingLocations_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onPageSchedulingLocations_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onPageSchedulingLocations_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onPageSchedulingLocations_exists?: InputMaybe<Scalars['Boolean']>;
	onPageScheduling_exists?: InputMaybe<Scalars['Boolean']>;
	onPageScheduling_not?: InputMaybe<Scalars['Boolean']>;
	providerHeroTheme?: InputMaybe<Scalars['String']>;
	providerHeroTheme_contains?: InputMaybe<Scalars['String']>;
	providerHeroTheme_exists?: InputMaybe<Scalars['Boolean']>;
	providerHeroTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	providerHeroTheme_not?: InputMaybe<Scalars['String']>;
	providerHeroTheme_not_contains?: InputMaybe<Scalars['String']>;
	providerHeroTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	salesForceEvergageScript?: InputMaybe<Scalars['String']>;
	salesForceEvergageScript_contains?: InputMaybe<Scalars['String']>;
	salesForceEvergageScript_exists?: InputMaybe<Scalars['Boolean']>;
	salesForceEvergageScript_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	salesForceEvergageScript_not?: InputMaybe<Scalars['String']>;
	salesForceEvergageScript_not_contains?: InputMaybe<Scalars['String']>;
	salesForceEvergageScript_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	showHeaderWithLocationNav?: InputMaybe<Scalars['Boolean']>;
	showHeaderWithLocationNav_exists?: InputMaybe<Scalars['Boolean']>;
	showHeaderWithLocationNav_not?: InputMaybe<Scalars['Boolean']>;
	showMobileHeaderLocationBar?: InputMaybe<Scalars['Boolean']>;
	showMobileHeaderLocationBar_exists?: InputMaybe<Scalars['Boolean']>;
	showMobileHeaderLocationBar_not?: InputMaybe<Scalars['Boolean']>;
	showProviderPageCtaInMeetOurStaff?: InputMaybe<Scalars['Boolean']>;
	showProviderPageCtaInMeetOurStaff_exists?: InputMaybe<Scalars['Boolean']>;
	showProviderPageCtaInMeetOurStaff_not?: InputMaybe<Scalars['Boolean']>;
	showReadMoreForBioInMeetOurStaff?: InputMaybe<Scalars['Boolean']>;
	showReadMoreForBioInMeetOurStaff_exists?: InputMaybe<Scalars['Boolean']>;
	showReadMoreForBioInMeetOurStaff_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	transitionallyUseBeffe?: InputMaybe<Scalars['Boolean']>;
	transitionallyUseBeffe_exists?: InputMaybe<Scalars['Boolean']>;
	transitionallyUseBeffe_not?: InputMaybe<Scalars['Boolean']>;
	useFacilityBeffeDataSource?: InputMaybe<Scalars['Boolean']>;
	useFacilityBeffeDataSource_exists?: InputMaybe<Scalars['Boolean']>;
	useFacilityBeffeDataSource_not?: InputMaybe<Scalars['Boolean']>;
	useFilterForOdpDetailsContent?: InputMaybe<Scalars['Boolean']>;
	useFilterForOdpDetailsContent_exists?: InputMaybe<Scalars['Boolean']>;
	useFilterForOdpDetailsContent_not?: InputMaybe<Scalars['Boolean']>;
	useInlineOneTrust?: InputMaybe<Scalars['Boolean']>;
	useInlineOneTrust_exists?: InputMaybe<Scalars['Boolean']>;
	useInlineOneTrust_not?: InputMaybe<Scalars['Boolean']>;
};

export type FeatureFlagsLinkingCollections = {
	__typename?: 'FeatureFlagsLinkingCollections';
	appCollection?: Maybe<AppCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type FeatureFlagsLinkingCollectionsAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FeatureFlagsLinkingCollectionsAppCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FeatureFlagsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FeatureFlagsLinkingCollectionsAppCollectionOrder =
	| 'brandImageLink_ASC'
	| 'brandImageLink_DESC'
	| 'branding_ASC'
	| 'branding_DESC'
	| 'isDeprecated_ASC'
	| 'isDeprecated_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FeatureFlagsOrder =
	| 'continentalUsMapFallbackThreshold_ASC'
	| 'continentalUsMapFallbackThreshold_DESC'
	| 'defaultToCdn_ASC'
	| 'defaultToCdn_DESC'
	| 'enforceContinentalUsZoom_ASC'
	| 'enforceContinentalUsZoom_DESC'
	| 'hideOdpHeaderLinks_ASC'
	| 'hideOdpHeaderLinks_DESC'
	| 'hideSearchWithLocationCards_ASC'
	| 'hideSearchWithLocationCards_DESC'
	| 'loadFacilityReviewsFromBeffe_ASC'
	| 'loadFacilityReviewsFromBeffe_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'onPageScheduling_ASC'
	| 'onPageScheduling_DESC'
	| 'providerHeroTheme_ASC'
	| 'providerHeroTheme_DESC'
	| 'salesForceEvergageScript_ASC'
	| 'salesForceEvergageScript_DESC'
	| 'showHeaderWithLocationNav_ASC'
	| 'showHeaderWithLocationNav_DESC'
	| 'showMobileHeaderLocationBar_ASC'
	| 'showMobileHeaderLocationBar_DESC'
	| 'showProviderPageCtaInMeetOurStaff_ASC'
	| 'showProviderPageCtaInMeetOurStaff_DESC'
	| 'showReadMoreForBioInMeetOurStaff_ASC'
	| 'showReadMoreForBioInMeetOurStaff_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'transitionallyUseBeffe_ASC'
	| 'transitionallyUseBeffe_DESC'
	| 'useFacilityBeffeDataSource_ASC'
	| 'useFacilityBeffeDataSource_DESC'
	| 'useFilterForOdpDetailsContent_ASC'
	| 'useFilterForOdpDetailsContent_DESC'
	| 'useInlineOneTrust_ASC'
	| 'useInlineOneTrust_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/focalPointImage) */
export type FocalPointImage = Entry &
	_Node & {
		__typename?: 'FocalPointImage';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		focalPoint?: Maybe<Scalars['JSON']>;
		image?: Maybe<Asset>;
		linkedFrom?: Maybe<FocalPointImageLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/focalPointImage) */
export type FocalPointImageFocalPointArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/focalPointImage) */
export type FocalPointImageImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/focalPointImage) */
export type FocalPointImageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/focalPointImage) */
export type FocalPointImageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type FocalPointImageCollection = {
	__typename?: 'FocalPointImageCollection';
	items: Array<Maybe<FocalPointImage>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FocalPointImageFilter = {
	AND?: InputMaybe<Array<InputMaybe<FocalPointImageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FocalPointImageFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	focalPoint_exists?: InputMaybe<Scalars['Boolean']>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type FocalPointImageLinkingCollections = {
	__typename?: 'FocalPointImageLinkingCollections';
	accordionsCollection?: Maybe<AccordionsCollection>;
	birdEyeReviewsCollection?: Maybe<BirdEyeReviewsCollection>;
	cardCollection?: Maybe<CardCollection>;
	entryCollection?: Maybe<EntryCollection>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	mediaSliderCollection?: Maybe<MediaSliderCollection>;
	odpHeroCollection?: Maybe<OdpHeroCollection>;
	questionnaireCollection?: Maybe<QuestionnaireCollection>;
	secondaryHeroCollection?: Maybe<SecondaryHeroCollection>;
	sectionContentCollection?: Maybe<SectionContentCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
	singleMessageCollection?: Maybe<SingleMessageCollection>;
	smallScreenContentCollection?: Maybe<SmallScreenContentCollection>;
};

export type FocalPointImageLinkingCollectionsAccordionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsAccordionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsBirdEyeReviewsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsBirdEyeReviewsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsCardCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsHeroV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsMediaSliderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsMediaSliderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsOdpHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsOdpHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsQuestionnaireCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsQuestionnaireCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsSecondaryHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsSecondaryHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsSectionContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsSectionContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsSingleMessageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsSingleMessageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsSmallScreenContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageLinkingCollectionsSmallScreenContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FocalPointImageLinkingCollectionsAccordionsCollectionOrder =
	| 'accordionsHeading_ASC'
	| 'accordionsHeading_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonCollectionAlignment_ASC'
	| 'buttonCollectionAlignment_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'expandIconPosition_ASC'
	| 'expandIconPosition_DESC'
	| 'expandIconType_ASC'
	| 'expandIconType_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundedCorner_ASC'
	| 'imageRoundedCorner_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'roundedCornersSize_ASC'
	| 'roundedCornersSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleAlignment_ASC'
	| 'titleAlignment_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'withInnerGap_ASC'
	| 'withInnerGap_DESC';

export type FocalPointImageLinkingCollectionsBirdEyeReviewsCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FocalPointImageLinkingCollectionsCardCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FocalPointImageLinkingCollectionsHeroV2CollectionOrder =
	| 'backgroundColorSmallScreen_ASC'
	| 'backgroundColorSmallScreen_DESC'
	| 'bodySize_ASC'
	| 'bodySize_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentPositionSmallScreen_ASC'
	| 'contentPositionSmallScreen_DESC'
	| 'contentPosition_ASC'
	| 'contentPosition_DESC'
	| 'contentWidth_ASC'
	| 'contentWidth_DESC'
	| 'darkOverlay_ASC'
	| 'darkOverlay_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'headingSize_ASC'
	| 'headingSize_DESC'
	| 'heightStyle_ASC'
	| 'heightStyle_DESC'
	| 'mediaAspectRatioSmallScreen_ASC'
	| 'mediaAspectRatioSmallScreen_DESC'
	| 'promoHeadlineSize_ASC'
	| 'promoHeadlineSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type FocalPointImageLinkingCollectionsMediaSliderCollectionOrder =
	| 'aspectRatio_ASC'
	| 'aspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FocalPointImageLinkingCollectionsOdpHeroCollectionOrder =
	| 'appointmentDataSource_ASC'
	| 'appointmentDataSource_DESC'
	| 'callCenterLabel_ASC'
	| 'callCenterLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'officeHoursLabel_ASC'
	| 'officeHoursLabel_DESC'
	| 'promoTitle_ASC'
	| 'promoTitle_DESC'
	| 'showAddress_ASC'
	| 'showAddress_DESC'
	| 'showEmail_ASC'
	| 'showEmail_DESC'
	| 'showFax_ASC'
	| 'showFax_DESC'
	| 'showHours_ASC'
	| 'showHours_DESC'
	| 'showLocationStatusBadge_ASC'
	| 'showLocationStatusBadge_DESC'
	| 'showPhoneNumber_ASC'
	| 'showPhoneNumber_DESC'
	| 'showReviews_ASC'
	| 'showReviews_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FocalPointImageLinkingCollectionsQuestionnaireCollectionOrder =
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'defaultErrorMessage_ASC'
	| 'defaultErrorMessage_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subTitle_ASC'
	| 'subTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'text_ASC'
	| 'text_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FocalPointImageLinkingCollectionsSecondaryHeroCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'body_ASC'
	| 'body_DESC'
	| 'ctaStyle_ASC'
	| 'ctaStyle_DESC'
	| 'ctaText_ASC'
	| 'ctaText_DESC'
	| 'ctaUrl_ASC'
	| 'ctaUrl_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'heading_ASC'
	| 'heading_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundCornerMobile_ASC'
	| 'imageRoundCornerMobile_DESC'
	| 'imageRoundedBottomDesktop_ASC'
	| 'imageRoundedBottomDesktop_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'stickyImage_ASC'
	| 'stickyImage_DESC'
	| 'subheading_ASC'
	| 'subheading_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'themeColor_ASC'
	| 'themeColor_DESC';

export type FocalPointImageLinkingCollectionsSectionContentCollectionOrder =
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageShape_ASC'
	| 'imageShape_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'orientation_ASC'
	| 'orientation_DESC'
	| 'subTitle_ASC'
	| 'subTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type FocalPointImageLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type FocalPointImageLinkingCollectionsSingleMessageCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentFillVerticalSpace_ASC'
	| 'contentFillVerticalSpace_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isBannerImage_ASC'
	| 'isBannerImage_DESC'
	| 'isInlineLayout_ASC'
	| 'isInlineLayout_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subText_ASC'
	| 'subText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateHeight_ASC'
	| 'templateHeight_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleVariant_ASC'
	| 'titleVariant_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'useRoundedCorners_ASC'
	| 'useRoundedCorners_DESC';

export type FocalPointImageLinkingCollectionsSmallScreenContentCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowSmallScreen_ASC'
	| 'eyebrowSmallScreen_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FocalPointImageOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Footer Navigation Group Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroup) */
export type FooterNavigationGroup = Entry &
	_Node & {
		__typename?: 'FooterNavigationGroup';
		_id: Scalars['ID'];
		componentName?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<FooterNavigationGroupLinkingCollections>;
		navigationGroupLink?: Maybe<Page>;
		navigationGroupTitle?: Maybe<Scalars['String']>;
		navigationLinksForGroupCollection?: Maybe<FooterNavigationGroupNavigationLinksForGroupCollection>;
		sys: Sys;
	};

/** Footer Navigation Group Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroup) */
export type FooterNavigationGroupComponentNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Footer Navigation Group Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroup) */
export type FooterNavigationGroupLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Footer Navigation Group Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroup) */
export type FooterNavigationGroupNavigationGroupLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<PageFilter>;
};

/** Footer Navigation Group Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroup) */
export type FooterNavigationGroupNavigationGroupTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Footer Navigation Group Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroup) */
export type FooterNavigationGroupNavigationLinksForGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FooterNavigationGroupNavigationLinksForGroupFilter>;
};

export type FooterNavigationGroupCollection = {
	__typename?: 'FooterNavigationGroupCollection';
	items: Array<Maybe<FooterNavigationGroup>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FooterNavigationGroupFilter = {
	AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupFilter>>>;
	componentName?: InputMaybe<Scalars['String']>;
	componentName_contains?: InputMaybe<Scalars['String']>;
	componentName_exists?: InputMaybe<Scalars['Boolean']>;
	componentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName_not?: InputMaybe<Scalars['String']>;
	componentName_not_contains?: InputMaybe<Scalars['String']>;
	componentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	navigationGroupLink?: InputMaybe<CfPageNestedFilter>;
	navigationGroupLink_exists?: InputMaybe<Scalars['Boolean']>;
	navigationGroupTitle?: InputMaybe<Scalars['String']>;
	navigationGroupTitle_contains?: InputMaybe<Scalars['String']>;
	navigationGroupTitle_exists?: InputMaybe<Scalars['Boolean']>;
	navigationGroupTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationGroupTitle_not?: InputMaybe<Scalars['String']>;
	navigationGroupTitle_not_contains?: InputMaybe<Scalars['String']>;
	navigationGroupTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationLinksForGroup?: InputMaybe<CfnavigationLinksForGroupMultiTypeNestedFilter>;
	navigationLinksForGroupCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type FooterNavigationGroupLinkingCollections = {
	__typename?: 'FooterNavigationGroupLinkingCollections';
	adFooterCollection?: Maybe<AdFooterCollection>;
	entryCollection?: Maybe<EntryCollection>;
	headerCollection?: Maybe<HeaderCollection>;
};

export type FooterNavigationGroupLinkingCollectionsAdFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupLinkingCollectionsAdFooterCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationGroupLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationGroupLinkingCollectionsHeaderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupLinkingCollectionsHeaderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationGroupLinkingCollectionsAdFooterCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'isLightFooter_ASC'
	| 'isLightFooter_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useMobileStickyFooter_ASC'
	| 'useMobileStickyFooter_DESC';

export type FooterNavigationGroupLinkingCollectionsHeaderCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'darkHeader_ASC'
	| 'darkHeader_DESC'
	| 'hideFullSiteSearch_ASC'
	| 'hideFullSiteSearch_DESC'
	| 'hideHamburgerMenuToggleButton_ASC'
	| 'hideHamburgerMenuToggleButton_DESC'
	| 'locationBarFindOfficeUrl_ASC'
	| 'locationBarFindOfficeUrl_DESC'
	| 'locationBarWithOfficeTitle_ASC'
	| 'locationBarWithOfficeTitle_DESC'
	| 'locationBarWithOutOfficeTitle_ASC'
	| 'locationBarWithOutOfficeTitle_DESC'
	| 'myAccountLabel_ASC'
	| 'myAccountLabel_DESC'
	| 'profileLinkUrl_ASC'
	| 'profileLinkUrl_DESC'
	| 'schedulingButtonTextMobile_ASC'
	| 'schedulingButtonTextMobile_DESC'
	| 'schedulingButtonText_ASC'
	| 'schedulingButtonText_DESC'
	| 'schedulingButtonUrlMobile_ASC'
	| 'schedulingButtonUrlMobile_DESC'
	| 'schedulingButtonUrl_ASC'
	| 'schedulingButtonUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useHeaderLocationCards_ASC'
	| 'useHeaderLocationCards_DESC'
	| 'useNewLayout_ASC'
	| 'useNewLayout_DESC'
	| 'usePopupScheduling_ASC'
	| 'usePopupScheduling_DESC';

export type FooterNavigationGroupNavigationLinksForGroupCollection = {
	__typename?: 'FooterNavigationGroupNavigationLinksForGroupCollection';
	items: Array<Maybe<FooterNavigationGroupNavigationLinksForGroupItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FooterNavigationGroupNavigationLinksForGroupFilter = {
	AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupNavigationLinksForGroupFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupNavigationLinksForGroupFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type FooterNavigationGroupNavigationLinksForGroupItem = NavigationLink | Page;

export type FooterNavigationGroupOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'navigationGroupTitle_ASC'
	| 'navigationGroupTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Footer Navigation Group Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroupV2) */
export type FooterNavigationGroupV2 = Entry &
	_Node & {
		__typename?: 'FooterNavigationGroupV2';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<FooterNavigationGroupV2LinkingCollections>;
		name?: Maybe<Scalars['String']>;
		navigationGroupLink?: Maybe<FooterNavigationGroupV2NavigationGroupLink>;
		navigationLinksForGroupCollection?: Maybe<FooterNavigationGroupV2NavigationLinksForGroupCollection>;
		sys: Sys;
	};

/** Footer Navigation Group Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroupV2) */
export type FooterNavigationGroupV2LinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Footer Navigation Group Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroupV2) */
export type FooterNavigationGroupV2NameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Footer Navigation Group Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroupV2) */
export type FooterNavigationGroupV2NavigationGroupLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FooterNavigationGroupV2NavigationGroupLinkFilter>;
};

/** Footer Navigation Group Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerNavigationGroupV2) */
export type FooterNavigationGroupV2NavigationLinksForGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FooterNavigationGroupV2NavigationLinksForGroupFilter>;
};

export type FooterNavigationGroupV2Collection = {
	__typename?: 'FooterNavigationGroupV2Collection';
	items: Array<Maybe<FooterNavigationGroupV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FooterNavigationGroupV2Filter = {
	AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupV2Filter>>>;
	OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupV2Filter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationGroupLink?: InputMaybe<CfnavigationGroupLinkMultiTypeNestedFilter>;
	navigationGroupLink_exists?: InputMaybe<Scalars['Boolean']>;
	navigationLinksForGroup?: InputMaybe<CfnavigationLinksForGroupMultiTypeNestedFilter>;
	navigationLinksForGroupCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type FooterNavigationGroupV2LinkingCollections = {
	__typename?: 'FooterNavigationGroupV2LinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	footerV2Collection?: Maybe<FooterV2Collection>;
};

export type FooterNavigationGroupV2LinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationGroupV2LinkingCollectionsFooterV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupV2LinkingCollectionsFooterV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationGroupV2LinkingCollectionsFooterV2CollectionOrder =
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FooterNavigationGroupV2NavigationGroupLink = NavigationLink | Page;

export type FooterNavigationGroupV2NavigationGroupLinkFilter = {
	AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupV2NavigationGroupLinkFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupV2NavigationGroupLinkFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type FooterNavigationGroupV2NavigationLinksForGroupCollection = {
	__typename?: 'FooterNavigationGroupV2NavigationLinksForGroupCollection';
	items: Array<Maybe<FooterNavigationGroupV2NavigationLinksForGroupItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FooterNavigationGroupV2NavigationLinksForGroupFilter = {
	AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupV2NavigationLinksForGroupFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupV2NavigationLinksForGroupFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type FooterNavigationGroupV2NavigationLinksForGroupItem = NavigationLink | Page;

export type FooterNavigationGroupV2Order =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2 = Entry &
	_Node & {
		__typename?: 'FooterV2';
		_id: Scalars['ID'];
		about?: Maybe<FooterV2About>;
		bookNowButton?: Maybe<Button>;
		contactNumber?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		copyright?: Maybe<Scalars['String']>;
		exclusivelyAt?: Maybe<Entry>;
		linkedFrom?: Maybe<FooterV2LinkingCollections>;
		logo?: Maybe<Asset>;
		name?: Maybe<Scalars['String']>;
		navigationGroupsCollection?: Maybe<FooterV2NavigationGroupsCollection>;
		socialNetworksCollection?: Maybe<FooterV2SocialNetworksCollection>;
		sys: Sys;
	};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2AboutArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2BookNowButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2ContactNumberArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2CopyrightArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2ExclusivelyAtArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2LinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2LogoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2NameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2NavigationGroupsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FooterV2NavigationGroupsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FooterNavigationGroupV2Filter>;
};

/** Not in Use: Footer Component for Next.js sites [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/footerV2) */
export type FooterV2SocialNetworksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FooterV2SocialNetworksFilter>;
};

export type FooterV2About = {
	__typename?: 'FooterV2About';
	json: Scalars['JSON'];
	links: FooterV2AboutLinks;
};

export type FooterV2AboutAssets = {
	__typename?: 'FooterV2AboutAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type FooterV2AboutEntries = {
	__typename?: 'FooterV2AboutEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type FooterV2AboutLinks = {
	__typename?: 'FooterV2AboutLinks';
	assets: FooterV2AboutAssets;
	entries: FooterV2AboutEntries;
	resources: FooterV2AboutResources;
};

export type FooterV2AboutResources = {
	__typename?: 'FooterV2AboutResources';
	block: Array<FooterV2AboutResourcesBlock>;
	hyperlink: Array<FooterV2AboutResourcesHyperlink>;
	inline: Array<FooterV2AboutResourcesInline>;
};

export type FooterV2AboutResourcesBlock = ResourceLink & {
	__typename?: 'FooterV2AboutResourcesBlock';
	sys: ResourceSys;
};

export type FooterV2AboutResourcesHyperlink = ResourceLink & {
	__typename?: 'FooterV2AboutResourcesHyperlink';
	sys: ResourceSys;
};

export type FooterV2AboutResourcesInline = ResourceLink & {
	__typename?: 'FooterV2AboutResourcesInline';
	sys: ResourceSys;
};

export type FooterV2Collection = {
	__typename?: 'FooterV2Collection';
	items: Array<Maybe<FooterV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FooterV2Filter = {
	AND?: InputMaybe<Array<InputMaybe<FooterV2Filter>>>;
	OR?: InputMaybe<Array<InputMaybe<FooterV2Filter>>>;
	about_contains?: InputMaybe<Scalars['String']>;
	about_exists?: InputMaybe<Scalars['Boolean']>;
	about_not_contains?: InputMaybe<Scalars['String']>;
	bookNowButton?: InputMaybe<CfButtonNestedFilter>;
	bookNowButton_exists?: InputMaybe<Scalars['Boolean']>;
	contactNumber?: InputMaybe<Scalars['String']>;
	contactNumber_contains?: InputMaybe<Scalars['String']>;
	contactNumber_exists?: InputMaybe<Scalars['Boolean']>;
	contactNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contactNumber_not?: InputMaybe<Scalars['String']>;
	contactNumber_not_contains?: InputMaybe<Scalars['String']>;
	contactNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	copyright?: InputMaybe<Scalars['String']>;
	copyright_contains?: InputMaybe<Scalars['String']>;
	copyright_exists?: InputMaybe<Scalars['Boolean']>;
	copyright_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	copyright_not?: InputMaybe<Scalars['String']>;
	copyright_not_contains?: InputMaybe<Scalars['String']>;
	copyright_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	exclusivelyAt_exists?: InputMaybe<Scalars['Boolean']>;
	logo_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationGroups?: InputMaybe<CfFooterNavigationGroupV2NestedFilter>;
	navigationGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	socialNetworks?: InputMaybe<CfsocialNetworksMultiTypeNestedFilter>;
	socialNetworksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type FooterV2LinkingCollections = {
	__typename?: 'FooterV2LinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type FooterV2LinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FooterV2NavigationGroupsCollection = {
	__typename?: 'FooterV2NavigationGroupsCollection';
	items: Array<Maybe<FooterNavigationGroupV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FooterV2NavigationGroupsCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FooterV2Order =
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FooterV2SocialNetworksCollection = {
	__typename?: 'FooterV2SocialNetworksCollection';
	items: Array<Maybe<FooterV2SocialNetworksItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FooterV2SocialNetworksFilter = {
	AND?: InputMaybe<Array<InputMaybe<FooterV2SocialNetworksFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FooterV2SocialNetworksFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type FooterV2SocialNetworksItem = NavigationLink | Page;

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownField = Entry &
	_Node & {
		__typename?: 'FormDropdownField';
		_id: Scalars['ID'];
		alertMessage?: Maybe<Scalars['String']>;
		apiKey?: Maybe<Scalars['String']>;
		clearTextItem?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		itemList?: Maybe<Array<Maybe<Scalars['String']>>>;
		itemsCollection?: Maybe<FormDropdownFieldItemsCollection>;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<FormDropdownFieldLinkingCollections>;
		required?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldAlertMessageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldApiKeyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldClearTextItemArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldItemListArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldItemsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormDropdownFieldItemsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FormDropdownListItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldRequiredArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownField) */
export type FormDropdownFieldTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type FormDropdownFieldCollection = {
	__typename?: 'FormDropdownFieldCollection';
	items: Array<Maybe<FormDropdownField>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FormDropdownFieldFilter = {
	AND?: InputMaybe<Array<InputMaybe<FormDropdownFieldFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FormDropdownFieldFilter>>>;
	alertMessage?: InputMaybe<Scalars['String']>;
	alertMessage_contains?: InputMaybe<Scalars['String']>;
	alertMessage_exists?: InputMaybe<Scalars['Boolean']>;
	alertMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	alertMessage_not?: InputMaybe<Scalars['String']>;
	alertMessage_not_contains?: InputMaybe<Scalars['String']>;
	alertMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiKey?: InputMaybe<Scalars['String']>;
	apiKey_contains?: InputMaybe<Scalars['String']>;
	apiKey_exists?: InputMaybe<Scalars['Boolean']>;
	apiKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiKey_not?: InputMaybe<Scalars['String']>;
	apiKey_not_contains?: InputMaybe<Scalars['String']>;
	apiKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clearTextItem?: InputMaybe<Scalars['String']>;
	clearTextItem_contains?: InputMaybe<Scalars['String']>;
	clearTextItem_exists?: InputMaybe<Scalars['Boolean']>;
	clearTextItem_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clearTextItem_not?: InputMaybe<Scalars['String']>;
	clearTextItem_not_contains?: InputMaybe<Scalars['String']>;
	clearTextItem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	itemList_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	itemList_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	itemList_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	itemList_exists?: InputMaybe<Scalars['Boolean']>;
	items?: InputMaybe<CfFormDropdownListItemNestedFilter>;
	itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	required?: InputMaybe<Scalars['Boolean']>;
	required_exists?: InputMaybe<Scalars['Boolean']>;
	required_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FormDropdownFieldItemsCollection = {
	__typename?: 'FormDropdownFieldItemsCollection';
	items: Array<Maybe<FormDropdownListItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FormDropdownFieldItemsCollectionOrder =
	| 'divider_ASC'
	| 'divider_DESC'
	| 'iconBoolean_ASC'
	| 'iconBoolean_DESC'
	| 'iconName_ASC'
	| 'iconName_DESC'
	| 'itemText_ASC'
	| 'itemText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FormDropdownFieldLinkingCollections = {
	__typename?: 'FormDropdownFieldLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	questionnaireCollection?: Maybe<QuestionnaireCollection>;
	schemaStepCollection?: Maybe<SchemaStepCollection>;
};

export type FormDropdownFieldLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FormDropdownFieldLinkingCollectionsQuestionnaireCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormDropdownFieldLinkingCollectionsQuestionnaireCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FormDropdownFieldLinkingCollectionsSchemaStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormDropdownFieldLinkingCollectionsSchemaStepCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FormDropdownFieldLinkingCollectionsQuestionnaireCollectionOrder =
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'defaultErrorMessage_ASC'
	| 'defaultErrorMessage_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subTitle_ASC'
	| 'subTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'text_ASC'
	| 'text_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FormDropdownFieldLinkingCollectionsSchemaStepCollectionOrder =
	| 'formTitle_ASC'
	| 'formTitle_DESC'
	| 'stepNumber_ASC'
	| 'stepNumber_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FormDropdownFieldOrder =
	| 'alertMessage_ASC'
	| 'alertMessage_DESC'
	| 'apiKey_ASC'
	| 'apiKey_DESC'
	| 'clearTextItem_ASC'
	| 'clearTextItem_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'required_ASC'
	| 'required_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownListItem) */
export type FormDropdownListItem = Entry &
	_Node & {
		__typename?: 'FormDropdownListItem';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		divider?: Maybe<Scalars['Boolean']>;
		iconBoolean?: Maybe<Scalars['Boolean']>;
		iconName?: Maybe<Scalars['String']>;
		itemText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<FormDropdownListItemLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownListItem) */
export type FormDropdownListItemDividerArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownListItem) */
export type FormDropdownListItemIconBooleanArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownListItem) */
export type FormDropdownListItemIconNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownListItem) */
export type FormDropdownListItemItemTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownListItem) */
export type FormDropdownListItemLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formDropdownListItem) */
export type FormDropdownListItemNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type FormDropdownListItemCollection = {
	__typename?: 'FormDropdownListItemCollection';
	items: Array<Maybe<FormDropdownListItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FormDropdownListItemFilter = {
	AND?: InputMaybe<Array<InputMaybe<FormDropdownListItemFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FormDropdownListItemFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	divider?: InputMaybe<Scalars['Boolean']>;
	divider_exists?: InputMaybe<Scalars['Boolean']>;
	divider_not?: InputMaybe<Scalars['Boolean']>;
	iconBoolean?: InputMaybe<Scalars['Boolean']>;
	iconBoolean_exists?: InputMaybe<Scalars['Boolean']>;
	iconBoolean_not?: InputMaybe<Scalars['Boolean']>;
	iconName?: InputMaybe<Scalars['String']>;
	iconName_contains?: InputMaybe<Scalars['String']>;
	iconName_exists?: InputMaybe<Scalars['Boolean']>;
	iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconName_not?: InputMaybe<Scalars['String']>;
	iconName_not_contains?: InputMaybe<Scalars['String']>;
	iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	itemText?: InputMaybe<Scalars['String']>;
	itemText_contains?: InputMaybe<Scalars['String']>;
	itemText_exists?: InputMaybe<Scalars['Boolean']>;
	itemText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	itemText_not?: InputMaybe<Scalars['String']>;
	itemText_not_contains?: InputMaybe<Scalars['String']>;
	itemText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type FormDropdownListItemLinkingCollections = {
	__typename?: 'FormDropdownListItemLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	formDropdownFieldCollection?: Maybe<FormDropdownFieldCollection>;
};

export type FormDropdownListItemLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FormDropdownListItemLinkingCollectionsFormDropdownFieldCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormDropdownListItemLinkingCollectionsFormDropdownFieldCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FormDropdownListItemLinkingCollectionsFormDropdownFieldCollectionOrder =
	| 'alertMessage_ASC'
	| 'alertMessage_DESC'
	| 'apiKey_ASC'
	| 'apiKey_DESC'
	| 'clearTextItem_ASC'
	| 'clearTextItem_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'required_ASC'
	| 'required_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FormDropdownListItemOrder =
	| 'divider_ASC'
	| 'divider_DESC'
	| 'iconBoolean_ASC'
	| 'iconBoolean_DESC'
	| 'iconName_ASC'
	| 'iconName_DESC'
	| 'itemText_ASC'
	| 'itemText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormField = Entry &
	_Node & {
		__typename?: 'FormField';
		_id: Scalars['ID'];
		alertMessage?: Maybe<Scalars['String']>;
		apiKey?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		image?: Maybe<Asset>;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<FormFieldLinkingCollections>;
		onSubmitFormUrl?: Maybe<Scalars['String']>;
		placeholder?: Maybe<Scalars['String']>;
		required?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		type?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldAlertMessageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldApiKeyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldOnSubmitFormUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldPlaceholderArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldRequiredArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/formField) */
export type FormFieldTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type FormFieldCollection = {
	__typename?: 'FormFieldCollection';
	items: Array<Maybe<FormField>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type FormFieldFilter = {
	AND?: InputMaybe<Array<InputMaybe<FormFieldFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<FormFieldFilter>>>;
	alertMessage?: InputMaybe<Scalars['String']>;
	alertMessage_contains?: InputMaybe<Scalars['String']>;
	alertMessage_exists?: InputMaybe<Scalars['Boolean']>;
	alertMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	alertMessage_not?: InputMaybe<Scalars['String']>;
	alertMessage_not_contains?: InputMaybe<Scalars['String']>;
	alertMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiKey?: InputMaybe<Scalars['String']>;
	apiKey_contains?: InputMaybe<Scalars['String']>;
	apiKey_exists?: InputMaybe<Scalars['Boolean']>;
	apiKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiKey_not?: InputMaybe<Scalars['String']>;
	apiKey_not_contains?: InputMaybe<Scalars['String']>;
	apiKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onSubmitFormUrl?: InputMaybe<Scalars['String']>;
	onSubmitFormUrl_contains?: InputMaybe<Scalars['String']>;
	onSubmitFormUrl_exists?: InputMaybe<Scalars['Boolean']>;
	onSubmitFormUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onSubmitFormUrl_not?: InputMaybe<Scalars['String']>;
	onSubmitFormUrl_not_contains?: InputMaybe<Scalars['String']>;
	onSubmitFormUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	placeholder?: InputMaybe<Scalars['String']>;
	placeholder_contains?: InputMaybe<Scalars['String']>;
	placeholder_exists?: InputMaybe<Scalars['Boolean']>;
	placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	placeholder_not?: InputMaybe<Scalars['String']>;
	placeholder_not_contains?: InputMaybe<Scalars['String']>;
	placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	required?: InputMaybe<Scalars['Boolean']>;
	required_exists?: InputMaybe<Scalars['Boolean']>;
	required_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type?: InputMaybe<Scalars['String']>;
	type_contains?: InputMaybe<Scalars['String']>;
	type_exists?: InputMaybe<Scalars['Boolean']>;
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type_not?: InputMaybe<Scalars['String']>;
	type_not_contains?: InputMaybe<Scalars['String']>;
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FormFieldLinkingCollections = {
	__typename?: 'FormFieldLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	questionnaireCollection?: Maybe<QuestionnaireCollection>;
	schemaStepCollection?: Maybe<SchemaStepCollection>;
};

export type FormFieldLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FormFieldLinkingCollectionsQuestionnaireCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormFieldLinkingCollectionsQuestionnaireCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FormFieldLinkingCollectionsSchemaStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormFieldLinkingCollectionsSchemaStepCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type FormFieldLinkingCollectionsQuestionnaireCollectionOrder =
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'defaultErrorMessage_ASC'
	| 'defaultErrorMessage_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subTitle_ASC'
	| 'subTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'text_ASC'
	| 'text_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type FormFieldLinkingCollectionsSchemaStepCollectionOrder =
	| 'formTitle_ASC'
	| 'formTitle_DESC'
	| 'stepNumber_ASC'
	| 'stepNumber_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type FormFieldOrder =
	| 'alertMessage_ASC'
	| 'alertMessage_DESC'
	| 'apiKey_ASC'
	| 'apiKey_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'onSubmitFormUrl_ASC'
	| 'onSubmitFormUrl_DESC'
	| 'placeholder_ASC'
	| 'placeholder_DESC'
	| 'required_ASC'
	| 'required_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type FreeTextAnswer = HasAnswerFormat & {
	__typename?: 'FreeTextAnswer';
	format?: Maybe<QuestionnaireAnswerFormat>;
};

export type FreeTextQuestionnaireAnswerInput = {
	text: Scalars['NonEmptyString'];
};

export type FreeTextQuestionnaireAnswerScheduleInput = {
	text: Scalars['NonEmptyString'];
};

export type GeneralVisitReasonType =
	| 'BROKEN_TOOTH_TOOTH_PAIN'
	| 'CLEAR_ALIGNERS'
	| 'DENTURES'
	| 'EMERGENCY'
	| 'HEALTHY_MOUTH_MOVEMENT'
	| 'IMPLANTS'
	| 'OTHER'
	| 'UNKNOWN_REASON';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplate = Entry &
	_Node & {
		__typename?: 'GoogleMapTemplate';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		defaultImage?: Maybe<Asset>;
		desktopZoom?: Maybe<Scalars['Float']>;
		facilityBadgeLabel?: Maybe<Scalars['String']>;
		facilityBasePath?: Maybe<Scalars['String']>;
		facilityButtonLabel?: Maybe<Scalars['String']>;
		googleMapsBusinessQuery?: Maybe<Scalars['String']>;
		holidayHoursMessageText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<GoogleMapTemplateLinkingCollections>;
		listTabIsHorizontalScrolling?: Maybe<Scalars['Boolean']>;
		locationScheduleButtonUrl?: Maybe<Scalars['String']>;
		mobileZoom?: Maybe<Scalars['Float']>;
		name?: Maybe<Scalars['String']>;
		noResultsFoundMessage?: Maybe<GoogleMapTemplateNoResultsFoundMessage>;
		officeDetailsButton?: Maybe<Button>;
		scheduleButton?: Maybe<Button>;
		searchRadius?: Maybe<Scalars['Int']>;
		searchResultLimit?: Maybe<Scalars['Int']>;
		selectedImage?: Maybe<Asset>;
		service?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
		useUsaCountryMap?: Maybe<Scalars['Boolean']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateDefaultImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateDesktopZoomArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateFacilityBadgeLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateFacilityBasePathArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateFacilityButtonLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateGoogleMapsBusinessQueryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateHolidayHoursMessageTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateListTabIsHorizontalScrollingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateLocationScheduleButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateMobileZoomArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateNoResultsFoundMessageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateOfficeDetailsButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateScheduleButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateSearchRadiusArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateSearchResultLimitArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateSelectedImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateServiceArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/googleMapTemplate) */
export type GoogleMapTemplateUseUsaCountryMapArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type GoogleMapTemplateCollection = {
	__typename?: 'GoogleMapTemplateCollection';
	items: Array<Maybe<GoogleMapTemplate>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type GoogleMapTemplateFilter = {
	AND?: InputMaybe<Array<InputMaybe<GoogleMapTemplateFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<GoogleMapTemplateFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	defaultImage_exists?: InputMaybe<Scalars['Boolean']>;
	desktopZoom?: InputMaybe<Scalars['Float']>;
	desktopZoom_exists?: InputMaybe<Scalars['Boolean']>;
	desktopZoom_gt?: InputMaybe<Scalars['Float']>;
	desktopZoom_gte?: InputMaybe<Scalars['Float']>;
	desktopZoom_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	desktopZoom_lt?: InputMaybe<Scalars['Float']>;
	desktopZoom_lte?: InputMaybe<Scalars['Float']>;
	desktopZoom_not?: InputMaybe<Scalars['Float']>;
	desktopZoom_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	facilityBadgeLabel?: InputMaybe<Scalars['String']>;
	facilityBadgeLabel_contains?: InputMaybe<Scalars['String']>;
	facilityBadgeLabel_exists?: InputMaybe<Scalars['Boolean']>;
	facilityBadgeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityBadgeLabel_not?: InputMaybe<Scalars['String']>;
	facilityBadgeLabel_not_contains?: InputMaybe<Scalars['String']>;
	facilityBadgeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityBasePath?: InputMaybe<Scalars['String']>;
	facilityBasePath_contains?: InputMaybe<Scalars['String']>;
	facilityBasePath_exists?: InputMaybe<Scalars['Boolean']>;
	facilityBasePath_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityBasePath_not?: InputMaybe<Scalars['String']>;
	facilityBasePath_not_contains?: InputMaybe<Scalars['String']>;
	facilityBasePath_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityButtonLabel?: InputMaybe<Scalars['String']>;
	facilityButtonLabel_contains?: InputMaybe<Scalars['String']>;
	facilityButtonLabel_exists?: InputMaybe<Scalars['Boolean']>;
	facilityButtonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityButtonLabel_not?: InputMaybe<Scalars['String']>;
	facilityButtonLabel_not_contains?: InputMaybe<Scalars['String']>;
	facilityButtonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	googleMapsBusinessQuery?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_exists?: InputMaybe<Scalars['Boolean']>;
	googleMapsBusinessQuery_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	googleMapsBusinessQuery_not?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	holidayHoursMessageText?: InputMaybe<Scalars['String']>;
	holidayHoursMessageText_contains?: InputMaybe<Scalars['String']>;
	holidayHoursMessageText_exists?: InputMaybe<Scalars['Boolean']>;
	holidayHoursMessageText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	holidayHoursMessageText_not?: InputMaybe<Scalars['String']>;
	holidayHoursMessageText_not_contains?: InputMaybe<Scalars['String']>;
	holidayHoursMessageText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	listTabIsHorizontalScrolling?: InputMaybe<Scalars['Boolean']>;
	listTabIsHorizontalScrolling_exists?: InputMaybe<Scalars['Boolean']>;
	listTabIsHorizontalScrolling_not?: InputMaybe<Scalars['Boolean']>;
	locationScheduleButtonUrl?: InputMaybe<Scalars['String']>;
	locationScheduleButtonUrl_contains?: InputMaybe<Scalars['String']>;
	locationScheduleButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	locationScheduleButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationScheduleButtonUrl_not?: InputMaybe<Scalars['String']>;
	locationScheduleButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
	locationScheduleButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileZoom?: InputMaybe<Scalars['Float']>;
	mobileZoom_exists?: InputMaybe<Scalars['Boolean']>;
	mobileZoom_gt?: InputMaybe<Scalars['Float']>;
	mobileZoom_gte?: InputMaybe<Scalars['Float']>;
	mobileZoom_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	mobileZoom_lt?: InputMaybe<Scalars['Float']>;
	mobileZoom_lte?: InputMaybe<Scalars['Float']>;
	mobileZoom_not?: InputMaybe<Scalars['Float']>;
	mobileZoom_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	noResultsFoundMessage_contains?: InputMaybe<Scalars['String']>;
	noResultsFoundMessage_exists?: InputMaybe<Scalars['Boolean']>;
	noResultsFoundMessage_not_contains?: InputMaybe<Scalars['String']>;
	officeDetailsButton?: InputMaybe<CfButtonNestedFilter>;
	officeDetailsButton_exists?: InputMaybe<Scalars['Boolean']>;
	scheduleButton?: InputMaybe<CfButtonNestedFilter>;
	scheduleButton_exists?: InputMaybe<Scalars['Boolean']>;
	searchRadius?: InputMaybe<Scalars['Int']>;
	searchRadius_exists?: InputMaybe<Scalars['Boolean']>;
	searchRadius_gt?: InputMaybe<Scalars['Int']>;
	searchRadius_gte?: InputMaybe<Scalars['Int']>;
	searchRadius_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	searchRadius_lt?: InputMaybe<Scalars['Int']>;
	searchRadius_lte?: InputMaybe<Scalars['Int']>;
	searchRadius_not?: InputMaybe<Scalars['Int']>;
	searchRadius_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	searchResultLimit?: InputMaybe<Scalars['Int']>;
	searchResultLimit_exists?: InputMaybe<Scalars['Boolean']>;
	searchResultLimit_gt?: InputMaybe<Scalars['Int']>;
	searchResultLimit_gte?: InputMaybe<Scalars['Int']>;
	searchResultLimit_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	searchResultLimit_lt?: InputMaybe<Scalars['Int']>;
	searchResultLimit_lte?: InputMaybe<Scalars['Int']>;
	searchResultLimit_not?: InputMaybe<Scalars['Int']>;
	searchResultLimit_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	selectedImage_exists?: InputMaybe<Scalars['Boolean']>;
	service?: InputMaybe<Scalars['String']>;
	service_contains?: InputMaybe<Scalars['String']>;
	service_exists?: InputMaybe<Scalars['Boolean']>;
	service_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	service_not?: InputMaybe<Scalars['String']>;
	service_not_contains?: InputMaybe<Scalars['String']>;
	service_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	useUsaCountryMap?: InputMaybe<Scalars['Boolean']>;
	useUsaCountryMap_exists?: InputMaybe<Scalars['Boolean']>;
	useUsaCountryMap_not?: InputMaybe<Scalars['Boolean']>;
};

export type GoogleMapTemplateLinkingCollections = {
	__typename?: 'GoogleMapTemplateLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type GoogleMapTemplateLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type GoogleMapTemplateLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<GoogleMapTemplateLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type GoogleMapTemplateLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<GoogleMapTemplateLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type GoogleMapTemplateLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<GoogleMapTemplateLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type GoogleMapTemplateLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type GoogleMapTemplateLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type GoogleMapTemplateLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type GoogleMapTemplateNoResultsFoundMessage = {
	__typename?: 'GoogleMapTemplateNoResultsFoundMessage';
	json: Scalars['JSON'];
	links: GoogleMapTemplateNoResultsFoundMessageLinks;
};

export type GoogleMapTemplateNoResultsFoundMessageAssets = {
	__typename?: 'GoogleMapTemplateNoResultsFoundMessageAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type GoogleMapTemplateNoResultsFoundMessageEntries = {
	__typename?: 'GoogleMapTemplateNoResultsFoundMessageEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type GoogleMapTemplateNoResultsFoundMessageLinks = {
	__typename?: 'GoogleMapTemplateNoResultsFoundMessageLinks';
	assets: GoogleMapTemplateNoResultsFoundMessageAssets;
	entries: GoogleMapTemplateNoResultsFoundMessageEntries;
	resources: GoogleMapTemplateNoResultsFoundMessageResources;
};

export type GoogleMapTemplateNoResultsFoundMessageResources = {
	__typename?: 'GoogleMapTemplateNoResultsFoundMessageResources';
	block: Array<GoogleMapTemplateNoResultsFoundMessageResourcesBlock>;
	hyperlink: Array<GoogleMapTemplateNoResultsFoundMessageResourcesHyperlink>;
	inline: Array<GoogleMapTemplateNoResultsFoundMessageResourcesInline>;
};

export type GoogleMapTemplateNoResultsFoundMessageResourcesBlock = ResourceLink & {
	__typename?: 'GoogleMapTemplateNoResultsFoundMessageResourcesBlock';
	sys: ResourceSys;
};

export type GoogleMapTemplateNoResultsFoundMessageResourcesHyperlink = ResourceLink & {
	__typename?: 'GoogleMapTemplateNoResultsFoundMessageResourcesHyperlink';
	sys: ResourceSys;
};

export type GoogleMapTemplateNoResultsFoundMessageResourcesInline = ResourceLink & {
	__typename?: 'GoogleMapTemplateNoResultsFoundMessageResourcesInline';
	sys: ResourceSys;
};

export type GoogleMapTemplateOrder =
	| 'desktopZoom_ASC'
	| 'desktopZoom_DESC'
	| 'facilityBadgeLabel_ASC'
	| 'facilityBadgeLabel_DESC'
	| 'facilityBasePath_ASC'
	| 'facilityBasePath_DESC'
	| 'facilityButtonLabel_ASC'
	| 'facilityButtonLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'holidayHoursMessageText_ASC'
	| 'holidayHoursMessageText_DESC'
	| 'listTabIsHorizontalScrolling_ASC'
	| 'listTabIsHorizontalScrolling_DESC'
	| 'locationScheduleButtonUrl_ASC'
	| 'locationScheduleButtonUrl_DESC'
	| 'mobileZoom_ASC'
	| 'mobileZoom_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'searchRadius_ASC'
	| 'searchRadius_DESC'
	| 'searchResultLimit_ASC'
	| 'searchResultLimit_DESC'
	| 'service_ASC'
	| 'service_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'useUsaCountryMap_ASC'
	| 'useUsaCountryMap_DESC';

export type Guarantor = {
	__typename?: 'Guarantor';
	personalInformation?: Maybe<PersonalInformation>;
	relation?: Maybe<PatientRelationToGuarantor>;
};

export type GuarantorInput = {
	nonExistingGuarantor?: InputMaybe<NewGuarantorInput>;
};

export type GuarantorRequiredError = Error & {
	__typename?: 'GuarantorRequiredError';
	message: Scalars['String'];
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreen = Entry &
	_Node & {
		__typename?: 'HalfScreen';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		body?: Maybe<HalfScreenBody>;
		buttonCollection?: Maybe<HalfScreenButtonCollection>;
		contentSmallScreen?: Maybe<SmallScreenContent>;
		contentfulMetadata: ContentfulMetadata;
		disclaimer?: Maybe<DisclaimerV2>;
		eyebrow?: Maybe<Scalars['String']>;
		eyebrowStyle?: Maybe<Scalars['String']>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		heading?: Maybe<HalfScreenHeading>;
		hideContentHalf?: Maybe<Scalars['Boolean']>;
		image?: Maybe<Asset>;
		imageAsset?: Maybe<ImageAsset>;
		imageAssetTwo?: Maybe<ImageAsset>;
		isInsetLayout?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<HalfScreenLinkingCollections>;
		mediaFirst?: Maybe<Scalars['Boolean']>;
		name?: Maybe<Scalars['String']>;
		subHeading?: Maybe<HalfScreenSubHeading>;
		sys: Sys;
		templateSize?: Maybe<Scalars['String']>;
		termsAndConditions?: Maybe<TermsAndConditions>;
		useStaticImageSize?: Maybe<Scalars['Boolean']>;
		video?: Maybe<Video>;
		videoAsset?: Maybe<VideoAsset>;
	};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenBodyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HalfScreenButtonCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenContentSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<SmallScreenContentFilter>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenDisclaimerArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<DisclaimerV2Filter>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenEyebrowStyleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenFacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenHideContentHalfArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenImageAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenImageAssetTwoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenIsInsetLayoutArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenMediaFirstArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenSubHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenTemplateSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenTermsAndConditionsArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<TermsAndConditionsFilter>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenUseStaticImageSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenVideoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoFilter>;
};

/**
 * HalfScreen template:
 *
 * https://www.figma.com/file/CzH3RUoUxD7Kihz8tfwuI6/2023-Redesign-Templates?node-id=19%3A198&t=tBH0ZaSVvcii3NoH-0 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/halfScreen)
 */
export type HalfScreenVideoAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoAssetFilter>;
};

export type HalfScreenBody = {
	__typename?: 'HalfScreenBody';
	json: Scalars['JSON'];
	links: HalfScreenBodyLinks;
};

export type HalfScreenBodyAssets = {
	__typename?: 'HalfScreenBodyAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type HalfScreenBodyEntries = {
	__typename?: 'HalfScreenBodyEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type HalfScreenBodyLinks = {
	__typename?: 'HalfScreenBodyLinks';
	assets: HalfScreenBodyAssets;
	entries: HalfScreenBodyEntries;
	resources: HalfScreenBodyResources;
};

export type HalfScreenBodyResources = {
	__typename?: 'HalfScreenBodyResources';
	block: Array<HalfScreenBodyResourcesBlock>;
	hyperlink: Array<HalfScreenBodyResourcesHyperlink>;
	inline: Array<HalfScreenBodyResourcesInline>;
};

export type HalfScreenBodyResourcesBlock = ResourceLink & {
	__typename?: 'HalfScreenBodyResourcesBlock';
	sys: ResourceSys;
};

export type HalfScreenBodyResourcesHyperlink = ResourceLink & {
	__typename?: 'HalfScreenBodyResourcesHyperlink';
	sys: ResourceSys;
};

export type HalfScreenBodyResourcesInline = ResourceLink & {
	__typename?: 'HalfScreenBodyResourcesInline';
	sys: ResourceSys;
};

export type HalfScreenButtonCollection = {
	__typename?: 'HalfScreenButtonCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HalfScreenButtonCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type HalfScreenCollection = {
	__typename?: 'HalfScreenCollection';
	items: Array<Maybe<HalfScreen>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HalfScreenFilter = {
	AND?: InputMaybe<Array<InputMaybe<HalfScreenFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HalfScreenFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	body_contains?: InputMaybe<Scalars['String']>;
	body_exists?: InputMaybe<Scalars['Boolean']>;
	body_not_contains?: InputMaybe<Scalars['String']>;
	button?: InputMaybe<CfButtonNestedFilter>;
	buttonCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentSmallScreen?: InputMaybe<CfSmallScreenContentNestedFilter>;
	contentSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disclaimer?: InputMaybe<CfDisclaimerV2NestedFilter>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrowStyle?: InputMaybe<Scalars['String']>;
	eyebrowStyle_contains?: InputMaybe<Scalars['String']>;
	eyebrowStyle_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowStyle_not?: InputMaybe<Scalars['String']>;
	eyebrowStyle_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	heading_contains?: InputMaybe<Scalars['String']>;
	heading_exists?: InputMaybe<Scalars['Boolean']>;
	heading_not_contains?: InputMaybe<Scalars['String']>;
	hideContentHalf?: InputMaybe<Scalars['Boolean']>;
	hideContentHalf_exists?: InputMaybe<Scalars['Boolean']>;
	hideContentHalf_not?: InputMaybe<Scalars['Boolean']>;
	imageAsset?: InputMaybe<CfImageAssetNestedFilter>;
	imageAssetTwo?: InputMaybe<CfImageAssetNestedFilter>;
	imageAssetTwo_exists?: InputMaybe<Scalars['Boolean']>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	isInsetLayout?: InputMaybe<Scalars['Boolean']>;
	isInsetLayout_exists?: InputMaybe<Scalars['Boolean']>;
	isInsetLayout_not?: InputMaybe<Scalars['Boolean']>;
	mediaFirst?: InputMaybe<Scalars['Boolean']>;
	mediaFirst_exists?: InputMaybe<Scalars['Boolean']>;
	mediaFirst_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subHeading_contains?: InputMaybe<Scalars['String']>;
	subHeading_exists?: InputMaybe<Scalars['Boolean']>;
	subHeading_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	templateSize?: InputMaybe<Scalars['String']>;
	templateSize_contains?: InputMaybe<Scalars['String']>;
	templateSize_exists?: InputMaybe<Scalars['Boolean']>;
	templateSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateSize_not?: InputMaybe<Scalars['String']>;
	templateSize_not_contains?: InputMaybe<Scalars['String']>;
	templateSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	termsAndConditions?: InputMaybe<CfTermsAndConditionsNestedFilter>;
	termsAndConditions_exists?: InputMaybe<Scalars['Boolean']>;
	useStaticImageSize?: InputMaybe<Scalars['Boolean']>;
	useStaticImageSize_exists?: InputMaybe<Scalars['Boolean']>;
	useStaticImageSize_not?: InputMaybe<Scalars['Boolean']>;
	video?: InputMaybe<CfVideoNestedFilter>;
	videoAsset?: InputMaybe<CfVideoAssetNestedFilter>;
	videoAsset_exists?: InputMaybe<Scalars['Boolean']>;
	video_exists?: InputMaybe<Scalars['Boolean']>;
};

export type HalfScreenHeading = {
	__typename?: 'HalfScreenHeading';
	json: Scalars['JSON'];
	links: HalfScreenHeadingLinks;
};

export type HalfScreenHeadingAssets = {
	__typename?: 'HalfScreenHeadingAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type HalfScreenHeadingEntries = {
	__typename?: 'HalfScreenHeadingEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type HalfScreenHeadingLinks = {
	__typename?: 'HalfScreenHeadingLinks';
	assets: HalfScreenHeadingAssets;
	entries: HalfScreenHeadingEntries;
	resources: HalfScreenHeadingResources;
};

export type HalfScreenHeadingResources = {
	__typename?: 'HalfScreenHeadingResources';
	block: Array<HalfScreenHeadingResourcesBlock>;
	hyperlink: Array<HalfScreenHeadingResourcesHyperlink>;
	inline: Array<HalfScreenHeadingResourcesInline>;
};

export type HalfScreenHeadingResourcesBlock = ResourceLink & {
	__typename?: 'HalfScreenHeadingResourcesBlock';
	sys: ResourceSys;
};

export type HalfScreenHeadingResourcesHyperlink = ResourceLink & {
	__typename?: 'HalfScreenHeadingResourcesHyperlink';
	sys: ResourceSys;
};

export type HalfScreenHeadingResourcesInline = ResourceLink & {
	__typename?: 'HalfScreenHeadingResourcesInline';
	sys: ResourceSys;
};

export type HalfScreenLinkingCollections = {
	__typename?: 'HalfScreenLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type HalfScreenLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HalfScreenLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HalfScreenLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HalfScreenLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HalfScreenLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HalfScreenLinkingCollectionsProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HalfScreenLinkingCollectionsProviderDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HalfScreenLinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HalfScreenLinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HalfScreenLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HalfScreenLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HalfScreenLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type HalfScreenLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type HalfScreenLinkingCollectionsProviderDetailsPageCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HalfScreenLinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HalfScreenLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type HalfScreenOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'hideContentHalf_ASC'
	| 'hideContentHalf_DESC'
	| 'isInsetLayout_ASC'
	| 'isInsetLayout_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC'
	| 'useStaticImageSize_ASC'
	| 'useStaticImageSize_DESC';

export type HalfScreenSubHeading = {
	__typename?: 'HalfScreenSubHeading';
	json: Scalars['JSON'];
	links: HalfScreenSubHeadingLinks;
};

export type HalfScreenSubHeadingAssets = {
	__typename?: 'HalfScreenSubHeadingAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type HalfScreenSubHeadingEntries = {
	__typename?: 'HalfScreenSubHeadingEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type HalfScreenSubHeadingLinks = {
	__typename?: 'HalfScreenSubHeadingLinks';
	assets: HalfScreenSubHeadingAssets;
	entries: HalfScreenSubHeadingEntries;
	resources: HalfScreenSubHeadingResources;
};

export type HalfScreenSubHeadingResources = {
	__typename?: 'HalfScreenSubHeadingResources';
	block: Array<HalfScreenSubHeadingResourcesBlock>;
	hyperlink: Array<HalfScreenSubHeadingResourcesHyperlink>;
	inline: Array<HalfScreenSubHeadingResourcesInline>;
};

export type HalfScreenSubHeadingResourcesBlock = ResourceLink & {
	__typename?: 'HalfScreenSubHeadingResourcesBlock';
	sys: ResourceSys;
};

export type HalfScreenSubHeadingResourcesHyperlink = ResourceLink & {
	__typename?: 'HalfScreenSubHeadingResourcesHyperlink';
	sys: ResourceSys;
};

export type HalfScreenSubHeadingResourcesInline = ResourceLink & {
	__typename?: 'HalfScreenSubHeadingResourcesInline';
	sys: ResourceSys;
};

export type HasAnswerFormat = {
	format?: Maybe<QuestionnaireAnswerFormat>;
};

export type HasPredefinedAnswer = {
	answers?: Maybe<Array<PredefinedAnswer>>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type Header = Entry &
	_Node & {
		__typename?: 'Header';
		_id: Scalars['ID'];
		bookNowButton?: Maybe<CallToAction>;
		componentName?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		darkHeader?: Maybe<Scalars['Boolean']>;
		hideFullSiteSearch?: Maybe<Scalars['Boolean']>;
		hideHamburgerMenuToggleButton?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<HeaderLinkingCollections>;
		locationBarFindOfficeUrl?: Maybe<Scalars['String']>;
		locationBarWithOfficeTitle?: Maybe<Scalars['String']>;
		locationBarWithOutOfficeTitle?: Maybe<Scalars['String']>;
		locationNavigationLinksCollection?: Maybe<HeaderLocationNavigationLinksCollection>;
		myAccountLabel?: Maybe<Scalars['String']>;
		navigationLinksCollection?: Maybe<HeaderNavigationLinksCollection>;
		navigationMenu?: Maybe<HeaderNavMenu>;
		phoneNumber?: Maybe<NavigationLink>;
		profileLinkUrl?: Maybe<Scalars['String']>;
		schedulingButtonText?: Maybe<Scalars['String']>;
		schedulingButtonTextMobile?: Maybe<Scalars['String']>;
		schedulingButtonUrl?: Maybe<Scalars['String']>;
		schedulingButtonUrlMobile?: Maybe<Scalars['String']>;
		schedulingNavigationLinksCollection?: Maybe<HeaderSchedulingNavigationLinksCollection>;
		secondaryNavigationLinksCollection?: Maybe<HeaderSecondaryNavigationLinksCollection>;
		sys: Sys;
		topNavigationLinksCollection?: Maybe<HeaderTopNavigationLinksCollection>;
		useHeaderLocationCards?: Maybe<Scalars['Boolean']>;
		useNewLayout?: Maybe<Scalars['Boolean']>;
		usePopupScheduling?: Maybe<Scalars['Boolean']>;
	};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderBookNowButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<CallToActionFilter>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderComponentNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderDarkHeaderArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderHideFullSiteSearchArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderHideHamburgerMenuToggleButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderLocationBarFindOfficeUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderLocationBarWithOfficeTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderLocationBarWithOutOfficeTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderLocationNavigationLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderLocationNavigationLinksFilter>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderMyAccountLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderNavigationLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderNavigationLinksFilter>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderNavigationMenuArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<HeaderNavMenuFilter>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderPhoneNumberArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<NavigationLinkFilter>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderProfileLinkUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderSchedulingButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderSchedulingButtonTextMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderSchedulingButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderSchedulingButtonUrlMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderSchedulingNavigationLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderSchedulingNavigationLinksCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NavigationGroupFilter>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderSecondaryNavigationLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderSecondaryNavigationLinksFilter>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderTopNavigationLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderTopNavigationLinksFilter>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderUseHeaderLocationCardsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderUseNewLayoutArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Header Component [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/header) */
export type HeaderUsePopupSchedulingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type HeaderCollection = {
	__typename?: 'HeaderCollection';
	items: Array<Maybe<Header>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeaderFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeaderFilter>>>;
	bookNowButton?: InputMaybe<CfCallToActionNestedFilter>;
	bookNowButton_exists?: InputMaybe<Scalars['Boolean']>;
	componentName?: InputMaybe<Scalars['String']>;
	componentName_contains?: InputMaybe<Scalars['String']>;
	componentName_exists?: InputMaybe<Scalars['Boolean']>;
	componentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName_not?: InputMaybe<Scalars['String']>;
	componentName_not_contains?: InputMaybe<Scalars['String']>;
	componentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	darkHeader?: InputMaybe<Scalars['Boolean']>;
	darkHeader_exists?: InputMaybe<Scalars['Boolean']>;
	darkHeader_not?: InputMaybe<Scalars['Boolean']>;
	hideFullSiteSearch?: InputMaybe<Scalars['Boolean']>;
	hideFullSiteSearch_exists?: InputMaybe<Scalars['Boolean']>;
	hideFullSiteSearch_not?: InputMaybe<Scalars['Boolean']>;
	hideHamburgerMenuToggleButton?: InputMaybe<Scalars['Boolean']>;
	hideHamburgerMenuToggleButton_exists?: InputMaybe<Scalars['Boolean']>;
	hideHamburgerMenuToggleButton_not?: InputMaybe<Scalars['Boolean']>;
	locationBarFindOfficeUrl?: InputMaybe<Scalars['String']>;
	locationBarFindOfficeUrl_contains?: InputMaybe<Scalars['String']>;
	locationBarFindOfficeUrl_exists?: InputMaybe<Scalars['Boolean']>;
	locationBarFindOfficeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarFindOfficeUrl_not?: InputMaybe<Scalars['String']>;
	locationBarFindOfficeUrl_not_contains?: InputMaybe<Scalars['String']>;
	locationBarFindOfficeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarWithOfficeTitle?: InputMaybe<Scalars['String']>;
	locationBarWithOfficeTitle_contains?: InputMaybe<Scalars['String']>;
	locationBarWithOfficeTitle_exists?: InputMaybe<Scalars['Boolean']>;
	locationBarWithOfficeTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarWithOfficeTitle_not?: InputMaybe<Scalars['String']>;
	locationBarWithOfficeTitle_not_contains?: InputMaybe<Scalars['String']>;
	locationBarWithOfficeTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarWithOutOfficeTitle?: InputMaybe<Scalars['String']>;
	locationBarWithOutOfficeTitle_contains?: InputMaybe<Scalars['String']>;
	locationBarWithOutOfficeTitle_exists?: InputMaybe<Scalars['Boolean']>;
	locationBarWithOutOfficeTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarWithOutOfficeTitle_not?: InputMaybe<Scalars['String']>;
	locationBarWithOutOfficeTitle_not_contains?: InputMaybe<Scalars['String']>;
	locationBarWithOutOfficeTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationNavigationLinks?: InputMaybe<CflocationNavigationLinksMultiTypeNestedFilter>;
	locationNavigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	myAccountLabel?: InputMaybe<Scalars['String']>;
	myAccountLabel_contains?: InputMaybe<Scalars['String']>;
	myAccountLabel_exists?: InputMaybe<Scalars['Boolean']>;
	myAccountLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	myAccountLabel_not?: InputMaybe<Scalars['String']>;
	myAccountLabel_not_contains?: InputMaybe<Scalars['String']>;
	myAccountLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationLinks?: InputMaybe<CfnavigationLinksMultiTypeNestedFilter>;
	navigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	navigationMenu?: InputMaybe<CfHeaderNavMenuNestedFilter>;
	navigationMenu_exists?: InputMaybe<Scalars['Boolean']>;
	phoneNumber?: InputMaybe<CfNavigationLinkNestedFilter>;
	phoneNumber_exists?: InputMaybe<Scalars['Boolean']>;
	profileLinkUrl?: InputMaybe<Scalars['String']>;
	profileLinkUrl_contains?: InputMaybe<Scalars['String']>;
	profileLinkUrl_exists?: InputMaybe<Scalars['Boolean']>;
	profileLinkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	profileLinkUrl_not?: InputMaybe<Scalars['String']>;
	profileLinkUrl_not_contains?: InputMaybe<Scalars['String']>;
	profileLinkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonText?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile_exists?: InputMaybe<Scalars['Boolean']>;
	schedulingButtonTextMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonTextMobile_not?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile_not_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonText_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	schedulingButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonText_not?: InputMaybe<Scalars['String']>;
	schedulingButtonText_not_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonUrl?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile_exists?: InputMaybe<Scalars['Boolean']>;
	schedulingButtonUrlMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonUrlMobile_not?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile_not_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonUrl_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	schedulingButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonUrl_not?: InputMaybe<Scalars['String']>;
	schedulingButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingNavigationLinks?: InputMaybe<CfNavigationGroupNestedFilter>;
	schedulingNavigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	secondaryNavigationLinks?: InputMaybe<CfsecondaryNavigationLinksMultiTypeNestedFilter>;
	secondaryNavigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	topNavigationLinks?: InputMaybe<CftopNavigationLinksMultiTypeNestedFilter>;
	topNavigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	useHeaderLocationCards?: InputMaybe<Scalars['Boolean']>;
	useHeaderLocationCards_exists?: InputMaybe<Scalars['Boolean']>;
	useHeaderLocationCards_not?: InputMaybe<Scalars['Boolean']>;
	useNewLayout?: InputMaybe<Scalars['Boolean']>;
	useNewLayout_exists?: InputMaybe<Scalars['Boolean']>;
	useNewLayout_not?: InputMaybe<Scalars['Boolean']>;
	usePopupScheduling?: InputMaybe<Scalars['Boolean']>;
	usePopupScheduling_exists?: InputMaybe<Scalars['Boolean']>;
	usePopupScheduling_not?: InputMaybe<Scalars['Boolean']>;
};

export type HeaderLinkingCollections = {
	__typename?: 'HeaderLinkingCollections';
	appCollection?: Maybe<AppCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type HeaderLinkingCollectionsAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderLinkingCollectionsAppCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderLinkingCollectionsAppCollectionOrder =
	| 'brandImageLink_ASC'
	| 'brandImageLink_DESC'
	| 'branding_ASC'
	| 'branding_DESC'
	| 'isDeprecated_ASC'
	| 'isDeprecated_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeaderLocationNavigationLinksCollection = {
	__typename?: 'HeaderLocationNavigationLinksCollection';
	items: Array<Maybe<HeaderLocationNavigationLinksItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderLocationNavigationLinksFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeaderLocationNavigationLinksFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeaderLocationNavigationLinksFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type HeaderLocationNavigationLinksItem = NavigationLink | Page;

/** The Header Navigation Menu Link and Sub Menu(navigation groups with links) displayed on hover of this link. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerMainNav) */
export type HeaderMainNav = Entry &
	_Node & {
		__typename?: 'HeaderMainNav';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		headerMainNavLink?: Maybe<NavigationLink>;
		headerSubNavMenu?: Maybe<HeaderSubNav>;
		linkedFrom?: Maybe<HeaderMainNavLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** The Header Navigation Menu Link and Sub Menu(navigation groups with links) displayed on hover of this link. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerMainNav) */
export type HeaderMainNavHeaderMainNavLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<NavigationLinkFilter>;
};

/** The Header Navigation Menu Link and Sub Menu(navigation groups with links) displayed on hover of this link. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerMainNav) */
export type HeaderMainNavHeaderSubNavMenuArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<HeaderSubNavFilter>;
};

/** The Header Navigation Menu Link and Sub Menu(navigation groups with links) displayed on hover of this link. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerMainNav) */
export type HeaderMainNavLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The Header Navigation Menu Link and Sub Menu(navigation groups with links) displayed on hover of this link. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerMainNav) */
export type HeaderMainNavNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type HeaderMainNavCollection = {
	__typename?: 'HeaderMainNavCollection';
	items: Array<Maybe<HeaderMainNav>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderMainNavFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeaderMainNavFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeaderMainNavFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headerMainNavLink?: InputMaybe<CfNavigationLinkNestedFilter>;
	headerMainNavLink_exists?: InputMaybe<Scalars['Boolean']>;
	headerSubNavMenu?: InputMaybe<CfHeaderSubNavNestedFilter>;
	headerSubNavMenu_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type HeaderMainNavLinkingCollections = {
	__typename?: 'HeaderMainNavLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	headerNavMenuCollection?: Maybe<HeaderNavMenuCollection>;
};

export type HeaderMainNavLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderMainNavLinkingCollectionsHeaderNavMenuCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderMainNavLinkingCollectionsHeaderNavMenuCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderMainNavLinkingCollectionsHeaderNavMenuCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeaderMainNavOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** The navigation menu displayed in the header with main nav links and the sub nav groups and links. The sub nav will be displayed when hover over the main nav links. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerNavMenu) */
export type HeaderNavMenu = Entry &
	_Node & {
		__typename?: 'HeaderNavMenu';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		headerMainNavLinksCollection?: Maybe<HeaderNavMenuHeaderMainNavLinksCollection>;
		linkedFrom?: Maybe<HeaderNavMenuLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** The navigation menu displayed in the header with main nav links and the sub nav groups and links. The sub nav will be displayed when hover over the main nav links. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerNavMenu) */
export type HeaderNavMenuHeaderMainNavLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderNavMenuHeaderMainNavLinksCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderMainNavFilter>;
};

/** The navigation menu displayed in the header with main nav links and the sub nav groups and links. The sub nav will be displayed when hover over the main nav links. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerNavMenu) */
export type HeaderNavMenuLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The navigation menu displayed in the header with main nav links and the sub nav groups and links. The sub nav will be displayed when hover over the main nav links. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerNavMenu) */
export type HeaderNavMenuNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type HeaderNavMenuCollection = {
	__typename?: 'HeaderNavMenuCollection';
	items: Array<Maybe<HeaderNavMenu>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderNavMenuFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeaderNavMenuFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeaderNavMenuFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headerMainNavLinks?: InputMaybe<CfHeaderMainNavNestedFilter>;
	headerMainNavLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type HeaderNavMenuHeaderMainNavLinksCollection = {
	__typename?: 'HeaderNavMenuHeaderMainNavLinksCollection';
	items: Array<Maybe<HeaderMainNav>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderNavMenuHeaderMainNavLinksCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeaderNavMenuLinkingCollections = {
	__typename?: 'HeaderNavMenuLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	headerCollection?: Maybe<HeaderCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type HeaderNavMenuLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderNavMenuLinkingCollectionsHeaderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderNavMenuLinkingCollectionsHeaderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderNavMenuLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderNavMenuLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderNavMenuLinkingCollectionsHeaderCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'darkHeader_ASC'
	| 'darkHeader_DESC'
	| 'hideFullSiteSearch_ASC'
	| 'hideFullSiteSearch_DESC'
	| 'hideHamburgerMenuToggleButton_ASC'
	| 'hideHamburgerMenuToggleButton_DESC'
	| 'locationBarFindOfficeUrl_ASC'
	| 'locationBarFindOfficeUrl_DESC'
	| 'locationBarWithOfficeTitle_ASC'
	| 'locationBarWithOfficeTitle_DESC'
	| 'locationBarWithOutOfficeTitle_ASC'
	| 'locationBarWithOutOfficeTitle_DESC'
	| 'myAccountLabel_ASC'
	| 'myAccountLabel_DESC'
	| 'profileLinkUrl_ASC'
	| 'profileLinkUrl_DESC'
	| 'schedulingButtonTextMobile_ASC'
	| 'schedulingButtonTextMobile_DESC'
	| 'schedulingButtonText_ASC'
	| 'schedulingButtonText_DESC'
	| 'schedulingButtonUrlMobile_ASC'
	| 'schedulingButtonUrlMobile_DESC'
	| 'schedulingButtonUrl_ASC'
	| 'schedulingButtonUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useHeaderLocationCards_ASC'
	| 'useHeaderLocationCards_DESC'
	| 'useNewLayout_ASC'
	| 'useNewLayout_DESC'
	| 'usePopupScheduling_ASC'
	| 'usePopupScheduling_DESC';

export type HeaderNavMenuLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type HeaderNavMenuOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeaderNavigationLinksCollection = {
	__typename?: 'HeaderNavigationLinksCollection';
	items: Array<Maybe<HeaderNavigationLinksItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderNavigationLinksFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeaderNavigationLinksFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeaderNavigationLinksFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type HeaderNavigationLinksItem = FooterNavigationGroup | NavigationGroup | NavigationLink | Page;

export type HeaderOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'darkHeader_ASC'
	| 'darkHeader_DESC'
	| 'hideFullSiteSearch_ASC'
	| 'hideFullSiteSearch_DESC'
	| 'hideHamburgerMenuToggleButton_ASC'
	| 'hideHamburgerMenuToggleButton_DESC'
	| 'locationBarFindOfficeUrl_ASC'
	| 'locationBarFindOfficeUrl_DESC'
	| 'locationBarWithOfficeTitle_ASC'
	| 'locationBarWithOfficeTitle_DESC'
	| 'locationBarWithOutOfficeTitle_ASC'
	| 'locationBarWithOutOfficeTitle_DESC'
	| 'myAccountLabel_ASC'
	| 'myAccountLabel_DESC'
	| 'profileLinkUrl_ASC'
	| 'profileLinkUrl_DESC'
	| 'schedulingButtonTextMobile_ASC'
	| 'schedulingButtonTextMobile_DESC'
	| 'schedulingButtonText_ASC'
	| 'schedulingButtonText_DESC'
	| 'schedulingButtonUrlMobile_ASC'
	| 'schedulingButtonUrlMobile_DESC'
	| 'schedulingButtonUrl_ASC'
	| 'schedulingButtonUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useHeaderLocationCards_ASC'
	| 'useHeaderLocationCards_DESC'
	| 'useNewLayout_ASC'
	| 'useNewLayout_DESC'
	| 'usePopupScheduling_ASC'
	| 'usePopupScheduling_DESC';

export type HeaderSchedulingNavigationLinksCollection = {
	__typename?: 'HeaderSchedulingNavigationLinksCollection';
	items: Array<Maybe<NavigationGroup>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderSchedulingNavigationLinksCollectionOrder =
	| 'displayAsExpandedForMobileMenu_ASC'
	| 'displayAsExpandedForMobileMenu_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeaderSecondaryNavigationLinksCollection = {
	__typename?: 'HeaderSecondaryNavigationLinksCollection';
	items: Array<Maybe<HeaderSecondaryNavigationLinksItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderSecondaryNavigationLinksFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeaderSecondaryNavigationLinksFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeaderSecondaryNavigationLinksFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type HeaderSecondaryNavigationLinksItem = NavigationLink | Page;

/** The navigation groups with sub links when hover over the header main nav link [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerSubNav) */
export type HeaderSubNav = Entry &
	_Node & {
		__typename?: 'HeaderSubNav';
		_id: Scalars['ID'];
		contentTilesCollection?: Maybe<HeaderSubNavContentTilesCollection>;
		contentfulMetadata: ContentfulMetadata;
		headerSubNavGroupsCollection?: Maybe<HeaderSubNavHeaderSubNavGroupsCollection>;
		linkedFrom?: Maybe<HeaderSubNavLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** The navigation groups with sub links when hover over the header main nav link [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerSubNav) */
export type HeaderSubNavContentTilesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderSubNavContentTilesCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ContentTileFilter>;
};

/** The navigation groups with sub links when hover over the header main nav link [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerSubNav) */
export type HeaderSubNavHeaderSubNavGroupsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderSubNavHeaderSubNavGroupsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NavigationGroupFilter>;
};

/** The navigation groups with sub links when hover over the header main nav link [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerSubNav) */
export type HeaderSubNavLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The navigation groups with sub links when hover over the header main nav link [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/headerSubNav) */
export type HeaderSubNavNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type HeaderSubNavCollection = {
	__typename?: 'HeaderSubNavCollection';
	items: Array<Maybe<HeaderSubNav>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderSubNavContentTilesCollection = {
	__typename?: 'HeaderSubNavContentTilesCollection';
	items: Array<Maybe<ContentTile>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderSubNavContentTilesCollectionOrder =
	| 'description_ASC'
	| 'description_DESC'
	| 'externalUrl_ASC'
	| 'externalUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type HeaderSubNavFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeaderSubNavFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeaderSubNavFilter>>>;
	contentTiles?: InputMaybe<CfContentTileNestedFilter>;
	contentTilesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headerSubNavGroups?: InputMaybe<CfNavigationGroupNestedFilter>;
	headerSubNavGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type HeaderSubNavHeaderSubNavGroupsCollection = {
	__typename?: 'HeaderSubNavHeaderSubNavGroupsCollection';
	items: Array<Maybe<NavigationGroup>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderSubNavHeaderSubNavGroupsCollectionOrder =
	| 'displayAsExpandedForMobileMenu_ASC'
	| 'displayAsExpandedForMobileMenu_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeaderSubNavLinkingCollections = {
	__typename?: 'HeaderSubNavLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	headerMainNavCollection?: Maybe<HeaderMainNavCollection>;
};

export type HeaderSubNavLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderSubNavLinkingCollectionsHeaderMainNavCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderSubNavLinkingCollectionsHeaderMainNavCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeaderSubNavLinkingCollectionsHeaderMainNavCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeaderSubNavOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeaderTopNavigationLinksCollection = {
	__typename?: 'HeaderTopNavigationLinksCollection';
	items: Array<Maybe<HeaderTopNavigationLinksItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeaderTopNavigationLinksFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeaderTopNavigationLinksFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeaderTopNavigationLinksFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type HeaderTopNavigationLinksItem = NavigationLink | Page;

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2 = Entry &
	_Node & {
		__typename?: 'HeroV2';
		_id: Scalars['ID'];
		backgroundColorSmallScreen?: Maybe<Scalars['String']>;
		body?: Maybe<HeroV2Body>;
		bodySize?: Maybe<Scalars['String']>;
		buttonsCollection?: Maybe<HeroV2ButtonsCollection>;
		contentAlign?: Maybe<Scalars['String']>;
		contentPosition?: Maybe<Scalars['String']>;
		contentPositionSmallScreen?: Maybe<Scalars['String']>;
		contentSmallScreen?: Maybe<SmallScreenContent>;
		contentWidth?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		darkOverlay?: Maybe<Scalars['Boolean']>;
		darkTextColor?: Maybe<Scalars['Boolean']>;
		disclaimer?: Maybe<DisclaimerV2>;
		entryName?: Maybe<Scalars['String']>;
		eyebrow?: Maybe<Scalars['String']>;
		eyebrowStyle?: Maybe<Scalars['String']>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		focalPointImage?: Maybe<FocalPointImage>;
		heading?: Maybe<HeroV2Heading>;
		headingSize?: Maybe<Scalars['String']>;
		heightStyle?: Maybe<Scalars['String']>;
		imageAsset?: Maybe<ImageAsset>;
		linkedFrom?: Maybe<HeroV2LinkingCollections>;
		mediaAspectRatioSmallScreen?: Maybe<Scalars['String']>;
		promoHeadline?: Maybe<HeroV2PromoHeadline>;
		promoHeadlineSize?: Maybe<Scalars['String']>;
		sys: Sys;
		templateWidth?: Maybe<Scalars['String']>;
		termsConditions?: Maybe<TermsAndConditions>;
		video?: Maybe<Asset>;
		videoAsset?: Maybe<VideoAsset>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2BackgroundColorSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2BodyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2BodySizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2ButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeroV2ButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2ContentAlignArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2ContentPositionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2ContentPositionSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2ContentSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<SmallScreenContentFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2ContentWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2DarkOverlayArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2DarkTextColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2DisclaimerArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<DisclaimerV2Filter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2EntryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2EyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2EyebrowStyleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2FacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2FocalPointImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2HeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2HeadingSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2HeightStyleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2ImageAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2LinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2MediaAspectRatioSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2PromoHeadlineArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2PromoHeadlineSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2TemplateWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2TermsConditionsArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<TermsAndConditionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2VideoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/heroV2) */
export type HeroV2VideoAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoAssetFilter>;
};

export type HeroV2Body = {
	__typename?: 'HeroV2Body';
	json: Scalars['JSON'];
	links: HeroV2BodyLinks;
};

export type HeroV2BodyAssets = {
	__typename?: 'HeroV2BodyAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type HeroV2BodyEntries = {
	__typename?: 'HeroV2BodyEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type HeroV2BodyLinks = {
	__typename?: 'HeroV2BodyLinks';
	assets: HeroV2BodyAssets;
	entries: HeroV2BodyEntries;
	resources: HeroV2BodyResources;
};

export type HeroV2BodyResources = {
	__typename?: 'HeroV2BodyResources';
	block: Array<HeroV2BodyResourcesBlock>;
	hyperlink: Array<HeroV2BodyResourcesHyperlink>;
	inline: Array<HeroV2BodyResourcesInline>;
};

export type HeroV2BodyResourcesBlock = ResourceLink & {
	__typename?: 'HeroV2BodyResourcesBlock';
	sys: ResourceSys;
};

export type HeroV2BodyResourcesHyperlink = ResourceLink & {
	__typename?: 'HeroV2BodyResourcesHyperlink';
	sys: ResourceSys;
};

export type HeroV2BodyResourcesInline = ResourceLink & {
	__typename?: 'HeroV2BodyResourcesInline';
	sys: ResourceSys;
};

export type HeroV2ButtonsCollection = {
	__typename?: 'HeroV2ButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeroV2ButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type HeroV2Collection = {
	__typename?: 'HeroV2Collection';
	items: Array<Maybe<HeroV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type HeroV2Filter = {
	AND?: InputMaybe<Array<InputMaybe<HeroV2Filter>>>;
	OR?: InputMaybe<Array<InputMaybe<HeroV2Filter>>>;
	backgroundColorSmallScreen?: InputMaybe<Scalars['String']>;
	backgroundColorSmallScreen_contains?: InputMaybe<Scalars['String']>;
	backgroundColorSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColorSmallScreen_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColorSmallScreen_not?: InputMaybe<Scalars['String']>;
	backgroundColorSmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColorSmallScreen_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bodySize?: InputMaybe<Scalars['String']>;
	bodySize_contains?: InputMaybe<Scalars['String']>;
	bodySize_exists?: InputMaybe<Scalars['Boolean']>;
	bodySize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bodySize_not?: InputMaybe<Scalars['String']>;
	bodySize_not_contains?: InputMaybe<Scalars['String']>;
	bodySize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	body_contains?: InputMaybe<Scalars['String']>;
	body_exists?: InputMaybe<Scalars['Boolean']>;
	body_not_contains?: InputMaybe<Scalars['String']>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign?: InputMaybe<Scalars['String']>;
	contentAlign_contains?: InputMaybe<Scalars['String']>;
	contentAlign_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentAlign_not?: InputMaybe<Scalars['String']>;
	contentAlign_not_contains?: InputMaybe<Scalars['String']>;
	contentAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentPosition?: InputMaybe<Scalars['String']>;
	contentPositionSmallScreen?: InputMaybe<Scalars['String']>;
	contentPositionSmallScreen_contains?: InputMaybe<Scalars['String']>;
	contentPositionSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	contentPositionSmallScreen_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentPositionSmallScreen_not?: InputMaybe<Scalars['String']>;
	contentPositionSmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	contentPositionSmallScreen_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentPosition_contains?: InputMaybe<Scalars['String']>;
	contentPosition_exists?: InputMaybe<Scalars['Boolean']>;
	contentPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentPosition_not?: InputMaybe<Scalars['String']>;
	contentPosition_not_contains?: InputMaybe<Scalars['String']>;
	contentPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentSmallScreen?: InputMaybe<CfSmallScreenContentNestedFilter>;
	contentSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	contentWidth?: InputMaybe<Scalars['String']>;
	contentWidth_contains?: InputMaybe<Scalars['String']>;
	contentWidth_exists?: InputMaybe<Scalars['Boolean']>;
	contentWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentWidth_not?: InputMaybe<Scalars['String']>;
	contentWidth_not_contains?: InputMaybe<Scalars['String']>;
	contentWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	darkOverlay?: InputMaybe<Scalars['Boolean']>;
	darkOverlay_exists?: InputMaybe<Scalars['Boolean']>;
	darkOverlay_not?: InputMaybe<Scalars['Boolean']>;
	darkTextColor?: InputMaybe<Scalars['Boolean']>;
	darkTextColor_exists?: InputMaybe<Scalars['Boolean']>;
	darkTextColor_not?: InputMaybe<Scalars['Boolean']>;
	disclaimer?: InputMaybe<CfDisclaimerV2NestedFilter>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	entryName?: InputMaybe<Scalars['String']>;
	entryName_contains?: InputMaybe<Scalars['String']>;
	entryName_exists?: InputMaybe<Scalars['Boolean']>;
	entryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	entryName_not?: InputMaybe<Scalars['String']>;
	entryName_not_contains?: InputMaybe<Scalars['String']>;
	entryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrowStyle?: InputMaybe<Scalars['String']>;
	eyebrowStyle_contains?: InputMaybe<Scalars['String']>;
	eyebrowStyle_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowStyle_not?: InputMaybe<Scalars['String']>;
	eyebrowStyle_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImage?: InputMaybe<CfFocalPointImageNestedFilter>;
	focalPointImage_exists?: InputMaybe<Scalars['Boolean']>;
	headingSize?: InputMaybe<Scalars['String']>;
	headingSize_contains?: InputMaybe<Scalars['String']>;
	headingSize_exists?: InputMaybe<Scalars['Boolean']>;
	headingSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingSize_not?: InputMaybe<Scalars['String']>;
	headingSize_not_contains?: InputMaybe<Scalars['String']>;
	headingSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	heading_contains?: InputMaybe<Scalars['String']>;
	heading_exists?: InputMaybe<Scalars['Boolean']>;
	heading_not_contains?: InputMaybe<Scalars['String']>;
	heightStyle?: InputMaybe<Scalars['String']>;
	heightStyle_contains?: InputMaybe<Scalars['String']>;
	heightStyle_exists?: InputMaybe<Scalars['Boolean']>;
	heightStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	heightStyle_not?: InputMaybe<Scalars['String']>;
	heightStyle_not_contains?: InputMaybe<Scalars['String']>;
	heightStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAsset?: InputMaybe<CfImageAssetNestedFilter>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	mediaAspectRatioSmallScreen?: InputMaybe<Scalars['String']>;
	mediaAspectRatioSmallScreen_contains?: InputMaybe<Scalars['String']>;
	mediaAspectRatioSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	mediaAspectRatioSmallScreen_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mediaAspectRatioSmallScreen_not?: InputMaybe<Scalars['String']>;
	mediaAspectRatioSmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	mediaAspectRatioSmallScreen_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	promoHeadlineSize?: InputMaybe<Scalars['String']>;
	promoHeadlineSize_contains?: InputMaybe<Scalars['String']>;
	promoHeadlineSize_exists?: InputMaybe<Scalars['Boolean']>;
	promoHeadlineSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	promoHeadlineSize_not?: InputMaybe<Scalars['String']>;
	promoHeadlineSize_not_contains?: InputMaybe<Scalars['String']>;
	promoHeadlineSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	promoHeadline_contains?: InputMaybe<Scalars['String']>;
	promoHeadline_exists?: InputMaybe<Scalars['Boolean']>;
	promoHeadline_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	termsConditions?: InputMaybe<CfTermsAndConditionsNestedFilter>;
	termsConditions_exists?: InputMaybe<Scalars['Boolean']>;
	videoAsset?: InputMaybe<CfVideoAssetNestedFilter>;
	videoAsset_exists?: InputMaybe<Scalars['Boolean']>;
	video_exists?: InputMaybe<Scalars['Boolean']>;
};

export type HeroV2Heading = {
	__typename?: 'HeroV2Heading';
	json: Scalars['JSON'];
	links: HeroV2HeadingLinks;
};

export type HeroV2HeadingAssets = {
	__typename?: 'HeroV2HeadingAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type HeroV2HeadingEntries = {
	__typename?: 'HeroV2HeadingEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type HeroV2HeadingLinks = {
	__typename?: 'HeroV2HeadingLinks';
	assets: HeroV2HeadingAssets;
	entries: HeroV2HeadingEntries;
	resources: HeroV2HeadingResources;
};

export type HeroV2HeadingResources = {
	__typename?: 'HeroV2HeadingResources';
	block: Array<HeroV2HeadingResourcesBlock>;
	hyperlink: Array<HeroV2HeadingResourcesHyperlink>;
	inline: Array<HeroV2HeadingResourcesInline>;
};

export type HeroV2HeadingResourcesBlock = ResourceLink & {
	__typename?: 'HeroV2HeadingResourcesBlock';
	sys: ResourceSys;
};

export type HeroV2HeadingResourcesHyperlink = ResourceLink & {
	__typename?: 'HeroV2HeadingResourcesHyperlink';
	sys: ResourceSys;
};

export type HeroV2HeadingResourcesInline = ResourceLink & {
	__typename?: 'HeroV2HeadingResourcesInline';
	sys: ResourceSys;
};

export type HeroV2LinkingCollections = {
	__typename?: 'HeroV2LinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type HeroV2LinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeroV2LinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeroV2LinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeroV2LinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeroV2LinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeroV2LinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeroV2LinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeroV2LinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeroV2LinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type HeroV2LinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type HeroV2LinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type HeroV2LinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type HeroV2LinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type HeroV2Order =
	| 'backgroundColorSmallScreen_ASC'
	| 'backgroundColorSmallScreen_DESC'
	| 'bodySize_ASC'
	| 'bodySize_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentPositionSmallScreen_ASC'
	| 'contentPositionSmallScreen_DESC'
	| 'contentPosition_ASC'
	| 'contentPosition_DESC'
	| 'contentWidth_ASC'
	| 'contentWidth_DESC'
	| 'darkOverlay_ASC'
	| 'darkOverlay_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'headingSize_ASC'
	| 'headingSize_DESC'
	| 'heightStyle_ASC'
	| 'heightStyle_DESC'
	| 'mediaAspectRatioSmallScreen_ASC'
	| 'mediaAspectRatioSmallScreen_DESC'
	| 'promoHeadlineSize_ASC'
	| 'promoHeadlineSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type HeroV2PromoHeadline = {
	__typename?: 'HeroV2PromoHeadline';
	json: Scalars['JSON'];
	links: HeroV2PromoHeadlineLinks;
};

export type HeroV2PromoHeadlineAssets = {
	__typename?: 'HeroV2PromoHeadlineAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type HeroV2PromoHeadlineEntries = {
	__typename?: 'HeroV2PromoHeadlineEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type HeroV2PromoHeadlineLinks = {
	__typename?: 'HeroV2PromoHeadlineLinks';
	assets: HeroV2PromoHeadlineAssets;
	entries: HeroV2PromoHeadlineEntries;
	resources: HeroV2PromoHeadlineResources;
};

export type HeroV2PromoHeadlineResources = {
	__typename?: 'HeroV2PromoHeadlineResources';
	block: Array<HeroV2PromoHeadlineResourcesBlock>;
	hyperlink: Array<HeroV2PromoHeadlineResourcesHyperlink>;
	inline: Array<HeroV2PromoHeadlineResourcesInline>;
};

export type HeroV2PromoHeadlineResourcesBlock = ResourceLink & {
	__typename?: 'HeroV2PromoHeadlineResourcesBlock';
	sys: ResourceSys;
};

export type HeroV2PromoHeadlineResourcesHyperlink = ResourceLink & {
	__typename?: 'HeroV2PromoHeadlineResourcesHyperlink';
	sys: ResourceSys;
};

export type HeroV2PromoHeadlineResourcesInline = ResourceLink & {
	__typename?: 'HeroV2PromoHeadlineResourcesInline';
	sys: ResourceSys;
};

/** Providing  a component to embed an iframe on the page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/iframe) */
export type Iframe = Entry &
	_Node & {
		__typename?: 'Iframe';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		height?: Maybe<Scalars['Int']>;
		linkedFrom?: Maybe<IframeLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		url?: Maybe<Scalars['String']>;
	};

/** Providing  a component to embed an iframe on the page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/iframe) */
export type IframeHeightArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Providing  a component to embed an iframe on the page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/iframe) */
export type IframeLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Providing  a component to embed an iframe on the page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/iframe) */
export type IframeNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Providing  a component to embed an iframe on the page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/iframe) */
export type IframeTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Providing  a component to embed an iframe on the page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/iframe) */
export type IframeUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type IframeCollection = {
	__typename?: 'IframeCollection';
	items: Array<Maybe<Iframe>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type IframeFilter = {
	AND?: InputMaybe<Array<InputMaybe<IframeFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<IframeFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	height?: InputMaybe<Scalars['Int']>;
	height_exists?: InputMaybe<Scalars['Boolean']>;
	height_gt?: InputMaybe<Scalars['Int']>;
	height_gte?: InputMaybe<Scalars['Int']>;
	height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	height_lt?: InputMaybe<Scalars['Int']>;
	height_lte?: InputMaybe<Scalars['Int']>;
	height_not?: InputMaybe<Scalars['Int']>;
	height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	url?: InputMaybe<Scalars['String']>;
	url_contains?: InputMaybe<Scalars['String']>;
	url_exists?: InputMaybe<Scalars['Boolean']>;
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	url_not?: InputMaybe<Scalars['String']>;
	url_not_contains?: InputMaybe<Scalars['String']>;
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IframeLinkingCollections = {
	__typename?: 'IframeLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type IframeLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type IframeLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<IframeLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type IframeLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type IframeOrder =
	| 'height_ASC'
	| 'height_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'url_ASC'
	| 'url_DESC';

export type Image = {
	__typename?: 'Image';
	data: Scalars['String'];
	dataUrl: Scalars['String'];
	mediaType: Scalars['String'];
};

/** Content type used to display images with focal point using Brand Folder Image with separate images for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/imageAsset) */
export type ImageAsset = Entry &
	_Node & {
		__typename?: 'ImageAsset';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		desktopImage?: Maybe<Scalars['JSON']>;
		linkedFrom?: Maybe<ImageAssetLinkingCollections>;
		mobileImage?: Maybe<Scalars['JSON']>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** Content type used to display images with focal point using Brand Folder Image with separate images for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/imageAsset) */
export type ImageAssetDesktopImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content type used to display images with focal point using Brand Folder Image with separate images for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/imageAsset) */
export type ImageAssetLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content type used to display images with focal point using Brand Folder Image with separate images for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/imageAsset) */
export type ImageAssetMobileImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content type used to display images with focal point using Brand Folder Image with separate images for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/imageAsset) */
export type ImageAssetNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ImageAssetCollection = {
	__typename?: 'ImageAssetCollection';
	items: Array<Maybe<ImageAsset>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ImageAssetFilter = {
	AND?: InputMaybe<Array<InputMaybe<ImageAssetFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ImageAssetFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	desktopImage_exists?: InputMaybe<Scalars['Boolean']>;
	mobileImage_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ImageAssetLinkingCollections = {
	__typename?: 'ImageAssetLinkingCollections';
	blogDetailsPageCollection?: Maybe<BlogDetailsPageCollection>;
	cardCollection?: Maybe<CardCollection>;
	categoryCollection?: Maybe<CategoryCollection>;
	entryCollection?: Maybe<EntryCollection>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	multiCardV2Collection?: Maybe<MultiCardV2Collection>;
	nameValuesCollection?: Maybe<NameValuesCollection>;
	reusableContentCollection?: Maybe<ReusableContentCollection>;
	singleMessageCollection?: Maybe<SingleMessageCollection>;
	tableRowCollection?: Maybe<TableRowCollection>;
};

export type ImageAssetLinkingCollectionsBlogDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsBlogDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsCardCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsCategoryCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsHalfScreenCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsHeroV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsMultiCardV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsMultiCardV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsNameValuesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsNameValuesCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsReusableContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsReusableContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsSingleMessageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsSingleMessageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsTableRowCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetLinkingCollectionsTableRowCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ImageAssetLinkingCollectionsBlogDetailsPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'date_ASC'
	| 'date_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'timeToRead_ASC'
	| 'timeToRead_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ImageAssetLinkingCollectionsCardCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ImageAssetLinkingCollectionsCategoryCollectionOrder =
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'link_ASC'
	| 'link_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ImageAssetLinkingCollectionsHalfScreenCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'hideContentHalf_ASC'
	| 'hideContentHalf_DESC'
	| 'isInsetLayout_ASC'
	| 'isInsetLayout_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC'
	| 'useStaticImageSize_ASC'
	| 'useStaticImageSize_DESC';

export type ImageAssetLinkingCollectionsHeroV2CollectionOrder =
	| 'backgroundColorSmallScreen_ASC'
	| 'backgroundColorSmallScreen_DESC'
	| 'bodySize_ASC'
	| 'bodySize_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentPositionSmallScreen_ASC'
	| 'contentPositionSmallScreen_DESC'
	| 'contentPosition_ASC'
	| 'contentPosition_DESC'
	| 'contentWidth_ASC'
	| 'contentWidth_DESC'
	| 'darkOverlay_ASC'
	| 'darkOverlay_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'headingSize_ASC'
	| 'headingSize_DESC'
	| 'heightStyle_ASC'
	| 'heightStyle_DESC'
	| 'mediaAspectRatioSmallScreen_ASC'
	| 'mediaAspectRatioSmallScreen_DESC'
	| 'promoHeadlineSize_ASC'
	| 'promoHeadlineSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type ImageAssetLinkingCollectionsMultiCardV2CollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'bigScreenBackground_ASC'
	| 'bigScreenBackground_DESC'
	| 'cardsAlignment_ASC'
	| 'cardsAlignment_DESC'
	| 'displayAsStepper_ASC'
	| 'displayAsStepper_DESC'
	| 'enableMobileCarouselNavigation_ASC'
	| 'enableMobileCarouselNavigation_DESC'
	| 'enableResourceCardMode_ASC'
	| 'enableResourceCardMode_DESC'
	| 'eyebrowColor_ASC'
	| 'eyebrowColor_DESC'
	| 'eyebrowSx_ASC'
	| 'eyebrowSx_DESC'
	| 'eyebrowTextTransform_ASC'
	| 'eyebrowTextTransform_DESC'
	| 'fixBackgroundToBottom_ASC'
	| 'fixBackgroundToBottom_DESC'
	| 'forMobile_ASC'
	| 'forMobile_DESC'
	| 'headerSpacing_ASC'
	| 'headerSpacing_DESC'
	| 'lastRowCardsAlignment_ASC'
	| 'lastRowCardsAlignment_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'numberOfCardsInLineDesktop_ASC'
	| 'numberOfCardsInLineDesktop_DESC'
	| 'roundedImage_ASC'
	| 'roundedImage_DESC'
	| 'sectionEyebrow_ASC'
	| 'sectionEyebrow_DESC'
	| 'sectionInfoAlignment_ASC'
	| 'sectionInfoAlignment_DESC'
	| 'sectionTitleHtag_ASC'
	| 'sectionTitleHtag_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ImageAssetLinkingCollectionsNameValuesCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ImageAssetLinkingCollectionsReusableContentCollectionOrder =
	| 'component_ASC'
	| 'component_DESC'
	| 'disclaimer_ASC'
	| 'disclaimer_DESC'
	| 'mainTitle_ASC'
	| 'mainTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'textValue_ASC'
	| 'textValue_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type ImageAssetLinkingCollectionsSingleMessageCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentFillVerticalSpace_ASC'
	| 'contentFillVerticalSpace_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isBannerImage_ASC'
	| 'isBannerImage_DESC'
	| 'isInlineLayout_ASC'
	| 'isInlineLayout_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subText_ASC'
	| 'subText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateHeight_ASC'
	| 'templateHeight_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleVariant_ASC'
	| 'titleVariant_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'useRoundedCorners_ASC'
	| 'useRoundedCorners_DESC';

export type ImageAssetLinkingCollectionsTableRowCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ImageAssetOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ImageFormat =
	| 'AVIF'
	/** JPG image format. */
	| 'JPG'
	/**
	 * Progressive JPG format stores multiple passes of an image in progressively higher detail.
	 *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
	 *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
	 *         early as possible to make the layout look as designed.
	 */
	| 'JPG_PROGRESSIVE'
	/** PNG image format */
	| 'PNG'
	/**
	 * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
	 *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
	 */
	| 'PNG8'
	/** WebP image format. */
	| 'WEBP';

export type ImageResizeFocus =
	/** Focus the resizing on the bottom. */
	| 'BOTTOM'
	/** Focus the resizing on the bottom left. */
	| 'BOTTOM_LEFT'
	/** Focus the resizing on the bottom right. */
	| 'BOTTOM_RIGHT'
	/** Focus the resizing on the center. */
	| 'CENTER'
	/** Focus the resizing on the largest face. */
	| 'FACE'
	/** Focus the resizing on the area containing all the faces. */
	| 'FACES'
	/** Focus the resizing on the left. */
	| 'LEFT'
	/** Focus the resizing on the right. */
	| 'RIGHT'
	/** Focus the resizing on the top. */
	| 'TOP'
	/** Focus the resizing on the top left. */
	| 'TOP_LEFT'
	/** Focus the resizing on the top right. */
	| 'TOP_RIGHT';

export type ImageResizeStrategy =
	/** Crops a part of the original image to fit into the specified dimensions. */
	| 'CROP'
	/** Resizes the image to the specified dimensions, cropping the image if needed. */
	| 'FILL'
	/** Resizes the image to fit into the specified dimensions. */
	| 'FIT'
	/**
	 * Resizes the image to the specified dimensions, padding the image if needed.
	 *         Uses desired background color as padding color.
	 */
	| 'PAD'
	/** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
	| 'SCALE'
	/** Creates a thumbnail from the image. */
	| 'THUMB';

export type ImageTransformOptions = {
	/**
	 * Desired background color, used with corner radius or `PAD` resize strategy.
	 *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
	 */
	backgroundColor?: InputMaybe<Scalars['HexColor']>;
	/**
	 * Desired corner radius in pixels.
	 *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
	 *         Defaults to `0`. Uses desired background color as padding color,
	 *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
	 */
	cornerRadius?: InputMaybe<Scalars['Int']>;
	/** Desired image format. Defaults to the original image format. */
	format?: InputMaybe<ImageFormat>;
	/** Desired height in pixels. Defaults to the original image height. */
	height?: InputMaybe<Scalars['Dimension']>;
	/**
	 * Desired quality of the image in percents.
	 *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
	 */
	quality?: InputMaybe<Scalars['Quality']>;
	/** Desired resize focus area. Defaults to `CENTER`. */
	resizeFocus?: InputMaybe<ImageResizeFocus>;
	/** Desired resize strategy. Defaults to `FIT`. */
	resizeStrategy?: InputMaybe<ImageResizeStrategy>;
	/** Desired width in pixels. Defaults to the original image width. */
	width?: InputMaybe<Scalars['Dimension']>;
};

export type IneligibilityReasonEnum = 'HAS_SCHEDULED_VISIT' | 'INACTIVE_ACCOUNT' | 'NOT_CONSIDERED_AS_NEW_PATIENT';

/** List Insurance Providers by States with a Rich Text Introduction [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/insurance) */
export type Insurance = Entry &
	_Node & {
		__typename?: 'Insurance';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		haveInsurance?: Maybe<Scalars['Boolean']>;
		insuranceInfo?: Maybe<Array<Maybe<InsuranceInfo>>>;
		intro?: Maybe<InsuranceIntro>;
		linkedFrom?: Maybe<InsuranceLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** List Insurance Providers by States with a Rich Text Introduction [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/insurance) */
export type InsuranceIntroArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** List Insurance Providers by States with a Rich Text Introduction [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/insurance) */
export type InsuranceLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** List Insurance Providers by States with a Rich Text Introduction [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/insurance) */
export type InsuranceNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** List Insurance Providers by States with a Rich Text Introduction [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/insurance) */
export type InsuranceTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type InsuranceCollection = {
	__typename?: 'InsuranceCollection';
	items: Array<Maybe<Insurance>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type InsuranceCompaniesFilterInput = {
	facilityBrand?: InputMaybe<FacilityBrand>;
	usStateCode?: InputMaybe<UsStateCode>;
};

export type InsuranceCompaniesList = {
	__typename?: 'InsuranceCompaniesList';
	items: Array<InsuranceCompany>;
	total: Scalars['Int'];
};

export type InsuranceCompaniesNetwork = {
	__typename?: 'InsuranceCompaniesNetwork';
	name?: Maybe<Scalars['String']>;
};

export type InsuranceCompaniesNetworkFilterInput = {
	facilityBrand?: InputMaybe<FacilityBrand>;
	usStateCode?: InputMaybe<UsStateCode>;
};

export type InsuranceCompaniesNetworkList = {
	__typename?: 'InsuranceCompaniesNetworkList';
	items: Array<InsuranceCompaniesNetwork>;
	total: Scalars['Int'];
};

export type InsuranceCompany = {
	__typename?: 'InsuranceCompany';
	name?: Maybe<Scalars['String']>;
};

export type InsuranceFilter = {
	AND?: InputMaybe<Array<InputMaybe<InsuranceFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<InsuranceFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	intro_contains?: InputMaybe<Scalars['String']>;
	intro_exists?: InputMaybe<Scalars['Boolean']>;
	intro_not_contains?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Insurance Details */
export type InsuranceInfo = {
	__typename?: 'InsuranceInfo';
	groupId?: Maybe<Scalars['String']>;
	insurer?: Maybe<Scalars['String']>;
	subscriberFirstName?: Maybe<Scalars['String']>;
	subscriberLastName?: Maybe<Scalars['String']>;
	subscriberMemberId?: Maybe<Scalars['String']>;
};

export type InsuranceIntro = {
	__typename?: 'InsuranceIntro';
	json: Scalars['JSON'];
	links: InsuranceIntroLinks;
};

export type InsuranceIntroAssets = {
	__typename?: 'InsuranceIntroAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type InsuranceIntroEntries = {
	__typename?: 'InsuranceIntroEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type InsuranceIntroLinks = {
	__typename?: 'InsuranceIntroLinks';
	assets: InsuranceIntroAssets;
	entries: InsuranceIntroEntries;
	resources: InsuranceIntroResources;
};

export type InsuranceIntroResources = {
	__typename?: 'InsuranceIntroResources';
	block: Array<InsuranceIntroResourcesBlock>;
	hyperlink: Array<InsuranceIntroResourcesHyperlink>;
	inline: Array<InsuranceIntroResourcesInline>;
};

export type InsuranceIntroResourcesBlock = ResourceLink & {
	__typename?: 'InsuranceIntroResourcesBlock';
	sys: ResourceSys;
};

export type InsuranceIntroResourcesHyperlink = ResourceLink & {
	__typename?: 'InsuranceIntroResourcesHyperlink';
	sys: ResourceSys;
};

export type InsuranceIntroResourcesInline = ResourceLink & {
	__typename?: 'InsuranceIntroResourcesInline';
	sys: ResourceSys;
};

export type InsuranceLinkingCollections = {
	__typename?: 'InsuranceLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type InsuranceLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type InsuranceLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<InsuranceLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type InsuranceLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type InsuranceOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type Intervals = {
	__typename?: 'Intervals';
	endTime?: Maybe<Scalars['String']>;
	startTime?: Maybe<Scalars['String']>;
};

export type InvalidFieldError = Error & {
	__typename?: 'InvalidFieldError';
	fieldName: Scalars['String'];
	message: Scalars['String'];
	validFormat?: Maybe<Scalars['String']>;
};

/** Returned when provided answer type and expected answer type mismatch. */
export type InvalidQuestionnaireAnswerTypeError = Error & {
	__typename?: 'InvalidQuestionnaireAnswerTypeError';
	answeredQuestion: QuestionnaireQuestion;
	message: Scalars['String'];
	providedAnswerType: Scalars['String'];
};

export type InvalidRelationToGuarantorError = Error & {
	__typename?: 'InvalidRelationToGuarantorError';
	message: Scalars['String'];
};

/** Allows one to forward a platform page to an external page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/linkForwarder) */
export type LinkForwarder = Entry &
	_Node & {
		__typename?: 'LinkForwarder';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<LinkForwarderLinkingCollections>;
		sys: Sys;
		url?: Maybe<Scalars['String']>;
	};

/** Allows one to forward a platform page to an external page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/linkForwarder) */
export type LinkForwarderLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Allows one to forward a platform page to an external page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/linkForwarder) */
export type LinkForwarderUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type LinkForwarderCollection = {
	__typename?: 'LinkForwarderCollection';
	items: Array<Maybe<LinkForwarder>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type LinkForwarderFilter = {
	AND?: InputMaybe<Array<InputMaybe<LinkForwarderFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<LinkForwarderFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
	url?: InputMaybe<Scalars['String']>;
	url_contains?: InputMaybe<Scalars['String']>;
	url_exists?: InputMaybe<Scalars['Boolean']>;
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	url_not?: InputMaybe<Scalars['String']>;
	url_not_contains?: InputMaybe<Scalars['String']>;
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LinkForwarderLinkingCollections = {
	__typename?: 'LinkForwarderLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type LinkForwarderLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type LinkForwarderLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<LinkForwarderLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type LinkForwarderLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type LinkForwarderOrder =
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'url_ASC'
	| 'url_DESC';

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitle = Entry &
	_Node & {
		__typename?: 'LocalizedServiceTitle';
		_id: Scalars['ID'];
		callCenterLabel?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<ContentTypeRichText>;
		googleMapsBusinessQuery?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<LocalizedServiceTitleLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		officeHoursLabel?: Maybe<Scalars['String']>;
		showAddress?: Maybe<Scalars['Boolean']>;
		showHours?: Maybe<Scalars['Boolean']>;
		showPhoneNumber?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		templateWidth?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
	};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleCallCenterLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleGoogleMapsBusinessQueryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleOfficeHoursLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleShowAddressArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleShowHoursArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleShowPhoneNumberArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleTemplateWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This template is to display office information on service details pages when we do not want to display same information in ODPHero. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/localizedServiceTitle) */
export type LocalizedServiceTitleTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type LocalizedServiceTitleCollection = {
	__typename?: 'LocalizedServiceTitleCollection';
	items: Array<Maybe<LocalizedServiceTitle>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type LocalizedServiceTitleFilter = {
	AND?: InputMaybe<Array<InputMaybe<LocalizedServiceTitleFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<LocalizedServiceTitleFilter>>>;
	callCenterLabel?: InputMaybe<Scalars['String']>;
	callCenterLabel_contains?: InputMaybe<Scalars['String']>;
	callCenterLabel_exists?: InputMaybe<Scalars['Boolean']>;
	callCenterLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	callCenterLabel_not?: InputMaybe<Scalars['String']>;
	callCenterLabel_not_contains?: InputMaybe<Scalars['String']>;
	callCenterLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	googleMapsBusinessQuery?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_exists?: InputMaybe<Scalars['Boolean']>;
	googleMapsBusinessQuery_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	googleMapsBusinessQuery_not?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	officeHoursLabel?: InputMaybe<Scalars['String']>;
	officeHoursLabel_contains?: InputMaybe<Scalars['String']>;
	officeHoursLabel_exists?: InputMaybe<Scalars['Boolean']>;
	officeHoursLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	officeHoursLabel_not?: InputMaybe<Scalars['String']>;
	officeHoursLabel_not_contains?: InputMaybe<Scalars['String']>;
	officeHoursLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	showAddress?: InputMaybe<Scalars['Boolean']>;
	showAddress_exists?: InputMaybe<Scalars['Boolean']>;
	showAddress_not?: InputMaybe<Scalars['Boolean']>;
	showHours?: InputMaybe<Scalars['Boolean']>;
	showHours_exists?: InputMaybe<Scalars['Boolean']>;
	showHours_not?: InputMaybe<Scalars['Boolean']>;
	showPhoneNumber?: InputMaybe<Scalars['Boolean']>;
	showPhoneNumber_exists?: InputMaybe<Scalars['Boolean']>;
	showPhoneNumber_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LocalizedServiceTitleLinkingCollections = {
	__typename?: 'LocalizedServiceTitleLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type LocalizedServiceTitleLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type LocalizedServiceTitleOrder =
	| 'callCenterLabel_ASC'
	| 'callCenterLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'officeHoursLabel_ASC'
	| 'officeHoursLabel_DESC'
	| 'showAddress_ASC'
	| 'showAddress_DESC'
	| 'showHours_ASC'
	| 'showHours_DESC'
	| 'showPhoneNumber_ASC'
	| 'showPhoneNumber_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/locationStep) */
export type LocationStep = Entry &
	_Node & {
		__typename?: 'LocationStep';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		currentLocation?: Maybe<CallToAction>;
		currentLocationLabel?: Maybe<Scalars['String']>;
		inputLabel?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<LocationStepLinkingCollections>;
		more?: Maybe<CallToAction>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/locationStep) */
export type LocationStepCurrentLocationArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<CallToActionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/locationStep) */
export type LocationStepCurrentLocationLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/locationStep) */
export type LocationStepInputLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/locationStep) */
export type LocationStepLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/locationStep) */
export type LocationStepMoreArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<CallToActionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/locationStep) */
export type LocationStepNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/locationStep) */
export type LocationStepTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type LocationStepCollection = {
	__typename?: 'LocationStepCollection';
	items: Array<Maybe<LocationStep>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type LocationStepFilter = {
	AND?: InputMaybe<Array<InputMaybe<LocationStepFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<LocationStepFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	currentLocation?: InputMaybe<CfCallToActionNestedFilter>;
	currentLocationLabel?: InputMaybe<Scalars['String']>;
	currentLocationLabel_contains?: InputMaybe<Scalars['String']>;
	currentLocationLabel_exists?: InputMaybe<Scalars['Boolean']>;
	currentLocationLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	currentLocationLabel_not?: InputMaybe<Scalars['String']>;
	currentLocationLabel_not_contains?: InputMaybe<Scalars['String']>;
	currentLocationLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	currentLocation_exists?: InputMaybe<Scalars['Boolean']>;
	inputLabel?: InputMaybe<Scalars['String']>;
	inputLabel_contains?: InputMaybe<Scalars['String']>;
	inputLabel_exists?: InputMaybe<Scalars['Boolean']>;
	inputLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	inputLabel_not?: InputMaybe<Scalars['String']>;
	inputLabel_not_contains?: InputMaybe<Scalars['String']>;
	inputLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	more?: InputMaybe<CfCallToActionNestedFilter>;
	more_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LocationStepLinkingCollections = {
	__typename?: 'LocationStepLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	stepperCollection?: Maybe<StepperCollection>;
};

export type LocationStepLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type LocationStepLinkingCollectionsStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<LocationStepLinkingCollectionsStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type LocationStepLinkingCollectionsStepperCollectionOrder =
	| 'confirmButtonText_ASC'
	| 'confirmButtonText_DESC'
	| 'continueButtonText_ASC'
	| 'continueButtonText_DESC'
	| 'headingScheduledText_ASC'
	| 'headingScheduledText_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'needAssistanceText_ASC'
	| 'needAssistanceText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type LocationStepOrder =
	| 'currentLocationLabel_ASC'
	| 'currentLocationLabel_DESC'
	| 'inputLabel_ASC'
	| 'inputLabel_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/marquee) */
export type Marquee = Entry &
	_Node & {
		__typename?: 'Marquee';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		height?: Maybe<Scalars['String']>;
		icon?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<MarqueeLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		textList?: Maybe<Array<Maybe<Scalars['String']>>>;
		withAutoScrolling?: Maybe<Scalars['Boolean']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/marquee) */
export type MarqueeBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/marquee) */
export type MarqueeHeightArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/marquee) */
export type MarqueeIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/marquee) */
export type MarqueeLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/marquee) */
export type MarqueeNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/marquee) */
export type MarqueeTextListArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/marquee) */
export type MarqueeWithAutoScrollingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type MarqueeCollection = {
	__typename?: 'MarqueeCollection';
	items: Array<Maybe<Marquee>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MarqueeFilter = {
	AND?: InputMaybe<Array<InputMaybe<MarqueeFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<MarqueeFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	height?: InputMaybe<Scalars['String']>;
	height_contains?: InputMaybe<Scalars['String']>;
	height_exists?: InputMaybe<Scalars['Boolean']>;
	height_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	height_not?: InputMaybe<Scalars['String']>;
	height_not_contains?: InputMaybe<Scalars['String']>;
	height_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon?: InputMaybe<Scalars['String']>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	textList_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	textList_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	textList_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	textList_exists?: InputMaybe<Scalars['Boolean']>;
	withAutoScrolling?: InputMaybe<Scalars['Boolean']>;
	withAutoScrolling_exists?: InputMaybe<Scalars['Boolean']>;
	withAutoScrolling_not?: InputMaybe<Scalars['Boolean']>;
};

export type MarqueeLinkingCollections = {
	__typename?: 'MarqueeLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type MarqueeLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MarqueeLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MarqueeLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MarqueeLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MarqueeLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MarqueeLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MarqueeLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MarqueeOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'height_ASC'
	| 'height_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'withAutoScrolling_ASC'
	| 'withAutoScrolling_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mediaSlider) */
export type MediaSlider = Entry &
	_Node & {
		__typename?: 'MediaSlider';
		_id: Scalars['ID'];
		aspectRatio?: Maybe<Scalars['String']>;
		badge?: Maybe<Badge>;
		contentfulMetadata: ContentfulMetadata;
		contentsCollection?: Maybe<MediaSliderContentsCollection>;
		linkedFrom?: Maybe<MediaSliderLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mediaSlider) */
export type MediaSliderAspectRatioArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mediaSlider) */
export type MediaSliderBadgeArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BadgeFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mediaSlider) */
export type MediaSliderContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MediaSliderContentsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mediaSlider) */
export type MediaSliderLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mediaSlider) */
export type MediaSliderNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type MediaSliderCollection = {
	__typename?: 'MediaSliderCollection';
	items: Array<Maybe<MediaSlider>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MediaSliderContentsCollection = {
	__typename?: 'MediaSliderContentsCollection';
	items: Array<Maybe<MediaSliderContentsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MediaSliderContentsFilter = {
	AND?: InputMaybe<Array<InputMaybe<MediaSliderContentsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<MediaSliderContentsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type MediaSliderContentsItem = FocalPointImage | Video;

export type MediaSliderFilter = {
	AND?: InputMaybe<Array<InputMaybe<MediaSliderFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<MediaSliderFilter>>>;
	aspectRatio?: InputMaybe<Scalars['String']>;
	aspectRatio_contains?: InputMaybe<Scalars['String']>;
	aspectRatio_exists?: InputMaybe<Scalars['Boolean']>;
	aspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	aspectRatio_not?: InputMaybe<Scalars['String']>;
	aspectRatio_not_contains?: InputMaybe<Scalars['String']>;
	aspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	badge?: InputMaybe<CfBadgeNestedFilter>;
	badge_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type MediaSliderLinkingCollections = {
	__typename?: 'MediaSliderLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
	productFeatureCollection?: Maybe<ProductFeatureCollection>;
	productFeatureMediaCollection?: Maybe<ProductFeatureMediaCollection>;
};

export type MediaSliderLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MediaSliderLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MediaSliderLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MediaSliderLinkingCollectionsProductFeatureCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MediaSliderLinkingCollectionsProductFeatureCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MediaSliderLinkingCollectionsProductFeatureMediaCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MediaSliderLinkingCollectionsProductFeatureMediaCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MediaSliderLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MediaSliderLinkingCollectionsProductFeatureCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type MediaSliderLinkingCollectionsProductFeatureMediaCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type MediaSliderOrder =
	| 'aspectRatio_ASC'
	| 'aspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** A medical service belongs to a service line, e.g Injury, X-ray, Covid-19 etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/medicalService) */
export type MedicalService = Entry &
	_Node & {
		__typename?: 'MedicalService';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<MedicalServiceDescription>;
		iconName?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<MedicalServiceLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** A medical service belongs to a service line, e.g Injury, X-ray, Covid-19 etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/medicalService) */
export type MedicalServiceAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** A medical service belongs to a service line, e.g Injury, X-ray, Covid-19 etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/medicalService) */
export type MedicalServiceDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A medical service belongs to a service line, e.g Injury, X-ray, Covid-19 etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/medicalService) */
export type MedicalServiceIconNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A medical service belongs to a service line, e.g Injury, X-ray, Covid-19 etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/medicalService) */
export type MedicalServiceLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A medical service belongs to a service line, e.g Injury, X-ray, Covid-19 etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/medicalService) */
export type MedicalServiceNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A medical service belongs to a service line, e.g Injury, X-ray, Covid-19 etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/medicalService) */
export type MedicalServiceTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type MedicalServiceCollection = {
	__typename?: 'MedicalServiceCollection';
	items: Array<Maybe<MedicalService>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MedicalServiceDescription = {
	__typename?: 'MedicalServiceDescription';
	json: Scalars['JSON'];
	links: MedicalServiceDescriptionLinks;
};

export type MedicalServiceDescriptionAssets = {
	__typename?: 'MedicalServiceDescriptionAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type MedicalServiceDescriptionEntries = {
	__typename?: 'MedicalServiceDescriptionEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type MedicalServiceDescriptionLinks = {
	__typename?: 'MedicalServiceDescriptionLinks';
	assets: MedicalServiceDescriptionAssets;
	entries: MedicalServiceDescriptionEntries;
	resources: MedicalServiceDescriptionResources;
};

export type MedicalServiceDescriptionResources = {
	__typename?: 'MedicalServiceDescriptionResources';
	block: Array<MedicalServiceDescriptionResourcesBlock>;
	hyperlink: Array<MedicalServiceDescriptionResourcesHyperlink>;
	inline: Array<MedicalServiceDescriptionResourcesInline>;
};

export type MedicalServiceDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'MedicalServiceDescriptionResourcesBlock';
	sys: ResourceSys;
};

export type MedicalServiceDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'MedicalServiceDescriptionResourcesHyperlink';
	sys: ResourceSys;
};

export type MedicalServiceDescriptionResourcesInline = ResourceLink & {
	__typename?: 'MedicalServiceDescriptionResourcesInline';
	sys: ResourceSys;
};

export type MedicalServiceFilter = {
	AND?: InputMaybe<Array<InputMaybe<MedicalServiceFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<MedicalServiceFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	iconName?: InputMaybe<Scalars['String']>;
	iconName_contains?: InputMaybe<Scalars['String']>;
	iconName_exists?: InputMaybe<Scalars['Boolean']>;
	iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconName_not?: InputMaybe<Scalars['String']>;
	iconName_not_contains?: InputMaybe<Scalars['String']>;
	iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MedicalServiceLinkingCollections = {
	__typename?: 'MedicalServiceLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
};

export type MedicalServiceLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MedicalServiceLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MedicalServiceLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MedicalServiceLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type MedicalServiceOrder =
	| 'iconName_ASC'
	| 'iconName_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurDoctors) */
export type MeetOurDoctors = Entry &
	_Node & {
		__typename?: 'MeetOurDoctors';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		doctorsCollection?: Maybe<MeetOurDoctorsDoctorsCollection>;
		linkedFrom?: Maybe<MeetOurDoctorsLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sectionBackground?: Maybe<Scalars['String']>;
		sectionInfoAlignment?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurDoctors) */
export type MeetOurDoctorsDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurDoctors) */
export type MeetOurDoctorsDoctorsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurDoctorsDoctorsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<StaffFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurDoctors) */
export type MeetOurDoctorsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurDoctors) */
export type MeetOurDoctorsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurDoctors) */
export type MeetOurDoctorsSectionBackgroundArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurDoctors) */
export type MeetOurDoctorsSectionInfoAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurDoctors) */
export type MeetOurDoctorsTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type MeetOurDoctorsCollection = {
	__typename?: 'MeetOurDoctorsCollection';
	items: Array<Maybe<MeetOurDoctors>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MeetOurDoctorsDoctorsCollection = {
	__typename?: 'MeetOurDoctorsDoctorsCollection';
	items: Array<Maybe<Staff>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MeetOurDoctorsDoctorsCollectionOrder =
	| 'collegeCity_ASC'
	| 'collegeCity_DESC'
	| 'collegeName_ASC'
	| 'collegeName_DESC'
	| 'collegeState_ASC'
	| 'collegeState_DESC'
	| 'firstName_ASC'
	| 'firstName_DESC'
	| 'graduationDate_ASC'
	| 'graduationDate_DESC'
	| 'hireDate_ASC'
	| 'hireDate_DESC'
	| 'jobTitle_ASC'
	| 'jobTitle_DESC'
	| 'lastName_ASC'
	| 'lastName_DESC'
	| 'location_ASC'
	| 'location_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'specialties_ASC'
	| 'specialties_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type MeetOurDoctorsFilter = {
	AND?: InputMaybe<Array<InputMaybe<MeetOurDoctorsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<MeetOurDoctorsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	doctors?: InputMaybe<CfStaffNestedFilter>;
	doctorsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionBackground?: InputMaybe<Scalars['String']>;
	sectionBackground_contains?: InputMaybe<Scalars['String']>;
	sectionBackground_exists?: InputMaybe<Scalars['Boolean']>;
	sectionBackground_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionBackground_not?: InputMaybe<Scalars['String']>;
	sectionBackground_not_contains?: InputMaybe<Scalars['String']>;
	sectionBackground_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionInfoAlignment?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_contains?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	sectionInfoAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionInfoAlignment_not?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_not_contains?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MeetOurDoctorsLinkingCollections = {
	__typename?: 'MeetOurDoctorsLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type MeetOurDoctorsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MeetOurDoctorsLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurDoctorsLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MeetOurDoctorsLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurDoctorsLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MeetOurDoctorsLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurDoctorsLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MeetOurDoctorsLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MeetOurDoctorsLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MeetOurDoctorsLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MeetOurDoctorsOrder =
	| 'description_ASC'
	| 'description_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sectionBackground_ASC'
	| 'sectionBackground_DESC'
	| 'sectionInfoAlignment_ASC'
	| 'sectionInfoAlignment_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaff = Entry &
	_Node & {
		__typename?: 'MeetOurStaff';
		_id: Scalars['ID'];
		alignment?: Maybe<Scalars['String']>;
		contentMaxWidth?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<MeetOurStaffLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sectionBackground?: Maybe<Scalars['String']>;
		showDisabledSwiperArrows?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		withDesktopPaginationNavigation?: Maybe<Scalars['Boolean']>;
		withMobileArrowsNavigation?: Maybe<Scalars['Boolean']>;
	};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffContentMaxWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffSectionBackgroundArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffShowDisabledSwiperArrowsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffWithDesktopPaginationNavigationArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** IN DEVELOPMENT: MeetOurDoctors will be deprecated in the future. MeetOurStaff will take it's place. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/meetOurStaff) */
export type MeetOurStaffWithMobileArrowsNavigationArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type MeetOurStaffCollection = {
	__typename?: 'MeetOurStaffCollection';
	items: Array<Maybe<MeetOurStaff>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MeetOurStaffFilter = {
	AND?: InputMaybe<Array<InputMaybe<MeetOurStaffFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<MeetOurStaffFilter>>>;
	alignment?: InputMaybe<Scalars['String']>;
	alignment_contains?: InputMaybe<Scalars['String']>;
	alignment_exists?: InputMaybe<Scalars['Boolean']>;
	alignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	alignment_not?: InputMaybe<Scalars['String']>;
	alignment_not_contains?: InputMaybe<Scalars['String']>;
	alignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentMaxWidth?: InputMaybe<Scalars['String']>;
	contentMaxWidth_contains?: InputMaybe<Scalars['String']>;
	contentMaxWidth_exists?: InputMaybe<Scalars['Boolean']>;
	contentMaxWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentMaxWidth_not?: InputMaybe<Scalars['String']>;
	contentMaxWidth_not_contains?: InputMaybe<Scalars['String']>;
	contentMaxWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionBackground?: InputMaybe<Scalars['String']>;
	sectionBackground_contains?: InputMaybe<Scalars['String']>;
	sectionBackground_exists?: InputMaybe<Scalars['Boolean']>;
	sectionBackground_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionBackground_not?: InputMaybe<Scalars['String']>;
	sectionBackground_not_contains?: InputMaybe<Scalars['String']>;
	sectionBackground_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	showDisabledSwiperArrows?: InputMaybe<Scalars['Boolean']>;
	showDisabledSwiperArrows_exists?: InputMaybe<Scalars['Boolean']>;
	showDisabledSwiperArrows_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	withDesktopPaginationNavigation?: InputMaybe<Scalars['Boolean']>;
	withDesktopPaginationNavigation_exists?: InputMaybe<Scalars['Boolean']>;
	withDesktopPaginationNavigation_not?: InputMaybe<Scalars['Boolean']>;
	withMobileArrowsNavigation?: InputMaybe<Scalars['Boolean']>;
	withMobileArrowsNavigation_exists?: InputMaybe<Scalars['Boolean']>;
	withMobileArrowsNavigation_not?: InputMaybe<Scalars['Boolean']>;
};

export type MeetOurStaffLinkingCollections = {
	__typename?: 'MeetOurStaffLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type MeetOurStaffLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MeetOurStaffLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurStaffLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MeetOurStaffLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurStaffLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MeetOurStaffLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurStaffLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MeetOurStaffLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MeetOurStaffLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MeetOurStaffLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MeetOurStaffOrder =
	| 'alignment_ASC'
	| 'alignment_DESC'
	| 'contentMaxWidth_ASC'
	| 'contentMaxWidth_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sectionBackground_ASC'
	| 'sectionBackground_DESC'
	| 'showDisabledSwiperArrows_ASC'
	| 'showDisabledSwiperArrows_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'withDesktopPaginationNavigation_ASC'
	| 'withDesktopPaginationNavigation_DESC'
	| 'withMobileArrowsNavigation_ASC'
	| 'withMobileArrowsNavigation_DESC';

/** Generic Mobile button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mobileButton) */
export type MobileButton = Entry &
	_Node & {
		__typename?: 'MobileButton';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		icon?: Maybe<Scalars['String']>;
		iconPosition?: Maybe<Scalars['String']>;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<MobileButtonLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		size?: Maybe<Scalars['String']>;
		sys: Sys;
		variant?: Maybe<Scalars['String']>;
	};

/** Generic Mobile button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mobileButton) */
export type MobileButtonIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Mobile button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mobileButton) */
export type MobileButtonIconPositionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Mobile button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mobileButton) */
export type MobileButtonLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Mobile button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mobileButton) */
export type MobileButtonLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Generic Mobile button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mobileButton) */
export type MobileButtonNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Mobile button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mobileButton) */
export type MobileButtonSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Mobile button content model [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/mobileButton) */
export type MobileButtonVariantArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type MobileButtonCollection = {
	__typename?: 'MobileButtonCollection';
	items: Array<Maybe<MobileButton>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MobileButtonFilter = {
	AND?: InputMaybe<Array<InputMaybe<MobileButtonFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<MobileButtonFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	icon?: InputMaybe<Scalars['String']>;
	iconPosition?: InputMaybe<Scalars['String']>;
	iconPosition_contains?: InputMaybe<Scalars['String']>;
	iconPosition_exists?: InputMaybe<Scalars['Boolean']>;
	iconPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconPosition_not?: InputMaybe<Scalars['String']>;
	iconPosition_not_contains?: InputMaybe<Scalars['String']>;
	iconPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size?: InputMaybe<Scalars['String']>;
	size_contains?: InputMaybe<Scalars['String']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size_not?: InputMaybe<Scalars['String']>;
	size_not_contains?: InputMaybe<Scalars['String']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	variant?: InputMaybe<Scalars['String']>;
	variant_contains?: InputMaybe<Scalars['String']>;
	variant_exists?: InputMaybe<Scalars['Boolean']>;
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	variant_not?: InputMaybe<Scalars['String']>;
	variant_not_contains?: InputMaybe<Scalars['String']>;
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MobileButtonLinkingCollections = {
	__typename?: 'MobileButtonLinkingCollections';
	buttonCollection?: Maybe<ButtonCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type MobileButtonLinkingCollectionsButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MobileButtonLinkingCollectionsButtonCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MobileButtonLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MobileButtonLinkingCollectionsButtonCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type MobileButtonOrder =
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

/** modal that pops up (usually if there's an error) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/modal) */
export type Modal = Entry &
	_Node & {
		__typename?: 'Modal';
		_id: Scalars['ID'];
		bodyText?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		headlineHeading?: Maybe<Scalars['String']>;
		headlineText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ModalLinkingCollections>;
		modalTitle?: Maybe<Scalars['String']>;
		primaryButtonText?: Maybe<Scalars['String']>;
		secondaryButtonText?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** modal that pops up (usually if there's an error) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/modal) */
export type ModalBodyTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** modal that pops up (usually if there's an error) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/modal) */
export type ModalHeadlineHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** modal that pops up (usually if there's an error) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/modal) */
export type ModalHeadlineTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** modal that pops up (usually if there's an error) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/modal) */
export type ModalLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** modal that pops up (usually if there's an error) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/modal) */
export type ModalModalTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** modal that pops up (usually if there's an error) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/modal) */
export type ModalPrimaryButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** modal that pops up (usually if there's an error) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/modal) */
export type ModalSecondaryButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ModalCollection = {
	__typename?: 'ModalCollection';
	items: Array<Maybe<Modal>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ModalFilter = {
	AND?: InputMaybe<Array<InputMaybe<ModalFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ModalFilter>>>;
	bodyText?: InputMaybe<Scalars['String']>;
	bodyText_contains?: InputMaybe<Scalars['String']>;
	bodyText_exists?: InputMaybe<Scalars['Boolean']>;
	bodyText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bodyText_not?: InputMaybe<Scalars['String']>;
	bodyText_not_contains?: InputMaybe<Scalars['String']>;
	bodyText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headlineHeading?: InputMaybe<Scalars['String']>;
	headlineHeading_contains?: InputMaybe<Scalars['String']>;
	headlineHeading_exists?: InputMaybe<Scalars['Boolean']>;
	headlineHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineHeading_not?: InputMaybe<Scalars['String']>;
	headlineHeading_not_contains?: InputMaybe<Scalars['String']>;
	headlineHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText?: InputMaybe<Scalars['String']>;
	headlineText_contains?: InputMaybe<Scalars['String']>;
	headlineText_exists?: InputMaybe<Scalars['Boolean']>;
	headlineText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText_not?: InputMaybe<Scalars['String']>;
	headlineText_not_contains?: InputMaybe<Scalars['String']>;
	headlineText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	modalTitle?: InputMaybe<Scalars['String']>;
	modalTitle_contains?: InputMaybe<Scalars['String']>;
	modalTitle_exists?: InputMaybe<Scalars['Boolean']>;
	modalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	modalTitle_not?: InputMaybe<Scalars['String']>;
	modalTitle_not_contains?: InputMaybe<Scalars['String']>;
	modalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	primaryButtonText?: InputMaybe<Scalars['String']>;
	primaryButtonText_contains?: InputMaybe<Scalars['String']>;
	primaryButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	primaryButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	primaryButtonText_not?: InputMaybe<Scalars['String']>;
	primaryButtonText_not_contains?: InputMaybe<Scalars['String']>;
	primaryButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	secondaryButtonText?: InputMaybe<Scalars['String']>;
	secondaryButtonText_contains?: InputMaybe<Scalars['String']>;
	secondaryButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	secondaryButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	secondaryButtonText_not?: InputMaybe<Scalars['String']>;
	secondaryButtonText_not_contains?: InputMaybe<Scalars['String']>;
	secondaryButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ModalLinkingCollections = {
	__typename?: 'ModalLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type ModalLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ModalLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ModalLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ModalLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ModalOrder =
	| 'bodyText_ASC'
	| 'bodyText_DESC'
	| 'headlineHeading_ASC'
	| 'headlineHeading_DESC'
	| 'headlineText_ASC'
	| 'headlineText_DESC'
	| 'modalTitle_ASC'
	| 'modalTitle_DESC'
	| 'primaryButtonText_ASC'
	| 'primaryButtonText_DESC'
	| 'secondaryButtonText_ASC'
	| 'secondaryButtonText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCard = Entry &
	_Node & {
		__typename?: 'MultiCard';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		bigScreenBackground?: Maybe<Scalars['Boolean']>;
		cardsAlignment?: Maybe<Scalars['String']>;
		cardsCollection?: Maybe<MultiCardCardsCollection>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		forMobile?: Maybe<Scalars['String']>;
		lastRowCardsAlignment?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<MultiCardLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		roundedImage?: Maybe<Scalars['Boolean']>;
		sectionEyebrow?: Maybe<Scalars['String']>;
		sectionInfoAlignment?: Maybe<Scalars['String']>;
		sectionTitle?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardBigScreenBackgroundArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardCardsAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardCardsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardCardsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CardFilter>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardForMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardLastRowCardsAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardRoundedImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardSectionEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardSectionInfoAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards. MultiCard is deprecated, use MultiCardV2 instead [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCard) */
export type MultiCardSectionTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type MultiCardCardsCollection = {
	__typename?: 'MultiCardCardsCollection';
	items: Array<Maybe<Card>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MultiCardCardsCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MultiCardCollection = {
	__typename?: 'MultiCardCollection';
	items: Array<Maybe<MultiCard>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MultiCardFilter = {
	AND?: InputMaybe<Array<InputMaybe<MultiCardFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<MultiCardFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bigScreenBackground?: InputMaybe<Scalars['Boolean']>;
	bigScreenBackground_exists?: InputMaybe<Scalars['Boolean']>;
	bigScreenBackground_not?: InputMaybe<Scalars['Boolean']>;
	cards?: InputMaybe<CfCardNestedFilter>;
	cardsAlignment?: InputMaybe<Scalars['String']>;
	cardsAlignment_contains?: InputMaybe<Scalars['String']>;
	cardsAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	cardsAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardsAlignment_not?: InputMaybe<Scalars['String']>;
	cardsAlignment_not_contains?: InputMaybe<Scalars['String']>;
	cardsAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	forMobile?: InputMaybe<Scalars['String']>;
	forMobile_contains?: InputMaybe<Scalars['String']>;
	forMobile_exists?: InputMaybe<Scalars['Boolean']>;
	forMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	forMobile_not?: InputMaybe<Scalars['String']>;
	forMobile_not_contains?: InputMaybe<Scalars['String']>;
	forMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	lastRowCardsAlignment?: InputMaybe<Scalars['String']>;
	lastRowCardsAlignment_contains?: InputMaybe<Scalars['String']>;
	lastRowCardsAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	lastRowCardsAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	lastRowCardsAlignment_not?: InputMaybe<Scalars['String']>;
	lastRowCardsAlignment_not_contains?: InputMaybe<Scalars['String']>;
	lastRowCardsAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	roundedImage?: InputMaybe<Scalars['Boolean']>;
	roundedImage_exists?: InputMaybe<Scalars['Boolean']>;
	roundedImage_not?: InputMaybe<Scalars['Boolean']>;
	sectionEyebrow?: InputMaybe<Scalars['String']>;
	sectionEyebrow_contains?: InputMaybe<Scalars['String']>;
	sectionEyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	sectionEyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionEyebrow_not?: InputMaybe<Scalars['String']>;
	sectionEyebrow_not_contains?: InputMaybe<Scalars['String']>;
	sectionEyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionInfoAlignment?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_contains?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	sectionInfoAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionInfoAlignment_not?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_not_contains?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle?: InputMaybe<Scalars['String']>;
	sectionTitle_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_exists?: InputMaybe<Scalars['Boolean']>;
	sectionTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle_not?: InputMaybe<Scalars['String']>;
	sectionTitle_not_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type MultiCardLinkingCollections = {
	__typename?: 'MultiCardLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type MultiCardLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MultiCardLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MultiCardLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MultiCardOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'bigScreenBackground_ASC'
	| 'bigScreenBackground_DESC'
	| 'cardsAlignment_ASC'
	| 'cardsAlignment_DESC'
	| 'forMobile_ASC'
	| 'forMobile_DESC'
	| 'lastRowCardsAlignment_ASC'
	| 'lastRowCardsAlignment_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'roundedImage_ASC'
	| 'roundedImage_DESC'
	| 'sectionEyebrow_ASC'
	| 'sectionEyebrow_DESC'
	| 'sectionInfoAlignment_ASC'
	| 'sectionInfoAlignment_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2 = Entry &
	_Node & {
		__typename?: 'MultiCardV2';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		backgroundImage?: Maybe<ImageAsset>;
		bigScreenBackground?: Maybe<Scalars['Boolean']>;
		buttonsCollection?: Maybe<MultiCardV2ButtonsCollection>;
		cardsAlignment?: Maybe<Scalars['String']>;
		cardsCollection?: Maybe<MultiCardV2CardsCollection>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		descriptionRichText?: Maybe<MultiCardV2DescriptionRichText>;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		displayAsStepper?: Maybe<Scalars['Boolean']>;
		enableMobileCarouselNavigation?: Maybe<Scalars['Boolean']>;
		enableResourceCardMode?: Maybe<Scalars['Boolean']>;
		eyebrowColor?: Maybe<Scalars['String']>;
		eyebrowSx?: Maybe<Scalars['String']>;
		eyebrowTextTransform?: Maybe<Scalars['String']>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		fixBackgroundToBottom?: Maybe<Scalars['Boolean']>;
		forMobile?: Maybe<Scalars['String']>;
		headerSpacing?: Maybe<Scalars['String']>;
		lastRowCardsAlignment?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<MultiCardV2LinkingCollections>;
		name?: Maybe<Scalars['String']>;
		numberOfCardsInLineDesktop?: Maybe<Scalars['Int']>;
		roundedImage?: Maybe<Scalars['Boolean']>;
		sectionEyebrow?: Maybe<Scalars['String']>;
		sectionInfoAlignment?: Maybe<Scalars['String']>;
		sectionTitle?: Maybe<Scalars['String']>;
		sectionTitleHtag?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2BackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2BackgroundImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2BigScreenBackgroundArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2ButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardV2ButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2CardsAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2CardsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardV2CardsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CardFilter>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2DescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2DescriptionRichTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2DisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2DisplayAsStepperArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2EnableMobileCarouselNavigationArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2EnableResourceCardModeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2EyebrowColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2EyebrowSxArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2EyebrowTextTransformArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2FacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2FixBackgroundToBottomArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2ForMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2HeaderSpacingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2LastRowCardsAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2LinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2NameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2NumberOfCardsInLineDesktopArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2RoundedImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2SectionEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2SectionInfoAlignmentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2SectionTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Supports either 2 or 3 Cards [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/multiCardV2) */
export type MultiCardV2SectionTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type MultiCardV2ButtonsCollection = {
	__typename?: 'MultiCardV2ButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MultiCardV2ButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type MultiCardV2CardsCollection = {
	__typename?: 'MultiCardV2CardsCollection';
	items: Array<Maybe<Card>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MultiCardV2CardsCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MultiCardV2Collection = {
	__typename?: 'MultiCardV2Collection';
	items: Array<Maybe<MultiCardV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type MultiCardV2DescriptionRichText = {
	__typename?: 'MultiCardV2DescriptionRichText';
	json: Scalars['JSON'];
	links: MultiCardV2DescriptionRichTextLinks;
};

export type MultiCardV2DescriptionRichTextAssets = {
	__typename?: 'MultiCardV2DescriptionRichTextAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type MultiCardV2DescriptionRichTextEntries = {
	__typename?: 'MultiCardV2DescriptionRichTextEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type MultiCardV2DescriptionRichTextLinks = {
	__typename?: 'MultiCardV2DescriptionRichTextLinks';
	assets: MultiCardV2DescriptionRichTextAssets;
	entries: MultiCardV2DescriptionRichTextEntries;
	resources: MultiCardV2DescriptionRichTextResources;
};

export type MultiCardV2DescriptionRichTextResources = {
	__typename?: 'MultiCardV2DescriptionRichTextResources';
	block: Array<MultiCardV2DescriptionRichTextResourcesBlock>;
	hyperlink: Array<MultiCardV2DescriptionRichTextResourcesHyperlink>;
	inline: Array<MultiCardV2DescriptionRichTextResourcesInline>;
};

export type MultiCardV2DescriptionRichTextResourcesBlock = ResourceLink & {
	__typename?: 'MultiCardV2DescriptionRichTextResourcesBlock';
	sys: ResourceSys;
};

export type MultiCardV2DescriptionRichTextResourcesHyperlink = ResourceLink & {
	__typename?: 'MultiCardV2DescriptionRichTextResourcesHyperlink';
	sys: ResourceSys;
};

export type MultiCardV2DescriptionRichTextResourcesInline = ResourceLink & {
	__typename?: 'MultiCardV2DescriptionRichTextResourcesInline';
	sys: ResourceSys;
};

export type MultiCardV2Filter = {
	AND?: InputMaybe<Array<InputMaybe<MultiCardV2Filter>>>;
	OR?: InputMaybe<Array<InputMaybe<MultiCardV2Filter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundImage?: InputMaybe<CfImageAssetNestedFilter>;
	backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
	bigScreenBackground?: InputMaybe<Scalars['Boolean']>;
	bigScreenBackground_exists?: InputMaybe<Scalars['Boolean']>;
	bigScreenBackground_not?: InputMaybe<Scalars['Boolean']>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	cards?: InputMaybe<CfCardNestedFilter>;
	cardsAlignment?: InputMaybe<Scalars['String']>;
	cardsAlignment_contains?: InputMaybe<Scalars['String']>;
	cardsAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	cardsAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardsAlignment_not?: InputMaybe<Scalars['String']>;
	cardsAlignment_not_contains?: InputMaybe<Scalars['String']>;
	cardsAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	descriptionRichText_contains?: InputMaybe<Scalars['String']>;
	descriptionRichText_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionRichText_not_contains?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	displayAsStepper?: InputMaybe<Scalars['Boolean']>;
	displayAsStepper_exists?: InputMaybe<Scalars['Boolean']>;
	displayAsStepper_not?: InputMaybe<Scalars['Boolean']>;
	enableMobileCarouselNavigation?: InputMaybe<Scalars['Boolean']>;
	enableMobileCarouselNavigation_exists?: InputMaybe<Scalars['Boolean']>;
	enableMobileCarouselNavigation_not?: InputMaybe<Scalars['Boolean']>;
	enableResourceCardMode?: InputMaybe<Scalars['Boolean']>;
	enableResourceCardMode_exists?: InputMaybe<Scalars['Boolean']>;
	enableResourceCardMode_not?: InputMaybe<Scalars['Boolean']>;
	eyebrowColor?: InputMaybe<Scalars['String']>;
	eyebrowColor_contains?: InputMaybe<Scalars['String']>;
	eyebrowColor_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowColor_not?: InputMaybe<Scalars['String']>;
	eyebrowColor_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowSx?: InputMaybe<Scalars['String']>;
	eyebrowSx_contains?: InputMaybe<Scalars['String']>;
	eyebrowSx_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowSx_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowSx_not?: InputMaybe<Scalars['String']>;
	eyebrowSx_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowSx_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowTextTransform?: InputMaybe<Scalars['String']>;
	eyebrowTextTransform_contains?: InputMaybe<Scalars['String']>;
	eyebrowTextTransform_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowTextTransform_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowTextTransform_not?: InputMaybe<Scalars['String']>;
	eyebrowTextTransform_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowTextTransform_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fixBackgroundToBottom?: InputMaybe<Scalars['Boolean']>;
	fixBackgroundToBottom_exists?: InputMaybe<Scalars['Boolean']>;
	fixBackgroundToBottom_not?: InputMaybe<Scalars['Boolean']>;
	forMobile?: InputMaybe<Scalars['String']>;
	forMobile_contains?: InputMaybe<Scalars['String']>;
	forMobile_exists?: InputMaybe<Scalars['Boolean']>;
	forMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	forMobile_not?: InputMaybe<Scalars['String']>;
	forMobile_not_contains?: InputMaybe<Scalars['String']>;
	forMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headerSpacing?: InputMaybe<Scalars['String']>;
	headerSpacing_contains?: InputMaybe<Scalars['String']>;
	headerSpacing_exists?: InputMaybe<Scalars['Boolean']>;
	headerSpacing_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headerSpacing_not?: InputMaybe<Scalars['String']>;
	headerSpacing_not_contains?: InputMaybe<Scalars['String']>;
	headerSpacing_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	lastRowCardsAlignment?: InputMaybe<Scalars['String']>;
	lastRowCardsAlignment_contains?: InputMaybe<Scalars['String']>;
	lastRowCardsAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	lastRowCardsAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	lastRowCardsAlignment_not?: InputMaybe<Scalars['String']>;
	lastRowCardsAlignment_not_contains?: InputMaybe<Scalars['String']>;
	lastRowCardsAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	numberOfCardsInLineDesktop?: InputMaybe<Scalars['Int']>;
	numberOfCardsInLineDesktop_exists?: InputMaybe<Scalars['Boolean']>;
	numberOfCardsInLineDesktop_gt?: InputMaybe<Scalars['Int']>;
	numberOfCardsInLineDesktop_gte?: InputMaybe<Scalars['Int']>;
	numberOfCardsInLineDesktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	numberOfCardsInLineDesktop_lt?: InputMaybe<Scalars['Int']>;
	numberOfCardsInLineDesktop_lte?: InputMaybe<Scalars['Int']>;
	numberOfCardsInLineDesktop_not?: InputMaybe<Scalars['Int']>;
	numberOfCardsInLineDesktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	roundedImage?: InputMaybe<Scalars['Boolean']>;
	roundedImage_exists?: InputMaybe<Scalars['Boolean']>;
	roundedImage_not?: InputMaybe<Scalars['Boolean']>;
	sectionEyebrow?: InputMaybe<Scalars['String']>;
	sectionEyebrow_contains?: InputMaybe<Scalars['String']>;
	sectionEyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	sectionEyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionEyebrow_not?: InputMaybe<Scalars['String']>;
	sectionEyebrow_not_contains?: InputMaybe<Scalars['String']>;
	sectionEyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionInfoAlignment?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_contains?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	sectionInfoAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionInfoAlignment_not?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_not_contains?: InputMaybe<Scalars['String']>;
	sectionInfoAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle?: InputMaybe<Scalars['String']>;
	sectionTitleHtag?: InputMaybe<Scalars['String']>;
	sectionTitleHtag_contains?: InputMaybe<Scalars['String']>;
	sectionTitleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	sectionTitleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitleHtag_not?: InputMaybe<Scalars['String']>;
	sectionTitleHtag_not_contains?: InputMaybe<Scalars['String']>;
	sectionTitleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_exists?: InputMaybe<Scalars['Boolean']>;
	sectionTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle_not?: InputMaybe<Scalars['String']>;
	sectionTitle_not_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type MultiCardV2LinkingCollections = {
	__typename?: 'MultiCardV2LinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type MultiCardV2LinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MultiCardV2LinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardV2LinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MultiCardV2LinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardV2LinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MultiCardV2LinkingCollectionsProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardV2LinkingCollectionsProviderDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MultiCardV2LinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardV2LinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MultiCardV2LinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardV2LinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type MultiCardV2LinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MultiCardV2LinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MultiCardV2LinkingCollectionsProviderDetailsPageCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type MultiCardV2LinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type MultiCardV2LinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type MultiCardV2Order =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'bigScreenBackground_ASC'
	| 'bigScreenBackground_DESC'
	| 'cardsAlignment_ASC'
	| 'cardsAlignment_DESC'
	| 'displayAsStepper_ASC'
	| 'displayAsStepper_DESC'
	| 'enableMobileCarouselNavigation_ASC'
	| 'enableMobileCarouselNavigation_DESC'
	| 'enableResourceCardMode_ASC'
	| 'enableResourceCardMode_DESC'
	| 'eyebrowColor_ASC'
	| 'eyebrowColor_DESC'
	| 'eyebrowSx_ASC'
	| 'eyebrowSx_DESC'
	| 'eyebrowTextTransform_ASC'
	| 'eyebrowTextTransform_DESC'
	| 'fixBackgroundToBottom_ASC'
	| 'fixBackgroundToBottom_DESC'
	| 'forMobile_ASC'
	| 'forMobile_DESC'
	| 'headerSpacing_ASC'
	| 'headerSpacing_DESC'
	| 'lastRowCardsAlignment_ASC'
	| 'lastRowCardsAlignment_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'numberOfCardsInLineDesktop_ASC'
	| 'numberOfCardsInLineDesktop_DESC'
	| 'roundedImage_ASC'
	| 'roundedImage_DESC'
	| 'sectionEyebrow_ASC'
	| 'sectionEyebrow_DESC'
	| 'sectionInfoAlignment_ASC'
	| 'sectionInfoAlignment_DESC'
	| 'sectionTitleHtag_ASC'
	| 'sectionTitleHtag_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type MultiChoiceMultiAnswer = HasAnswerFormat &
	HasPredefinedAnswer & {
		__typename?: 'MultiChoiceMultiAnswer';
		answers?: Maybe<Array<PredefinedAnswer>>;
		format?: Maybe<QuestionnaireAnswerFormat>;
	};

export type MultiChoiceSingleAnswer = HasAnswerFormat &
	HasPredefinedAnswer & {
		__typename?: 'MultiChoiceSingleAnswer';
		answers?: Maybe<Array<PredefinedAnswer>>;
		format?: Maybe<QuestionnaireAnswerFormat>;
	};

export type MultiPredefinedQuestionnaireAnswerInput = {
	answerIds: Array<Scalars['NonEmptyString']>;
};

export type MultiPredefinedQuestionnaireAnswerScheduleInput = {
	answerIds: Array<Scalars['NonEmptyString']>;
	questionId: Scalars['NonEmptyString'];
};

/** Root mutation type. */
export type Mutation = {
	__typename?: 'Mutation';
	/** Recaptcha protected */
	cancelTimeslotReservation?: Maybe<CancelTimeslotReservationPayload>;
	confirmVisit?: Maybe<ConfirmVisitPayload>;
	/** Recaptcha protected */
	createTimeslotReservation?: Maybe<CreateTimeslotReservationPayload>;
	me?: Maybe<UserMutation>;
	/** Scheduling visits only for "New patients". Recaptcha protected */
	scheduleNewPatientVisit?: Maybe<ScheduleNewPatientVisitPayload>;
};

/** Root mutation type. */
export type MutationCancelTimeslotReservationArgs = {
	input: CancelTimeslotReservationInput;
};

/** Root mutation type. */
export type MutationConfirmVisitArgs = {
	input: ConfirmVisitInput;
};

/** Root mutation type. */
export type MutationCreateTimeslotReservationArgs = {
	input: CreateTimeslotReservationInput;
};

/** Root mutation type. */
export type MutationScheduleNewPatientVisitArgs = {
	input: ScheduleNewPatientVisitInput;
};

export type MutationError = Error & {
	__typename?: 'MutationError';
	message: Scalars['String'];
};

/** A generic container for a set of name value pairs [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/nameValues) */
export type NameValues = Entry &
	_Node & {
		__typename?: 'NameValues';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<NameValuesLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		value?: Maybe<NameValuesValue>;
	};

/** A generic container for a set of name value pairs [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/nameValues) */
export type NameValuesLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A generic container for a set of name value pairs [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/nameValues) */
export type NameValuesNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A generic container for a set of name value pairs [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/nameValues) */
export type NameValuesValueArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

export type NameValuesCollection = {
	__typename?: 'NameValuesCollection';
	items: Array<Maybe<NameValues>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type NameValuesFilter = {
	AND?: InputMaybe<Array<InputMaybe<NameValuesFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<NameValuesFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	value_exists?: InputMaybe<Scalars['Boolean']>;
};

export type NameValuesLinkingCollections = {
	__typename?: 'NameValuesLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type NameValuesLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NameValuesOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NameValuesValue = ContentTypeRichText | ImageAsset | VideoAsset;

export type NamedAttribute = {
	__typename?: 'NamedAttribute';
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	value?: Maybe<Scalars['String']>;
};

/** A group of links supporting Internal and External URLs for Navigation purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationGroup) */
export type NavigationGroup = Entry &
	_Node & {
		__typename?: 'NavigationGroup';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		displayAsExpandedForMobileMenu?: Maybe<Scalars['Boolean']>;
		groupLink?: Maybe<NavigationLink>;
		linkedFrom?: Maybe<NavigationGroupLinkingCollections>;
		linksCollection?: Maybe<NavigationGroupLinksCollection>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** A group of links supporting Internal and External URLs for Navigation purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationGroup) */
export type NavigationGroupDisplayAsExpandedForMobileMenuArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A group of links supporting Internal and External URLs for Navigation purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationGroup) */
export type NavigationGroupGroupLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<NavigationLinkFilter>;
};

/** A group of links supporting Internal and External URLs for Navigation purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationGroup) */
export type NavigationGroupLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A group of links supporting Internal and External URLs for Navigation purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationGroup) */
export type NavigationGroupLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationGroupLinksCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NavigationLinkFilter>;
};

/** A group of links supporting Internal and External URLs for Navigation purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationGroup) */
export type NavigationGroupNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type NavigationGroupCollection = {
	__typename?: 'NavigationGroupCollection';
	items: Array<Maybe<NavigationGroup>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type NavigationGroupFilter = {
	AND?: InputMaybe<Array<InputMaybe<NavigationGroupFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<NavigationGroupFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	displayAsExpandedForMobileMenu?: InputMaybe<Scalars['Boolean']>;
	displayAsExpandedForMobileMenu_exists?: InputMaybe<Scalars['Boolean']>;
	displayAsExpandedForMobileMenu_not?: InputMaybe<Scalars['Boolean']>;
	groupLink?: InputMaybe<CfNavigationLinkNestedFilter>;
	groupLink_exists?: InputMaybe<Scalars['Boolean']>;
	links?: InputMaybe<CfNavigationLinkNestedFilter>;
	linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type NavigationGroupLinkingCollections = {
	__typename?: 'NavigationGroupLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	headerCollection?: Maybe<HeaderCollection>;
	headerSubNavCollection?: Maybe<HeaderSubNavCollection>;
	odpLocalizedSecondaryFooterCollection?: Maybe<OdpLocalizedSecondaryFooterCollection>;
};

export type NavigationGroupLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationGroupLinkingCollectionsHeaderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationGroupLinkingCollectionsHeaderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationGroupLinkingCollectionsHeaderSubNavCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationGroupLinkingCollectionsHeaderSubNavCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationGroupLinkingCollectionsOdpLocalizedSecondaryFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationGroupLinkingCollectionsOdpLocalizedSecondaryFooterCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationGroupLinkingCollectionsHeaderCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'darkHeader_ASC'
	| 'darkHeader_DESC'
	| 'hideFullSiteSearch_ASC'
	| 'hideFullSiteSearch_DESC'
	| 'hideHamburgerMenuToggleButton_ASC'
	| 'hideHamburgerMenuToggleButton_DESC'
	| 'locationBarFindOfficeUrl_ASC'
	| 'locationBarFindOfficeUrl_DESC'
	| 'locationBarWithOfficeTitle_ASC'
	| 'locationBarWithOfficeTitle_DESC'
	| 'locationBarWithOutOfficeTitle_ASC'
	| 'locationBarWithOutOfficeTitle_DESC'
	| 'myAccountLabel_ASC'
	| 'myAccountLabel_DESC'
	| 'profileLinkUrl_ASC'
	| 'profileLinkUrl_DESC'
	| 'schedulingButtonTextMobile_ASC'
	| 'schedulingButtonTextMobile_DESC'
	| 'schedulingButtonText_ASC'
	| 'schedulingButtonText_DESC'
	| 'schedulingButtonUrlMobile_ASC'
	| 'schedulingButtonUrlMobile_DESC'
	| 'schedulingButtonUrl_ASC'
	| 'schedulingButtonUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useHeaderLocationCards_ASC'
	| 'useHeaderLocationCards_DESC'
	| 'useNewLayout_ASC'
	| 'useNewLayout_DESC'
	| 'usePopupScheduling_ASC'
	| 'usePopupScheduling_DESC';

export type NavigationGroupLinkingCollectionsHeaderSubNavCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NavigationGroupLinkingCollectionsOdpLocalizedSecondaryFooterCollectionOrder =
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NavigationGroupLinksCollection = {
	__typename?: 'NavigationGroupLinksCollection';
	items: Array<Maybe<NavigationLink>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type NavigationGroupLinksCollectionOrder =
	| 'externalUrl_ASC'
	| 'externalUrl_DESC'
	| 'isInternal_ASC'
	| 'isInternal_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'linkText_ASC'
	| 'linkText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'openInNewTab_ASC'
	| 'openInNewTab_DESC'
	| 'openInNewWindow_ASC'
	| 'openInNewWindow_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NavigationGroupOrder =
	| 'displayAsExpandedForMobileMenu_ASC'
	| 'displayAsExpandedForMobileMenu_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLink = Entry &
	_Node & {
		__typename?: 'NavigationLink';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		contentfulMetadata: ContentfulMetadata;
		externalUrl?: Maybe<Scalars['String']>;
		isInternal?: Maybe<Scalars['Boolean']>;
		isLocalized?: Maybe<Scalars['Boolean']>;
		linkIcon?: Maybe<Asset>;
		linkText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<NavigationLinkLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		openInNewTab?: Maybe<Scalars['Boolean']>;
		openInNewWindow?: Maybe<Scalars['Boolean']>;
		pageData?: Maybe<Page>;
		sys: Sys;
	};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkExternalUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkIsInternalArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkIsLocalizedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkLinkIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkLinkTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkOpenInNewTabArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkOpenInNewWindowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Link model for linking to internal and external pages. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/navigationLink) */
export type NavigationLinkPageDataArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<PageFilter>;
};

export type NavigationLinkCollection = {
	__typename?: 'NavigationLinkCollection';
	items: Array<Maybe<NavigationLink>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type NavigationLinkFilter = {
	AND?: InputMaybe<Array<InputMaybe<NavigationLinkFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<NavigationLinkFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	externalUrl?: InputMaybe<Scalars['String']>;
	externalUrl_contains?: InputMaybe<Scalars['String']>;
	externalUrl_exists?: InputMaybe<Scalars['Boolean']>;
	externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	externalUrl_not?: InputMaybe<Scalars['String']>;
	externalUrl_not_contains?: InputMaybe<Scalars['String']>;
	externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	isInternal?: InputMaybe<Scalars['Boolean']>;
	isInternal_exists?: InputMaybe<Scalars['Boolean']>;
	isInternal_not?: InputMaybe<Scalars['Boolean']>;
	isLocalized?: InputMaybe<Scalars['Boolean']>;
	isLocalized_exists?: InputMaybe<Scalars['Boolean']>;
	isLocalized_not?: InputMaybe<Scalars['Boolean']>;
	linkIcon_exists?: InputMaybe<Scalars['Boolean']>;
	linkText?: InputMaybe<Scalars['String']>;
	linkText_contains?: InputMaybe<Scalars['String']>;
	linkText_exists?: InputMaybe<Scalars['Boolean']>;
	linkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkText_not?: InputMaybe<Scalars['String']>;
	linkText_not_contains?: InputMaybe<Scalars['String']>;
	linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	openInNewTab?: InputMaybe<Scalars['Boolean']>;
	openInNewTab_exists?: InputMaybe<Scalars['Boolean']>;
	openInNewTab_not?: InputMaybe<Scalars['Boolean']>;
	openInNewWindow?: InputMaybe<Scalars['Boolean']>;
	openInNewWindow_exists?: InputMaybe<Scalars['Boolean']>;
	openInNewWindow_not?: InputMaybe<Scalars['Boolean']>;
	pageData?: InputMaybe<CfPageNestedFilter>;
	pageData_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type NavigationLinkLinkingCollections = {
	__typename?: 'NavigationLinkLinkingCollections';
	adFooterCollection?: Maybe<AdFooterCollection>;
	buttonCollection?: Maybe<ButtonCollection>;
	entryCollection?: Maybe<EntryCollection>;
	footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
	footerNavigationGroupV2Collection?: Maybe<FooterNavigationGroupV2Collection>;
	footerV2Collection?: Maybe<FooterV2Collection>;
	headerCollection?: Maybe<HeaderCollection>;
	headerMainNavCollection?: Maybe<HeaderMainNavCollection>;
	navigationGroupCollection?: Maybe<NavigationGroupCollection>;
};

export type NavigationLinkLinkingCollectionsAdFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkLinkingCollectionsAdFooterCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkLinkingCollectionsButtonCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsFooterNavigationGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkLinkingCollectionsFooterNavigationGroupCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsFooterNavigationGroupV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkLinkingCollectionsFooterNavigationGroupV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsFooterV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkLinkingCollectionsFooterV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsHeaderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkLinkingCollectionsHeaderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsHeaderMainNavCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkLinkingCollectionsHeaderMainNavCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsNavigationGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkLinkingCollectionsNavigationGroupCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NavigationLinkLinkingCollectionsAdFooterCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'isLightFooter_ASC'
	| 'isLightFooter_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useMobileStickyFooter_ASC'
	| 'useMobileStickyFooter_DESC';

export type NavigationLinkLinkingCollectionsButtonCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type NavigationLinkLinkingCollectionsFooterNavigationGroupCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'navigationGroupTitle_ASC'
	| 'navigationGroupTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NavigationLinkLinkingCollectionsFooterNavigationGroupV2CollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NavigationLinkLinkingCollectionsFooterV2CollectionOrder =
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NavigationLinkLinkingCollectionsHeaderCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'darkHeader_ASC'
	| 'darkHeader_DESC'
	| 'hideFullSiteSearch_ASC'
	| 'hideFullSiteSearch_DESC'
	| 'hideHamburgerMenuToggleButton_ASC'
	| 'hideHamburgerMenuToggleButton_DESC'
	| 'locationBarFindOfficeUrl_ASC'
	| 'locationBarFindOfficeUrl_DESC'
	| 'locationBarWithOfficeTitle_ASC'
	| 'locationBarWithOfficeTitle_DESC'
	| 'locationBarWithOutOfficeTitle_ASC'
	| 'locationBarWithOutOfficeTitle_DESC'
	| 'myAccountLabel_ASC'
	| 'myAccountLabel_DESC'
	| 'profileLinkUrl_ASC'
	| 'profileLinkUrl_DESC'
	| 'schedulingButtonTextMobile_ASC'
	| 'schedulingButtonTextMobile_DESC'
	| 'schedulingButtonText_ASC'
	| 'schedulingButtonText_DESC'
	| 'schedulingButtonUrlMobile_ASC'
	| 'schedulingButtonUrlMobile_DESC'
	| 'schedulingButtonUrl_ASC'
	| 'schedulingButtonUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useHeaderLocationCards_ASC'
	| 'useHeaderLocationCards_DESC'
	| 'useNewLayout_ASC'
	| 'useNewLayout_DESC'
	| 'usePopupScheduling_ASC'
	| 'usePopupScheduling_DESC';

export type NavigationLinkLinkingCollectionsHeaderMainNavCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NavigationLinkLinkingCollectionsNavigationGroupCollectionOrder =
	| 'displayAsExpandedForMobileMenu_ASC'
	| 'displayAsExpandedForMobileMenu_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NavigationLinkOrder =
	| 'externalUrl_ASC'
	| 'externalUrl_DESC'
	| 'isInternal_ASC'
	| 'isInternal_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'linkText_ASC'
	| 'linkText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'openInNewTab_ASC'
	| 'openInNewTab_DESC'
	| 'openInNewWindow_ASC'
	| 'openInNewWindow_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type NearbyFacilitiesFilter = {
	patientDateOfBirth?: InputMaybe<Scalars['Date']>;
	service?: InputMaybe<FacilityService>;
	visitReason?: InputMaybe<GeneralVisitReasonType>;
	/** In miles */
	withinRadius: Scalars['Decimal'];
};

export type NearbyFacilitiesInput = {
	take: Scalars['Int'];
	withinRadiusInMiles: Scalars['Int'];
};

export type NearbyFacilitiesList = {
	__typename?: 'NearbyFacilitiesList';
	hasMore: Scalars['Boolean'];
	items: Array<NearbyFacility>;
	/** @deprecated Use hasMore field. Total can't be determined precisely. */
	total: Scalars['Int'];
};

export type NearbyFacilitiesLocationFilter = {
	/** Brand defaults to 'AspenDental'. */
	brand?: InputMaybe<FacilityBrand>;
	latitude: Scalars['Decimal'];
	longitude: Scalars['Decimal'];
	patientDateOfBirth?: InputMaybe<Scalars['Date']>;
	visitReason?: InputMaybe<GeneralVisitReasonType>;
	/** In miles */
	withinRadius: Scalars['Decimal'];
};

export type NearbyFacilitiesScheduleSlots = {
	__typename?: 'NearbyFacilitiesScheduleSlots';
	mainFacility: FacilityWithScheduleSlots;
	nearbyFacilities?: Maybe<Array<NearbyFacilityWithScheduleSlots>>;
};

export type NearbyFacilitiesScheduleSlotsFilterInput = {
	dateFrom: Scalars['Date'];
	dateOfBirth: Scalars['Date'];
	dateTo: Scalars['Date'];
	daysOfWeek?: InputMaybe<Array<DayOfWeek>>;
	email?: InputMaybe<Scalars['String']>;
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	mainFacilityCode: Scalars['String'];
	nearbyFacilitiesInput?: InputMaybe<NearbyFacilitiesInput>;
	partOfDay?: InputMaybe<PartOfDay>;
	phone?: InputMaybe<Scalars['String']>;
	questionnaireAnswers?: InputMaybe<QuestionnaireAnswersInput>;
	visitAttemptId?: InputMaybe<Scalars['UUID']>;
	visitReason: GeneralVisitReasonType;
};

export type NearbyFacility = {
	__typename?: 'NearbyFacility';
	distanceInMiles: Scalars['Decimal'];
	facility: Facility;
};

export type NearbyFacilityWithScheduleSlots = {
	__typename?: 'NearbyFacilityWithScheduleSlots';
	nearbyFacility: NearbyFacility;
	slots?: Maybe<ScheduleSlotsList>;
};

export type NearbyRescheduleFacilitiesFilter = {
	dateFrom: Scalars['Date'];
	dateTo: Scalars['Date'];
	daysOfWeek?: InputMaybe<Array<DayOfWeek>>;
	partOfDay?: InputMaybe<PartOfDay>;
	scheduledVisitId: Scalars['String'];
	/** In miles */
	withinRadius: Scalars['Decimal'];
};

export type NewGuarantorInput = {
	address: NewPatientAddressInput;
	dateOfBirth: Scalars['Date'];
	email?: InputMaybe<Scalars['EmailAddress']>;
	firstName: Scalars['NonEmptyString'];
	lastName: Scalars['NonEmptyString'];
	phone: PatientPhoneInput;
};

export type NewPatientAddressInput = {
	address: Scalars['String'];
	city: NewPatientCityInput;
};

export type NewPatientCityInput = {
	existingCity?: InputMaybe<Scalars['CityUid']>;
};

export type NewPatientEligibilityFilterInput = {
	eligibilities: Array<PatientEligibilityType>;
	patientEligibilityInformation: PatientEligibilityInformationInput;
};

export type NewPatientGuarantorInput = {
	otherPersonAsGuarantor?: InputMaybe<OtherPersonAsGuarantorInput>;
};

export type NewPatientInput = {
	address: NewPatientAddressInput;
	dateOfBirth: Scalars['Date'];
	email?: InputMaybe<Scalars['EmailAddress']>;
	firstName: Scalars['NonEmptyString'];
	lastName: Scalars['NonEmptyString'];
	patientsGuarantor?: InputMaybe<NewPatientGuarantorInput>;
	phone: PatientPhoneInput;
};

export type NewPatientRelationToGuarantor = 'CHILD' | 'DISABLED_DEPENDENT' | 'OTHER_DEPENDENT';

export type NonConfirmablePastVisitError = Error & {
	__typename?: 'NonConfirmablePastVisitError';
	message: Scalars['String'];
};

/** Returned when question id does not exist in questionnaire being answered. */
export type NonExistingQuestionnaireQuestionError = Error & {
	__typename?: 'NonExistingQuestionnaireQuestionError';
	message: Scalars['String'];
	nonExistingQuestionId: Scalars['String'];
};

export type NonExistingVisitError = Error & {
	__typename?: 'NonExistingVisitError';
	message: Scalars['String'];
	nonExistingVisitId: Scalars['String'];
};

export type NonReschedulableVisitError = Error & {
	__typename?: 'NonReschedulableVisitError';
	message: Scalars['String'];
};

export type NotAvailableSlotError = Error & {
	__typename?: 'NotAvailableSlotError';
	message: Scalars['String'];
};

/** a modal that pops up on any sort of notification [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/notificationModal) */
export type NotificationModal = Entry &
	_Node & {
		__typename?: 'NotificationModal';
		_id: Scalars['ID'];
		bodyText?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		headlineText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<NotificationModalLinkingCollections>;
		modalName?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** a modal that pops up on any sort of notification [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/notificationModal) */
export type NotificationModalBodyTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** a modal that pops up on any sort of notification [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/notificationModal) */
export type NotificationModalHeadlineTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** a modal that pops up on any sort of notification [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/notificationModal) */
export type NotificationModalLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** a modal that pops up on any sort of notification [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/notificationModal) */
export type NotificationModalModalNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type NotificationModalCollection = {
	__typename?: 'NotificationModalCollection';
	items: Array<Maybe<NotificationModal>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type NotificationModalFilter = {
	AND?: InputMaybe<Array<InputMaybe<NotificationModalFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<NotificationModalFilter>>>;
	bodyText?: InputMaybe<Scalars['String']>;
	bodyText_contains?: InputMaybe<Scalars['String']>;
	bodyText_exists?: InputMaybe<Scalars['Boolean']>;
	bodyText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bodyText_not?: InputMaybe<Scalars['String']>;
	bodyText_not_contains?: InputMaybe<Scalars['String']>;
	bodyText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headlineText?: InputMaybe<Scalars['String']>;
	headlineText_contains?: InputMaybe<Scalars['String']>;
	headlineText_exists?: InputMaybe<Scalars['Boolean']>;
	headlineText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText_not?: InputMaybe<Scalars['String']>;
	headlineText_not_contains?: InputMaybe<Scalars['String']>;
	headlineText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	modalName?: InputMaybe<Scalars['String']>;
	modalName_contains?: InputMaybe<Scalars['String']>;
	modalName_exists?: InputMaybe<Scalars['Boolean']>;
	modalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	modalName_not?: InputMaybe<Scalars['String']>;
	modalName_not_contains?: InputMaybe<Scalars['String']>;
	modalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type NotificationModalLinkingCollections = {
	__typename?: 'NotificationModalLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type NotificationModalLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NotificationModalLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NotificationModalLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type NotificationModalLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type NotificationModalOrder =
	| 'headlineText_ASC'
	| 'headlineText_DESC'
	| 'modalName_ASC'
	| 'modalName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHero = Entry &
	_Node & {
		__typename?: 'OdpHero';
		_id: Scalars['ID'];
		appointmentDataSource?: Maybe<Scalars['String']>;
		buttonsCollection?: Maybe<OdpHeroButtonsCollection>;
		callCenterLabel?: Maybe<Scalars['String']>;
		contentSmallScreen?: Maybe<SmallScreenContent>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<ContentTypeRichText>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		googleMapsBusinessQuery?: Maybe<Scalars['String']>;
		image?: Maybe<FocalPointImage>;
		linkedFrom?: Maybe<OdpHeroLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		officeHoursLabel?: Maybe<Scalars['String']>;
		promoTitle?: Maybe<Scalars['String']>;
		showAddress?: Maybe<Scalars['Boolean']>;
		showEmail?: Maybe<Scalars['Boolean']>;
		showFax?: Maybe<Scalars['Boolean']>;
		showHours?: Maybe<Scalars['Boolean']>;
		showLocationStatusBadge?: Maybe<Scalars['Boolean']>;
		showPhoneNumber?: Maybe<Scalars['Boolean']>;
		showReviews?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		templateWidth?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroAppointmentDataSourceArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpHeroButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroCallCenterLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroContentSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<SmallScreenContentFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroFacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroGoogleMapsBusinessQueryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroOfficeHoursLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroPromoTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroShowAddressArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroShowEmailArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroShowFaxArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroShowHoursArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroShowLocationStatusBadgeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroShowPhoneNumberArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroShowReviewsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroTemplateWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/ODPHero) */
export type OdpHeroTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type OdpHeroButtonsCollection = {
	__typename?: 'OdpHeroButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type OdpHeroButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type OdpHeroCollection = {
	__typename?: 'OdpHeroCollection';
	items: Array<Maybe<OdpHero>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type OdpHeroFilter = {
	AND?: InputMaybe<Array<InputMaybe<OdpHeroFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<OdpHeroFilter>>>;
	appointmentDataSource?: InputMaybe<Scalars['String']>;
	appointmentDataSource_contains?: InputMaybe<Scalars['String']>;
	appointmentDataSource_exists?: InputMaybe<Scalars['Boolean']>;
	appointmentDataSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appointmentDataSource_not?: InputMaybe<Scalars['String']>;
	appointmentDataSource_not_contains?: InputMaybe<Scalars['String']>;
	appointmentDataSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	callCenterLabel?: InputMaybe<Scalars['String']>;
	callCenterLabel_contains?: InputMaybe<Scalars['String']>;
	callCenterLabel_exists?: InputMaybe<Scalars['Boolean']>;
	callCenterLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	callCenterLabel_not?: InputMaybe<Scalars['String']>;
	callCenterLabel_not_contains?: InputMaybe<Scalars['String']>;
	callCenterLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentSmallScreen?: InputMaybe<CfSmallScreenContentNestedFilter>;
	contentSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	googleMapsBusinessQuery?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_exists?: InputMaybe<Scalars['Boolean']>;
	googleMapsBusinessQuery_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	googleMapsBusinessQuery_not?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image?: InputMaybe<CfFocalPointImageNestedFilter>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	officeHoursLabel?: InputMaybe<Scalars['String']>;
	officeHoursLabel_contains?: InputMaybe<Scalars['String']>;
	officeHoursLabel_exists?: InputMaybe<Scalars['Boolean']>;
	officeHoursLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	officeHoursLabel_not?: InputMaybe<Scalars['String']>;
	officeHoursLabel_not_contains?: InputMaybe<Scalars['String']>;
	officeHoursLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	promoTitle?: InputMaybe<Scalars['String']>;
	promoTitle_contains?: InputMaybe<Scalars['String']>;
	promoTitle_exists?: InputMaybe<Scalars['Boolean']>;
	promoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	promoTitle_not?: InputMaybe<Scalars['String']>;
	promoTitle_not_contains?: InputMaybe<Scalars['String']>;
	promoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	showAddress?: InputMaybe<Scalars['Boolean']>;
	showAddress_exists?: InputMaybe<Scalars['Boolean']>;
	showAddress_not?: InputMaybe<Scalars['Boolean']>;
	showEmail?: InputMaybe<Scalars['Boolean']>;
	showEmail_exists?: InputMaybe<Scalars['Boolean']>;
	showEmail_not?: InputMaybe<Scalars['Boolean']>;
	showFax?: InputMaybe<Scalars['Boolean']>;
	showFax_exists?: InputMaybe<Scalars['Boolean']>;
	showFax_not?: InputMaybe<Scalars['Boolean']>;
	showHours?: InputMaybe<Scalars['Boolean']>;
	showHours_exists?: InputMaybe<Scalars['Boolean']>;
	showHours_not?: InputMaybe<Scalars['Boolean']>;
	showLocationStatusBadge?: InputMaybe<Scalars['Boolean']>;
	showLocationStatusBadge_exists?: InputMaybe<Scalars['Boolean']>;
	showLocationStatusBadge_not?: InputMaybe<Scalars['Boolean']>;
	showPhoneNumber?: InputMaybe<Scalars['Boolean']>;
	showPhoneNumber_exists?: InputMaybe<Scalars['Boolean']>;
	showPhoneNumber_not?: InputMaybe<Scalars['Boolean']>;
	showReviews?: InputMaybe<Scalars['Boolean']>;
	showReviews_exists?: InputMaybe<Scalars['Boolean']>;
	showReviews_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OdpHeroLinkingCollections = {
	__typename?: 'OdpHeroLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type OdpHeroLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpHeroLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpHeroLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpHeroLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpHeroLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpHeroLinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpHeroLinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpHeroLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpHeroLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpHeroLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type OdpHeroLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type OdpHeroLinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type OdpHeroLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type OdpHeroOrder =
	| 'appointmentDataSource_ASC'
	| 'appointmentDataSource_DESC'
	| 'callCenterLabel_ASC'
	| 'callCenterLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'officeHoursLabel_ASC'
	| 'officeHoursLabel_DESC'
	| 'promoTitle_ASC'
	| 'promoTitle_DESC'
	| 'showAddress_ASC'
	| 'showAddress_DESC'
	| 'showEmail_ASC'
	| 'showEmail_DESC'
	| 'showFax_ASC'
	| 'showFax_DESC'
	| 'showHours_ASC'
	| 'showHours_DESC'
	| 'showLocationStatusBadge_ASC'
	| 'showLocationStatusBadge_DESC'
	| 'showPhoneNumber_ASC'
	| 'showPhoneNumber_DESC'
	| 'showReviews_ASC'
	| 'showReviews_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** This component is a group of links and its sub-links as a set of navigation(similar to current generic footer) to serve as secondary footer along with the office location details as well. It can be an additional component to have more navigation which can be localized(making the link as a specific one for an office). [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/odpLocalizedSecondaryFooter) */
export type OdpLocalizedSecondaryFooter = Entry &
	_Node & {
		__typename?: 'OdpLocalizedSecondaryFooter';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		googleMapsBusinessQuery?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<OdpLocalizedSecondaryFooterLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		navigationGroupsCollection?: Maybe<OdpLocalizedSecondaryFooterNavigationGroupsCollection>;
		sys: Sys;
	};

/** This component is a group of links and its sub-links as a set of navigation(similar to current generic footer) to serve as secondary footer along with the office location details as well. It can be an additional component to have more navigation which can be localized(making the link as a specific one for an office). [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/odpLocalizedSecondaryFooter) */
export type OdpLocalizedSecondaryFooterGoogleMapsBusinessQueryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This component is a group of links and its sub-links as a set of navigation(similar to current generic footer) to serve as secondary footer along with the office location details as well. It can be an additional component to have more navigation which can be localized(making the link as a specific one for an office). [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/odpLocalizedSecondaryFooter) */
export type OdpLocalizedSecondaryFooterLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This component is a group of links and its sub-links as a set of navigation(similar to current generic footer) to serve as secondary footer along with the office location details as well. It can be an additional component to have more navigation which can be localized(making the link as a specific one for an office). [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/odpLocalizedSecondaryFooter) */
export type OdpLocalizedSecondaryFooterNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This component is a group of links and its sub-links as a set of navigation(similar to current generic footer) to serve as secondary footer along with the office location details as well. It can be an additional component to have more navigation which can be localized(making the link as a specific one for an office). [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/odpLocalizedSecondaryFooter) */
export type OdpLocalizedSecondaryFooterNavigationGroupsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpLocalizedSecondaryFooterNavigationGroupsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NavigationGroupFilter>;
};

export type OdpLocalizedSecondaryFooterCollection = {
	__typename?: 'OdpLocalizedSecondaryFooterCollection';
	items: Array<Maybe<OdpLocalizedSecondaryFooter>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type OdpLocalizedSecondaryFooterFilter = {
	AND?: InputMaybe<Array<InputMaybe<OdpLocalizedSecondaryFooterFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<OdpLocalizedSecondaryFooterFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	googleMapsBusinessQuery?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_exists?: InputMaybe<Scalars['Boolean']>;
	googleMapsBusinessQuery_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	googleMapsBusinessQuery_not?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationGroups?: InputMaybe<CfNavigationGroupNestedFilter>;
	navigationGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type OdpLocalizedSecondaryFooterLinkingCollections = {
	__typename?: 'OdpLocalizedSecondaryFooterLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type OdpLocalizedSecondaryFooterLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpLocalizedSecondaryFooterLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<
		Array<InputMaybe<OdpLocalizedSecondaryFooterLinkingCollectionsFacilityDetailsPageCollectionOrder>>
	>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpLocalizedSecondaryFooterLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpLocalizedSecondaryFooterLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpLocalizedSecondaryFooterLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<
		Array<InputMaybe<OdpLocalizedSecondaryFooterLinkingCollectionsServiceDetailsPageCollectionOrder>>
	>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OdpLocalizedSecondaryFooterLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type OdpLocalizedSecondaryFooterLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type OdpLocalizedSecondaryFooterLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type OdpLocalizedSecondaryFooterNavigationGroupsCollection = {
	__typename?: 'OdpLocalizedSecondaryFooterNavigationGroupsCollection';
	items: Array<Maybe<NavigationGroup>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type OdpLocalizedSecondaryFooterNavigationGroupsCollectionOrder =
	| 'displayAsExpandedForMobileMenu_ASC'
	| 'displayAsExpandedForMobileMenu_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type OdpLocalizedSecondaryFooterOrder =
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** UI Component contains all relevant scheduling flow [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/onPageScheduling) */
export type OnPageScheduling = Entry &
	_Node & {
		__typename?: 'OnPageScheduling';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<OnPageSchedulingLinkingCollections>;
		locationCardMedia?: Maybe<Asset>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** UI Component contains all relevant scheduling flow [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/onPageScheduling) */
export type OnPageSchedulingLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** UI Component contains all relevant scheduling flow [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/onPageScheduling) */
export type OnPageSchedulingLocationCardMediaArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** UI Component contains all relevant scheduling flow [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/onPageScheduling) */
export type OnPageSchedulingNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type OnPageSchedulingCollection = {
	__typename?: 'OnPageSchedulingCollection';
	items: Array<Maybe<OnPageScheduling>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type OnPageSchedulingFilter = {
	AND?: InputMaybe<Array<InputMaybe<OnPageSchedulingFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<OnPageSchedulingFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	locationCardMedia_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type OnPageSchedulingLinkingCollections = {
	__typename?: 'OnPageSchedulingLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type OnPageSchedulingLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OnPageSchedulingLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OnPageSchedulingLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type OnPageSchedulingLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type OnPageSchedulingOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type OtherPersonAsGuarantorInput = {
	guarantor: GuarantorInput;
	patientIs: NewPatientRelationToGuarantor;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type Page = Entry &
	_Node & {
		__typename?: 'Page';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		appName?: Maybe<Scalars['String']>;
		breadcrumbTitle?: Maybe<Scalars['String']>;
		breadcrumbsCollection?: Maybe<PageBreadcrumbsCollection>;
		buttonText?: Maybe<Scalars['String']>;
		buttonUrl?: Maybe<Scalars['String']>;
		canonical?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		contentsCollection?: Maybe<PageContentsCollection>;
		description?: Maybe<Scalars['String']>;
		disableChatBot?: Maybe<Scalars['Boolean']>;
		displayOnMobile?: Maybe<Scalars['Boolean']>;
		image?: Maybe<Asset>;
		indexed?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<PageLinkingCollections>;
		mobileBreadcrumbTitle?: Maybe<Scalars['String']>;
		name?: Maybe<Scalars['String']>;
		overrideSchedulingButtonUrl?: Maybe<Scalars['String']>;
		pageTheme?: Maybe<Scalars['String']>;
		pageTitle?: Maybe<Scalars['String']>;
		path?: Maybe<Scalars['String']>;
		personalized?: Maybe<Scalars['Boolean']>;
		personalizedPathPrefix?: Maybe<Scalars['String']>;
		personalizedPathSuffix?: Maybe<Scalars['String']>;
		redirectFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageAppNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageBreadcrumbTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageBreadcrumbsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageBreadcrumbsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PageFilter>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageCanonicalArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PageContentsFilter>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageDisableChatBotArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageDisplayOnMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageIndexedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageMobileBreadcrumbTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageOverrideSchedulingButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PagePageThemeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PagePageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PagePathArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PagePersonalizedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PagePersonalizedPathPrefixArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PagePersonalizedPathSuffixArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageRedirectFromArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Generic Landing Page, for all purposes [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/page) */
export type PageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type PageBreadcrumbsCollection = {
	__typename?: 'PageBreadcrumbsCollection';
	items: Array<Maybe<Page>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PageBreadcrumbsCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PageCollection = {
	__typename?: 'PageCollection';
	items: Array<Maybe<Page>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PageContentsCollection = {
	__typename?: 'PageContentsCollection';
	items: Array<Maybe<PageContentsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PageContentsFilter = {
	AND?: InputMaybe<Array<InputMaybe<PageContentsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<PageContentsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type PageContentsItem =
	| Accordions
	| AgendaStepper
	| BirdEyeReviews
	| CallToAction
	| Carousel
	| CategoryPicker
	| CityLinks
	| Columns
	| Compare
	| ContentTypeRichText
	| DisclaimerV2
	| GoogleMapTemplate
	| HalfScreen
	| HeaderNavMenu
	| HeroV2
	| Iframe
	| Insurance
	| LinkForwarder
	| Marquee
	| MediaSlider
	| MeetOurDoctors
	| MeetOurStaff
	| Modal
	| MultiCard
	| MultiCardV2
	| NotificationModal
	| OdpHero
	| OdpLocalizedSecondaryFooter
	| OnPageScheduling
	| PatientReviewsV2
	| ProductFeature
	| PromotionsAndOffering
	| QuestionStepper
	| Questionnaire
	| QuickLinks
	| Quiz
	| RichTextBlockSeo
	| Schema
	| SchemaStep
	| SecondaryHero
	| Section
	| SectionContent
	| SegmentsContainer
	| Service
	| ServiceGroup
	| ServiceLineHero
	| SharedForm
	| SingleMessage
	| SmileAssessmentForm
	| Spacer
	| StateLinks
	| Stepper
	| Tabs
	| TermsAndConditions
	| TextBlockSeo
	| VideoCarousel;

export type PageFilter = {
	AND?: InputMaybe<Array<InputMaybe<PageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<PageFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	appName?: InputMaybe<Scalars['String']>;
	appName_contains?: InputMaybe<Scalars['String']>;
	appName_exists?: InputMaybe<Scalars['Boolean']>;
	appName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appName_not?: InputMaybe<Scalars['String']>;
	appName_not_contains?: InputMaybe<Scalars['String']>;
	appName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbTitle?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_contains?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_exists?: InputMaybe<Scalars['Boolean']>;
	breadcrumbTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbTitle_not?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_not_contains?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbs?: InputMaybe<CfPageNestedFilter>;
	breadcrumbsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical?: InputMaybe<Scalars['String']>;
	canonical_contains?: InputMaybe<Scalars['String']>;
	canonical_exists?: InputMaybe<Scalars['Boolean']>;
	canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical_not?: InputMaybe<Scalars['String']>;
	canonical_not_contains?: InputMaybe<Scalars['String']>;
	canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disableChatBot?: InputMaybe<Scalars['Boolean']>;
	disableChatBot_exists?: InputMaybe<Scalars['Boolean']>;
	disableChatBot_not?: InputMaybe<Scalars['Boolean']>;
	displayOnMobile?: InputMaybe<Scalars['Boolean']>;
	displayOnMobile_exists?: InputMaybe<Scalars['Boolean']>;
	displayOnMobile_not?: InputMaybe<Scalars['Boolean']>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	indexed?: InputMaybe<Scalars['Boolean']>;
	indexed_exists?: InputMaybe<Scalars['Boolean']>;
	indexed_not?: InputMaybe<Scalars['Boolean']>;
	mobileBreadcrumbTitle?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_contains?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_exists?: InputMaybe<Scalars['Boolean']>;
	mobileBreadcrumbTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileBreadcrumbTitle_not?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_not_contains?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	overrideSchedulingButtonUrl?: InputMaybe<Scalars['String']>;
	overrideSchedulingButtonUrl_contains?: InputMaybe<Scalars['String']>;
	overrideSchedulingButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	overrideSchedulingButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	overrideSchedulingButtonUrl_not?: InputMaybe<Scalars['String']>;
	overrideSchedulingButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
	overrideSchedulingButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTheme?: InputMaybe<Scalars['String']>;
	pageTheme_contains?: InputMaybe<Scalars['String']>;
	pageTheme_exists?: InputMaybe<Scalars['Boolean']>;
	pageTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTheme_not?: InputMaybe<Scalars['String']>;
	pageTheme_not_contains?: InputMaybe<Scalars['String']>;
	pageTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTitle?: InputMaybe<Scalars['String']>;
	pageTitle_contains?: InputMaybe<Scalars['String']>;
	pageTitle_exists?: InputMaybe<Scalars['Boolean']>;
	pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTitle_not?: InputMaybe<Scalars['String']>;
	pageTitle_not_contains?: InputMaybe<Scalars['String']>;
	pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path?: InputMaybe<Scalars['String']>;
	path_contains?: InputMaybe<Scalars['String']>;
	path_exists?: InputMaybe<Scalars['Boolean']>;
	path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path_not?: InputMaybe<Scalars['String']>;
	path_not_contains?: InputMaybe<Scalars['String']>;
	path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalized?: InputMaybe<Scalars['Boolean']>;
	personalizedPathPrefix?: InputMaybe<Scalars['String']>;
	personalizedPathPrefix_contains?: InputMaybe<Scalars['String']>;
	personalizedPathPrefix_exists?: InputMaybe<Scalars['Boolean']>;
	personalizedPathPrefix_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalizedPathPrefix_not?: InputMaybe<Scalars['String']>;
	personalizedPathPrefix_not_contains?: InputMaybe<Scalars['String']>;
	personalizedPathPrefix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalizedPathSuffix?: InputMaybe<Scalars['String']>;
	personalizedPathSuffix_contains?: InputMaybe<Scalars['String']>;
	personalizedPathSuffix_exists?: InputMaybe<Scalars['Boolean']>;
	personalizedPathSuffix_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalizedPathSuffix_not?: InputMaybe<Scalars['String']>;
	personalizedPathSuffix_not_contains?: InputMaybe<Scalars['String']>;
	personalizedPathSuffix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalized_exists?: InputMaybe<Scalars['Boolean']>;
	personalized_not?: InputMaybe<Scalars['Boolean']>;
	redirectFrom_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	redirectFrom_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	redirectFrom_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	redirectFrom_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PageLinkingCollections = {
	__typename?: 'PageLinkingCollections';
	appCollection?: Maybe<AppCollection>;
	contentTileCollection?: Maybe<ContentTileCollection>;
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
	footerNavigationGroupV2Collection?: Maybe<FooterNavigationGroupV2Collection>;
	footerV2Collection?: Maybe<FooterV2Collection>;
	headerCollection?: Maybe<HeaderCollection>;
	navigationLinkCollection?: Maybe<NavigationLinkCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type PageLinkingCollectionsAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsAppCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsContentTileCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsContentTileCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsFooterNavigationGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsFooterNavigationGroupCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsFooterNavigationGroupV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsFooterNavigationGroupV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsFooterV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsFooterV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsHeaderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsHeaderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsNavigationLinkCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsNavigationLinkCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsAppCollectionOrder =
	| 'brandImageLink_ASC'
	| 'brandImageLink_DESC'
	| 'branding_ASC'
	| 'branding_DESC'
	| 'isDeprecated_ASC'
	| 'isDeprecated_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type PageLinkingCollectionsContentTileCollectionOrder =
	| 'description_ASC'
	| 'description_DESC'
	| 'externalUrl_ASC'
	| 'externalUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PageLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PageLinkingCollectionsFooterNavigationGroupCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'navigationGroupTitle_ASC'
	| 'navigationGroupTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type PageLinkingCollectionsFooterNavigationGroupV2CollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type PageLinkingCollectionsFooterV2CollectionOrder =
	| 'contactNumber_ASC'
	| 'contactNumber_DESC'
	| 'copyright_ASC'
	| 'copyright_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type PageLinkingCollectionsHeaderCollectionOrder =
	| 'componentName_ASC'
	| 'componentName_DESC'
	| 'darkHeader_ASC'
	| 'darkHeader_DESC'
	| 'hideFullSiteSearch_ASC'
	| 'hideFullSiteSearch_DESC'
	| 'hideHamburgerMenuToggleButton_ASC'
	| 'hideHamburgerMenuToggleButton_DESC'
	| 'locationBarFindOfficeUrl_ASC'
	| 'locationBarFindOfficeUrl_DESC'
	| 'locationBarWithOfficeTitle_ASC'
	| 'locationBarWithOfficeTitle_DESC'
	| 'locationBarWithOutOfficeTitle_ASC'
	| 'locationBarWithOutOfficeTitle_DESC'
	| 'myAccountLabel_ASC'
	| 'myAccountLabel_DESC'
	| 'profileLinkUrl_ASC'
	| 'profileLinkUrl_DESC'
	| 'schedulingButtonTextMobile_ASC'
	| 'schedulingButtonTextMobile_DESC'
	| 'schedulingButtonText_ASC'
	| 'schedulingButtonText_DESC'
	| 'schedulingButtonUrlMobile_ASC'
	| 'schedulingButtonUrlMobile_DESC'
	| 'schedulingButtonUrl_ASC'
	| 'schedulingButtonUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useHeaderLocationCards_ASC'
	| 'useHeaderLocationCards_DESC'
	| 'useNewLayout_ASC'
	| 'useNewLayout_DESC'
	| 'usePopupScheduling_ASC'
	| 'usePopupScheduling_DESC';

export type PageLinkingCollectionsNavigationLinkCollectionOrder =
	| 'externalUrl_ASC'
	| 'externalUrl_DESC'
	| 'isInternal_ASC'
	| 'isInternal_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'linkText_ASC'
	| 'linkText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'openInNewTab_ASC'
	| 'openInNewTab_DESC'
	| 'openInNewWindow_ASC'
	| 'openInNewWindow_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type PageLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PageOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ParentQuestionTrigger = {
	__typename?: 'ParentQuestionTrigger';
	answerId: Scalars['String'];
	questionId: Scalars['String'];
};

export type PartOfDay = 'AM' | 'PM';

/** Patient Details */
export type Patient = {
	__typename?: 'Patient';
	accountNumber?: Maybe<Scalars['String']>;
	emergencyContact?: Maybe<EmergencyContact>;
	insurance?: Maybe<Insurance>;
	patientCode?: Maybe<Scalars['String']>;
	patientId?: Maybe<Scalars['String']>;
	patientInformation?: Maybe<PatientDetails>;
};

export type PatientAddress = {
	__typename?: 'PatientAddress';
	addressLine?: Maybe<Scalars['String']>;
	city?: Maybe<City>;
};

export type PatientAuthorizations = {
	__typename?: 'PatientAuthorizations';
	financialPoliciesAuthorization?: Maybe<Scalars['Boolean']>;
	medicalHistoryObtainingAuthorization?: Maybe<Scalars['Boolean']>;
	privacyPracticesAuthorization?: Maybe<Scalars['Boolean']>;
	releaseOfInfoToInsurersAuthorization?: Maybe<Scalars['Boolean']>;
	signature?: Maybe<Image>;
	thirdPartyInformationSharingAuthorization?: Maybe<ThirdPartyInformationSharingAuthorization>;
};

/** Patient Details */
export type PatientDetails = {
	__typename?: 'PatientDetails';
	dateOfBirth?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	facilityCode?: Maybe<Scalars['String']>;
	facilityName?: Maybe<Scalars['String']>;
	firstName?: Maybe<Scalars['String']>;
	language?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	preferredCommunication?: Maybe<Scalars['String']>;
	prefix?: Maybe<Scalars['String']>;
	sex?: Maybe<Scalars['String']>;
	suffix?: Maybe<Scalars['String']>;
};

export type PatientEligibility = {
	__typename?: 'PatientEligibility';
	eligible: Scalars['Boolean'];
	ineligibilityReason?: Maybe<IneligibilityReasonEnum>;
	type: PatientEligibilityType;
};

export type PatientEligibilityInformationInput = {
	dateOfBirth: Scalars['Date'];
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	phoneNumber: Scalars['String'];
};

export type PatientEligibilityType = 'SCHEDULE_AS_NEW_PATIENT';

export type PatientFreeTextAnswer = {
	__typename?: 'PatientFreeTextAnswer';
	question: QuestionnaireQuestion;
	text: Scalars['String'];
};

export type PatientIdentifiers = {
	__typename?: 'PatientIdentifiers';
	accountId: Scalars['String'];
	accountNumber: Scalars['String'];
	patientCode: Scalars['String'];
	patientId: Scalars['String'];
	platform: Platform;
};

export type PatientMedicalHistory = {
	__typename?: 'PatientMedicalHistory';
	answers?: Maybe<Array<PatientQuestionnaireAnswer>>;
};

export type PatientPhone = {
	__typename?: 'PatientPhone';
	phoneNumber: Scalars['String'];
	type: PatientPhoneType;
	useToConfirm: Scalars['Boolean'];
};

export type PatientPhoneInput = {
	phoneNumber: Scalars['AspenPhoneNumberType'];
	type: PatientPhoneType;
	useToConfirm: Scalars['Boolean'];
};

export type PatientPhoneType = 'CELL' | 'HOME' | 'OTHER' | 'WORK';

export type PatientPredefinedAnswer = {
	__typename?: 'PatientPredefinedAnswer';
	answerIds: Array<Scalars['String']>;
	question: QuestionnaireQuestion;
};

export type PatientQuestionnaireAnswer = PatientFreeTextAnswer | PatientPredefinedAnswer;

export type PatientRelationToGuarantor =
	| 'CHILD'
	| 'DISABLED_DEPENDENT'
	| 'HUSBAND'
	| 'NOT_RELATED'
	| 'OTHER_DEPENDENT'
	| 'WIFE';

/** A single patient review as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewV2) */
export type PatientReviewV2 = Entry &
	_Node & {
		__typename?: 'PatientReviewV2';
		_id: Scalars['ID'];
		avatar?: Maybe<Asset>;
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<PatientReviewV2LinkingCollections>;
		score?: Maybe<Scalars['Int']>;
		subText1?: Maybe<Scalars['String']>;
		subText2?: Maybe<Scalars['String']>;
		sys: Sys;
		text?: Maybe<Scalars['String']>;
	};

/** A single patient review as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewV2) */
export type PatientReviewV2AvatarArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** A single patient review as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewV2) */
export type PatientReviewV2LinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A single patient review as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewV2) */
export type PatientReviewV2ScoreArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A single patient review as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewV2) */
export type PatientReviewV2SubText1Args = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A single patient review as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewV2) */
export type PatientReviewV2SubText2Args = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A single patient review as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewV2) */
export type PatientReviewV2TextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type PatientReviewV2Collection = {
	__typename?: 'PatientReviewV2Collection';
	items: Array<Maybe<PatientReviewV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PatientReviewV2Filter = {
	AND?: InputMaybe<Array<InputMaybe<PatientReviewV2Filter>>>;
	OR?: InputMaybe<Array<InputMaybe<PatientReviewV2Filter>>>;
	avatar_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	score?: InputMaybe<Scalars['Int']>;
	score_exists?: InputMaybe<Scalars['Boolean']>;
	score_gt?: InputMaybe<Scalars['Int']>;
	score_gte?: InputMaybe<Scalars['Int']>;
	score_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	score_lt?: InputMaybe<Scalars['Int']>;
	score_lte?: InputMaybe<Scalars['Int']>;
	score_not?: InputMaybe<Scalars['Int']>;
	score_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	subText1?: InputMaybe<Scalars['String']>;
	subText1_contains?: InputMaybe<Scalars['String']>;
	subText1_exists?: InputMaybe<Scalars['Boolean']>;
	subText1_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText1_not?: InputMaybe<Scalars['String']>;
	subText1_not_contains?: InputMaybe<Scalars['String']>;
	subText1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText2?: InputMaybe<Scalars['String']>;
	subText2_contains?: InputMaybe<Scalars['String']>;
	subText2_exists?: InputMaybe<Scalars['Boolean']>;
	subText2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText2_not?: InputMaybe<Scalars['String']>;
	subText2_not_contains?: InputMaybe<Scalars['String']>;
	subText2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	text?: InputMaybe<Scalars['String']>;
	text_contains?: InputMaybe<Scalars['String']>;
	text_exists?: InputMaybe<Scalars['Boolean']>;
	text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	text_not?: InputMaybe<Scalars['String']>;
	text_not_contains?: InputMaybe<Scalars['String']>;
	text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PatientReviewV2LinkingCollections = {
	__typename?: 'PatientReviewV2LinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	patientReviewsV2Collection?: Maybe<PatientReviewsV2Collection>;
};

export type PatientReviewV2LinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PatientReviewV2LinkingCollectionsPatientReviewsV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewV2LinkingCollectionsPatientReviewsV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PatientReviewV2LinkingCollectionsPatientReviewsV2CollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowLargeScreens_ASC'
	| 'eyebrowLargeScreens_DESC'
	| 'eyebrowSmallScreens_ASC'
	| 'eyebrowSmallScreens_DESC'
	| 'maxReviewsLargeScreens_ASC'
	| 'maxReviewsLargeScreens_DESC'
	| 'maxReviewsSmallScreens_ASC'
	| 'maxReviewsSmallScreens_DESC'
	| 'mode_ASC'
	| 'mode_DESC'
	| 'scoreIcon_ASC'
	| 'scoreIcon_DESC'
	| 'subtitleLargeScreens_ASC'
	| 'subtitleLargeScreens_DESC'
	| 'subtitleSmallScreens_ASC'
	| 'subtitleSmallScreens_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'titleLargeScreens_ASC'
	| 'titleLargeScreens_DESC'
	| 'titleSmallScreens_ASC'
	| 'titleSmallScreens_DESC'
	| 'trimThreshold_ASC'
	| 'trimThreshold_DESC';

export type PatientReviewV2Order =
	| 'score_ASC'
	| 'score_DESC'
	| 'subText1_ASC'
	| 'subText1_DESC'
	| 'subText2_ASC'
	| 'subText2_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2 = Entry &
	_Node & {
		__typename?: 'PatientReviewsV2';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		buttonsLargeScreensCollection?: Maybe<PatientReviewsV2ButtonsLargeScreensCollection>;
		buttonsSmallScreensCollection?: Maybe<PatientReviewsV2ButtonsSmallScreensCollection>;
		contentAlign?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		entryName?: Maybe<Scalars['String']>;
		eyebrowLargeScreens?: Maybe<Scalars['String']>;
		eyebrowSmallScreens?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<PatientReviewsV2LinkingCollections>;
		maxReviewsLargeScreens?: Maybe<Scalars['Int']>;
		maxReviewsSmallScreens?: Maybe<Scalars['Int']>;
		mode?: Maybe<Scalars['String']>;
		reviewsCollection?: Maybe<PatientReviewsV2ReviewsCollection>;
		scoreIcon?: Maybe<Scalars['String']>;
		subtitleLargeScreens?: Maybe<Scalars['String']>;
		subtitleSmallScreens?: Maybe<Scalars['String']>;
		sys: Sys;
		templateWidth?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
		titleLargeScreens?: Maybe<Scalars['String']>;
		titleSmallScreens?: Maybe<Scalars['String']>;
		trimThreshold?: Maybe<Scalars['Int']>;
	};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2BackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2ButtonsLargeScreensCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewsV2ButtonsLargeScreensCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2ButtonsSmallScreensCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewsV2ButtonsSmallScreensCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2ContentAlignArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2DisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2EntryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2EyebrowLargeScreensArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2EyebrowSmallScreensArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2LinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2MaxReviewsLargeScreensArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2MaxReviewsSmallScreensArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2ModeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2ReviewsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewsV2ReviewsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PatientReviewV2Filter>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2ScoreIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2SubtitleLargeScreensArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2SubtitleSmallScreensArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2TemplateWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2TitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2TitleLargeScreensArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2TitleSmallScreensArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Patient reviews section as defined by WAD-2847 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/patientReviewsV2) */
export type PatientReviewsV2TrimThresholdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type PatientReviewsV2ButtonsLargeScreensCollection = {
	__typename?: 'PatientReviewsV2ButtonsLargeScreensCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PatientReviewsV2ButtonsLargeScreensCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type PatientReviewsV2ButtonsSmallScreensCollection = {
	__typename?: 'PatientReviewsV2ButtonsSmallScreensCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PatientReviewsV2ButtonsSmallScreensCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type PatientReviewsV2Collection = {
	__typename?: 'PatientReviewsV2Collection';
	items: Array<Maybe<PatientReviewsV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PatientReviewsV2Filter = {
	AND?: InputMaybe<Array<InputMaybe<PatientReviewsV2Filter>>>;
	OR?: InputMaybe<Array<InputMaybe<PatientReviewsV2Filter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonsLargeScreens?: InputMaybe<CfButtonNestedFilter>;
	buttonsLargeScreensCollection_exists?: InputMaybe<Scalars['Boolean']>;
	buttonsSmallScreens?: InputMaybe<CfButtonNestedFilter>;
	buttonsSmallScreensCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign?: InputMaybe<Scalars['String']>;
	contentAlign_contains?: InputMaybe<Scalars['String']>;
	contentAlign_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentAlign_not?: InputMaybe<Scalars['String']>;
	contentAlign_not_contains?: InputMaybe<Scalars['String']>;
	contentAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	entryName?: InputMaybe<Scalars['String']>;
	entryName_contains?: InputMaybe<Scalars['String']>;
	entryName_exists?: InputMaybe<Scalars['Boolean']>;
	entryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	entryName_not?: InputMaybe<Scalars['String']>;
	entryName_not_contains?: InputMaybe<Scalars['String']>;
	entryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowLargeScreens?: InputMaybe<Scalars['String']>;
	eyebrowLargeScreens_contains?: InputMaybe<Scalars['String']>;
	eyebrowLargeScreens_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowLargeScreens_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowLargeScreens_not?: InputMaybe<Scalars['String']>;
	eyebrowLargeScreens_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowLargeScreens_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowSmallScreens?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreens_contains?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreens_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowSmallScreens_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowSmallScreens_not?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreens_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreens_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	maxReviewsLargeScreens?: InputMaybe<Scalars['Int']>;
	maxReviewsLargeScreens_exists?: InputMaybe<Scalars['Boolean']>;
	maxReviewsLargeScreens_gt?: InputMaybe<Scalars['Int']>;
	maxReviewsLargeScreens_gte?: InputMaybe<Scalars['Int']>;
	maxReviewsLargeScreens_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	maxReviewsLargeScreens_lt?: InputMaybe<Scalars['Int']>;
	maxReviewsLargeScreens_lte?: InputMaybe<Scalars['Int']>;
	maxReviewsLargeScreens_not?: InputMaybe<Scalars['Int']>;
	maxReviewsLargeScreens_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	maxReviewsSmallScreens?: InputMaybe<Scalars['Int']>;
	maxReviewsSmallScreens_exists?: InputMaybe<Scalars['Boolean']>;
	maxReviewsSmallScreens_gt?: InputMaybe<Scalars['Int']>;
	maxReviewsSmallScreens_gte?: InputMaybe<Scalars['Int']>;
	maxReviewsSmallScreens_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	maxReviewsSmallScreens_lt?: InputMaybe<Scalars['Int']>;
	maxReviewsSmallScreens_lte?: InputMaybe<Scalars['Int']>;
	maxReviewsSmallScreens_not?: InputMaybe<Scalars['Int']>;
	maxReviewsSmallScreens_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	mode?: InputMaybe<Scalars['String']>;
	mode_contains?: InputMaybe<Scalars['String']>;
	mode_exists?: InputMaybe<Scalars['Boolean']>;
	mode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mode_not?: InputMaybe<Scalars['String']>;
	mode_not_contains?: InputMaybe<Scalars['String']>;
	mode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	reviews?: InputMaybe<CfPatientReviewV2NestedFilter>;
	reviewsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	scoreIcon?: InputMaybe<Scalars['String']>;
	scoreIcon_contains?: InputMaybe<Scalars['String']>;
	scoreIcon_exists?: InputMaybe<Scalars['Boolean']>;
	scoreIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	scoreIcon_not?: InputMaybe<Scalars['String']>;
	scoreIcon_not_contains?: InputMaybe<Scalars['String']>;
	scoreIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subtitleLargeScreens?: InputMaybe<Scalars['String']>;
	subtitleLargeScreens_contains?: InputMaybe<Scalars['String']>;
	subtitleLargeScreens_exists?: InputMaybe<Scalars['Boolean']>;
	subtitleLargeScreens_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subtitleLargeScreens_not?: InputMaybe<Scalars['String']>;
	subtitleLargeScreens_not_contains?: InputMaybe<Scalars['String']>;
	subtitleLargeScreens_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subtitleSmallScreens?: InputMaybe<Scalars['String']>;
	subtitleSmallScreens_contains?: InputMaybe<Scalars['String']>;
	subtitleSmallScreens_exists?: InputMaybe<Scalars['Boolean']>;
	subtitleSmallScreens_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subtitleSmallScreens_not?: InputMaybe<Scalars['String']>;
	subtitleSmallScreens_not_contains?: InputMaybe<Scalars['String']>;
	subtitleSmallScreens_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleLargeScreens?: InputMaybe<Scalars['String']>;
	titleLargeScreens_contains?: InputMaybe<Scalars['String']>;
	titleLargeScreens_exists?: InputMaybe<Scalars['Boolean']>;
	titleLargeScreens_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleLargeScreens_not?: InputMaybe<Scalars['String']>;
	titleLargeScreens_not_contains?: InputMaybe<Scalars['String']>;
	titleLargeScreens_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleSmallScreens?: InputMaybe<Scalars['String']>;
	titleSmallScreens_contains?: InputMaybe<Scalars['String']>;
	titleSmallScreens_exists?: InputMaybe<Scalars['Boolean']>;
	titleSmallScreens_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleSmallScreens_not?: InputMaybe<Scalars['String']>;
	titleSmallScreens_not_contains?: InputMaybe<Scalars['String']>;
	titleSmallScreens_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	trimThreshold?: InputMaybe<Scalars['Int']>;
	trimThreshold_exists?: InputMaybe<Scalars['Boolean']>;
	trimThreshold_gt?: InputMaybe<Scalars['Int']>;
	trimThreshold_gte?: InputMaybe<Scalars['Int']>;
	trimThreshold_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	trimThreshold_lt?: InputMaybe<Scalars['Int']>;
	trimThreshold_lte?: InputMaybe<Scalars['Int']>;
	trimThreshold_not?: InputMaybe<Scalars['Int']>;
	trimThreshold_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type PatientReviewsV2LinkingCollections = {
	__typename?: 'PatientReviewsV2LinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type PatientReviewsV2LinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PatientReviewsV2LinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewsV2LinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PatientReviewsV2LinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewsV2LinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PatientReviewsV2LinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewsV2LinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PatientReviewsV2LinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PatientReviewsV2LinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PatientReviewsV2LinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PatientReviewsV2Order =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowLargeScreens_ASC'
	| 'eyebrowLargeScreens_DESC'
	| 'eyebrowSmallScreens_ASC'
	| 'eyebrowSmallScreens_DESC'
	| 'maxReviewsLargeScreens_ASC'
	| 'maxReviewsLargeScreens_DESC'
	| 'maxReviewsSmallScreens_ASC'
	| 'maxReviewsSmallScreens_DESC'
	| 'mode_ASC'
	| 'mode_DESC'
	| 'scoreIcon_ASC'
	| 'scoreIcon_DESC'
	| 'subtitleLargeScreens_ASC'
	| 'subtitleLargeScreens_DESC'
	| 'subtitleSmallScreens_ASC'
	| 'subtitleSmallScreens_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'titleLargeScreens_ASC'
	| 'titleLargeScreens_DESC'
	| 'titleSmallScreens_ASC'
	| 'titleSmallScreens_DESC'
	| 'trimThreshold_ASC'
	| 'trimThreshold_DESC';

export type PatientReviewsV2ReviewsCollection = {
	__typename?: 'PatientReviewsV2ReviewsCollection';
	items: Array<Maybe<PatientReviewV2>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PatientReviewsV2ReviewsCollectionOrder =
	| 'score_ASC'
	| 'score_DESC'
	| 'subText1_ASC'
	| 'subText1_DESC'
	| 'subText2_ASC'
	| 'subText2_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type Period = {
	__typename?: 'Period';
	from?: Maybe<Scalars['DateTime']>;
	to?: Maybe<Scalars['DateTime']>;
};

export type PeriodInput = {
	from: Scalars['DateTime'];
	to: Scalars['DateTime'];
};

export type PersonalInformation = {
	__typename?: 'PersonalInformation';
	address?: Maybe<PatientAddress>;
	dateOfBirth?: Maybe<Scalars['Date']>;
	email?: Maybe<Scalars['String']>;
	firstName?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
	middleName?: Maybe<Scalars['String']>;
	phones?: Maybe<Array<PatientPhone>>;
	sex?: Maybe<Sex>;
	ssnLast4?: Maybe<Scalars['String']>;
	suffix?: Maybe<Suffix>;
};

export type Platform = 'P2' | 'P3';

export type PredefinedAnswer = {
	__typename?: 'PredefinedAnswer';
	answerId: Scalars['String'];
	description?: Maybe<Scalars['String']>;
};

/** Returned when provided answers dont match the set of valid predefined answers. */
export type PredefinedQuestionnaireAnswerError = Error & {
	__typename?: 'PredefinedQuestionnaireAnswerError';
	answeredQuestion: QuestionnaireQuestion;
	invalidAnswersIds: Array<Scalars['String']>;
	message: Scalars['String'];
};

export type ProcedurePrice = {
	__typename?: 'ProcedurePrice';
	description: Scalars['String'];
	price: Scalars['Decimal'];
	procedureCode: Scalars['String'];
};

export type ProcedurePricesList = {
	__typename?: 'ProcedurePricesList';
	items: Array<ProcedurePrice>;
	total: Scalars['Int'];
};

export type ProceduresPricesFilterInput = {
	procedureCodes: Array<Scalars['String']>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type Product = Entry &
	_Node & {
		__typename?: 'Product';
		_id: Scalars['ID'];
		brand?: Maybe<Brand>;
		brandfolderImageAssetUrl?: Maybe<Scalars['String']>;
		category?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		id?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ProductLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		procedures?: Maybe<Array<Maybe<Scalars['String']>>>;
		subCategory?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductBrandArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BrandFilter>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductBrandfolderImageAssetUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductCategoryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductProceduresArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/**
 * A type to describe an entire product to be showcased in a global or facility based way.
 *
 * The format of the procedure text line is {procedure code},{quantity needed},{price},{discount percentage}
 * Example:
 * When
 * procedure code = A009
 * quantity needed = 2
 * price = 1,200.15
 * discount percentage = 12%
 *
 * The line will be (note the decimal separator for price is underscore _.)
 * A009, 2, 1200_00, 12 [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/product)
 */
export type ProductSubCategoryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ProductCollection = {
	__typename?: 'ProductCollection';
	items: Array<Maybe<Product>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeature = Entry &
	_Node & {
		__typename?: 'ProductFeature';
		_id: Scalars['ID'];
		buttonsCollection?: Maybe<ProductFeatureButtonsCollection>;
		contentfulMetadata: ContentfulMetadata;
		descriptionButton?: Maybe<Button>;
		descriptionButtonsCollection?: Maybe<ProductFeatureDescriptionButtonsCollection>;
		descriptionListCollection?: Maybe<ProductFeatureDescriptionListCollection>;
		descriptionRichText?: Maybe<ContentTypeRichText>;
		disclaimer?: Maybe<DisclaimerV2>;
		heading?: Maybe<ProductFeatureHeading>;
		linkedFrom?: Maybe<ProductFeatureLinkingCollections>;
		media?: Maybe<MediaSlider>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureDescriptionButtonArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ButtonFilter>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureDescriptionButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureDescriptionButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureDescriptionListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureDescriptionListCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProductFeatureDescriptionListItemFilter>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureDescriptionRichTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureDisclaimerArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<DisclaimerV2Filter>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ProductFeatureHeadingFilter>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureMediaArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<MediaSliderFilter>;
};

/** A template that accepts only Product Feature content models [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeature) */
export type ProductFeatureNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ProductFeatureButtonsCollection = {
	__typename?: 'ProductFeatureButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProductFeatureButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type ProductFeatureCollection = {
	__typename?: 'ProductFeatureCollection';
	items: Array<Maybe<ProductFeature>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProductFeatureDescriptionButtonsCollection = {
	__typename?: 'ProductFeatureDescriptionButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProductFeatureDescriptionButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type ProductFeatureDescriptionListCollection = {
	__typename?: 'ProductFeatureDescriptionListCollection';
	items: Array<Maybe<ProductFeatureDescriptionListItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProductFeatureDescriptionListCollectionOrder =
	| 'icon_ASC'
	| 'icon_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** A List Item for the Product Feature Description List Array [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureDescriptionListItem) */
export type ProductFeatureDescriptionListItem = Entry &
	_Node & {
		__typename?: 'ProductFeatureDescriptionListItem';
		_id: Scalars['ID'];
		content?: Maybe<ContentTypeRichText>;
		contentfulMetadata: ContentfulMetadata;
		icon?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ProductFeatureDescriptionListItemLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** A List Item for the Product Feature Description List Array [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureDescriptionListItem) */
export type ProductFeatureDescriptionListItemContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** A List Item for the Product Feature Description List Array [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureDescriptionListItem) */
export type ProductFeatureDescriptionListItemIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A List Item for the Product Feature Description List Array [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureDescriptionListItem) */
export type ProductFeatureDescriptionListItemLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A List Item for the Product Feature Description List Array [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureDescriptionListItem) */
export type ProductFeatureDescriptionListItemNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ProductFeatureDescriptionListItemCollection = {
	__typename?: 'ProductFeatureDescriptionListItemCollection';
	items: Array<Maybe<ProductFeatureDescriptionListItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProductFeatureDescriptionListItemFilter = {
	AND?: InputMaybe<Array<InputMaybe<ProductFeatureDescriptionListItemFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ProductFeatureDescriptionListItemFilter>>>;
	content?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	icon?: InputMaybe<Scalars['String']>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ProductFeatureDescriptionListItemLinkingCollections = {
	__typename?: 'ProductFeatureDescriptionListItemLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	productFeatureCollection?: Maybe<ProductFeatureCollection>;
};

export type ProductFeatureDescriptionListItemLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductFeatureDescriptionListItemLinkingCollectionsProductFeatureCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<
		Array<InputMaybe<ProductFeatureDescriptionListItemLinkingCollectionsProductFeatureCollectionOrder>>
	>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductFeatureDescriptionListItemLinkingCollectionsProductFeatureCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProductFeatureDescriptionListItemOrder =
	| 'icon_ASC'
	| 'icon_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProductFeatureFilter = {
	AND?: InputMaybe<Array<InputMaybe<ProductFeatureFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ProductFeatureFilter>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	descriptionButton?: InputMaybe<CfButtonNestedFilter>;
	descriptionButton_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionButtons?: InputMaybe<CfButtonNestedFilter>;
	descriptionButtonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionList?: InputMaybe<CfProductFeatureDescriptionListItemNestedFilter>;
	descriptionListCollection_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionRichText?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	descriptionRichText_exists?: InputMaybe<Scalars['Boolean']>;
	disclaimer?: InputMaybe<CfDisclaimerV2NestedFilter>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	heading?: InputMaybe<CfProductFeatureHeadingNestedFilter>;
	heading_exists?: InputMaybe<Scalars['Boolean']>;
	media?: InputMaybe<CfMediaSliderNestedFilter>;
	media_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureHeading) */
export type ProductFeatureHeading = Entry &
	_Node & {
		__typename?: 'ProductFeatureHeading';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		eyebrowHtag?: Maybe<Scalars['String']>;
		eyebrowText?: Maybe<Scalars['String']>;
		headingHtag?: Maybe<Scalars['String']>;
		headingText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ProductFeatureHeadingLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureHeading) */
export type ProductFeatureHeadingEyebrowHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureHeading) */
export type ProductFeatureHeadingEyebrowTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureHeading) */
export type ProductFeatureHeadingHeadingHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureHeading) */
export type ProductFeatureHeadingHeadingTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureHeading) */
export type ProductFeatureHeadingLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureHeading) */
export type ProductFeatureHeadingNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ProductFeatureHeadingCollection = {
	__typename?: 'ProductFeatureHeadingCollection';
	items: Array<Maybe<ProductFeatureHeading>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProductFeatureHeadingFilter = {
	AND?: InputMaybe<Array<InputMaybe<ProductFeatureHeadingFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ProductFeatureHeadingFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	eyebrowHtag?: InputMaybe<Scalars['String']>;
	eyebrowHtag_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowHtag_not?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowText?: InputMaybe<Scalars['String']>;
	eyebrowText_contains?: InputMaybe<Scalars['String']>;
	eyebrowText_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowText_not?: InputMaybe<Scalars['String']>;
	eyebrowText_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingHtag?: InputMaybe<Scalars['String']>;
	headingHtag_contains?: InputMaybe<Scalars['String']>;
	headingHtag_exists?: InputMaybe<Scalars['Boolean']>;
	headingHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingHtag_not?: InputMaybe<Scalars['String']>;
	headingHtag_not_contains?: InputMaybe<Scalars['String']>;
	headingHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingText?: InputMaybe<Scalars['String']>;
	headingText_contains?: InputMaybe<Scalars['String']>;
	headingText_exists?: InputMaybe<Scalars['Boolean']>;
	headingText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingText_not?: InputMaybe<Scalars['String']>;
	headingText_not_contains?: InputMaybe<Scalars['String']>;
	headingText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ProductFeatureHeadingLinkingCollections = {
	__typename?: 'ProductFeatureHeadingLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	productFeatureCollection?: Maybe<ProductFeatureCollection>;
};

export type ProductFeatureHeadingLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductFeatureHeadingLinkingCollectionsProductFeatureCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureHeadingLinkingCollectionsProductFeatureCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductFeatureHeadingLinkingCollectionsProductFeatureCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProductFeatureHeadingOrder =
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrowText_ASC'
	| 'eyebrowText_DESC'
	| 'headingHtag_ASC'
	| 'headingHtag_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProductFeatureLinkingCollections = {
	__typename?: 'ProductFeatureLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type ProductFeatureLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductFeatureLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductFeatureLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductFeatureLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ProductFeatureLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureMedia) */
export type ProductFeatureMedia = Entry &
	_Node & {
		__typename?: 'ProductFeatureMedia';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		contentsCollection?: Maybe<ProductFeatureMediaContentsCollection>;
		linkedFrom?: Maybe<ProductFeatureMediaLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureMedia) */
export type ProductFeatureMediaContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureMediaContentsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MediaSliderFilter>;
};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureMedia) */
export type ProductFeatureMediaLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A reference for the Product Feature [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/productFeatureMedia) */
export type ProductFeatureMediaNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ProductFeatureMediaCollection = {
	__typename?: 'ProductFeatureMediaCollection';
	items: Array<Maybe<ProductFeatureMedia>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProductFeatureMediaContentsCollection = {
	__typename?: 'ProductFeatureMediaContentsCollection';
	items: Array<Maybe<MediaSlider>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProductFeatureMediaContentsCollectionOrder =
	| 'aspectRatio_ASC'
	| 'aspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProductFeatureMediaFilter = {
	AND?: InputMaybe<Array<InputMaybe<ProductFeatureMediaFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ProductFeatureMediaFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contents?: InputMaybe<CfMediaSliderNestedFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ProductFeatureMediaLinkingCollections = {
	__typename?: 'ProductFeatureMediaLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type ProductFeatureMediaLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductFeatureMediaOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProductFeatureOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProductFilter = {
	AND?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
	brand?: InputMaybe<CfBrandNestedFilter>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	brandfolderImageAssetUrl?: InputMaybe<Scalars['String']>;
	brandfolderImageAssetUrl_contains?: InputMaybe<Scalars['String']>;
	brandfolderImageAssetUrl_exists?: InputMaybe<Scalars['Boolean']>;
	brandfolderImageAssetUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	brandfolderImageAssetUrl_not?: InputMaybe<Scalars['String']>;
	brandfolderImageAssetUrl_not_contains?: InputMaybe<Scalars['String']>;
	brandfolderImageAssetUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	category?: InputMaybe<Scalars['String']>;
	category_contains?: InputMaybe<Scalars['String']>;
	category_exists?: InputMaybe<Scalars['Boolean']>;
	category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	category_not?: InputMaybe<Scalars['String']>;
	category_not_contains?: InputMaybe<Scalars['String']>;
	category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id?: InputMaybe<Scalars['String']>;
	id_contains?: InputMaybe<Scalars['String']>;
	id_exists?: InputMaybe<Scalars['Boolean']>;
	id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_not?: InputMaybe<Scalars['String']>;
	id_not_contains?: InputMaybe<Scalars['String']>;
	id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	procedures_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	procedures_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	procedures_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	procedures_exists?: InputMaybe<Scalars['Boolean']>;
	subCategory?: InputMaybe<Scalars['String']>;
	subCategory_contains?: InputMaybe<Scalars['String']>;
	subCategory_exists?: InputMaybe<Scalars['Boolean']>;
	subCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subCategory_not?: InputMaybe<Scalars['String']>;
	subCategory_not_contains?: InputMaybe<Scalars['String']>;
	subCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ProductLinkingCollections = {
	__typename?: 'ProductLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type ProductLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProductOrder =
	| 'brandfolderImageAssetUrl_ASC'
	| 'brandfolderImageAssetUrl_DESC'
	| 'category_ASC'
	| 'category_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'id_ASC'
	| 'id_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subCategory_ASC'
	| 'subCategory_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotions) */
export type Promotions = Entry &
	_Node & {
		__typename?: 'Promotions';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		facilities?: Maybe<Array<Maybe<Scalars['String']>>>;
		linkedFrom?: Maybe<PromotionsLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		promoContent?: Maybe<Card>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotions) */
export type PromotionsFacilitiesArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotions) */
export type PromotionsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotions) */
export type PromotionsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotions) */
export type PromotionsPromoContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<CardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotionsAndOffering) */
export type PromotionsAndOffering = Entry &
	_Node & {
		__typename?: 'PromotionsAndOffering';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		cardsCollection?: Maybe<PromotionsAndOfferingCardsCollection>;
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<PromotionsAndOfferingLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sectionTitle?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotionsAndOffering) */
export type PromotionsAndOfferingBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotionsAndOffering) */
export type PromotionsAndOfferingCardsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PromotionsAndOfferingCardsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotionsAndOffering) */
export type PromotionsAndOfferingLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotionsAndOffering) */
export type PromotionsAndOfferingNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/promotionsAndOffering) */
export type PromotionsAndOfferingSectionTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type PromotionsAndOfferingCardsCollection = {
	__typename?: 'PromotionsAndOfferingCardsCollection';
	items: Array<Maybe<Card>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PromotionsAndOfferingCardsCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PromotionsAndOfferingCollection = {
	__typename?: 'PromotionsAndOfferingCollection';
	items: Array<Maybe<PromotionsAndOffering>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PromotionsAndOfferingFilter = {
	AND?: InputMaybe<Array<InputMaybe<PromotionsAndOfferingFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<PromotionsAndOfferingFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cards?: InputMaybe<CfCardNestedFilter>;
	cardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle?: InputMaybe<Scalars['String']>;
	sectionTitle_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_exists?: InputMaybe<Scalars['Boolean']>;
	sectionTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle_not?: InputMaybe<Scalars['String']>;
	sectionTitle_not_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type PromotionsAndOfferingLinkingCollections = {
	__typename?: 'PromotionsAndOfferingLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type PromotionsAndOfferingLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PromotionsAndOfferingLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PromotionsAndOfferingLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PromotionsAndOfferingLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type PromotionsAndOfferingOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type PromotionsCollection = {
	__typename?: 'PromotionsCollection';
	items: Array<Maybe<Promotions>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type PromotionsFilter = {
	AND?: InputMaybe<Array<InputMaybe<PromotionsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<PromotionsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	facilities_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilities_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilities_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilities_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	promoContent?: InputMaybe<CfCardNestedFilter>;
	promoContent_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type PromotionsLinkingCollections = {
	__typename?: 'PromotionsLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type PromotionsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type PromotionsOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type Provider = {
	__typename?: 'Provider';
	associations?: Maybe<Array<Scalars['String']>>;
	biography?: Maybe<Scalars['String']>;
	certifications?: Maybe<Array<ProviderCertification>>;
	collegeCity?: Maybe<Scalars['String']>;
	collegeName?: Maybe<Scalars['String']>;
	collegeState?: Maybe<Scalars['String']>;
	degreeName?: Maybe<Scalars['String']>;
	educations?: Maybe<Array<ProviderEducation>>;
	employeeId: Scalars['String'];
	employeeStatus?: Maybe<Scalars['String']>;
	ethnicity?: Maybe<Scalars['String']>;
	firstName?: Maybe<Scalars['String']>;
	gender?: Maybe<Scalars['String']>;
	graduationDate?: Maybe<Scalars['DateTime']>;
	hireDate?: Maybe<Scalars['DateTime']>;
	languages?: Maybe<Array<Scalars['String']>>;
	lastName?: Maybe<Scalars['String']>;
	mainWorkerFunction?: Maybe<Scalars['String']>;
	middleName?: Maybe<Scalars['String']>;
	nickname?: Maybe<Scalars['String']>;
	npi?: Maybe<Scalars['String']>;
	providerLocations?: Maybe<Array<ProviderLocation>>;
	quote?: Maybe<Scalars['String']>;
	socialNetworks?: Maybe<Array<SocialNetwork>>;
	stateLicense?: Maybe<Scalars['String']>;
	workerType?: Maybe<Scalars['String']>;
	yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type ProviderBrand = 'ASPEN_DENTAL' | 'AZ_PET' | 'CLEAR_CHOICE';

export type ProviderCertification = {
	__typename?: 'ProviderCertification';
	certification?: Maybe<Scalars['String']>;
	certificationCountry?: Maybe<Scalars['String']>;
	certificationName?: Maybe<Scalars['String']>;
	certifierOrIssuer?: Maybe<Scalars['String']>;
	issuedDate?: Maybe<Scalars['DateTime']>;
};

/** This is the content that needs to be displayed for Provider details page, default or for specific provider. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/providerDetailsPage) */
export type ProviderDetailsPage = Entry &
	_Node & {
		__typename?: 'ProviderDetailsPage';
		_id: Scalars['ID'];
		appCollection?: Maybe<ProviderDetailsPageAppCollection>;
		contentfulMetadata: ContentfulMetadata;
		contentsCollection?: Maybe<ProviderDetailsPageContentsCollection>;
		linkedFrom?: Maybe<ProviderDetailsPageLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		npiId?: Maybe<Scalars['String']>;
		secondaryContentsCollection?: Maybe<ProviderDetailsPageSecondaryContentsCollection>;
		sys: Sys;
	};

/** This is the content that needs to be displayed for Provider details page, default or for specific provider. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/providerDetailsPage) */
export type ProviderDetailsPageAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProviderDetailsPageAppCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AppDetailsFilter>;
};

/** This is the content that needs to be displayed for Provider details page, default or for specific provider. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/providerDetailsPage) */
export type ProviderDetailsPageContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProviderDetailsPageContentsFilter>;
};

/** This is the content that needs to be displayed for Provider details page, default or for specific provider. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/providerDetailsPage) */
export type ProviderDetailsPageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the content that needs to be displayed for Provider details page, default or for specific provider. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/providerDetailsPage) */
export type ProviderDetailsPageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This is the content that needs to be displayed for Provider details page, default or for specific provider. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/providerDetailsPage) */
export type ProviderDetailsPageNpiIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** This is the content that needs to be displayed for Provider details page, default or for specific provider. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/providerDetailsPage) */
export type ProviderDetailsPageSecondaryContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProviderDetailsPageSecondaryContentsFilter>;
};

export type ProviderDetailsPageAppCollection = {
	__typename?: 'ProviderDetailsPageAppCollection';
	items: Array<Maybe<AppDetails>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProviderDetailsPageAppCollectionOrder =
	| 'id_ASC'
	| 'id_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProviderDetailsPageCollection = {
	__typename?: 'ProviderDetailsPageCollection';
	items: Array<Maybe<ProviderDetailsPage>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProviderDetailsPageContentsCollection = {
	__typename?: 'ProviderDetailsPageContentsCollection';
	items: Array<Maybe<ProviderDetailsPageContentsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProviderDetailsPageContentsFilter = {
	AND?: InputMaybe<Array<InputMaybe<ProviderDetailsPageContentsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ProviderDetailsPageContentsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ProviderDetailsPageContentsItem =
	| Accordions
	| HalfScreen
	| MultiCardV2
	| Service
	| SingleMessage
	| Spacer
	| VideoCarousel;

export type ProviderDetailsPageFilter = {
	AND?: InputMaybe<Array<InputMaybe<ProviderDetailsPageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ProviderDetailsPageFilter>>>;
	app?: InputMaybe<CfAppDetailsNestedFilter>;
	appCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	npiId?: InputMaybe<Scalars['String']>;
	npiId_contains?: InputMaybe<Scalars['String']>;
	npiId_exists?: InputMaybe<Scalars['Boolean']>;
	npiId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	npiId_not?: InputMaybe<Scalars['String']>;
	npiId_not_contains?: InputMaybe<Scalars['String']>;
	npiId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	secondaryContents?: InputMaybe<CfsecondaryContentsMultiTypeNestedFilter>;
	secondaryContentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type ProviderDetailsPageLinkingCollections = {
	__typename?: 'ProviderDetailsPageLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type ProviderDetailsPageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ProviderDetailsPageOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ProviderDetailsPageSecondaryContentsCollection = {
	__typename?: 'ProviderDetailsPageSecondaryContentsCollection';
	items: Array<Maybe<ProviderDetailsPageSecondaryContentsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ProviderDetailsPageSecondaryContentsFilter = {
	AND?: InputMaybe<Array<InputMaybe<ProviderDetailsPageSecondaryContentsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ProviderDetailsPageSecondaryContentsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ProviderDetailsPageSecondaryContentsItem =
	| Accordions
	| HalfScreen
	| MultiCardV2
	| Service
	| SingleMessage
	| Spacer
	| VideoCarousel;

export type ProviderEducation = {
	__typename?: 'ProviderEducation';
	collegeName?: Maybe<Scalars['String']>;
	collegeState?: Maybe<Scalars['String']>;
	degreeName?: Maybe<Scalars['String']>;
	graduationDate?: Maybe<Scalars['DateTime']>;
};

export type ProviderLocation = {
	__typename?: 'ProviderLocation';
	facility?: Maybe<Facility>;
	isMain: Scalars['Boolean'];
	workerFunctions: Array<Scalars['String']>;
};

export type ProvidersFilterInput = {
	brand?: InputMaybe<ProviderBrand>;
};

export type ProvidersList = {
	__typename?: 'ProvidersList';
	items: Array<Provider>;
	total: Scalars['Int'];
};

/** Root query type. */
export type Query = {
	__typename?: 'Query';
	_node?: Maybe<_Node>;
	accordions?: Maybe<Accordions>;
	accordionsCollection?: Maybe<AccordionsCollection>;
	adFooter?: Maybe<AdFooter>;
	adFooterCollection?: Maybe<AdFooterCollection>;
	agendaModal?: Maybe<AgendaModal>;
	agendaModalCollection?: Maybe<AgendaModalCollection>;
	agendaStepper?: Maybe<AgendaStepper>;
	agendaStepperCollection?: Maybe<AgendaStepperCollection>;
	alert?: Maybe<Alert>;
	alertCollection?: Maybe<AlertCollection>;
	analyticsContext?: Maybe<AnalyticsContext>;
	analyticsContextCollection?: Maybe<AnalyticsContextCollection>;
	app?: Maybe<App>;
	appCollection?: Maybe<AppCollection>;
	appDetails?: Maybe<AppDetails>;
	appDetailsCollection?: Maybe<AppDetailsCollection>;
	appointmentBookingPage?: Maybe<AppointmentBookingPage>;
	appointmentBookingPageCollection?: Maybe<AppointmentBookingPageCollection>;
	asset?: Maybe<Asset>;
	assetCollection?: Maybe<AssetCollection>;
	averageProcedurePrices?: Maybe<ProcedurePricesList>;
	badge?: Maybe<Badge>;
	badgeCollection?: Maybe<BadgeCollection>;
	birdEyeReviews?: Maybe<BirdEyeReviews>;
	birdEyeReviewsCollection?: Maybe<BirdEyeReviewsCollection>;
	blogAuthor?: Maybe<BlogAuthor>;
	blogAuthorCollection?: Maybe<BlogAuthorCollection>;
	blogCategory?: Maybe<BlogCategory>;
	blogCategoryCollection?: Maybe<BlogCategoryCollection>;
	blogDetailsPage?: Maybe<BlogDetailsPage>;
	blogDetailsPageCollection?: Maybe<BlogDetailsPageCollection>;
	blogList?: Maybe<BlogList>;
	blogListCollection?: Maybe<BlogListCollection>;
	blogListPage?: Maybe<BlogListPage>;
	blogListPageCollection?: Maybe<BlogListPageCollection>;
	blogSubCategory?: Maybe<BlogSubCategory>;
	blogSubCategoryCollection?: Maybe<BlogSubCategoryCollection>;
	brand?: Maybe<Brand>;
	brandCollection?: Maybe<BrandCollection>;
	button?: Maybe<Button>;
	buttonCollection?: Maybe<ButtonCollection>;
	cacheStats?: Maybe<Array<Maybe<BffCacheStats>>>;
	callCenterHours: Array<WorkingHours>;
	callToAction?: Maybe<CallToAction>;
	callToActionCollection?: Maybe<CallToActionCollection>;
	card?: Maybe<Card>;
	cardCollection?: Maybe<CardCollection>;
	carousel?: Maybe<Carousel>;
	carouselCollection?: Maybe<CarouselCollection>;
	category?: Maybe<Category>;
	categoryCollection?: Maybe<CategoryCollection>;
	categoryPicker?: Maybe<CategoryPicker>;
	categoryPickerCollection?: Maybe<CategoryPickerCollection>;
	changeCacheTTL?: Maybe<BffCacheStats>;
	cities?: Maybe<CitiesList>;
	cityLinks?: Maybe<CityLinks>;
	cityLinksCollection?: Maybe<CityLinksCollection>;
	columns?: Maybe<Columns>;
	columnsCollection?: Maybe<ColumnsCollection>;
	compare?: Maybe<Compare>;
	compareCollection?: Maybe<CompareCollection>;
	confirmationStep?: Maybe<ConfirmationStep>;
	confirmationStepCollection?: Maybe<ConfirmationStepCollection>;
	contentSection?: Maybe<ContentSection>;
	contentSectionCollection?: Maybe<ContentSectionCollection>;
	contentTile?: Maybe<ContentTile>;
	contentTileCollection?: Maybe<ContentTileCollection>;
	contentTypeRichText?: Maybe<ContentTypeRichText>;
	contentTypeRichTextCollection?: Maybe<ContentTypeRichTextCollection>;
	cookie?: Maybe<Cookie>;
	cookieCollection?: Maybe<CookieCollection>;
	createBooking?: Maybe<BffSession>;
	disclaimer?: Maybe<Disclaimer>;
	disclaimerCollection?: Maybe<DisclaimerCollection>;
	disclaimerV2?: Maybe<DisclaimerV2>;
	disclaimerV2Collection?: Maybe<DisclaimerV2Collection>;
	entryCollection?: Maybe<EntryCollection>;
	error404?: Maybe<Error404>;
	error404Collection?: Maybe<Error404Collection>;
	facilities?: Maybe<FacilitiesList>;
	facilitiesByAddress?: Maybe<Array<Maybe<BffFacility>>>;
	facilitiesByState?: Maybe<Array<Maybe<BffFacility>>>;
	facilitiesByStateAndCity?: Maybe<Array<Maybe<BffFacility>>>;
	facilitiesByStateCityAndStreet?: Maybe<Array<Maybe<BffFacility>>>;
	facilitiesByStateCityAndZipcode?: Maybe<Array<Maybe<BffFacility>>>;
	facilitiesByZipcode?: Maybe<Array<Maybe<BffFacility>>>;
	facility?: Maybe<Facility>;
	facilityBrand?: Maybe<Array<Maybe<BffBrand>>>;
	facilityById?: Maybe<BffFacility>;
	facilityByLocation?: Maybe<Array<Maybe<BffFacility>>>;
	facilityCities?: Maybe<Array<Maybe<BffUsCity>>>;
	facilityCitiesByState?: Maybe<Array<Maybe<BffCity>>>;
	facilityCollection?: Maybe<BffFacilityCollection>;
	facilityDetailsPage?: Maybe<FacilityDetailsPage>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	facilityPicById?: Maybe<Array<Maybe<BffDamAsset>>>;
	facilityStates?: Maybe<Array<Maybe<BffState>>>;
	featureFlag?: Maybe<FeatureFlag>;
	featureFlagCollection?: Maybe<FeatureFlagCollection>;
	featureFlags?: Maybe<FeatureFlags>;
	featureFlagsCollection?: Maybe<FeatureFlagsCollection>;
	focalPointImage?: Maybe<FocalPointImage>;
	focalPointImageCollection?: Maybe<FocalPointImageCollection>;
	footerNavigationGroup?: Maybe<FooterNavigationGroup>;
	footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
	footerNavigationGroupV2?: Maybe<FooterNavigationGroupV2>;
	footerNavigationGroupV2Collection?: Maybe<FooterNavigationGroupV2Collection>;
	footerV2?: Maybe<FooterV2>;
	footerV2Collection?: Maybe<FooterV2Collection>;
	formDropdownField?: Maybe<FormDropdownField>;
	formDropdownFieldCollection?: Maybe<FormDropdownFieldCollection>;
	formDropdownListItem?: Maybe<FormDropdownListItem>;
	formDropdownListItemCollection?: Maybe<FormDropdownListItemCollection>;
	formField?: Maybe<FormField>;
	formFieldCollection?: Maybe<FormFieldCollection>;
	getCenters?: Maybe<BffCenters>;
	getServices?: Maybe<BffServices>;
	getSlots?: Maybe<BffSlots>;
	googleMapTemplate?: Maybe<GoogleMapTemplate>;
	googleMapTemplateCollection?: Maybe<GoogleMapTemplateCollection>;
	halfScreen?: Maybe<HalfScreen>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	header?: Maybe<Header>;
	headerCollection?: Maybe<HeaderCollection>;
	headerMainNav?: Maybe<HeaderMainNav>;
	headerMainNavCollection?: Maybe<HeaderMainNavCollection>;
	headerNavMenu?: Maybe<HeaderNavMenu>;
	headerNavMenuCollection?: Maybe<HeaderNavMenuCollection>;
	headerSubNav?: Maybe<HeaderSubNav>;
	headerSubNavCollection?: Maybe<HeaderSubNavCollection>;
	heroV2?: Maybe<HeroV2>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	iframe?: Maybe<Iframe>;
	iframeCollection?: Maybe<IframeCollection>;
	imageAsset?: Maybe<ImageAsset>;
	imageAssetCollection?: Maybe<ImageAssetCollection>;
	insurance?: Maybe<Insurance>;
	insuranceCollection?: Maybe<InsuranceCollection>;
	insuranceCompanies?: Maybe<InsuranceCompaniesList>;
	insuranceNetworks?: Maybe<InsuranceCompaniesNetworkList>;
	invalidateCache?: Maybe<BffCacheStats>;
	linkForwarder?: Maybe<LinkForwarder>;
	linkForwarderCollection?: Maybe<LinkForwarderCollection>;
	localizedServiceTitle?: Maybe<LocalizedServiceTitle>;
	localizedServiceTitleCollection?: Maybe<LocalizedServiceTitleCollection>;
	locationStep?: Maybe<LocationStep>;
	locationStepCollection?: Maybe<LocationStepCollection>;
	marquee?: Maybe<Marquee>;
	marqueeCollection?: Maybe<MarqueeCollection>;
	me?: Maybe<User>;
	mediaSlider?: Maybe<MediaSlider>;
	mediaSliderCollection?: Maybe<MediaSliderCollection>;
	medicalHistoryQuestionnaire?: Maybe<Questionnaire>;
	medicalService?: Maybe<MedicalService>;
	medicalServiceCollection?: Maybe<MedicalServiceCollection>;
	meetOurDoctors?: Maybe<MeetOurDoctors>;
	meetOurDoctorsCollection?: Maybe<MeetOurDoctorsCollection>;
	meetOurStaff?: Maybe<MeetOurStaff>;
	meetOurStaffCollection?: Maybe<MeetOurStaffCollection>;
	mobileButton?: Maybe<MobileButton>;
	mobileButtonCollection?: Maybe<MobileButtonCollection>;
	modal?: Maybe<Modal>;
	modalCollection?: Maybe<ModalCollection>;
	multiCard?: Maybe<MultiCard>;
	multiCardCollection?: Maybe<MultiCardCollection>;
	multiCardV2?: Maybe<MultiCardV2>;
	multiCardV2Collection?: Maybe<MultiCardV2Collection>;
	nameValues?: Maybe<NameValues>;
	nameValuesCollection?: Maybe<NameValuesCollection>;
	navigationGroup?: Maybe<NavigationGroup>;
	navigationGroupCollection?: Maybe<NavigationGroupCollection>;
	navigationLink?: Maybe<NavigationLink>;
	navigationLinkCollection?: Maybe<NavigationLinkCollection>;
	nearbyFacilities?: Maybe<NearbyFacilitiesList>;
	nearbyFacilitiesScheduleSlots?: Maybe<NearbyFacilitiesScheduleSlots>;
	notificationModal?: Maybe<NotificationModal>;
	notificationModalCollection?: Maybe<NotificationModalCollection>;
	odpHero?: Maybe<OdpHero>;
	odpHeroCollection?: Maybe<OdpHeroCollection>;
	odpLocalizedSecondaryFooter?: Maybe<OdpLocalizedSecondaryFooter>;
	odpLocalizedSecondaryFooterCollection?: Maybe<OdpLocalizedSecondaryFooterCollection>;
	onPageScheduling?: Maybe<OnPageScheduling>;
	onPageSchedulingCollection?: Maybe<OnPageSchedulingCollection>;
	page?: Maybe<Page>;
	pageCollection?: Maybe<PageCollection>;
	/** Recaptcha protected */
	patientEligibilities?: Maybe<Array<PatientEligibility>>;
	patientReviewV2?: Maybe<PatientReviewV2>;
	patientReviewV2Collection?: Maybe<PatientReviewV2Collection>;
	patientReviewsV2?: Maybe<PatientReviewsV2>;
	patientReviewsV2Collection?: Maybe<PatientReviewsV2Collection>;
	product?: Maybe<Product>;
	productByName?: Maybe<BffProduct>;
	productByNameAndFacility?: Maybe<BffProduct>;
	productCollection?: Maybe<ProductCollection>;
	productFeature?: Maybe<ProductFeature>;
	productFeatureCollection?: Maybe<ProductFeatureCollection>;
	productFeatureDescriptionListItem?: Maybe<ProductFeatureDescriptionListItem>;
	productFeatureDescriptionListItemCollection?: Maybe<ProductFeatureDescriptionListItemCollection>;
	productFeatureHeading?: Maybe<ProductFeatureHeading>;
	productFeatureHeadingCollection?: Maybe<ProductFeatureHeadingCollection>;
	productFeatureMedia?: Maybe<ProductFeatureMedia>;
	productFeatureMediaCollection?: Maybe<ProductFeatureMediaCollection>;
	promotions?: Maybe<Promotions>;
	promotionsAndOffering?: Maybe<PromotionsAndOffering>;
	promotionsAndOfferingCollection?: Maybe<PromotionsAndOfferingCollection>;
	promotionsCollection?: Maybe<PromotionsCollection>;
	provider?: Maybe<Provider>;
	providerDetailsPage?: Maybe<ProviderDetailsPage>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
	providerPicById?: Maybe<Array<Maybe<BffDamAsset>>>;
	providerPicByNpi?: Maybe<Array<Maybe<BffDamAsset>>>;
	providers: ProvidersList;
	questionStep?: Maybe<QuestionStep>;
	questionStepButton?: Maybe<QuestionStepButton>;
	questionStepButtonCollection?: Maybe<QuestionStepButtonCollection>;
	questionStepCollection?: Maybe<QuestionStepCollection>;
	questionStepper?: Maybe<QuestionStepper>;
	questionStepperCollection?: Maybe<QuestionStepperCollection>;
	questionnaire?: Maybe<Questionnaire>;
	questionnaireCollection?: Maybe<QuestionnaireCollection>;
	quickLinks?: Maybe<QuickLinks>;
	quickLinksCollection?: Maybe<QuickLinksCollection>;
	quiz?: Maybe<Quiz>;
	quizCollection?: Maybe<QuizCollection>;
	reusableContent?: Maybe<ReusableContent>;
	reusableContentCollection?: Maybe<ReusableContentCollection>;
	reviewCard?: Maybe<ReviewCard>;
	reviewCardCollection?: Maybe<ReviewCardCollection>;
	reviewStep?: Maybe<ReviewStep>;
	reviewStepCollection?: Maybe<ReviewStepCollection>;
	reviews?: Maybe<BffReviews>;
	richTextBlockSeo?: Maybe<RichTextBlockSeo>;
	richTextBlockSeoCollection?: Maybe<RichTextBlockSeoCollection>;
	schema?: Maybe<Schema>;
	schemaCollection?: Maybe<SchemaCollection>;
	schemaStep?: Maybe<SchemaStep>;
	schemaStepCollection?: Maybe<SchemaStepCollection>;
	searchFacilities?: Maybe<FacilitiesList>;
	secondaryHero?: Maybe<SecondaryHero>;
	secondaryHeroCollection?: Maybe<SecondaryHeroCollection>;
	section?: Maybe<Section>;
	sectionCollection?: Maybe<SectionCollection>;
	sectionContent?: Maybe<SectionContent>;
	sectionContentCollection?: Maybe<SectionContentCollection>;
	segmentsContainer?: Maybe<SegmentsContainer>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	service?: Maybe<Service>;
	serviceCollection?: Maybe<ServiceCollection>;
	serviceDetailsPage?: Maybe<ServiceDetailsPage>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
	serviceGroup?: Maybe<ServiceGroup>;
	serviceGroupCollection?: Maybe<ServiceGroupCollection>;
	serviceLineHero?: Maybe<ServiceLineHero>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
	sharedForm?: Maybe<SharedForm>;
	sharedFormCollection?: Maybe<SharedFormCollection>;
	sharedFormDefinitions?: Maybe<SharedFormDefinitions>;
	sharedFormDefinitionsCollection?: Maybe<SharedFormDefinitionsCollection>;
	sharedFormField?: Maybe<SharedFormField>;
	sharedFormFieldCollection?: Maybe<SharedFormFieldCollection>;
	singleMessage?: Maybe<SingleMessage>;
	singleMessageCollection?: Maybe<SingleMessageCollection>;
	smallScreenContent?: Maybe<SmallScreenContent>;
	smallScreenContentCollection?: Maybe<SmallScreenContentCollection>;
	smileAssessmentForm?: Maybe<SmileAssessmentForm>;
	smileAssessmentFormCollection?: Maybe<SmileAssessmentFormCollection>;
	spacer?: Maybe<Spacer>;
	spacerCollection?: Maybe<SpacerCollection>;
	staff?: Maybe<Staff>;
	staffCollection?: Maybe<StaffCollection>;
	stateLinks?: Maybe<StateLinks>;
	stateLinksCollection?: Maybe<StateLinksCollection>;
	states?: Maybe<Array<Maybe<BffState>>>;
	statesByAbbreviation?: Maybe<BffState>;
	statesByName?: Maybe<BffState>;
	stepper?: Maybe<Stepper>;
	stepperCollection?: Maybe<StepperCollection>;
	successCard?: Maybe<SuccessCard>;
	successCardCollection?: Maybe<SuccessCardCollection>;
	tab?: Maybe<Tab>;
	tabCollection?: Maybe<TabCollection>;
	table?: Maybe<Table>;
	tableCollection?: Maybe<TableCollection>;
	tableRow?: Maybe<TableRow>;
	tableRowCollection?: Maybe<TableRowCollection>;
	tabs?: Maybe<Tabs>;
	tabsCollection?: Maybe<TabsCollection>;
	termsAndConditions?: Maybe<TermsAndConditions>;
	termsAndConditionsCollection?: Maybe<TermsAndConditionsCollection>;
	textBlockSeo?: Maybe<TextBlockSeo>;
	textBlockSeoCollection?: Maybe<TextBlockSeoCollection>;
	video?: Maybe<Video>;
	videoAsset?: Maybe<VideoAsset>;
	videoAssetCollection?: Maybe<VideoAssetCollection>;
	videoCarousel?: Maybe<VideoCarousel>;
	videoCarouselCollection?: Maybe<VideoCarouselCollection>;
	videoCollection?: Maybe<VideoCollection>;
	videoWithContent?: Maybe<VideoWithContent>;
	videoWithContentCollection?: Maybe<VideoWithContentCollection>;
	wellNowFacility?: Maybe<Facility>;
};

/** Root query type. */
export type Query_NodeArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAccordionsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAccordionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AccordionsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AccordionsFilter>;
};

/** Root query type. */
export type QueryAdFooterArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAdFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AdFooterOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AdFooterFilter>;
};

/** Root query type. */
export type QueryAgendaModalArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAgendaModalCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AgendaModalOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AgendaModalFilter>;
};

/** Root query type. */
export type QueryAgendaStepperArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAgendaStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AgendaStepperOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AgendaStepperFilter>;
};

/** Root query type. */
export type QueryAlertArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAlertCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AlertOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AlertFilter>;
};

/** Root query type. */
export type QueryAnalyticsContextArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAnalyticsContextCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AnalyticsContextOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** Root query type. */
export type QueryAppArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AppOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AppFilter>;
};

/** Root query type. */
export type QueryAppDetailsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAppDetailsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AppDetailsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AppDetailsFilter>;
};

/** Root query type. */
export type QueryAppointmentBookingPageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAppointmentBookingPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AppointmentBookingPageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AppointmentBookingPageFilter>;
};

/** Root query type. */
export type QueryAssetArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryAssetCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<AssetFilter>;
};

/** Root query type. */
export type QueryAverageProcedurePricesArgs = {
	filter?: InputMaybe<ProceduresPricesFilterInput>;
	skip?: Scalars['NonNegativeInt'];
	take?: Scalars['NonNegativeInt'];
};

/** Root query type. */
export type QueryBadgeArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBadgeCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BadgeOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BadgeFilter>;
};

/** Root query type. */
export type QueryBirdEyeReviewsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBirdEyeReviewsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BirdEyeReviewsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BirdEyeReviewsFilter>;
};

/** Root query type. */
export type QueryBlogAuthorArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBlogAuthorCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogAuthorOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BlogAuthorFilter>;
};

/** Root query type. */
export type QueryBlogCategoryArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBlogCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogCategoryOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BlogCategoryFilter>;
};

/** Root query type. */
export type QueryBlogDetailsPageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBlogDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogDetailsPageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BlogDetailsPageFilter>;
};

/** Root query type. */
export type QueryBlogListArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBlogListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogListOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BlogListFilter>;
};

/** Root query type. */
export type QueryBlogListPageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBlogListPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogListPageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BlogListPageFilter>;
};

/** Root query type. */
export type QueryBlogSubCategoryArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBlogSubCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BlogSubCategoryOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BlogSubCategoryFilter>;
};

/** Root query type. */
export type QueryBrandArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryBrandCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<BrandOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<BrandFilter>;
};

/** Root query type. */
export type QueryButtonArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ButtonOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Root query type. */
export type QueryCallToActionArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryCallToActionCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CallToActionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CallToActionFilter>;
};

/** Root query type. */
export type QueryCardArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CardOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CardFilter>;
};

/** Root query type. */
export type QueryCarouselArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryCarouselCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CarouselOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CarouselFilter>;
};

/** Root query type. */
export type QueryCategoryArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CategoryFilter>;
};

/** Root query type. */
export type QueryCategoryPickerArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryCategoryPickerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CategoryPickerOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CategoryPickerFilter>;
};

/** Root query type. */
export type QueryChangeCacheTtlArgs = {
	code: Scalars['String'];
	name: Scalars['String'];
	ttlInSeconds?: InputMaybe<Scalars['Int']>;
};

/** Root query type. */
export type QueryCitiesArgs = {
	filter: CitiesFilterInput;
};

/** Root query type. */
export type QueryCityLinksArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryCityLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CityLinksOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CityLinksFilter>;
};

/** Root query type. */
export type QueryColumnsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryColumnsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ColumnsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ColumnsFilter>;
};

/** Root query type. */
export type QueryCompareArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryCompareCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CompareOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CompareFilter>;
};

/** Root query type. */
export type QueryConfirmationStepArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryConfirmationStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ConfirmationStepOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ConfirmationStepFilter>;
};

/** Root query type. */
export type QueryContentSectionArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryContentSectionCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentSectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ContentSectionFilter>;
};

/** Root query type. */
export type QueryContentTileArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryContentTileCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTileOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ContentTileFilter>;
};

/** Root query type. */
export type QueryContentTypeRichTextArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryContentTypeRichTextCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ContentTypeRichTextOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** Root query type. */
export type QueryCookieArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryCookieCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<CookieOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<CookieFilter>;
};

/** Root query type. */
export type QueryCreateBookingArgs = {
	brand: BffBrandType;
	centerId: Scalars['String'];
	date: Scalars['String'];
	isDoubleBookingEnabled?: InputMaybe<Scalars['Boolean']>;
	isOnlyCatalogEmployees?: InputMaybe<Scalars['Boolean']>;
	serviceId: Scalars['String'];
};

/** Root query type. */
export type QueryDisclaimerArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryDisclaimerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<DisclaimerFilter>;
};

/** Root query type. */
export type QueryDisclaimerV2Args = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryDisclaimerV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<DisclaimerV2Order>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<DisclaimerV2Filter>;
};

/** Root query type. */
export type QueryEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<EntryFilter>;
};

/** Root query type. */
export type QueryError404Args = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryError404CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<Error404Order>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<Error404Filter>;
};

/** Root query type. */
export type QueryFacilitiesArgs = {
	filter: FacilitiesFilterInput;
	skip?: Scalars['Int'];
	take?: Scalars['Int'];
};

/** Root query type. */
export type QueryFacilitiesByAddressArgs = {
	address: Scalars['String'];
	brand: BffBrandType;
	maxItems?: InputMaybe<Scalars['Int']>;
	radiusMiles?: InputMaybe<Scalars['Int']>;
};

/** Root query type. */
export type QueryFacilitiesByStateArgs = {
	brand: BffBrandType;
	state: Scalars['String'];
};

/** Root query type. */
export type QueryFacilitiesByStateAndCityArgs = {
	brand: BffBrandType;
	city: Scalars['String'];
	state: Scalars['String'];
};

/** Root query type. */
export type QueryFacilitiesByStateCityAndStreetArgs = {
	brand: BffBrandType;
	city: Scalars['String'];
	state: Scalars['String'];
	street: Scalars['String'];
};

/** Root query type. */
export type QueryFacilitiesByStateCityAndZipcodeArgs = {
	brand: BffBrandType;
	city: Scalars['String'];
	state: Scalars['String'];
	zipCode: Scalars['String'];
};

/** Root query type. */
export type QueryFacilitiesByZipcodeArgs = {
	brand: BffBrandType;
	maxItems?: InputMaybe<Scalars['Int']>;
	radiusMiles?: InputMaybe<Scalars['Int']>;
	zipCode: Scalars['String'];
};

/** Root query type. */
export type QueryFacilityArgs = {
	brand?: InputMaybe<FacilityBrand>;
	code: Scalars['String'];
};

/** Root query type. */
export type QueryFacilityByIdArgs = {
	brand: BffBrandType;
	id: Scalars['String'];
};

/** Root query type. */
export type QueryFacilityByLocationArgs = {
	brand: BffBrandType;
	latitude: Scalars['Float'];
	longitude: Scalars['Float'];
	maxItems?: InputMaybe<Scalars['Int']>;
	radiusMiles?: InputMaybe<Scalars['Int']>;
};

/** Root query type. */
export type QueryFacilityCitiesArgs = {
	brand: BffBrandType;
};

/** Root query type. */
export type QueryFacilityCitiesByStateArgs = {
	brand: BffBrandType;
	state: Scalars['String'];
};

/** Root query type. */
export type QueryFacilityCollectionArgs = {
	brand: BffBrandType;
	skip?: InputMaybe<Scalars['Int']>;
	take?: InputMaybe<Scalars['Int']>;
};

/** Root query type. */
export type QueryFacilityDetailsPageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FacilityDetailsPageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FacilityDetailsPageFilter>;
};

/** Root query type. */
export type QueryFacilityPicByIdArgs = {
	brand: BffBrandType;
	id: Scalars['String'];
};

/** Root query type. */
export type QueryFacilityStatesArgs = {
	brand: BffBrandType;
};

/** Root query type. */
export type QueryFeatureFlagArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFeatureFlagCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FeatureFlagOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FeatureFlagFilter>;
};

/** Root query type. */
export type QueryFeatureFlagsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFeatureFlagsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FeatureFlagsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FeatureFlagsFilter>;
};

/** Root query type. */
export type QueryFocalPointImageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFocalPointImageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FocalPointImageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** Root query type. */
export type QueryFooterNavigationGroupArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFooterNavigationGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FooterNavigationGroupFilter>;
};

/** Root query type. */
export type QueryFooterNavigationGroupV2Args = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFooterNavigationGroupV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupV2Order>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FooterNavigationGroupV2Filter>;
};

/** Root query type. */
export type QueryFooterV2Args = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFooterV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FooterV2Order>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FooterV2Filter>;
};

/** Root query type. */
export type QueryFormDropdownFieldArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFormDropdownFieldCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormDropdownFieldOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FormDropdownFieldFilter>;
};

/** Root query type. */
export type QueryFormDropdownListItemArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFormDropdownListItemCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormDropdownListItemOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FormDropdownListItemFilter>;
};

/** Root query type. */
export type QueryFormFieldArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryFormFieldCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<FormFieldOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<FormFieldFilter>;
};

/** Root query type. */
export type QueryGetCentersArgs = {
	brand: BffBrandType;
	catalogEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryGetServicesArgs = {
	brand: BffBrandType;
	catalogEnabled?: InputMaybe<Scalars['Boolean']>;
	centerId: Scalars['String'];
	page?: InputMaybe<Scalars['Int']>;
	size?: InputMaybe<Scalars['Int']>;
};

/** Root query type. */
export type QueryGetSlotsArgs = {
	bookingId: Scalars['String'];
	brand: BffBrandType;
	checkFutureDayAvailablity?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryGoogleMapTemplateArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryGoogleMapTemplateCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<GoogleMapTemplateOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<GoogleMapTemplateFilter>;
};

/** Root query type. */
export type QueryHalfScreenArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HalfScreenOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HalfScreenFilter>;
};

/** Root query type. */
export type QueryHeaderArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryHeaderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderFilter>;
};

/** Root query type. */
export type QueryHeaderMainNavArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryHeaderMainNavCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderMainNavOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderMainNavFilter>;
};

/** Root query type. */
export type QueryHeaderNavMenuArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryHeaderNavMenuCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderNavMenuOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderNavMenuFilter>;
};

/** Root query type. */
export type QueryHeaderSubNavArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryHeaderSubNavCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeaderSubNavOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeaderSubNavFilter>;
};

/** Root query type. */
export type QueryHeroV2Args = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<HeroV2Order>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<HeroV2Filter>;
};

/** Root query type. */
export type QueryIframeArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryIframeCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<IframeOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<IframeFilter>;
};

/** Root query type. */
export type QueryImageAssetArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryImageAssetCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ImageAssetOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** Root query type. */
export type QueryInsuranceArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryInsuranceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<InsuranceOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<InsuranceFilter>;
};

/** Root query type. */
export type QueryInsuranceCompaniesArgs = {
	filter: InsuranceCompaniesFilterInput;
};

/** Root query type. */
export type QueryInsuranceNetworksArgs = {
	filter: InsuranceCompaniesNetworkFilterInput;
};

/** Root query type. */
export type QueryInvalidateCacheArgs = {
	code: Scalars['String'];
	name: Scalars['String'];
};

/** Root query type. */
export type QueryLinkForwarderArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryLinkForwarderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<LinkForwarderOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<LinkForwarderFilter>;
};

/** Root query type. */
export type QueryLocalizedServiceTitleArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryLocalizedServiceTitleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<LocalizedServiceTitleOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<LocalizedServiceTitleFilter>;
};

/** Root query type. */
export type QueryLocationStepArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryLocationStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<LocationStepOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<LocationStepFilter>;
};

/** Root query type. */
export type QueryMarqueeArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryMarqueeCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MarqueeOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MarqueeFilter>;
};

/** Root query type. */
export type QueryMediaSliderArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryMediaSliderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MediaSliderOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MediaSliderFilter>;
};

/** Root query type. */
export type QueryMedicalServiceArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryMedicalServiceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MedicalServiceOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MedicalServiceFilter>;
};

/** Root query type. */
export type QueryMeetOurDoctorsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryMeetOurDoctorsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurDoctorsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MeetOurDoctorsFilter>;
};

/** Root query type. */
export type QueryMeetOurStaffArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryMeetOurStaffCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MeetOurStaffOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MeetOurStaffFilter>;
};

/** Root query type. */
export type QueryMobileButtonArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryMobileButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MobileButtonOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MobileButtonFilter>;
};

/** Root query type. */
export type QueryModalArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryModalCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ModalOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ModalFilter>;
};

/** Root query type. */
export type QueryMultiCardArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryMultiCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MultiCardFilter>;
};

/** Root query type. */
export type QueryMultiCardV2Args = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryMultiCardV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<MultiCardV2Order>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MultiCardV2Filter>;
};

/** Root query type. */
export type QueryNameValuesArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryNameValuesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NameValuesOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NameValuesFilter>;
};

/** Root query type. */
export type QueryNavigationGroupArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryNavigationGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationGroupOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NavigationGroupFilter>;
};

/** Root query type. */
export type QueryNavigationLinkArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryNavigationLinkCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NavigationLinkOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NavigationLinkFilter>;
};

/** Root query type. */
export type QueryNearbyFacilitiesArgs = {
	filter: NearbyFacilitiesLocationFilter;
	skip?: Scalars['NonNegativeInt'];
	take?: Scalars['NonNegativeInt'];
};

/** Root query type. */
export type QueryNearbyFacilitiesScheduleSlotsArgs = {
	filter: NearbyFacilitiesScheduleSlotsFilterInput;
};

/** Root query type. */
export type QueryNotificationModalArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryNotificationModalCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<NotificationModalOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<NotificationModalFilter>;
};

/** Root query type. */
export type QueryOdpHeroArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryOdpHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpHeroOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<OdpHeroFilter>;
};

/** Root query type. */
export type QueryOdpLocalizedSecondaryFooterArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryOdpLocalizedSecondaryFooterCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OdpLocalizedSecondaryFooterOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<OdpLocalizedSecondaryFooterFilter>;
};

/** Root query type. */
export type QueryOnPageSchedulingArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryOnPageSchedulingCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<OnPageSchedulingOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<OnPageSchedulingFilter>;
};

/** Root query type. */
export type QueryPageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PageFilter>;
};

/** Root query type. */
export type QueryPatientEligibilitiesArgs = {
	input: NewPatientEligibilityFilterInput;
};

/** Root query type. */
export type QueryPatientReviewV2Args = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryPatientReviewV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewV2Order>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PatientReviewV2Filter>;
};

/** Root query type. */
export type QueryPatientReviewsV2Args = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryPatientReviewsV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PatientReviewsV2Order>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PatientReviewsV2Filter>;
};

/** Root query type. */
export type QueryProductArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryProductByNameArgs = {
	brand: BffBrandType;
	name: Scalars['String'];
};

/** Root query type. */
export type QueryProductByNameAndFacilityArgs = {
	brand: BffBrandType;
	facilityCode: Scalars['String'];
	name: Scalars['String'];
};

/** Root query type. */
export type QueryProductCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProductFilter>;
};

/** Root query type. */
export type QueryProductFeatureArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryProductFeatureCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProductFeatureFilter>;
};

/** Root query type. */
export type QueryProductFeatureDescriptionListItemArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryProductFeatureDescriptionListItemCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureDescriptionListItemOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProductFeatureDescriptionListItemFilter>;
};

/** Root query type. */
export type QueryProductFeatureHeadingArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryProductFeatureHeadingCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureHeadingOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProductFeatureHeadingFilter>;
};

/** Root query type. */
export type QueryProductFeatureMediaArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryProductFeatureMediaCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProductFeatureMediaOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProductFeatureMediaFilter>;
};

/** Root query type. */
export type QueryPromotionsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryPromotionsAndOfferingArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryPromotionsAndOfferingCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PromotionsAndOfferingOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PromotionsAndOfferingFilter>;
};

/** Root query type. */
export type QueryPromotionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<PromotionsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<PromotionsFilter>;
};

/** Root query type. */
export type QueryProviderArgs = {
	npi: Scalars['NonEmptyString'];
};

/** Root query type. */
export type QueryProviderDetailsPageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ProviderDetailsPageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ProviderDetailsPageFilter>;
};

/** Root query type. */
export type QueryProviderPicByIdArgs = {
	brand: BffBrandType;
	id: Scalars['String'];
};

/** Root query type. */
export type QueryProviderPicByNpiArgs = {
	brand: BffBrandType;
	npi: Scalars['String'];
};

/** Root query type. */
export type QueryProvidersArgs = {
	filter: ProvidersFilterInput;
	skip: Scalars['NonNegativeInt'];
	take: Scalars['NonNegativeInt'];
};

/** Root query type. */
export type QueryQuestionStepArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryQuestionStepButtonArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryQuestionStepButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionStepButtonOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<QuestionStepButtonFilter>;
};

/** Root query type. */
export type QueryQuestionStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionStepOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<QuestionStepFilter>;
};

/** Root query type. */
export type QueryQuestionStepperArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryQuestionStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionStepperOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<QuestionStepperFilter>;
};

/** Root query type. */
export type QueryQuestionnaireArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	options?: InputMaybe<QuestionnaireOptionsInput>;
	preview?: InputMaybe<Scalars['Boolean']>;
	questionnaireId: Scalars['String'];
};

/** Root query type. */
export type QueryQuestionnaireCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionnaireOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<QuestionnaireFilter>;
};

/** Root query type. */
export type QueryQuickLinksArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryQuickLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuickLinksOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<QuickLinksFilter>;
};

/** Root query type. */
export type QueryQuizArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryQuizCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuizOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<QuizFilter>;
};

/** Root query type. */
export type QueryReusableContentArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryReusableContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ReusableContentOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ReusableContentFilter>;
};

/** Root query type. */
export type QueryReviewCardArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryReviewCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ReviewCardOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ReviewCardFilter>;
};

/** Root query type. */
export type QueryReviewStepArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryReviewStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ReviewStepOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ReviewStepFilter>;
};

/** Root query type. */
export type QueryReviewsArgs = {
	brand: BffBrandType;
	facilityCode: Scalars['String'];
};

/** Root query type. */
export type QueryRichTextBlockSeoArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryRichTextBlockSeoCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<RichTextBlockSeoOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<RichTextBlockSeoFilter>;
};

/** Root query type. */
export type QuerySchemaArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySchemaCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SchemaOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SchemaFilter>;
};

/** Root query type. */
export type QuerySchemaStepArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySchemaStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SchemaStepOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SchemaStepFilter>;
};

/** Root query type. */
export type QuerySearchFacilitiesArgs = {
	filter: SearchFacilitiesFilterInput;
	skip?: Scalars['Int'];
	take?: Scalars['Int'];
};

/** Root query type. */
export type QuerySecondaryHeroArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySecondaryHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SecondaryHeroOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SecondaryHeroFilter>;
};

/** Root query type. */
export type QuerySectionArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySectionCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SectionFilter>;
};

/** Root query type. */
export type QuerySectionContentArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySectionContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SectionContentOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SectionContentFilter>;
};

/** Root query type. */
export type QuerySegmentsContainerArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SegmentsContainerOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SegmentsContainerFilter>;
};

/** Root query type. */
export type QueryServiceArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryServiceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ServiceFilter>;
};

/** Root query type. */
export type QueryServiceDetailsPageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceDetailsPageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ServiceDetailsPageFilter>;
};

/** Root query type. */
export type QueryServiceGroupArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryServiceGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceGroupOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ServiceGroupFilter>;
};

/** Root query type. */
export type QueryServiceLineHeroArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLineHeroOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ServiceLineHeroFilter>;
};

/** Root query type. */
export type QuerySharedFormArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySharedFormCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SharedFormFilter>;
};

/** Root query type. */
export type QuerySharedFormDefinitionsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySharedFormDefinitionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormDefinitionsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SharedFormDefinitionsFilter>;
};

/** Root query type. */
export type QuerySharedFormFieldArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySharedFormFieldCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormFieldOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SharedFormFieldFilter>;
};

/** Root query type. */
export type QuerySingleMessageArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySingleMessageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SingleMessageFilter>;
};

/** Root query type. */
export type QuerySmallScreenContentArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySmallScreenContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SmallScreenContentOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SmallScreenContentFilter>;
};

/** Root query type. */
export type QuerySmileAssessmentFormArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySmileAssessmentFormCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SmileAssessmentFormOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SmileAssessmentFormFilter>;
};

/** Root query type. */
export type QuerySpacerArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySpacerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SpacerOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SpacerFilter>;
};

/** Root query type. */
export type QueryStaffArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryStaffCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<StaffOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<StaffFilter>;
};

/** Root query type. */
export type QueryStateLinksArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryStateLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<StateLinksOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<StateLinksFilter>;
};

/** Root query type. */
export type QueryStatesByAbbreviationArgs = {
	abbreviation: Scalars['String'];
};

/** Root query type. */
export type QueryStatesByNameArgs = {
	name: Scalars['String'];
};

/** Root query type. */
export type QueryStepperArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<StepperOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<StepperFilter>;
};

/** Root query type. */
export type QuerySuccessCardArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QuerySuccessCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SuccessCardOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SuccessCardFilter>;
};

/** Root query type. */
export type QueryTabArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryTabCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TabOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TabFilter>;
};

/** Root query type. */
export type QueryTableArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryTableCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TableOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TableFilter>;
};

/** Root query type. */
export type QueryTableRowArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryTableRowCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TableRowOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TableRowFilter>;
};

/** Root query type. */
export type QueryTabsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryTabsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TabsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TabsFilter>;
};

/** Root query type. */
export type QueryTermsAndConditionsArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryTermsAndConditionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TermsAndConditionsOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TermsAndConditionsFilter>;
};

/** Root query type. */
export type QueryTextBlockSeoArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryTextBlockSeoCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TextBlockSeoOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TextBlockSeoFilter>;
};

/** Root query type. */
export type QueryVideoArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryVideoAssetArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryVideoAssetCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoAssetOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<VideoAssetFilter>;
};

/** Root query type. */
export type QueryVideoCarouselArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryVideoCarouselCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoCarouselOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<VideoCarouselFilter>;
};

/** Root query type. */
export type QueryVideoCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<VideoFilter>;
};

/** Root query type. */
export type QueryVideoWithContentArgs = {
	id: Scalars['String'];
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Root query type. */
export type QueryVideoWithContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoWithContentOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<VideoWithContentFilter>;
};

/** Root query type. */
export type QueryWellNowFacilityArgs = {
	code: Scalars['String'];
};

/** reason for visit page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStep) */
export type QuestionStep = Entry &
	_Node & {
		__typename?: 'QuestionStep';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<QuestionStepLinkingCollections>;
		pageTitle?: Maybe<Scalars['String']>;
		stepButtonsCollection?: Maybe<QuestionStepStepButtonsCollection>;
		stepName?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** reason for visit page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStep) */
export type QuestionStepLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** reason for visit page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStep) */
export type QuestionStepPageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** reason for visit page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStep) */
export type QuestionStepStepButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionStepStepButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<QuestionStepButtonFilter>;
};

/** reason for visit page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStep) */
export type QuestionStepStepNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** a button that will go on a question step within the question stepper [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStepButton) */
export type QuestionStepButton = Entry &
	_Node & {
		__typename?: 'QuestionStepButton';
		_id: Scalars['ID'];
		apiName?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		iconName?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<QuestionStepButtonLinkingCollections>;
		questionStepButtonDescription?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** a button that will go on a question step within the question stepper [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStepButton) */
export type QuestionStepButtonApiNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** a button that will go on a question step within the question stepper [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStepButton) */
export type QuestionStepButtonIconNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** a button that will go on a question step within the question stepper [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStepButton) */
export type QuestionStepButtonLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** a button that will go on a question step within the question stepper [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStepButton) */
export type QuestionStepButtonQuestionStepButtonDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type QuestionStepButtonCollection = {
	__typename?: 'QuestionStepButtonCollection';
	items: Array<Maybe<QuestionStepButton>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuestionStepButtonFilter = {
	AND?: InputMaybe<Array<InputMaybe<QuestionStepButtonFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<QuestionStepButtonFilter>>>;
	apiName?: InputMaybe<Scalars['String']>;
	apiName_contains?: InputMaybe<Scalars['String']>;
	apiName_exists?: InputMaybe<Scalars['Boolean']>;
	apiName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiName_not?: InputMaybe<Scalars['String']>;
	apiName_not_contains?: InputMaybe<Scalars['String']>;
	apiName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	iconName?: InputMaybe<Scalars['String']>;
	iconName_contains?: InputMaybe<Scalars['String']>;
	iconName_exists?: InputMaybe<Scalars['Boolean']>;
	iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconName_not?: InputMaybe<Scalars['String']>;
	iconName_not_contains?: InputMaybe<Scalars['String']>;
	iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	questionStepButtonDescription?: InputMaybe<Scalars['String']>;
	questionStepButtonDescription_contains?: InputMaybe<Scalars['String']>;
	questionStepButtonDescription_exists?: InputMaybe<Scalars['Boolean']>;
	questionStepButtonDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	questionStepButtonDescription_not?: InputMaybe<Scalars['String']>;
	questionStepButtonDescription_not_contains?: InputMaybe<Scalars['String']>;
	questionStepButtonDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type QuestionStepButtonLinkingCollections = {
	__typename?: 'QuestionStepButtonLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	questionStepCollection?: Maybe<QuestionStepCollection>;
};

export type QuestionStepButtonLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionStepButtonLinkingCollectionsQuestionStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionStepButtonLinkingCollectionsQuestionStepCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionStepButtonLinkingCollectionsQuestionStepCollectionOrder =
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type QuestionStepButtonOrder =
	| 'apiName_ASC'
	| 'apiName_DESC'
	| 'iconName_ASC'
	| 'iconName_DESC'
	| 'questionStepButtonDescription_ASC'
	| 'questionStepButtonDescription_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type QuestionStepCollection = {
	__typename?: 'QuestionStepCollection';
	items: Array<Maybe<QuestionStep>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuestionStepFilter = {
	AND?: InputMaybe<Array<InputMaybe<QuestionStepFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<QuestionStepFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	pageTitle?: InputMaybe<Scalars['String']>;
	pageTitle_contains?: InputMaybe<Scalars['String']>;
	pageTitle_exists?: InputMaybe<Scalars['Boolean']>;
	pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTitle_not?: InputMaybe<Scalars['String']>;
	pageTitle_not_contains?: InputMaybe<Scalars['String']>;
	pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepButtons?: InputMaybe<CfQuestionStepButtonNestedFilter>;
	stepButtonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	stepName?: InputMaybe<Scalars['String']>;
	stepName_contains?: InputMaybe<Scalars['String']>;
	stepName_exists?: InputMaybe<Scalars['Boolean']>;
	stepName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepName_not?: InputMaybe<Scalars['String']>;
	stepName_not_contains?: InputMaybe<Scalars['String']>;
	stepName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type QuestionStepLinkingCollections = {
	__typename?: 'QuestionStepLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	stepperCollection?: Maybe<StepperCollection>;
};

export type QuestionStepLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionStepLinkingCollectionsStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionStepLinkingCollectionsStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionStepLinkingCollectionsStepperCollectionOrder =
	| 'confirmButtonText_ASC'
	| 'confirmButtonText_DESC'
	| 'continueButtonText_ASC'
	| 'continueButtonText_DESC'
	| 'headingScheduledText_ASC'
	| 'headingScheduledText_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'needAssistanceText_ASC'
	| 'needAssistanceText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type QuestionStepOrder =
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type QuestionStepStepButtonsCollection = {
	__typename?: 'QuestionStepStepButtonsCollection';
	items: Array<Maybe<QuestionStepButton>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuestionStepStepButtonsCollectionOrder =
	| 'apiName_ASC'
	| 'apiName_DESC'
	| 'iconName_ASC'
	| 'iconName_DESC'
	| 'questionStepButtonDescription_ASC'
	| 'questionStepButtonDescription_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** contains reason for visit and any potential other questions before scheduling. last step in this will always be innerStepper [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStepper) */
export type QuestionStepper = Entry &
	_Node & {
		__typename?: 'QuestionStepper';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<QuestionStepperLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** contains reason for visit and any potential other questions before scheduling. last step in this will always be innerStepper [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStepper) */
export type QuestionStepperLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** contains reason for visit and any potential other questions before scheduling. last step in this will always be innerStepper [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionStepper) */
export type QuestionStepperNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type QuestionStepperCollection = {
	__typename?: 'QuestionStepperCollection';
	items: Array<Maybe<QuestionStepper>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuestionStepperFilter = {
	AND?: InputMaybe<Array<InputMaybe<QuestionStepperFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<QuestionStepperFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type QuestionStepperLinkingCollections = {
	__typename?: 'QuestionStepperLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type QuestionStepperLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionStepperLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionStepperLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionStepperLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type QuestionStepperOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type Questionnaire = Entry &
	_Node & {
		__typename?: 'Questionnaire';
		_id: Scalars['ID'];
		buttonText?: Maybe<Scalars['String']>;
		buttonUrl?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		defaultErrorMessage?: Maybe<Scalars['String']>;
		eyebrow?: Maybe<Scalars['String']>;
		formCollection?: Maybe<QuestionnaireFormCollection>;
		image?: Maybe<FocalPointImage>;
		linkedFrom?: Maybe<QuestionnaireLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		questions?: Maybe<Array<QuestionnaireQuestion>>;
		subTitle?: Maybe<Scalars['String']>;
		sys: Sys;
		text?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireDefaultErrorMessageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireFormCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<QuestionnaireFormFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireSubTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/questionnaire) */
export type QuestionnaireTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type QuestionnaireAnswer = FreeTextAnswer | MultiChoiceMultiAnswer | MultiChoiceSingleAnswer | YesNoAnswer;

export type QuestionnaireAnswerFormat =
	| 'CHECK_BOX_LIST'
	| 'CHECK_BOX_LIST_NONE'
	| 'DATE'
	| 'DROPDOWN'
	| 'HEIGHT'
	| 'MULTI_SELECT'
	| 'PHONE'
	| 'RADIO_BUTTON'
	| 'TEXT_AREA'
	| 'TEXT_BOX'
	| 'WEIGHT';

export type QuestionnaireAnswerInput = {
	freeTextAnswer?: InputMaybe<FreeTextQuestionnaireAnswerInput>;
	multiAnswer?: InputMaybe<MultiPredefinedQuestionnaireAnswerInput>;
	singleAnswer?: InputMaybe<SinglePredefinedQuestionnaireAnswerInput>;
};

/** Returned when questions requiring answer are not answered. */
export type QuestionnaireAnswerRequiredError = Error & {
	__typename?: 'QuestionnaireAnswerRequiredError';
	message: Scalars['String'];
	requiredQuestions: Array<QuestionnaireQuestion>;
};

export type QuestionnaireAnswerScheduleInput = {
	freeTextAnswer?: InputMaybe<FreeTextQuestionnaireAnswerScheduleInput>;
	multiAnswer?: InputMaybe<MultiPredefinedQuestionnaireAnswerScheduleInput>;
	singleAnswer?: InputMaybe<SinglePredefinedQuestionnaireAnswerScheduleInput>;
};

export type QuestionnaireAnswersInput = {
	answers?: InputMaybe<Array<QuestionnaireAnswerScheduleInput>>;
	questionnaireId: Scalars['NonEmptyString'];
};

export type QuestionnaireCollection = {
	__typename?: 'QuestionnaireCollection';
	items: Array<Maybe<Questionnaire>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuestionnaireFilter = {
	AND?: InputMaybe<Array<InputMaybe<QuestionnaireFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<QuestionnaireFilter>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	defaultErrorMessage?: InputMaybe<Scalars['String']>;
	defaultErrorMessage_contains?: InputMaybe<Scalars['String']>;
	defaultErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
	defaultErrorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	defaultErrorMessage_not?: InputMaybe<Scalars['String']>;
	defaultErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
	defaultErrorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	form?: InputMaybe<CfformMultiTypeNestedFilter>;
	formCollection_exists?: InputMaybe<Scalars['Boolean']>;
	image?: InputMaybe<CfFocalPointImageNestedFilter>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subTitle?: InputMaybe<Scalars['String']>;
	subTitle_contains?: InputMaybe<Scalars['String']>;
	subTitle_exists?: InputMaybe<Scalars['Boolean']>;
	subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subTitle_not?: InputMaybe<Scalars['String']>;
	subTitle_not_contains?: InputMaybe<Scalars['String']>;
	subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	text?: InputMaybe<Scalars['String']>;
	text_contains?: InputMaybe<Scalars['String']>;
	text_exists?: InputMaybe<Scalars['Boolean']>;
	text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	text_not?: InputMaybe<Scalars['String']>;
	text_not_contains?: InputMaybe<Scalars['String']>;
	text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuestionnaireFormCollection = {
	__typename?: 'QuestionnaireFormCollection';
	items: Array<Maybe<QuestionnaireFormItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuestionnaireFormFilter = {
	AND?: InputMaybe<Array<InputMaybe<QuestionnaireFormFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<QuestionnaireFormFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuestionnaireFormItem = FormDropdownField | FormField | SectionContent;

export type QuestionnaireLinkingCollections = {
	__typename?: 'QuestionnaireLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
	smileAssessmentFormCollection?: Maybe<SmileAssessmentFormCollection>;
};

export type QuestionnaireLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionnaireLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionnaireLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionnaireLinkingCollectionsSmileAssessmentFormCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuestionnaireLinkingCollectionsSmileAssessmentFormCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuestionnaireLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type QuestionnaireLinkingCollectionsSmileAssessmentFormCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type QuestionnaireOptionsInput = {
	language?: InputMaybe<Scalars['NonEmptyString']>;
};

export type QuestionnaireOrder =
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'defaultErrorMessage_ASC'
	| 'defaultErrorMessage_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subTitle_ASC'
	| 'subTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'text_ASC'
	| 'text_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type QuestionnaireQuestion = {
	__typename?: 'QuestionnaireQuestion';
	answer: QuestionnaireAnswer;
	answerRequired: Scalars['Boolean'];
	code?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	questionId: Scalars['String'];
	sectionType?: Maybe<Scalars['String']>;
	triggers?: Maybe<Array<ParentQuestionTrigger>>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinks = Entry &
	_Node & {
		__typename?: 'QuickLinks';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		body?: Maybe<QuickLinksBody>;
		buttonsCollection?: Maybe<QuickLinksButtonsCollection>;
		contentfulMetadata: ContentfulMetadata;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		displayAsTitles?: Maybe<Scalars['Boolean']>;
		eyebrow?: Maybe<Scalars['String']>;
		eyebrowStyle?: Maybe<Scalars['String']>;
		heading?: Maybe<QuickLinksHeading>;
		linkedFrom?: Maybe<QuickLinksLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		quickLinksCollection?: Maybe<QuickLinksQuickLinksCollection>;
		sys: Sys;
		templateSize?: Maybe<Scalars['String']>;
	};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksBodyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuickLinksButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksDisplayAsTitlesArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksEyebrowStyleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksQuickLinksCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuickLinksQuickLinksCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** QuickLinks template [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quickLinks) */
export type QuickLinksTemplateSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type QuickLinksBody = {
	__typename?: 'QuickLinksBody';
	json: Scalars['JSON'];
	links: QuickLinksBodyLinks;
};

export type QuickLinksBodyAssets = {
	__typename?: 'QuickLinksBodyAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type QuickLinksBodyEntries = {
	__typename?: 'QuickLinksBodyEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type QuickLinksBodyLinks = {
	__typename?: 'QuickLinksBodyLinks';
	assets: QuickLinksBodyAssets;
	entries: QuickLinksBodyEntries;
	resources: QuickLinksBodyResources;
};

export type QuickLinksBodyResources = {
	__typename?: 'QuickLinksBodyResources';
	block: Array<QuickLinksBodyResourcesBlock>;
	hyperlink: Array<QuickLinksBodyResourcesHyperlink>;
	inline: Array<QuickLinksBodyResourcesInline>;
};

export type QuickLinksBodyResourcesBlock = ResourceLink & {
	__typename?: 'QuickLinksBodyResourcesBlock';
	sys: ResourceSys;
};

export type QuickLinksBodyResourcesHyperlink = ResourceLink & {
	__typename?: 'QuickLinksBodyResourcesHyperlink';
	sys: ResourceSys;
};

export type QuickLinksBodyResourcesInline = ResourceLink & {
	__typename?: 'QuickLinksBodyResourcesInline';
	sys: ResourceSys;
};

export type QuickLinksButtonsCollection = {
	__typename?: 'QuickLinksButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuickLinksButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type QuickLinksCollection = {
	__typename?: 'QuickLinksCollection';
	items: Array<Maybe<QuickLinks>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuickLinksFilter = {
	AND?: InputMaybe<Array<InputMaybe<QuickLinksFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<QuickLinksFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	body_contains?: InputMaybe<Scalars['String']>;
	body_exists?: InputMaybe<Scalars['Boolean']>;
	body_not_contains?: InputMaybe<Scalars['String']>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	displayAsTitles?: InputMaybe<Scalars['Boolean']>;
	displayAsTitles_exists?: InputMaybe<Scalars['Boolean']>;
	displayAsTitles_not?: InputMaybe<Scalars['Boolean']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrowStyle?: InputMaybe<Scalars['String']>;
	eyebrowStyle_contains?: InputMaybe<Scalars['String']>;
	eyebrowStyle_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowStyle_not?: InputMaybe<Scalars['String']>;
	eyebrowStyle_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	heading_contains?: InputMaybe<Scalars['String']>;
	heading_exists?: InputMaybe<Scalars['Boolean']>;
	heading_not_contains?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quickLinks?: InputMaybe<CfButtonNestedFilter>;
	quickLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	templateSize?: InputMaybe<Scalars['String']>;
	templateSize_contains?: InputMaybe<Scalars['String']>;
	templateSize_exists?: InputMaybe<Scalars['Boolean']>;
	templateSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateSize_not?: InputMaybe<Scalars['String']>;
	templateSize_not_contains?: InputMaybe<Scalars['String']>;
	templateSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuickLinksHeading = {
	__typename?: 'QuickLinksHeading';
	json: Scalars['JSON'];
	links: QuickLinksHeadingLinks;
};

export type QuickLinksHeadingAssets = {
	__typename?: 'QuickLinksHeadingAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type QuickLinksHeadingEntries = {
	__typename?: 'QuickLinksHeadingEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type QuickLinksHeadingLinks = {
	__typename?: 'QuickLinksHeadingLinks';
	assets: QuickLinksHeadingAssets;
	entries: QuickLinksHeadingEntries;
	resources: QuickLinksHeadingResources;
};

export type QuickLinksHeadingResources = {
	__typename?: 'QuickLinksHeadingResources';
	block: Array<QuickLinksHeadingResourcesBlock>;
	hyperlink: Array<QuickLinksHeadingResourcesHyperlink>;
	inline: Array<QuickLinksHeadingResourcesInline>;
};

export type QuickLinksHeadingResourcesBlock = ResourceLink & {
	__typename?: 'QuickLinksHeadingResourcesBlock';
	sys: ResourceSys;
};

export type QuickLinksHeadingResourcesHyperlink = ResourceLink & {
	__typename?: 'QuickLinksHeadingResourcesHyperlink';
	sys: ResourceSys;
};

export type QuickLinksHeadingResourcesInline = ResourceLink & {
	__typename?: 'QuickLinksHeadingResourcesInline';
	sys: ResourceSys;
};

export type QuickLinksLinkingCollections = {
	__typename?: 'QuickLinksLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type QuickLinksLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuickLinksLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuickLinksLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuickLinksLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuickLinksLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuickLinksLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuickLinksLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuickLinksLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type QuickLinksLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type QuickLinksLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type QuickLinksOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'displayAsTitles_ASC'
	| 'displayAsTitles_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC';

export type QuickLinksQuickLinksCollection = {
	__typename?: 'QuickLinksQuickLinksCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuickLinksQuickLinksCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

/** Content model used to get the questions and other data for the Quiz displayed in Aspen Dental, ClearChoice etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quiz) */
export type Quiz = Entry &
	_Node & {
		__typename?: 'Quiz';
		_id: Scalars['ID'];
		brand?: Maybe<Brand>;
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<QuizLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		quizFormData?: Maybe<Scalars['JSON']>;
		quizType?: Maybe<Scalars['String']>;
		submitEndpointUrl?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** Content model used to get the questions and other data for the Quiz displayed in Aspen Dental, ClearChoice etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quiz) */
export type QuizBrandArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BrandFilter>;
};

/** Content model used to get the questions and other data for the Quiz displayed in Aspen Dental, ClearChoice etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quiz) */
export type QuizLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content model used to get the questions and other data for the Quiz displayed in Aspen Dental, ClearChoice etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quiz) */
export type QuizNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content model used to get the questions and other data for the Quiz displayed in Aspen Dental, ClearChoice etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quiz) */
export type QuizQuizFormDataArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content model used to get the questions and other data for the Quiz displayed in Aspen Dental, ClearChoice etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quiz) */
export type QuizQuizTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content model used to get the questions and other data for the Quiz displayed in Aspen Dental, ClearChoice etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/quiz) */
export type QuizSubmitEndpointUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type QuizCollection = {
	__typename?: 'QuizCollection';
	items: Array<Maybe<Quiz>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type QuizFilter = {
	AND?: InputMaybe<Array<InputMaybe<QuizFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<QuizFilter>>>;
	brand?: InputMaybe<CfBrandNestedFilter>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quizFormData_exists?: InputMaybe<Scalars['Boolean']>;
	quizType?: InputMaybe<Scalars['String']>;
	quizType_contains?: InputMaybe<Scalars['String']>;
	quizType_exists?: InputMaybe<Scalars['Boolean']>;
	quizType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quizType_not?: InputMaybe<Scalars['String']>;
	quizType_not_contains?: InputMaybe<Scalars['String']>;
	quizType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	submitEndpointUrl?: InputMaybe<Scalars['String']>;
	submitEndpointUrl_contains?: InputMaybe<Scalars['String']>;
	submitEndpointUrl_exists?: InputMaybe<Scalars['Boolean']>;
	submitEndpointUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	submitEndpointUrl_not?: InputMaybe<Scalars['String']>;
	submitEndpointUrl_not_contains?: InputMaybe<Scalars['String']>;
	submitEndpointUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type QuizLinkingCollections = {
	__typename?: 'QuizLinkingCollections';
	buttonCollection?: Maybe<ButtonCollection>;
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type QuizLinkingCollectionsButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuizLinkingCollectionsButtonCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuizLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuizLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<QuizLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type QuizLinkingCollectionsButtonCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type QuizLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type QuizOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'quizType_ASC'
	| 'quizType_DESC'
	| 'submitEndpointUrl_ASC'
	| 'submitEndpointUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type RescheduleSlot = {
	__typename?: 'RescheduleSlot';
	timeslot: Scalars['DateTime'];
	timeslotUid: Scalars['String'];
};

export type RescheduleSlotsFilter = {
	dateFrom: Scalars['Date'];
	dateTo: Scalars['Date'];
	daysOfWeek?: InputMaybe<Array<DayOfWeek>>;
	partOfDay?: InputMaybe<PartOfDay>;
	scheduledVisitId: Scalars['String'];
};

export type RescheduleSlotsList = {
	__typename?: 'RescheduleSlotsList';
	items: Array<RescheduleSlot>;
	total: Scalars['Int'];
};

export type RescheduleVisitError =
	| NonExistingVisitError
	| NonReschedulableVisitError
	| NotAvailableSlotError
	| TemporaryUnavailableError;

export type RescheduleVisitInput = {
	timeslotUid: Scalars['String'];
	visitId: Scalars['String'];
};

export type RescheduleVisitPayload = {
	__typename?: 'RescheduleVisitPayload';
	errors?: Maybe<Array<RescheduleVisitError>>;
	rescheduledVisit?: Maybe<RescheduledVisit>;
};

export type RescheduledVisit = {
	__typename?: 'RescheduledVisit';
	facilityCode: Scalars['String'];
	timeslot: Scalars['DateTime'];
	visitId: Scalars['String'];
};

export type ResourceLink = {
	sys: ResourceSys;
};

export type ResourceSys = {
	__typename?: 'ResourceSys';
	linkType: Scalars['String'];
	urn: Scalars['String'];
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContent = Entry &
	_Node & {
		__typename?: 'ReusableContent';
		_id: Scalars['ID'];
		brand?: Maybe<Brand>;
		component?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		disclaimer?: Maybe<Scalars['String']>;
		imageValue?: Maybe<Scalars['JSON']>;
		linkedFrom?: Maybe<ReusableContentLinkingCollections>;
		mainDescription?: Maybe<ReusableContentMainDescription>;
		mainImage?: Maybe<ImageAsset>;
		mainTitle?: Maybe<Scalars['String']>;
		mainVideo?: Maybe<VideoAsset>;
		name?: Maybe<Scalars['String']>;
		richTextValue?: Maybe<ReusableContentRichTextValue>;
		sys: Sys;
		table?: Maybe<Scalars['JSON']>;
		textValue?: Maybe<Scalars['String']>;
		type?: Maybe<Scalars['String']>;
		videoValue?: Maybe<Scalars['JSON']>;
	};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentBrandArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<BrandFilter>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentComponentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentDisclaimerArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentImageValueArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentMainDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentMainImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentMainTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentMainVideoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoAssetFilter>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentRichTextValueArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentTableArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentTextValueArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content that can be called by FE and used from a given component while being decoupled from site/page structure. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reusableContent) */
export type ReusableContentVideoValueArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ReusableContentCollection = {
	__typename?: 'ReusableContentCollection';
	items: Array<Maybe<ReusableContent>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ReusableContentFilter = {
	AND?: InputMaybe<Array<InputMaybe<ReusableContentFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ReusableContentFilter>>>;
	brand?: InputMaybe<CfBrandNestedFilter>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	component?: InputMaybe<Scalars['String']>;
	component_contains?: InputMaybe<Scalars['String']>;
	component_exists?: InputMaybe<Scalars['Boolean']>;
	component_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	component_not?: InputMaybe<Scalars['String']>;
	component_not_contains?: InputMaybe<Scalars['String']>;
	component_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disclaimer?: InputMaybe<Scalars['String']>;
	disclaimer_contains?: InputMaybe<Scalars['String']>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	disclaimer_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disclaimer_not?: InputMaybe<Scalars['String']>;
	disclaimer_not_contains?: InputMaybe<Scalars['String']>;
	disclaimer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageValue_exists?: InputMaybe<Scalars['Boolean']>;
	mainDescription_contains?: InputMaybe<Scalars['String']>;
	mainDescription_exists?: InputMaybe<Scalars['Boolean']>;
	mainDescription_not_contains?: InputMaybe<Scalars['String']>;
	mainImage?: InputMaybe<CfImageAssetNestedFilter>;
	mainImage_exists?: InputMaybe<Scalars['Boolean']>;
	mainTitle?: InputMaybe<Scalars['String']>;
	mainTitle_contains?: InputMaybe<Scalars['String']>;
	mainTitle_exists?: InputMaybe<Scalars['Boolean']>;
	mainTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mainTitle_not?: InputMaybe<Scalars['String']>;
	mainTitle_not_contains?: InputMaybe<Scalars['String']>;
	mainTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mainVideo?: InputMaybe<CfVideoAssetNestedFilter>;
	mainVideo_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	richTextValue_contains?: InputMaybe<Scalars['String']>;
	richTextValue_exists?: InputMaybe<Scalars['Boolean']>;
	richTextValue_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	table_exists?: InputMaybe<Scalars['Boolean']>;
	textValue?: InputMaybe<Scalars['String']>;
	textValue_contains?: InputMaybe<Scalars['String']>;
	textValue_exists?: InputMaybe<Scalars['Boolean']>;
	textValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	textValue_not?: InputMaybe<Scalars['String']>;
	textValue_not_contains?: InputMaybe<Scalars['String']>;
	textValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type?: InputMaybe<Scalars['String']>;
	type_contains?: InputMaybe<Scalars['String']>;
	type_exists?: InputMaybe<Scalars['Boolean']>;
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type_not?: InputMaybe<Scalars['String']>;
	type_not_contains?: InputMaybe<Scalars['String']>;
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	videoValue_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ReusableContentLinkingCollections = {
	__typename?: 'ReusableContentLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type ReusableContentLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ReusableContentMainDescription = {
	__typename?: 'ReusableContentMainDescription';
	json: Scalars['JSON'];
	links: ReusableContentMainDescriptionLinks;
};

export type ReusableContentMainDescriptionAssets = {
	__typename?: 'ReusableContentMainDescriptionAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ReusableContentMainDescriptionEntries = {
	__typename?: 'ReusableContentMainDescriptionEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ReusableContentMainDescriptionLinks = {
	__typename?: 'ReusableContentMainDescriptionLinks';
	assets: ReusableContentMainDescriptionAssets;
	entries: ReusableContentMainDescriptionEntries;
	resources: ReusableContentMainDescriptionResources;
};

export type ReusableContentMainDescriptionResources = {
	__typename?: 'ReusableContentMainDescriptionResources';
	block: Array<ReusableContentMainDescriptionResourcesBlock>;
	hyperlink: Array<ReusableContentMainDescriptionResourcesHyperlink>;
	inline: Array<ReusableContentMainDescriptionResourcesInline>;
};

export type ReusableContentMainDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'ReusableContentMainDescriptionResourcesBlock';
	sys: ResourceSys;
};

export type ReusableContentMainDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'ReusableContentMainDescriptionResourcesHyperlink';
	sys: ResourceSys;
};

export type ReusableContentMainDescriptionResourcesInline = ResourceLink & {
	__typename?: 'ReusableContentMainDescriptionResourcesInline';
	sys: ResourceSys;
};

export type ReusableContentOrder =
	| 'component_ASC'
	| 'component_DESC'
	| 'disclaimer_ASC'
	| 'disclaimer_DESC'
	| 'mainTitle_ASC'
	| 'mainTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'textValue_ASC'
	| 'textValue_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type ReusableContentRichTextValue = {
	__typename?: 'ReusableContentRichTextValue';
	json: Scalars['JSON'];
	links: ReusableContentRichTextValueLinks;
};

export type ReusableContentRichTextValueAssets = {
	__typename?: 'ReusableContentRichTextValueAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ReusableContentRichTextValueEntries = {
	__typename?: 'ReusableContentRichTextValueEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ReusableContentRichTextValueLinks = {
	__typename?: 'ReusableContentRichTextValueLinks';
	assets: ReusableContentRichTextValueAssets;
	entries: ReusableContentRichTextValueEntries;
	resources: ReusableContentRichTextValueResources;
};

export type ReusableContentRichTextValueResources = {
	__typename?: 'ReusableContentRichTextValueResources';
	block: Array<ReusableContentRichTextValueResourcesBlock>;
	hyperlink: Array<ReusableContentRichTextValueResourcesHyperlink>;
	inline: Array<ReusableContentRichTextValueResourcesInline>;
};

export type ReusableContentRichTextValueResourcesBlock = ResourceLink & {
	__typename?: 'ReusableContentRichTextValueResourcesBlock';
	sys: ResourceSys;
};

export type ReusableContentRichTextValueResourcesHyperlink = ResourceLink & {
	__typename?: 'ReusableContentRichTextValueResourcesHyperlink';
	sys: ResourceSys;
};

export type ReusableContentRichTextValueResourcesInline = ResourceLink & {
	__typename?: 'ReusableContentRichTextValueResourcesInline';
	sys: ResourceSys;
};

export type Review = {
	__typename?: 'Review';
	comments?: Maybe<Scalars['String']>;
	rating: Scalars['Decimal'];
	reviewId: Scalars['String'];
	reviewTimestamp?: Maybe<Scalars['DateTime']>;
	reviewer?: Maybe<Reviewer>;
	source?: Maybe<Scalars['String']>;
	tags?: Maybe<Array<Scalars['String']>>;
	title?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewCard) */
export type ReviewCard = Entry &
	_Node & {
		__typename?: 'ReviewCard';
		_id: Scalars['ID'];
		cardName?: Maybe<Scalars['String']>;
		cardTitle?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		editButtonText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ReviewCardLinkingCollections>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewCard) */
export type ReviewCardCardNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewCard) */
export type ReviewCardCardTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewCard) */
export type ReviewCardEditButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewCard) */
export type ReviewCardLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReviewCardCollection = {
	__typename?: 'ReviewCardCollection';
	items: Array<Maybe<ReviewCard>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ReviewCardFilter = {
	AND?: InputMaybe<Array<InputMaybe<ReviewCardFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ReviewCardFilter>>>;
	cardName?: InputMaybe<Scalars['String']>;
	cardName_contains?: InputMaybe<Scalars['String']>;
	cardName_exists?: InputMaybe<Scalars['Boolean']>;
	cardName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardName_not?: InputMaybe<Scalars['String']>;
	cardName_not_contains?: InputMaybe<Scalars['String']>;
	cardName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardTitle?: InputMaybe<Scalars['String']>;
	cardTitle_contains?: InputMaybe<Scalars['String']>;
	cardTitle_exists?: InputMaybe<Scalars['Boolean']>;
	cardTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardTitle_not?: InputMaybe<Scalars['String']>;
	cardTitle_not_contains?: InputMaybe<Scalars['String']>;
	cardTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	editButtonText?: InputMaybe<Scalars['String']>;
	editButtonText_contains?: InputMaybe<Scalars['String']>;
	editButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	editButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	editButtonText_not?: InputMaybe<Scalars['String']>;
	editButtonText_not_contains?: InputMaybe<Scalars['String']>;
	editButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ReviewCardLinkingCollections = {
	__typename?: 'ReviewCardLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	reviewStepCollection?: Maybe<ReviewStepCollection>;
};

export type ReviewCardLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ReviewCardLinkingCollectionsReviewStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ReviewCardLinkingCollectionsReviewStepCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ReviewCardLinkingCollectionsReviewStepCollectionOrder =
	| 'reviewStepName_ASC'
	| 'reviewStepName_DESC'
	| 'stepNumber_ASC'
	| 'stepNumber_DESC'
	| 'stepSubtitle_ASC'
	| 'stepSubtitle_DESC'
	| 'stepTitle_ASC'
	| 'stepTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ReviewCardOrder =
	| 'cardName_ASC'
	| 'cardName_DESC'
	| 'cardTitle_ASC'
	| 'cardTitle_DESC'
	| 'editButtonText_ASC'
	| 'editButtonText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ReviewRating = {
	__typename?: 'ReviewRating';
	rating: Scalars['Int'];
	reviewCount: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewStep) */
export type ReviewStep = Entry &
	_Node & {
		__typename?: 'ReviewStep';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<ReviewStepLinkingCollections>;
		reviewCardsCollection?: Maybe<ReviewStepReviewCardsCollection>;
		reviewStepName?: Maybe<Scalars['String']>;
		stepNumber?: Maybe<Scalars['Int']>;
		stepSubtitle?: Maybe<Scalars['String']>;
		stepTitle?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewStep) */
export type ReviewStepLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewStep) */
export type ReviewStepReviewCardsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ReviewStepReviewCardsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ReviewCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewStep) */
export type ReviewStepReviewStepNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewStep) */
export type ReviewStepStepNumberArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewStep) */
export type ReviewStepStepSubtitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/reviewStep) */
export type ReviewStepStepTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ReviewStepCollection = {
	__typename?: 'ReviewStepCollection';
	items: Array<Maybe<ReviewStep>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ReviewStepFilter = {
	AND?: InputMaybe<Array<InputMaybe<ReviewStepFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ReviewStepFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	reviewCards?: InputMaybe<CfReviewCardNestedFilter>;
	reviewCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	reviewStepName?: InputMaybe<Scalars['String']>;
	reviewStepName_contains?: InputMaybe<Scalars['String']>;
	reviewStepName_exists?: InputMaybe<Scalars['Boolean']>;
	reviewStepName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	reviewStepName_not?: InputMaybe<Scalars['String']>;
	reviewStepName_not_contains?: InputMaybe<Scalars['String']>;
	reviewStepName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepNumber?: InputMaybe<Scalars['Int']>;
	stepNumber_exists?: InputMaybe<Scalars['Boolean']>;
	stepNumber_gt?: InputMaybe<Scalars['Int']>;
	stepNumber_gte?: InputMaybe<Scalars['Int']>;
	stepNumber_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	stepNumber_lt?: InputMaybe<Scalars['Int']>;
	stepNumber_lte?: InputMaybe<Scalars['Int']>;
	stepNumber_not?: InputMaybe<Scalars['Int']>;
	stepNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	stepSubtitle?: InputMaybe<Scalars['String']>;
	stepSubtitle_contains?: InputMaybe<Scalars['String']>;
	stepSubtitle_exists?: InputMaybe<Scalars['Boolean']>;
	stepSubtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepSubtitle_not?: InputMaybe<Scalars['String']>;
	stepSubtitle_not_contains?: InputMaybe<Scalars['String']>;
	stepSubtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepTitle?: InputMaybe<Scalars['String']>;
	stepTitle_contains?: InputMaybe<Scalars['String']>;
	stepTitle_exists?: InputMaybe<Scalars['Boolean']>;
	stepTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepTitle_not?: InputMaybe<Scalars['String']>;
	stepTitle_not_contains?: InputMaybe<Scalars['String']>;
	stepTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ReviewStepLinkingCollections = {
	__typename?: 'ReviewStepLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	stepperCollection?: Maybe<StepperCollection>;
};

export type ReviewStepLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ReviewStepLinkingCollectionsStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ReviewStepLinkingCollectionsStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ReviewStepLinkingCollectionsStepperCollectionOrder =
	| 'confirmButtonText_ASC'
	| 'confirmButtonText_DESC'
	| 'continueButtonText_ASC'
	| 'continueButtonText_DESC'
	| 'headingScheduledText_ASC'
	| 'headingScheduledText_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'needAssistanceText_ASC'
	| 'needAssistanceText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ReviewStepOrder =
	| 'reviewStepName_ASC'
	| 'reviewStepName_DESC'
	| 'stepNumber_ASC'
	| 'stepNumber_DESC'
	| 'stepSubtitle_ASC'
	| 'stepSubtitle_DESC'
	| 'stepTitle_ASC'
	| 'stepTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ReviewStepReviewCardsCollection = {
	__typename?: 'ReviewStepReviewCardsCollection';
	items: Array<Maybe<ReviewCard>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ReviewStepReviewCardsCollectionOrder =
	| 'cardName_ASC'
	| 'cardName_DESC'
	| 'cardTitle_ASC'
	| 'cardTitle_DESC'
	| 'editButtonText_ASC'
	| 'editButtonText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ReviewWithSummary = {
	__typename?: 'ReviewWithSummary';
	averageRating?: Maybe<Scalars['Decimal']>;
	businessId?: Maybe<Scalars['String']>;
	ratings?: Maybe<Array<ReviewRating>>;
	reviewCount?: Maybe<Scalars['Int']>;
	reviews?: Maybe<Array<Review>>;
};

export type Reviewer = {
	__typename?: 'Reviewer';
	firstName?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richTextBlockSeo) */
export type RichTextBlockSeo = Entry &
	_Node & {
		__typename?: 'RichTextBlockSeo';
		_id: Scalars['ID'];
		content?: Maybe<ContentTypeRichText>;
		contentfulMetadata: ContentfulMetadata;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<RichTextBlockSeoLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richTextBlockSeo) */
export type RichTextBlockSeoContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentTypeRichTextFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richTextBlockSeo) */
export type RichTextBlockSeoDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richTextBlockSeo) */
export type RichTextBlockSeoFacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richTextBlockSeo) */
export type RichTextBlockSeoLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/richTextBlockSeo) */
export type RichTextBlockSeoNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type RichTextBlockSeoCollection = {
	__typename?: 'RichTextBlockSeoCollection';
	items: Array<Maybe<RichTextBlockSeo>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type RichTextBlockSeoFilter = {
	AND?: InputMaybe<Array<InputMaybe<RichTextBlockSeoFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<RichTextBlockSeoFilter>>>;
	content?: InputMaybe<CfContentTypeRichTextNestedFilter>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type RichTextBlockSeoLinkingCollections = {
	__typename?: 'RichTextBlockSeoLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type RichTextBlockSeoLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type RichTextBlockSeoLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<RichTextBlockSeoLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type RichTextBlockSeoLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<RichTextBlockSeoLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type RichTextBlockSeoLinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<RichTextBlockSeoLinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type RichTextBlockSeoLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<RichTextBlockSeoLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type RichTextBlockSeoLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type RichTextBlockSeoLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type RichTextBlockSeoLinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type RichTextBlockSeoLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type RichTextBlockSeoOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ScheduleNewPatientVisitError =
	| GuarantorRequiredError
	| InvalidRelationToGuarantorError
	| SchedulingPartiallySucceededError
	| TimeslotNotAvailableError
	| UnderageGuarantorError
	| UnderagePatientForFacilityError
	| UnderagePatientForVisitReasonError;

export type ScheduleNewPatientVisitInput = {
	facilityCode: Scalars['NonEmptyString'];
	hasInsurance: Scalars['Boolean'];
	patient: ScheduleNewPatientVisitPatientInput;
	questionnaireAnswers?: InputMaybe<QuestionnaireAnswersInput>;
	scheduleSlotUid: Scalars['String'];
	visitAttemptId?: InputMaybe<Scalars['UUID']>;
};

export type ScheduleNewPatientVisitPatientInput = {
	nonExistingNewPatient?: InputMaybe<NewPatientInput>;
};

export type ScheduleNewPatientVisitPayload = {
	__typename?: 'ScheduleNewPatientVisitPayload';
	errors?: Maybe<Array<ScheduleNewPatientVisitError>>;
	result?: Maybe<ScheduledNewPatientVisit>;
};

export type ScheduleSlot = {
	__typename?: 'ScheduleSlot';
	nationalPatientTier?: Maybe<Scalars['Int']>;
	patientTier?: Maybe<Scalars['Int']>;
	patientValue?: Maybe<Scalars['Decimal']>;
	timeslot: Scalars['DateTime'];
	timeslotUid: Scalars['String'];
};

export type ScheduleSlotsList = {
	__typename?: 'ScheduleSlotsList';
	items: Array<ScheduleSlot>;
	total: Scalars['Int'];
};

export type ScheduledNewPatientVisit = {
	__typename?: 'ScheduledNewPatientVisit';
	accountNumber: Scalars['String'];
	facilityCode: Scalars['String'];
	timeslotUid: Scalars['String'];
	visitId: Scalars['String'];
};

export type ScheduledVisit = {
	__typename?: 'ScheduledVisit';
	canBeCanceled?: Maybe<Scalars['Boolean']>;
	canBeRescheduled?: Maybe<Scalars['Boolean']>;
	classDescription?: Maybe<Scalars['String']>;
	/** Visit duration in minutes. */
	duration?: Maybe<Scalars['Int']>;
	facility?: Maybe<Facility>;
	scheduledDateTime?: Maybe<Scalars['DateTime']>;
	visitId: Scalars['String'];
	visitNumber: Scalars['String'];
};

export type ScheduledVisitList = {
	__typename?: 'ScheduledVisitList';
	items: Array<ScheduledVisit>;
	total: Scalars['Int'];
};

export type SchedulingPartiallySucceededError = Error & {
	__typename?: 'SchedulingPartiallySucceededError';
	message: Scalars['String'];
	patient: PatientIdentifiers;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schema) */
export type Schema = Entry &
	_Node & {
		__typename?: 'Schema';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<SchemaLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		schema?: Maybe<Scalars['JSON']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schema) */
export type SchemaLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schema) */
export type SchemaNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schema) */
export type SchemaSchemaArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SchemaCollection = {
	__typename?: 'SchemaCollection';
	items: Array<Maybe<Schema>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SchemaFilter = {
	AND?: InputMaybe<Array<InputMaybe<SchemaFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SchemaFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schema_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type SchemaLinkingCollections = {
	__typename?: 'SchemaLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type SchemaLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SchemaLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SchemaLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SchemaLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SchemaLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SchemaLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SchemaLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SchemaLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SchemaLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SchemaLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SchemaOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schemaStep) */
export type SchemaStep = Entry &
	_Node & {
		__typename?: 'SchemaStep';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		formFieldCollection?: Maybe<SchemaStepFormFieldCollection>;
		formTitle?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<SchemaStepLinkingCollections>;
		stepNumber?: Maybe<Scalars['Int']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schemaStep) */
export type SchemaStepFormFieldCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SchemaStepFormFieldFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schemaStep) */
export type SchemaStepFormTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schemaStep) */
export type SchemaStepLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/schemaStep) */
export type SchemaStepStepNumberArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SchemaStepCollection = {
	__typename?: 'SchemaStepCollection';
	items: Array<Maybe<SchemaStep>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SchemaStepFilter = {
	AND?: InputMaybe<Array<InputMaybe<SchemaStepFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SchemaStepFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	formField?: InputMaybe<CfformFieldMultiTypeNestedFilter>;
	formFieldCollection_exists?: InputMaybe<Scalars['Boolean']>;
	formTitle?: InputMaybe<Scalars['String']>;
	formTitle_contains?: InputMaybe<Scalars['String']>;
	formTitle_exists?: InputMaybe<Scalars['Boolean']>;
	formTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formTitle_not?: InputMaybe<Scalars['String']>;
	formTitle_not_contains?: InputMaybe<Scalars['String']>;
	formTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepNumber?: InputMaybe<Scalars['Int']>;
	stepNumber_exists?: InputMaybe<Scalars['Boolean']>;
	stepNumber_gt?: InputMaybe<Scalars['Int']>;
	stepNumber_gte?: InputMaybe<Scalars['Int']>;
	stepNumber_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	stepNumber_lt?: InputMaybe<Scalars['Int']>;
	stepNumber_lte?: InputMaybe<Scalars['Int']>;
	stepNumber_not?: InputMaybe<Scalars['Int']>;
	stepNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type SchemaStepFormFieldCollection = {
	__typename?: 'SchemaStepFormFieldCollection';
	items: Array<Maybe<SchemaStepFormFieldItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SchemaStepFormFieldFilter = {
	AND?: InputMaybe<Array<InputMaybe<SchemaStepFormFieldFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SchemaStepFormFieldFilter>>>;
	alertMessage?: InputMaybe<Scalars['String']>;
	alertMessage_contains?: InputMaybe<Scalars['String']>;
	alertMessage_exists?: InputMaybe<Scalars['Boolean']>;
	alertMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	alertMessage_not?: InputMaybe<Scalars['String']>;
	alertMessage_not_contains?: InputMaybe<Scalars['String']>;
	alertMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiKey?: InputMaybe<Scalars['String']>;
	apiKey_contains?: InputMaybe<Scalars['String']>;
	apiKey_exists?: InputMaybe<Scalars['Boolean']>;
	apiKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiKey_not?: InputMaybe<Scalars['String']>;
	apiKey_not_contains?: InputMaybe<Scalars['String']>;
	apiKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	required?: InputMaybe<Scalars['Boolean']>;
	required_exists?: InputMaybe<Scalars['Boolean']>;
	required_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SchemaStepFormFieldItem = FormDropdownField | FormField;

export type SchemaStepLinkingCollections = {
	__typename?: 'SchemaStepLinkingCollections';
	columnsCollection?: Maybe<ColumnsCollection>;
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
	stepperCollection?: Maybe<StepperCollection>;
};

export type SchemaStepLinkingCollectionsColumnsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SchemaStepLinkingCollectionsColumnsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SchemaStepLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SchemaStepLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SchemaStepLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SchemaStepLinkingCollectionsStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SchemaStepLinkingCollectionsStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SchemaStepLinkingCollectionsColumnsCollectionOrder =
	| 'allowEqualHeights_ASC'
	| 'allowEqualHeights_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'mobileDisplayAccordion_ASC'
	| 'mobileDisplayAccordion_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SchemaStepLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SchemaStepLinkingCollectionsStepperCollectionOrder =
	| 'confirmButtonText_ASC'
	| 'confirmButtonText_DESC'
	| 'continueButtonText_ASC'
	| 'continueButtonText_DESC'
	| 'headingScheduledText_ASC'
	| 'headingScheduledText_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'needAssistanceText_ASC'
	| 'needAssistanceText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type SchemaStepOrder =
	| 'formTitle_ASC'
	| 'formTitle_DESC'
	| 'stepNumber_ASC'
	| 'stepNumber_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type SearchFacilitiesFilterInput = {
	brand?: InputMaybe<FacilityBrand>;
	term: Scalars['String'];
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHero = Entry &
	_Node & {
		__typename?: 'SecondaryHero';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		body?: Maybe<Scalars['String']>;
		bottomContent?: Maybe<SectionContent>;
		contentfulMetadata: ContentfulMetadata;
		ctaStyle?: Maybe<Scalars['String']>;
		ctaText?: Maybe<Scalars['String']>;
		ctaUrl?: Maybe<Scalars['String']>;
		eyebrow?: Maybe<Scalars['String']>;
		focalPointImage?: Maybe<FocalPointImage>;
		heading?: Maybe<Scalars['String']>;
		imagePositionRight?: Maybe<Scalars['Boolean']>;
		imageRoundCornerMobile?: Maybe<Scalars['String']>;
		imageRoundedBottomDesktop?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<SecondaryHeroLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		stickyImage?: Maybe<Scalars['Boolean']>;
		subheading?: Maybe<Scalars['String']>;
		subheadingText?: Maybe<SecondaryHeroSubheadingText>;
		sys: Sys;
		themeColor?: Maybe<Scalars['String']>;
	};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroBodyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroBottomContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<SectionContentFilter>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroCtaStyleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroCtaTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroCtaUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroFocalPointImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroHeadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroImagePositionRightArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroImageRoundCornerMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroImageRoundedBottomDesktopArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroStickyImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroSubheadingArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroSubheadingTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** MottoV2 Hero [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/secondaryHero) */
export type SecondaryHeroThemeColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SecondaryHeroCollection = {
	__typename?: 'SecondaryHeroCollection';
	items: Array<Maybe<SecondaryHero>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SecondaryHeroFilter = {
	AND?: InputMaybe<Array<InputMaybe<SecondaryHeroFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SecondaryHeroFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	body?: InputMaybe<Scalars['String']>;
	body_contains?: InputMaybe<Scalars['String']>;
	body_exists?: InputMaybe<Scalars['Boolean']>;
	body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	body_not?: InputMaybe<Scalars['String']>;
	body_not_contains?: InputMaybe<Scalars['String']>;
	body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bottomContent?: InputMaybe<CfSectionContentNestedFilter>;
	bottomContent_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	ctaStyle?: InputMaybe<Scalars['String']>;
	ctaStyle_contains?: InputMaybe<Scalars['String']>;
	ctaStyle_exists?: InputMaybe<Scalars['Boolean']>;
	ctaStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ctaStyle_not?: InputMaybe<Scalars['String']>;
	ctaStyle_not_contains?: InputMaybe<Scalars['String']>;
	ctaStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ctaText?: InputMaybe<Scalars['String']>;
	ctaText_contains?: InputMaybe<Scalars['String']>;
	ctaText_exists?: InputMaybe<Scalars['Boolean']>;
	ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ctaText_not?: InputMaybe<Scalars['String']>;
	ctaText_not_contains?: InputMaybe<Scalars['String']>;
	ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ctaUrl?: InputMaybe<Scalars['String']>;
	ctaUrl_contains?: InputMaybe<Scalars['String']>;
	ctaUrl_exists?: InputMaybe<Scalars['Boolean']>;
	ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ctaUrl_not?: InputMaybe<Scalars['String']>;
	ctaUrl_not_contains?: InputMaybe<Scalars['String']>;
	ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImage?: InputMaybe<CfFocalPointImageNestedFilter>;
	focalPointImage_exists?: InputMaybe<Scalars['Boolean']>;
	heading?: InputMaybe<Scalars['String']>;
	heading_contains?: InputMaybe<Scalars['String']>;
	heading_exists?: InputMaybe<Scalars['Boolean']>;
	heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	heading_not?: InputMaybe<Scalars['String']>;
	heading_not_contains?: InputMaybe<Scalars['String']>;
	heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imagePositionRight?: InputMaybe<Scalars['Boolean']>;
	imagePositionRight_exists?: InputMaybe<Scalars['Boolean']>;
	imagePositionRight_not?: InputMaybe<Scalars['Boolean']>;
	imageRoundCornerMobile?: InputMaybe<Scalars['String']>;
	imageRoundCornerMobile_contains?: InputMaybe<Scalars['String']>;
	imageRoundCornerMobile_exists?: InputMaybe<Scalars['Boolean']>;
	imageRoundCornerMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageRoundCornerMobile_not?: InputMaybe<Scalars['String']>;
	imageRoundCornerMobile_not_contains?: InputMaybe<Scalars['String']>;
	imageRoundCornerMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageRoundedBottomDesktop?: InputMaybe<Scalars['Boolean']>;
	imageRoundedBottomDesktop_exists?: InputMaybe<Scalars['Boolean']>;
	imageRoundedBottomDesktop_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stickyImage?: InputMaybe<Scalars['Boolean']>;
	stickyImage_exists?: InputMaybe<Scalars['Boolean']>;
	stickyImage_not?: InputMaybe<Scalars['Boolean']>;
	subheading?: InputMaybe<Scalars['String']>;
	subheadingText_contains?: InputMaybe<Scalars['String']>;
	subheadingText_exists?: InputMaybe<Scalars['Boolean']>;
	subheadingText_not_contains?: InputMaybe<Scalars['String']>;
	subheading_contains?: InputMaybe<Scalars['String']>;
	subheading_exists?: InputMaybe<Scalars['Boolean']>;
	subheading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subheading_not?: InputMaybe<Scalars['String']>;
	subheading_not_contains?: InputMaybe<Scalars['String']>;
	subheading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	themeColor?: InputMaybe<Scalars['String']>;
	themeColor_contains?: InputMaybe<Scalars['String']>;
	themeColor_exists?: InputMaybe<Scalars['Boolean']>;
	themeColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	themeColor_not?: InputMaybe<Scalars['String']>;
	themeColor_not_contains?: InputMaybe<Scalars['String']>;
	themeColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SecondaryHeroLinkingCollections = {
	__typename?: 'SecondaryHeroLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type SecondaryHeroLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SecondaryHeroLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SecondaryHeroLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SecondaryHeroLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SecondaryHeroOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'body_ASC'
	| 'body_DESC'
	| 'ctaStyle_ASC'
	| 'ctaStyle_DESC'
	| 'ctaText_ASC'
	| 'ctaText_DESC'
	| 'ctaUrl_ASC'
	| 'ctaUrl_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'heading_ASC'
	| 'heading_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundCornerMobile_ASC'
	| 'imageRoundCornerMobile_DESC'
	| 'imageRoundedBottomDesktop_ASC'
	| 'imageRoundedBottomDesktop_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'stickyImage_ASC'
	| 'stickyImage_DESC'
	| 'subheading_ASC'
	| 'subheading_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'themeColor_ASC'
	| 'themeColor_DESC';

export type SecondaryHeroSubheadingText = {
	__typename?: 'SecondaryHeroSubheadingText';
	json: Scalars['JSON'];
	links: SecondaryHeroSubheadingTextLinks;
};

export type SecondaryHeroSubheadingTextAssets = {
	__typename?: 'SecondaryHeroSubheadingTextAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type SecondaryHeroSubheadingTextEntries = {
	__typename?: 'SecondaryHeroSubheadingTextEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type SecondaryHeroSubheadingTextLinks = {
	__typename?: 'SecondaryHeroSubheadingTextLinks';
	assets: SecondaryHeroSubheadingTextAssets;
	entries: SecondaryHeroSubheadingTextEntries;
	resources: SecondaryHeroSubheadingTextResources;
};

export type SecondaryHeroSubheadingTextResources = {
	__typename?: 'SecondaryHeroSubheadingTextResources';
	block: Array<SecondaryHeroSubheadingTextResourcesBlock>;
	hyperlink: Array<SecondaryHeroSubheadingTextResourcesHyperlink>;
	inline: Array<SecondaryHeroSubheadingTextResourcesInline>;
};

export type SecondaryHeroSubheadingTextResourcesBlock = ResourceLink & {
	__typename?: 'SecondaryHeroSubheadingTextResourcesBlock';
	sys: ResourceSys;
};

export type SecondaryHeroSubheadingTextResourcesHyperlink = ResourceLink & {
	__typename?: 'SecondaryHeroSubheadingTextResourcesHyperlink';
	sys: ResourceSys;
};

export type SecondaryHeroSubheadingTextResourcesInline = ResourceLink & {
	__typename?: 'SecondaryHeroSubheadingTextResourcesInline';
	sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type Section = Entry &
	_Node & {
		__typename?: 'Section';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		contentCollection?: Maybe<SectionContentCollection>;
		contentfulMetadata: ContentfulMetadata;
		eyebrow?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<SectionLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		orientation?: Maybe<Scalars['String']>;
		sys: Sys;
		textColor?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
		typeTemplate?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionOrientationArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionTextColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/section) */
export type SectionTypeTemplateArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SectionCollection = {
	__typename?: 'SectionCollection';
	items: Array<Maybe<Section>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContent = Entry &
	_Node & {
		__typename?: 'SectionContent';
		_id: Scalars['ID'];
		aditionalReferencesCollection?: Maybe<SectionContentAditionalReferencesCollection>;
		buttonText?: Maybe<Scalars['String']>;
		buttonUrl?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		eyebrow?: Maybe<Scalars['String']>;
		image?: Maybe<FocalPointImage>;
		imageShape?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<SectionContentLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		orientation?: Maybe<Scalars['String']>;
		richTextContent?: Maybe<SectionContentRichTextContent>;
		subTitle?: Maybe<Scalars['String']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		type?: Maybe<Scalars['String']>;
	};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentAditionalReferencesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentImageShapeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentOrientationArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentRichTextContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentSubTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Should not be used anymore [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sectionContent) */
export type SectionContentTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SectionContentAditionalReferencesCollection = {
	__typename?: 'SectionContentAditionalReferencesCollection';
	items: Array<Maybe<Entry>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SectionContentCollection = {
	__typename?: 'SectionContentCollection';
	items: Array<Maybe<SectionContent>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SectionContentFilter = {
	AND?: InputMaybe<Array<InputMaybe<SectionContentFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SectionContentFilter>>>;
	aditionalReferencesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image?: InputMaybe<CfFocalPointImageNestedFilter>;
	imageShape?: InputMaybe<Scalars['String']>;
	imageShape_contains?: InputMaybe<Scalars['String']>;
	imageShape_exists?: InputMaybe<Scalars['Boolean']>;
	imageShape_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageShape_not?: InputMaybe<Scalars['String']>;
	imageShape_not_contains?: InputMaybe<Scalars['String']>;
	imageShape_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	orientation?: InputMaybe<Scalars['String']>;
	orientation_contains?: InputMaybe<Scalars['String']>;
	orientation_exists?: InputMaybe<Scalars['Boolean']>;
	orientation_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	orientation_not?: InputMaybe<Scalars['String']>;
	orientation_not_contains?: InputMaybe<Scalars['String']>;
	orientation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	richTextContent_contains?: InputMaybe<Scalars['String']>;
	richTextContent_exists?: InputMaybe<Scalars['Boolean']>;
	richTextContent_not_contains?: InputMaybe<Scalars['String']>;
	subTitle?: InputMaybe<Scalars['String']>;
	subTitle_contains?: InputMaybe<Scalars['String']>;
	subTitle_exists?: InputMaybe<Scalars['Boolean']>;
	subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subTitle_not?: InputMaybe<Scalars['String']>;
	subTitle_not_contains?: InputMaybe<Scalars['String']>;
	subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type?: InputMaybe<Scalars['String']>;
	type_contains?: InputMaybe<Scalars['String']>;
	type_exists?: InputMaybe<Scalars['Boolean']>;
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type_not?: InputMaybe<Scalars['String']>;
	type_not_contains?: InputMaybe<Scalars['String']>;
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SectionContentLinkingCollections = {
	__typename?: 'SectionContentLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
	questionnaireCollection?: Maybe<QuestionnaireCollection>;
	secondaryHeroCollection?: Maybe<SecondaryHeroCollection>;
};

export type SectionContentLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SectionContentLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SectionContentLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SectionContentLinkingCollectionsQuestionnaireCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SectionContentLinkingCollectionsQuestionnaireCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SectionContentLinkingCollectionsSecondaryHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SectionContentLinkingCollectionsSecondaryHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SectionContentLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SectionContentLinkingCollectionsQuestionnaireCollectionOrder =
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'defaultErrorMessage_ASC'
	| 'defaultErrorMessage_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subTitle_ASC'
	| 'subTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'text_ASC'
	| 'text_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SectionContentLinkingCollectionsSecondaryHeroCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'body_ASC'
	| 'body_DESC'
	| 'ctaStyle_ASC'
	| 'ctaStyle_DESC'
	| 'ctaText_ASC'
	| 'ctaText_DESC'
	| 'ctaUrl_ASC'
	| 'ctaUrl_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'heading_ASC'
	| 'heading_DESC'
	| 'imagePositionRight_ASC'
	| 'imagePositionRight_DESC'
	| 'imageRoundCornerMobile_ASC'
	| 'imageRoundCornerMobile_DESC'
	| 'imageRoundedBottomDesktop_ASC'
	| 'imageRoundedBottomDesktop_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'stickyImage_ASC'
	| 'stickyImage_DESC'
	| 'subheading_ASC'
	| 'subheading_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'themeColor_ASC'
	| 'themeColor_DESC';

export type SectionContentOrder =
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageShape_ASC'
	| 'imageShape_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'orientation_ASC'
	| 'orientation_DESC'
	| 'subTitle_ASC'
	| 'subTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type SectionContentRichTextContent = {
	__typename?: 'SectionContentRichTextContent';
	json: Scalars['JSON'];
	links: SectionContentRichTextContentLinks;
};

export type SectionContentRichTextContentAssets = {
	__typename?: 'SectionContentRichTextContentAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type SectionContentRichTextContentEntries = {
	__typename?: 'SectionContentRichTextContentEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type SectionContentRichTextContentLinks = {
	__typename?: 'SectionContentRichTextContentLinks';
	assets: SectionContentRichTextContentAssets;
	entries: SectionContentRichTextContentEntries;
	resources: SectionContentRichTextContentResources;
};

export type SectionContentRichTextContentResources = {
	__typename?: 'SectionContentRichTextContentResources';
	block: Array<SectionContentRichTextContentResourcesBlock>;
	hyperlink: Array<SectionContentRichTextContentResourcesHyperlink>;
	inline: Array<SectionContentRichTextContentResourcesInline>;
};

export type SectionContentRichTextContentResourcesBlock = ResourceLink & {
	__typename?: 'SectionContentRichTextContentResourcesBlock';
	sys: ResourceSys;
};

export type SectionContentRichTextContentResourcesHyperlink = ResourceLink & {
	__typename?: 'SectionContentRichTextContentResourcesHyperlink';
	sys: ResourceSys;
};

export type SectionContentRichTextContentResourcesInline = ResourceLink & {
	__typename?: 'SectionContentRichTextContentResourcesInline';
	sys: ResourceSys;
};

export type SectionFilter = {
	AND?: InputMaybe<Array<InputMaybe<SectionFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SectionFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	orientation?: InputMaybe<Scalars['String']>;
	orientation_contains?: InputMaybe<Scalars['String']>;
	orientation_exists?: InputMaybe<Scalars['Boolean']>;
	orientation_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	orientation_not?: InputMaybe<Scalars['String']>;
	orientation_not_contains?: InputMaybe<Scalars['String']>;
	orientation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	textColor?: InputMaybe<Scalars['String']>;
	textColor_contains?: InputMaybe<Scalars['String']>;
	textColor_exists?: InputMaybe<Scalars['Boolean']>;
	textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	textColor_not?: InputMaybe<Scalars['String']>;
	textColor_not_contains?: InputMaybe<Scalars['String']>;
	textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	typeTemplate?: InputMaybe<Scalars['String']>;
	typeTemplate_contains?: InputMaybe<Scalars['String']>;
	typeTemplate_exists?: InputMaybe<Scalars['Boolean']>;
	typeTemplate_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	typeTemplate_not?: InputMaybe<Scalars['String']>;
	typeTemplate_not_contains?: InputMaybe<Scalars['String']>;
	typeTemplate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SectionLinkingCollections = {
	__typename?: 'SectionLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type SectionLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SectionLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SectionLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SectionLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'orientation_ASC'
	| 'orientation_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'textColor_ASC'
	| 'textColor_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'typeTemplate_ASC'
	| 'typeTemplate_DESC';

/** HeroV2, Service and HalfScreen are supported, but do not combine different templates in one SegmentsContainer [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/segmentsContainer) */
export type SegmentsContainer = Entry &
	_Node & {
		__typename?: 'SegmentsContainer';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		contentsCollection?: Maybe<SegmentsContainerContentsCollection>;
		entryName?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<SegmentsContainerLinkingCollections>;
		sys: Sys;
	};

/** HeroV2, Service and HalfScreen are supported, but do not combine different templates in one SegmentsContainer [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/segmentsContainer) */
export type SegmentsContainerContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SegmentsContainerContentsFilter>;
};

/** HeroV2, Service and HalfScreen are supported, but do not combine different templates in one SegmentsContainer [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/segmentsContainer) */
export type SegmentsContainerEntryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** HeroV2, Service and HalfScreen are supported, but do not combine different templates in one SegmentsContainer [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/segmentsContainer) */
export type SegmentsContainerLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SegmentsContainerCollection = {
	__typename?: 'SegmentsContainerCollection';
	items: Array<Maybe<SegmentsContainer>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SegmentsContainerContentsCollection = {
	__typename?: 'SegmentsContainerContentsCollection';
	items: Array<Maybe<SegmentsContainerContentsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SegmentsContainerContentsFilter = {
	AND?: InputMaybe<Array<InputMaybe<SegmentsContainerContentsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SegmentsContainerContentsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type SegmentsContainerContentsItem =
	| ContentTypeRichText
	| HalfScreen
	| HeroV2
	| MultiCardV2
	| OdpHero
	| RichTextBlockSeo
	| Service
	| ServiceLineHero
	| SingleMessage;

export type SegmentsContainerFilter = {
	AND?: InputMaybe<Array<InputMaybe<SegmentsContainerFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SegmentsContainerFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	entryName?: InputMaybe<Scalars['String']>;
	entryName_contains?: InputMaybe<Scalars['String']>;
	entryName_exists?: InputMaybe<Scalars['Boolean']>;
	entryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	entryName_not?: InputMaybe<Scalars['String']>;
	entryName_not_contains?: InputMaybe<Scalars['String']>;
	entryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type SegmentsContainerLinkingCollections = {
	__typename?: 'SegmentsContainerLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type SegmentsContainerLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SegmentsContainerLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SegmentsContainerLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SegmentsContainerLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SegmentsContainerLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SegmentsContainerLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SegmentsContainerLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SegmentsContainerLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SegmentsContainerLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SegmentsContainerLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SegmentsContainerOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type Service = Entry &
	_Node & {
		__typename?: 'Service';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		brands?: Maybe<Array<FacilityServiceBrand>>;
		buttonText?: Maybe<Scalars['String']>;
		buttonType?: Maybe<Scalars['String']>;
		buttonUrl?: Maybe<Scalars['String']>;
		buttonsCollection?: Maybe<ServiceButtonsCollection>;
		centerAlign?: Maybe<Scalars['Boolean']>;
		contained?: Maybe<Scalars['Boolean']>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		direction?: Maybe<Scalars['String']>;
		eyebrow?: Maybe<Scalars['String']>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		image?: Maybe<Asset>;
		imageLayout?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<ServiceLinkingCollections>;
		mediaType?: Maybe<Scalars['String']>;
		mobileImage?: Maybe<Asset>;
		name?: Maybe<Scalars['String']>;
		richTextDescription?: Maybe<ServiceRichTextDescription>;
		sys: Sys;
		tc?: Maybe<TermsAndConditions>;
		title?: Maybe<Scalars['String']>;
		titleHtag?: Maybe<Scalars['String']>;
		type: FacilityServiceType;
		video?: Maybe<Asset>;
		videoImage?: Maybe<Asset>;
		youTubeVideoId?: Maybe<Scalars['String']>;
		youTubeVideoImage?: Maybe<Asset>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceButtonTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceCenterAlignArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceContainedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceDirectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceFacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceImageLayoutArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceMediaTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceMobileImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceRichTextDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceTcArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<TermsAndConditionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceTitleHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceVideoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceVideoImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceYouTubeVideoIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/service) */
export type ServiceYouTubeVideoImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceButtonsCollection = {
	__typename?: 'ServiceButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type ServiceCollection = {
	__typename?: 'ServiceCollection';
	items: Array<Maybe<Service>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPage = Entry &
	_Node & {
		__typename?: 'ServiceDetailsPage';
		_id: Scalars['ID'];
		analyticsContext?: Maybe<AnalyticsContext>;
		appName?: Maybe<Scalars['String']>;
		canonical?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		contentsCollection?: Maybe<ServiceDetailsPageContentsCollection>;
		description?: Maybe<Scalars['String']>;
		facilityId?: Maybe<Scalars['String']>;
		indexed?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<ServiceDetailsPageLinkingCollections>;
		moreTextSeo?: Maybe<ServiceDetailsPageMoreTextSeo>;
		name?: Maybe<Scalars['String']>;
		path?: Maybe<Scalars['String']>;
		sys: Sys;
		textSeo?: Maybe<ServiceDetailsPageTextSeo>;
		title?: Maybe<Scalars['String']>;
	};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageAnalyticsContextArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AnalyticsContextFilter>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageAppNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageCanonicalArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageContentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ServiceDetailsPageContentsFilter>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageFacilityIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageIndexedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageMoreTextSeoArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPagePathArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageTextSeoArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The content type used to define any Service Details for a brand. Example Dentures, Cosmetic Dentistry, Vaccination etc [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceDetailsPage) */
export type ServiceDetailsPageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ServiceDetailsPageCollection = {
	__typename?: 'ServiceDetailsPageCollection';
	items: Array<Maybe<ServiceDetailsPage>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceDetailsPageContentsCollection = {
	__typename?: 'ServiceDetailsPageContentsCollection';
	items: Array<Maybe<ServiceDetailsPageContentsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceDetailsPageContentsFilter = {
	AND?: InputMaybe<Array<InputMaybe<ServiceDetailsPageContentsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ServiceDetailsPageContentsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type ServiceDetailsPageContentsItem =
	| Accordions
	| BirdEyeReviews
	| CategoryPicker
	| Columns
	| ContentTypeRichText
	| DisclaimerV2
	| GoogleMapTemplate
	| HalfScreen
	| HeroV2
	| MeetOurDoctors
	| MeetOurStaff
	| MultiCardV2
	| OdpHero
	| OdpLocalizedSecondaryFooter
	| PatientReviewsV2
	| QuickLinks
	| RichTextBlockSeo
	| Schema
	| SegmentsContainer
	| SingleMessage
	| Spacer;

export type ServiceDetailsPageFilter = {
	AND?: InputMaybe<Array<InputMaybe<ServiceDetailsPageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ServiceDetailsPageFilter>>>;
	analyticsContext?: InputMaybe<CfAnalyticsContextNestedFilter>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	appName?: InputMaybe<Scalars['String']>;
	appName_contains?: InputMaybe<Scalars['String']>;
	appName_exists?: InputMaybe<Scalars['Boolean']>;
	appName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appName_not?: InputMaybe<Scalars['String']>;
	appName_not_contains?: InputMaybe<Scalars['String']>;
	appName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical?: InputMaybe<Scalars['String']>;
	canonical_contains?: InputMaybe<Scalars['String']>;
	canonical_exists?: InputMaybe<Scalars['Boolean']>;
	canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical_not?: InputMaybe<Scalars['String']>;
	canonical_not_contains?: InputMaybe<Scalars['String']>;
	canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityId?: InputMaybe<Scalars['String']>;
	facilityId_contains?: InputMaybe<Scalars['String']>;
	facilityId_exists?: InputMaybe<Scalars['Boolean']>;
	facilityId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityId_not?: InputMaybe<Scalars['String']>;
	facilityId_not_contains?: InputMaybe<Scalars['String']>;
	facilityId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	indexed?: InputMaybe<Scalars['Boolean']>;
	indexed_exists?: InputMaybe<Scalars['Boolean']>;
	indexed_not?: InputMaybe<Scalars['Boolean']>;
	moreTextSeo_contains?: InputMaybe<Scalars['String']>;
	moreTextSeo_exists?: InputMaybe<Scalars['Boolean']>;
	moreTextSeo_not_contains?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path?: InputMaybe<Scalars['String']>;
	path_contains?: InputMaybe<Scalars['String']>;
	path_exists?: InputMaybe<Scalars['Boolean']>;
	path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path_not?: InputMaybe<Scalars['String']>;
	path_not_contains?: InputMaybe<Scalars['String']>;
	path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	textSeo_contains?: InputMaybe<Scalars['String']>;
	textSeo_exists?: InputMaybe<Scalars['Boolean']>;
	textSeo_not_contains?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ServiceDetailsPageLinkingCollections = {
	__typename?: 'ServiceDetailsPageLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
};

export type ServiceDetailsPageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceDetailsPageMoreTextSeo = {
	__typename?: 'ServiceDetailsPageMoreTextSeo';
	json: Scalars['JSON'];
	links: ServiceDetailsPageMoreTextSeoLinks;
};

export type ServiceDetailsPageMoreTextSeoAssets = {
	__typename?: 'ServiceDetailsPageMoreTextSeoAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ServiceDetailsPageMoreTextSeoEntries = {
	__typename?: 'ServiceDetailsPageMoreTextSeoEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ServiceDetailsPageMoreTextSeoLinks = {
	__typename?: 'ServiceDetailsPageMoreTextSeoLinks';
	assets: ServiceDetailsPageMoreTextSeoAssets;
	entries: ServiceDetailsPageMoreTextSeoEntries;
	resources: ServiceDetailsPageMoreTextSeoResources;
};

export type ServiceDetailsPageMoreTextSeoResources = {
	__typename?: 'ServiceDetailsPageMoreTextSeoResources';
	block: Array<ServiceDetailsPageMoreTextSeoResourcesBlock>;
	hyperlink: Array<ServiceDetailsPageMoreTextSeoResourcesHyperlink>;
	inline: Array<ServiceDetailsPageMoreTextSeoResourcesInline>;
};

export type ServiceDetailsPageMoreTextSeoResourcesBlock = ResourceLink & {
	__typename?: 'ServiceDetailsPageMoreTextSeoResourcesBlock';
	sys: ResourceSys;
};

export type ServiceDetailsPageMoreTextSeoResourcesHyperlink = ResourceLink & {
	__typename?: 'ServiceDetailsPageMoreTextSeoResourcesHyperlink';
	sys: ResourceSys;
};

export type ServiceDetailsPageMoreTextSeoResourcesInline = ResourceLink & {
	__typename?: 'ServiceDetailsPageMoreTextSeoResourcesInline';
	sys: ResourceSys;
};

export type ServiceDetailsPageOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceDetailsPageTextSeo = {
	__typename?: 'ServiceDetailsPageTextSeo';
	json: Scalars['JSON'];
	links: ServiceDetailsPageTextSeoLinks;
};

export type ServiceDetailsPageTextSeoAssets = {
	__typename?: 'ServiceDetailsPageTextSeoAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ServiceDetailsPageTextSeoEntries = {
	__typename?: 'ServiceDetailsPageTextSeoEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ServiceDetailsPageTextSeoLinks = {
	__typename?: 'ServiceDetailsPageTextSeoLinks';
	assets: ServiceDetailsPageTextSeoAssets;
	entries: ServiceDetailsPageTextSeoEntries;
	resources: ServiceDetailsPageTextSeoResources;
};

export type ServiceDetailsPageTextSeoResources = {
	__typename?: 'ServiceDetailsPageTextSeoResources';
	block: Array<ServiceDetailsPageTextSeoResourcesBlock>;
	hyperlink: Array<ServiceDetailsPageTextSeoResourcesHyperlink>;
	inline: Array<ServiceDetailsPageTextSeoResourcesInline>;
};

export type ServiceDetailsPageTextSeoResourcesBlock = ResourceLink & {
	__typename?: 'ServiceDetailsPageTextSeoResourcesBlock';
	sys: ResourceSys;
};

export type ServiceDetailsPageTextSeoResourcesHyperlink = ResourceLink & {
	__typename?: 'ServiceDetailsPageTextSeoResourcesHyperlink';
	sys: ResourceSys;
};

export type ServiceDetailsPageTextSeoResourcesInline = ResourceLink & {
	__typename?: 'ServiceDetailsPageTextSeoResourcesInline';
	sys: ResourceSys;
};

export type ServiceFilter = {
	AND?: InputMaybe<Array<InputMaybe<ServiceFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ServiceFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType?: InputMaybe<Scalars['String']>;
	buttonType_contains?: InputMaybe<Scalars['String']>;
	buttonType_exists?: InputMaybe<Scalars['Boolean']>;
	buttonType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType_not?: InputMaybe<Scalars['String']>;
	buttonType_not_contains?: InputMaybe<Scalars['String']>;
	buttonType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign?: InputMaybe<Scalars['Boolean']>;
	centerAlign_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign_not?: InputMaybe<Scalars['Boolean']>;
	contained?: InputMaybe<Scalars['Boolean']>;
	contained_exists?: InputMaybe<Scalars['Boolean']>;
	contained_not?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	direction?: InputMaybe<Scalars['String']>;
	direction_contains?: InputMaybe<Scalars['String']>;
	direction_exists?: InputMaybe<Scalars['Boolean']>;
	direction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	direction_not?: InputMaybe<Scalars['String']>;
	direction_not_contains?: InputMaybe<Scalars['String']>;
	direction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageLayout?: InputMaybe<Scalars['String']>;
	imageLayout_contains?: InputMaybe<Scalars['String']>;
	imageLayout_exists?: InputMaybe<Scalars['Boolean']>;
	imageLayout_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageLayout_not?: InputMaybe<Scalars['String']>;
	imageLayout_not_contains?: InputMaybe<Scalars['String']>;
	imageLayout_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	mediaType?: InputMaybe<Scalars['String']>;
	mediaType_contains?: InputMaybe<Scalars['String']>;
	mediaType_exists?: InputMaybe<Scalars['Boolean']>;
	mediaType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mediaType_not?: InputMaybe<Scalars['String']>;
	mediaType_not_contains?: InputMaybe<Scalars['String']>;
	mediaType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileImage_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	richTextDescription_contains?: InputMaybe<Scalars['String']>;
	richTextDescription_exists?: InputMaybe<Scalars['Boolean']>;
	richTextDescription_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	tc?: InputMaybe<CfTermsAndConditionsNestedFilter>;
	tc_exists?: InputMaybe<Scalars['Boolean']>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	videoImage_exists?: InputMaybe<Scalars['Boolean']>;
	video_exists?: InputMaybe<Scalars['Boolean']>;
	youTubeVideoId?: InputMaybe<Scalars['String']>;
	youTubeVideoId_contains?: InputMaybe<Scalars['String']>;
	youTubeVideoId_exists?: InputMaybe<Scalars['Boolean']>;
	youTubeVideoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	youTubeVideoId_not?: InputMaybe<Scalars['String']>;
	youTubeVideoId_not_contains?: InputMaybe<Scalars['String']>;
	youTubeVideoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	youTubeVideoImage_exists?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceGroup) */
export type ServiceGroup = Entry &
	_Node & {
		__typename?: 'ServiceGroup';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		fullWidth?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<ServiceGroupLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		servicesCollection?: Maybe<ServiceGroupServicesCollection>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceGroup) */
export type ServiceGroupDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceGroup) */
export type ServiceGroupFullWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceGroup) */
export type ServiceGroupLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceGroup) */
export type ServiceGroupNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceGroup) */
export type ServiceGroupServicesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceGroupServicesCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ServiceFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceGroup) */
export type ServiceGroupTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type ServiceGroupCollection = {
	__typename?: 'ServiceGroupCollection';
	items: Array<Maybe<ServiceGroup>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceGroupFilter = {
	AND?: InputMaybe<Array<InputMaybe<ServiceGroupFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ServiceGroupFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	services?: InputMaybe<CfServiceNestedFilter>;
	servicesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ServiceGroupLinkingCollections = {
	__typename?: 'ServiceGroupLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type ServiceGroupLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceGroupLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceGroupLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceGroupLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceGroupOrder =
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceGroupServicesCollection = {
	__typename?: 'ServiceGroupServicesCollection';
	items: Array<Maybe<Service>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceGroupServicesCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'contained_ASC'
	| 'contained_DESC'
	| 'direction_ASC'
	| 'direction_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageLayout_ASC'
	| 'imageLayout_DESC'
	| 'mediaType_ASC'
	| 'mediaType_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'youTubeVideoId_ASC'
	| 'youTubeVideoId_DESC';

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHero = Entry &
	_Node & {
		__typename?: 'ServiceLineHero';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		backgroundColorMobile?: Maybe<Scalars['String']>;
		bodyCopy?: Maybe<ServiceLineHeroBodyCopy>;
		buttonCollection?: Maybe<ServiceLineHeroButtonCollection>;
		contentAlign?: Maybe<Scalars['String']>;
		contentListCollection?: Maybe<ServiceLineHeroContentListCollection>;
		contentfulMetadata: ContentfulMetadata;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		displayOfficeHoursInModal?: Maybe<Scalars['Boolean']>;
		eyebrow?: Maybe<Scalars['String']>;
		eyebrowHtag?: Maybe<Scalars['String']>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		focalPointImage?: Maybe<FocalPointImage>;
		googleMapsBusinessQuery?: Maybe<Scalars['String']>;
		headline?: Maybe<ServiceLineHeroHeadline>;
		hideHeroOnMobile?: Maybe<Scalars['Boolean']>;
		imageWithBorderRadius?: Maybe<Scalars['Boolean']>;
		imgDisplaysFirstOnDesktop?: Maybe<Scalars['Boolean']>;
		imgDisplaysFirstOnMobile?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<ServiceLineHeroLinkingCollections>;
		mediaRoundedCornerSize?: Maybe<Scalars['String']>;
		name?: Maybe<Scalars['String']>;
		servicesCollection?: Maybe<ServiceLineHeroServicesCollection>;
		showOfficeDetails?: Maybe<Scalars['Boolean']>;
		subHeadline?: Maybe<ServiceLineHeroSubHeadline>;
		sys: Sys;
		templateWidth?: Maybe<Scalars['String']>;
		termsConditions?: Maybe<TermsAndConditions>;
		videoAsset?: Maybe<VideoAsset>;
	};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroBackgroundColorMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroBodyCopyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLineHeroButtonCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroContentAlignArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroContentListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ServiceLineHeroContentListFilter>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroDisplayOfficeHoursInModalArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroEyebrowHtagArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroFacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroFocalPointImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroGoogleMapsBusinessQueryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroHeadlineArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroHideHeroOnMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroImageWithBorderRadiusArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroImgDisplaysFirstOnDesktopArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroImgDisplaysFirstOnMobileArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroMediaRoundedCornerSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroServicesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLineHeroServicesCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<MedicalServiceFilter>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroShowOfficeDetailsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroSubHeadlineArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroTemplateWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroTermsConditionsArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<TermsAndConditionsFilter>;
};

/** Hero for a service line, e.g Allergy, Urgent Care, OCC Med, etc. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/serviceLineHero) */
export type ServiceLineHeroVideoAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoAssetFilter>;
};

export type ServiceLineHeroBodyCopy = {
	__typename?: 'ServiceLineHeroBodyCopy';
	json: Scalars['JSON'];
	links: ServiceLineHeroBodyCopyLinks;
};

export type ServiceLineHeroBodyCopyAssets = {
	__typename?: 'ServiceLineHeroBodyCopyAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ServiceLineHeroBodyCopyEntries = {
	__typename?: 'ServiceLineHeroBodyCopyEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ServiceLineHeroBodyCopyLinks = {
	__typename?: 'ServiceLineHeroBodyCopyLinks';
	assets: ServiceLineHeroBodyCopyAssets;
	entries: ServiceLineHeroBodyCopyEntries;
	resources: ServiceLineHeroBodyCopyResources;
};

export type ServiceLineHeroBodyCopyResources = {
	__typename?: 'ServiceLineHeroBodyCopyResources';
	block: Array<ServiceLineHeroBodyCopyResourcesBlock>;
	hyperlink: Array<ServiceLineHeroBodyCopyResourcesHyperlink>;
	inline: Array<ServiceLineHeroBodyCopyResourcesInline>;
};

export type ServiceLineHeroBodyCopyResourcesBlock = ResourceLink & {
	__typename?: 'ServiceLineHeroBodyCopyResourcesBlock';
	sys: ResourceSys;
};

export type ServiceLineHeroBodyCopyResourcesHyperlink = ResourceLink & {
	__typename?: 'ServiceLineHeroBodyCopyResourcesHyperlink';
	sys: ResourceSys;
};

export type ServiceLineHeroBodyCopyResourcesInline = ResourceLink & {
	__typename?: 'ServiceLineHeroBodyCopyResourcesInline';
	sys: ResourceSys;
};

export type ServiceLineHeroButtonCollection = {
	__typename?: 'ServiceLineHeroButtonCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceLineHeroButtonCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type ServiceLineHeroCollection = {
	__typename?: 'ServiceLineHeroCollection';
	items: Array<Maybe<ServiceLineHero>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceLineHeroContentListCollection = {
	__typename?: 'ServiceLineHeroContentListCollection';
	items: Array<Maybe<ServiceLineHeroContentListItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceLineHeroContentListFilter = {
	AND?: InputMaybe<Array<InputMaybe<ServiceLineHeroContentListFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ServiceLineHeroContentListFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type ServiceLineHeroContentListItem = Accordions | ContentTypeRichText | SingleMessage | Table;

export type ServiceLineHeroFilter = {
	AND?: InputMaybe<Array<InputMaybe<ServiceLineHeroFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<ServiceLineHeroFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColorMobile?: InputMaybe<Scalars['String']>;
	backgroundColorMobile_contains?: InputMaybe<Scalars['String']>;
	backgroundColorMobile_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColorMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColorMobile_not?: InputMaybe<Scalars['String']>;
	backgroundColorMobile_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColorMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bodyCopy_contains?: InputMaybe<Scalars['String']>;
	bodyCopy_exists?: InputMaybe<Scalars['Boolean']>;
	bodyCopy_not_contains?: InputMaybe<Scalars['String']>;
	button?: InputMaybe<CfButtonNestedFilter>;
	buttonCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign?: InputMaybe<Scalars['String']>;
	contentAlign_contains?: InputMaybe<Scalars['String']>;
	contentAlign_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentAlign_not?: InputMaybe<Scalars['String']>;
	contentAlign_not_contains?: InputMaybe<Scalars['String']>;
	contentAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentList?: InputMaybe<CfcontentListMultiTypeNestedFilter>;
	contentListCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	displayOfficeHoursInModal?: InputMaybe<Scalars['Boolean']>;
	displayOfficeHoursInModal_exists?: InputMaybe<Scalars['Boolean']>;
	displayOfficeHoursInModal_not?: InputMaybe<Scalars['Boolean']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrowHtag?: InputMaybe<Scalars['String']>;
	eyebrowHtag_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowHtag_not?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImage?: InputMaybe<CfFocalPointImageNestedFilter>;
	focalPointImage_exists?: InputMaybe<Scalars['Boolean']>;
	googleMapsBusinessQuery?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_exists?: InputMaybe<Scalars['Boolean']>;
	googleMapsBusinessQuery_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	googleMapsBusinessQuery_not?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_contains?: InputMaybe<Scalars['String']>;
	googleMapsBusinessQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headline_contains?: InputMaybe<Scalars['String']>;
	headline_exists?: InputMaybe<Scalars['Boolean']>;
	headline_not_contains?: InputMaybe<Scalars['String']>;
	hideHeroOnMobile?: InputMaybe<Scalars['Boolean']>;
	hideHeroOnMobile_exists?: InputMaybe<Scalars['Boolean']>;
	hideHeroOnMobile_not?: InputMaybe<Scalars['Boolean']>;
	imageWithBorderRadius?: InputMaybe<Scalars['Boolean']>;
	imageWithBorderRadius_exists?: InputMaybe<Scalars['Boolean']>;
	imageWithBorderRadius_not?: InputMaybe<Scalars['Boolean']>;
	imgDisplaysFirstOnDesktop?: InputMaybe<Scalars['Boolean']>;
	imgDisplaysFirstOnDesktop_exists?: InputMaybe<Scalars['Boolean']>;
	imgDisplaysFirstOnDesktop_not?: InputMaybe<Scalars['Boolean']>;
	imgDisplaysFirstOnMobile?: InputMaybe<Scalars['Boolean']>;
	imgDisplaysFirstOnMobile_exists?: InputMaybe<Scalars['Boolean']>;
	imgDisplaysFirstOnMobile_not?: InputMaybe<Scalars['Boolean']>;
	mediaRoundedCornerSize?: InputMaybe<Scalars['String']>;
	mediaRoundedCornerSize_contains?: InputMaybe<Scalars['String']>;
	mediaRoundedCornerSize_exists?: InputMaybe<Scalars['Boolean']>;
	mediaRoundedCornerSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mediaRoundedCornerSize_not?: InputMaybe<Scalars['String']>;
	mediaRoundedCornerSize_not_contains?: InputMaybe<Scalars['String']>;
	mediaRoundedCornerSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	services?: InputMaybe<CfMedicalServiceNestedFilter>;
	servicesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	showOfficeDetails?: InputMaybe<Scalars['Boolean']>;
	showOfficeDetails_exists?: InputMaybe<Scalars['Boolean']>;
	showOfficeDetails_not?: InputMaybe<Scalars['Boolean']>;
	subHeadline_contains?: InputMaybe<Scalars['String']>;
	subHeadline_exists?: InputMaybe<Scalars['Boolean']>;
	subHeadline_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	termsConditions?: InputMaybe<CfTermsAndConditionsNestedFilter>;
	termsConditions_exists?: InputMaybe<Scalars['Boolean']>;
	videoAsset?: InputMaybe<CfVideoAssetNestedFilter>;
	videoAsset_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceLineHeroHeadline = {
	__typename?: 'ServiceLineHeroHeadline';
	json: Scalars['JSON'];
	links: ServiceLineHeroHeadlineLinks;
};

export type ServiceLineHeroHeadlineAssets = {
	__typename?: 'ServiceLineHeroHeadlineAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ServiceLineHeroHeadlineEntries = {
	__typename?: 'ServiceLineHeroHeadlineEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ServiceLineHeroHeadlineLinks = {
	__typename?: 'ServiceLineHeroHeadlineLinks';
	assets: ServiceLineHeroHeadlineAssets;
	entries: ServiceLineHeroHeadlineEntries;
	resources: ServiceLineHeroHeadlineResources;
};

export type ServiceLineHeroHeadlineResources = {
	__typename?: 'ServiceLineHeroHeadlineResources';
	block: Array<ServiceLineHeroHeadlineResourcesBlock>;
	hyperlink: Array<ServiceLineHeroHeadlineResourcesHyperlink>;
	inline: Array<ServiceLineHeroHeadlineResourcesInline>;
};

export type ServiceLineHeroHeadlineResourcesBlock = ResourceLink & {
	__typename?: 'ServiceLineHeroHeadlineResourcesBlock';
	sys: ResourceSys;
};

export type ServiceLineHeroHeadlineResourcesHyperlink = ResourceLink & {
	__typename?: 'ServiceLineHeroHeadlineResourcesHyperlink';
	sys: ResourceSys;
};

export type ServiceLineHeroHeadlineResourcesInline = ResourceLink & {
	__typename?: 'ServiceLineHeroHeadlineResourcesInline';
	sys: ResourceSys;
};

export type ServiceLineHeroLinkingCollections = {
	__typename?: 'ServiceLineHeroLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
};

export type ServiceLineHeroLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLineHeroLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLineHeroLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLineHeroLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLineHeroLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLineHeroLinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLineHeroLinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLineHeroLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceLineHeroLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceLineHeroLinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ServiceLineHeroOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type ServiceLineHeroServicesCollection = {
	__typename?: 'ServiceLineHeroServicesCollection';
	items: Array<Maybe<MedicalService>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type ServiceLineHeroServicesCollectionOrder =
	| 'iconName_ASC'
	| 'iconName_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceLineHeroSubHeadline = {
	__typename?: 'ServiceLineHeroSubHeadline';
	json: Scalars['JSON'];
	links: ServiceLineHeroSubHeadlineLinks;
};

export type ServiceLineHeroSubHeadlineAssets = {
	__typename?: 'ServiceLineHeroSubHeadlineAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ServiceLineHeroSubHeadlineEntries = {
	__typename?: 'ServiceLineHeroSubHeadlineEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ServiceLineHeroSubHeadlineLinks = {
	__typename?: 'ServiceLineHeroSubHeadlineLinks';
	assets: ServiceLineHeroSubHeadlineAssets;
	entries: ServiceLineHeroSubHeadlineEntries;
	resources: ServiceLineHeroSubHeadlineResources;
};

export type ServiceLineHeroSubHeadlineResources = {
	__typename?: 'ServiceLineHeroSubHeadlineResources';
	block: Array<ServiceLineHeroSubHeadlineResourcesBlock>;
	hyperlink: Array<ServiceLineHeroSubHeadlineResourcesHyperlink>;
	inline: Array<ServiceLineHeroSubHeadlineResourcesInline>;
};

export type ServiceLineHeroSubHeadlineResourcesBlock = ResourceLink & {
	__typename?: 'ServiceLineHeroSubHeadlineResourcesBlock';
	sys: ResourceSys;
};

export type ServiceLineHeroSubHeadlineResourcesHyperlink = ResourceLink & {
	__typename?: 'ServiceLineHeroSubHeadlineResourcesHyperlink';
	sys: ResourceSys;
};

export type ServiceLineHeroSubHeadlineResourcesInline = ResourceLink & {
	__typename?: 'ServiceLineHeroSubHeadlineResourcesInline';
	sys: ResourceSys;
};

export type ServiceLinkingCollections = {
	__typename?: 'ServiceLinkingCollections';
	carouselCollection?: Maybe<CarouselCollection>;
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	serviceGroupCollection?: Maybe<ServiceGroupCollection>;
};

export type ServiceLinkingCollectionsCarouselCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLinkingCollectionsCarouselCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLinkingCollectionsProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLinkingCollectionsProviderDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLinkingCollectionsServiceGroupCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<ServiceLinkingCollectionsServiceGroupCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type ServiceLinkingCollectionsCarouselCollectionOrder =
	| 'arrowsColor_ASC'
	| 'arrowsColor_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRounded_ASC'
	| 'imageRounded_DESC'
	| 'imageWidth_ASC'
	| 'imageWidth_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quotesColor_ASC'
	| 'quotesColor_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceLinkingCollectionsProviderDetailsPageCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ServiceLinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ServiceLinkingCollectionsServiceGroupCollectionOrder =
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type ServiceOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'contained_ASC'
	| 'contained_DESC'
	| 'direction_ASC'
	| 'direction_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageLayout_ASC'
	| 'imageLayout_DESC'
	| 'mediaType_ASC'
	| 'mediaType_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'youTubeVideoId_ASC'
	| 'youTubeVideoId_DESC';

export type ServiceRichTextDescription = {
	__typename?: 'ServiceRichTextDescription';
	json: Scalars['JSON'];
	links: ServiceRichTextDescriptionLinks;
};

export type ServiceRichTextDescriptionAssets = {
	__typename?: 'ServiceRichTextDescriptionAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type ServiceRichTextDescriptionEntries = {
	__typename?: 'ServiceRichTextDescriptionEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type ServiceRichTextDescriptionLinks = {
	__typename?: 'ServiceRichTextDescriptionLinks';
	assets: ServiceRichTextDescriptionAssets;
	entries: ServiceRichTextDescriptionEntries;
	resources: ServiceRichTextDescriptionResources;
};

export type ServiceRichTextDescriptionResources = {
	__typename?: 'ServiceRichTextDescriptionResources';
	block: Array<ServiceRichTextDescriptionResourcesBlock>;
	hyperlink: Array<ServiceRichTextDescriptionResourcesHyperlink>;
	inline: Array<ServiceRichTextDescriptionResourcesInline>;
};

export type ServiceRichTextDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'ServiceRichTextDescriptionResourcesBlock';
	sys: ResourceSys;
};

export type ServiceRichTextDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'ServiceRichTextDescriptionResourcesHyperlink';
	sys: ResourceSys;
};

export type ServiceRichTextDescriptionResourcesInline = ResourceLink & {
	__typename?: 'ServiceRichTextDescriptionResourcesInline';
	sys: ResourceSys;
};

export type Sex = 'FEMALE' | 'MALE' | 'OTHER' | 'UNSPECIFIED';

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedForm = Entry &
	_Node & {
		__typename?: 'SharedForm';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		definitionsCollection?: Maybe<SharedFormDefinitionsCollection>;
		description?: Maybe<Scalars['String']>;
		descriptionColor?: Maybe<Scalars['String']>;
		descriptionFont?: Maybe<Scalars['String']>;
		formButtonPrimary?: Maybe<Scalars['String']>;
		formButtonPrimaryUrl?: Maybe<Scalars['String']>;
		formButtonSecondary?: Maybe<Scalars['String']>;
		formButtonSecondaryUrl?: Maybe<Scalars['String']>;
		formFieldsCollection?: Maybe<SharedFormFormFieldsCollection>;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<SharedFormLinkingCollections>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleColor?: Maybe<Scalars['String']>;
		titleFont?: Maybe<Scalars['String']>;
	};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormDefinitionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormDefinitionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SharedFormDefinitionsFilter>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormDescriptionColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormDescriptionFontArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormFormButtonPrimaryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormFormButtonPrimaryUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormFormButtonSecondaryArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormFormButtonSecondaryUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormFormFieldsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormFormFieldsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SharedFormFieldFilter>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormTitleColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Form container for all form fields [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedForm) */
export type SharedFormTitleFontArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SharedFormCollection = {
	__typename?: 'SharedFormCollection';
	items: Array<Maybe<SharedForm>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitions = Entry &
	_Node & {
		__typename?: 'SharedFormDefinitions';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		createNewLine?: Maybe<Scalars['Boolean']>;
		description?: Maybe<Scalars['String']>;
		descriptionFont?: Maybe<Scalars['String']>;
		displayDefinition?: Maybe<Scalars['Boolean']>;
		divider?: Maybe<Scalars['Boolean']>;
		formFieldsCollection?: Maybe<SharedFormDefinitionsFormFieldsCollection>;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<SharedFormDefinitionsLinkingCollections>;
		needLabel?: Maybe<Scalars['Boolean']>;
		phoneSize?: Maybe<Scalars['Float']>;
		size?: Maybe<Scalars['Float']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		titleFont?: Maybe<Scalars['String']>;
	};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsCreateNewLineArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsDescriptionFontArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsDisplayDefinitionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsDividerArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsFormFieldsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormDefinitionsFormFieldsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<SharedFormFieldFilter>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsNeedLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsPhoneSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A section of the shared form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormDefinitions) */
export type SharedFormDefinitionsTitleFontArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SharedFormDefinitionsCollection = {
	__typename?: 'SharedFormDefinitionsCollection';
	items: Array<Maybe<SharedFormDefinitions>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SharedFormDefinitionsCollectionOrder =
	| 'createNewLine_ASC'
	| 'createNewLine_DESC'
	| 'descriptionFont_ASC'
	| 'descriptionFont_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'displayDefinition_ASC'
	| 'displayDefinition_DESC'
	| 'divider_ASC'
	| 'divider_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'needLabel_ASC'
	| 'needLabel_DESC'
	| 'phoneSize_ASC'
	| 'phoneSize_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleFont_ASC'
	| 'titleFont_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SharedFormDefinitionsFilter = {
	AND?: InputMaybe<Array<InputMaybe<SharedFormDefinitionsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SharedFormDefinitionsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	createNewLine?: InputMaybe<Scalars['Boolean']>;
	createNewLine_exists?: InputMaybe<Scalars['Boolean']>;
	createNewLine_not?: InputMaybe<Scalars['Boolean']>;
	description?: InputMaybe<Scalars['String']>;
	descriptionFont?: InputMaybe<Scalars['String']>;
	descriptionFont_contains?: InputMaybe<Scalars['String']>;
	descriptionFont_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionFont_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	descriptionFont_not?: InputMaybe<Scalars['String']>;
	descriptionFont_not_contains?: InputMaybe<Scalars['String']>;
	descriptionFont_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	displayDefinition?: InputMaybe<Scalars['Boolean']>;
	displayDefinition_exists?: InputMaybe<Scalars['Boolean']>;
	displayDefinition_not?: InputMaybe<Scalars['Boolean']>;
	divider?: InputMaybe<Scalars['Boolean']>;
	divider_exists?: InputMaybe<Scalars['Boolean']>;
	divider_not?: InputMaybe<Scalars['Boolean']>;
	formFields?: InputMaybe<CfSharedFormFieldNestedFilter>;
	formFieldsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	needLabel?: InputMaybe<Scalars['Boolean']>;
	needLabel_exists?: InputMaybe<Scalars['Boolean']>;
	needLabel_not?: InputMaybe<Scalars['Boolean']>;
	phoneSize?: InputMaybe<Scalars['Float']>;
	phoneSize_exists?: InputMaybe<Scalars['Boolean']>;
	phoneSize_gt?: InputMaybe<Scalars['Float']>;
	phoneSize_gte?: InputMaybe<Scalars['Float']>;
	phoneSize_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	phoneSize_lt?: InputMaybe<Scalars['Float']>;
	phoneSize_lte?: InputMaybe<Scalars['Float']>;
	phoneSize_not?: InputMaybe<Scalars['Float']>;
	phoneSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	size?: InputMaybe<Scalars['Float']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_gt?: InputMaybe<Scalars['Float']>;
	size_gte?: InputMaybe<Scalars['Float']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	size_lt?: InputMaybe<Scalars['Float']>;
	size_lte?: InputMaybe<Scalars['Float']>;
	size_not?: InputMaybe<Scalars['Float']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleFont?: InputMaybe<Scalars['String']>;
	titleFont_contains?: InputMaybe<Scalars['String']>;
	titleFont_exists?: InputMaybe<Scalars['Boolean']>;
	titleFont_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleFont_not?: InputMaybe<Scalars['String']>;
	titleFont_not_contains?: InputMaybe<Scalars['String']>;
	titleFont_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SharedFormDefinitionsFormFieldsCollection = {
	__typename?: 'SharedFormDefinitionsFormFieldsCollection';
	items: Array<Maybe<SharedFormField>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SharedFormDefinitionsFormFieldsCollectionOrder =
	| 'createNewLine_ASC'
	| 'createNewLine_DESC'
	| 'default_ASC'
	| 'default_DESC'
	| 'divider_ASC'
	| 'divider_DESC'
	| 'field_ASC'
	| 'field_DESC'
	| 'format_ASC'
	| 'format_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'minLength_ASC'
	| 'minLength_DESC'
	| 'needOuterLabel_ASC'
	| 'needOuterLabel_DESC'
	| 'phoneSize_ASC'
	| 'phoneSize_DESC'
	| 'placeHolder_ASC'
	| 'placeHolder_DESC'
	| 'required_ASC'
	| 'required_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type SharedFormDefinitionsLinkingCollections = {
	__typename?: 'SharedFormDefinitionsLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	sharedFormCollection?: Maybe<SharedFormCollection>;
};

export type SharedFormDefinitionsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SharedFormDefinitionsLinkingCollectionsSharedFormCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormDefinitionsLinkingCollectionsSharedFormCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SharedFormDefinitionsLinkingCollectionsSharedFormCollectionOrder =
	| 'descriptionColor_ASC'
	| 'descriptionColor_DESC'
	| 'descriptionFont_ASC'
	| 'descriptionFont_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'formButtonPrimaryURL_ASC'
	| 'formButtonPrimaryURL_DESC'
	| 'formButtonPrimary_ASC'
	| 'formButtonPrimary_DESC'
	| 'formButtonSecondaryURL_ASC'
	| 'formButtonSecondaryURL_DESC'
	| 'formButtonSecondary_ASC'
	| 'formButtonSecondary_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleFont_ASC'
	| 'titleFont_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SharedFormDefinitionsOrder =
	| 'createNewLine_ASC'
	| 'createNewLine_DESC'
	| 'descriptionFont_ASC'
	| 'descriptionFont_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'displayDefinition_ASC'
	| 'displayDefinition_DESC'
	| 'divider_ASC'
	| 'divider_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'needLabel_ASC'
	| 'needLabel_DESC'
	| 'phoneSize_ASC'
	| 'phoneSize_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleFont_ASC'
	| 'titleFont_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormField = Entry &
	_Node & {
		__typename?: 'SharedFormField';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		createNewLine?: Maybe<Scalars['Boolean']>;
		default?: Maybe<Scalars['String']>;
		dependency?: Maybe<Scalars['JSON']>;
		divider?: Maybe<Scalars['Boolean']>;
		enum?: Maybe<Array<Maybe<Scalars['String']>>>;
		field?: Maybe<Scalars['String']>;
		format?: Maybe<Scalars['String']>;
		items?: Maybe<Array<Maybe<Scalars['String']>>>;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<SharedFormFieldLinkingCollections>;
		minLength?: Maybe<Scalars['Int']>;
		needOuterLabel?: Maybe<Scalars['Boolean']>;
		phoneSize?: Maybe<Scalars['Float']>;
		placeHolder?: Maybe<Scalars['String']>;
		required?: Maybe<Scalars['Boolean']>;
		size?: Maybe<Scalars['Float']>;
		sys: Sys;
		title?: Maybe<Scalars['String']>;
		type?: Maybe<Scalars['String']>;
	};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldCreateNewLineArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldDefaultArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldDependencyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldDividerArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldEnumArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldFieldArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldFormatArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldItemsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldMinLengthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldNeedOuterLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldPhoneSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldPlaceHolderArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldRequiredArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** The actual field within the definition, within the form [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/sharedFormField) */
export type SharedFormFieldTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SharedFormFieldCollection = {
	__typename?: 'SharedFormFieldCollection';
	items: Array<Maybe<SharedFormField>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SharedFormFieldFilter = {
	AND?: InputMaybe<Array<InputMaybe<SharedFormFieldFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SharedFormFieldFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	createNewLine?: InputMaybe<Scalars['Boolean']>;
	createNewLine_exists?: InputMaybe<Scalars['Boolean']>;
	createNewLine_not?: InputMaybe<Scalars['Boolean']>;
	default?: InputMaybe<Scalars['String']>;
	default_contains?: InputMaybe<Scalars['String']>;
	default_exists?: InputMaybe<Scalars['Boolean']>;
	default_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	default_not?: InputMaybe<Scalars['String']>;
	default_not_contains?: InputMaybe<Scalars['String']>;
	default_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	dependency_exists?: InputMaybe<Scalars['Boolean']>;
	divider?: InputMaybe<Scalars['Boolean']>;
	divider_exists?: InputMaybe<Scalars['Boolean']>;
	divider_not?: InputMaybe<Scalars['Boolean']>;
	enum_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	enum_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	enum_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	enum_exists?: InputMaybe<Scalars['Boolean']>;
	field?: InputMaybe<Scalars['String']>;
	field_contains?: InputMaybe<Scalars['String']>;
	field_exists?: InputMaybe<Scalars['Boolean']>;
	field_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	field_not?: InputMaybe<Scalars['String']>;
	field_not_contains?: InputMaybe<Scalars['String']>;
	field_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	format?: InputMaybe<Scalars['String']>;
	format_contains?: InputMaybe<Scalars['String']>;
	format_exists?: InputMaybe<Scalars['Boolean']>;
	format_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	format_not?: InputMaybe<Scalars['String']>;
	format_not_contains?: InputMaybe<Scalars['String']>;
	format_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	items_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	items_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	items_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	items_exists?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	minLength?: InputMaybe<Scalars['Int']>;
	minLength_exists?: InputMaybe<Scalars['Boolean']>;
	minLength_gt?: InputMaybe<Scalars['Int']>;
	minLength_gte?: InputMaybe<Scalars['Int']>;
	minLength_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	minLength_lt?: InputMaybe<Scalars['Int']>;
	minLength_lte?: InputMaybe<Scalars['Int']>;
	minLength_not?: InputMaybe<Scalars['Int']>;
	minLength_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	needOuterLabel?: InputMaybe<Scalars['Boolean']>;
	needOuterLabel_exists?: InputMaybe<Scalars['Boolean']>;
	needOuterLabel_not?: InputMaybe<Scalars['Boolean']>;
	phoneSize?: InputMaybe<Scalars['Float']>;
	phoneSize_exists?: InputMaybe<Scalars['Boolean']>;
	phoneSize_gt?: InputMaybe<Scalars['Float']>;
	phoneSize_gte?: InputMaybe<Scalars['Float']>;
	phoneSize_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	phoneSize_lt?: InputMaybe<Scalars['Float']>;
	phoneSize_lte?: InputMaybe<Scalars['Float']>;
	phoneSize_not?: InputMaybe<Scalars['Float']>;
	phoneSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	placeHolder?: InputMaybe<Scalars['String']>;
	placeHolder_contains?: InputMaybe<Scalars['String']>;
	placeHolder_exists?: InputMaybe<Scalars['Boolean']>;
	placeHolder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	placeHolder_not?: InputMaybe<Scalars['String']>;
	placeHolder_not_contains?: InputMaybe<Scalars['String']>;
	placeHolder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	required?: InputMaybe<Scalars['Boolean']>;
	required_exists?: InputMaybe<Scalars['Boolean']>;
	required_not?: InputMaybe<Scalars['Boolean']>;
	size?: InputMaybe<Scalars['Float']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_gt?: InputMaybe<Scalars['Float']>;
	size_gte?: InputMaybe<Scalars['Float']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	size_lt?: InputMaybe<Scalars['Float']>;
	size_lte?: InputMaybe<Scalars['Float']>;
	size_not?: InputMaybe<Scalars['Float']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type?: InputMaybe<Scalars['String']>;
	type_contains?: InputMaybe<Scalars['String']>;
	type_exists?: InputMaybe<Scalars['Boolean']>;
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type_not?: InputMaybe<Scalars['String']>;
	type_not_contains?: InputMaybe<Scalars['String']>;
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SharedFormFieldLinkingCollections = {
	__typename?: 'SharedFormFieldLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	sharedFormCollection?: Maybe<SharedFormCollection>;
	sharedFormDefinitionsCollection?: Maybe<SharedFormDefinitionsCollection>;
};

export type SharedFormFieldLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SharedFormFieldLinkingCollectionsSharedFormCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormFieldLinkingCollectionsSharedFormCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SharedFormFieldLinkingCollectionsSharedFormDefinitionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormFieldLinkingCollectionsSharedFormDefinitionsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SharedFormFieldLinkingCollectionsSharedFormCollectionOrder =
	| 'descriptionColor_ASC'
	| 'descriptionColor_DESC'
	| 'descriptionFont_ASC'
	| 'descriptionFont_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'formButtonPrimaryURL_ASC'
	| 'formButtonPrimaryURL_DESC'
	| 'formButtonPrimary_ASC'
	| 'formButtonPrimary_DESC'
	| 'formButtonSecondaryURL_ASC'
	| 'formButtonSecondaryURL_DESC'
	| 'formButtonSecondary_ASC'
	| 'formButtonSecondary_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleFont_ASC'
	| 'titleFont_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SharedFormFieldLinkingCollectionsSharedFormDefinitionsCollectionOrder =
	| 'createNewLine_ASC'
	| 'createNewLine_DESC'
	| 'descriptionFont_ASC'
	| 'descriptionFont_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'displayDefinition_ASC'
	| 'displayDefinition_DESC'
	| 'divider_ASC'
	| 'divider_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'needLabel_ASC'
	| 'needLabel_DESC'
	| 'phoneSize_ASC'
	| 'phoneSize_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleFont_ASC'
	| 'titleFont_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SharedFormFieldOrder =
	| 'createNewLine_ASC'
	| 'createNewLine_DESC'
	| 'default_ASC'
	| 'default_DESC'
	| 'divider_ASC'
	| 'divider_DESC'
	| 'field_ASC'
	| 'field_DESC'
	| 'format_ASC'
	| 'format_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'minLength_ASC'
	| 'minLength_DESC'
	| 'needOuterLabel_ASC'
	| 'needOuterLabel_DESC'
	| 'phoneSize_ASC'
	| 'phoneSize_DESC'
	| 'placeHolder_ASC'
	| 'placeHolder_DESC'
	| 'required_ASC'
	| 'required_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type SharedFormFilter = {
	AND?: InputMaybe<Array<InputMaybe<SharedFormFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SharedFormFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	definitions?: InputMaybe<CfSharedFormDefinitionsNestedFilter>;
	definitionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	description?: InputMaybe<Scalars['String']>;
	descriptionColor?: InputMaybe<Scalars['String']>;
	descriptionColor_contains?: InputMaybe<Scalars['String']>;
	descriptionColor_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	descriptionColor_not?: InputMaybe<Scalars['String']>;
	descriptionColor_not_contains?: InputMaybe<Scalars['String']>;
	descriptionColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	descriptionFont?: InputMaybe<Scalars['String']>;
	descriptionFont_contains?: InputMaybe<Scalars['String']>;
	descriptionFont_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionFont_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	descriptionFont_not?: InputMaybe<Scalars['String']>;
	descriptionFont_not_contains?: InputMaybe<Scalars['String']>;
	descriptionFont_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formButtonPrimary?: InputMaybe<Scalars['String']>;
	formButtonPrimaryURL?: InputMaybe<Scalars['String']>;
	formButtonPrimaryURL_contains?: InputMaybe<Scalars['String']>;
	formButtonPrimaryURL_exists?: InputMaybe<Scalars['Boolean']>;
	formButtonPrimaryURL_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formButtonPrimaryURL_not?: InputMaybe<Scalars['String']>;
	formButtonPrimaryURL_not_contains?: InputMaybe<Scalars['String']>;
	formButtonPrimaryURL_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formButtonPrimary_contains?: InputMaybe<Scalars['String']>;
	formButtonPrimary_exists?: InputMaybe<Scalars['Boolean']>;
	formButtonPrimary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formButtonPrimary_not?: InputMaybe<Scalars['String']>;
	formButtonPrimary_not_contains?: InputMaybe<Scalars['String']>;
	formButtonPrimary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formButtonSecondary?: InputMaybe<Scalars['String']>;
	formButtonSecondaryURL?: InputMaybe<Scalars['String']>;
	formButtonSecondaryURL_contains?: InputMaybe<Scalars['String']>;
	formButtonSecondaryURL_exists?: InputMaybe<Scalars['Boolean']>;
	formButtonSecondaryURL_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formButtonSecondaryURL_not?: InputMaybe<Scalars['String']>;
	formButtonSecondaryURL_not_contains?: InputMaybe<Scalars['String']>;
	formButtonSecondaryURL_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formButtonSecondary_contains?: InputMaybe<Scalars['String']>;
	formButtonSecondary_exists?: InputMaybe<Scalars['Boolean']>;
	formButtonSecondary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formButtonSecondary_not?: InputMaybe<Scalars['String']>;
	formButtonSecondary_not_contains?: InputMaybe<Scalars['String']>;
	formButtonSecondary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formFields?: InputMaybe<CfSharedFormFieldNestedFilter>;
	formFieldsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleColor?: InputMaybe<Scalars['String']>;
	titleColor_contains?: InputMaybe<Scalars['String']>;
	titleColor_exists?: InputMaybe<Scalars['Boolean']>;
	titleColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleColor_not?: InputMaybe<Scalars['String']>;
	titleColor_not_contains?: InputMaybe<Scalars['String']>;
	titleColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleFont?: InputMaybe<Scalars['String']>;
	titleFont_contains?: InputMaybe<Scalars['String']>;
	titleFont_exists?: InputMaybe<Scalars['Boolean']>;
	titleFont_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleFont_not?: InputMaybe<Scalars['String']>;
	titleFont_not_contains?: InputMaybe<Scalars['String']>;
	titleFont_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SharedFormFormFieldsCollection = {
	__typename?: 'SharedFormFormFieldsCollection';
	items: Array<Maybe<SharedFormField>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SharedFormFormFieldsCollectionOrder =
	| 'createNewLine_ASC'
	| 'createNewLine_DESC'
	| 'default_ASC'
	| 'default_DESC'
	| 'divider_ASC'
	| 'divider_DESC'
	| 'field_ASC'
	| 'field_DESC'
	| 'format_ASC'
	| 'format_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'minLength_ASC'
	| 'minLength_DESC'
	| 'needOuterLabel_ASC'
	| 'needOuterLabel_DESC'
	| 'phoneSize_ASC'
	| 'phoneSize_DESC'
	| 'placeHolder_ASC'
	| 'placeHolder_DESC'
	| 'required_ASC'
	| 'required_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type SharedFormLinkingCollections = {
	__typename?: 'SharedFormLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
	stepperCollection?: Maybe<StepperCollection>;
};

export type SharedFormLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SharedFormLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SharedFormLinkingCollectionsStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SharedFormLinkingCollectionsStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SharedFormLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SharedFormLinkingCollectionsStepperCollectionOrder =
	| 'confirmButtonText_ASC'
	| 'confirmButtonText_DESC'
	| 'continueButtonText_ASC'
	| 'continueButtonText_DESC'
	| 'headingScheduledText_ASC'
	| 'headingScheduledText_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'needAssistanceText_ASC'
	| 'needAssistanceText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type SharedFormOrder =
	| 'descriptionColor_ASC'
	| 'descriptionColor_DESC'
	| 'descriptionFont_ASC'
	| 'descriptionFont_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'formButtonPrimaryURL_ASC'
	| 'formButtonPrimaryURL_DESC'
	| 'formButtonPrimary_ASC'
	| 'formButtonPrimary_DESC'
	| 'formButtonSecondaryURL_ASC'
	| 'formButtonSecondaryURL_DESC'
	| 'formButtonSecondary_ASC'
	| 'formButtonSecondary_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleColor_ASC'
	| 'titleColor_DESC'
	| 'titleFont_ASC'
	| 'titleFont_DESC'
	| 'title_ASC'
	| 'title_DESC';

/** Returned when child question is answered but parent is either missing or trigger answer id is mismatching. */
export type ShouldNotBeAnsweredQuestionnaireAnswerError = Error & {
	__typename?: 'ShouldNotBeAnsweredQuestionnaireAnswerError';
	answeredQuestion: QuestionnaireQuestion;
	message: Scalars['String'];
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessage = Entry &
	_Node & {
		__typename?: 'SingleMessage';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		buttonSize?: Maybe<Scalars['String']>;
		buttonText?: Maybe<Scalars['String']>;
		buttonType?: Maybe<Scalars['String']>;
		buttonUrl?: Maybe<Scalars['String']>;
		buttonsCollection?: Maybe<SingleMessageButtonsCollection>;
		content?: Maybe<SingleMessageContent>;
		contentAlign?: Maybe<Scalars['String']>;
		contentFillVerticalSpace?: Maybe<Scalars['Boolean']>;
		contentSmallScreen?: Maybe<SmallScreenContent>;
		contentfulMetadata: ContentfulMetadata;
		description?: Maybe<Scalars['String']>;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		eyebrow?: Maybe<Scalars['String']>;
		facilitySegmentCollection?: Maybe<Scalars['String']>;
		focalPointImage?: Maybe<FocalPointImage>;
		fullWidth?: Maybe<Scalars['Boolean']>;
		icon?: Maybe<Scalars['String']>;
		imageAsset?: Maybe<ImageAsset>;
		isBannerImage?: Maybe<Scalars['Boolean']>;
		isInlineLayout?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<SingleMessageLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		subText?: Maybe<Scalars['String']>;
		sys: Sys;
		tc?: Maybe<TermsAndConditions>;
		templateHeight?: Maybe<Scalars['String']>;
		templateWidth?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
		titleVariant?: Maybe<Scalars['String']>;
		useRoundedCorners?: Maybe<Scalars['Boolean']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageButtonSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageButtonTypeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageButtonUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageContentAlignArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageContentFillVerticalSpaceArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageContentSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<SmallScreenContentFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageEyebrowArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageFacilitySegmentCollectionArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageFocalPointImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageFullWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageIconArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageImageAssetArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageIsBannerImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageIsInlineLayoutArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageSubTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageTcArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<TermsAndConditionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageTemplateHeightArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageTemplateWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageTitleVariantArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/singleMessage) */
export type SingleMessageUseRoundedCornersArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SingleMessageButtonsCollection = {
	__typename?: 'SingleMessageButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SingleMessageButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type SingleMessageCollection = {
	__typename?: 'SingleMessageCollection';
	items: Array<Maybe<SingleMessage>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SingleMessageContent = {
	__typename?: 'SingleMessageContent';
	json: Scalars['JSON'];
	links: SingleMessageContentLinks;
};

export type SingleMessageContentAssets = {
	__typename?: 'SingleMessageContentAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type SingleMessageContentEntries = {
	__typename?: 'SingleMessageContentEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type SingleMessageContentLinks = {
	__typename?: 'SingleMessageContentLinks';
	assets: SingleMessageContentAssets;
	entries: SingleMessageContentEntries;
	resources: SingleMessageContentResources;
};

export type SingleMessageContentResources = {
	__typename?: 'SingleMessageContentResources';
	block: Array<SingleMessageContentResourcesBlock>;
	hyperlink: Array<SingleMessageContentResourcesHyperlink>;
	inline: Array<SingleMessageContentResourcesInline>;
};

export type SingleMessageContentResourcesBlock = ResourceLink & {
	__typename?: 'SingleMessageContentResourcesBlock';
	sys: ResourceSys;
};

export type SingleMessageContentResourcesHyperlink = ResourceLink & {
	__typename?: 'SingleMessageContentResourcesHyperlink';
	sys: ResourceSys;
};

export type SingleMessageContentResourcesInline = ResourceLink & {
	__typename?: 'SingleMessageContentResourcesInline';
	sys: ResourceSys;
};

export type SingleMessageFilter = {
	AND?: InputMaybe<Array<InputMaybe<SingleMessageFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SingleMessageFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonSize?: InputMaybe<Scalars['String']>;
	buttonSize_contains?: InputMaybe<Scalars['String']>;
	buttonSize_exists?: InputMaybe<Scalars['Boolean']>;
	buttonSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonSize_not?: InputMaybe<Scalars['String']>;
	buttonSize_not_contains?: InputMaybe<Scalars['String']>;
	buttonSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType?: InputMaybe<Scalars['String']>;
	buttonType_contains?: InputMaybe<Scalars['String']>;
	buttonType_exists?: InputMaybe<Scalars['Boolean']>;
	buttonType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType_not?: InputMaybe<Scalars['String']>;
	buttonType_not_contains?: InputMaybe<Scalars['String']>;
	buttonType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign?: InputMaybe<Scalars['String']>;
	contentAlign_contains?: InputMaybe<Scalars['String']>;
	contentAlign_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentAlign_not?: InputMaybe<Scalars['String']>;
	contentAlign_not_contains?: InputMaybe<Scalars['String']>;
	contentAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentFillVerticalSpace?: InputMaybe<Scalars['Boolean']>;
	contentFillVerticalSpace_exists?: InputMaybe<Scalars['Boolean']>;
	contentFillVerticalSpace_not?: InputMaybe<Scalars['Boolean']>;
	contentSmallScreen?: InputMaybe<CfSmallScreenContentNestedFilter>;
	contentSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	content_contains?: InputMaybe<Scalars['String']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	content_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImage?: InputMaybe<CfFocalPointImageNestedFilter>;
	focalPointImage_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	icon?: InputMaybe<Scalars['String']>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAsset?: InputMaybe<CfImageAssetNestedFilter>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	isBannerImage?: InputMaybe<Scalars['Boolean']>;
	isBannerImage_exists?: InputMaybe<Scalars['Boolean']>;
	isBannerImage_not?: InputMaybe<Scalars['Boolean']>;
	isInlineLayout?: InputMaybe<Scalars['Boolean']>;
	isInlineLayout_exists?: InputMaybe<Scalars['Boolean']>;
	isInlineLayout_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText?: InputMaybe<Scalars['String']>;
	subText_contains?: InputMaybe<Scalars['String']>;
	subText_exists?: InputMaybe<Scalars['Boolean']>;
	subText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText_not?: InputMaybe<Scalars['String']>;
	subText_not_contains?: InputMaybe<Scalars['String']>;
	subText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tc?: InputMaybe<CfTermsAndConditionsNestedFilter>;
	tc_exists?: InputMaybe<Scalars['Boolean']>;
	templateHeight?: InputMaybe<Scalars['String']>;
	templateHeight_contains?: InputMaybe<Scalars['String']>;
	templateHeight_exists?: InputMaybe<Scalars['Boolean']>;
	templateHeight_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateHeight_not?: InputMaybe<Scalars['String']>;
	templateHeight_not_contains?: InputMaybe<Scalars['String']>;
	templateHeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title?: InputMaybe<Scalars['String']>;
	titleVariant?: InputMaybe<Scalars['String']>;
	titleVariant_contains?: InputMaybe<Scalars['String']>;
	titleVariant_exists?: InputMaybe<Scalars['Boolean']>;
	titleVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleVariant_not?: InputMaybe<Scalars['String']>;
	titleVariant_not_contains?: InputMaybe<Scalars['String']>;
	titleVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	useRoundedCorners?: InputMaybe<Scalars['Boolean']>;
	useRoundedCorners_exists?: InputMaybe<Scalars['Boolean']>;
	useRoundedCorners_not?: InputMaybe<Scalars['Boolean']>;
};

export type SingleMessageLinkingCollections = {
	__typename?: 'SingleMessageLinkingCollections';
	appointmentBookingPageCollection?: Maybe<AppointmentBookingPageCollection>;
	carouselCollection?: Maybe<CarouselCollection>;
	columnsCollection?: Maybe<ColumnsCollection>;
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
	segmentsContainerCollection?: Maybe<SegmentsContainerCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
};

export type SingleMessageLinkingCollectionsAppointmentBookingPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsAppointmentBookingPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsCarouselCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsCarouselCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsColumnsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsColumnsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsProviderDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsSegmentsContainerCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsSegmentsContainerCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SingleMessageLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SingleMessageLinkingCollectionsAppointmentBookingPageCollectionOrder =
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'metaDescription_ASC'
	| 'metaDescription_DESC'
	| 'metaTitle_ASC'
	| 'metaTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'theme_ASC'
	| 'theme_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SingleMessageLinkingCollectionsCarouselCollectionOrder =
	| 'arrowsColor_ASC'
	| 'arrowsColor_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRounded_ASC'
	| 'imageRounded_DESC'
	| 'imageWidth_ASC'
	| 'imageWidth_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quotesColor_ASC'
	| 'quotesColor_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SingleMessageLinkingCollectionsColumnsCollectionOrder =
	| 'allowEqualHeights_ASC'
	| 'allowEqualHeights_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'mobileDisplayAccordion_ASC'
	| 'mobileDisplayAccordion_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SingleMessageLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SingleMessageLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SingleMessageLinkingCollectionsProviderDetailsPageCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type SingleMessageLinkingCollectionsSegmentsContainerCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type SingleMessageLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SingleMessageLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type SingleMessageOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentFillVerticalSpace_ASC'
	| 'contentFillVerticalSpace_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isBannerImage_ASC'
	| 'isBannerImage_DESC'
	| 'isInlineLayout_ASC'
	| 'isInlineLayout_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subText_ASC'
	| 'subText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateHeight_ASC'
	| 'templateHeight_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleVariant_ASC'
	| 'titleVariant_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'useRoundedCorners_ASC'
	| 'useRoundedCorners_DESC';

export type SinglePredefinedQuestionnaireAnswerInput = {
	answerId: Scalars['NonEmptyString'];
};

export type SinglePredefinedQuestionnaireAnswerScheduleInput = {
	answerId: Scalars['NonEmptyString'];
	questionId: Scalars['NonEmptyString'];
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContent = Entry &
	_Node & {
		__typename?: 'SmallScreenContent';
		_id: Scalars['ID'];
		bodySmallScreen?: Maybe<SmallScreenContentBodySmallScreen>;
		contentfulMetadata: ContentfulMetadata;
		controllableVideo?: Maybe<Video>;
		entryName?: Maybe<Scalars['String']>;
		eyebrowSmallScreen?: Maybe<Scalars['String']>;
		focalPointImageSmallScreen?: Maybe<FocalPointImage>;
		headingSmallScreen?: Maybe<SmallScreenContentHeadingSmallScreen>;
		imageSmallScreen?: Maybe<Asset>;
		linkedFrom?: Maybe<SmallScreenContentLinkingCollections>;
		mediaFirst?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		videoSmallScreen?: Maybe<Asset>;
	};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentBodySmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentControllableVideoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoFilter>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentEntryNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentEyebrowSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentFocalPointImageSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<FocalPointImageFilter>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentHeadingSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentImageSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentMediaFirstArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Component for small screen content fields. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smallScreenContent) */
export type SmallScreenContentVideoSmallScreenArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

export type SmallScreenContentBodySmallScreen = {
	__typename?: 'SmallScreenContentBodySmallScreen';
	json: Scalars['JSON'];
	links: SmallScreenContentBodySmallScreenLinks;
};

export type SmallScreenContentBodySmallScreenAssets = {
	__typename?: 'SmallScreenContentBodySmallScreenAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type SmallScreenContentBodySmallScreenEntries = {
	__typename?: 'SmallScreenContentBodySmallScreenEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type SmallScreenContentBodySmallScreenLinks = {
	__typename?: 'SmallScreenContentBodySmallScreenLinks';
	assets: SmallScreenContentBodySmallScreenAssets;
	entries: SmallScreenContentBodySmallScreenEntries;
	resources: SmallScreenContentBodySmallScreenResources;
};

export type SmallScreenContentBodySmallScreenResources = {
	__typename?: 'SmallScreenContentBodySmallScreenResources';
	block: Array<SmallScreenContentBodySmallScreenResourcesBlock>;
	hyperlink: Array<SmallScreenContentBodySmallScreenResourcesHyperlink>;
	inline: Array<SmallScreenContentBodySmallScreenResourcesInline>;
};

export type SmallScreenContentBodySmallScreenResourcesBlock = ResourceLink & {
	__typename?: 'SmallScreenContentBodySmallScreenResourcesBlock';
	sys: ResourceSys;
};

export type SmallScreenContentBodySmallScreenResourcesHyperlink = ResourceLink & {
	__typename?: 'SmallScreenContentBodySmallScreenResourcesHyperlink';
	sys: ResourceSys;
};

export type SmallScreenContentBodySmallScreenResourcesInline = ResourceLink & {
	__typename?: 'SmallScreenContentBodySmallScreenResourcesInline';
	sys: ResourceSys;
};

export type SmallScreenContentCollection = {
	__typename?: 'SmallScreenContentCollection';
	items: Array<Maybe<SmallScreenContent>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SmallScreenContentFilter = {
	AND?: InputMaybe<Array<InputMaybe<SmallScreenContentFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SmallScreenContentFilter>>>;
	bodySmallScreen_contains?: InputMaybe<Scalars['String']>;
	bodySmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	bodySmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	controllableVideo?: InputMaybe<CfVideoNestedFilter>;
	controllableVideo_exists?: InputMaybe<Scalars['Boolean']>;
	entryName?: InputMaybe<Scalars['String']>;
	entryName_contains?: InputMaybe<Scalars['String']>;
	entryName_exists?: InputMaybe<Scalars['Boolean']>;
	entryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	entryName_not?: InputMaybe<Scalars['String']>;
	entryName_not_contains?: InputMaybe<Scalars['String']>;
	entryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowSmallScreen?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreen_contains?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowSmallScreen_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowSmallScreen_not?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreen_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImageSmallScreen?: InputMaybe<CfFocalPointImageNestedFilter>;
	focalPointImageSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	headingSmallScreen_contains?: InputMaybe<Scalars['String']>;
	headingSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	headingSmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	imageSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	mediaFirst?: InputMaybe<Scalars['Boolean']>;
	mediaFirst_exists?: InputMaybe<Scalars['Boolean']>;
	mediaFirst_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	videoSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
};

export type SmallScreenContentHeadingSmallScreen = {
	__typename?: 'SmallScreenContentHeadingSmallScreen';
	json: Scalars['JSON'];
	links: SmallScreenContentHeadingSmallScreenLinks;
};

export type SmallScreenContentHeadingSmallScreenAssets = {
	__typename?: 'SmallScreenContentHeadingSmallScreenAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type SmallScreenContentHeadingSmallScreenEntries = {
	__typename?: 'SmallScreenContentHeadingSmallScreenEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type SmallScreenContentHeadingSmallScreenLinks = {
	__typename?: 'SmallScreenContentHeadingSmallScreenLinks';
	assets: SmallScreenContentHeadingSmallScreenAssets;
	entries: SmallScreenContentHeadingSmallScreenEntries;
	resources: SmallScreenContentHeadingSmallScreenResources;
};

export type SmallScreenContentHeadingSmallScreenResources = {
	__typename?: 'SmallScreenContentHeadingSmallScreenResources';
	block: Array<SmallScreenContentHeadingSmallScreenResourcesBlock>;
	hyperlink: Array<SmallScreenContentHeadingSmallScreenResourcesHyperlink>;
	inline: Array<SmallScreenContentHeadingSmallScreenResourcesInline>;
};

export type SmallScreenContentHeadingSmallScreenResourcesBlock = ResourceLink & {
	__typename?: 'SmallScreenContentHeadingSmallScreenResourcesBlock';
	sys: ResourceSys;
};

export type SmallScreenContentHeadingSmallScreenResourcesHyperlink = ResourceLink & {
	__typename?: 'SmallScreenContentHeadingSmallScreenResourcesHyperlink';
	sys: ResourceSys;
};

export type SmallScreenContentHeadingSmallScreenResourcesInline = ResourceLink & {
	__typename?: 'SmallScreenContentHeadingSmallScreenResourcesInline';
	sys: ResourceSys;
};

export type SmallScreenContentLinkingCollections = {
	__typename?: 'SmallScreenContentLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	odpHeroCollection?: Maybe<OdpHeroCollection>;
	singleMessageCollection?: Maybe<SingleMessageCollection>;
};

export type SmallScreenContentLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SmallScreenContentLinkingCollectionsHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SmallScreenContentLinkingCollectionsHalfScreenCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SmallScreenContentLinkingCollectionsHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SmallScreenContentLinkingCollectionsHeroV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SmallScreenContentLinkingCollectionsOdpHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SmallScreenContentLinkingCollectionsOdpHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SmallScreenContentLinkingCollectionsSingleMessageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SmallScreenContentLinkingCollectionsSingleMessageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SmallScreenContentLinkingCollectionsHalfScreenCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'hideContentHalf_ASC'
	| 'hideContentHalf_DESC'
	| 'isInsetLayout_ASC'
	| 'isInsetLayout_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC'
	| 'useStaticImageSize_ASC'
	| 'useStaticImageSize_DESC';

export type SmallScreenContentLinkingCollectionsHeroV2CollectionOrder =
	| 'backgroundColorSmallScreen_ASC'
	| 'backgroundColorSmallScreen_DESC'
	| 'bodySize_ASC'
	| 'bodySize_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentPositionSmallScreen_ASC'
	| 'contentPositionSmallScreen_DESC'
	| 'contentPosition_ASC'
	| 'contentPosition_DESC'
	| 'contentWidth_ASC'
	| 'contentWidth_DESC'
	| 'darkOverlay_ASC'
	| 'darkOverlay_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'headingSize_ASC'
	| 'headingSize_DESC'
	| 'heightStyle_ASC'
	| 'heightStyle_DESC'
	| 'mediaAspectRatioSmallScreen_ASC'
	| 'mediaAspectRatioSmallScreen_DESC'
	| 'promoHeadlineSize_ASC'
	| 'promoHeadlineSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type SmallScreenContentLinkingCollectionsOdpHeroCollectionOrder =
	| 'appointmentDataSource_ASC'
	| 'appointmentDataSource_DESC'
	| 'callCenterLabel_ASC'
	| 'callCenterLabel_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'officeHoursLabel_ASC'
	| 'officeHoursLabel_DESC'
	| 'promoTitle_ASC'
	| 'promoTitle_DESC'
	| 'showAddress_ASC'
	| 'showAddress_DESC'
	| 'showEmail_ASC'
	| 'showEmail_DESC'
	| 'showFax_ASC'
	| 'showFax_DESC'
	| 'showHours_ASC'
	| 'showHours_DESC'
	| 'showLocationStatusBadge_ASC'
	| 'showLocationStatusBadge_DESC'
	| 'showPhoneNumber_ASC'
	| 'showPhoneNumber_DESC'
	| 'showReviews_ASC'
	| 'showReviews_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SmallScreenContentLinkingCollectionsSingleMessageCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentFillVerticalSpace_ASC'
	| 'contentFillVerticalSpace_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isBannerImage_ASC'
	| 'isBannerImage_DESC'
	| 'isInlineLayout_ASC'
	| 'isInlineLayout_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subText_ASC'
	| 'subText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateHeight_ASC'
	| 'templateHeight_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleVariant_ASC'
	| 'titleVariant_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'useRoundedCorners_ASC'
	| 'useRoundedCorners_DESC';

export type SmallScreenContentOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowSmallScreen_ASC'
	| 'eyebrowSmallScreen_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smileAssessmentForm) */
export type SmileAssessmentForm = Entry &
	_Node & {
		__typename?: 'SmileAssessmentForm';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		formContent?: Maybe<Questionnaire>;
		linkedFrom?: Maybe<SmileAssessmentFormLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smileAssessmentForm) */
export type SmileAssessmentFormFormContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<QuestionnaireFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smileAssessmentForm) */
export type SmileAssessmentFormLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/smileAssessmentForm) */
export type SmileAssessmentFormNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SmileAssessmentFormCollection = {
	__typename?: 'SmileAssessmentFormCollection';
	items: Array<Maybe<SmileAssessmentForm>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SmileAssessmentFormFilter = {
	AND?: InputMaybe<Array<InputMaybe<SmileAssessmentFormFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SmileAssessmentFormFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	formContent?: InputMaybe<CfQuestionnaireNestedFilter>;
	formContent_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type SmileAssessmentFormLinkingCollections = {
	__typename?: 'SmileAssessmentFormLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type SmileAssessmentFormLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SmileAssessmentFormLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SmileAssessmentFormLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SmileAssessmentFormLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SmileAssessmentFormOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type SocialNetwork = {
	__typename?: 'SocialNetwork';
	type: Scalars['String'];
	url: Scalars['String'];
};

/** A spacer component to create empty spaces between other templates and to serve as anchor to scroll to [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/spacer) */
export type Spacer = Entry &
	_Node & {
		__typename?: 'Spacer';
		_id: Scalars['ID'];
		anchorId?: Maybe<Scalars['String']>;
		color?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<SpacerLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		size?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** A spacer component to create empty spaces between other templates and to serve as anchor to scroll to [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/spacer) */
export type SpacerAnchorIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A spacer component to create empty spaces between other templates and to serve as anchor to scroll to [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/spacer) */
export type SpacerColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A spacer component to create empty spaces between other templates and to serve as anchor to scroll to [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/spacer) */
export type SpacerLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A spacer component to create empty spaces between other templates and to serve as anchor to scroll to [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/spacer) */
export type SpacerNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A spacer component to create empty spaces between other templates and to serve as anchor to scroll to [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/spacer) */
export type SpacerSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SpacerCollection = {
	__typename?: 'SpacerCollection';
	items: Array<Maybe<Spacer>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SpacerFilter = {
	AND?: InputMaybe<Array<InputMaybe<SpacerFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SpacerFilter>>>;
	anchorId?: InputMaybe<Scalars['String']>;
	anchorId_contains?: InputMaybe<Scalars['String']>;
	anchorId_exists?: InputMaybe<Scalars['Boolean']>;
	anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	anchorId_not?: InputMaybe<Scalars['String']>;
	anchorId_not_contains?: InputMaybe<Scalars['String']>;
	anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	color?: InputMaybe<Scalars['String']>;
	color_contains?: InputMaybe<Scalars['String']>;
	color_exists?: InputMaybe<Scalars['Boolean']>;
	color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	color_not?: InputMaybe<Scalars['String']>;
	color_not_contains?: InputMaybe<Scalars['String']>;
	color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size?: InputMaybe<Scalars['String']>;
	size_contains?: InputMaybe<Scalars['String']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size_not?: InputMaybe<Scalars['String']>;
	size_not_contains?: InputMaybe<Scalars['String']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type SpacerLinkingCollections = {
	__typename?: 'SpacerLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
	serviceDetailsPageCollection?: Maybe<ServiceDetailsPageCollection>;
};

export type SpacerLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SpacerLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SpacerLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SpacerLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SpacerLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SpacerLinkingCollectionsProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SpacerLinkingCollectionsProviderDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SpacerLinkingCollectionsServiceDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SpacerLinkingCollectionsServiceDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SpacerLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SpacerLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SpacerLinkingCollectionsProviderDetailsPageCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type SpacerLinkingCollectionsServiceDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type SpacerOrder =
	| 'anchorId_ASC'
	| 'anchorId_DESC'
	| 'color_ASC'
	| 'color_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type Staff = Entry &
	_Node & {
		__typename?: 'Staff';
		_id: Scalars['ID'];
		biography?: Maybe<Scalars['String']>;
		collegeCity?: Maybe<Scalars['String']>;
		collegeName?: Maybe<Scalars['String']>;
		collegeState?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		firstName?: Maybe<Scalars['String']>;
		graduationDate?: Maybe<Scalars['String']>;
		hireDate?: Maybe<Scalars['String']>;
		image?: Maybe<Asset>;
		jobTitle?: Maybe<Scalars['String']>;
		languages?: Maybe<Array<Maybe<Scalars['String']>>>;
		lastName?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<StaffLinkingCollections>;
		location?: Maybe<Scalars['String']>;
		name?: Maybe<Scalars['String']>;
		specialties?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffBiographyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffCollegeCityArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffCollegeNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffCollegeStateArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffFirstNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffGraduationDateArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffHireDateArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffJobTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffLanguagesArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffLastNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffLocationArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** For WN Research [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/staff) */
export type StaffSpecialtiesArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type StaffCollection = {
	__typename?: 'StaffCollection';
	items: Array<Maybe<Staff>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type StaffFilter = {
	AND?: InputMaybe<Array<InputMaybe<StaffFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<StaffFilter>>>;
	biography?: InputMaybe<Scalars['String']>;
	biography_contains?: InputMaybe<Scalars['String']>;
	biography_exists?: InputMaybe<Scalars['Boolean']>;
	biography_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	biography_not?: InputMaybe<Scalars['String']>;
	biography_not_contains?: InputMaybe<Scalars['String']>;
	biography_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeCity?: InputMaybe<Scalars['String']>;
	collegeCity_contains?: InputMaybe<Scalars['String']>;
	collegeCity_exists?: InputMaybe<Scalars['Boolean']>;
	collegeCity_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeCity_not?: InputMaybe<Scalars['String']>;
	collegeCity_not_contains?: InputMaybe<Scalars['String']>;
	collegeCity_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeName?: InputMaybe<Scalars['String']>;
	collegeName_contains?: InputMaybe<Scalars['String']>;
	collegeName_exists?: InputMaybe<Scalars['Boolean']>;
	collegeName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeName_not?: InputMaybe<Scalars['String']>;
	collegeName_not_contains?: InputMaybe<Scalars['String']>;
	collegeName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeState?: InputMaybe<Scalars['String']>;
	collegeState_contains?: InputMaybe<Scalars['String']>;
	collegeState_exists?: InputMaybe<Scalars['Boolean']>;
	collegeState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeState_not?: InputMaybe<Scalars['String']>;
	collegeState_not_contains?: InputMaybe<Scalars['String']>;
	collegeState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	firstName?: InputMaybe<Scalars['String']>;
	firstName_contains?: InputMaybe<Scalars['String']>;
	firstName_exists?: InputMaybe<Scalars['Boolean']>;
	firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	firstName_not?: InputMaybe<Scalars['String']>;
	firstName_not_contains?: InputMaybe<Scalars['String']>;
	firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	graduationDate?: InputMaybe<Scalars['String']>;
	graduationDate_contains?: InputMaybe<Scalars['String']>;
	graduationDate_exists?: InputMaybe<Scalars['Boolean']>;
	graduationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	graduationDate_not?: InputMaybe<Scalars['String']>;
	graduationDate_not_contains?: InputMaybe<Scalars['String']>;
	graduationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	hireDate?: InputMaybe<Scalars['String']>;
	hireDate_contains?: InputMaybe<Scalars['String']>;
	hireDate_exists?: InputMaybe<Scalars['Boolean']>;
	hireDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	hireDate_not?: InputMaybe<Scalars['String']>;
	hireDate_not_contains?: InputMaybe<Scalars['String']>;
	hireDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	jobTitle?: InputMaybe<Scalars['String']>;
	jobTitle_contains?: InputMaybe<Scalars['String']>;
	jobTitle_exists?: InputMaybe<Scalars['Boolean']>;
	jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	jobTitle_not?: InputMaybe<Scalars['String']>;
	jobTitle_not_contains?: InputMaybe<Scalars['String']>;
	jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	languages_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	languages_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	languages_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	languages_exists?: InputMaybe<Scalars['Boolean']>;
	lastName?: InputMaybe<Scalars['String']>;
	lastName_contains?: InputMaybe<Scalars['String']>;
	lastName_exists?: InputMaybe<Scalars['Boolean']>;
	lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	lastName_not?: InputMaybe<Scalars['String']>;
	lastName_not_contains?: InputMaybe<Scalars['String']>;
	lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	location?: InputMaybe<Scalars['String']>;
	location_contains?: InputMaybe<Scalars['String']>;
	location_exists?: InputMaybe<Scalars['Boolean']>;
	location_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	location_not?: InputMaybe<Scalars['String']>;
	location_not_contains?: InputMaybe<Scalars['String']>;
	location_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	specialties?: InputMaybe<Scalars['String']>;
	specialties_contains?: InputMaybe<Scalars['String']>;
	specialties_exists?: InputMaybe<Scalars['Boolean']>;
	specialties_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	specialties_not?: InputMaybe<Scalars['String']>;
	specialties_not_contains?: InputMaybe<Scalars['String']>;
	specialties_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type StaffLinkingCollections = {
	__typename?: 'StaffLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	meetOurDoctorsCollection?: Maybe<MeetOurDoctorsCollection>;
};

export type StaffLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type StaffLinkingCollectionsMeetOurDoctorsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<StaffLinkingCollectionsMeetOurDoctorsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type StaffLinkingCollectionsMeetOurDoctorsCollectionOrder =
	| 'description_ASC'
	| 'description_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sectionBackground_ASC'
	| 'sectionBackground_DESC'
	| 'sectionInfoAlignment_ASC'
	| 'sectionInfoAlignment_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type StaffOrder =
	| 'collegeCity_ASC'
	| 'collegeCity_DESC'
	| 'collegeName_ASC'
	| 'collegeName_DESC'
	| 'collegeState_ASC'
	| 'collegeState_DESC'
	| 'firstName_ASC'
	| 'firstName_DESC'
	| 'graduationDate_ASC'
	| 'graduationDate_DESC'
	| 'hireDate_ASC'
	| 'hireDate_DESC'
	| 'jobTitle_ASC'
	| 'jobTitle_DESC'
	| 'lastName_ASC'
	| 'lastName_DESC'
	| 'location_ASC'
	| 'location_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'specialties_ASC'
	| 'specialties_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** A container component for state links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stateLinks) */
export type StateLinks = Entry &
	_Node & {
		__typename?: 'StateLinks';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<StateLinksLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sectionTitle?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** A container component for state links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stateLinks) */
export type StateLinksLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A container component for state links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stateLinks) */
export type StateLinksNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A container component for state links (ODP) [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stateLinks) */
export type StateLinksSectionTitleArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type StateLinksCollection = {
	__typename?: 'StateLinksCollection';
	items: Array<Maybe<StateLinks>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type StateLinksFilter = {
	AND?: InputMaybe<Array<InputMaybe<StateLinksFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<StateLinksFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle?: InputMaybe<Scalars['String']>;
	sectionTitle_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_exists?: InputMaybe<Scalars['Boolean']>;
	sectionTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sectionTitle_not?: InputMaybe<Scalars['String']>;
	sectionTitle_not_contains?: InputMaybe<Scalars['String']>;
	sectionTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type StateLinksLinkingCollections = {
	__typename?: 'StateLinksLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type StateLinksLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type StateLinksLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<StateLinksLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type StateLinksLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type StateLinksOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sectionTitle_ASC'
	| 'sectionTitle_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type Stepper = Entry &
	_Node & {
		__typename?: 'Stepper';
		_id: Scalars['ID'];
		confirmButtonText?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		continueButtonText?: Maybe<Scalars['String']>;
		headingScheduledText?: Maybe<Scalars['String']>;
		headingText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<StepperLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		needAssistanceText?: Maybe<Scalars['String']>;
		stepLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
		stepsCollection?: Maybe<StepperStepsCollection>;
		sys: Sys;
	};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperConfirmButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperContinueButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperHeadingScheduledTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperHeadingTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperNeedAssistanceTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperStepLabelsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** NOTE: only for Scheduling, not ready for general consumption [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/stepper) */
export type StepperStepsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<StepperStepsFilter>;
};

export type StepperCollection = {
	__typename?: 'StepperCollection';
	items: Array<Maybe<Stepper>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type StepperFilter = {
	AND?: InputMaybe<Array<InputMaybe<StepperFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<StepperFilter>>>;
	confirmButtonText?: InputMaybe<Scalars['String']>;
	confirmButtonText_contains?: InputMaybe<Scalars['String']>;
	confirmButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	confirmButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	confirmButtonText_not?: InputMaybe<Scalars['String']>;
	confirmButtonText_not_contains?: InputMaybe<Scalars['String']>;
	confirmButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	continueButtonText?: InputMaybe<Scalars['String']>;
	continueButtonText_contains?: InputMaybe<Scalars['String']>;
	continueButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	continueButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	continueButtonText_not?: InputMaybe<Scalars['String']>;
	continueButtonText_not_contains?: InputMaybe<Scalars['String']>;
	continueButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingScheduledText?: InputMaybe<Scalars['String']>;
	headingScheduledText_contains?: InputMaybe<Scalars['String']>;
	headingScheduledText_exists?: InputMaybe<Scalars['Boolean']>;
	headingScheduledText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingScheduledText_not?: InputMaybe<Scalars['String']>;
	headingScheduledText_not_contains?: InputMaybe<Scalars['String']>;
	headingScheduledText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingText?: InputMaybe<Scalars['String']>;
	headingText_contains?: InputMaybe<Scalars['String']>;
	headingText_exists?: InputMaybe<Scalars['Boolean']>;
	headingText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingText_not?: InputMaybe<Scalars['String']>;
	headingText_not_contains?: InputMaybe<Scalars['String']>;
	headingText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	needAssistanceText?: InputMaybe<Scalars['String']>;
	needAssistanceText_contains?: InputMaybe<Scalars['String']>;
	needAssistanceText_exists?: InputMaybe<Scalars['Boolean']>;
	needAssistanceText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	needAssistanceText_not?: InputMaybe<Scalars['String']>;
	needAssistanceText_not_contains?: InputMaybe<Scalars['String']>;
	needAssistanceText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepLabels_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepLabels_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepLabels_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepLabels_exists?: InputMaybe<Scalars['Boolean']>;
	steps?: InputMaybe<CfstepsMultiTypeNestedFilter>;
	stepsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type StepperLinkingCollections = {
	__typename?: 'StepperLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
	stepperCollection?: Maybe<StepperCollection>;
};

export type StepperLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type StepperLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<StepperLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type StepperLinkingCollectionsStepperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<StepperLinkingCollectionsStepperCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type StepperLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type StepperLinkingCollectionsStepperCollectionOrder =
	| 'confirmButtonText_ASC'
	| 'confirmButtonText_DESC'
	| 'continueButtonText_ASC'
	| 'continueButtonText_DESC'
	| 'headingScheduledText_ASC'
	| 'headingScheduledText_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'needAssistanceText_ASC'
	| 'needAssistanceText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type StepperOrder =
	| 'confirmButtonText_ASC'
	| 'confirmButtonText_DESC'
	| 'continueButtonText_ASC'
	| 'continueButtonText_DESC'
	| 'headingScheduledText_ASC'
	| 'headingScheduledText_DESC'
	| 'headingText_ASC'
	| 'headingText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'needAssistanceText_ASC'
	| 'needAssistanceText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type StepperStepsCollection = {
	__typename?: 'StepperStepsCollection';
	items: Array<Maybe<StepperStepsItem>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type StepperStepsFilter = {
	AND?: InputMaybe<Array<InputMaybe<StepperStepsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<StepperStepsFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type StepperStepsItem =
	| ConfirmationStep
	| LocationStep
	| QuestionStep
	| ReviewStep
	| SchemaStep
	| SharedForm
	| Stepper;

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCard = Entry &
	_Node & {
		__typename?: 'SuccessCard';
		_id: Scalars['ID'];
		buttonLink?: Maybe<Scalars['String']>;
		buttonText?: Maybe<Scalars['String']>;
		cardImage?: Maybe<Asset>;
		contentfulMetadata: ContentfulMetadata;
		descriptionText?: Maybe<Scalars['String']>;
		imageAltText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<SuccessCardLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		titleText?: Maybe<Scalars['String']>;
	};

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCardButtonLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCardButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCardCardImageArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCardDescriptionTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCardImageAltTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCardLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCardNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** A card that is displayed at the bottom of the confirmation page [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/successCard) */
export type SuccessCardTitleTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type SuccessCardCollection = {
	__typename?: 'SuccessCardCollection';
	items: Array<Maybe<SuccessCard>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type SuccessCardFilter = {
	AND?: InputMaybe<Array<InputMaybe<SuccessCardFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<SuccessCardFilter>>>;
	buttonLink?: InputMaybe<Scalars['String']>;
	buttonLink_contains?: InputMaybe<Scalars['String']>;
	buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLink_not?: InputMaybe<Scalars['String']>;
	buttonLink_not_contains?: InputMaybe<Scalars['String']>;
	buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardImage_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	descriptionText?: InputMaybe<Scalars['String']>;
	descriptionText_contains?: InputMaybe<Scalars['String']>;
	descriptionText_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	descriptionText_not?: InputMaybe<Scalars['String']>;
	descriptionText_not_contains?: InputMaybe<Scalars['String']>;
	descriptionText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAltText?: InputMaybe<Scalars['String']>;
	imageAltText_contains?: InputMaybe<Scalars['String']>;
	imageAltText_exists?: InputMaybe<Scalars['Boolean']>;
	imageAltText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAltText_not?: InputMaybe<Scalars['String']>;
	imageAltText_not_contains?: InputMaybe<Scalars['String']>;
	imageAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	titleText?: InputMaybe<Scalars['String']>;
	titleText_contains?: InputMaybe<Scalars['String']>;
	titleText_exists?: InputMaybe<Scalars['Boolean']>;
	titleText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleText_not?: InputMaybe<Scalars['String']>;
	titleText_not_contains?: InputMaybe<Scalars['String']>;
	titleText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SuccessCardLinkingCollections = {
	__typename?: 'SuccessCardLinkingCollections';
	confirmationStepCollection?: Maybe<ConfirmationStepCollection>;
	entryCollection?: Maybe<EntryCollection>;
};

export type SuccessCardLinkingCollectionsConfirmationStepCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<SuccessCardLinkingCollectionsConfirmationStepCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SuccessCardLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type SuccessCardLinkingCollectionsConfirmationStepCollectionOrder =
	| 'accountNumberText_ASC'
	| 'accountNumberText_DESC'
	| 'cardInfoText_ASC'
	| 'cardInfoText_DESC'
	| 'confirmationPageName_ASC'
	| 'confirmationPageName_DESC'
	| 'copyTextButtonText_ASC'
	| 'copyTextButtonText_DESC'
	| 'stepNumber_ASC'
	| 'stepNumber_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type SuccessCardOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'imageAltText_ASC'
	| 'imageAltText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleText_ASC'
	| 'titleText_DESC';

export type Suffix = 'DDS' | 'DMD' | 'ESQ' | 'II' | 'III' | 'IV' | 'JR' | 'MD' | 'SR' | 'V';

export type Sys = {
	__typename?: 'Sys';
	environmentId: Scalars['String'];
	firstPublishedAt?: Maybe<Scalars['DateTime']>;
	id: Scalars['String'];
	/** The locale that was requested. */
	locale?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	publishedVersion?: Maybe<Scalars['Int']>;
	spaceId: Scalars['String'];
};

export type SysFilter = {
	firstPublishedAt?: InputMaybe<Scalars['DateTime']>;
	firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>;
	firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>;
	firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>;
	firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>;
	firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>;
	firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>;
	firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	id?: InputMaybe<Scalars['String']>;
	id_contains?: InputMaybe<Scalars['String']>;
	id_exists?: InputMaybe<Scalars['Boolean']>;
	id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_not?: InputMaybe<Scalars['String']>;
	id_not_contains?: InputMaybe<Scalars['String']>;
	id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	publishedAt_exists?: InputMaybe<Scalars['Boolean']>;
	publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
	publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
	publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
	publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
	publishedAt_not?: InputMaybe<Scalars['DateTime']>;
	publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	publishedVersion?: InputMaybe<Scalars['Float']>;
	publishedVersion_exists?: InputMaybe<Scalars['Boolean']>;
	publishedVersion_gt?: InputMaybe<Scalars['Float']>;
	publishedVersion_gte?: InputMaybe<Scalars['Float']>;
	publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	publishedVersion_lt?: InputMaybe<Scalars['Float']>;
	publishedVersion_lte?: InputMaybe<Scalars['Float']>;
	publishedVersion_not?: InputMaybe<Scalars['Float']>;
	publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tab) */
export type Tab = Entry &
	_Node & {
		__typename?: 'Tab';
		_id: Scalars['ID'];
		content?: Maybe<Accordions>;
		contentfulMetadata: ContentfulMetadata;
		label?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<TabLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tab) */
export type TabContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<AccordionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tab) */
export type TabLabelArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tab) */
export type TabLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tab) */
export type TabNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type TabCollection = {
	__typename?: 'TabCollection';
	items: Array<Maybe<Tab>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TabFilter = {
	AND?: InputMaybe<Array<InputMaybe<TabFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<TabFilter>>>;
	content?: InputMaybe<CfAccordionsNestedFilter>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type TabLinkingCollections = {
	__typename?: 'TabLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	tabsCollection?: Maybe<TabsCollection>;
};

export type TabLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TabLinkingCollectionsTabsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TabLinkingCollectionsTabsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TabLinkingCollectionsTabsCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tabRowVariant_ASC'
	| 'tabRowVariant_DESC'
	| 'tabsSize_ASC'
	| 'tabsSize_DESC'
	| 'withContentInnerGap_ASC'
	| 'withContentInnerGap_DESC';

export type TabOrder =
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type Table = Entry &
	_Node & {
		__typename?: 'Table';
		_id: Scalars['ID'];
		bodyRowsCollection?: Maybe<TableBodyRowsCollection>;
		border?: Maybe<Scalars['Boolean']>;
		contentfulMetadata: ContentfulMetadata;
		firstColumnSticky?: Maybe<Scalars['Boolean']>;
		fontSize?: Maybe<Scalars['String']>;
		footnote?: Maybe<TableFootnote>;
		headerRow?: Maybe<TableRow>;
		linkedFrom?: Maybe<TableLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		tableContent?: Maybe<TableTableContent>;
		variant?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableBodyRowsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TableBodyRowsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TableRowFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableBorderArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableFirstColumnStickyArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableFontSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableFootnoteArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableHeaderRowArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<TableRowFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableTableContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/table) */
export type TableVariantArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type TableBodyRowsCollection = {
	__typename?: 'TableBodyRowsCollection';
	items: Array<Maybe<TableRow>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TableBodyRowsCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type TableCollection = {
	__typename?: 'TableCollection';
	items: Array<Maybe<Table>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TableFilter = {
	AND?: InputMaybe<Array<InputMaybe<TableFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<TableFilter>>>;
	bodyRows?: InputMaybe<CfTableRowNestedFilter>;
	bodyRowsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	border?: InputMaybe<Scalars['Boolean']>;
	border_exists?: InputMaybe<Scalars['Boolean']>;
	border_not?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	firstColumnSticky?: InputMaybe<Scalars['Boolean']>;
	firstColumnSticky_exists?: InputMaybe<Scalars['Boolean']>;
	firstColumnSticky_not?: InputMaybe<Scalars['Boolean']>;
	fontSize?: InputMaybe<Scalars['String']>;
	fontSize_contains?: InputMaybe<Scalars['String']>;
	fontSize_exists?: InputMaybe<Scalars['Boolean']>;
	fontSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fontSize_not?: InputMaybe<Scalars['String']>;
	fontSize_not_contains?: InputMaybe<Scalars['String']>;
	fontSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	footnote_contains?: InputMaybe<Scalars['String']>;
	footnote_exists?: InputMaybe<Scalars['Boolean']>;
	footnote_not_contains?: InputMaybe<Scalars['String']>;
	headerRow?: InputMaybe<CfTableRowNestedFilter>;
	headerRow_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tableContent_contains?: InputMaybe<Scalars['String']>;
	tableContent_exists?: InputMaybe<Scalars['Boolean']>;
	tableContent_not_contains?: InputMaybe<Scalars['String']>;
	variant?: InputMaybe<Scalars['String']>;
	variant_contains?: InputMaybe<Scalars['String']>;
	variant_exists?: InputMaybe<Scalars['Boolean']>;
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	variant_not?: InputMaybe<Scalars['String']>;
	variant_not_contains?: InputMaybe<Scalars['String']>;
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TableFootnote = {
	__typename?: 'TableFootnote';
	json: Scalars['JSON'];
	links: TableFootnoteLinks;
};

export type TableFootnoteAssets = {
	__typename?: 'TableFootnoteAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type TableFootnoteEntries = {
	__typename?: 'TableFootnoteEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type TableFootnoteLinks = {
	__typename?: 'TableFootnoteLinks';
	assets: TableFootnoteAssets;
	entries: TableFootnoteEntries;
	resources: TableFootnoteResources;
};

export type TableFootnoteResources = {
	__typename?: 'TableFootnoteResources';
	block: Array<TableFootnoteResourcesBlock>;
	hyperlink: Array<TableFootnoteResourcesHyperlink>;
	inline: Array<TableFootnoteResourcesInline>;
};

export type TableFootnoteResourcesBlock = ResourceLink & {
	__typename?: 'TableFootnoteResourcesBlock';
	sys: ResourceSys;
};

export type TableFootnoteResourcesHyperlink = ResourceLink & {
	__typename?: 'TableFootnoteResourcesHyperlink';
	sys: ResourceSys;
};

export type TableFootnoteResourcesInline = ResourceLink & {
	__typename?: 'TableFootnoteResourcesInline';
	sys: ResourceSys;
};

export type TableLinkingCollections = {
	__typename?: 'TableLinkingCollections';
	compareCollection?: Maybe<CompareCollection>;
	entryCollection?: Maybe<EntryCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
};

export type TableLinkingCollectionsCompareCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TableLinkingCollectionsCompareCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TableLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TableLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TableLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TableLinkingCollectionsCompareCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type TableLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type TableOrder =
	| 'border_ASC'
	| 'border_DESC'
	| 'firstColumnSticky_ASC'
	| 'firstColumnSticky_DESC'
	| 'fontSize_ASC'
	| 'fontSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tableRow) */
export type TableRow = Entry &
	_Node & {
		__typename?: 'TableRow';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		imagesInTableCellsCollection?: Maybe<TableRowImagesInTableCellsCollection>;
		linkedFrom?: Maybe<TableRowLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		tableCellsJsonData?: Maybe<Scalars['JSON']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tableRow) */
export type TableRowImagesInTableCellsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TableRowImagesInTableCellsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ImageAssetFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tableRow) */
export type TableRowLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tableRow) */
export type TableRowNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tableRow) */
export type TableRowTableCellsJsonDataArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type TableRowCollection = {
	__typename?: 'TableRowCollection';
	items: Array<Maybe<TableRow>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TableRowFilter = {
	AND?: InputMaybe<Array<InputMaybe<TableRowFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<TableRowFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	imagesInTableCells?: InputMaybe<CfImageAssetNestedFilter>;
	imagesInTableCellsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tableCellsJsonData_exists?: InputMaybe<Scalars['Boolean']>;
};

export type TableRowImagesInTableCellsCollection = {
	__typename?: 'TableRowImagesInTableCellsCollection';
	items: Array<Maybe<ImageAsset>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TableRowImagesInTableCellsCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type TableRowLinkingCollections = {
	__typename?: 'TableRowLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	tableCollection?: Maybe<TableCollection>;
};

export type TableRowLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TableRowLinkingCollectionsTableCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TableRowLinkingCollectionsTableCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TableRowLinkingCollectionsTableCollectionOrder =
	| 'border_ASC'
	| 'border_DESC'
	| 'firstColumnSticky_ASC'
	| 'firstColumnSticky_DESC'
	| 'fontSize_ASC'
	| 'fontSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type TableRowOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type TableTableContent = {
	__typename?: 'TableTableContent';
	json: Scalars['JSON'];
	links: TableTableContentLinks;
};

export type TableTableContentAssets = {
	__typename?: 'TableTableContentAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type TableTableContentEntries = {
	__typename?: 'TableTableContentEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type TableTableContentLinks = {
	__typename?: 'TableTableContentLinks';
	assets: TableTableContentAssets;
	entries: TableTableContentEntries;
	resources: TableTableContentResources;
};

export type TableTableContentResources = {
	__typename?: 'TableTableContentResources';
	block: Array<TableTableContentResourcesBlock>;
	hyperlink: Array<TableTableContentResourcesHyperlink>;
	inline: Array<TableTableContentResourcesInline>;
};

export type TableTableContentResourcesBlock = ResourceLink & {
	__typename?: 'TableTableContentResourcesBlock';
	sys: ResourceSys;
};

export type TableTableContentResourcesHyperlink = ResourceLink & {
	__typename?: 'TableTableContentResourcesHyperlink';
	sys: ResourceSys;
};

export type TableTableContentResourcesInline = ResourceLink & {
	__typename?: 'TableTableContentResourcesInline';
	sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tabs) */
export type Tabs = Entry &
	_Node & {
		__typename?: 'Tabs';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<TabsLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		tabRowVariant?: Maybe<Scalars['String']>;
		tabsCollection?: Maybe<TabsTabsCollection>;
		tabsSize?: Maybe<Scalars['String']>;
		withContentInnerGap?: Maybe<Scalars['Boolean']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tabs) */
export type TabsBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tabs) */
export type TabsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tabs) */
export type TabsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tabs) */
export type TabsTabRowVariantArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tabs) */
export type TabsTabsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TabsTabsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TabFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tabs) */
export type TabsTabsSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/tabs) */
export type TabsWithContentInnerGapArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type TabsCollection = {
	__typename?: 'TabsCollection';
	items: Array<Maybe<Tabs>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TabsFilter = {
	AND?: InputMaybe<Array<InputMaybe<TabsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<TabsFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tabRowVariant?: InputMaybe<Scalars['String']>;
	tabRowVariant_contains?: InputMaybe<Scalars['String']>;
	tabRowVariant_exists?: InputMaybe<Scalars['Boolean']>;
	tabRowVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tabRowVariant_not?: InputMaybe<Scalars['String']>;
	tabRowVariant_not_contains?: InputMaybe<Scalars['String']>;
	tabRowVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tabs?: InputMaybe<CfTabNestedFilter>;
	tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	tabsSize?: InputMaybe<Scalars['String']>;
	tabsSize_contains?: InputMaybe<Scalars['String']>;
	tabsSize_exists?: InputMaybe<Scalars['Boolean']>;
	tabsSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tabsSize_not?: InputMaybe<Scalars['String']>;
	tabsSize_not_contains?: InputMaybe<Scalars['String']>;
	tabsSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	withContentInnerGap?: InputMaybe<Scalars['Boolean']>;
	withContentInnerGap_exists?: InputMaybe<Scalars['Boolean']>;
	withContentInnerGap_not?: InputMaybe<Scalars['Boolean']>;
};

export type TabsLinkingCollections = {
	__typename?: 'TabsLinkingCollections';
	compareCollection?: Maybe<CompareCollection>;
	entryCollection?: Maybe<EntryCollection>;
	facilityDetailsPageCollection?: Maybe<FacilityDetailsPageCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type TabsLinkingCollectionsCompareCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TabsLinkingCollectionsCompareCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TabsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TabsLinkingCollectionsFacilityDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TabsLinkingCollectionsFacilityDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TabsLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TabsLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TabsLinkingCollectionsCompareCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type TabsLinkingCollectionsFacilityDetailsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'facilityId_ASC'
	| 'facilityId_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type TabsLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type TabsOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tabRowVariant_ASC'
	| 'tabRowVariant_DESC'
	| 'tabsSize_ASC'
	| 'tabsSize_DESC'
	| 'withContentInnerGap_ASC'
	| 'withContentInnerGap_DESC';

export type TabsTabsCollection = {
	__typename?: 'TabsTabsCollection';
	items: Array<Maybe<Tab>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TabsTabsCollectionOrder =
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/**
 * Represents a tag entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type TaxonomyConcept = {
	__typename?: 'TaxonomyConcept';
	id?: Maybe<Scalars['String']>;
};

export type TemporaryUnavailableError = Error & {
	__typename?: 'TemporaryUnavailableError';
	message: Scalars['String'];
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/termsAndConditions) */
export type TermsAndConditions = Entry &
	_Node & {
		__typename?: 'TermsAndConditions';
		_id: Scalars['ID'];
		content?: Maybe<TermsAndConditionsContent>;
		contentfulMetadata: ContentfulMetadata;
		headlineText?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<TermsAndConditionsLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		showTcTextAs?: Maybe<Scalars['Boolean']>;
		sys: Sys;
		tcLink?: Maybe<Scalars['String']>;
		tcText?: Maybe<Scalars['String']>;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/termsAndConditions) */
export type TermsAndConditionsContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/termsAndConditions) */
export type TermsAndConditionsHeadlineTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/termsAndConditions) */
export type TermsAndConditionsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/termsAndConditions) */
export type TermsAndConditionsNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/termsAndConditions) */
export type TermsAndConditionsShowTcTextAsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/termsAndConditions) */
export type TermsAndConditionsTcLinkArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/termsAndConditions) */
export type TermsAndConditionsTcTextArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type TermsAndConditionsCollection = {
	__typename?: 'TermsAndConditionsCollection';
	items: Array<Maybe<TermsAndConditions>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TermsAndConditionsContent = {
	__typename?: 'TermsAndConditionsContent';
	json: Scalars['JSON'];
	links: TermsAndConditionsContentLinks;
};

export type TermsAndConditionsContentAssets = {
	__typename?: 'TermsAndConditionsContentAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type TermsAndConditionsContentEntries = {
	__typename?: 'TermsAndConditionsContentEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type TermsAndConditionsContentLinks = {
	__typename?: 'TermsAndConditionsContentLinks';
	assets: TermsAndConditionsContentAssets;
	entries: TermsAndConditionsContentEntries;
	resources: TermsAndConditionsContentResources;
};

export type TermsAndConditionsContentResources = {
	__typename?: 'TermsAndConditionsContentResources';
	block: Array<TermsAndConditionsContentResourcesBlock>;
	hyperlink: Array<TermsAndConditionsContentResourcesHyperlink>;
	inline: Array<TermsAndConditionsContentResourcesInline>;
};

export type TermsAndConditionsContentResourcesBlock = ResourceLink & {
	__typename?: 'TermsAndConditionsContentResourcesBlock';
	sys: ResourceSys;
};

export type TermsAndConditionsContentResourcesHyperlink = ResourceLink & {
	__typename?: 'TermsAndConditionsContentResourcesHyperlink';
	sys: ResourceSys;
};

export type TermsAndConditionsContentResourcesInline = ResourceLink & {
	__typename?: 'TermsAndConditionsContentResourcesInline';
	sys: ResourceSys;
};

export type TermsAndConditionsFilter = {
	AND?: InputMaybe<Array<InputMaybe<TermsAndConditionsFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<TermsAndConditionsFilter>>>;
	content_contains?: InputMaybe<Scalars['String']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	content_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headlineText?: InputMaybe<Scalars['String']>;
	headlineText_contains?: InputMaybe<Scalars['String']>;
	headlineText_exists?: InputMaybe<Scalars['Boolean']>;
	headlineText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText_not?: InputMaybe<Scalars['String']>;
	headlineText_not_contains?: InputMaybe<Scalars['String']>;
	headlineText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	showTcTextAs?: InputMaybe<Scalars['Boolean']>;
	showTcTextAs_exists?: InputMaybe<Scalars['Boolean']>;
	showTcTextAs_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	tcLink?: InputMaybe<Scalars['String']>;
	tcLink_contains?: InputMaybe<Scalars['String']>;
	tcLink_exists?: InputMaybe<Scalars['Boolean']>;
	tcLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcLink_not?: InputMaybe<Scalars['String']>;
	tcLink_not_contains?: InputMaybe<Scalars['String']>;
	tcLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcText?: InputMaybe<Scalars['String']>;
	tcText_contains?: InputMaybe<Scalars['String']>;
	tcText_exists?: InputMaybe<Scalars['Boolean']>;
	tcText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcText_not?: InputMaybe<Scalars['String']>;
	tcText_not_contains?: InputMaybe<Scalars['String']>;
	tcText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TermsAndConditionsLinkingCollections = {
	__typename?: 'TermsAndConditionsLinkingCollections';
	cardCollection?: Maybe<CardCollection>;
	entryCollection?: Maybe<EntryCollection>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	pageCollection?: Maybe<PageCollection>;
	serviceCollection?: Maybe<ServiceCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
	singleMessageCollection?: Maybe<SingleMessageCollection>;
};

export type TermsAndConditionsLinkingCollectionsCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TermsAndConditionsLinkingCollectionsCardCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TermsAndConditionsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TermsAndConditionsLinkingCollectionsHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TermsAndConditionsLinkingCollectionsHalfScreenCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TermsAndConditionsLinkingCollectionsHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TermsAndConditionsLinkingCollectionsHeroV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TermsAndConditionsLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TermsAndConditionsLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TermsAndConditionsLinkingCollectionsServiceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TermsAndConditionsLinkingCollectionsServiceCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TermsAndConditionsLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TermsAndConditionsLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TermsAndConditionsLinkingCollectionsSingleMessageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TermsAndConditionsLinkingCollectionsSingleMessageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TermsAndConditionsLinkingCollectionsCardCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonIconPosition_ASC'
	| 'buttonIconPosition_DESC'
	| 'buttonIconSize_ASC'
	| 'buttonIconSize_DESC'
	| 'buttonIcon_ASC'
	| 'buttonIcon_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'buttonVariant_ASC'
	| 'buttonVariant_DESC'
	| 'cardIconAlignment_ASC'
	| 'cardIconAlignment_DESC'
	| 'cardIcon_ASC'
	| 'cardIcon_DESC'
	| 'cardTextAlignment_ASC'
	| 'cardTextAlignment_DESC'
	| 'eventType_ASC'
	| 'eventType_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageRatio_ASC'
	| 'imageRatio_DESC'
	| 'imageUrl_ASC'
	| 'imageUrl_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'pdfDownloadTitle_ASC'
	| 'pdfDownloadTitle_DESC'
	| 'priority_ASC'
	| 'priority_DESC'
	| 'showBorder_ASC'
	| 'showBorder_DESC'
	| 'slot_ASC'
	| 'slot_DESC'
	| 'stepName_ASC'
	| 'stepName_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC'
	| 'tcUrl_ASC'
	| 'tcUrl_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type TermsAndConditionsLinkingCollectionsHalfScreenCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'hideContentHalf_ASC'
	| 'hideContentHalf_DESC'
	| 'isInsetLayout_ASC'
	| 'isInsetLayout_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC'
	| 'useStaticImageSize_ASC'
	| 'useStaticImageSize_DESC';

export type TermsAndConditionsLinkingCollectionsHeroV2CollectionOrder =
	| 'backgroundColorSmallScreen_ASC'
	| 'backgroundColorSmallScreen_DESC'
	| 'bodySize_ASC'
	| 'bodySize_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentPositionSmallScreen_ASC'
	| 'contentPositionSmallScreen_DESC'
	| 'contentPosition_ASC'
	| 'contentPosition_DESC'
	| 'contentWidth_ASC'
	| 'contentWidth_DESC'
	| 'darkOverlay_ASC'
	| 'darkOverlay_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'headingSize_ASC'
	| 'headingSize_DESC'
	| 'heightStyle_ASC'
	| 'heightStyle_DESC'
	| 'mediaAspectRatioSmallScreen_ASC'
	| 'mediaAspectRatioSmallScreen_DESC'
	| 'promoHeadlineSize_ASC'
	| 'promoHeadlineSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type TermsAndConditionsLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type TermsAndConditionsLinkingCollectionsServiceCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'centerAlign_ASC'
	| 'centerAlign_DESC'
	| 'contained_ASC'
	| 'contained_DESC'
	| 'direction_ASC'
	| 'direction_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'imageLayout_ASC'
	| 'imageLayout_DESC'
	| 'mediaType_ASC'
	| 'mediaType_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHtag_ASC'
	| 'titleHtag_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'youTubeVideoId_ASC'
	| 'youTubeVideoId_DESC';

export type TermsAndConditionsLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type TermsAndConditionsLinkingCollectionsSingleMessageCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'buttonSize_ASC'
	| 'buttonSize_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonType_ASC'
	| 'buttonType_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentFillVerticalSpace_ASC'
	| 'contentFillVerticalSpace_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'fullWidth_ASC'
	| 'fullWidth_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isBannerImage_ASC'
	| 'isBannerImage_DESC'
	| 'isInlineLayout_ASC'
	| 'isInlineLayout_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'subText_ASC'
	| 'subText_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateHeight_ASC'
	| 'templateHeight_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC'
	| 'titleVariant_ASC'
	| 'titleVariant_DESC'
	| 'title_ASC'
	| 'title_DESC'
	| 'useRoundedCorners_ASC'
	| 'useRoundedCorners_DESC';

export type TermsAndConditionsOrder =
	| 'headlineText_ASC'
	| 'headlineText_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showTcTextAs_ASC'
	| 'showTcTextAs_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'tcLink_ASC'
	| 'tcLink_DESC'
	| 'tcText_ASC'
	| 'tcText_DESC';

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/textBlockSeo) */
export type TextBlockSeo = Entry &
	_Node & {
		__typename?: 'TextBlockSeo';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		content?: Maybe<TextBlockSeoContent>;
		contentfulMetadata: ContentfulMetadata;
		disablePaddingOn?: Maybe<Array<Maybe<Scalars['String']>>>;
		facilityFields?: Maybe<Array<Maybe<Scalars['String']>>>;
		linkedFrom?: Maybe<TextBlockSeoLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/textBlockSeo) */
export type TextBlockSeoBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/textBlockSeo) */
export type TextBlockSeoContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/textBlockSeo) */
export type TextBlockSeoDisablePaddingOnArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/textBlockSeo) */
export type TextBlockSeoFacilityFieldsArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/textBlockSeo) */
export type TextBlockSeoLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/textBlockSeo) */
export type TextBlockSeoNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type TextBlockSeoCollection = {
	__typename?: 'TextBlockSeoCollection';
	items: Array<Maybe<TextBlockSeo>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type TextBlockSeoContent = {
	__typename?: 'TextBlockSeoContent';
	json: Scalars['JSON'];
	links: TextBlockSeoContentLinks;
};

export type TextBlockSeoContentAssets = {
	__typename?: 'TextBlockSeoContentAssets';
	block: Array<Maybe<Asset>>;
	hyperlink: Array<Maybe<Asset>>;
};

export type TextBlockSeoContentEntries = {
	__typename?: 'TextBlockSeoContentEntries';
	block: Array<Maybe<Entry>>;
	hyperlink: Array<Maybe<Entry>>;
	inline: Array<Maybe<Entry>>;
};

export type TextBlockSeoContentLinks = {
	__typename?: 'TextBlockSeoContentLinks';
	assets: TextBlockSeoContentAssets;
	entries: TextBlockSeoContentEntries;
	resources: TextBlockSeoContentResources;
};

export type TextBlockSeoContentResources = {
	__typename?: 'TextBlockSeoContentResources';
	block: Array<TextBlockSeoContentResourcesBlock>;
	hyperlink: Array<TextBlockSeoContentResourcesHyperlink>;
	inline: Array<TextBlockSeoContentResourcesInline>;
};

export type TextBlockSeoContentResourcesBlock = ResourceLink & {
	__typename?: 'TextBlockSeoContentResourcesBlock';
	sys: ResourceSys;
};

export type TextBlockSeoContentResourcesHyperlink = ResourceLink & {
	__typename?: 'TextBlockSeoContentResourcesHyperlink';
	sys: ResourceSys;
};

export type TextBlockSeoContentResourcesInline = ResourceLink & {
	__typename?: 'TextBlockSeoContentResourcesInline';
	sys: ResourceSys;
};

export type TextBlockSeoFilter = {
	AND?: InputMaybe<Array<InputMaybe<TextBlockSeoFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<TextBlockSeoFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	content_contains?: InputMaybe<Scalars['String']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	content_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	facilityFields_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityFields_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityFields_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilityFields_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type TextBlockSeoLinkingCollections = {
	__typename?: 'TextBlockSeoLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
};

export type TextBlockSeoLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TextBlockSeoLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<TextBlockSeoLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type TextBlockSeoLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type TextBlockSeoOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type ThirdPartyInformationSharingAuthorization = {
	__typename?: 'ThirdPartyInformationSharingAuthorization';
	fullName?: Maybe<Scalars['String']>;
	period?: Maybe<Period>;
	relation?: Maybe<Scalars['String']>;
};

export type ThirdPartyInformationSharingAuthorizationInput = {
	fullName: Scalars['NonEmptyString'];
	period?: InputMaybe<PeriodInput>;
	relation: Scalars['NonEmptyString'];
};

export type TimeslotAlreadyReservedError = Error & {
	__typename?: 'TimeslotAlreadyReservedError';
	message: Scalars['String'];
};

export type TimeslotNotAvailableError = Error & {
	__typename?: 'TimeslotNotAvailableError';
	message: Scalars['String'];
};

export type Token = {
	__typename?: 'Token';
	access_token?: Maybe<Scalars['String']>;
};

export type UnderageGuarantorError = Error & {
	__typename?: 'UnderageGuarantorError';
	message: Scalars['String'];
};

export type UnderagePatientForFacilityError = Error & {
	__typename?: 'UnderagePatientForFacilityError';
	facilityCode: Scalars['String'];
	message: Scalars['String'];
	patientAge: Scalars['Int'];
};

export type UnderagePatientForVisitReasonError = Error & {
	__typename?: 'UnderagePatientForVisitReasonError';
	message: Scalars['String'];
	minimalAge?: Maybe<Scalars['Int']>;
	standardTreatmentPlan?: Maybe<Scalars['String']>;
};

export type UpdateMedicalHistoryError =
	| InvalidQuestionnaireAnswerTypeError
	| NonExistingQuestionnaireQuestionError
	| PredefinedQuestionnaireAnswerError
	| QuestionnaireAnswerRequiredError
	| ShouldNotBeAnsweredQuestionnaireAnswerError;

export type UpdateMedicalHistoryInput = {
	answers: Array<AnsweredQuestionnaireQuestionInput>;
	signature: Scalars['NonEmptyString'];
};

export type UpdateMedicalHistoryPayload = {
	__typename?: 'UpdateMedicalHistoryPayload';
	errors?: Maybe<Array<UpdateMedicalHistoryError>>;
	result?: Maybe<PatientMedicalHistory>;
};

export type UsStateCode =
	| 'AK'
	| 'AL'
	| 'AR'
	| 'AS'
	| 'AZ'
	| 'CA'
	| 'CO'
	| 'CT'
	| 'DC'
	| 'DE'
	| 'FL'
	| 'GA'
	| 'GU'
	| 'HI'
	| 'IA'
	| 'ID'
	| 'IL'
	| 'IN'
	| 'KS'
	| 'KY'
	| 'LA'
	| 'MA'
	| 'MD'
	| 'ME'
	| 'MI'
	| 'MN'
	| 'MO'
	| 'MP'
	| 'MS'
	| 'MT'
	| 'NC'
	| 'ND'
	| 'NE'
	| 'NH'
	| 'NJ'
	| 'NM'
	| 'NV'
	| 'NY'
	| 'OH'
	| 'OK'
	| 'OR'
	| 'PA'
	| 'PR'
	| 'RI'
	| 'SC'
	| 'SD'
	| 'TN'
	| 'TT'
	| 'TX'
	| 'UT'
	| 'VA'
	| 'VI'
	| 'VT'
	| 'WA'
	| 'WI'
	| 'WV'
	| 'WY';

/** User context */
export type User = {
	__typename?: 'User';
	/** Get Patient Account details */
	account?: Maybe<Account>;
	currentMedicalHistory?: Maybe<PatientMedicalHistory>;
	guarantor?: Maybe<Guarantor>;
	/**
	 * Get Patient Information
	 * @deprecated Use Personal Information query instead
	 */
	patient?: Maybe<Patient>;
	patientAuthorizations?: Maybe<PatientAuthorizations>;
	personalInformation?: Maybe<PersonalInformation>;
	/** Scheduled visit by id. */
	scheduledVisit?: Maybe<ScheduledVisit>;
	/** Scheduled visits ordered by date ascending. */
	scheduledVisits?: Maybe<ScheduledVisitList>;
};

/** User context */
export type UserScheduledVisitArgs = {
	visitId: Scalars['String'];
};

/** User context */
export type UserScheduledVisitsArgs = {
	skip?: Scalars['NonNegativeInt'];
	take?: Scalars['NonNegativeInt'];
};

/** Mutations for user */
export type UserMutation = {
	__typename?: 'UserMutation';
	addPatientAuthorization?: Maybe<AddPatientAuthorizationPayload>;
	cancelVisit?: Maybe<CancelVisitPayload>;
	confirmVisit?: Maybe<ConfirmPatientVisitPayload>;
	rescheduleVisit?: Maybe<RescheduleVisitPayload>;
	updateMedicalHistory?: Maybe<UpdateMedicalHistoryPayload>;
};

/** Mutations for user */
export type UserMutationAddPatientAuthorizationArgs = {
	input: AddPatientAuthorizationInput;
};

/** Mutations for user */
export type UserMutationCancelVisitArgs = {
	input: CancelVisitInput;
};

/** Mutations for user */
export type UserMutationConfirmVisitArgs = {
	input: ConfirmPatientVisitInput;
};

/** Mutations for user */
export type UserMutationRescheduleVisitArgs = {
	input: RescheduleVisitInput;
};

/** Mutations for user */
export type UserMutationUpdateMedicalHistoryArgs = {
	input: UpdateMedicalHistoryInput;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type Video = Entry &
	_Node & {
		__typename?: 'Video';
		_id: Scalars['ID'];
		adjustedWidth?: Maybe<Scalars['Int']>;
		autoPlay?: Maybe<Scalars['Boolean']>;
		contentfulMetadata: ContentfulMetadata;
		isLooped?: Maybe<Scalars['Boolean']>;
		isMuted?: Maybe<Scalars['Boolean']>;
		linkedFrom?: Maybe<VideoLinkingCollections>;
		media?: Maybe<Asset>;
		name?: Maybe<Scalars['String']>;
		posterMedia?: Maybe<Asset>;
		posterUrl?: Maybe<Scalars['String']>;
		sys: Sys;
		useOriginalVideoSize?: Maybe<Scalars['Boolean']>;
		youtubeId?: Maybe<Scalars['String']>;
	};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoAdjustedWidthArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoAutoPlayArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoIsLoopedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoIsMutedArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoMediaArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoPosterMediaArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoPosterUrlArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoUseOriginalVideoSizeArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** generic model for Video content [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/video) */
export type VideoYoutubeIdArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Content type used to display video using Video content type with separate video selection for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoAsset) */
export type VideoAsset = Entry &
	_Node & {
		__typename?: 'VideoAsset';
		_id: Scalars['ID'];
		contentfulMetadata: ContentfulMetadata;
		desktopVideo?: Maybe<Video>;
		linkedFrom?: Maybe<VideoAssetLinkingCollections>;
		mobileVideo?: Maybe<Video>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
	};

/** Content type used to display video using Video content type with separate video selection for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoAsset) */
export type VideoAssetDesktopVideoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoFilter>;
};

/** Content type used to display video using Video content type with separate video selection for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoAsset) */
export type VideoAssetLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content type used to display video using Video content type with separate video selection for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoAsset) */
export type VideoAssetMobileVideoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoFilter>;
};

/** Content type used to display video using Video content type with separate video selection for Desktop and Mobile. [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoAsset) */
export type VideoAssetNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

export type VideoAssetCollection = {
	__typename?: 'VideoAssetCollection';
	items: Array<Maybe<VideoAsset>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type VideoAssetFilter = {
	AND?: InputMaybe<Array<InputMaybe<VideoAssetFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<VideoAssetFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	desktopVideo?: InputMaybe<CfVideoNestedFilter>;
	desktopVideo_exists?: InputMaybe<Scalars['Boolean']>;
	mobileVideo?: InputMaybe<CfVideoNestedFilter>;
	mobileVideo_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type VideoAssetLinkingCollections = {
	__typename?: 'VideoAssetLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	heroV2Collection?: Maybe<HeroV2Collection>;
	nameValuesCollection?: Maybe<NameValuesCollection>;
	reusableContentCollection?: Maybe<ReusableContentCollection>;
	serviceLineHeroCollection?: Maybe<ServiceLineHeroCollection>;
	videoWithContentCollection?: Maybe<VideoWithContentCollection>;
};

export type VideoAssetLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoAssetLinkingCollectionsHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoAssetLinkingCollectionsHalfScreenCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoAssetLinkingCollectionsHeroV2CollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoAssetLinkingCollectionsHeroV2CollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoAssetLinkingCollectionsNameValuesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoAssetLinkingCollectionsNameValuesCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoAssetLinkingCollectionsReusableContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoAssetLinkingCollectionsReusableContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoAssetLinkingCollectionsServiceLineHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoAssetLinkingCollectionsServiceLineHeroCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoAssetLinkingCollectionsVideoWithContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoAssetLinkingCollectionsVideoWithContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoAssetLinkingCollectionsHalfScreenCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'hideContentHalf_ASC'
	| 'hideContentHalf_DESC'
	| 'isInsetLayout_ASC'
	| 'isInsetLayout_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC'
	| 'useStaticImageSize_ASC'
	| 'useStaticImageSize_DESC';

export type VideoAssetLinkingCollectionsHeroV2CollectionOrder =
	| 'backgroundColorSmallScreen_ASC'
	| 'backgroundColorSmallScreen_DESC'
	| 'bodySize_ASC'
	| 'bodySize_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'contentPositionSmallScreen_ASC'
	| 'contentPositionSmallScreen_DESC'
	| 'contentPosition_ASC'
	| 'contentPosition_DESC'
	| 'contentWidth_ASC'
	| 'contentWidth_DESC'
	| 'darkOverlay_ASC'
	| 'darkOverlay_DESC'
	| 'darkTextColor_ASC'
	| 'darkTextColor_DESC'
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'headingSize_ASC'
	| 'headingSize_DESC'
	| 'heightStyle_ASC'
	| 'heightStyle_DESC'
	| 'mediaAspectRatioSmallScreen_ASC'
	| 'mediaAspectRatioSmallScreen_DESC'
	| 'promoHeadlineSize_ASC'
	| 'promoHeadlineSize_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type VideoAssetLinkingCollectionsNameValuesCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoAssetLinkingCollectionsReusableContentCollectionOrder =
	| 'component_ASC'
	| 'component_DESC'
	| 'disclaimer_ASC'
	| 'disclaimer_DESC'
	| 'mainTitle_ASC'
	| 'mainTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'textValue_ASC'
	| 'textValue_DESC'
	| 'type_ASC'
	| 'type_DESC';

export type VideoAssetLinkingCollectionsServiceLineHeroCollectionOrder =
	| 'backgroundColorMobile_ASC'
	| 'backgroundColorMobile_DESC'
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'contentAlign_ASC'
	| 'contentAlign_DESC'
	| 'displayOfficeHoursInModal_ASC'
	| 'displayOfficeHoursInModal_DESC'
	| 'eyebrowHtag_ASC'
	| 'eyebrowHtag_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'googleMapsBusinessQuery_ASC'
	| 'googleMapsBusinessQuery_DESC'
	| 'hideHeroOnMobile_ASC'
	| 'hideHeroOnMobile_DESC'
	| 'imageWithBorderRadius_ASC'
	| 'imageWithBorderRadius_DESC'
	| 'imgDisplaysFirstOnDesktop_ASC'
	| 'imgDisplaysFirstOnDesktop_DESC'
	| 'imgDisplaysFirstOnMobile_ASC'
	| 'imgDisplaysFirstOnMobile_DESC'
	| 'mediaRoundedCornerSize_ASC'
	| 'mediaRoundedCornerSize_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'showOfficeDetails_ASC'
	| 'showOfficeDetails_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateWidth_ASC'
	| 'templateWidth_DESC';

export type VideoAssetLinkingCollectionsVideoWithContentCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoAssetOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarousel = Entry &
	_Node & {
		__typename?: 'VideoCarousel';
		_id: Scalars['ID'];
		backgroundColor?: Maybe<Scalars['String']>;
		buttonsCollection?: Maybe<VideoCarouselButtonsCollection>;
		contentfulMetadata: ContentfulMetadata;
		desktopVideosAspectRatio?: Maybe<Scalars['String']>;
		linkedFrom?: Maybe<VideoCarouselLinkingCollections>;
		mobileVideosAspectRatio?: Maybe<Scalars['String']>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		topFields?: Maybe<ContentSection>;
		videosCollection?: Maybe<VideoCarouselVideosCollection>;
	};

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarouselBackgroundColorArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarouselButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoCarouselButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarouselDesktopVideosAspectRatioArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarouselLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarouselMobileVideosAspectRatioArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarouselNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarouselTopFieldsArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentSectionFilter>;
};

/** Used for displaying horizontal scrollable videos [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoCarousel) */
export type VideoCarouselVideosCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoCarouselVideosCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<VideoWithContentFilter>;
};

export type VideoCarouselButtonsCollection = {
	__typename?: 'VideoCarouselButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type VideoCarouselButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type VideoCarouselCollection = {
	__typename?: 'VideoCarouselCollection';
	items: Array<Maybe<VideoCarousel>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type VideoCarouselFilter = {
	AND?: InputMaybe<Array<InputMaybe<VideoCarouselFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<VideoCarouselFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	desktopVideosAspectRatio?: InputMaybe<Scalars['String']>;
	desktopVideosAspectRatio_contains?: InputMaybe<Scalars['String']>;
	desktopVideosAspectRatio_exists?: InputMaybe<Scalars['Boolean']>;
	desktopVideosAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	desktopVideosAspectRatio_not?: InputMaybe<Scalars['String']>;
	desktopVideosAspectRatio_not_contains?: InputMaybe<Scalars['String']>;
	desktopVideosAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileVideosAspectRatio?: InputMaybe<Scalars['String']>;
	mobileVideosAspectRatio_contains?: InputMaybe<Scalars['String']>;
	mobileVideosAspectRatio_exists?: InputMaybe<Scalars['Boolean']>;
	mobileVideosAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileVideosAspectRatio_not?: InputMaybe<Scalars['String']>;
	mobileVideosAspectRatio_not_contains?: InputMaybe<Scalars['String']>;
	mobileVideosAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	topFields?: InputMaybe<CfContentSectionNestedFilter>;
	topFields_exists?: InputMaybe<Scalars['Boolean']>;
	videos?: InputMaybe<CfVideoWithContentNestedFilter>;
	videosCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type VideoCarouselLinkingCollections = {
	__typename?: 'VideoCarouselLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	pageCollection?: Maybe<PageCollection>;
	providerDetailsPageCollection?: Maybe<ProviderDetailsPageCollection>;
};

export type VideoCarouselLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoCarouselLinkingCollectionsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoCarouselLinkingCollectionsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoCarouselLinkingCollectionsProviderDetailsPageCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoCarouselLinkingCollectionsProviderDetailsPageCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoCarouselLinkingCollectionsPageCollectionOrder =
	| 'appName_ASC'
	| 'appName_DESC'
	| 'breadcrumbTitle_ASC'
	| 'breadcrumbTitle_DESC'
	| 'buttonText_ASC'
	| 'buttonText_DESC'
	| 'buttonUrl_ASC'
	| 'buttonUrl_DESC'
	| 'canonical_ASC'
	| 'canonical_DESC'
	| 'description_ASC'
	| 'description_DESC'
	| 'disableChatBot_ASC'
	| 'disableChatBot_DESC'
	| 'displayOnMobile_ASC'
	| 'displayOnMobile_DESC'
	| 'indexed_ASC'
	| 'indexed_DESC'
	| 'mobileBreadcrumbTitle_ASC'
	| 'mobileBreadcrumbTitle_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'overrideSchedulingButtonUrl_ASC'
	| 'overrideSchedulingButtonUrl_DESC'
	| 'pageTheme_ASC'
	| 'pageTheme_DESC'
	| 'pageTitle_ASC'
	| 'pageTitle_DESC'
	| 'path_ASC'
	| 'path_DESC'
	| 'personalizedPathPrefix_ASC'
	| 'personalizedPathPrefix_DESC'
	| 'personalizedPathSuffix_ASC'
	| 'personalizedPathSuffix_DESC'
	| 'personalized_ASC'
	| 'personalized_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type VideoCarouselLinkingCollectionsProviderDetailsPageCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'npiId_ASC'
	| 'npiId_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoCarouselOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'desktopVideosAspectRatio_ASC'
	| 'desktopVideosAspectRatio_DESC'
	| 'mobileVideosAspectRatio_ASC'
	| 'mobileVideosAspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoCarouselVideosCollection = {
	__typename?: 'VideoCarouselVideosCollection';
	items: Array<Maybe<VideoWithContent>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type VideoCarouselVideosCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoCollection = {
	__typename?: 'VideoCollection';
	items: Array<Maybe<Video>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type VideoFilter = {
	AND?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
	adjustedWidth?: InputMaybe<Scalars['Int']>;
	adjustedWidth_exists?: InputMaybe<Scalars['Boolean']>;
	adjustedWidth_gt?: InputMaybe<Scalars['Int']>;
	adjustedWidth_gte?: InputMaybe<Scalars['Int']>;
	adjustedWidth_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	adjustedWidth_lt?: InputMaybe<Scalars['Int']>;
	adjustedWidth_lte?: InputMaybe<Scalars['Int']>;
	adjustedWidth_not?: InputMaybe<Scalars['Int']>;
	adjustedWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	autoPlay?: InputMaybe<Scalars['Boolean']>;
	autoPlay_exists?: InputMaybe<Scalars['Boolean']>;
	autoPlay_not?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	isLooped?: InputMaybe<Scalars['Boolean']>;
	isLooped_exists?: InputMaybe<Scalars['Boolean']>;
	isLooped_not?: InputMaybe<Scalars['Boolean']>;
	isMuted?: InputMaybe<Scalars['Boolean']>;
	isMuted_exists?: InputMaybe<Scalars['Boolean']>;
	isMuted_not?: InputMaybe<Scalars['Boolean']>;
	media_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	posterMedia_exists?: InputMaybe<Scalars['Boolean']>;
	posterUrl?: InputMaybe<Scalars['String']>;
	posterUrl_contains?: InputMaybe<Scalars['String']>;
	posterUrl_exists?: InputMaybe<Scalars['Boolean']>;
	posterUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	posterUrl_not?: InputMaybe<Scalars['String']>;
	posterUrl_not_contains?: InputMaybe<Scalars['String']>;
	posterUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	useOriginalVideoSize?: InputMaybe<Scalars['Boolean']>;
	useOriginalVideoSize_exists?: InputMaybe<Scalars['Boolean']>;
	useOriginalVideoSize_not?: InputMaybe<Scalars['Boolean']>;
	youtubeId?: InputMaybe<Scalars['String']>;
	youtubeId_contains?: InputMaybe<Scalars['String']>;
	youtubeId_exists?: InputMaybe<Scalars['Boolean']>;
	youtubeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	youtubeId_not?: InputMaybe<Scalars['String']>;
	youtubeId_not_contains?: InputMaybe<Scalars['String']>;
	youtubeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VideoLinkingCollections = {
	__typename?: 'VideoLinkingCollections';
	birdEyeReviewsCollection?: Maybe<BirdEyeReviewsCollection>;
	entryCollection?: Maybe<EntryCollection>;
	halfScreenCollection?: Maybe<HalfScreenCollection>;
	mediaSliderCollection?: Maybe<MediaSliderCollection>;
	smallScreenContentCollection?: Maybe<SmallScreenContentCollection>;
	videoAssetCollection?: Maybe<VideoAssetCollection>;
};

export type VideoLinkingCollectionsBirdEyeReviewsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsBirdEyeReviewsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsHalfScreenCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsHalfScreenCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsMediaSliderCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsMediaSliderCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsSmallScreenContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsSmallScreenContentCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsVideoAssetCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsVideoAssetCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsBirdEyeReviewsCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'titleHeading_ASC'
	| 'titleHeading_DESC'
	| 'title_ASC'
	| 'title_DESC';

export type VideoLinkingCollectionsHalfScreenCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'eyebrowStyle_ASC'
	| 'eyebrowStyle_DESC'
	| 'eyebrow_ASC'
	| 'eyebrow_DESC'
	| 'hideContentHalf_ASC'
	| 'hideContentHalf_DESC'
	| 'isInsetLayout_ASC'
	| 'isInsetLayout_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'templateSize_ASC'
	| 'templateSize_DESC'
	| 'useStaticImageSize_ASC'
	| 'useStaticImageSize_DESC';

export type VideoLinkingCollectionsMediaSliderCollectionOrder =
	| 'aspectRatio_ASC'
	| 'aspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoLinkingCollectionsSmallScreenContentCollectionOrder =
	| 'entryName_ASC'
	| 'entryName_DESC'
	| 'eyebrowSmallScreen_ASC'
	| 'eyebrowSmallScreen_DESC'
	| 'mediaFirst_ASC'
	| 'mediaFirst_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoLinkingCollectionsVideoAssetCollectionOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoOrder =
	| 'adjustedWidth_ASC'
	| 'adjustedWidth_DESC'
	| 'autoPlay_ASC'
	| 'autoPlay_DESC'
	| 'isLooped_ASC'
	| 'isLooped_DESC'
	| 'isMuted_ASC'
	| 'isMuted_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'posterUrl_ASC'
	| 'posterUrl_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'useOriginalVideoSize_ASC'
	| 'useOriginalVideoSize_DESC'
	| 'youtubeId_ASC'
	| 'youtubeId_DESC';

/** Use this to render video with some eyebrow, title and description like a card [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoWithContent) */
export type VideoWithContent = Entry &
	_Node & {
		__typename?: 'VideoWithContent';
		_id: Scalars['ID'];
		buttonsCollection?: Maybe<VideoWithContentButtonsCollection>;
		content?: Maybe<ContentSection>;
		contentfulMetadata: ContentfulMetadata;
		linkedFrom?: Maybe<VideoWithContentLinkingCollections>;
		name?: Maybe<Scalars['String']>;
		sys: Sys;
		video?: Maybe<VideoAsset>;
	};

/** Use this to render video with some eyebrow, title and description like a card [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoWithContent) */
export type VideoWithContentButtonsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoWithContentButtonsCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ButtonFilter>;
};

/** Use this to render video with some eyebrow, title and description like a card [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoWithContent) */
export type VideoWithContentContentArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<ContentSectionFilter>;
};

/** Use this to render video with some eyebrow, title and description like a card [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoWithContent) */
export type VideoWithContentLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Use this to render video with some eyebrow, title and description like a card [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoWithContent) */
export type VideoWithContentNameArgs = {
	locale?: InputMaybe<Scalars['String']>;
};

/** Use this to render video with some eyebrow, title and description like a card [See type definition](https://app.contentful.com/spaces/m8zwsu9tyucg/content_types/videoWithContent) */
export type VideoWithContentVideoArgs = {
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	where?: InputMaybe<VideoAssetFilter>;
};

export type VideoWithContentButtonsCollection = {
	__typename?: 'VideoWithContentButtonsCollection';
	items: Array<Maybe<Button>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type VideoWithContentButtonsCollectionOrder =
	| 'buttonLink_ASC'
	| 'buttonLink_DESC'
	| 'iconPosition_ASC'
	| 'iconPosition_DESC'
	| 'iconSize_ASC'
	| 'iconSize_DESC'
	| 'icon_ASC'
	| 'icon_DESC'
	| 'isLocalized_ASC'
	| 'isLocalized_DESC'
	| 'label_ASC'
	| 'label_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'quizSelector_ASC'
	| 'quizSelector_DESC'
	| 'size_ASC'
	| 'size_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC'
	| 'variant_ASC'
	| 'variant_DESC';

export type VideoWithContentCollection = {
	__typename?: 'VideoWithContentCollection';
	items: Array<Maybe<VideoWithContent>>;
	limit: Scalars['Int'];
	skip: Scalars['Int'];
	total: Scalars['Int'];
};

export type VideoWithContentFilter = {
	AND?: InputMaybe<Array<InputMaybe<VideoWithContentFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<VideoWithContentFilter>>>;
	buttons?: InputMaybe<CfButtonNestedFilter>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	content?: InputMaybe<CfContentSectionNestedFilter>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	video?: InputMaybe<CfVideoAssetNestedFilter>;
	video_exists?: InputMaybe<Scalars['Boolean']>;
};

export type VideoWithContentLinkingCollections = {
	__typename?: 'VideoWithContentLinkingCollections';
	entryCollection?: Maybe<EntryCollection>;
	videoCarouselCollection?: Maybe<VideoCarouselCollection>;
};

export type VideoWithContentLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoWithContentLinkingCollectionsVideoCarouselCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	locale?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Array<InputMaybe<VideoWithContentLinkingCollectionsVideoCarouselCollectionOrder>>>;
	preview?: InputMaybe<Scalars['Boolean']>;
	skip?: InputMaybe<Scalars['Int']>;
};

export type VideoWithContentLinkingCollectionsVideoCarouselCollectionOrder =
	| 'backgroundColor_ASC'
	| 'backgroundColor_DESC'
	| 'desktopVideosAspectRatio_ASC'
	| 'desktopVideosAspectRatio_DESC'
	| 'mobileVideosAspectRatio_ASC'
	| 'mobileVideosAspectRatio_DESC'
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VideoWithContentOrder =
	| 'name_ASC'
	| 'name_DESC'
	| 'sys_firstPublishedAt_ASC'
	| 'sys_firstPublishedAt_DESC'
	| 'sys_id_ASC'
	| 'sys_id_DESC'
	| 'sys_publishedAt_ASC'
	| 'sys_publishedAt_DESC'
	| 'sys_publishedVersion_ASC'
	| 'sys_publishedVersion_DESC';

export type VisitNotCancelableError = Error & {
	__typename?: 'VisitNotCancelableError';
	message: Scalars['String'];
};

export type WorkingHours = {
	__typename?: 'WorkingHours';
	day: Scalars['String'];
	intervals: Array<Intervals>;
};

export type YesNoAnswer = HasAnswerFormat &
	HasPredefinedAnswer & {
		__typename?: 'YesNoAnswer';
		answers?: Maybe<Array<PredefinedAnswer>>;
		format?: Maybe<QuestionnaireAnswerFormat>;
	};

export type _Node = {
	_id: Scalars['ID'];
};

export type CfAccordionsNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAccordionsNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfAccordionsNestedFilter>>>;
	accordionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	accordionsContentSectionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	accordionsHeading?: InputMaybe<Scalars['String']>;
	accordionsHeading_contains?: InputMaybe<Scalars['String']>;
	accordionsHeading_exists?: InputMaybe<Scalars['Boolean']>;
	accordionsHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	accordionsHeading_not?: InputMaybe<Scalars['String']>;
	accordionsHeading_not_contains?: InputMaybe<Scalars['String']>;
	accordionsHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
	buttonCollectionAlignment?: InputMaybe<Scalars['String']>;
	buttonCollectionAlignment_contains?: InputMaybe<Scalars['String']>;
	buttonCollectionAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	buttonCollectionAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonCollectionAlignment_not?: InputMaybe<Scalars['String']>;
	buttonCollectionAlignment_not_contains?: InputMaybe<Scalars['String']>;
	buttonCollectionAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign?: InputMaybe<Scalars['Boolean']>;
	centerAlign_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign_not?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	expandIconPosition?: InputMaybe<Scalars['String']>;
	expandIconPosition_contains?: InputMaybe<Scalars['String']>;
	expandIconPosition_exists?: InputMaybe<Scalars['Boolean']>;
	expandIconPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	expandIconPosition_not?: InputMaybe<Scalars['String']>;
	expandIconPosition_not_contains?: InputMaybe<Scalars['String']>;
	expandIconPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	expandIconType?: InputMaybe<Scalars['String']>;
	expandIconType_contains?: InputMaybe<Scalars['String']>;
	expandIconType_exists?: InputMaybe<Scalars['Boolean']>;
	expandIconType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	expandIconType_not?: InputMaybe<Scalars['String']>;
	expandIconType_not_contains?: InputMaybe<Scalars['String']>;
	expandIconType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	imagePositionRight?: InputMaybe<Scalars['Boolean']>;
	imagePositionRight_exists?: InputMaybe<Scalars['Boolean']>;
	imagePositionRight_not?: InputMaybe<Scalars['Boolean']>;
	imageRoundedCorner?: InputMaybe<Scalars['String']>;
	imageRoundedCorner_contains?: InputMaybe<Scalars['String']>;
	imageRoundedCorner_exists?: InputMaybe<Scalars['Boolean']>;
	imageRoundedCorner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageRoundedCorner_not?: InputMaybe<Scalars['String']>;
	imageRoundedCorner_not_contains?: InputMaybe<Scalars['String']>;
	imageRoundedCorner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	roundedCornersSize?: InputMaybe<Scalars['String']>;
	roundedCornersSize_contains?: InputMaybe<Scalars['String']>;
	roundedCornersSize_exists?: InputMaybe<Scalars['Boolean']>;
	roundedCornersSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	roundedCornersSize_not?: InputMaybe<Scalars['String']>;
	roundedCornersSize_not_contains?: InputMaybe<Scalars['String']>;
	roundedCornersSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleAlignment?: InputMaybe<Scalars['String']>;
	titleAlignment_contains?: InputMaybe<Scalars['String']>;
	titleAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	titleAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleAlignment_not?: InputMaybe<Scalars['String']>;
	titleAlignment_not_contains?: InputMaybe<Scalars['String']>;
	titleAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleColor?: InputMaybe<Scalars['String']>;
	titleColor_contains?: InputMaybe<Scalars['String']>;
	titleColor_exists?: InputMaybe<Scalars['Boolean']>;
	titleColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleColor_not?: InputMaybe<Scalars['String']>;
	titleColor_not_contains?: InputMaybe<Scalars['String']>;
	titleColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	withInnerGap?: InputMaybe<Scalars['Boolean']>;
	withInnerGap_exists?: InputMaybe<Scalars['Boolean']>;
	withInnerGap_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfAdFooterNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAdFooterNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfAdFooterNestedFilter>>>;
	about_contains?: InputMaybe<Scalars['String']>;
	about_exists?: InputMaybe<Scalars['Boolean']>;
	about_not_contains?: InputMaybe<Scalars['String']>;
	bookNowButton_exists?: InputMaybe<Scalars['Boolean']>;
	componentName?: InputMaybe<Scalars['String']>;
	componentName_contains?: InputMaybe<Scalars['String']>;
	componentName_exists?: InputMaybe<Scalars['Boolean']>;
	componentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName_not?: InputMaybe<Scalars['String']>;
	componentName_not_contains?: InputMaybe<Scalars['String']>;
	componentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contactNumber?: InputMaybe<Scalars['String']>;
	contactNumber_contains?: InputMaybe<Scalars['String']>;
	contactNumber_exists?: InputMaybe<Scalars['Boolean']>;
	contactNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contactNumber_not?: InputMaybe<Scalars['String']>;
	contactNumber_not_contains?: InputMaybe<Scalars['String']>;
	contactNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	copyright?: InputMaybe<Scalars['String']>;
	copyright_contains?: InputMaybe<Scalars['String']>;
	copyright_exists?: InputMaybe<Scalars['Boolean']>;
	copyright_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	copyright_not?: InputMaybe<Scalars['String']>;
	copyright_not_contains?: InputMaybe<Scalars['String']>;
	copyright_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	exclusivelyAt_exists?: InputMaybe<Scalars['Boolean']>;
	footerPolicyGroupCollection_exists?: InputMaybe<Scalars['Boolean']>;
	isLightFooter?: InputMaybe<Scalars['Boolean']>;
	isLightFooter_exists?: InputMaybe<Scalars['Boolean']>;
	isLightFooter_not?: InputMaybe<Scalars['Boolean']>;
	logo_exists?: InputMaybe<Scalars['Boolean']>;
	navigationGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	socialNetworksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	stickyFooterButton_exists?: InputMaybe<Scalars['Boolean']>;
	stickyFooterButtonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	useMobileStickyFooter?: InputMaybe<Scalars['Boolean']>;
	useMobileStickyFooter_exists?: InputMaybe<Scalars['Boolean']>;
	useMobileStickyFooter_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfAgendaModalNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAgendaModalNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfAgendaModalNestedFilter>>>;
	bodyText_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headlineHeading?: InputMaybe<Scalars['String']>;
	headlineHeading_contains?: InputMaybe<Scalars['String']>;
	headlineHeading_exists?: InputMaybe<Scalars['Boolean']>;
	headlineHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineHeading_not?: InputMaybe<Scalars['String']>;
	headlineHeading_not_contains?: InputMaybe<Scalars['String']>;
	headlineHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText?: InputMaybe<Scalars['String']>;
	headlineText_contains?: InputMaybe<Scalars['String']>;
	headlineText_exists?: InputMaybe<Scalars['Boolean']>;
	headlineText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText_not?: InputMaybe<Scalars['String']>;
	headlineText_not_contains?: InputMaybe<Scalars['String']>;
	headlineText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	modalName?: InputMaybe<Scalars['String']>;
	modalName_contains?: InputMaybe<Scalars['String']>;
	modalName_exists?: InputMaybe<Scalars['Boolean']>;
	modalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	modalName_not?: InputMaybe<Scalars['String']>;
	modalName_not_contains?: InputMaybe<Scalars['String']>;
	modalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfAnalyticsContextNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAnalyticsContextNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfAnalyticsContextNestedFilter>>>;
	appointmentSelectedFacilityId?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityId_contains?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityId_exists?: InputMaybe<Scalars['Boolean']>;
	appointmentSelectedFacilityId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appointmentSelectedFacilityId_not?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityId_not_contains?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appointmentSelectedFacilityName?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityName_contains?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityName_exists?: InputMaybe<Scalars['Boolean']>;
	appointmentSelectedFacilityName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appointmentSelectedFacilityName_not?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityName_not_contains?: InputMaybe<Scalars['String']>;
	appointmentSelectedFacilityName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	brand?: InputMaybe<Scalars['String']>;
	brand_contains?: InputMaybe<Scalars['String']>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	brand_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	brand_not?: InputMaybe<Scalars['String']>;
	brand_not_contains?: InputMaybe<Scalars['String']>;
	brand_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickName?: InputMaybe<Scalars['String']>;
	clickName_contains?: InputMaybe<Scalars['String']>;
	clickName_exists?: InputMaybe<Scalars['Boolean']>;
	clickName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickName_not?: InputMaybe<Scalars['String']>;
	clickName_not_contains?: InputMaybe<Scalars['String']>;
	clickName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickPosition?: InputMaybe<Scalars['String']>;
	clickPosition_contains?: InputMaybe<Scalars['String']>;
	clickPosition_exists?: InputMaybe<Scalars['Boolean']>;
	clickPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickPosition_not?: InputMaybe<Scalars['String']>;
	clickPosition_not_contains?: InputMaybe<Scalars['String']>;
	clickPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickType?: InputMaybe<Scalars['String']>;
	clickType_contains?: InputMaybe<Scalars['String']>;
	clickType_exists?: InputMaybe<Scalars['Boolean']>;
	clickType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	clickType_not?: InputMaybe<Scalars['String']>;
	clickType_not_contains?: InputMaybe<Scalars['String']>;
	clickType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName?: InputMaybe<Scalars['String']>;
	componentName_contains?: InputMaybe<Scalars['String']>;
	componentName_exists?: InputMaybe<Scalars['Boolean']>;
	componentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName_not?: InputMaybe<Scalars['String']>;
	componentName_not_contains?: InputMaybe<Scalars['String']>;
	componentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	errorDetails?: InputMaybe<Scalars['String']>;
	errorDetails_contains?: InputMaybe<Scalars['String']>;
	errorDetails_exists?: InputMaybe<Scalars['Boolean']>;
	errorDetails_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorDetails_not?: InputMaybe<Scalars['String']>;
	errorDetails_not_contains?: InputMaybe<Scalars['String']>;
	errorDetails_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorStatus?: InputMaybe<Scalars['String']>;
	errorStatus_contains?: InputMaybe<Scalars['String']>;
	errorStatus_exists?: InputMaybe<Scalars['Boolean']>;
	errorStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorStatus_not?: InputMaybe<Scalars['String']>;
	errorStatus_not_contains?: InputMaybe<Scalars['String']>;
	errorStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorType?: InputMaybe<Scalars['String']>;
	errorType_contains?: InputMaybe<Scalars['String']>;
	errorType_exists?: InputMaybe<Scalars['Boolean']>;
	errorType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	errorType_not?: InputMaybe<Scalars['String']>;
	errorType_not_contains?: InputMaybe<Scalars['String']>;
	errorType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	event?: InputMaybe<Scalars['String']>;
	eventName?: InputMaybe<Scalars['String']>;
	eventName_contains?: InputMaybe<Scalars['String']>;
	eventName_exists?: InputMaybe<Scalars['Boolean']>;
	eventName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eventName_not?: InputMaybe<Scalars['String']>;
	eventName_not_contains?: InputMaybe<Scalars['String']>;
	eventName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	event_contains?: InputMaybe<Scalars['String']>;
	event_exists?: InputMaybe<Scalars['Boolean']>;
	event_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	event_not?: InputMaybe<Scalars['String']>;
	event_not_contains?: InputMaybe<Scalars['String']>;
	event_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkText?: InputMaybe<Scalars['String']>;
	linkText_contains?: InputMaybe<Scalars['String']>;
	linkText_exists?: InputMaybe<Scalars['Boolean']>;
	linkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkText_not?: InputMaybe<Scalars['String']>;
	linkText_not_contains?: InputMaybe<Scalars['String']>;
	linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkUrl?: InputMaybe<Scalars['String']>;
	linkUrl_contains?: InputMaybe<Scalars['String']>;
	linkUrl_exists?: InputMaybe<Scalars['Boolean']>;
	linkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkUrl_not?: InputMaybe<Scalars['String']>;
	linkUrl_not_contains?: InputMaybe<Scalars['String']>;
	linkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationType?: InputMaybe<Scalars['String']>;
	navigationType_contains?: InputMaybe<Scalars['String']>;
	navigationType_exists?: InputMaybe<Scalars['Boolean']>;
	navigationType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationType_not?: InputMaybe<Scalars['String']>;
	navigationType_not_contains?: InputMaybe<Scalars['String']>;
	navigationType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageName?: InputMaybe<Scalars['String']>;
	pageName_contains?: InputMaybe<Scalars['String']>;
	pageName_exists?: InputMaybe<Scalars['Boolean']>;
	pageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageName_not?: InputMaybe<Scalars['String']>;
	pageName_not_contains?: InputMaybe<Scalars['String']>;
	pageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageUrl?: InputMaybe<Scalars['String']>;
	pageUrl_contains?: InputMaybe<Scalars['String']>;
	pageUrl_exists?: InputMaybe<Scalars['Boolean']>;
	pageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageUrl_not?: InputMaybe<Scalars['String']>;
	pageUrl_not_contains?: InputMaybe<Scalars['String']>;
	pageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	siteSection?: InputMaybe<Scalars['String']>;
	siteSection_contains?: InputMaybe<Scalars['String']>;
	siteSection_exists?: InputMaybe<Scalars['Boolean']>;
	siteSection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	siteSection_not?: InputMaybe<Scalars['String']>;
	siteSection_not_contains?: InputMaybe<Scalars['String']>;
	siteSection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	socialMediaName?: InputMaybe<Scalars['String']>;
	socialMediaName_contains?: InputMaybe<Scalars['String']>;
	socialMediaName_exists?: InputMaybe<Scalars['Boolean']>;
	socialMediaName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	socialMediaName_not?: InputMaybe<Scalars['String']>;
	socialMediaName_not_contains?: InputMaybe<Scalars['String']>;
	socialMediaName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	virtualPageTitle?: InputMaybe<Scalars['String']>;
	virtualPageTitle_contains?: InputMaybe<Scalars['String']>;
	virtualPageTitle_exists?: InputMaybe<Scalars['Boolean']>;
	virtualPageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	virtualPageTitle_not?: InputMaybe<Scalars['String']>;
	virtualPageTitle_not_contains?: InputMaybe<Scalars['String']>;
	virtualPageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	virtualPageUrl?: InputMaybe<Scalars['String']>;
	virtualPageUrl_contains?: InputMaybe<Scalars['String']>;
	virtualPageUrl_exists?: InputMaybe<Scalars['Boolean']>;
	virtualPageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	virtualPageUrl_not?: InputMaybe<Scalars['String']>;
	virtualPageUrl_not_contains?: InputMaybe<Scalars['String']>;
	virtualPageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfAppDetailsNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAppDetailsNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfAppDetailsNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	id?: InputMaybe<Scalars['String']>;
	id_contains?: InputMaybe<Scalars['String']>;
	id_exists?: InputMaybe<Scalars['Boolean']>;
	id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_not?: InputMaybe<Scalars['String']>;
	id_not_contains?: InputMaybe<Scalars['String']>;
	id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfBadgeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfBadgeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfBadgeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	variant?: InputMaybe<Scalars['String']>;
	variant_contains?: InputMaybe<Scalars['String']>;
	variant_exists?: InputMaybe<Scalars['Boolean']>;
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	variant_not?: InputMaybe<Scalars['String']>;
	variant_not_contains?: InputMaybe<Scalars['String']>;
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfBlogAuthorNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfBlogAuthorNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfBlogAuthorNestedFilter>>>;
	authorBioSummary_contains?: InputMaybe<Scalars['String']>;
	authorBioSummary_exists?: InputMaybe<Scalars['Boolean']>;
	authorBioSummary_not_contains?: InputMaybe<Scalars['String']>;
	bioSummary?: InputMaybe<Scalars['String']>;
	bioSummary_contains?: InputMaybe<Scalars['String']>;
	bioSummary_exists?: InputMaybe<Scalars['Boolean']>;
	bioSummary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	bioSummary_not?: InputMaybe<Scalars['String']>;
	bioSummary_not_contains?: InputMaybe<Scalars['String']>;
	bioSummary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfBlogCategoryNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfBlogCategoryNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfBlogCategoryNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	slug?: InputMaybe<Scalars['String']>;
	slug_contains?: InputMaybe<Scalars['String']>;
	slug_exists?: InputMaybe<Scalars['Boolean']>;
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	slug_not?: InputMaybe<Scalars['String']>;
	slug_not_contains?: InputMaybe<Scalars['String']>;
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfBlogListNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfBlogListNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfBlogListNestedFilter>>>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLabel?: InputMaybe<Scalars['String']>;
	buttonLabel_contains?: InputMaybe<Scalars['String']>;
	buttonLabel_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLabel_not?: InputMaybe<Scalars['String']>;
	buttonLabel_not_contains?: InputMaybe<Scalars['String']>;
	buttonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	category_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	displayType?: InputMaybe<Scalars['String']>;
	displayType_contains?: InputMaybe<Scalars['String']>;
	displayType_exists?: InputMaybe<Scalars['Boolean']>;
	displayType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	displayType_not?: InputMaybe<Scalars['String']>;
	displayType_not_contains?: InputMaybe<Scalars['String']>;
	displayType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	loadMoreButtonLabel?: InputMaybe<Scalars['String']>;
	loadMoreButtonLabel_contains?: InputMaybe<Scalars['String']>;
	loadMoreButtonLabel_exists?: InputMaybe<Scalars['Boolean']>;
	loadMoreButtonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	loadMoreButtonLabel_not?: InputMaybe<Scalars['String']>;
	loadMoreButtonLabel_not_contains?: InputMaybe<Scalars['String']>;
	loadMoreButtonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subCategory_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type CfBlogSubCategoryNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfBlogSubCategoryNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfBlogSubCategoryNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	slug?: InputMaybe<Scalars['String']>;
	slug_contains?: InputMaybe<Scalars['String']>;
	slug_exists?: InputMaybe<Scalars['Boolean']>;
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	slug_not?: InputMaybe<Scalars['String']>;
	slug_not_contains?: InputMaybe<Scalars['String']>;
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfBrandNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfBrandNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfBrandNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfButtonNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfButtonNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfButtonNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLink?: InputMaybe<Scalars['String']>;
	buttonLink_contains?: InputMaybe<Scalars['String']>;
	buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLink_not?: InputMaybe<Scalars['String']>;
	buttonLink_not_contains?: InputMaybe<Scalars['String']>;
	buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	icon?: InputMaybe<Scalars['String']>;
	iconPosition?: InputMaybe<Scalars['String']>;
	iconPosition_contains?: InputMaybe<Scalars['String']>;
	iconPosition_exists?: InputMaybe<Scalars['Boolean']>;
	iconPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconPosition_not?: InputMaybe<Scalars['String']>;
	iconPosition_not_contains?: InputMaybe<Scalars['String']>;
	iconPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconSize?: InputMaybe<Scalars['String']>;
	iconSize_contains?: InputMaybe<Scalars['String']>;
	iconSize_exists?: InputMaybe<Scalars['Boolean']>;
	iconSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconSize_not?: InputMaybe<Scalars['String']>;
	iconSize_not_contains?: InputMaybe<Scalars['String']>;
	iconSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	isLocalized?: InputMaybe<Scalars['Boolean']>;
	isLocalized_exists?: InputMaybe<Scalars['Boolean']>;
	isLocalized_not?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileButton_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationDetails_exists?: InputMaybe<Scalars['Boolean']>;
	quizDetails_exists?: InputMaybe<Scalars['Boolean']>;
	quizSelector?: InputMaybe<Scalars['String']>;
	quizSelector_contains?: InputMaybe<Scalars['String']>;
	quizSelector_exists?: InputMaybe<Scalars['Boolean']>;
	quizSelector_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quizSelector_not?: InputMaybe<Scalars['String']>;
	quizSelector_not_contains?: InputMaybe<Scalars['String']>;
	quizSelector_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size?: InputMaybe<Scalars['String']>;
	size_contains?: InputMaybe<Scalars['String']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size_not?: InputMaybe<Scalars['String']>;
	size_not_contains?: InputMaybe<Scalars['String']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	variant?: InputMaybe<Scalars['String']>;
	variant_contains?: InputMaybe<Scalars['String']>;
	variant_exists?: InputMaybe<Scalars['Boolean']>;
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	variant_not?: InputMaybe<Scalars['String']>;
	variant_not_contains?: InputMaybe<Scalars['String']>;
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCallToActionNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCallToActionNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfCallToActionNestedFilter>>>;
	buttonLink?: InputMaybe<Scalars['String']>;
	buttonLink_contains?: InputMaybe<Scalars['String']>;
	buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLink_not?: InputMaybe<Scalars['String']>;
	buttonLink_not_contains?: InputMaybe<Scalars['String']>;
	buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType?: InputMaybe<Scalars['String']>;
	buttonType_contains?: InputMaybe<Scalars['String']>;
	buttonType_exists?: InputMaybe<Scalars['Boolean']>;
	buttonType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType_not?: InputMaybe<Scalars['String']>;
	buttonType_not_contains?: InputMaybe<Scalars['String']>;
	buttonType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	downloadFile_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCardNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCardNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfCardNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIcon?: InputMaybe<Scalars['String']>;
	buttonIconPosition?: InputMaybe<Scalars['String']>;
	buttonIconPosition_contains?: InputMaybe<Scalars['String']>;
	buttonIconPosition_exists?: InputMaybe<Scalars['Boolean']>;
	buttonIconPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIconPosition_not?: InputMaybe<Scalars['String']>;
	buttonIconPosition_not_contains?: InputMaybe<Scalars['String']>;
	buttonIconPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIconSize?: InputMaybe<Scalars['String']>;
	buttonIconSize_contains?: InputMaybe<Scalars['String']>;
	buttonIconSize_exists?: InputMaybe<Scalars['Boolean']>;
	buttonIconSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIconSize_not?: InputMaybe<Scalars['String']>;
	buttonIconSize_not_contains?: InputMaybe<Scalars['String']>;
	buttonIconSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIcon_contains?: InputMaybe<Scalars['String']>;
	buttonIcon_exists?: InputMaybe<Scalars['Boolean']>;
	buttonIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonIcon_not?: InputMaybe<Scalars['String']>;
	buttonIcon_not_contains?: InputMaybe<Scalars['String']>;
	buttonIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonSize?: InputMaybe<Scalars['String']>;
	buttonSize_contains?: InputMaybe<Scalars['String']>;
	buttonSize_exists?: InputMaybe<Scalars['Boolean']>;
	buttonSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonSize_not?: InputMaybe<Scalars['String']>;
	buttonSize_not_contains?: InputMaybe<Scalars['String']>;
	buttonSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonVariant?: InputMaybe<Scalars['String']>;
	buttonVariant_contains?: InputMaybe<Scalars['String']>;
	buttonVariant_exists?: InputMaybe<Scalars['Boolean']>;
	buttonVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonVariant_not?: InputMaybe<Scalars['String']>;
	buttonVariant_not_contains?: InputMaybe<Scalars['String']>;
	buttonVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	cardIcon?: InputMaybe<Scalars['String']>;
	cardIconAlignment?: InputMaybe<Scalars['String']>;
	cardIconAlignment_contains?: InputMaybe<Scalars['String']>;
	cardIconAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	cardIconAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardIconAlignment_not?: InputMaybe<Scalars['String']>;
	cardIconAlignment_not_contains?: InputMaybe<Scalars['String']>;
	cardIconAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardIcon_contains?: InputMaybe<Scalars['String']>;
	cardIcon_exists?: InputMaybe<Scalars['Boolean']>;
	cardIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardIcon_not?: InputMaybe<Scalars['String']>;
	cardIcon_not_contains?: InputMaybe<Scalars['String']>;
	cardIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardTextAlignment?: InputMaybe<Scalars['String']>;
	cardTextAlignment_contains?: InputMaybe<Scalars['String']>;
	cardTextAlignment_exists?: InputMaybe<Scalars['Boolean']>;
	cardTextAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardTextAlignment_not?: InputMaybe<Scalars['String']>;
	cardTextAlignment_not_contains?: InputMaybe<Scalars['String']>;
	cardTextAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eventType?: InputMaybe<Scalars['String']>;
	eventType_contains?: InputMaybe<Scalars['String']>;
	eventType_exists?: InputMaybe<Scalars['Boolean']>;
	eventType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eventType_not?: InputMaybe<Scalars['String']>;
	eventType_not_contains?: InputMaybe<Scalars['String']>;
	eventType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImage_exists?: InputMaybe<Scalars['Boolean']>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	imageRatio?: InputMaybe<Scalars['String']>;
	imageRatio_contains?: InputMaybe<Scalars['String']>;
	imageRatio_exists?: InputMaybe<Scalars['Boolean']>;
	imageRatio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageRatio_not?: InputMaybe<Scalars['String']>;
	imageRatio_not_contains?: InputMaybe<Scalars['String']>;
	imageRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageUrl?: InputMaybe<Scalars['String']>;
	imageUrl_contains?: InputMaybe<Scalars['String']>;
	imageUrl_exists?: InputMaybe<Scalars['Boolean']>;
	imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageUrl_not?: InputMaybe<Scalars['String']>;
	imageUrl_not_contains?: InputMaybe<Scalars['String']>;
	imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pdfDownloadTitle?: InputMaybe<Scalars['String']>;
	pdfDownloadTitle_contains?: InputMaybe<Scalars['String']>;
	pdfDownloadTitle_exists?: InputMaybe<Scalars['Boolean']>;
	pdfDownloadTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pdfDownloadTitle_not?: InputMaybe<Scalars['String']>;
	pdfDownloadTitle_not_contains?: InputMaybe<Scalars['String']>;
	pdfDownloadTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pdfFileLink_exists?: InputMaybe<Scalars['Boolean']>;
	priority?: InputMaybe<Scalars['Int']>;
	priority_exists?: InputMaybe<Scalars['Boolean']>;
	priority_gt?: InputMaybe<Scalars['Int']>;
	priority_gte?: InputMaybe<Scalars['Int']>;
	priority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	priority_lt?: InputMaybe<Scalars['Int']>;
	priority_lte?: InputMaybe<Scalars['Int']>;
	priority_not?: InputMaybe<Scalars['Int']>;
	priority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	richTextDescription_contains?: InputMaybe<Scalars['String']>;
	richTextDescription_exists?: InputMaybe<Scalars['Boolean']>;
	richTextDescription_not_contains?: InputMaybe<Scalars['String']>;
	showBorder?: InputMaybe<Scalars['Boolean']>;
	showBorder_exists?: InputMaybe<Scalars['Boolean']>;
	showBorder_not?: InputMaybe<Scalars['Boolean']>;
	slot?: InputMaybe<Scalars['Int']>;
	slot_exists?: InputMaybe<Scalars['Boolean']>;
	slot_gt?: InputMaybe<Scalars['Int']>;
	slot_gte?: InputMaybe<Scalars['Int']>;
	slot_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	slot_lt?: InputMaybe<Scalars['Int']>;
	slot_lte?: InputMaybe<Scalars['Int']>;
	slot_not?: InputMaybe<Scalars['Int']>;
	slot_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	stepName?: InputMaybe<Scalars['String']>;
	stepName_contains?: InputMaybe<Scalars['String']>;
	stepName_exists?: InputMaybe<Scalars['Boolean']>;
	stepName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	stepName_not?: InputMaybe<Scalars['String']>;
	stepName_not_contains?: InputMaybe<Scalars['String']>;
	stepName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tcText?: InputMaybe<Scalars['String']>;
	tcText_contains?: InputMaybe<Scalars['String']>;
	tcText_exists?: InputMaybe<Scalars['Boolean']>;
	tcText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcText_not?: InputMaybe<Scalars['String']>;
	tcText_not_contains?: InputMaybe<Scalars['String']>;
	tcText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcUrl?: InputMaybe<Scalars['String']>;
	tcUrl_contains?: InputMaybe<Scalars['String']>;
	tcUrl_exists?: InputMaybe<Scalars['Boolean']>;
	tcUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcUrl_not?: InputMaybe<Scalars['String']>;
	tcUrl_not_contains?: InputMaybe<Scalars['String']>;
	tcUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tc_exists?: InputMaybe<Scalars['Boolean']>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCategoryNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCategoryNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfCategoryNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	icon?: InputMaybe<Scalars['String']>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	isLocalized?: InputMaybe<Scalars['Boolean']>;
	isLocalized_exists?: InputMaybe<Scalars['Boolean']>;
	isLocalized_not?: InputMaybe<Scalars['Boolean']>;
	link?: InputMaybe<Scalars['String']>;
	link_contains?: InputMaybe<Scalars['String']>;
	link_exists?: InputMaybe<Scalars['Boolean']>;
	link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	link_not?: InputMaybe<Scalars['String']>;
	link_not_contains?: InputMaybe<Scalars['String']>;
	link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCategoryPickerNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCategoryPickerNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfCategoryPickerNestedFilter>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrowHeading?: InputMaybe<Scalars['String']>;
	eyebrowHeading_contains?: InputMaybe<Scalars['String']>;
	eyebrowHeading_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowHeading_not?: InputMaybe<Scalars['String']>;
	eyebrowHeading_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileThreshold?: InputMaybe<Scalars['Int']>;
	mobileThreshold_exists?: InputMaybe<Scalars['Boolean']>;
	mobileThreshold_gt?: InputMaybe<Scalars['Int']>;
	mobileThreshold_gte?: InputMaybe<Scalars['Int']>;
	mobileThreshold_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	mobileThreshold_lt?: InputMaybe<Scalars['Int']>;
	mobileThreshold_lte?: InputMaybe<Scalars['Int']>;
	mobileThreshold_not?: InputMaybe<Scalars['Int']>;
	mobileThreshold_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	setTwoRows?: InputMaybe<Scalars['Boolean']>;
	setTwoRows_exists?: InputMaybe<Scalars['Boolean']>;
	setTwoRows_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHeading?: InputMaybe<Scalars['String']>;
	titleHeading_contains?: InputMaybe<Scalars['String']>;
	titleHeading_exists?: InputMaybe<Scalars['Boolean']>;
	titleHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHeading_not?: InputMaybe<Scalars['String']>;
	titleHeading_not_contains?: InputMaybe<Scalars['String']>;
	titleHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	transparentCategories?: InputMaybe<Scalars['Boolean']>;
	transparentCategories_exists?: InputMaybe<Scalars['Boolean']>;
	transparentCategories_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfContentSectionNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfContentSectionNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfContentSectionNestedFilter>>>;
	alignment?: InputMaybe<Scalars['String']>;
	alignment_contains?: InputMaybe<Scalars['String']>;
	alignment_exists?: InputMaybe<Scalars['Boolean']>;
	alignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	alignment_not?: InputMaybe<Scalars['String']>;
	alignment_not_contains?: InputMaybe<Scalars['String']>;
	alignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrowHtag?: InputMaybe<Scalars['String']>;
	eyebrowHtag_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowHtag_not?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleIcon?: InputMaybe<Scalars['String']>;
	titleIcon_contains?: InputMaybe<Scalars['String']>;
	titleIcon_exists?: InputMaybe<Scalars['Boolean']>;
	titleIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleIcon_not?: InputMaybe<Scalars['String']>;
	titleIcon_not_contains?: InputMaybe<Scalars['String']>;
	titleIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfContentTileNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfContentTileNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfContentTileNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	externalUrl?: InputMaybe<Scalars['String']>;
	externalUrl_contains?: InputMaybe<Scalars['String']>;
	externalUrl_exists?: InputMaybe<Scalars['Boolean']>;
	externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	externalUrl_not?: InputMaybe<Scalars['String']>;
	externalUrl_not_contains?: InputMaybe<Scalars['String']>;
	externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	internalPage_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfContentTypeRichTextNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfContentTypeRichTextNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfContentTypeRichTextNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign?: InputMaybe<Scalars['Boolean']>;
	centerAlign_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign_not?: InputMaybe<Scalars['Boolean']>;
	contained?: InputMaybe<Scalars['Boolean']>;
	contained_exists?: InputMaybe<Scalars['Boolean']>;
	contained_not?: InputMaybe<Scalars['Boolean']>;
	contentMaxWidth?: InputMaybe<Scalars['String']>;
	contentMaxWidth_contains?: InputMaybe<Scalars['String']>;
	contentMaxWidth_exists?: InputMaybe<Scalars['Boolean']>;
	contentMaxWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentMaxWidth_not?: InputMaybe<Scalars['String']>;
	contentMaxWidth_not_contains?: InputMaybe<Scalars['String']>;
	contentMaxWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	content_contains?: InputMaybe<Scalars['String']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	content_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['String']>;
	id_contains?: InputMaybe<Scalars['String']>;
	id_exists?: InputMaybe<Scalars['Boolean']>;
	id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	id_not?: InputMaybe<Scalars['String']>;
	id_not_contains?: InputMaybe<Scalars['String']>;
	id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	secondaryContent_contains?: InputMaybe<Scalars['String']>;
	secondaryContent_exists?: InputMaybe<Scalars['Boolean']>;
	secondaryContent_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleHeading?: InputMaybe<Scalars['String']>;
	titleHeading_contains?: InputMaybe<Scalars['String']>;
	titleHeading_exists?: InputMaybe<Scalars['Boolean']>;
	titleHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHeading_not?: InputMaybe<Scalars['String']>;
	titleHeading_not_contains?: InputMaybe<Scalars['String']>;
	titleHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfDisclaimerV2NestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfDisclaimerV2NestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfDisclaimerV2NestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
	entryName?: InputMaybe<Scalars['String']>;
	entryName_contains?: InputMaybe<Scalars['String']>;
	entryName_exists?: InputMaybe<Scalars['Boolean']>;
	entryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	entryName_not?: InputMaybe<Scalars['String']>;
	entryName_not_contains?: InputMaybe<Scalars['String']>;
	entryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size?: InputMaybe<Scalars['String']>;
	size_contains?: InputMaybe<Scalars['String']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size_not?: InputMaybe<Scalars['String']>;
	size_not_contains?: InputMaybe<Scalars['String']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfError404NestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfError404NestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfError404NestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
	body?: InputMaybe<Scalars['String']>;
	body_contains?: InputMaybe<Scalars['String']>;
	body_exists?: InputMaybe<Scalars['Boolean']>;
	body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	body_not?: InputMaybe<Scalars['String']>;
	body_not_contains?: InputMaybe<Scalars['String']>;
	body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLabel?: InputMaybe<Scalars['String']>;
	buttonLabel_contains?: InputMaybe<Scalars['String']>;
	buttonLabel_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLabel_not?: InputMaybe<Scalars['String']>;
	buttonLabel_not_contains?: InputMaybe<Scalars['String']>;
	buttonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	darkTextColor?: InputMaybe<Scalars['Boolean']>;
	darkTextColor_exists?: InputMaybe<Scalars['Boolean']>;
	darkTextColor_not?: InputMaybe<Scalars['Boolean']>;
	heading?: InputMaybe<Scalars['String']>;
	heading_contains?: InputMaybe<Scalars['String']>;
	heading_exists?: InputMaybe<Scalars['Boolean']>;
	heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	heading_not?: InputMaybe<Scalars['String']>;
	heading_not_contains?: InputMaybe<Scalars['String']>;
	heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subtitle?: InputMaybe<Scalars['String']>;
	subtitle_contains?: InputMaybe<Scalars['String']>;
	subtitle_exists?: InputMaybe<Scalars['Boolean']>;
	subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subtitle_not?: InputMaybe<Scalars['String']>;
	subtitle_not_contains?: InputMaybe<Scalars['String']>;
	subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfFeatureFlagsNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfFeatureFlagsNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfFeatureFlagsNestedFilter>>>;
	ameliaChat_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ameliaChat_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ameliaChat_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	ameliaChat_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	continentalUsMapFallbackThreshold?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_exists?: InputMaybe<Scalars['Boolean']>;
	continentalUsMapFallbackThreshold_gt?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_gte?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	continentalUsMapFallbackThreshold_lt?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_lte?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_not?: InputMaybe<Scalars['Int']>;
	continentalUsMapFallbackThreshold_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	defaultToCdn?: InputMaybe<Scalars['Boolean']>;
	defaultToCdn_exists?: InputMaybe<Scalars['Boolean']>;
	defaultToCdn_not?: InputMaybe<Scalars['Boolean']>;
	enforceContinentalUsZoom?: InputMaybe<Scalars['Boolean']>;
	enforceContinentalUsZoom_exists?: InputMaybe<Scalars['Boolean']>;
	enforceContinentalUsZoom_not?: InputMaybe<Scalars['Boolean']>;
	featureList_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	featureList_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	featureList_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	featureList_exists?: InputMaybe<Scalars['Boolean']>;
	hideOdpHeaderLinks?: InputMaybe<Scalars['Boolean']>;
	hideOdpHeaderLinks_exists?: InputMaybe<Scalars['Boolean']>;
	hideOdpHeaderLinks_not?: InputMaybe<Scalars['Boolean']>;
	hideSearchWithLocationCards?: InputMaybe<Scalars['Boolean']>;
	hideSearchWithLocationCards_exists?: InputMaybe<Scalars['Boolean']>;
	hideSearchWithLocationCards_not?: InputMaybe<Scalars['Boolean']>;
	loadFacilityReviewsFromBeffe?: InputMaybe<Scalars['Boolean']>;
	loadFacilityReviewsFromBeffe_exists?: InputMaybe<Scalars['Boolean']>;
	loadFacilityReviewsFromBeffe_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onPageScheduling?: InputMaybe<Scalars['Boolean']>;
	onPageSchedulingLocations_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onPageSchedulingLocations_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onPageSchedulingLocations_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	onPageSchedulingLocations_exists?: InputMaybe<Scalars['Boolean']>;
	onPageScheduling_exists?: InputMaybe<Scalars['Boolean']>;
	onPageScheduling_not?: InputMaybe<Scalars['Boolean']>;
	providerHeroTheme?: InputMaybe<Scalars['String']>;
	providerHeroTheme_contains?: InputMaybe<Scalars['String']>;
	providerHeroTheme_exists?: InputMaybe<Scalars['Boolean']>;
	providerHeroTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	providerHeroTheme_not?: InputMaybe<Scalars['String']>;
	providerHeroTheme_not_contains?: InputMaybe<Scalars['String']>;
	providerHeroTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	salesForceEvergageScript?: InputMaybe<Scalars['String']>;
	salesForceEvergageScript_contains?: InputMaybe<Scalars['String']>;
	salesForceEvergageScript_exists?: InputMaybe<Scalars['Boolean']>;
	salesForceEvergageScript_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	salesForceEvergageScript_not?: InputMaybe<Scalars['String']>;
	salesForceEvergageScript_not_contains?: InputMaybe<Scalars['String']>;
	salesForceEvergageScript_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	showHeaderWithLocationNav?: InputMaybe<Scalars['Boolean']>;
	showHeaderWithLocationNav_exists?: InputMaybe<Scalars['Boolean']>;
	showHeaderWithLocationNav_not?: InputMaybe<Scalars['Boolean']>;
	showMobileHeaderLocationBar?: InputMaybe<Scalars['Boolean']>;
	showMobileHeaderLocationBar_exists?: InputMaybe<Scalars['Boolean']>;
	showMobileHeaderLocationBar_not?: InputMaybe<Scalars['Boolean']>;
	showProviderPageCtaInMeetOurStaff?: InputMaybe<Scalars['Boolean']>;
	showProviderPageCtaInMeetOurStaff_exists?: InputMaybe<Scalars['Boolean']>;
	showProviderPageCtaInMeetOurStaff_not?: InputMaybe<Scalars['Boolean']>;
	showReadMoreForBioInMeetOurStaff?: InputMaybe<Scalars['Boolean']>;
	showReadMoreForBioInMeetOurStaff_exists?: InputMaybe<Scalars['Boolean']>;
	showReadMoreForBioInMeetOurStaff_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	transitionallyUseBeffe?: InputMaybe<Scalars['Boolean']>;
	transitionallyUseBeffe_exists?: InputMaybe<Scalars['Boolean']>;
	transitionallyUseBeffe_not?: InputMaybe<Scalars['Boolean']>;
	useFacilityBeffeDataSource?: InputMaybe<Scalars['Boolean']>;
	useFacilityBeffeDataSource_exists?: InputMaybe<Scalars['Boolean']>;
	useFacilityBeffeDataSource_not?: InputMaybe<Scalars['Boolean']>;
	useFilterForOdpDetailsContent?: InputMaybe<Scalars['Boolean']>;
	useFilterForOdpDetailsContent_exists?: InputMaybe<Scalars['Boolean']>;
	useFilterForOdpDetailsContent_not?: InputMaybe<Scalars['Boolean']>;
	useInlineOneTrust?: InputMaybe<Scalars['Boolean']>;
	useInlineOneTrust_exists?: InputMaybe<Scalars['Boolean']>;
	useInlineOneTrust_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfFocalPointImageNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfFocalPointImageNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfFocalPointImageNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	focalPoint_exists?: InputMaybe<Scalars['Boolean']>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfFooterNavigationGroupNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupNestedFilter>>>;
	componentName?: InputMaybe<Scalars['String']>;
	componentName_contains?: InputMaybe<Scalars['String']>;
	componentName_exists?: InputMaybe<Scalars['Boolean']>;
	componentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName_not?: InputMaybe<Scalars['String']>;
	componentName_not_contains?: InputMaybe<Scalars['String']>;
	componentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	navigationGroupLink_exists?: InputMaybe<Scalars['Boolean']>;
	navigationGroupTitle?: InputMaybe<Scalars['String']>;
	navigationGroupTitle_contains?: InputMaybe<Scalars['String']>;
	navigationGroupTitle_exists?: InputMaybe<Scalars['Boolean']>;
	navigationGroupTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationGroupTitle_not?: InputMaybe<Scalars['String']>;
	navigationGroupTitle_not_contains?: InputMaybe<Scalars['String']>;
	navigationGroupTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationLinksForGroupCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type CfFooterNavigationGroupV2NestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupV2NestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupV2NestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationGroupLink_exists?: InputMaybe<Scalars['Boolean']>;
	navigationLinksForGroupCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type CfFormDropdownListItemNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfFormDropdownListItemNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfFormDropdownListItemNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	divider?: InputMaybe<Scalars['Boolean']>;
	divider_exists?: InputMaybe<Scalars['Boolean']>;
	divider_not?: InputMaybe<Scalars['Boolean']>;
	iconBoolean?: InputMaybe<Scalars['Boolean']>;
	iconBoolean_exists?: InputMaybe<Scalars['Boolean']>;
	iconBoolean_not?: InputMaybe<Scalars['Boolean']>;
	iconName?: InputMaybe<Scalars['String']>;
	iconName_contains?: InputMaybe<Scalars['String']>;
	iconName_exists?: InputMaybe<Scalars['Boolean']>;
	iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconName_not?: InputMaybe<Scalars['String']>;
	iconName_not_contains?: InputMaybe<Scalars['String']>;
	iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	itemText?: InputMaybe<Scalars['String']>;
	itemText_contains?: InputMaybe<Scalars['String']>;
	itemText_exists?: InputMaybe<Scalars['Boolean']>;
	itemText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	itemText_not?: InputMaybe<Scalars['String']>;
	itemText_not_contains?: InputMaybe<Scalars['String']>;
	itemText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfHeaderMainNavNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfHeaderMainNavNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfHeaderMainNavNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headerMainNavLink_exists?: InputMaybe<Scalars['Boolean']>;
	headerSubNavMenu_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfHeaderNavMenuNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfHeaderNavMenuNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfHeaderNavMenuNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headerMainNavLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfHeaderNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfHeaderNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfHeaderNestedFilter>>>;
	bookNowButton_exists?: InputMaybe<Scalars['Boolean']>;
	componentName?: InputMaybe<Scalars['String']>;
	componentName_contains?: InputMaybe<Scalars['String']>;
	componentName_exists?: InputMaybe<Scalars['Boolean']>;
	componentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	componentName_not?: InputMaybe<Scalars['String']>;
	componentName_not_contains?: InputMaybe<Scalars['String']>;
	componentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	darkHeader?: InputMaybe<Scalars['Boolean']>;
	darkHeader_exists?: InputMaybe<Scalars['Boolean']>;
	darkHeader_not?: InputMaybe<Scalars['Boolean']>;
	hideFullSiteSearch?: InputMaybe<Scalars['Boolean']>;
	hideFullSiteSearch_exists?: InputMaybe<Scalars['Boolean']>;
	hideFullSiteSearch_not?: InputMaybe<Scalars['Boolean']>;
	hideHamburgerMenuToggleButton?: InputMaybe<Scalars['Boolean']>;
	hideHamburgerMenuToggleButton_exists?: InputMaybe<Scalars['Boolean']>;
	hideHamburgerMenuToggleButton_not?: InputMaybe<Scalars['Boolean']>;
	locationBarFindOfficeUrl?: InputMaybe<Scalars['String']>;
	locationBarFindOfficeUrl_contains?: InputMaybe<Scalars['String']>;
	locationBarFindOfficeUrl_exists?: InputMaybe<Scalars['Boolean']>;
	locationBarFindOfficeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarFindOfficeUrl_not?: InputMaybe<Scalars['String']>;
	locationBarFindOfficeUrl_not_contains?: InputMaybe<Scalars['String']>;
	locationBarFindOfficeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarWithOfficeTitle?: InputMaybe<Scalars['String']>;
	locationBarWithOfficeTitle_contains?: InputMaybe<Scalars['String']>;
	locationBarWithOfficeTitle_exists?: InputMaybe<Scalars['Boolean']>;
	locationBarWithOfficeTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarWithOfficeTitle_not?: InputMaybe<Scalars['String']>;
	locationBarWithOfficeTitle_not_contains?: InputMaybe<Scalars['String']>;
	locationBarWithOfficeTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarWithOutOfficeTitle?: InputMaybe<Scalars['String']>;
	locationBarWithOutOfficeTitle_contains?: InputMaybe<Scalars['String']>;
	locationBarWithOutOfficeTitle_exists?: InputMaybe<Scalars['Boolean']>;
	locationBarWithOutOfficeTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationBarWithOutOfficeTitle_not?: InputMaybe<Scalars['String']>;
	locationBarWithOutOfficeTitle_not_contains?: InputMaybe<Scalars['String']>;
	locationBarWithOutOfficeTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	locationNavigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	myAccountLabel?: InputMaybe<Scalars['String']>;
	myAccountLabel_contains?: InputMaybe<Scalars['String']>;
	myAccountLabel_exists?: InputMaybe<Scalars['Boolean']>;
	myAccountLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	myAccountLabel_not?: InputMaybe<Scalars['String']>;
	myAccountLabel_not_contains?: InputMaybe<Scalars['String']>;
	myAccountLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	navigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	navigationMenu_exists?: InputMaybe<Scalars['Boolean']>;
	phoneNumber_exists?: InputMaybe<Scalars['Boolean']>;
	profileLinkUrl?: InputMaybe<Scalars['String']>;
	profileLinkUrl_contains?: InputMaybe<Scalars['String']>;
	profileLinkUrl_exists?: InputMaybe<Scalars['Boolean']>;
	profileLinkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	profileLinkUrl_not?: InputMaybe<Scalars['String']>;
	profileLinkUrl_not_contains?: InputMaybe<Scalars['String']>;
	profileLinkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonText?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile_exists?: InputMaybe<Scalars['Boolean']>;
	schedulingButtonTextMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonTextMobile_not?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile_not_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonTextMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonText_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	schedulingButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonText_not?: InputMaybe<Scalars['String']>;
	schedulingButtonText_not_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonUrl?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile_exists?: InputMaybe<Scalars['Boolean']>;
	schedulingButtonUrlMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonUrlMobile_not?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile_not_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonUrlMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonUrl_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	schedulingButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingButtonUrl_not?: InputMaybe<Scalars['String']>;
	schedulingButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
	schedulingButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	schedulingNavigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	secondaryNavigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	topNavigationLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	useHeaderLocationCards?: InputMaybe<Scalars['Boolean']>;
	useHeaderLocationCards_exists?: InputMaybe<Scalars['Boolean']>;
	useHeaderLocationCards_not?: InputMaybe<Scalars['Boolean']>;
	useNewLayout?: InputMaybe<Scalars['Boolean']>;
	useNewLayout_exists?: InputMaybe<Scalars['Boolean']>;
	useNewLayout_not?: InputMaybe<Scalars['Boolean']>;
	usePopupScheduling?: InputMaybe<Scalars['Boolean']>;
	usePopupScheduling_exists?: InputMaybe<Scalars['Boolean']>;
	usePopupScheduling_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfHeaderSubNavNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfHeaderSubNavNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfHeaderSubNavNestedFilter>>>;
	contentTilesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headerSubNavGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfImageAssetNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfImageAssetNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfImageAssetNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	desktopImage_exists?: InputMaybe<Scalars['Boolean']>;
	mobileImage_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfMediaSliderNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfMediaSliderNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfMediaSliderNestedFilter>>>;
	aspectRatio?: InputMaybe<Scalars['String']>;
	aspectRatio_contains?: InputMaybe<Scalars['String']>;
	aspectRatio_exists?: InputMaybe<Scalars['Boolean']>;
	aspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	aspectRatio_not?: InputMaybe<Scalars['String']>;
	aspectRatio_not_contains?: InputMaybe<Scalars['String']>;
	aspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	badge_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfMedicalServiceNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfMedicalServiceNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfMedicalServiceNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	iconName?: InputMaybe<Scalars['String']>;
	iconName_contains?: InputMaybe<Scalars['String']>;
	iconName_exists?: InputMaybe<Scalars['Boolean']>;
	iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconName_not?: InputMaybe<Scalars['String']>;
	iconName_not_contains?: InputMaybe<Scalars['String']>;
	iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfMobileButtonNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfMobileButtonNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfMobileButtonNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	icon?: InputMaybe<Scalars['String']>;
	iconPosition?: InputMaybe<Scalars['String']>;
	iconPosition_contains?: InputMaybe<Scalars['String']>;
	iconPosition_exists?: InputMaybe<Scalars['Boolean']>;
	iconPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconPosition_not?: InputMaybe<Scalars['String']>;
	iconPosition_not_contains?: InputMaybe<Scalars['String']>;
	iconPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size?: InputMaybe<Scalars['String']>;
	size_contains?: InputMaybe<Scalars['String']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	size_not?: InputMaybe<Scalars['String']>;
	size_not_contains?: InputMaybe<Scalars['String']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	variant?: InputMaybe<Scalars['String']>;
	variant_contains?: InputMaybe<Scalars['String']>;
	variant_exists?: InputMaybe<Scalars['Boolean']>;
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	variant_not?: InputMaybe<Scalars['String']>;
	variant_not_contains?: InputMaybe<Scalars['String']>;
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfNavigationGroupNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfNavigationGroupNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfNavigationGroupNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	displayAsExpandedForMobileMenu?: InputMaybe<Scalars['Boolean']>;
	displayAsExpandedForMobileMenu_exists?: InputMaybe<Scalars['Boolean']>;
	displayAsExpandedForMobileMenu_not?: InputMaybe<Scalars['Boolean']>;
	groupLink_exists?: InputMaybe<Scalars['Boolean']>;
	linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfNavigationLinkNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfNavigationLinkNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfNavigationLinkNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	externalUrl?: InputMaybe<Scalars['String']>;
	externalUrl_contains?: InputMaybe<Scalars['String']>;
	externalUrl_exists?: InputMaybe<Scalars['Boolean']>;
	externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	externalUrl_not?: InputMaybe<Scalars['String']>;
	externalUrl_not_contains?: InputMaybe<Scalars['String']>;
	externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	isInternal?: InputMaybe<Scalars['Boolean']>;
	isInternal_exists?: InputMaybe<Scalars['Boolean']>;
	isInternal_not?: InputMaybe<Scalars['Boolean']>;
	isLocalized?: InputMaybe<Scalars['Boolean']>;
	isLocalized_exists?: InputMaybe<Scalars['Boolean']>;
	isLocalized_not?: InputMaybe<Scalars['Boolean']>;
	linkIcon_exists?: InputMaybe<Scalars['Boolean']>;
	linkText?: InputMaybe<Scalars['String']>;
	linkText_contains?: InputMaybe<Scalars['String']>;
	linkText_exists?: InputMaybe<Scalars['Boolean']>;
	linkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	linkText_not?: InputMaybe<Scalars['String']>;
	linkText_not_contains?: InputMaybe<Scalars['String']>;
	linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	openInNewTab?: InputMaybe<Scalars['Boolean']>;
	openInNewTab_exists?: InputMaybe<Scalars['Boolean']>;
	openInNewTab_not?: InputMaybe<Scalars['Boolean']>;
	openInNewWindow?: InputMaybe<Scalars['Boolean']>;
	openInNewWindow_exists?: InputMaybe<Scalars['Boolean']>;
	openInNewWindow_not?: InputMaybe<Scalars['Boolean']>;
	pageData_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
};

export type CfPageNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	appName?: InputMaybe<Scalars['String']>;
	appName_contains?: InputMaybe<Scalars['String']>;
	appName_exists?: InputMaybe<Scalars['Boolean']>;
	appName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	appName_not?: InputMaybe<Scalars['String']>;
	appName_not_contains?: InputMaybe<Scalars['String']>;
	appName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbTitle?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_contains?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_exists?: InputMaybe<Scalars['Boolean']>;
	breadcrumbTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbTitle_not?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_not_contains?: InputMaybe<Scalars['String']>;
	breadcrumbTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	breadcrumbsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical?: InputMaybe<Scalars['String']>;
	canonical_contains?: InputMaybe<Scalars['String']>;
	canonical_exists?: InputMaybe<Scalars['Boolean']>;
	canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	canonical_not?: InputMaybe<Scalars['String']>;
	canonical_not_contains?: InputMaybe<Scalars['String']>;
	canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disableChatBot?: InputMaybe<Scalars['Boolean']>;
	disableChatBot_exists?: InputMaybe<Scalars['Boolean']>;
	disableChatBot_not?: InputMaybe<Scalars['Boolean']>;
	displayOnMobile?: InputMaybe<Scalars['Boolean']>;
	displayOnMobile_exists?: InputMaybe<Scalars['Boolean']>;
	displayOnMobile_not?: InputMaybe<Scalars['Boolean']>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	indexed?: InputMaybe<Scalars['Boolean']>;
	indexed_exists?: InputMaybe<Scalars['Boolean']>;
	indexed_not?: InputMaybe<Scalars['Boolean']>;
	mobileBreadcrumbTitle?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_contains?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_exists?: InputMaybe<Scalars['Boolean']>;
	mobileBreadcrumbTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileBreadcrumbTitle_not?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_not_contains?: InputMaybe<Scalars['String']>;
	mobileBreadcrumbTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	overrideSchedulingButtonUrl?: InputMaybe<Scalars['String']>;
	overrideSchedulingButtonUrl_contains?: InputMaybe<Scalars['String']>;
	overrideSchedulingButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	overrideSchedulingButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	overrideSchedulingButtonUrl_not?: InputMaybe<Scalars['String']>;
	overrideSchedulingButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
	overrideSchedulingButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTheme?: InputMaybe<Scalars['String']>;
	pageTheme_contains?: InputMaybe<Scalars['String']>;
	pageTheme_exists?: InputMaybe<Scalars['Boolean']>;
	pageTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTheme_not?: InputMaybe<Scalars['String']>;
	pageTheme_not_contains?: InputMaybe<Scalars['String']>;
	pageTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTitle?: InputMaybe<Scalars['String']>;
	pageTitle_contains?: InputMaybe<Scalars['String']>;
	pageTitle_exists?: InputMaybe<Scalars['Boolean']>;
	pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	pageTitle_not?: InputMaybe<Scalars['String']>;
	pageTitle_not_contains?: InputMaybe<Scalars['String']>;
	pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path?: InputMaybe<Scalars['String']>;
	path_contains?: InputMaybe<Scalars['String']>;
	path_exists?: InputMaybe<Scalars['Boolean']>;
	path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	path_not?: InputMaybe<Scalars['String']>;
	path_not_contains?: InputMaybe<Scalars['String']>;
	path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalized?: InputMaybe<Scalars['Boolean']>;
	personalizedPathPrefix?: InputMaybe<Scalars['String']>;
	personalizedPathPrefix_contains?: InputMaybe<Scalars['String']>;
	personalizedPathPrefix_exists?: InputMaybe<Scalars['Boolean']>;
	personalizedPathPrefix_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalizedPathPrefix_not?: InputMaybe<Scalars['String']>;
	personalizedPathPrefix_not_contains?: InputMaybe<Scalars['String']>;
	personalizedPathPrefix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalizedPathSuffix?: InputMaybe<Scalars['String']>;
	personalizedPathSuffix_contains?: InputMaybe<Scalars['String']>;
	personalizedPathSuffix_exists?: InputMaybe<Scalars['Boolean']>;
	personalizedPathSuffix_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalizedPathSuffix_not?: InputMaybe<Scalars['String']>;
	personalizedPathSuffix_not_contains?: InputMaybe<Scalars['String']>;
	personalizedPathSuffix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	personalized_exists?: InputMaybe<Scalars['Boolean']>;
	personalized_not?: InputMaybe<Scalars['Boolean']>;
	redirectFrom_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	redirectFrom_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	redirectFrom_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	redirectFrom_exists?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfPatientReviewV2NestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfPatientReviewV2NestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfPatientReviewV2NestedFilter>>>;
	avatar_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	score?: InputMaybe<Scalars['Int']>;
	score_exists?: InputMaybe<Scalars['Boolean']>;
	score_gt?: InputMaybe<Scalars['Int']>;
	score_gte?: InputMaybe<Scalars['Int']>;
	score_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	score_lt?: InputMaybe<Scalars['Int']>;
	score_lte?: InputMaybe<Scalars['Int']>;
	score_not?: InputMaybe<Scalars['Int']>;
	score_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	subText1?: InputMaybe<Scalars['String']>;
	subText1_contains?: InputMaybe<Scalars['String']>;
	subText1_exists?: InputMaybe<Scalars['Boolean']>;
	subText1_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText1_not?: InputMaybe<Scalars['String']>;
	subText1_not_contains?: InputMaybe<Scalars['String']>;
	subText1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText2?: InputMaybe<Scalars['String']>;
	subText2_contains?: InputMaybe<Scalars['String']>;
	subText2_exists?: InputMaybe<Scalars['Boolean']>;
	subText2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText2_not?: InputMaybe<Scalars['String']>;
	subText2_not_contains?: InputMaybe<Scalars['String']>;
	subText2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	text?: InputMaybe<Scalars['String']>;
	text_contains?: InputMaybe<Scalars['String']>;
	text_exists?: InputMaybe<Scalars['Boolean']>;
	text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	text_not?: InputMaybe<Scalars['String']>;
	text_not_contains?: InputMaybe<Scalars['String']>;
	text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfProductFeatureDescriptionListItemNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfProductFeatureDescriptionListItemNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfProductFeatureDescriptionListItemNestedFilter>>>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	icon?: InputMaybe<Scalars['String']>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfProductFeatureHeadingNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfProductFeatureHeadingNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfProductFeatureHeadingNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	eyebrowHtag?: InputMaybe<Scalars['String']>;
	eyebrowHtag_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowHtag_not?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowText?: InputMaybe<Scalars['String']>;
	eyebrowText_contains?: InputMaybe<Scalars['String']>;
	eyebrowText_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowText_not?: InputMaybe<Scalars['String']>;
	eyebrowText_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingHtag?: InputMaybe<Scalars['String']>;
	headingHtag_contains?: InputMaybe<Scalars['String']>;
	headingHtag_exists?: InputMaybe<Scalars['Boolean']>;
	headingHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingHtag_not?: InputMaybe<Scalars['String']>;
	headingHtag_not_contains?: InputMaybe<Scalars['String']>;
	headingHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingText?: InputMaybe<Scalars['String']>;
	headingText_contains?: InputMaybe<Scalars['String']>;
	headingText_exists?: InputMaybe<Scalars['Boolean']>;
	headingText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headingText_not?: InputMaybe<Scalars['String']>;
	headingText_not_contains?: InputMaybe<Scalars['String']>;
	headingText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfQuestionStepButtonNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfQuestionStepButtonNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfQuestionStepButtonNestedFilter>>>;
	apiName?: InputMaybe<Scalars['String']>;
	apiName_contains?: InputMaybe<Scalars['String']>;
	apiName_exists?: InputMaybe<Scalars['Boolean']>;
	apiName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiName_not?: InputMaybe<Scalars['String']>;
	apiName_not_contains?: InputMaybe<Scalars['String']>;
	apiName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	iconName?: InputMaybe<Scalars['String']>;
	iconName_contains?: InputMaybe<Scalars['String']>;
	iconName_exists?: InputMaybe<Scalars['Boolean']>;
	iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	iconName_not?: InputMaybe<Scalars['String']>;
	iconName_not_contains?: InputMaybe<Scalars['String']>;
	iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	questionStepButtonDescription?: InputMaybe<Scalars['String']>;
	questionStepButtonDescription_contains?: InputMaybe<Scalars['String']>;
	questionStepButtonDescription_exists?: InputMaybe<Scalars['Boolean']>;
	questionStepButtonDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	questionStepButtonDescription_not?: InputMaybe<Scalars['String']>;
	questionStepButtonDescription_not_contains?: InputMaybe<Scalars['String']>;
	questionStepButtonDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfQuestionnaireNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfQuestionnaireNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfQuestionnaireNestedFilter>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	defaultErrorMessage?: InputMaybe<Scalars['String']>;
	defaultErrorMessage_contains?: InputMaybe<Scalars['String']>;
	defaultErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
	defaultErrorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	defaultErrorMessage_not?: InputMaybe<Scalars['String']>;
	defaultErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
	defaultErrorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	formCollection_exists?: InputMaybe<Scalars['Boolean']>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subTitle?: InputMaybe<Scalars['String']>;
	subTitle_contains?: InputMaybe<Scalars['String']>;
	subTitle_exists?: InputMaybe<Scalars['Boolean']>;
	subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subTitle_not?: InputMaybe<Scalars['String']>;
	subTitle_not_contains?: InputMaybe<Scalars['String']>;
	subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	text?: InputMaybe<Scalars['String']>;
	text_contains?: InputMaybe<Scalars['String']>;
	text_exists?: InputMaybe<Scalars['Boolean']>;
	text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	text_not?: InputMaybe<Scalars['String']>;
	text_not_contains?: InputMaybe<Scalars['String']>;
	text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfQuizNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfQuizNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfQuizNestedFilter>>>;
	brand_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quizFormData_exists?: InputMaybe<Scalars['Boolean']>;
	quizType?: InputMaybe<Scalars['String']>;
	quizType_contains?: InputMaybe<Scalars['String']>;
	quizType_exists?: InputMaybe<Scalars['Boolean']>;
	quizType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	quizType_not?: InputMaybe<Scalars['String']>;
	quizType_not_contains?: InputMaybe<Scalars['String']>;
	quizType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	submitEndpointUrl?: InputMaybe<Scalars['String']>;
	submitEndpointUrl_contains?: InputMaybe<Scalars['String']>;
	submitEndpointUrl_exists?: InputMaybe<Scalars['Boolean']>;
	submitEndpointUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	submitEndpointUrl_not?: InputMaybe<Scalars['String']>;
	submitEndpointUrl_not_contains?: InputMaybe<Scalars['String']>;
	submitEndpointUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfReviewCardNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfReviewCardNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfReviewCardNestedFilter>>>;
	cardName?: InputMaybe<Scalars['String']>;
	cardName_contains?: InputMaybe<Scalars['String']>;
	cardName_exists?: InputMaybe<Scalars['Boolean']>;
	cardName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardName_not?: InputMaybe<Scalars['String']>;
	cardName_not_contains?: InputMaybe<Scalars['String']>;
	cardName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardTitle?: InputMaybe<Scalars['String']>;
	cardTitle_contains?: InputMaybe<Scalars['String']>;
	cardTitle_exists?: InputMaybe<Scalars['Boolean']>;
	cardTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardTitle_not?: InputMaybe<Scalars['String']>;
	cardTitle_not_contains?: InputMaybe<Scalars['String']>;
	cardTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	editButtonText?: InputMaybe<Scalars['String']>;
	editButtonText_contains?: InputMaybe<Scalars['String']>;
	editButtonText_exists?: InputMaybe<Scalars['Boolean']>;
	editButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	editButtonText_not?: InputMaybe<Scalars['String']>;
	editButtonText_not_contains?: InputMaybe<Scalars['String']>;
	editButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfSectionContentNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfSectionContentNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfSectionContentNestedFilter>>>;
	aditionalReferencesCollection_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageShape?: InputMaybe<Scalars['String']>;
	imageShape_contains?: InputMaybe<Scalars['String']>;
	imageShape_exists?: InputMaybe<Scalars['Boolean']>;
	imageShape_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageShape_not?: InputMaybe<Scalars['String']>;
	imageShape_not_contains?: InputMaybe<Scalars['String']>;
	imageShape_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	orientation?: InputMaybe<Scalars['String']>;
	orientation_contains?: InputMaybe<Scalars['String']>;
	orientation_exists?: InputMaybe<Scalars['Boolean']>;
	orientation_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	orientation_not?: InputMaybe<Scalars['String']>;
	orientation_not_contains?: InputMaybe<Scalars['String']>;
	orientation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	richTextContent_contains?: InputMaybe<Scalars['String']>;
	richTextContent_exists?: InputMaybe<Scalars['Boolean']>;
	richTextContent_not_contains?: InputMaybe<Scalars['String']>;
	subTitle?: InputMaybe<Scalars['String']>;
	subTitle_contains?: InputMaybe<Scalars['String']>;
	subTitle_exists?: InputMaybe<Scalars['Boolean']>;
	subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subTitle_not?: InputMaybe<Scalars['String']>;
	subTitle_not_contains?: InputMaybe<Scalars['String']>;
	subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type?: InputMaybe<Scalars['String']>;
	type_contains?: InputMaybe<Scalars['String']>;
	type_exists?: InputMaybe<Scalars['Boolean']>;
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type_not?: InputMaybe<Scalars['String']>;
	type_not_contains?: InputMaybe<Scalars['String']>;
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfServiceNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfServiceNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfServiceNestedFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType?: InputMaybe<Scalars['String']>;
	buttonType_contains?: InputMaybe<Scalars['String']>;
	buttonType_exists?: InputMaybe<Scalars['Boolean']>;
	buttonType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType_not?: InputMaybe<Scalars['String']>;
	buttonType_not_contains?: InputMaybe<Scalars['String']>;
	buttonType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign?: InputMaybe<Scalars['Boolean']>;
	centerAlign_exists?: InputMaybe<Scalars['Boolean']>;
	centerAlign_not?: InputMaybe<Scalars['Boolean']>;
	contained?: InputMaybe<Scalars['Boolean']>;
	contained_exists?: InputMaybe<Scalars['Boolean']>;
	contained_not?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	direction?: InputMaybe<Scalars['String']>;
	direction_contains?: InputMaybe<Scalars['String']>;
	direction_exists?: InputMaybe<Scalars['Boolean']>;
	direction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	direction_not?: InputMaybe<Scalars['String']>;
	direction_not_contains?: InputMaybe<Scalars['String']>;
	direction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageLayout?: InputMaybe<Scalars['String']>;
	imageLayout_contains?: InputMaybe<Scalars['String']>;
	imageLayout_exists?: InputMaybe<Scalars['Boolean']>;
	imageLayout_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageLayout_not?: InputMaybe<Scalars['String']>;
	imageLayout_not_contains?: InputMaybe<Scalars['String']>;
	imageLayout_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	mediaType?: InputMaybe<Scalars['String']>;
	mediaType_contains?: InputMaybe<Scalars['String']>;
	mediaType_exists?: InputMaybe<Scalars['Boolean']>;
	mediaType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mediaType_not?: InputMaybe<Scalars['String']>;
	mediaType_not_contains?: InputMaybe<Scalars['String']>;
	mediaType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	mobileImage_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	richTextDescription_contains?: InputMaybe<Scalars['String']>;
	richTextDescription_exists?: InputMaybe<Scalars['Boolean']>;
	richTextDescription_not_contains?: InputMaybe<Scalars['String']>;
	sys?: InputMaybe<SysFilter>;
	tc_exists?: InputMaybe<Scalars['Boolean']>;
	title?: InputMaybe<Scalars['String']>;
	titleHtag?: InputMaybe<Scalars['String']>;
	titleHtag_contains?: InputMaybe<Scalars['String']>;
	titleHtag_exists?: InputMaybe<Scalars['Boolean']>;
	titleHtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleHtag_not?: InputMaybe<Scalars['String']>;
	titleHtag_not_contains?: InputMaybe<Scalars['String']>;
	titleHtag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	videoImage_exists?: InputMaybe<Scalars['Boolean']>;
	video_exists?: InputMaybe<Scalars['Boolean']>;
	youTubeVideoId?: InputMaybe<Scalars['String']>;
	youTubeVideoId_contains?: InputMaybe<Scalars['String']>;
	youTubeVideoId_exists?: InputMaybe<Scalars['Boolean']>;
	youTubeVideoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	youTubeVideoId_not?: InputMaybe<Scalars['String']>;
	youTubeVideoId_not_contains?: InputMaybe<Scalars['String']>;
	youTubeVideoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	youTubeVideoImage_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfSharedFormDefinitionsNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfSharedFormDefinitionsNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfSharedFormDefinitionsNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	createNewLine?: InputMaybe<Scalars['Boolean']>;
	createNewLine_exists?: InputMaybe<Scalars['Boolean']>;
	createNewLine_not?: InputMaybe<Scalars['Boolean']>;
	description?: InputMaybe<Scalars['String']>;
	descriptionFont?: InputMaybe<Scalars['String']>;
	descriptionFont_contains?: InputMaybe<Scalars['String']>;
	descriptionFont_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionFont_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	descriptionFont_not?: InputMaybe<Scalars['String']>;
	descriptionFont_not_contains?: InputMaybe<Scalars['String']>;
	descriptionFont_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	displayDefinition?: InputMaybe<Scalars['Boolean']>;
	displayDefinition_exists?: InputMaybe<Scalars['Boolean']>;
	displayDefinition_not?: InputMaybe<Scalars['Boolean']>;
	divider?: InputMaybe<Scalars['Boolean']>;
	divider_exists?: InputMaybe<Scalars['Boolean']>;
	divider_not?: InputMaybe<Scalars['Boolean']>;
	formFieldsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	needLabel?: InputMaybe<Scalars['Boolean']>;
	needLabel_exists?: InputMaybe<Scalars['Boolean']>;
	needLabel_not?: InputMaybe<Scalars['Boolean']>;
	phoneSize?: InputMaybe<Scalars['Float']>;
	phoneSize_exists?: InputMaybe<Scalars['Boolean']>;
	phoneSize_gt?: InputMaybe<Scalars['Float']>;
	phoneSize_gte?: InputMaybe<Scalars['Float']>;
	phoneSize_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	phoneSize_lt?: InputMaybe<Scalars['Float']>;
	phoneSize_lte?: InputMaybe<Scalars['Float']>;
	phoneSize_not?: InputMaybe<Scalars['Float']>;
	phoneSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	size?: InputMaybe<Scalars['Float']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_gt?: InputMaybe<Scalars['Float']>;
	size_gte?: InputMaybe<Scalars['Float']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	size_lt?: InputMaybe<Scalars['Float']>;
	size_lte?: InputMaybe<Scalars['Float']>;
	size_not?: InputMaybe<Scalars['Float']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	titleFont?: InputMaybe<Scalars['String']>;
	titleFont_contains?: InputMaybe<Scalars['String']>;
	titleFont_exists?: InputMaybe<Scalars['Boolean']>;
	titleFont_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleFont_not?: InputMaybe<Scalars['String']>;
	titleFont_not_contains?: InputMaybe<Scalars['String']>;
	titleFont_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfSharedFormFieldNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfSharedFormFieldNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfSharedFormFieldNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	createNewLine?: InputMaybe<Scalars['Boolean']>;
	createNewLine_exists?: InputMaybe<Scalars['Boolean']>;
	createNewLine_not?: InputMaybe<Scalars['Boolean']>;
	default?: InputMaybe<Scalars['String']>;
	default_contains?: InputMaybe<Scalars['String']>;
	default_exists?: InputMaybe<Scalars['Boolean']>;
	default_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	default_not?: InputMaybe<Scalars['String']>;
	default_not_contains?: InputMaybe<Scalars['String']>;
	default_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	dependency_exists?: InputMaybe<Scalars['Boolean']>;
	divider?: InputMaybe<Scalars['Boolean']>;
	divider_exists?: InputMaybe<Scalars['Boolean']>;
	divider_not?: InputMaybe<Scalars['Boolean']>;
	enum_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	enum_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	enum_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	enum_exists?: InputMaybe<Scalars['Boolean']>;
	field?: InputMaybe<Scalars['String']>;
	field_contains?: InputMaybe<Scalars['String']>;
	field_exists?: InputMaybe<Scalars['Boolean']>;
	field_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	field_not?: InputMaybe<Scalars['String']>;
	field_not_contains?: InputMaybe<Scalars['String']>;
	field_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	format?: InputMaybe<Scalars['String']>;
	format_contains?: InputMaybe<Scalars['String']>;
	format_exists?: InputMaybe<Scalars['Boolean']>;
	format_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	format_not?: InputMaybe<Scalars['String']>;
	format_not_contains?: InputMaybe<Scalars['String']>;
	format_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	items_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	items_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	items_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	items_exists?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	minLength?: InputMaybe<Scalars['Int']>;
	minLength_exists?: InputMaybe<Scalars['Boolean']>;
	minLength_gt?: InputMaybe<Scalars['Int']>;
	minLength_gte?: InputMaybe<Scalars['Int']>;
	minLength_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	minLength_lt?: InputMaybe<Scalars['Int']>;
	minLength_lte?: InputMaybe<Scalars['Int']>;
	minLength_not?: InputMaybe<Scalars['Int']>;
	minLength_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	needOuterLabel?: InputMaybe<Scalars['Boolean']>;
	needOuterLabel_exists?: InputMaybe<Scalars['Boolean']>;
	needOuterLabel_not?: InputMaybe<Scalars['Boolean']>;
	phoneSize?: InputMaybe<Scalars['Float']>;
	phoneSize_exists?: InputMaybe<Scalars['Boolean']>;
	phoneSize_gt?: InputMaybe<Scalars['Float']>;
	phoneSize_gte?: InputMaybe<Scalars['Float']>;
	phoneSize_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	phoneSize_lt?: InputMaybe<Scalars['Float']>;
	phoneSize_lte?: InputMaybe<Scalars['Float']>;
	phoneSize_not?: InputMaybe<Scalars['Float']>;
	phoneSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	placeHolder?: InputMaybe<Scalars['String']>;
	placeHolder_contains?: InputMaybe<Scalars['String']>;
	placeHolder_exists?: InputMaybe<Scalars['Boolean']>;
	placeHolder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	placeHolder_not?: InputMaybe<Scalars['String']>;
	placeHolder_not_contains?: InputMaybe<Scalars['String']>;
	placeHolder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	required?: InputMaybe<Scalars['Boolean']>;
	required_exists?: InputMaybe<Scalars['Boolean']>;
	required_not?: InputMaybe<Scalars['Boolean']>;
	size?: InputMaybe<Scalars['Float']>;
	size_exists?: InputMaybe<Scalars['Boolean']>;
	size_gt?: InputMaybe<Scalars['Float']>;
	size_gte?: InputMaybe<Scalars['Float']>;
	size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	size_lt?: InputMaybe<Scalars['Float']>;
	size_lte?: InputMaybe<Scalars['Float']>;
	size_not?: InputMaybe<Scalars['Float']>;
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type?: InputMaybe<Scalars['String']>;
	type_contains?: InputMaybe<Scalars['String']>;
	type_exists?: InputMaybe<Scalars['Boolean']>;
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	type_not?: InputMaybe<Scalars['String']>;
	type_not_contains?: InputMaybe<Scalars['String']>;
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfSingleMessageNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfSingleMessageNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfSingleMessageNestedFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonSize?: InputMaybe<Scalars['String']>;
	buttonSize_contains?: InputMaybe<Scalars['String']>;
	buttonSize_exists?: InputMaybe<Scalars['Boolean']>;
	buttonSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonSize_not?: InputMaybe<Scalars['String']>;
	buttonSize_not_contains?: InputMaybe<Scalars['String']>;
	buttonSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType?: InputMaybe<Scalars['String']>;
	buttonType_contains?: InputMaybe<Scalars['String']>;
	buttonType_exists?: InputMaybe<Scalars['Boolean']>;
	buttonType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonType_not?: InputMaybe<Scalars['String']>;
	buttonType_not_contains?: InputMaybe<Scalars['String']>;
	buttonType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign?: InputMaybe<Scalars['String']>;
	contentAlign_contains?: InputMaybe<Scalars['String']>;
	contentAlign_exists?: InputMaybe<Scalars['Boolean']>;
	contentAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentAlign_not?: InputMaybe<Scalars['String']>;
	contentAlign_not_contains?: InputMaybe<Scalars['String']>;
	contentAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentFillVerticalSpace?: InputMaybe<Scalars['Boolean']>;
	contentFillVerticalSpace_exists?: InputMaybe<Scalars['Boolean']>;
	contentFillVerticalSpace_not?: InputMaybe<Scalars['Boolean']>;
	contentSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	content_contains?: InputMaybe<Scalars['String']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	content_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_exists?: InputMaybe<Scalars['Boolean']>;
	facilitySegmentCollection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	facilitySegmentCollection_not?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_contains?: InputMaybe<Scalars['String']>;
	facilitySegmentCollection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImage_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	icon?: InputMaybe<Scalars['String']>;
	icon_contains?: InputMaybe<Scalars['String']>;
	icon_exists?: InputMaybe<Scalars['Boolean']>;
	icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	icon_not?: InputMaybe<Scalars['String']>;
	icon_not_contains?: InputMaybe<Scalars['String']>;
	icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAsset_exists?: InputMaybe<Scalars['Boolean']>;
	isBannerImage?: InputMaybe<Scalars['Boolean']>;
	isBannerImage_exists?: InputMaybe<Scalars['Boolean']>;
	isBannerImage_not?: InputMaybe<Scalars['Boolean']>;
	isInlineLayout?: InputMaybe<Scalars['Boolean']>;
	isInlineLayout_exists?: InputMaybe<Scalars['Boolean']>;
	isInlineLayout_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText?: InputMaybe<Scalars['String']>;
	subText_contains?: InputMaybe<Scalars['String']>;
	subText_exists?: InputMaybe<Scalars['Boolean']>;
	subText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	subText_not?: InputMaybe<Scalars['String']>;
	subText_not_contains?: InputMaybe<Scalars['String']>;
	subText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tc_exists?: InputMaybe<Scalars['Boolean']>;
	templateHeight?: InputMaybe<Scalars['String']>;
	templateHeight_contains?: InputMaybe<Scalars['String']>;
	templateHeight_exists?: InputMaybe<Scalars['Boolean']>;
	templateHeight_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateHeight_not?: InputMaybe<Scalars['String']>;
	templateHeight_not_contains?: InputMaybe<Scalars['String']>;
	templateHeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth?: InputMaybe<Scalars['String']>;
	templateWidth_contains?: InputMaybe<Scalars['String']>;
	templateWidth_exists?: InputMaybe<Scalars['Boolean']>;
	templateWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	templateWidth_not?: InputMaybe<Scalars['String']>;
	templateWidth_not_contains?: InputMaybe<Scalars['String']>;
	templateWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title?: InputMaybe<Scalars['String']>;
	titleVariant?: InputMaybe<Scalars['String']>;
	titleVariant_contains?: InputMaybe<Scalars['String']>;
	titleVariant_exists?: InputMaybe<Scalars['Boolean']>;
	titleVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleVariant_not?: InputMaybe<Scalars['String']>;
	titleVariant_not_contains?: InputMaybe<Scalars['String']>;
	titleVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	useRoundedCorners?: InputMaybe<Scalars['Boolean']>;
	useRoundedCorners_exists?: InputMaybe<Scalars['Boolean']>;
	useRoundedCorners_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfSmallScreenContentNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfSmallScreenContentNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfSmallScreenContentNestedFilter>>>;
	bodySmallScreen_contains?: InputMaybe<Scalars['String']>;
	bodySmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	bodySmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	controllableVideo_exists?: InputMaybe<Scalars['Boolean']>;
	entryName?: InputMaybe<Scalars['String']>;
	entryName_contains?: InputMaybe<Scalars['String']>;
	entryName_exists?: InputMaybe<Scalars['Boolean']>;
	entryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	entryName_not?: InputMaybe<Scalars['String']>;
	entryName_not_contains?: InputMaybe<Scalars['String']>;
	entryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowSmallScreen?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreen_contains?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrowSmallScreen_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrowSmallScreen_not?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	eyebrowSmallScreen_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	focalPointImageSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	headingSmallScreen_contains?: InputMaybe<Scalars['String']>;
	headingSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	headingSmallScreen_not_contains?: InputMaybe<Scalars['String']>;
	imageSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
	mediaFirst?: InputMaybe<Scalars['Boolean']>;
	mediaFirst_exists?: InputMaybe<Scalars['Boolean']>;
	mediaFirst_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	videoSmallScreen_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfStaffNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfStaffNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfStaffNestedFilter>>>;
	biography?: InputMaybe<Scalars['String']>;
	biography_contains?: InputMaybe<Scalars['String']>;
	biography_exists?: InputMaybe<Scalars['Boolean']>;
	biography_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	biography_not?: InputMaybe<Scalars['String']>;
	biography_not_contains?: InputMaybe<Scalars['String']>;
	biography_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeCity?: InputMaybe<Scalars['String']>;
	collegeCity_contains?: InputMaybe<Scalars['String']>;
	collegeCity_exists?: InputMaybe<Scalars['Boolean']>;
	collegeCity_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeCity_not?: InputMaybe<Scalars['String']>;
	collegeCity_not_contains?: InputMaybe<Scalars['String']>;
	collegeCity_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeName?: InputMaybe<Scalars['String']>;
	collegeName_contains?: InputMaybe<Scalars['String']>;
	collegeName_exists?: InputMaybe<Scalars['Boolean']>;
	collegeName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeName_not?: InputMaybe<Scalars['String']>;
	collegeName_not_contains?: InputMaybe<Scalars['String']>;
	collegeName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeState?: InputMaybe<Scalars['String']>;
	collegeState_contains?: InputMaybe<Scalars['String']>;
	collegeState_exists?: InputMaybe<Scalars['Boolean']>;
	collegeState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	collegeState_not?: InputMaybe<Scalars['String']>;
	collegeState_not_contains?: InputMaybe<Scalars['String']>;
	collegeState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	firstName?: InputMaybe<Scalars['String']>;
	firstName_contains?: InputMaybe<Scalars['String']>;
	firstName_exists?: InputMaybe<Scalars['Boolean']>;
	firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	firstName_not?: InputMaybe<Scalars['String']>;
	firstName_not_contains?: InputMaybe<Scalars['String']>;
	firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	graduationDate?: InputMaybe<Scalars['String']>;
	graduationDate_contains?: InputMaybe<Scalars['String']>;
	graduationDate_exists?: InputMaybe<Scalars['Boolean']>;
	graduationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	graduationDate_not?: InputMaybe<Scalars['String']>;
	graduationDate_not_contains?: InputMaybe<Scalars['String']>;
	graduationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	hireDate?: InputMaybe<Scalars['String']>;
	hireDate_contains?: InputMaybe<Scalars['String']>;
	hireDate_exists?: InputMaybe<Scalars['Boolean']>;
	hireDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	hireDate_not?: InputMaybe<Scalars['String']>;
	hireDate_not_contains?: InputMaybe<Scalars['String']>;
	hireDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	image_exists?: InputMaybe<Scalars['Boolean']>;
	jobTitle?: InputMaybe<Scalars['String']>;
	jobTitle_contains?: InputMaybe<Scalars['String']>;
	jobTitle_exists?: InputMaybe<Scalars['Boolean']>;
	jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	jobTitle_not?: InputMaybe<Scalars['String']>;
	jobTitle_not_contains?: InputMaybe<Scalars['String']>;
	jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	languages_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	languages_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	languages_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	languages_exists?: InputMaybe<Scalars['Boolean']>;
	lastName?: InputMaybe<Scalars['String']>;
	lastName_contains?: InputMaybe<Scalars['String']>;
	lastName_exists?: InputMaybe<Scalars['Boolean']>;
	lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	lastName_not?: InputMaybe<Scalars['String']>;
	lastName_not_contains?: InputMaybe<Scalars['String']>;
	lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	location?: InputMaybe<Scalars['String']>;
	location_contains?: InputMaybe<Scalars['String']>;
	location_exists?: InputMaybe<Scalars['Boolean']>;
	location_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	location_not?: InputMaybe<Scalars['String']>;
	location_not_contains?: InputMaybe<Scalars['String']>;
	location_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	specialties?: InputMaybe<Scalars['String']>;
	specialties_contains?: InputMaybe<Scalars['String']>;
	specialties_exists?: InputMaybe<Scalars['Boolean']>;
	specialties_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	specialties_not?: InputMaybe<Scalars['String']>;
	specialties_not_contains?: InputMaybe<Scalars['String']>;
	specialties_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfSuccessCardNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfSuccessCardNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfSuccessCardNestedFilter>>>;
	buttonLink?: InputMaybe<Scalars['String']>;
	buttonLink_contains?: InputMaybe<Scalars['String']>;
	buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
	buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonLink_not?: InputMaybe<Scalars['String']>;
	buttonLink_not_contains?: InputMaybe<Scalars['String']>;
	buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	cardImage_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	descriptionText?: InputMaybe<Scalars['String']>;
	descriptionText_contains?: InputMaybe<Scalars['String']>;
	descriptionText_exists?: InputMaybe<Scalars['Boolean']>;
	descriptionText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	descriptionText_not?: InputMaybe<Scalars['String']>;
	descriptionText_not_contains?: InputMaybe<Scalars['String']>;
	descriptionText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAltText?: InputMaybe<Scalars['String']>;
	imageAltText_contains?: InputMaybe<Scalars['String']>;
	imageAltText_exists?: InputMaybe<Scalars['Boolean']>;
	imageAltText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	imageAltText_not?: InputMaybe<Scalars['String']>;
	imageAltText_not_contains?: InputMaybe<Scalars['String']>;
	imageAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	titleText?: InputMaybe<Scalars['String']>;
	titleText_contains?: InputMaybe<Scalars['String']>;
	titleText_exists?: InputMaybe<Scalars['Boolean']>;
	titleText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	titleText_not?: InputMaybe<Scalars['String']>;
	titleText_not_contains?: InputMaybe<Scalars['String']>;
	titleText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfTabNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfTabNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfTabNestedFilter>>>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfTableNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfTableNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfTableNestedFilter>>>;
	bodyRowsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	border?: InputMaybe<Scalars['Boolean']>;
	border_exists?: InputMaybe<Scalars['Boolean']>;
	border_not?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	firstColumnSticky?: InputMaybe<Scalars['Boolean']>;
	firstColumnSticky_exists?: InputMaybe<Scalars['Boolean']>;
	firstColumnSticky_not?: InputMaybe<Scalars['Boolean']>;
	fontSize?: InputMaybe<Scalars['String']>;
	fontSize_contains?: InputMaybe<Scalars['String']>;
	fontSize_exists?: InputMaybe<Scalars['Boolean']>;
	fontSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	fontSize_not?: InputMaybe<Scalars['String']>;
	fontSize_not_contains?: InputMaybe<Scalars['String']>;
	fontSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	footnote_contains?: InputMaybe<Scalars['String']>;
	footnote_exists?: InputMaybe<Scalars['Boolean']>;
	footnote_not_contains?: InputMaybe<Scalars['String']>;
	headerRow_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tableContent_contains?: InputMaybe<Scalars['String']>;
	tableContent_exists?: InputMaybe<Scalars['Boolean']>;
	tableContent_not_contains?: InputMaybe<Scalars['String']>;
	variant?: InputMaybe<Scalars['String']>;
	variant_contains?: InputMaybe<Scalars['String']>;
	variant_exists?: InputMaybe<Scalars['Boolean']>;
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	variant_not?: InputMaybe<Scalars['String']>;
	variant_not_contains?: InputMaybe<Scalars['String']>;
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfTableRowNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfTableRowNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfTableRowNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	imagesInTableCellsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tableCellsJsonData_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfTabsNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfTabsNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfTabsNestedFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tabRowVariant?: InputMaybe<Scalars['String']>;
	tabRowVariant_contains?: InputMaybe<Scalars['String']>;
	tabRowVariant_exists?: InputMaybe<Scalars['Boolean']>;
	tabRowVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tabRowVariant_not?: InputMaybe<Scalars['String']>;
	tabRowVariant_not_contains?: InputMaybe<Scalars['String']>;
	tabRowVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	tabsSize?: InputMaybe<Scalars['String']>;
	tabsSize_contains?: InputMaybe<Scalars['String']>;
	tabsSize_exists?: InputMaybe<Scalars['Boolean']>;
	tabsSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tabsSize_not?: InputMaybe<Scalars['String']>;
	tabsSize_not_contains?: InputMaybe<Scalars['String']>;
	tabsSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	withContentInnerGap?: InputMaybe<Scalars['Boolean']>;
	withContentInnerGap_exists?: InputMaybe<Scalars['Boolean']>;
	withContentInnerGap_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfTermsAndConditionsNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfTermsAndConditionsNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfTermsAndConditionsNestedFilter>>>;
	content_contains?: InputMaybe<Scalars['String']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	content_not_contains?: InputMaybe<Scalars['String']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	headlineText?: InputMaybe<Scalars['String']>;
	headlineText_contains?: InputMaybe<Scalars['String']>;
	headlineText_exists?: InputMaybe<Scalars['Boolean']>;
	headlineText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	headlineText_not?: InputMaybe<Scalars['String']>;
	headlineText_not_contains?: InputMaybe<Scalars['String']>;
	headlineText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	showTcTextAs?: InputMaybe<Scalars['Boolean']>;
	showTcTextAs_exists?: InputMaybe<Scalars['Boolean']>;
	showTcTextAs_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	tcLink?: InputMaybe<Scalars['String']>;
	tcLink_contains?: InputMaybe<Scalars['String']>;
	tcLink_exists?: InputMaybe<Scalars['Boolean']>;
	tcLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcLink_not?: InputMaybe<Scalars['String']>;
	tcLink_not_contains?: InputMaybe<Scalars['String']>;
	tcLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcText?: InputMaybe<Scalars['String']>;
	tcText_contains?: InputMaybe<Scalars['String']>;
	tcText_exists?: InputMaybe<Scalars['Boolean']>;
	tcText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	tcText_not?: InputMaybe<Scalars['String']>;
	tcText_not_contains?: InputMaybe<Scalars['String']>;
	tcText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfVideoAssetNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfVideoAssetNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfVideoAssetNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	desktopVideo_exists?: InputMaybe<Scalars['Boolean']>;
	mobileVideo_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfVideoNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfVideoNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfVideoNestedFilter>>>;
	adjustedWidth?: InputMaybe<Scalars['Int']>;
	adjustedWidth_exists?: InputMaybe<Scalars['Boolean']>;
	adjustedWidth_gt?: InputMaybe<Scalars['Int']>;
	adjustedWidth_gte?: InputMaybe<Scalars['Int']>;
	adjustedWidth_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	adjustedWidth_lt?: InputMaybe<Scalars['Int']>;
	adjustedWidth_lte?: InputMaybe<Scalars['Int']>;
	adjustedWidth_not?: InputMaybe<Scalars['Int']>;
	adjustedWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	autoPlay?: InputMaybe<Scalars['Boolean']>;
	autoPlay_exists?: InputMaybe<Scalars['Boolean']>;
	autoPlay_not?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	isLooped?: InputMaybe<Scalars['Boolean']>;
	isLooped_exists?: InputMaybe<Scalars['Boolean']>;
	isLooped_not?: InputMaybe<Scalars['Boolean']>;
	isMuted?: InputMaybe<Scalars['Boolean']>;
	isMuted_exists?: InputMaybe<Scalars['Boolean']>;
	isMuted_not?: InputMaybe<Scalars['Boolean']>;
	media_exists?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	posterMedia_exists?: InputMaybe<Scalars['Boolean']>;
	posterUrl?: InputMaybe<Scalars['String']>;
	posterUrl_contains?: InputMaybe<Scalars['String']>;
	posterUrl_exists?: InputMaybe<Scalars['Boolean']>;
	posterUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	posterUrl_not?: InputMaybe<Scalars['String']>;
	posterUrl_not_contains?: InputMaybe<Scalars['String']>;
	posterUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	useOriginalVideoSize?: InputMaybe<Scalars['Boolean']>;
	useOriginalVideoSize_exists?: InputMaybe<Scalars['Boolean']>;
	useOriginalVideoSize_not?: InputMaybe<Scalars['Boolean']>;
	youtubeId?: InputMaybe<Scalars['String']>;
	youtubeId_contains?: InputMaybe<Scalars['String']>;
	youtubeId_exists?: InputMaybe<Scalars['Boolean']>;
	youtubeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	youtubeId_not?: InputMaybe<Scalars['String']>;
	youtubeId_not_contains?: InputMaybe<Scalars['String']>;
	youtubeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfVideoWithContentNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfVideoWithContentNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfVideoWithContentNestedFilter>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	content_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	video_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfaccordionsMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfaccordionsMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfaccordionsMultiTypeNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	disablePaddingOn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	disablePaddingOn_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth?: InputMaybe<Scalars['Boolean']>;
	fullWidth_exists?: InputMaybe<Scalars['Boolean']>;
	fullWidth_not?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfcolumnsMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfcolumnsMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfcolumnsMultiTypeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type CfcontentListMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfcontentListMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfcontentListMultiTypeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfcontentsMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfcontentsMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfcontentsMultiTypeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type CfformFieldMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfformFieldMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfformFieldMultiTypeNestedFilter>>>;
	alertMessage?: InputMaybe<Scalars['String']>;
	alertMessage_contains?: InputMaybe<Scalars['String']>;
	alertMessage_exists?: InputMaybe<Scalars['Boolean']>;
	alertMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	alertMessage_not?: InputMaybe<Scalars['String']>;
	alertMessage_not_contains?: InputMaybe<Scalars['String']>;
	alertMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiKey?: InputMaybe<Scalars['String']>;
	apiKey_contains?: InputMaybe<Scalars['String']>;
	apiKey_exists?: InputMaybe<Scalars['Boolean']>;
	apiKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	apiKey_not?: InputMaybe<Scalars['String']>;
	apiKey_not_contains?: InputMaybe<Scalars['String']>;
	apiKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	label?: InputMaybe<Scalars['String']>;
	label_contains?: InputMaybe<Scalars['String']>;
	label_exists?: InputMaybe<Scalars['Boolean']>;
	label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	label_not?: InputMaybe<Scalars['String']>;
	label_not_contains?: InputMaybe<Scalars['String']>;
	label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	required?: InputMaybe<Scalars['Boolean']>;
	required_exists?: InputMaybe<Scalars['Boolean']>;
	required_not?: InputMaybe<Scalars['Boolean']>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfformMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfformMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfformMultiTypeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CflocationNavigationLinksMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CflocationNavigationLinksMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CflocationNavigationLinksMultiTypeNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfmediaContentMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfmediaContentMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfmediaContentMultiTypeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfnavigationGroupLinkMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfnavigationGroupLinkMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfnavigationGroupLinkMultiTypeNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfnavigationLinksForGroupMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfnavigationLinksForGroupMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfnavigationLinksForGroupMultiTypeNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfnavigationLinksMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfnavigationLinksMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfnavigationLinksMultiTypeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type CfrotationContentMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfrotationContentMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfrotationContentMultiTypeNestedFilter>>>;
	backgroundColor?: InputMaybe<Scalars['String']>;
	backgroundColor_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
	backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	backgroundColor_not?: InputMaybe<Scalars['String']>;
	backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
	backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText?: InputMaybe<Scalars['String']>;
	buttonText_contains?: InputMaybe<Scalars['String']>;
	buttonText_exists?: InputMaybe<Scalars['Boolean']>;
	buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonText_not?: InputMaybe<Scalars['String']>;
	buttonText_not_contains?: InputMaybe<Scalars['String']>;
	buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl?: InputMaybe<Scalars['String']>;
	buttonUrl_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_exists?: InputMaybe<Scalars['Boolean']>;
	buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonUrl_not?: InputMaybe<Scalars['String']>;
	buttonUrl_not_contains?: InputMaybe<Scalars['String']>;
	buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	description?: InputMaybe<Scalars['String']>;
	description_contains?: InputMaybe<Scalars['String']>;
	description_exists?: InputMaybe<Scalars['Boolean']>;
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	description_not?: InputMaybe<Scalars['String']>;
	description_not_contains?: InputMaybe<Scalars['String']>;
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow?: InputMaybe<Scalars['String']>;
	eyebrow_contains?: InputMaybe<Scalars['String']>;
	eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
	eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	eyebrow_not?: InputMaybe<Scalars['String']>;
	eyebrow_not_contains?: InputMaybe<Scalars['String']>;
	eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
	tc_exists?: InputMaybe<Scalars['Boolean']>;
	title?: InputMaybe<Scalars['String']>;
	title_contains?: InputMaybe<Scalars['String']>;
	title_exists?: InputMaybe<Scalars['Boolean']>;
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	title_not?: InputMaybe<Scalars['String']>;
	title_not_contains?: InputMaybe<Scalars['String']>;
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfsecondaryContentsMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfsecondaryContentsMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfsecondaryContentsMultiTypeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfsecondaryNavigationLinksMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfsecondaryNavigationLinksMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfsecondaryNavigationLinksMultiTypeNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfsocialNetworksMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfsocialNetworksMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfsocialNetworksMultiTypeNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type CfstepsMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfstepsMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CfstepsMultiTypeNestedFilter>>>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	sys?: InputMaybe<SysFilter>;
};

export type CftopNavigationLinksMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CftopNavigationLinksMultiTypeNestedFilter>>>;
	OR?: InputMaybe<Array<InputMaybe<CftopNavigationLinksMultiTypeNestedFilter>>>;
	analyticsContext_exists?: InputMaybe<Scalars['Boolean']>;
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
	name?: InputMaybe<Scalars['String']>;
	name_contains?: InputMaybe<Scalars['String']>;
	name_exists?: InputMaybe<Scalars['Boolean']>;
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	name_not?: InputMaybe<Scalars['String']>;
	name_not_contains?: InputMaybe<Scalars['String']>;
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	sys?: InputMaybe<SysFilter>;
};

export type BffFacilitiesQueryVariables = Exact<{
	brand: BffBrandType;
	take: Scalars['Int']['input'];
	skip: Scalars['Int']['input'];
}>;

export type BffFacilitiesQuery = {
	__typename?: 'Query';
	facilityCollection?: {
		__typename?: 'BffFacilityCollection';
		skip?: number | null;
		take?: number | null;
		total?: number | null;
		items?: Array<({ __typename?: 'BffFacility' } & BffFacilityFieldsFragment) | null> | null;
	} | null;
};

export type BffFacilityAddressFragment = {
	__typename?: 'BffFacilityAddress';
	address1?: string | null;
	address2?: string | null;
	city?: string | null;
	stateCode?: string | null;
	zipCode?: string | null;
};

export type BffFacilityLocationFragment = {
	__typename?: 'BffFacilityLocation';
	distance?: number | null;
	landmarks?: string | null;
	latitude?: number | null;
	longitude?: number | null;
	neighboringAreas?: string | null;
	timeZone?: string | null;
};

export type BffTimeSpanFragment = { __typename?: 'BffTimeSpan'; from?: string | null; to?: string | null };

export type BffWorkingHoursFragment = {
	__typename?: 'BffWorkingHours';
	date?: string | null;
	open?: ({ __typename?: 'BffTimeSpan' } & BffTimeSpanFragment) | null;
};

export type BffFacilityFieldsFragment = {
	__typename?: 'BffFacility';
	name?: string | null;
	code: string;
	brand?: string | null;
	phoneNumber?: string | null;
	scheduleAppointmentURL?: string | null;
	schedulingSystem?: string | null;
	revspringAccountId?: string | null;
	revspringLocationValue?: string | null;
	status?: string | null;
	address?: ({ __typename?: 'BffFacilityAddress' } & BffFacilityAddressFragment) | null;
	location?: ({ __typename?: 'BffFacilityLocation' } & BffFacilityLocationFragment) | null;
	workingHours?: Array<({ __typename?: 'BffWorkingHours' } & BffWorkingHoursFragment) | null> | null;
};

export type BffFacilitiesByAddressQueryVariables = Exact<{
	brand: BffBrandType;
	address: Scalars['String']['input'];
}>;

export type BffFacilitiesByAddressQuery = {
	__typename?: 'Query';
	facilitiesByAddress?: Array<({ __typename?: 'BffFacility' } & BffFacilityFieldsFragment) | null> | null;
};

export type BffFacilityByIdQueryVariables = Exact<{
	brand: BffBrandType;
	id: Scalars['String']['input'];
}>;

export type BffFacilityByIdQuery = {
	__typename?: 'Query';
	facilityById?: ({ __typename?: 'BffFacility' } & BffFacilityFieldsFragment) | null;
};

export type BffNearByFacilitiesByLocationQueryVariables = Exact<{
	brand: BffBrandType;
	latitude: Scalars['Float']['input'];
	longitude: Scalars['Float']['input'];
}>;

export type BffNearByFacilitiesByLocationQuery = {
	__typename?: 'Query';
	facilityByLocation?: Array<({ __typename?: 'BffFacility' } & BffFacilityFieldsFragment) | null> | null;
};

export type BlogsListByBrandQueryVariables = Exact<{
	limit: Scalars['Int']['input'];
	skip: Scalars['Int']['input'];
	brandName: Scalars['String']['input'];
	order?: InputMaybe<Array<BlogDetailsPageOrder> | BlogDetailsPageOrder>;
	preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BlogsListByBrandQuery = {
	__typename?: 'Query';
	blogDetailsPageCollection?: {
		__typename?: 'BlogDetailsPageCollection';
		total: number;
		skip: number;
		limit: number;
		items: Array<{
			__typename?: 'BlogDetailsPage';
			timeToRead?: number | null;
			title?: string | null;
			path?: string | null;
			blogImage?: any | null;
			date?: any | null;
			category?: { __typename?: 'BlogCategory'; name?: string | null; slug?: string | null } | null;
			summary?: { __typename?: 'BlogDetailsPageSummary'; json: any } | null;
			imageAsset?: { __typename?: 'ImageAsset'; desktopImage?: any | null; mobileImage?: any | null } | null;
		} | null>;
	} | null;
};

export type FacilityByCodeQueryVariables = Exact<{
	code: Scalars['String']['input'];
	brand: FacilityBrand;
}>;

export type FacilityByCodeQuery = {
	__typename?: 'Query';
	facility?: ({ __typename?: 'Facility' } & FacilityFieldsFragment) | null;
};

export type InsuranceByStateQueryVariables = Exact<{
	filter: InsuranceCompaniesFilterInput;
}>;

export type InsuranceByStateQuery = {
	__typename?: 'Query';
	insuranceCompanies?: {
		__typename?: 'InsuranceCompaniesList';
		total: number;
		items: Array<{ __typename?: 'InsuranceCompany'; name?: string | null }>;
	} | null;
};

export type NearbyFacilitiesQueryVariables = Exact<{
	filter: NearbyFacilitiesLocationFilter;
	skip?: InputMaybe<Scalars['NonNegativeInt']['input']>;
	take?: InputMaybe<Scalars['NonNegativeInt']['input']>;
}>;

export type NearbyFacilitiesQuery = {
	__typename?: 'Query';
	nearbyFacilities?: {
		__typename?: 'NearbyFacilitiesList';
		hasMore: boolean;
		items: Array<{
			__typename?: 'NearbyFacility';
			distanceInMiles: any;
			facility: { __typename?: 'Facility' } & FacilityFieldsFragment;
		}>;
	} | null;
	callCenterHours: Array<{
		__typename?: 'WorkingHours';
		day: string;
		intervals: Array<{ __typename?: 'Intervals'; startTime?: string | null; endTime?: string | null }>;
	}>;
};

export type FacilityFieldsFragment = {
	__typename?: 'Facility';
	name: string;
	code: string;
	brand: FacilityBrand;
	scheduling?: { __typename?: 'FacilityScheduling'; schedulingUrl?: string | null } | null;
	technicalInfrastructure?: {
		__typename?: 'FacilityTechnicalInfrastructure';
		phoneNumber?: string | null;
		faxNumber?: string | null;
		email?: string | null;
	} | null;
	address: {
		__typename?: 'FacilityAddress';
		address1?: string | null;
		address2?: string | null;
		city?: string | null;
		stateCode?: string | null;
		zipCode?: string | null;
	};
	location?: {
		__typename?: 'FacilityLocation';
		latitude: any;
		longitude: any;
		dst: boolean;
		landmarks?: string | null;
		neighbouringAreas?: string | null;
	} | null;
	workingHours?: Array<{
		__typename?: 'FacilityDailyWorkingHours';
		date: any;
		open?: { __typename?: 'FacilityWorkingHours'; from?: any | null; to?: any | null } | null;
	}> | null;
};

export type FacilityDetailsWithNearbyFacilitiesQueryVariables = Exact<{
	code: Scalars['String']['input'];
	radius: Scalars['Decimal']['input'];
	skip: Scalars['NonNegativeInt']['input'];
	take: Scalars['NonNegativeInt']['input'];
	brand?: InputMaybe<FacilityBrand>;
}>;

export type FacilityDetailsWithNearbyFacilitiesQuery = {
	__typename?: 'Query';
	facility?:
		| ({
				__typename?: 'Facility';
				nearbyFacilities?: {
					__typename?: 'NearbyFacilitiesList';
					items: Array<{
						__typename?: 'NearbyFacility';
						distanceInMiles: any;
						facility: { __typename?: 'Facility' } & FacilityFieldsFragment;
					}>;
				} | null;
		  } & FacilityFieldsFragment)
		| null;
};

export type ProviderDetailsByIdQueryVariables = Exact<{
	npi: Scalars['NonEmptyString']['input'];
}>;

export type ProviderDetailsByIdQuery = {
	__typename?: 'Query';
	provider?: {
		__typename?: 'Provider';
		npi?: string | null;
		mainWorkerFunction?: string | null;
		firstName?: string | null;
		middleName?: string | null;
		lastName?: string | null;
		degreeName?: string | null;
		associations?: Array<string> | null;
		languages?: Array<string> | null;
		biography?: string | null;
		collegeCity?: string | null;
		collegeName?: string | null;
		collegeState?: string | null;
		hireDate?: any | null;
		providerLocations?: Array<{
			__typename?: 'ProviderLocation';
			isMain: boolean;
			facility?: {
				__typename?: 'Facility';
				name: string;
				code: string;
				brand: FacilityBrand;
				technicalInfrastructure?: {
					__typename?: 'FacilityTechnicalInfrastructure';
					phoneNumber?: string | null;
				} | null;
				address: {
					__typename?: 'FacilityAddress';
					address1?: string | null;
					zipCode?: string | null;
					city?: string | null;
					stateCode?: string | null;
				};
				location?: {
					__typename?: 'FacilityLocation';
					latitude: any;
					longitude: any;
					address1?: string | null;
					stateCode?: string | null;
					city?: string | null;
				} | null;
				insuranceInfo?: {
					__typename?: 'FacilityInsuranceInfo';
					companies: Array<{ __typename?: 'InsuranceCompany'; name?: string | null }>;
				} | null;
			} | null;
		}> | null;
	} | null;
};

export const BffFacilityAddressFragmentDoc = gql`
	fragment BffFacilityAddress on BffFacilityAddress {
		address1
		address2
		city
		stateCode
		zipCode
	}
`;
export const BffFacilityLocationFragmentDoc = gql`
	fragment BffFacilityLocation on BffFacilityLocation {
		distance
		landmarks
		latitude
		longitude
		neighboringAreas
		timeZone
	}
`;
export const BffTimeSpanFragmentDoc = gql`
	fragment BffTimeSpan on BffTimeSpan {
		from
		to
	}
`;
export const BffWorkingHoursFragmentDoc = gql`
	fragment BffWorkingHours on BffWorkingHours {
		date
		open {
			...BffTimeSpan
		}
	}
`;
export const BffFacilityFieldsFragmentDoc = gql`
	fragment BffFacilityFields on BffFacility {
		name
		code
		brand
		phoneNumber
		scheduleAppointmentURL
		schedulingSystem
		revspringAccountId
		revspringLocationValue
		address {
			...BffFacilityAddress
		}
		location {
			...BffFacilityLocation
		}
		workingHours {
			...BffWorkingHours
		}
		status
	}
`;
export const FacilityFieldsFragmentDoc = gql`
	fragment FacilityFields on Facility {
		name
		code
		brand
		scheduling {
			schedulingUrl
		}
		technicalInfrastructure {
			phoneNumber
			faxNumber
			email
		}
		address {
			address1
			address2
			city
			stateCode
			zipCode
		}
		location {
			latitude
			longitude
			dst
			landmarks
			neighbouringAreas
		}
		workingHours {
			date
			open {
				from
				to
			}
		}
	}
`;
export const BffFacilitiesDocument = gql`
	query bffFacilities($brand: BffBrandType!, $take: Int!, $skip: Int!) {
		facilityCollection(brand: $brand, take: $take, skip: $skip) {
			items {
				...BffFacilityFields
			}
			skip
			take
			total
		}
	}
	${BffFacilityFieldsFragmentDoc}
	${BffFacilityAddressFragmentDoc}
	${BffFacilityLocationFragmentDoc}
	${BffWorkingHoursFragmentDoc}
	${BffTimeSpanFragmentDoc}
`;
export const BffFacilitiesByAddressDocument = gql`
	query bffFacilitiesByAddress($brand: BffBrandType!, $address: String!) {
		facilitiesByAddress(brand: $brand, address: $address) {
			...BffFacilityFields
		}
	}
	${BffFacilityFieldsFragmentDoc}
	${BffFacilityAddressFragmentDoc}
	${BffFacilityLocationFragmentDoc}
	${BffWorkingHoursFragmentDoc}
	${BffTimeSpanFragmentDoc}
`;
export const BffFacilityByIdDocument = gql`
	query bffFacilityById($brand: BffBrandType!, $id: String!) {
		facilityById(brand: $brand, id: $id) {
			...BffFacilityFields
		}
	}
	${BffFacilityFieldsFragmentDoc}
	${BffFacilityAddressFragmentDoc}
	${BffFacilityLocationFragmentDoc}
	${BffWorkingHoursFragmentDoc}
	${BffTimeSpanFragmentDoc}
`;
export const BffNearByFacilitiesByLocationDocument = gql`
	query bffNearByFacilitiesByLocation($brand: BffBrandType!, $latitude: Float!, $longitude: Float!) {
		facilityByLocation(brand: $brand, latitude: $latitude, longitude: $longitude) {
			...BffFacilityFields
		}
	}
	${BffFacilityFieldsFragmentDoc}
	${BffFacilityAddressFragmentDoc}
	${BffFacilityLocationFragmentDoc}
	${BffWorkingHoursFragmentDoc}
	${BffTimeSpanFragmentDoc}
`;
export const BlogsListByBrandDocument = gql`
	query blogsListByBrand(
		$limit: Int!
		$skip: Int!
		$brandName: String!
		$order: [BlogDetailsPageOrder!]
		$preview: Boolean = false
	) {
		blogDetailsPageCollection(
			limit: $limit
			skip: $skip
			where: { brand: { title: $brandName } }
			order: $order
			preview: $preview
		) {
			items {
				category {
					name
					slug
				}
				timeToRead
				title
				path
				blogImage
				date
				summary {
					json
				}
				imageAsset {
					desktopImage
					mobileImage
				}
			}
			total
			skip
			limit
		}
	}
`;
export const FacilityByCodeDocument = gql`
	query facilityByCode($code: String!, $brand: FacilityBrand!) {
		facility(code: $code, brand: $brand) {
			...FacilityFields
		}
	}
	${FacilityFieldsFragmentDoc}
`;
export const InsuranceByStateDocument = gql`
	query insuranceByState($filter: InsuranceCompaniesFilterInput!) {
		insuranceCompanies(filter: $filter) {
			total
			items {
				name
			}
		}
	}
`;
export const NearbyFacilitiesDocument = gql`
	query nearbyFacilities(
		$filter: NearbyFacilitiesLocationFilter!
		$skip: NonNegativeInt = 0
		$take: NonNegativeInt = 5
	) {
		nearbyFacilities(filter: $filter, skip: $skip, take: $take) {
			items {
				distanceInMiles
				facility {
					...FacilityFields
				}
			}
			hasMore
		}
		callCenterHours {
			day
			intervals {
				startTime
				endTime
			}
		}
	}
	${FacilityFieldsFragmentDoc}
`;
export const FacilityDetailsWithNearbyFacilitiesDocument = gql`
	query facilityDetailsWithNearbyFacilities(
		$code: String!
		$radius: Decimal!
		$skip: NonNegativeInt!
		$take: NonNegativeInt!
		$brand: FacilityBrand
	) {
		facility(code: $code, brand: $brand) {
			...FacilityFields
			nearbyFacilities(filter: { withinRadius: $radius }, skip: $skip, take: $take) {
				items {
					distanceInMiles
					facility {
						...FacilityFields
					}
				}
			}
		}
	}
	${FacilityFieldsFragmentDoc}
`;
export const ProviderDetailsByIdDocument = gql`
	query providerDetailsById($npi: NonEmptyString!) {
		provider(npi: $npi) {
			npi
			mainWorkerFunction
			firstName
			middleName
			lastName
			degreeName
			associations
			languages
			biography
			collegeCity
			collegeName
			collegeState
			hireDate
			providerLocations {
				isMain
				facility {
					name
					code
					brand
					technicalInfrastructure {
						phoneNumber
					}
					address {
						address1
						zipCode
						city
						stateCode
					}
					location {
						latitude
						longitude
						address1
						stateCode
						city
					}
					insuranceInfo {
						companies {
							name
						}
					}
				}
			}
		}
	}
`;

export type SdkFunctionWrapper = <T>(
	action: (requestHeaders?: Record<string, string>) => Promise<T>,
	operationName: string,
	operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
	return {
		bffFacilities(
			variables: BffFacilitiesQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<BffFacilitiesQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<BffFacilitiesQuery>(BffFacilitiesDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'bffFacilities',
				'query'
			);
		},
		bffFacilitiesByAddress(
			variables: BffFacilitiesByAddressQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<BffFacilitiesByAddressQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<BffFacilitiesByAddressQuery>(BffFacilitiesByAddressDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'bffFacilitiesByAddress',
				'query'
			);
		},
		bffFacilityById(
			variables: BffFacilityByIdQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<BffFacilityByIdQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<BffFacilityByIdQuery>(BffFacilityByIdDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'bffFacilityById',
				'query'
			);
		},
		bffNearByFacilitiesByLocation(
			variables: BffNearByFacilitiesByLocationQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<BffNearByFacilitiesByLocationQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<BffNearByFacilitiesByLocationQuery>(
						BffNearByFacilitiesByLocationDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'bffNearByFacilitiesByLocation',
				'query'
			);
		},
		blogsListByBrand(
			variables: BlogsListByBrandQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<BlogsListByBrandQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<BlogsListByBrandQuery>(BlogsListByBrandDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'blogsListByBrand',
				'query'
			);
		},
		facilityByCode(
			variables: FacilityByCodeQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<FacilityByCodeQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<FacilityByCodeQuery>(FacilityByCodeDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'facilityByCode',
				'query'
			);
		},
		insuranceByState(
			variables: InsuranceByStateQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<InsuranceByStateQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<InsuranceByStateQuery>(InsuranceByStateDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'insuranceByState',
				'query'
			);
		},
		nearbyFacilities(
			variables: NearbyFacilitiesQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<NearbyFacilitiesQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<NearbyFacilitiesQuery>(NearbyFacilitiesDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'nearbyFacilities',
				'query'
			);
		},
		facilityDetailsWithNearbyFacilities(
			variables: FacilityDetailsWithNearbyFacilitiesQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<FacilityDetailsWithNearbyFacilitiesQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<FacilityDetailsWithNearbyFacilitiesQuery>(
						FacilityDetailsWithNearbyFacilitiesDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'facilityDetailsWithNearbyFacilities',
				'query'
			);
		},
		providerDetailsById(
			variables: ProviderDetailsByIdQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<ProviderDetailsByIdQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<ProviderDetailsByIdQuery>(ProviderDetailsByIdDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'providerDetailsById',
				'query'
			);
		},
	};
}
export type Sdk = ReturnType<typeof getSdk>;
