interface DeviceSize {
	isSmall: boolean;
	isMedium: boolean;
}

const getWrapperVerticalPadding = (deviceSize: DeviceSize) => {
	return deviceSize.isSmall ? '40px' : '60px';
};

const getWrapperHorizontalPadding = (deviceSize: DeviceSize) => {
	if (deviceSize.isSmall) return '20px';
	if (deviceSize.isMedium) return '2%';
	return '5%';
};

export const getWrapperStyles = (deviceSize: DeviceSize) => {
	const verticalPadding = getWrapperVerticalPadding(deviceSize);
	const horizontalPadding = getWrapperHorizontalPadding(deviceSize);

	return {
		display: 'flex',
		backgroundColor: 'backgrounds.light',
		padding: [verticalPadding, horizontalPadding].join(' '),
	};
};

export const getAboutWrapperStyles = (isSmallDevice: boolean) => ({
	display: 'flex',
	justifyContent: 'start',
	alignItems: 'start',
	color: 'neutrals.white',
	gap: 1,
	fontSize: isSmallDevice ? '0.875rem' : '1.125rem',
	paddingBottom: '1rem',
	flexDirection: 'column',
});

export const getSocialImageStyle = {
	width: '2.5rem',
	height: '2.5rem',
	filter: 'brightness(0) saturate(100%) invert(15%) sepia(53%) saturate(4016%) hue-rotate(208deg) brightness(93%) contrast(112%)',
};

export const disclaimerWrapperStyles = {
	'&.MuiContainer-root': {
		maxWidth: { md: 'none' },
	},
	px: 0,
};
