export const footerContainerStyles = {
	display: 'flex',
	justifyContent: 'start',
	alignItems: 'start',
	flexDirection: 'column',
	gap: 2,
};

export const getSubscribeInputWrapperStyles = {
	'& fieldset': { border: 'none' },
};

export const getSubscribeInputStyles = {
	background: 'white',
};
