import { AppBar, Toolbar, Box } from '@mui/material';

import ImageWrapper from '@/components/ImageWrapper';
import TAGLink from '@/components/TAGLink';

// import HeaderSearch from '../HeaderSearch';
import NavigationBar from '../NavigationBar';
import { IHeaderWithLocationNav } from '../HeaderWithLocationNav';
import LocationNavBar from '../LocationNavBar';

import {
	logoWrapStyles,
	logoStyles,
	headerStyles,
	navigationStyles,
	searchWrapStyles,
	toolbarStyles,
} from './HeaderDesktop.styles';

export default function HeaderDesktop({ logoUrl, logoHref = '/', headerData }: IHeaderWithLocationNav) {
	// const [showSearchInputField, setShowSearchInputField] = useState(false);
	return (
		<AppBar data-test-id="header_desktop_with_location_nav" sx={headerStyles}>
			<Toolbar disableGutters sx={toolbarStyles}>
				<Box sx={logoWrapStyles}>
					<TAGLink href={logoHref}>
						<ImageWrapper
							src={logoUrl}
							width="200px"
							height="unset"
							sx={logoStyles}
							data-test-id="img_logo_desktop_with_location_nav"
							alt="brand logo"
						/>
					</TAGLink>
					<NavigationBar
						navigationItems={headerData?.navigationLinksCollection}
						navigationSx={navigationStyles}
					/>
				</Box>

				<Box sx={searchWrapStyles}>
					{/* TODO: next iteration */}
					{/* <HeaderSearch
						showSearchInputField={showSearchInputField}
						setShowSearchInputField={setShowSearchInputField}
					/> */}
					{/* {!showSearchInputField && ( */}
					<NavigationBar
						navigationItems={headerData?.secondaryNavigationLinksCollection}
						navigationSx={navigationStyles}
						isNavLinkSemibold={false}
					/>
					{/* )} */}
				</Box>
			</Toolbar>
			<LocationNavBar
				schedulingButtonText={headerData?.schedulingButtonText}
				locationBarFindOfficeUrl={headerData?.locationBarFindOfficeUrl}
			/>
		</AppBar>
	);
}
