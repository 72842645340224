import { SxProps } from '@mui/material';

import { Asset, Maybe } from '@/types/generated';
import { incorrectUsageWarning } from '@/utils/miscUtils';

import ImageWrapper from '../ImageWrapper';

import { getBackgroundImageStyles, getImageStyles } from './FocalPointImage.styles';
import { optimizeImageUrl, resolveImagePosition, resolveNextImageProps } from './FocalPointImage.helpers';

export type FocalPointData = {
	focalPoint: {
		x: number;
		y: number;
	};
};

export interface BrandFolderImage extends Asset {
	cdn_url?: string;
	appendWebpToUrl?: boolean;
}

interface IFocalPointImage {
	/**
	 * The image to display.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IFocalPointImage
	 */
	image?: Maybe<Asset>;
	/**
	 * The image to display.
	 *
	 * @type {Maybe<BrandFolderImage>}
	 * @memberof IFocalPointImage
	 */
	brandFolderImage?: Maybe<BrandFolderImage>;
	/**
	 * The focal point information for the image.
	 *
	 * @type {Maybe<FocalPointData>}
	 * @memberof IFocalPointImage
	 */
	focalPoint?: Maybe<FocalPointData>;
	/**
	 * Whether to display the image as a background image.
	 *
	 * @type {boolean}
	 * @memberof IFocalPointImage
	 * @todo rename this to isBackgroundImage
	 */
	backgroundImage?: boolean;
	/**
	 * Function called when the image has loaded.
	 *
	 * @memberof IFocalPointImage
	 */
	onLoad?: () => void;
	/**
	 * Optional styles to apply to the image.
	 *
	 * @type {SxProps}
	 * @memberof IFocalPointImage
	 */
	imageSx?: SxProps;
	/**
	 * Image element to be used to display the image. Defaults to `img`.
	 * This allows us to provide custom, framework specific, image wrapper, such as `next/image` or GatsbyImage.
	 *
	 * @type {unknown}
	 * @memberof IFocalPointImage
	 */
	imageElement?: unknown;
	/**
	 * Optional data-test-id attribute for testing purposes
	 *
	 * @type {string}
	 * @memberof IFocalPointImage
	 */
	dataTestId?: string;
}

// TODO: check if this is working the way we expect it to
export default function FocalPointImage({
	backgroundImage: isBackgroundImage,
	focalPoint,
	image,
	brandFolderImage,
	onLoad,
	imageSx,
	imageElement,
	dataTestId,
}: IFocalPointImage) {
	const imageToDisplay = brandFolderImage || image;
	const imageUrl = brandFolderImage ? brandFolderImage.cdn_url : image?.url;
	const imagePosition = resolveImagePosition(focalPoint, imageToDisplay);

	if (imageToDisplay) {
		const styles = isBackgroundImage
			? getBackgroundImageStyles(imageSx || {}, imagePosition, optimizeImageUrl(imageUrl) || '')
			: getImageStyles(imageSx || {}, imagePosition);
		const nextImageProps = resolveNextImageProps(!!isBackgroundImage, imageToDisplay, imageElement);

		return (
			<ImageWrapper
				style={styles}
				onLoad={onLoad}
				src={!isBackgroundImage ? imageUrl : undefined}
				alt={!isBackgroundImage ? imageToDisplay.description || '' : ''}
				imageEl={imageElement as React.ComponentProps<typeof ImageWrapper>['imageEl'] | undefined}
				data-test-id={
					dataTestId || `img_${isBackgroundImage ? 'background_' : ''}focal_point_${imageToDisplay.sys.id}`
				}
				{...nextImageProps}
			/>
		);
	}

	incorrectUsageWarning('FocalPointImage', "the 'image' prop is required.");

	return null;
}
