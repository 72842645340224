import { Location } from '@/rest/__generated/revspringApi';
import { logError, logWarning } from '@/utils/miscUtils';
import { httpClient } from '@/utils';

export const cachebust = 34572468;

export default async function getLocationDetails(params: {
	longitude: number;
	latitude: number;
	featureList?: Array<string>;
	environment?: string;
}): Promise<{
	revspringLocationValue: string;
	revspringAccountId: string;
	displayName: string;
	timezone: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	schedulingSystem: string | null;
	scheduleAppointmentURL: string | null;
}> {
	try {
		const { longitude, latitude, featureList, environment = 'prod' } = params;
		const reqObj = {
			url: `Location/v3`,
			params: {
				longitude,
				latitude,
				onlyOpen: true,
				recordsPerPage: 1,
				...(featureList && featureList.includes('onPageSchedulingPilotEnabled') ? { revSpringDemo: true } : {}),
				...(environment !== 'prod' ? { isQA: true } : {}),
			},
			requestName: 'getLocationDetails',
		};
		const [locationDetails] = await httpClient.request<Location[]>(reqObj);
		if (!locationDetails) {
			throw new Error('Could not find location details');
		}

		if (!locationDetails.schedulingSystem) {
			throw new Error('Could not find schedulingSystem in location details');
		}

		if (locationDetails.schedulingSystem === 'Revspring' && !locationDetails.revspringLocationValue) {
			throw new Error('Could not find revspringLocationValue');
		}

		return Promise.resolve({
			revspringLocationValue: locationDetails.revspringLocationValue ?? '',
			revspringAccountId: locationDetails.revspringAccountId ?? '',
			displayName: locationDetails.displayName ?? '',
			timezone: locationDetails.timezone ?? '',
			address: locationDetails.address ?? '',
			city: locationDetails.city ?? '',
			state: locationDetails.state ?? '',
			zip: locationDetails.zip ?? '',
			schedulingSystem: locationDetails.schedulingSystem,
			scheduleAppointmentURL: locationDetails.scheduleAppointmentURL ?? null,
		});
	} catch (err) {
		logWarning(`Could not find facility for lat: ${params.latitude}, long: ${params.longitude}.`);
		if (err instanceof Error) {
			logError(`REVSPRING_LOCATION_DETAIL_FAIL: ${err.message}`);
		}
		return Promise.resolve({
			revspringLocationValue: '',
			revspringAccountId: '',
			displayName: '',
			timezone: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			schedulingSystem: null,
			scheduleAppointmentURL: null,
		});
	}
}
