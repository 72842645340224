export const underlineStyles = (isDarkHeader?: boolean) => ({
	'&::after': {
		content: '""',
		display: 'block',
		height: '0.125rem',
		width: 0,
		backgroundColor: 'transparent',
		transition: 'width .3s ease, background-color .3s ease',
		position: 'relative',
		top: '-5px',
	},
	':hover::after': {
		width: '100%',
		backgroundColor: isDarkHeader ? 'text.light' : 'text.interactive',
	},
});

const navItemStyles = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	height: '3rem',
	lineHeight: '3rem',
	display: 'inline-block', // makes the link 100% height so that user doesn't have to click exactly on the text
	padding: '0 1rem',
	fontSize: '1rem',
};

const navItemStylesMobile = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	borderBottom: '1px solid',
	borderColor: 'neutrals.primary',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	height: '3.25rem',
	lineHeight: '3.25rem',
	width: '100%',
	textIndent: '1.25rem',
	'&:hover, &:active': {
		opacity: 0.8,
	},
};

const utilityNavItemMobileStyles = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	display: 'inline-block',
	height: '2rem',
	lineHeight: '3rem',
	width: '100%',
	textIndent: '1.25rem',
	'&:hover, &:active': {
		opacity: 0.8,
	},
};

interface IGetNavItemStyles {
	isSmallScreen?: boolean;
	isUtilityNavigation?: boolean;
	withIcon?: boolean;
	isDarkHeader?: boolean;
}

export const getNavItemStyles = ({ isSmallScreen, isUtilityNavigation, withIcon, isDarkHeader }: IGetNavItemStyles) => {
	if (withIcon) {
		return navItemStyles;
	}

	if (isUtilityNavigation) {
		return isSmallScreen ? utilityNavItemMobileStyles : { ...navItemStyles, ...underlineStyles(isDarkHeader) };
	}

	return isSmallScreen ? navItemStylesMobile : { ...navItemStyles, ...underlineStyles(isDarkHeader) };
};

export const getNavItemTypographyVariant = (isSmallScreen: boolean, isUtilityNavigation?: boolean) => {
	if (isUtilityNavigation) {
		return isSmallScreen ? 'bodySmallBook' : 'bodySmallSemiBold';
	}
	return 'bodyLargeSemiBold';
};

export const getNavItemWithIconStyles = (isSmallScreen: boolean) => {
	const navItemWithIconStyles = {
		...getNavItemStyles({ isSmallScreen, isUtilityNavigation: true }),
		paddingLeft: '0',
		paddingRight: '0.3125rem',
	};

	return navItemWithIconStyles;
};

export const dropMenuStyles = {
	'.MuiPaper-root': {
		backgroundColor: 'backgrounds.light',
		'.MuiList-root': {
			'.MuiMenuItem-root': {
				a: {
					width: '100%',
				},
			},
		},
	},
};

export const navGroupLinkStyles = {
	groupLinkStyles: {
		color: 'text.interactive',
		'&:last-of-type': {
			paddingRight: '0',
		},
	},
	groupLinkTitleStyles: {
		'&.MuiTypography-root': {
			':hover::after': {
				width: 'calc(100% - 0.375rem)',
			},
		},
	},
	groupLinkIconStyles: {
		verticalAlign: 'middle',
		mb: '0.2rem',
		ml: '0.2rem',
	},
	groupLinkIconMobileStyles: {
		mr: '0.75rem',
	},
};

export const mobileNavGroupStyles = {
	breadCrumbLinkStyles: {
		color: 'text.interactive',
		display: 'flex',
		alignItems: 'center',
		pb: '1.5rem',
		pl: '0.75rem',
		width: 'fit-content',
	},
	navGroupHeaderTitleStyles: {
		pl: '1rem',
		pb: '1.5rem',
	},
	menuItemStyles: {
		py: '0.75rem',
		borderColor: 'neutrals.primary',
		borderBottom: '1px solid',
		'&:first-of-type': {
			borderTop: '1px solid',
		},
		a: {
			width: '100%',
		},
	},
};
