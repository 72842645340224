import { strToSlug } from '@/utils';
import { STATE_CODE_BY_NAME, STATE_NAME_BY_CODE } from '@/constants';
import { TCity } from '@/types';

const addressZip = (address: string, zip?: string): string => `${address}${zip ? `-${zip}` : ''}`;
const cityAddressZip = (city: string, address?: string, zip?: string): string =>
	`${city}${address ? `/${addressZip(address, zip)}` : ''}`;

export interface CityAddressParams {
	address?: string;
	city?: string;
	zip?: string;
}

export interface UrlTemplateParams extends CityAddressParams {
	stateStr: string;
}

export interface OfficePathParams {
	stateStrOptions: TCity;
	cityAddressOptions?: CityAddressParams;
}

// determine the state string by brand
const getStateCode = (city: TCity): string =>
	city?.stateAbbreviation || STATE_CODE_BY_NAME[city?.location?.state || 'default'] || STATE_CODE_BY_NAME.default;

const stateStrLookups: Record<string, (city: TCity) => string> = {
	fallback: () => '_',
	aspendental: getStateCode,
	azPetVet: getStateCode,
	clearchoice: getStateCode,
	lovet: getStateCode,
	wellnow: (city) => STATE_NAME_BY_CODE[city.stateAbbreviation || ''] || city.state || STATE_NAME_BY_CODE.default,
};

export const stateStrByBrand = (brand: string, params: TCity): string =>
	(stateStrLookups?.[brand] || stateStrLookups.fallback)(params);

// determine the office URL template by brand
const defaultOfficeUrlTemplate = ({ stateStr, city, address }: UrlTemplateParams): string =>
	`${stateStr}${city ? `/${cityAddressZip(city, address)}` : ''}`;

const officeUrlTemplates: Record<string, (params: UrlTemplateParams) => string> = {
	fallback: () => '_',
	aspendental: defaultOfficeUrlTemplate,
	azPetVet: defaultOfficeUrlTemplate,
	clearchoice: defaultOfficeUrlTemplate,
	lovet: defaultOfficeUrlTemplate,
	wellnow: ({ stateStr, city, address, zip }) => `${stateStr}${city ? `/${cityAddressZip(city, address, zip)}` : ''}`,
};

export const officeURLByBrand = (brand: string, params: UrlTemplateParams) =>
	strToSlug((officeUrlTemplates?.[brand] || officeUrlTemplates.fallback)(params));

export const officePathByBrand = (brand: string, params: OfficePathParams) =>
	strToSlug(
		(officeUrlTemplates?.[brand] || officeUrlTemplates.fallback)({
			stateStr: stateStrByBrand(brand, params.stateStrOptions),
			...params.cityAddressOptions,
		})
	);
