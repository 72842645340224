// Regex for Formatting U.S. Phone Number
// Ref to: https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript#:~:text=Here%27s%20a%20version%20that%20allows%20the%20optional%20%2B1%20international%20code%3A

export function resolvePhoneNumberFormat(phoneNumberString: string) {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return ['(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}
