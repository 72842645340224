import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import TAGLink from '@/components/TAGLink';
import { useAppContext, useFacilityContext } from '@/context';
import { generateODPLinks } from '@/utils/generateODPLinks';

import { getNavItemStyles } from '../../NavigationBar/NavigationBarItem/NavigationBarItem.styles';

import { getLinkStyles, getNavMenuStyles } from './LocationNavMenu.styles';

interface IProps {
	isMobile?: boolean;
}

export default function LocationNavMenu({ isMobile }: IProps) {
	const { officeInfo } = useFacilityContext();
	const { config } = useAppContext();
	const locationLinks = useMemo(() => (officeInfo ? generateODPLinks(officeInfo, config) : []), [officeInfo, config]);

	if (!officeInfo) return null;

	return (
		<Box sx={getNavMenuStyles(isMobile)}>
			{locationLinks.map((item, index) =>
				item ? (
					<TAGLink
						href={item.path}
						key={`location-navigation-link-${index}`}
						linkSx={{ ...(!isMobile && getNavItemStyles()), ...getLinkStyles(isMobile) }}
					>
						<Typography
							variant="bodyMediumSemiBold"
							color="text.interactive"
							data-test-id={`link_location_${item.id}`}
						>
							{item.title}
						</Typography>
					</TAGLink>
				) : null
			)}
		</Box>
	);
}
