import { resolveEnv } from './resolveEnv';

export function logWarning(message: string) {
	const env = resolveEnv();

	if (env === 'prod') return null;

	console.warn(`⚠️⚠️⚠️ Shared Components Web - WARNING: ${message} ⚠️⚠️⚠️`);
}

export function logError(message: string) {
	const env = resolveEnv();

	if (env === 'prod') return null;

	console.error(`❌❌❌ Shared Components Web - ERROR: ${message} ❌❌❌`);
}

export function deprecationWarning(message: string) {
	const env = resolveEnv();

	if (env === 'prod') return null;

	console.warn(`⚠️⚠️⚠️ Shared Components Web - DEPRECATION WARNING: ${message} ⚠️⚠️⚠️`);
}

export function incorrectUsageWarning(componentName: string, message: string) {
	const env = resolveEnv();

	if (env === 'prod') return null;

	console.warn(`⚠️⚠️⚠️ Shared Components Web - Incorrect use of ${componentName} component: ${message} ⚠️⚠️⚠️`);
}
