import { Dialog, DialogActions, FormLabel, Grid, IconButton, RadioGroup, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import TAGSvgIcon from '@/components/TAGSvgIcon';

import LanguageChoiceButton from './LanguageChoiceButton';

export default function LanguageDialog({
	openLanguageDialog,
	setOpenLanguageDialog,
}: Readonly<{
	openLanguageDialog: boolean;
	setOpenLanguageDialog: (openFilterDialog: boolean) => void;
}>) {
	const router = useRouter();
	const { locale } = router;
	const enSelected = locale === 'en-US';
	const esSelected = locale === 'es-US';
	const languageString = esSelected ? 'Idioma' : 'Language';
	const englishLabelString = esSelected ? 'Inglés (English)' : 'English (Inglés)';
	const spanishLabelString = esSelected ? 'Español (Spanish)' : 'Spanish (Español)';

	const handleChange = (event: { target: { value: string } }) => {
		changeLanguage(event.target.value);
		setOpenLanguageDialog(false);
	};

	const changeLanguage = (locale: string) => {
		// manual overwrite for paid media AD scheduling, impossible to use next router like the normal path
		if (window.location.href.includes('schedule-an-appt')) {
			const hardCodedEndpoint =
				window.location.origin + '/' + `schedule-an-appt/${locale}/` + window.location.search;
			window.location.href = hardCodedEndpoint;
		} else {
			void router.push(router.pathname, router.asPath, { locale });
		}
	};

	return (
		<Dialog
			open={openLanguageDialog}
			onClose={() => {
				setOpenLanguageDialog(false);
			}}
			aria-labelledby="alert-dialog-title"
			maxWidth="lg"
		>
			<Stack alignItems="end" pr={1.2} pt={1.2}>
				<IconButton
					onClick={() => {
						setOpenLanguageDialog(false);
					}}
				>
					<TAGSvgIcon icon="Times" size={22} />
				</IconButton>
			</Stack>

			<Stack alignItems="center" pb={1.6}>
				<Stack pb={2}>
					<TAGSvgIcon icon="Language" size={40} color="#004BB9" />
				</Stack>
				<FormLabel id="select-language">
					<Typography color="primary" variant="header3">
						{languageString}
					</Typography>
				</FormLabel>
			</Stack>

			<DialogActions>
				<RadioGroup aria-labelledby="select-language" onChange={handleChange} value={locale}>
					<Grid
						container
						justifyContent="left"
						sx={{ maxWidth: '50rem' }}
						spacing={1.4}
						pt={0}
						px={4}
						pr={28.3}
						pb={4}
					>
						<Grid item key={'en-US'} xs={6} md={6}>
							<LanguageChoiceButton
								value="en-US"
								icon="FlagUS"
								label={englishLabelString}
								selected={enSelected}
							/>
						</Grid>
						<Grid item key={'es-US'} xs={6} md={6}>
							<LanguageChoiceButton
								value="es-US"
								icon="FlagMX"
								label={spanishLabelString}
								selected={esSelected}
							/>
						</Grid>
					</Grid>
				</RadioGroup>
			</DialogActions>
		</Dialog>
	);
}
