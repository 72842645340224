import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormControlLabel, Radio, Typography, useMediaQuery, useTheme } from '@mui/material';

import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';

import { checkIconStyles, formControlLabelStyles, radioStyles } from './LanguageChoiceButton.styles';

interface ReasonForVisitButtonProps {
	label: string;
	icon: IconName;
	value: string;
	selected: boolean;
}

export default function ReasonForVisitButton({ value, icon, label, selected }: Readonly<ReasonForVisitButtonProps>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const fontVariant = isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook';

	return (
		<FormControlLabel
			data-test-id={label}
			sx={formControlLabelStyles(selected)}
			control={
				<>
					{selected ? <CheckCircleIcon sx={checkIconStyles} data-test-id="icon_selected" /> : null}
					<Radio
						checkedIcon={<Icon icon={icon} />}
						icon={<Icon icon={icon} />}
						value={value}
						disableTouchRipple
						sx={radioStyles}
					></Radio>
				</>
			}
			label={
				<Typography align="center" color="secondary.main" variant={fontVariant}>
					{label}
				</Typography>
			}
		/>
	);
}

const Icon = ({ icon }: { icon: IconName }) => <TAGSvgIcon icon={icon} size={40} />;
