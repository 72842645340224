const validStr = (val: string | undefined | boolean): boolean => typeof val === 'string';

export const joinValidStrs = (glue: string, arr: (string | undefined | boolean)[]): string =>
	arr.filter(validStr).join(glue);

export const strToSlug = (str = '') =>
	str
		.trim()
		.toLowerCase()
		.replace(/['.#,"\u200B-\u200D\uFEFF]/g, '')
		.replace(/[ ]/g, '-');

export const strListToPath = (strList: string[] = ['']) => strToSlug(strList.join('/'));
