import { Theme } from '@mui/material';

import { ContentfulBackground } from '@/types';

export function translateTagColorNameForContentful(color: string): string {
	interface IColorList {
		[key: string]: ContentfulBackground;
	}
	const colorList: IColorList = {
		dark: 'Dark',
		light: 'Light',
		white: 'White',
		neutral: 'Neutral',
		darkGradient: 'Dark Gradient',
		neutralGradient: 'Neutral Gradient',
		darkTwo: 'Dark Two',
		lightGradient: 'Light Gradient',
		lightThree: 'Light Three',
		lightThreeGradient: 'Light Three Gradient',
		lightTwo: 'Light Two',
		lightTwoGradient: 'Light Two Gradient',
		transparent: 'Transparent',
	};
	return colorList[color] || color;
}

export function resolveContentfulBgColor(backgroundColor: ContentfulBackground, theme: Theme, defaultBgColor?: string) {
	switch (backgroundColor) {
		case 'White':
			return `${theme.palette.backgrounds?.white}`;
		case 'Dark':
			return `${theme.palette.backgrounds?.dark}`;
		case 'Dark Two':
			return `${theme.palette.backgrounds?.darkTwo || theme.palette.backgrounds?.dark}`;
		case 'Neutral':
			return `${theme.palette.backgrounds?.neutral}`;
		case 'Neutral Gradient':
			return `${theme.palette.backgrounds?.neutralGradient}`;
		case 'Dark Gradient':
			return `${theme.palette.backgrounds?.darkGradient}`;
		case 'Light':
			return `${theme.palette.backgrounds?.light}`;
		case 'Light Two':
			return `${theme.palette.backgrounds?.lightTwo || theme.palette.backgrounds?.light}`;
		case 'Light Three':
			return `${theme.palette.backgrounds?.lightThree || theme.palette.backgrounds?.light}`;
		case 'Light Gradient':
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			return `${theme.palette.backgrounds?.lightGradient}`;
		case 'Light Two Gradient':
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			return `${theme.palette.backgrounds?.lightTwoGradient || theme.palette.backgrounds?.lightGradient}`;
		case 'Light Three Gradient':
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			return `${theme.palette.backgrounds?.lightThreeGradient || theme.palette.backgrounds?.lightGradient}`;
		case 'Eucalyptus':
			return `${theme.palette.accent02?.light}`;
		case 'Rose':
			return `${theme.palette.accent02?.main}`;
		case 'Saffron':
			return `${theme.palette.accent02?.dark}`;
		case 'Transparent':
			return `${theme.palette.backgrounds?.transparent}`;
		case 'Accent Two Lightest':
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			return `${theme.palette.accent02?.lightest}`;
		default:
			return defaultBgColor || `${theme.palette.backgrounds?.white}`;
	}
}
