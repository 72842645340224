import { ButtonProps } from '@mui/material';

import { ContentfulButtonVariant } from '../types';

export function resolveContentfulButtonVariant(
	buttonVariant: ContentfulButtonVariant,
	defaultVariant?: ButtonProps['variant']
): ButtonProps['variant'] {
	switch (buttonVariant) {
		case 'Primary Default':
			return 'primaryDefault';
		case 'Primary High Contrast':
			return 'primaryHC';
		case 'Primary Accent01':
			return 'primaryAccent1';
		case 'Secondary Default':
			return 'secondaryDefault';
		case 'Secondary High Contrast':
			return 'secondaryHC';
		case 'Tertiary Default':
			return 'tertiaryDefault';
		case 'Tertiary High Contrast':
			return 'tertiaryHC';
		default:
			return defaultVariant || 'primaryDefault';
	}
}
