import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';

import { TAGBrand } from '@/types';

import TAGSvgIcon from '../TAGSvgIcon/TAGSvgIcon';

import { headerWrapperStyles, svgItemWrapperStyles } from './Chatbot.styles';

interface IChatbotHeader {
	handleClose: () => void;
	logoUrl?: string;
}

const ChatbotHeader = ({ handleClose, logoUrl = '' }: IChatbotHeader) => {
	const theme = useTheme();
	const { brand } = theme;

	const getLogoAltText = (brand: TAGBrand) => {
		switch (brand) {
			case TAGBrand.AspenDental:
				return 'Aspen Dental Logo';
			case TAGBrand.AZPetVet:
				return 'AZPetVet Logo';
			case TAGBrand.WellNow:
			case TAGBrand.YouWellNow:
			case TAGBrand.LivWellNow:
				return 'WellNow Logo';
			case TAGBrand.Lovet:
				return 'Lovet Logo';
			case TAGBrand.Chapter:
				return 'Chapter Logo';
			case TAGBrand.ClearChoice:
				return 'ClearChoice Logo';
			case TAGBrand.Motto:
				return 'Motto Logo';
			case TAGBrand.TeamTAG:
				return 'TeamTag Logo';
			default:
				return 'Logo';
		}
	};

	const logoAltText = getLogoAltText(brand as TAGBrand);

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			direction="row"
			spacing={1}
			data-test-id="chatbot_header"
			sx={headerWrapperStyles}
		>
			<Grid item sx={svgItemWrapperStyles(theme, brand as TAGBrand)}>
				<TAGSvgIcon
					icon={brand !== TAGBrand.AspenDental ? 'ChatBubbleOutline' : 'AspenChatLogoWhite'}
					dataTestId="chatbot_header_logo"
				/>
			</Grid>
			<Grid item>
				{logoUrl && brand !== TAGBrand.AspenDental ? (
					<img src={logoUrl} alt={logoAltText} style={{ width: '132px', height: 'auto' }} />
				) : (
					<Typography variant="header4" data-test-id="chatbot_header_title">
						Amelia Virtual Assistant
					</Typography>
				)}
			</Grid>
			<Grid item>
				<IconButton onClick={handleClose}>
					<CloseIcon data-test-id="chatbot_header_Close_icon" />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default ChatbotHeader;
