export function pixelsToRemStr(pxValue: string): string {
	const match = pxValue.match(/^(\d*\.?\d+)(px)?$/);

	if (pxValue === 'none') {
		return 'none';
	}

	if (!match) {
		return '75rem';
	}

	const numericValue = parseFloat(match[1]);
	const remValue = numericValue / 16;

	return `${remValue}rem`;
}
