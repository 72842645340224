export const getWrapperStyles = (color: string, fieldError: boolean) => {
	// default fallback
	const transformedColor = color.includes('.') ? color : `${color}.light`;

	return {
		'& label.Mui-focused': {
			color: fieldError ? 'error.dark' : transformedColor,
		},
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: transformedColor,
			},
		},
		'& .Mui-error': {
			'&.Mui-focused fieldset': {
				borderColor: 'error.dark',
			},
		},
	};
};

export const getInputStyles = () => ({
	'&.Mui-error': {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'error.dark',
		},
	},
	paddingLeft: '0.5rem',
});

export const inputHelperTextStyles = {
	marginLeft: 0,
	'&.Mui-error': {
		color: 'error.dark',
	},
};
