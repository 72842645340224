import { SxProps } from '@mui/material';

export const contentWrapperStyles = (backgroundColor: string, containerSx: SxProps) => ({
	maxWidth: { md: '120rem' },
	margin: '0 auto',
	backgroundColor: `${backgroundColor || 'white'}`,
	...containerSx,
});

export const getButtonsWrapperStyles = (isContentCenterAligned?: boolean | null) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: { xs: '0.5rem', md: '1rem' },
	padding: {
		xs: isContentCenterAligned ? '1rem 0.375rem 0.75rem' : '1rem 0.375rem 0.75rem 0',
		md: '1rem 0 1.25rem 0',
	},
	margin: { xs: '0 auto', md: 0 },
	width: isContentCenterAligned ? null : '100%',
	maxWidth: isContentCenterAligned ? null : '70rem',
	justifyContent: isContentCenterAligned ? 'center' : 'flex-start',
});
