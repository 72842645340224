import { Theme } from '@mui/material';

export const getDialogWrapperStyles = {
	'.MuiDialog-paper': {
		height: '47.5rem',
		form: {
			display: 'contents',
		},
	},
	'.MuiDialogContent-root': {
		padding: '3rem 3.75rem',
	},
};

export const getDrawerWrapperStyles = {
	'&.MuiDrawer-root': {
		zIndex: 12_000,
	},
	'.MuiDrawer-paper': {
		minHeight: '85%',
		form: {
			display: 'contents',
		},
	},
};

export const getFormLabelBoxWrapper = (theme: Theme, isOptionSelected: boolean) => {
	return {
		width: '100%',
		border: isOptionSelected ? '0.145rem solid' : '0.125rem solid',
		borderRadius: '0.5rem',
		borderColor: isOptionSelected ? theme.palette.text.interactive : theme.palette.neutrals?.primary,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '1rem',
		position: 'relative',
	};
};

export const getFormControlLabelStyles = {
	display: 'flex',
	width: '100%',
	margin: 0,
};

export const getFormGroupStyles = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '0.938rem',
};

export const getSelectedOptionIconStyles = {
	height: '1.25rem',
	width: '1.25rem',
	position: 'absolute',
	color: 'secondary.main',
	top: '0.3rem',
	right: '0.3rem',
};

export const dialogActionsStyles = {
	backgroundColor: 'backgrounds.neutral',
};

export const quizInPageWrapperStyles = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	justifyContent: 'center',
	alignItems: 'self-end',
	minHeight: 'calc(100vh - 12.5rem)',
	gap: '1rem',
	padding: { xs: '0 5% 1.5rem 5%', md: '0 25% 1.5rem 25%' },
	form: {
		width: '100%',
	},
};
