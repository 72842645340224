import { RefObject } from 'react';

import { Menu, PopoverProps } from '@mui/material';

import { menuStyles } from './MobileMenu.styles';

interface IMobileMenu {
	open: PopoverProps['open'];
	onClose: PopoverProps['onClose'];
	children: React.ReactNode;
	appBarRef?: RefObject<HTMLDivElement>;
}

export default function MobileMenu({ open, onClose, children, appBarRef }: IMobileMenu) {
	return (
		<Menu
			open={open}
			anchorEl={appBarRef?.current}
			onClose={onClose}
			marginThreshold={0}
			transitionDuration={1}
			sx={menuStyles}
		>
			{children}
		</Menu>
	);
}
