interface DeviceSize {
	isMobile: boolean;
}

const getWrapperVerticalPadding = () => {
	return '2.5rem';
};

const getWrapperHorizontalPadding = (deviceSize: DeviceSize) => {
	if (deviceSize.isMobile) return '2rem';
	return '5%';
};
export const getFooterStyles = {
	width: '100%',
	backgroundColor: 'backgrounds.darkTwo',
};
export const getWrapperStyles = (deviceSize: DeviceSize) => {
	const verticalPadding = getWrapperVerticalPadding();
	const horizontalPadding = getWrapperHorizontalPadding(deviceSize);

	return {
		display: 'flex',
		flexFlow: deviceSize.isMobile ? 'column' : 'row no-wrapper',
		justifyContent: 'space-between',
		color: 'neutrals.white',
		margin: '0 auto',
		padding: [verticalPadding, horizontalPadding].join(' '),
	};
};

export const getGroupWrapperStyles = (isMobile: boolean) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: isMobile ? 'wrap' : 'no-wrap',
	rowGap: '2rem',
	columnGap: '2rem',
	justifyContent: 'space-between',
});

export const getGroupItemStyles = (isMobile: boolean) => ({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	borderLeft: isMobile ? '' : '1px solid white',
	paddingLeft: isMobile ? '' : '1.5rem',
	paddingRight: isMobile ? '' : '1.5rem',
	gap: isMobile ? '1rem' : '',

	'&:last-child': {
		paddingRight: '0',
		flex: isMobile ? '1 0 100%' : '0 1 33%',
		alignItems: isMobile ? 'center' : '',
		gap: isMobile ? '1rem' : '',
	},
});

export const getNavLinkStyles = () => ({
	justifyContent: 'flex-start',
	lineHeight: '2rem',
	fontWeight: 600,
	fontSize: '1rem',
});

export const getNavTitleStyles = () => ({
	fontSize: '1.13rem',
});

export const getFooterConnectBlockStyles = (isMobile: boolean) => ({
	display: isMobile ? 'flex' : 'block',
	flexFlow: 'row nowrap',
	alignItems: isMobile ? 'center' : '',
	width: '100%',
	justifyContent: 'center',
	gap: '2rem',
});

export const getSocialImageStyle = {
	width: '1.25rem',
	height: '1.25rem',
};

export const getPolicyGroupStyle = (isMobile: boolean) => ({
	display: 'flex',
	flexFlow: isMobile ? 'row no-wrap' : 'column',
	alignItems: isMobile ? 'center' : '',
	gap: isMobile ? '3.5rem' : '',
});
