import { FacilityFieldsFragment, NavigationLink, Page } from '@/graphql/__generated/sdk';

import { generateFacilityUrl } from '../generateFacilityUrl';

export function generateLocationLinks(
	office: FacilityFieldsFragment,
	locationNavigationLinks: Array<NavigationLink | Page>
) {
	const facilityUrl = generateFacilityUrl(
		office?.address?.stateCode,
		office?.address?.city,
		office?.address?.address1
	);

	const linkUrl = globalThis.location?.origin && `${globalThis.location.origin}/${facilityUrl}`;

	const linkArray: { id: number; path: string; title: string }[] = locationNavigationLinks.map((item, index) => {
		if (item.__typename === 'NavigationLink') {
			if (item.isInternal) {
				return {
					id: index,
					path: `${linkUrl}${item?.pageData?.path || '#'}`,
					title: item?.linkText || '#',
				};
			}
			return {
				id: index,
				path: item.externalUrl || '#',
				title: item.linkText || '#',
			};
		}

		if (item.__typename === 'Page') {
			return {
				id: index,
				path: `${linkUrl}${item?.path || '#'}`,
				title: item?.title || '#',
			};
		}

		return {
			id: index,
			path: '#',
			title: '#',
		};
	});

	return linkArray;
}
