import { Theme } from '@mui/material';
import { resolveTypographyVariantStyles } from '@aspendental/themes';

export const desktopMainNavLinkGridStyle = (theme: Theme) => ({
	height: '3rem',
	'&:hover': {
		borderBottom: `.25rem solid ${theme.palette.text.primary}`,
	},
	display: 'block',
	position: 'relative',
});

export const getMainNavStyles = (theme: Theme) => ({
	...resolveTypographyVariantStyles(theme, 'mainNavigationDefault'),
	display: 'inline-block',
	lineHeight: '2.875rem',
	textDecoration: 'none',
});

export const headerSubNavMenuGridContainerStyle = (theme: Theme) => ({
	[theme.breakpoints.up('lg')]: {
		flexDirection: 'row',
		flexFlow: 'row',
	},
	[theme.breakpoints.between('md', 'lg')]: {
		flexDirection: 'column',
		flexFlow: 'column',
	},
});

export const headerSubNavMenuContainerStyle = (theme: Theme, subNavMenuAbsolutePosition: string) => ({
	[theme.breakpoints.up('md')]: {
		width: 'max-content',
		minWidth: '12.5rem',
		padding: '1.25rem 0 2.5rem 0',
		boxShadow: '0 0 .625rem 0 lightgray',
		position: 'absolute',
		zIndex: '50',
		...(subNavMenuAbsolutePosition === 'center' && { left: '50%', transform: 'translate(-50%,0)' }),
		...(subNavMenuAbsolutePosition === 'right' && { right: 0 }),
		...(subNavMenuAbsolutePosition === 'left' && { left: 0 }),
	},
	[theme.breakpoints.down('md')]: {
		padding: '0rem',
	},
	backgroundColor: 'backgrounds.white',
	transition: 'all 2s 2s ease',
});

export const desktopHeaderSubNavMenuContentBoxStyle = {
	display: 'flex',
	width: '100%',
	height: '100%',
};

export const desktopHeaderSubNavMenuLinksBoxStyle = (theme: Theme) => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'wrap',
	justifyContent: 'flex-start',
	width: '100%',
	gap: '1.25rem',
	paddingX: '1.5rem',
	minWidth: '11.75rem',
	[theme.breakpoints.up('md')]: {
		maxHeight: '32.25rem',
	},
});

export const navMenuContainerStyles = (isSmallScreen: boolean) => ({
	...(isSmallScreen && { borderTop: '1px solid', borderColor: 'neutrals.primary' }),
	...(!isSmallScreen && { padding: '0 5%' }),
	flexGrow: 0,
	height: '100%',
});

export const mobileMainNavLinkStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	height: '3.25rem',
	lineHeight: '3.25rem',
	width: '100%',
	textDecoration: 'none',
};

export const mobileMainNavLinkArrowStyle = {
	height: '1rem',
	width: '1rem',
	marginRight: '1.25rem',
	alignSelf: 'center',
};

export const mobileMainNavLinkGridStyle = {
	borderBottom: '1px solid',
	borderColor: 'neutrals.primary',
	paddingLeft: '1.25rem',
};

export const mobileHeaderSubNavMenuGridContainerStyle = {
	marginTop: '0rem',
	borderTop: '1px solid',
	borderColor: 'neutrals.primary',
};
export const mobileHeaderSubNavMenuGridItemStyle = { pt: '0rem !important' };
export const mobileHeaderSubNavMenuAccordionStyle = { borderBottom: `1px solid`, borderColor: 'neutrals.primary' };
export const mobileHeaderSubNavMenuAccordionSummaryStyle = {
	'&.MuiAccordionSummary-root': {
		flexDirection: 'row',
		paddingLeft: '.75rem',
		marginY: '.25rem',
	},
};
export const mobileHeaderSubNavMenuAccordionSummaryIconStyle = { color: 'text.primary', height: '1rem', width: '1rem' };
export const mobileHeaderSubNavMenuAccordionSummaryTxtStyle = {
	justifyContent: 'flex-start',
	lineHeight: '1.5rem',
};
export const mobileHeaderSubNavMenuAccordionDetailsStyle = {
	padding: '0rem 0rem 1.25rem 1rem',
};
export const mobileHeaderSubNavMenuTitleStyle = {
	marginTop: '1rem',
	marginBottom: '2rem',
};

export const mobileHeaderSubNavMenuAccordionLinksGridStyle = {
	'&.MuiGrid-item': {
		paddingLeft: '1.25rem',
	},
};

export const subNavMenuLinkStyle = {
	textDecoration: 'none',
};
