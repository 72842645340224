import { logError, logWarning } from '@/utils/miscUtils';
import { httpClient } from '@/utils';
import { APIFormDataAD, APIFormDataCC } from '@/components/Quiz/Quiz.helpers';

export default async function postQuiz(
	url: string,
	headers: { [key: string]: string } = {},
	formData: APIFormDataAD | APIFormDataCC
) {
	try {
		const response = await httpClient.request({
			url: url,
			requestName: 'postQuizData',
			method: 'POST',
			data: formData,
			headers: headers,
		});
		return response;
	} catch (err) {
		logWarning(`Could not post quiz to userEvents api`);
		if (err instanceof Error) {
			logError(`USER_EVENTS_URL: ${err.message}`);
		}
	}
}
