import { BffWorkingHours } from '@/types/generated';
import { DAYS_OF_WEEK, DAYS_OF_WEEK_SHORT_HAND } from '@/constants';

export const getWorkingHoursFromBff = (workingHours: BffWorkingHours[], isFullName?: boolean) => {
	const hours = workingHours.reduce((acc, cur) => {
		const day = cur.date ?? '';

		if (cur.open?.from && cur.open?.to) {
			return {
				...acc,
				[day]: cur.open.from !== null ? `${cur.open.from} - ${cur.open.to}` : 'Closed',
			};
		}
		return {
			...acc,
			[day]: 'Closed',
		};
	}, {} as Record<string, string>);

	const fullWeekHours = DAYS_OF_WEEK.map((fullDayName) => {
		const day = isFullName ? fullDayName : DAYS_OF_WEEK_SHORT_HAND[fullDayName];
		if (!hours[fullDayName]) {
			return {
				day,
				hours: 'Closed',
			};
		}
		return {
			day,
			hours: hours[fullDayName],
		};
	});
	return fullWeekHours;
};
