import { useEffect, useState } from 'react';

import { Box, Dialog, SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material';

import { useAppContext } from '@/context';
import { Maybe, Scalars } from '@/types/generated';

import { CloseButton } from '../TAGDialog/TAGDialog';

import { getDialogWrapperStyles, getDrawerWrapperStyles, quizInPageWrapperStyles } from './Quiz.styles';
import { IStateObject, quizGA4DataLayerPush, TFormData } from './Quiz.helpers';
import Questions from './Questions/Questions';
import UserDetailsForm from './UserDetailsForm/UserDetailsForm';

interface IQuiz {
	/**
	 * JSON Form data from contentful
	 */
	quizFormData: Maybe<Scalars['JSON']>;
	/**
	 * Quiz Submit endpoint url from contentful
	 */
	submitEndpointUrl: string;
	/**
	 * If <b>true</b>, the component is shown.
	 * @type {boolean}
	 *
	 */
	showInDialogOrDrawer?: boolean;
	/**
	 * If <b>true</b>, the component is shown.
	 * @type {boolean}
	 *
	 */
	openQuiz?: boolean;
	/**
	 * Callback to fire when the component needs to be closed.
	 */
	handleCloseQuiz?: () => void;
}

export default function Quiz({
	quizFormData,
	submitEndpointUrl,
	openQuiz,
	handleCloseQuiz,
	showInDialogOrDrawer = false,
}: Readonly<IQuiz>) {
	const [state, setState] = useState<IStateObject>({
		activeQuestionIndex: 0,
		formData: null,
		formType: 'Questions',
		fieldHasError: false,
	});

	const theme = useTheme();
	const { config } = useAppContext();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const brandName = config.name;

	const { formType, formData } = state;

	useEffect(() => {
		if (brandName) {
			updateState({
				formData: quizFormData as TFormData,
				formType: 'Questions',
				activeQuestionIndex: 0,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brandName]);

	const updateState = (newState: Partial<IStateObject>) => {
		setState({
			...state,
			...newState,
		});
	};

	const handleClose = () => {
		updateState({
			formData: quizFormData as TFormData,
			formType: 'Questions',
			activeQuestionIndex: 0,
		});
		quizGA4DataLayerPush(config.name, 'cancel', 'N/A', formData as TFormData);
		if (handleCloseQuiz) {
			handleCloseQuiz();
		}
	};

	const ContentWrapper = () => {
		return (
			<>
				{showInDialogOrDrawer && <CloseButton onClose={handleClose} />}
				{/* Render Questions initially  */}
				{formType === 'Questions' && (
					<Questions state={state} setState={updateState} showInDialogOrDrawer={showInDialogOrDrawer} />
				)}
				{/* Render user details form */}
				{formType === 'UserDetails' && (
					<UserDetailsForm
						state={state}
						setState={updateState}
						submitEndpointUrl={submitEndpointUrl}
						showInDialogOrDrawer={showInDialogOrDrawer}
					/>
				)}
			</>
		);
	};

	if ((showInDialogOrDrawer && !openQuiz) || !formData) {
		return null;
	}

	if (!showInDialogOrDrawer) {
		return (
			<Box data-test-id="quiz_in_page" sx={quizInPageWrapperStyles}>
				<ContentWrapper />
			</Box>
		);
	}

	if (isSmallScreen) {
		return (
			<SwipeableDrawer
				open
				onClose={() => null}
				onOpen={() => null}
				anchor="bottom"
				sx={getDrawerWrapperStyles}
				data-test-id={`${formType}_drawer`}
			>
				<ContentWrapper />
			</SwipeableDrawer>
		);
	}

	return (
		<Dialog open sx={getDialogWrapperStyles} fullWidth maxWidth="lg" data-test-id={`${formType}_dialog`}>
			<ContentWrapper />
		</Dialog>
	);
}
