import { videoControlsBottomStyles } from './VideoContent.helpers';

export const getVideoStyles = (useOriginalVideoSize: boolean | null | undefined): React.CSSProperties => {
	return {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 auto',
		...(useOriginalVideoSize
			? { height: 'auto', width: 'auto' }
			: {
					position: 'relative',
					objectFit: 'cover',
					objectPosition: 'center',
					top: 0,
					left: 0,
					height: '100%',
					width: '100%',
			  }),
	};
};

export const videoControlsWrapperStyles = (fromHero: boolean, isExtraLargeScreen: boolean, isWideScreen: boolean) => ({
	display: 'flex',
	flexDirection: 'row',
	gap: '0.75rem',
	position: 'absolute',
	zIndex: 10,
	pointerEvents: 'auto',
	lineHeight: 0,
	bottom: videoControlsBottomStyles(fromHero, isWideScreen, isExtraLargeScreen),
	left: '1.25rem',
});

export const richTextVideoControlsWrapperStyles = (isExtraSmallScreen: boolean) => {
	return {
		position: 'relative',
		bottom: '2.75rem',
		...(isExtraSmallScreen && { marginLeft: '1rem' }),
	};
};

export const videoControlIconStyles = {
	cursor: 'pointer',
	fontSize: '1.7rem',
	color: 'text.light',
};

export const youtubeVideoStyles = (richTextStylePosition: string | undefined) => {
	return { position: richTextStylePosition ? richTextStylePosition : 'static' };
};
