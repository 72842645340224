import { aspenDentalConsts, clearChoiceConsts } from '@/constants';

interface IClickContext {
	brand?: string;
	text?: string;
	type?: string;
}

export const clickContext = ({ brand, type, text }: IClickContext) => {
	if (brand === 'ClearChoice') {
		return {
			...clearChoiceConsts.context,
			...clearChoiceConsts[type as keyof typeof clearChoiceConsts],
			...(text ? { event: 'clicked_a_button_or_link', clickName: text } : {}),
		};
	}

	if (brand === 'Aspen Dental' && type === 'scheduleClick') {
		const isSpanishDirectory = globalThis?.location?.pathname?.includes('espanol');
		return {
			...aspenDentalConsts.context,
			...aspenDentalConsts[type as keyof typeof aspenDentalConsts],
			page_url: globalThis?.location?.pathname || '',
			page_name: globalThis?.document?.title || '',
			brand: brand.replace(/\s/g, '').toLowerCase(),
			link_text: isSpanishDirectory ? 'Haz una cita' : 'Schedule Appointment',
		};
	}

	return null;
};
