import { useEffect } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import { App, Maybe, NavigationLink, Page } from '@/types/generated';
import { dataLayerConfig } from '@/utils/dataLayerConfig';
import { useAppContext, useFacilityContext } from '@/context';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import { getHeaderWrapperStyles } from './Header.styles';

export interface IHeader {
	/**
	 * Application data, containg all the information about the application
	 *
	 * @type {App}
	 * @memberof IHeader
	 */
	appData: App;
	/**
	 * Specifies whether the applicaiton is being rendered serverside or not.
	 *
	 * @type {boolean}
	 * @memberof IHeader
	 */
	usesRehydration: boolean;
	/**
	 * Determines if the location cards will be used in the header or not
	 *
	 * @type {boolean}
	 * @memberof IHeader
	 */
	useHeaderLocationCards: boolean | null | undefined;
	/**
	 * Whether to show (and fetch) the office details in the header or not.
	 *
	 * @type {boolean}
	 * @memberof IHeader
	 */
	withOfficeDetails?: boolean;
	/**
	 * Whether to show the ODP links block in the header or not.
	 *
	 * @type {boolean}
	 * @memberof IHeader
	 */
	inlineNavigation?: boolean;
	topLinks?: boolean;
	logoPosition?: 'left' | 'center';
	navigationBar?: boolean;
	isFullScreenMenu?: boolean;
	withMenuCTA?: boolean;
	logo?: {
		dark: string;
		light: string;
	};
	hidePrimaryActionButton?: boolean;
	hideLocation?: boolean;
	showHeaderNavMenu?: boolean;
	logoHref?: string;
	locationNavigationLinks?: Array<NavigationLink | Page> | undefined;
	phoneNumber?: Maybe<NavigationLink>;
	showLanguageToggle?: boolean;
	/**
	 * Fixed Header
	 *
	 * when it is true, header won't disappear when scrolling down
	 * NOTE:
	 * Sticky header and Fixed header in most cases are the same.
	 * but sticky header is the one that disappears when scrolling down
	 * and reappears when scrolling up in our case today
	 *
	 * Default is false
	 *
	 * @type {boolean}
	 * @memberof IHeader
	 */
	withFixedHeader?: boolean;
}

export default function Header({
	appData,
	usesRehydration,
	withOfficeDetails,
	inlineNavigation,
	topLinks,
	logoPosition,
	navigationBar,
	isFullScreenMenu,
	withMenuCTA,
	logo,
	hidePrimaryActionButton = false,
	hideLocation = false,
	showHeaderNavMenu = false,
	logoHref = '/',
	locationNavigationLinks,
	phoneNumber,
	withFixedHeader = false,
	showLanguageToggle,
	useHeaderLocationCards = false,
}: IHeader) {
	const theme = useTheme();
	const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'), { noSsr: !usesRehydration });
	const logoUrl = appData?.header?.darkHeader ? logo?.light : logo?.dark;
	const { officeInfo } = useFacilityContext();
	const { config } = useAppContext();
	const showMobileHeaderLocationBar = config?.featureFlags?.showMobileHeaderLocationBar;
	const showHeaderWithLocationNav = config.featureFlags.showHeaderWithLocationNav;

	useEffect(() => {
		if (officeInfo?.code) {
			dataLayerConfig({
				event: 'facility_set',
				brand: config.name,
				website_selected_facility_id: officeInfo?.code,
				website_selected_facility_name: officeInfo?.name,
			});
		}
	}, [officeInfo?.code, config.name, officeInfo?.name]);

	return (
		<Box
			data-test-id="section_header"
			sx={getHeaderWrapperStyles(showMobileHeaderLocationBar, showHeaderNavMenu, showHeaderWithLocationNav)}
		>
			{isLargeDevice ? (
				<HeaderDesktop
					withOfficeDetails={withOfficeDetails}
					headerData={appData.header}
					logoUrl={logoUrl || appData.logo?.url}
					navigationBar={navigationBar}
					inlineNavigation={inlineNavigation}
					topLinks={topLinks}
					logoPosition={logoPosition}
					isDarkHeader={appData.header?.darkHeader || false}
					hidePrimaryActionButton={hidePrimaryActionButton}
					hideLocation={hideLocation}
					showHeaderNavMenu={showHeaderNavMenu}
					logoHref={logoHref}
					locationNavigationLinks={locationNavigationLinks}
					phoneNumber={phoneNumber}
					withFixedHeader={withFixedHeader}
					showLanguageToggle={showLanguageToggle}
					useHeaderLocationCards={useHeaderLocationCards}
				/>
			) : (
				<HeaderMobile
					withOfficeDetails={withOfficeDetails}
					headerData={appData.header}
					logoUrl={appData.logoMobile?.url || appData.logo?.url}
					isFullScreenMenu={isFullScreenMenu}
					withMenuCTA={withMenuCTA}
					hidePrimaryActionButton={hidePrimaryActionButton}
					hideLocation={hideLocation}
					showHeaderNavMenu={showHeaderNavMenu}
					logoHref={logoHref}
					locationNavigationLinks={locationNavigationLinks}
					phoneNumber={phoneNumber}
					withFixedHeader={withFixedHeader}
					showMobileHeaderLocationBar={showMobileHeaderLocationBar}
					showLanguageToggle={showLanguageToggle}
				/>
			)}
		</Box>
	);
}
