import { TemplateWidth } from '@/types';

export function resolveContentfulTemplateWidth(templateWidth: TemplateWidth, defaultWidth?: string) {
	switch (templateWidth) {
		case 'Full':
			return '100%';
		case 'Inset':
			return '90%';
		default:
			return defaultWidth || '100%';
	}
}
