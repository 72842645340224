import { Box, SxProps } from '@mui/material';

import { Header } from '@/types/generated';

import NavigationBarItem from './NavigationBarItem';

interface INavigationBar {
	navigationItems: Header['navigationLinksCollection'] | Header['secondaryNavigationLinksCollection'];
	navigationSx?: SxProps;
	isNavLinkSemibold?: boolean;
}

export default function NavigationBar({ navigationItems, navigationSx, isNavLinkSemibold }: INavigationBar) {
	return (
		<Box data-test-id="header_with_location_nav_main_navigation_bar" sx={navigationSx}>
			{navigationItems?.items?.map((item, index) =>
				item ? (
					<NavigationBarItem
						key={item.sys.id}
						item={item}
						itemIndex={index}
						isNavLinkSemibold={isNavLinkSemibold}
					/>
				) : null
			)}
		</Box>
	);
}
