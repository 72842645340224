import { Sys, ContentfulMetadata, Maybe, Scalars } from '@/types/generated';

import { TAGBrand, TGender, TSchedulingSectionName } from './types';

export const FACILITY_CODE_STORAGE_KEY = 'facilityCode';

// @deprecated appConfig is no longer used, use AppConfigContext instead
export const appConfig = {
	prod: {
		ALERTS_API_URL:
			'https://cdn.contentful.com/spaces/m8zwsu9tyucg/environments/master/entries?access_token=kBbSrPktGH1WBUOwK8INsltLi6ihJ5n7FDC3NCeUS44&content_type=alert&fields.facilities=',
		REVIEWS_API_KEY: '?api_key=pHTdKysFBHxzCr2CO4yaqNu4LTv74Fdu',
		REVIEWS_API_URL: 'https://api.birdeye.com/resources/v1/review/businessid/',
		ENTERPRISE_API_URL: 'https://entapi.aspendental.com/api/web/v1',
		REVIEWS_FACILITY_API_URL: 'https://api.birdeye.com/resources/v1/business/',
		PROMOTIONS_AND_OFFERING_API_URL:
			'https://cdn.contentful.com/spaces/m8zwsu9tyucg/environments/master/entries?access_token=kBbSrPktGH1WBUOwK8INsltLi6ihJ5n7FDC3NCeUS44&content_type=promotions&fields.facilities=',
		ENTERPRISE_API_GRAPHQL: 'https://api.aspendental.com/websitegraphql/graphql/',
	},
	nonprod: {
		ALERTS_API_URL:
			'https://cdn.contentful.com/spaces/m8zwsu9tyucg/environments/master/entries?access_token=kBbSrPktGH1WBUOwK8INsltLi6ihJ5n7FDC3NCeUS44&content_type=alert&fields.facilities=',
		REVIEWS_API_KEY: '?api_key=pHTdKysFBHxzCr2CO4yaqNu4LTv74Fdu',
		REVIEWS_API_URL: 'https://api.birdeye.com/resources/v1/review/businessid/',
		ENTERPRISE_API_URL: 'https://nonprod-entapi.aspendental.com/api/web/v1',
		REVIEWS_FACILITY_API_URL: 'https://api.birdeye.com/resources/v1/business/',
		PROMOTIONS_AND_OFFERING_API_URL:
			'https://cdn.contentful.com/spaces/m8zwsu9tyucg/environments/master/entries?access_token=kBbSrPktGH1WBUOwK8INsltLi6ihJ5n7FDC3NCeUS44&content_type=promotions&fields.facilities=',
		ENTERPRISE_API_GRAPHQL: 'https://apistg.aspendental.com/websitegraphql/graphql/',
	},
};

export const allowedYextOrigins = [
	'https://answers-embed_wellnow_com.yextpages.net',
	'https://answers-embed.wellnow.com.pagescdn.com',
	'https://answers-embed_aspendental_com.yextpages.net',
	'https://answers-embed.aspendental.com.pagescdn.com',
	'https://answers-embed_azpetvet_com.yextpages.net',
	'https://answers-embed.azpetvet.com.pagescdn.com',
];

export const STATES = [
	'Alabama',
	'Alaska',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'Florida',
	'Georgia',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Pennsylvania',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
];

export interface IStateCodes {
	state: string;
	code: string;
}

export interface IUser {
	coordinates: {
		latitude: number;
		longitude: number;
	};
	hasIpBasedCoordinates?: boolean;
}

export const DEFAULT_SCHEDULING_URL_ASPENDENTAL = 'https://www.aspendental.com/schedule-an-appointment';

export const DEFAULT_SCHEDULING_URL_WELLNOW = 'https://www.wellnow.com/location-search';

export const ALL_WELLNOW_OPERATING_REGIONS = [
	{
		state: 'Illinois',
		code: 'IL',
	},
	{
		state: 'Indiana',
		code: 'IN',
	},
	{
		state: 'Michigan',
		code: 'MI',
	},
	{
		state: 'New York',
		code: 'NY',
	},
	{
		state: 'Pennsylvania',
		code: 'PA',
	},
];

export const STATE_CODES = [
	{
		state: 'Alabama',
		code: 'AL',
	},
	{
		state: 'Alaska',
		code: 'AK',
	},
	{
		state: 'Arizona',
		code: 'AZ',
	},
	{
		state: 'Arkansas',
		code: 'AR',
	},
	{
		state: 'California',
		code: 'CA',
	},
	{
		state: 'Colorado',
		code: 'CO',
	},
	{
		state: 'Connecticut',
		code: 'CT',
	},
	{
		state: 'Delaware',
		code: 'DE',
	},
	{
		state: 'Florida',
		code: 'FL',
	},
	{
		state: 'Georgia',
		code: 'GA',
	},
	{
		state: 'Hawaii',
		code: 'HI',
	},
	{
		state: 'Idaho',
		code: 'ID',
	},
	{
		state: 'Illinois',
		code: 'IL',
	},
	{
		state: 'Indiana',
		code: 'IN',
	},
	{
		state: 'Iowa',
		code: 'IA',
	},
	{
		state: 'Kansas',
		code: 'KS',
	},
	{
		state: 'Kentucky',
		code: 'KY',
	},
	{
		state: 'Louisiana',
		code: 'LA',
	},
	{
		state: 'Maine',
		code: 'ME',
	},
	{
		state: 'Maryland',
		code: 'MD',
	},
	{
		state: 'Massachusetts',
		code: 'MA',
	},
	{
		state: 'Michigan',
		code: 'MI',
	},
	{
		state: 'Minnesota',
		code: 'MN',
	},
	{
		state: 'Mississippi',
		code: 'MS',
	},
	{
		state: 'Missouri',
		code: 'MO',
	},
	{
		state: 'Montana',
		code: 'MT',
	},
	{
		state: 'Nebraska',
		code: 'NE',
	},
	{
		state: 'Nevada',
		code: 'NV',
	},
	{
		state: 'New Hampshire',
		code: 'NH',
	},
	{
		state: 'New Jersey',
		code: 'NJ',
	},
	{
		state: 'New Mexico',
		code: 'NM',
	},
	{
		state: 'New York',
		code: 'NY',
	},
	{
		state: 'North Carolina',
		code: 'NC',
	},
	{
		state: 'North Dakota',
		code: 'ND',
	},
	{
		state: 'Ohio',
		code: 'OH',
	},
	{
		state: 'Oklahoma',
		code: 'OK',
	},
	{
		state: 'Oregon',
		code: 'OR',
	},
	{
		state: 'Pennsylvania',
		code: 'PA',
	},
	{
		state: 'Rhode Island',
		code: 'RI',
	},
	{
		state: 'South Carolina',
		code: 'SC',
	},
	{
		state: 'South Dakota',
		code: 'SD',
	},
	{
		state: 'Tennessee',
		code: 'TN',
	},
	{
		state: 'Texas',
		code: 'TX',
	},
	{
		state: 'Utah',
		code: 'UT',
	},
	{
		state: 'Vermont',
		code: 'VT',
	},
	{
		state: 'Virginia',
		code: 'VA',
	},
	{
		state: 'Washington',
		code: 'WA',
	},
	{
		state: 'West Virginia',
		code: 'WV',
	},
	{
		state: 'Wisconsin',
		code: 'WI',
	},
	{
		state: 'Wyoming',
		code: 'WY',
	},
];

interface StateCodeToName {
	[key: string]: string;
}

export const STATES_BY_CODE: StateCodeToName = STATE_CODES.reduce(
	(acc, { state, code }) => Object.assign(acc, { [code]: state }),
	{ default: '_' }
);

// alias the above to be more descriptive
export const STATE_NAME_BY_CODE = STATES_BY_CODE;

export const STATE_CODE_BY_NAME: StateCodeToName = STATE_CODES.reduce(
	(acc, { state, code }) => Object.assign(acc, { [state]: code }),
	{ default: '_' }
);

export const ORDERED_SCHEDULING_SECTIONS: TSchedulingSectionName[] = [
	'VisitTypeSelector',
	'TimeSlotSelector',
	'PatientInformation',
	'AppointmentReview',
	'Confirmation',
];

export const GENDER_OPTIONS: { value: TGender; label: string }[] = [
	{
		value: 'M',
		label: 'Male',
	},
	{
		value: 'F',
		label: 'Female',
	},
	{
		value: 'U',
		label: 'Unspecified',
	},
];

export const MOTTO_ALIGNERS_PATH = 'mottoaligners';
export const SCHEDULE_PATH = 'schedule-an-appointment';

export const errorPageView: Record<string, Record<string, unknown>> = {
	'404': {
		page_title: '404: Page Not Found',
		error_type: 'page',
		error_status: true,
		error_details: '404: Page Not Found',
	},
	'500': {
		page_title: '500: Internal Server Error',
		error_type: 'page',
		error_status: true,
		error_details: '500: Internal Server Error',
	},
};

// temporarily hardcoding various AnalyticsContext requirements for items that don't have contentful data
// This will be cleaned up as contentful is cleaned up, but is a larger lift than can be done in time for
// clearchoice
export interface IClearChoice {
	context: {
		__typename?: 'AnalyticsContext';
		_id: Scalars['String'];
		contentfulMetadata: ContentfulMetadata;
		sys: Sys;
	};
	logoClick: {
		clickName?: Maybe<Scalars['String']>;
		clickPosition?: Maybe<Scalars['String']>;
		event?: Maybe<Scalars['String']>;
	};
	scheduleClick: {
		clickName?: Maybe<Scalars['String']>;
		clickPosition?: Maybe<Scalars['String']>;
		event?: Maybe<Scalars['String']>;
	};
	locationClick: {
		clickName?: Maybe<Scalars['String']>;
		clickPosition?: Maybe<Scalars['String']>;
		event?: Maybe<Scalars['String']>;
	};
	footerLogoClick: {
		clickName?: Maybe<Scalars['String']>;
		event?: Maybe<Scalars['String']>;
	};
}

export interface IAspenDental {
	context: {
		__typename?: 'AnalyticsContext';
		_id: Scalars['String'];
		contentfulMetadata: ContentfulMetadata;
		sys: Sys;
	};
	scheduleClick: {
		clickName?: Maybe<Scalars['String']>;
		clickPosition?: Maybe<Scalars['String']>;
		event?: Maybe<Scalars['String']>;
	};
}

// mandatory meta data for HalfScreen and GoogleMaps Component
export const metaData = {
	__typename: undefined,
	tags: [],
	concepts: [],
};

export const sys = {
	__typename: undefined,
	id: '',
	environmentId: '',
	spaceId: '',
};

export const clearChoiceConsts: IClearChoice = {
	context: {
		__typename: 'AnalyticsContext',
		_id: '',
		contentfulMetadata: {
			...metaData,
		},
		sys: { ...sys },
	},
	logoClick: {
		event: 'clicked_a_button_or_link',
		clickName: 'ClearChoice Logo',
		clickPosition: 'Above Nav',
	},
	scheduleClick: {
		event: 'clicked_a_button_or_link',
		clickName: 'Schedule',
		clickPosition: 'Above Nav',
	},
	locationClick: {
		event: 'clicked_a_button_or_link',
		clickName: 'Find a Location',
		clickPosition: 'Above Nav',
	},
	footerLogoClick: {
		event: 'footer_click',
		clickName: 'ClearChoice Logo',
	},
};

export const aspenDentalConsts: IAspenDental = {
	context: {
		__typename: 'AnalyticsContext',
		_id: '',
		contentfulMetadata: {
			...metaData,
		},
		sys: { ...sys },
	},
	scheduleClick: {
		event: 'click_to_scheduler',
		clickName: 'Schedule',
		clickPosition: 'In Header',
	},
};

export const brandName: Record<TAGBrand, string> = {
	aspendental: 'Aspen Dental',
	clearchoice: 'ClearChoice',
	wellnow: 'Wellnow',
	azPetVet: 'AZPetVet',
	chapter: 'Chapter',
	teamTag: 'Team TAG',
	motto: 'Motto',
	youwellnow: 'WellNow',
	livwellnow: 'WellNow',
	lovet: 'Lovet',
};

export const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const DAYS_OF_WEEK_SHORT_HAND: Record<string, string> = {
	Monday: 'Mon',
	Tuesday: 'Tue',
	Wednesday: 'Wed',
	Thursday: 'Thu',
	Friday: 'Fri',
	Saturday: 'Sat',
	Sunday: 'Sun',
};
// Refer to https://en.wikipedia.org/wiki/Geographic_center_of_the_United_States
// for the most accurate center of the United States
export const USA_CENTER_COORDINATES = {
	latitude: 39.8283,
	longitude: -98.5795,
};

export const USA_DEFAULT_DESKTOP_ZOOM = 5;
export const USA_DEFAULT_MOBILE_ZOOM = 4;

export const ONE_TRUST_TOGGLE = 'toggle-cookie-display';

// Image CDN Hosts for image optimization
export const contentfulCDNHost = 'images.ctfassets.net';
export const brandFolderCDNHost = 'cdn.bfldr.com';
