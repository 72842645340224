export const underlineStyles = {
	padding: '0 1rem',
	'&::after': {
		content: '""',
		display: 'block',
		height: '0.125rem',
		width: 0,
		backgroundColor: 'transparent',
		transition: 'width .3s ease, background-color .3s ease',
		position: 'relative',
		top: '0px',
	},
	':hover::after': {
		width: '100%',
		backgroundColor: 'tertiary.main',
	},
	'&.active::after': {
		width: '100%',
		backgroundColor: 'tertiary.main',
	},
};

export const mobileNavItemStyles = {
	padding: '1rem 0 0.5rem',

	'.MuiTypography-root': {
		border: 'none',
		fontSize: '1.625rem',
		fontWeight: 400,
		height: 'auto',
		textIndent: 0,
		lineHeight: '1.75rem',
	},
};

export const getNavItemStyles = (isSemibold?: boolean) => ({
	...underlineStyles,
	height: '2.25rem',
	lineHeight: '2.25rem',

	'.MuiTypography-root': {
		padding: '0 0.5rem',
		color: 'text.primary',
		fontWeight: isSemibold ? 600 : 400,
		height: 'inherit',
		lineHeight: 'inherit',

		'&::after': {
			content: '""',
			display: 'none',
		},
	},
});

export const navItemWithIconStyles = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	height: '2.25rem',
	lineHeight: '2.25rem',
	display: 'inline-block', // makes the link 100% height so that user doesn't have to click exactly on the text
	fontSize: '1rem',
	fontWeight: 600,
	padding: '0 0.5rem',
};

export const dropMenuStyles = {
	'.MuiPaper-root': {
		backgroundColor: 'neutrals.white',
		boxShadow: 'none',
		borderRadius: '0 0 0.5rem 0.5rem',
		'.MuiList-root': {
			'.MuiMenuItem-root': {
				a: {
					width: '100%',
					span: {
						padding: '0 0.5rem',
					},
				},
			},
		},
	},
};

export const navGroupLinkStyles = {
	groupLinkStyles: {
		color: 'text.interactive',
		'&:last-of-type': {
			paddingRight: '0',
		},
	},
	groupLinkTitleStyles: {
		'&.MuiTypography-root': {
			':hover::after': {
				width: 'calc(100% - 0.375rem)',
			},
		},
	},
	getGroupLinkIconStyles: (active: boolean) => ({
		verticalAlign: 'middle',
		mb: '0.2rem',
		ml: '0.2rem',
		transition: 'transform 0.2s ease-in-out',
		...(active && {
			transform: 'rotate(-180deg)',
		}),
	}),
};

export const collapseMenuItemStyles = {
	'.MuiCollapse-wrapperInner': {
		display: 'flex',
		flexDirection: 'column',
		gap: '1.5rem',
	},
};

export const collapseNavigationLinkStyles = {
	'.MuiTypography-root': {
		border: 'none',
		height: 'auto',
		lineHeight: '1.5rem',
		textIndent: 0,
	},
};
