import { getGraphQLClient } from '@/graphql/client';
import { getGraphHeaders } from '@/utils/getGraphHeaders';
import { logWarning } from '@/utils/miscUtils';
import { BffBrandType } from '@/types/generated';

export const getBffFacilitiesByAddress = async (
	brand: BffBrandType,
	address: string,
	url: string,
	appName: string,
	appVersion: string
) => {
	try {
		if (!brand) {
			return null;
		}

		return await getGraphQLClient(url).bffFacilitiesByAddress(
			{
				brand,
				address,
			},
			getGraphHeaders(appName, appVersion)
		);
	} catch (err) {
		logWarning(`Could not find any facilities data for: ${brand} for query: ${address}`);
	}
};
