import { DAYS_OF_WEEK } from '@/constants';

interface GroupDay {
	day: string;
	hours: string;
}

export const groupDays = (arr: GroupDay[]) => {
	const result: GroupDay[] = [];
	let start = 0;

	for (let i = 1; i <= arr.length; i++) {
		if (i === arr.length || arr[i].hours !== arr[start].hours) {
			const end = i - 1;
			const dayRange = start === end ? DAYS_OF_WEEK[start] : `${DAYS_OF_WEEK[start]} - ${DAYS_OF_WEEK[end]}`;
			result.push({ day: dayRange, hours: arr[start].hours });
			start = i;
		}
	}

	return result;
};
