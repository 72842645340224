export const addClassName = (href?: string | null): string => {
	if (!href) return '';

	const isTelLink = href.startsWith('tel');

	if (isTelLink) {
		return 'InvocaNumber';
	}

	return '';
};
