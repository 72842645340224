//TODO: rename and move this to be Footer helpers since both Footer variations make use of it
import { ONE_TRUST_TOGGLE } from '@/constants';
import {
	Maybe,
	FooterNavigationGroupV2NavigationLinksForGroupItem,
	NavigationLink,
	Page,
	FooterNavigationGroupV2NavigationGroupLink,
	AdFooterNavigationGroupsCollection,
} from '@/types/generated';

export function isNavLink(
	item?: Maybe<FooterNavigationGroupV2NavigationLinksForGroupItem | FooterNavigationGroupV2NavigationGroupLink>
): item is NavigationLink {
	if (!item) return false;

	return item.__typename === 'NavigationLink';
}

export function isPageLink(item?: Maybe<FooterNavigationGroupV2NavigationLinksForGroupItem | Page>): item is Page {
	if (!item) return false;

	return item.__typename === 'Page';
}

export function resolveLinkTitle(
	item?:
		| Maybe<FooterNavigationGroupV2NavigationLinksForGroupItem>
		| Maybe<FooterNavigationGroupV2NavigationGroupLink | Page>
) {
	if (!item) return '';

	if (isNavLink(item)) {
		if (item.isInternal && item.pageData) {
			return item.pageData.breadcrumbTitle || item.pageData.title || '';
		}
		return item.linkText || '';
	} else if (isPageLink(item)) {
		return item.breadcrumbTitle || item.title || '';
	}

	return '';
}

export function resolveLinkUrl(
	item?: Maybe<FooterNavigationGroupV2NavigationLinksForGroupItem | FooterNavigationGroupV2NavigationGroupLink | Page>
) {
	if (!item) return '#';

	if (isNavLink(item)) {
		if (item.isInternal && item.pageData) {
			return item.pageData.path || '#';
		}
		if (item.externalUrl?.includes(ONE_TRUST_TOGGLE)) {
			return '#';
		}
		return item.externalUrl || '#';
	} else if (isPageLink(item)) {
		return item.path || '#';
	}

	return '#';
}

export function resolveLinkImage(
	item?: Maybe<FooterNavigationGroupV2NavigationLinksForGroupItem | FooterNavigationGroupV2NavigationGroupLink>
) {
	if (!item) return null;

	if (isNavLink(item) && item.linkIcon) {
		return item.linkIcon;
	} else if (isPageLink(item) && item.image) {
		return item.image;
	}

	return null;
}

export function findCompanyGroup(navigationGroupItems?: Maybe<AdFooterNavigationGroupsCollection['items']>) {
	if (!navigationGroupItems) return null;

	return navigationGroupItems.find((group) => {
		const linkTitle = resolveLinkTitle(group?.navigationGroupLink);

		return linkTitle === 'Company';
	});
}
