export const copyrightStyles = (isSmallDevice: boolean) => ({
	textAlign: 'center',
	justifyContent: isSmallDevice ? 'start' : 'center',
	display: 'flex',
});

export const getPolicyLinksStyles = {
	overflow: 'auto',
	'&::-webkit-scrollbar ': {
		background: 'transparent',
		WebkitAppearance: 'none',
	},
};
