export const underlineStyles = (isTextColorLight?: boolean) => ({
	'&::after': {
		content: '""',
		display: 'block',
		height: '0.125rem',
		width: 0,
		backgroundColor: 'transparent',
		position: 'relative',
		transition: 'width .3s ease, background-color .3s ease',
		top: '-5px',
	},
	':hover::after': {
		width: '100%',
		backgroundColor: isTextColorLight ? 'text.light' : 'text.interactive',
	},
});

const navItemStyles = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	height: '3rem',
	lineHeight: '3rem',
	display: 'inline-block', // makes the link 100% height so that user doesn't have to click exactly on the text
	padding: '0 1rem',
	fontSize: '1rem',
};

const navItemStylesMobile = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	height: '3.25rem',
	lineHeight: '3.25rem',
	textIndent: '1.25rem',
	width: '100%',
	'&:hover, &:active': {
		opacity: 0.8,
	},
};

const compactNavItemStyles = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
	'&:hover, &:active': {
		opacity: 0.8,
	},
};

const utilityNavItemMobileStyles = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	display: 'inline-block',
	height: '2rem',
	lineHeight: '3rem',
	width: '100%',
	textIndent: '1.25rem',
	'&:hover, &:active': {
		opacity: 0.8,
	},
};

const utilityNavItemStyles = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	height: '3rem',
	lineHeight: '3rem',
	display: 'inline-block', // makes the link 100% height so that user doesn't have to click exactly on the text
	padding: '0 1rem',
	fontSize: '1rem',
	...underlineStyles,
};
export const getNavLinkStyles = (
	isMobileScreen: boolean,
	itemIndex: number,
	isUtilityNavigation: boolean,
	compactMobileMenuStyle: boolean
) => {
	if (!isMobileScreen || isUtilityNavigation || compactMobileMenuStyle) {
		return { textDecoration: 'none' };
	}

	return {
		'& .MuiTypography-root': {
			borderBottom: itemIndex !== 0 ? '1px solid' : 'none',
			borderColor: 'neutrals.primary',
		},
		textDecoration: 'none',
	};
};

interface IGetNavItemStyles {
	isMobileScreen: boolean;
	isUtilityNavigation?: boolean;
	withIcon?: boolean;
	disableUnderline?: boolean;
	compactMobileMenuStyle?: boolean;
	isTextColorLight?: boolean;
}
export const getNavItemStyles = ({
	isMobileScreen,
	isUtilityNavigation,
	withIcon,
	disableUnderline,
	compactMobileMenuStyle,
	isTextColorLight,
}: IGetNavItemStyles) => {
	if (compactMobileMenuStyle) {
		return compactNavItemStyles;
	}

	if (withIcon) {
		return navItemStyles;
	}

	if (isUtilityNavigation) {
		return isMobileScreen ? utilityNavItemMobileStyles : utilityNavItemStyles;
	}

	if (disableUnderline) {
		return isMobileScreen ? navItemStylesMobile : navItemStyles;
	}

	return isMobileScreen ? navItemStylesMobile : { ...navItemStyles, ...underlineStyles(isTextColorLight) };
};

export const getNavItemTypographyVariant = (isSmallScreen: boolean, isUtilityNavigation?: boolean) => {
	if (isUtilityNavigation) {
		return isSmallScreen ? 'bodySmallBook' : 'bodySmallSemiBold';
	}
	return 'bodyLargeSemiBold';
};

export const imageStyles = {
	width: '1rem',
	height: '1rem',
	filter: 'brightness(0) saturate(100%) invert(15%) sepia(53%) saturate(4016%) hue-rotate(208deg) brightness(93%) contrast(112%)',
};
