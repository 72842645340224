import { Theme } from '@mui/material';

export const getWrapperStyles = (navigationGroupsLen: number) => ({
	display: 'grid',
	width: '100%',
	gridTemplateColumns: `repeat(${navigationGroupsLen}, 5fr)`,
	gridColumnGap: '1.25rem',
	overflow: 'hidden',
});

export const getScheduleContainerStyles = (isSmallDevice: boolean) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: isSmallDevice ? 'column' : 'row',
	gap: isSmallDevice ? '1rem' : '3rem',
});

export const getNavLinkStyles = (theme: Theme) => ({
	justifyContent: 'flex-start',
	lineHeight: '1.5rem',
	[theme.breakpoints.down('lg')]: {
		fontSize: '0.8rem',
	},
});

export const getNavTitleStyles = (theme: Theme) => ({
	[theme.breakpoints.down('lg')]: {
		fontSize: '0.9rem',
	},
});

export const smallScreenWrapper = {
	display: 'flex',
	flexDirection: 'column',
	gap: '0.5rem',
	alignItems: 'flex-start',
	justifyContent: 'center',
	width: '100%',
	'& > .MuiAccordion-root:before': {
		background: 'transparent',
	},
};

export const getAccordionStyles = (isLightFooter: boolean) => ({
	backgroundColor: isLightFooter ? 'backgrounds.light' : 'backgrounds.dark',
	boxShadow: 'none',
	width: '100%',
	'& > .MuiAccordionSummary-root': {
		flexDirection: 'row',
		padding: 0,
	},
});

export const accordionSummaryStyles = {
	display: 'flex',
	justifyContent: 'flex-start',
	lineHeight: '24px',
};

export const accordionDetailsBoxStyles = {
	gap: 2,
	display: 'flex',
	flexDirection: 'column',
	paddingLeft: '0.3rem',
};

export const footerLinkTextBoxDesktopStyles = (isOneTrustLink: boolean) => ({
	display: 'flex',
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	alignItems: 'center',
	...(isOneTrustLink && { whiteSpace: 'nowrap' }),
});
