import React, { useState, useEffect } from 'react';

import { Typography, Menu, MenuItem, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { HeaderNavigationLinksItem } from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { incorrectUsageWarning } from '@/utils/miscUtils';
import NavigationLink from '@/components/NavigationLink';
import {
	itemIsNavigationGroup,
	itemIsNavigationLink,
} from '@/components/Header/NavigationBar/NavigationBarItem/NavigationBarItem.helpers';

import {
	navItemWithIconStyles,
	dropMenuStyles,
	navGroupLinkStyles,
	underlineStyles,
	getNavItemStyles,
	mobileNavItemStyles,
	collapseMenuItemStyles,
	collapseNavigationLinkStyles,
} from './NavigationBarItem.styles';

interface INavigationBarItem {
	item: HeaderNavigationLinksItem;
	itemIndex: number;
	isNavLinkSemibold?: boolean;
	isMobile?: boolean;
	isUtilityNavigation?: boolean;
}

export default function NavigationBarItem({
	item,
	itemIndex,
	isNavLinkSemibold = true,
	isMobile,
	isUtilityNavigation = false,
}: INavigationBarItem) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [activeLinkId, setActiveLinkId] = useState<string | null>(null);
	const open = Boolean(anchorEl);
	const closeMenu = () => {
		setAnchorEl(null);
		setActiveLinkId(null);
	};
	const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
		event.preventDefault();
		if (id === activeLinkId) {
			closeMenu();
		} else {
			setAnchorEl(event.currentTarget);
			setActiveLinkId(id);
		}
	};
	const handleClose = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		closeMenu();
	};

	useEffect(() => {
		const handleScroll = () => {
			closeMenu();
		};
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (itemIsNavigationLink(item)) {
		return (
			<NavigationLink
				{...item}
				isInternal={item.isInternal || false}
				itemIndex={itemIndex}
				dataTestId={`link_page_nav_item_${item.sys.id}`}
				eventType="navigation"
				navLinkSx={isMobile ? mobileNavItemStyles : getNavItemStyles(isNavLinkSemibold)}
				isUtilityNavigation={isUtilityNavigation}
			/>
		);
	}

	if (itemIsNavigationGroup(item)) {
		return (
			<>
				{item.groupLink?.linkText && (
					<TAGLink
						dataTestId={`link_page_nav_group_item_${item.sys.id}`}
						onClick={(e) => handleClick(e, item.sys.id)}
						aria-controls={`header_navigation_group_menu_${itemIndex}`}
						aria-haspopup="true"
						analyticsContext={item.groupLink?.analyticsContext}
						linkSx={isMobile ? mobileNavItemStyles : underlineStyles}
						className={activeLinkId === item.sys.id ? 'active' : ''}
					>
						<Typography
							variant="bodyLargeSemiBold"
							color={isMobile ? 'text.interactive' : 'text.primary'}
							{...(!isMobile && { sx: [navGroupLinkStyles.groupLinkTitleStyles, navItemWithIconStyles] })}
						>
							{item.groupLink?.linkText}
							<Typography color="tertiary.main" sx={{ display: 'inline' }}>
								<KeyboardArrowDownIcon
									sx={navGroupLinkStyles.getGroupLinkIconStyles(activeLinkId === item.sys.id)}
								/>
							</Typography>
						</Typography>
					</TAGLink>
				)}

				{!isMobile && (
					<Menu
						id={`header_navigation_group_menu_${itemIndex}`}
						disableScrollLock={true}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						sx={dropMenuStyles}
						transitionDuration={1}
					>
						{item.linksCollection?.items?.map((item, index) =>
							item ? (
								<MenuItem key={item.sys.id}>
									<NavigationLink
										{...item}
										isInternal={item.isInternal || false}
										itemIndex={index}
										dataTestId={`link_navlink_nav_item_${item.sys.id}`}
										disableUnderline
									/>
								</MenuItem>
							) : null
						)}
					</Menu>
				)}
				{isMobile && (
					<Collapse in={open} sx={collapseMenuItemStyles}>
						{item.linksCollection?.items?.map((item, index) =>
							item ? (
								<NavigationLink
									key={item.sys.id}
									{...item}
									isInternal={item.isInternal || false}
									itemIndex={index}
									dataTestId={`link_navlink_nav_item_${item.sys.id}`}
									navLinkSx={collapseNavigationLinkStyles}
								/>
							) : null
						)}
					</Collapse>
				)}
			</>
		);
	}

	incorrectUsageWarning(
		'NavigationBarItem',
		`Unsupported Content Type for HeaderNavigationLinksItem type passed to the NavigationBar. NavigationBarItem must be of type NavigationLink or NavigationGroup. (Page or FooterNavigationGroup support would be removed once all content are using NavigationLink and NavigationGroup).`
	);

	return null;
}
