import { Theme } from '@mui/material';

import { TAGBrand } from '@/types';

const desktopBoxStyles = {
	bottom: 100,
	width: 407,
	height: 686,
	right: 20,
	borderRadius: '8px',
};

const mobileBoxStyles = {
	height: '72%',
	bottom: 0,
	right: 0,
	left: 0,
};

export const chatWithUsStyles = {
	position: 'fixed',
	zIndex: 99,
	bottom: 40,
	right: 20,
	'> span': {
		width: '20px',
	},
};

export const boxContainerStyles = (isSmall: boolean) => {
	if (!isSmall) {
		return;
	}

	return {
		position: 'fixed',
		height: '100%',
		width: '100%',
		top: 0,
		left: 0,
		zIndex: 9000,
	};
};

export const boxContainerBackground = (isSmall: boolean) => {
	if (!isSmall) {
		return;
	}

	return {
		content: '" "',
		position: 'absolute',
		height: '100%',
		width: '100%',
		top: 0,
		left: 0,
		backgroundColor: 'rgba(0,0,0,0.6)',
	};
};

export const boxWrapperStyles = (theme: Theme, isSmall: boolean) => {
	const responsiveStyles = isSmall ? mobileBoxStyles : desktopBoxStyles;
	return {
		display: 'flex',
		flexDirection: 'column',
		gap: '12px',
		position: 'fixed',
		background: `linear-gradient(to bottom, ${theme.palette.info.light} 195px, #FFFFFF 195px)`,
		boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.25)',
		padding: '10px 0 0 0',
		zIndex: 9999,
		...responsiveStyles,
		'> iframe': {
			width: '100%',
			overflow: 'hidden',
			height: '94%',
			border: 'transparent 1px solid',
			borderBottomRightRadius: '0.5rem',
			borderBottomLeftRadius: '0.5rem',
		},
	};
};

export const headerWrapperStyles = {
	width: '100%',
	margin: 0,
	padding: '0 0.5rem',
};

export const svgItemWrapperStyles = (theme: Theme, brand: TAGBrand) => {
	return {
		width: 41,
		height: 41,
		borderRadius: '50%',
		padding: 0,
		...(brand === TAGBrand.AspenDental && {
			backgroundColor: theme?.palette?.primary?.dark,
		}),
	};
};

export const svgIconStyles = {
	margin: '10px',
};

export const OpenChatButton = (theme: Theme) => {
	return {
		height: '56px',
		width: '56px',
		minWidth: 'unset',
		border: `1px solid ${theme.palette.primary.main}`,
		background: `${theme.palette.primary.main}`,
		borderRadius: '50%',
		boxShadow: '1rem 1rem 5rem rgba(0, 0, 0, 0.5)',
		textAlign: 'center',
		fontSize: '50px',
		transition: 'background-color cubic-bezier(0.4, 0, 0.2, 1) 250ms',
		...chatWithUsStyles,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.dark}`,
		},
	};
};
