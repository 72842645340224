import { FacilityFieldsFragment } from '@/graphql/__generated/sdk';
import { BrandThemeConfig, TOfficeInfo } from '@/types';
import { BffFacility } from '@/types/generated';

import { officePathByBrand } from '../officeURLByBrand/officeURLByBrand';

export const replaceUrlContentVars = (
	href: string,
	officeInfo: FacilityFieldsFragment | null,
	officeLocationInfo: TOfficeInfo | null,
	config: BrandThemeConfig
) => {
	if (href.includes('[facilityUrl]')) {
		const address: FacilityFieldsFragment['address'] | BffFacility['address'] =
			officeInfo?.address || officeLocationInfo?.address;
		if (address) {
			const facilityUrl = officePathByBrand(config.themeId, {
				stateStrOptions: {
					stateAbbreviation: address?.stateCode || '',
					state: '',
					city: address?.city || '',
				},
				cityAddressOptions: {
					city: address?.city || undefined,
					address: address?.address1 || undefined,
					zip: address?.zipCode || undefined,
				},
			});

			const facilityBasePath = config.features.otherApps.facilityDetailsBasePath;
			return href.replace('/[facilityUrl]/', `/${facilityBasePath}/${facilityUrl}/`);
		}
		return href;
	}

	if (href.includes('[schedulingUrl]')) {
		if (officeInfo?.scheduling?.schedulingUrl) {
			return href.replace('[schedulingUrl]', officeInfo?.scheduling?.schedulingUrl);
		}
		return href.replace('[schedulingUrl]', `/${config.features.otherApps.facilityDetailsBasePath}`);
	}

	return href;
};
