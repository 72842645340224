import { useState, useRef } from 'react';

import { Box } from '@mui/material';

import { Header } from '@/types/generated';
import { Maybe } from '@/types';
import { useFacilityContext } from '@/context';

import SchedulingButton from '../SchedulingButton';

import LocationSearch from './LocationSearch';
import { getLocationNavigationBarStyles, searchWrapStyles } from './LocationNavBar.styles';
import LocationDetails from './LocationDetails';
import LocationNavMenu from './LocationNavMenu';

interface ILocationNavBar {
	schedulingButtonText?: Maybe<string>;
	schedulingButtonTextMobile?: Maybe<string>;
	locationBarFindOfficeUrl?: Header['locationBarFindOfficeUrl'];
	isMobile?: boolean;
}

export default function LocationNavBar({
	schedulingButtonText,
	locationBarFindOfficeUrl,
	schedulingButtonTextMobile,
	isMobile,
}: ILocationNavBar) {
	const { officeInfo } = useFacilityContext();
	const [searchOpened, setSearchOpened] = useState(false);
	const locationNavBarRef = useRef<HTMLDivElement>(null);
	const schedulingButtonUrl = officeInfo?.scheduling?.schedulingUrl || locationBarFindOfficeUrl;

	return (
		<Box
			data-test-id="location_navigation_bar"
			sx={getLocationNavigationBarStyles(isMobile)}
			ref={locationNavBarRef}
		>
			<Box sx={searchWrapStyles}>
				{officeInfo && !searchOpened ? (
					<LocationDetails
						setSearchOpened={setSearchOpened}
						isMobile={isMobile}
						locationNavBarRef={locationNavBarRef}
					/>
				) : (
					<LocationSearch
						locationBarFindOfficeUrl={locationBarFindOfficeUrl}
						isMobile={isMobile}
						locationNavBarRef={locationNavBarRef}
						searchOpened={searchOpened}
					/>
				)}
				{!isMobile && <LocationNavMenu />}
			</Box>
			<SchedulingButton
				schedulingButtonUrl={schedulingButtonUrl}
				schedulingButtonText={schedulingButtonText}
				schedulingButtonTextMobile={schedulingButtonTextMobile}
				isMobile={isMobile}
				onClick={() => {
					const customEvent = new CustomEvent('lovetHeaderScheduleClick', { detail: 'site_button_click' });
					globalThis.document.dispatchEvent(customEvent);
				}}
			/>
		</Box>
	);
}
