import { Box, Typography } from '@mui/material';

import TAGLink from '@/components/TAGLink';
import { Header } from '@/types/generated';
import Drawer from '@/components/Drawer/Drawer';

import { linkStyles, findOfficeMenuStyles } from './HeaderOfficeLocation.styles';

export default function NoOfficeTitle({
	locationBarFindOfficeUrl,
	locationBarWithOutOfficeTitle,
	withPopupOfficeSearch = false,
	isLightText,
}: {
	locationBarFindOfficeUrl: NonNullable<Header['locationBarFindOfficeUrl']>;
	locationBarWithOutOfficeTitle: NonNullable<Header['locationBarFindOfficeUrl']>;
	withPopupOfficeSearch?: React.ReactNode;
	isLightText: boolean;
}) {
	if (withPopupOfficeSearch) {
		return (
			<Drawer
				anchor="left"
				triggerComponent={
					<Typography
						variant="bodyMediumBold"
						data-test-id="text_no_office_title"
						sx={linkStyles({ isLightText })}
					>
						{locationBarWithOutOfficeTitle}
					</Typography>
				}
				closeButton
			>
				<Box sx={findOfficeMenuStyles}>
					<Typography variant="header3">Find an office</Typography>
					<div>Test</div>
				</Box>
			</Drawer>
		);
	}
	return (
		<TAGLink href={locationBarFindOfficeUrl} linkSx={{ textDecoration: 'none' }}>
			<Typography
				variant="bodyMediumSemiBold"
				data-test-id="text_no_office_title"
				sx={linkStyles({ isLightText })}
			>
				{locationBarWithOutOfficeTitle}
			</Typography>
		</TAGLink>
	);
}
