import { useState, useRef, RefObject } from 'react';

import { Box, Divider, Grid, Menu, Typography } from '@mui/material';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useFacilityContext, useAppContext } from '@/context';
import TAGSvgIcon from '@/components/TAGSvgIcon';
import TAGButton from '@/components/TAGButton';
import TAGLink from '@/components/TAGLink';
import { getPhoneIconStyles } from '@/components/Header/HeaderDesktop/HeaderDesktop.styles';
import { getWorkingHoursFromBff, getWorkingHours, groupDays, resolvePhoneNumberFormat } from '@/utils';
import { getOdpUrl } from '@/utils/generateODPLinks';
import { BffWorkingHours } from '@/types/generated';

import LocationNavMenu from '../LocationNavMenu';

import {
	getCarrotIconStyles,
	getDropMenuStyles,
	officeInfoBoxStyles,
	secondaryMenuButtonStyles,
} from './LocationDetails.styles';

interface IProps {
	setSearchOpened: (opened: boolean) => void;
	isMobile?: boolean;
	locationNavBarRef?: RefObject<HTMLDivElement>;
}

export default function LocationDetails({ setSearchOpened, isMobile, locationNavBarRef }: IProps) {
	const buttonWrapRef = useRef<HTMLDivElement>(null);
	const [isMenuOpened, setMenuOpened] = useState(false);

	const { config } = useAppContext();
	const { officeInfo } = useFacilityContext();
	const useFacilityBeffeDataSource = config.featureFlags.useFacilityBeffeDataSource;

	const handleClose = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setMenuOpened(false);
	};

	if (!officeInfo) return null;

	const workingHours = useFacilityBeffeDataSource
		? getWorkingHoursFromBff((officeInfo.workingHours ?? []) as BffWorkingHours[], true)
		: getWorkingHours(officeInfo.workingHours ?? [], true);

	return (
		<>
			<Box ref={buttonWrapRef}>
				<TAGButton
					variant="text"
					color="primary"
					size="XS"
					startIcon={
						<Box color="primary.light" display="flex">
							<TAGSvgIcon icon="LocationOnOutlined" size={20} />
						</Box>
					}
					endIcon={
						<Box color="tertiary.main" display="flex">
							<KeyboardArrowDownIcon sx={getCarrotIconStyles(isMenuOpened)} />
						</Box>
					}
					onClick={() => setMenuOpened(true)}
				>
					<Typography color="primary.main" fontWeight={600} variant="bodyMediumBook">
						{officeInfo.name}
					</Typography>
				</TAGButton>
			</Box>
			<Menu
				id={`header_with_location_navigation_search_result_dropdown`}
				disableScrollLock={true}
				open={isMenuOpened}
				sx={getDropMenuStyles(isMobile)}
				transitionDuration={1}
				anchorEl={isMobile ? locationNavBarRef?.current : buttonWrapRef.current}
				onClose={handleClose}
				{...(isMobile && { marginThreshold: 0 })}
			>
				{isMobile && (
					<>
						<LocationNavMenu isMobile />
						<Divider />
					</>
				)}
				<Box>
					<Typography color="primary.main" variant="bodyMediumSemiBold">
						{officeInfo.name}
					</Typography>
					<Box sx={officeInfoBoxStyles}>
						<Box>
							<Typography color="neutrals.tertiary">{officeInfo.address.address1}</Typography>
							<Typography color="neutrals.tertiary">
								{officeInfo.address.city}, {officeInfo.address.stateCode} {officeInfo.address.zipCode}
							</Typography>
						</Box>
						{officeInfo.technicalInfrastructure?.phoneNumber && (
							<Box
								component="section"
								display="flex"
								alignItems="center"
								data-test-id="location_details_phone_number"
								gap={0.5}
							>
								<CallOutlinedIcon
									sx={getPhoneIconStyles({ isDarkHeader: false })}
									data-test-id="location_details_phone_number_icon"
								/>
								<TAGLink
									href={`tel:${officeInfo.technicalInfrastructure.phoneNumber.replace(
										/[- )(]/g,
										String
									)}`}
									noLinkStyle
								>
									<Typography variant="buttonLabel" color="text.interactive">
										{resolvePhoneNumberFormat(officeInfo.technicalInfrastructure.phoneNumber)}
									</Typography>
								</TAGLink>
							</Box>
						)}
					</Box>
				</Box>
				{!!workingHours.length && (
					<Box>
						<Typography color="primary.main" variant="bodyMediumBold">
							Hours of Operation
						</Typography>
						<Grid container gap={1} sx={{ flexDirection: 'column' }}>
							{groupDays(workingHours).map((item, index) => (
								<Grid container key={index} gap={1.5} alignItems="center">
									<Typography color="neutrals.tertiary" variant="bodyMediumBook" width="35%">
										{item.day}:
									</Typography>
									<Typography color="neutrals.tertiary" variant="bodyMediumSemiBold">
										{item.hours}
									</Typography>
								</Grid>
							))}
						</Grid>
					</Box>
				)}
				<Box display="flex" gap="1rem">
					<TAGButton
						variant="primaryDefault"
						size="M"
						href={getOdpUrl(officeInfo.address, config)}
						sx={{
							flexGrow: 1,
						}}
					>
						View More Info
					</TAGButton>
					<TAGButton
						variant="secondaryDefault"
						size="M"
						onClick={() => setSearchOpened(true)}
						sx={secondaryMenuButtonStyles}
					>
						Change Location
					</TAGButton>
				</Box>
			</Menu>
		</>
	);
}
