import { useEffect, useState } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Maybe, TAGBrand } from '@/types';
import { useFacilityContext } from '@/context';
import { dataLayerConfig } from '@/utils';
import { Button } from '@/types/generated';

import TAGSvgIcon from '../TAGSvgIcon/TAGSvgIcon';
import TAGButton from '../TAGButton';
import ContentfulButton from '../ContentfulButton';

import {
	boxWrapperStyles,
	chatWithUsStyles,
	boxContainerStyles,
	boxContainerBackground,
	OpenChatButton,
} from './Chatbot.styles';
import ChatbotHeader from './ChatbotHeader';

export interface ChatbotProps {
	url: string;
	isIconButton?: boolean;
	logoUrl?: string;
	featureList: string[];
	stickyFooterButton?: Maybe<Button>;
}

interface IStateObject {
	openPopup: boolean;
	showChatWithUs: boolean;
}

const Chatbot = ({
	url = '',
	isIconButton = false,
	logoUrl = '',
	featureList = [],
	stickyFooterButton,
}: ChatbotProps) => {
	const [state, setState] = useState<IStateObject>({
		openPopup: false,
		showChatWithUs: true,
	});
	const { officeInfo, facilityBrand } = useFacilityContext();
	const theme = useTheme();
	const { brand } = theme;

	const isSmall = useMediaQuery(theme.breakpoints.down('md'));

	const mobileOnly =
		isSmall && featureList?.includes('ameliaChatMobile') && !featureList?.includes('ameliaChatDesktop');
	const desktopOnly =
		!isSmall && featureList?.includes('ameliaChatDesktop') && !featureList?.includes('ameliaChatMobile');
	const mobileAndDesktop = featureList?.includes('ameliaChatDesktop') && featureList?.includes('ameliaChatMobile');

	useEffect(() => {
		if (isSmall && state.openPopup) {
			document.body.classList.add('chat-open');
		} else {
			document.body.classList.remove('chat-open');
		}
	}, [isSmall, state.openPopup]);

	useEffect(() => {
		// Amelia iframe message listener
		/* eslint-disable */
		const handleMessage = (event: MessageEvent) => {
			const {
				origin,
				data,
			}: {
				origin: string;
				data: {
					[key: string]: Maybe<string | boolean | number | undefined>;
				};
			} = event;

			if (!origin.includes('amelia')) {
				return;
			}

			switch (facilityBrand) {
				case 'ASPEN_DENTAL': {
					const isScheduleStarted = data?.action === 'Amelia Schedule start';
					const isScheduleCompleted = data?.account_id; // account_id exists when appointment is booked
					if (isScheduleStarted) {
						dataLayerConfig({
							event: 'amelia_schedule_start',
							brand: 'Aspen Dental',
						});
					}
					if (isScheduleCompleted) {
						dataLayerConfig({
							event: 'amelia_schedule_success',
							existing_customer: false,
							brand: 'Aspen Dental',
							website_selected_facility_id: officeInfo?.code,
							website_selected_facility_name: officeInfo?.name,
							...data,
						});
					}
					break;
				}
				default:
					return null;
			}
		};
		window.addEventListener('message', handleMessage);
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	const handleClick = () => {
		setState({
			...state,
			openPopup: !state.openPopup,
		});
	};

	const handleClose = () => {
		setState({
			...state,
			openPopup: false,
		});
	};

	if (!state.showChatWithUs) {
		return null;
	}

	if (
		!stickyFooterButton &&
		!featureList?.includes('ameliaChatMobile') &&
		!featureList?.includes('ameliaChatDesktop')
	) {
		return null;
	}

	const chatConditionSet = mobileAndDesktop || (isSmall && mobileOnly) || (!isSmall && desktopOnly);
	if (stickyFooterButton) {
		return (
			<>
				{state.openPopup && (
					<Box sx={{ ...boxContainerStyles(isSmall) }}>
						{isSmall && (
							<Box
								sx={{ ...boxContainerBackground(isSmall) }}
								{...(isSmall && { onClick: handleClose })}
							></Box>
						)}
						<Box data-test-id="section_chatbot" sx={{ ...boxWrapperStyles(theme, isSmall) }}>
							<ChatbotHeader handleClose={handleClose} logoUrl={logoUrl} />
							<iframe
								allow="geolocation; microphone"
								src={url}
								id="Amelia-chatbot-frame"
								title="Amelia-chatbot"
								data-gtm-yt-inspected-19="true"
							></iframe>
						</Box>
					</Box>
				)}
				<ContentfulButton
					onClick={handleClick}
					dataTestId="sticky_amelia_chat_icon_button"
					{...stickyFooterButton}
				/>
			</>
		);
	}

	return (
		<>
			{chatConditionSet ? (
				<>
					{state.openPopup && (
						<Box sx={{ ...boxContainerStyles(isSmall) }}>
							{isSmall && (
								<Box
									sx={{ ...boxContainerBackground(isSmall) }}
									{...(isSmall && { onClick: handleClose })}
								></Box>
							)}
							<Box data-test-id="section_chatbot" sx={{ ...boxWrapperStyles(theme, isSmall) }}>
								<ChatbotHeader handleClose={handleClose} logoUrl={logoUrl} />
								<iframe
									allow="geolocation; microphone"
									src={url}
									id="Amelia-chatbot-frame"
									title="Amelia-chatbot"
									data-gtm-yt-inspected-19="true"
								></iframe>
							</Box>
						</Box>
					)}
					{!isIconButton ? (
						<TAGButton
							size="S"
							variant="primaryAccent1"
							onClick={handleClick}
							dataTestId="amelia_chat_button"
							startIcon={
								<TAGSvgIcon
									icon={brand === TAGBrand.AspenDental ? 'AspenChatIcon' : 'ChatBubbleOutline'}
									dataTestId={
										brand === TAGBrand.AspenDental ? 'AspenChatIcon' : 'ChatBubbleOutlineIcon'
									}
									size={25}
								/>
							}
							sx={chatWithUsStyles}
						>
							Chat with us
						</TAGButton>
					) : (
						<TAGButton
							sx={OpenChatButton}
							disableTouchRipple
							onClick={handleClick}
							dataTestId="amelia_chat_icon_button"
						>
							<TAGSvgIcon icon="ChatBubbleOutline" color="#FFF" fill="#FFF" width="34" height="34" />
						</TAGButton>
					)}
				</>
			) : null}
		</>
	);
};

export default Chatbot;
