import { BrandThemeConfig } from '@/types';
import { BffBrandType } from '@/types/generated';

const contentfulEnvironmentSuffix = process.env.CONTENTFUL_ENVIRONMENT_ID
	? `/environments/${process.env.CONTENTFUL_ENVIRONMENT_ID}`
	: '';

const CONTENTFUL_GRAPHQL_URL = `https://graphql.contentful.com/content/v1/spaces/${
	process.env.CONTENTFUL_SPACE_ID as string
}${contentfulEnvironmentSuffix}`;

const config: BrandThemeConfig = {
	name: 'Aspen Dental',
	logo: {
		dark: 'https://images.ctfassets.net/m8zwsu9tyucg/7jzYqWlL2Z1NyUWPgR7mpc/922ffe5db0d5cd28bcb42653e7d9a5e6/AspenLogoDark.svg',
		light: 'https://images.ctfassets.net/m8zwsu9tyucg/1OLI6iOYAFp0Mvqm46Yyor/8908782b135cb2d577fe727a536434b1/logo-aspendental-white.svg',
	},
	siteURL: 'https://www.aspendental.com',
	services: {
		prod: {
			ENTERPRISE_API_GRAPHQL: 'https://api.aspendental.com/websitegraphql/graphql/',
			REVSPRING_API_URL: '/api/rssched/',
			CONTENTFUL_GRAPHQL_URL,
			ENTERPRISE_API_URL_V2: 'https://api.aspendental.com/api/web/v2',
			BEFFE_GRAPHQL_URL: 'https://beffe.web.gke.us-central1.gcp.prod.aspendental.com/',
			FORM_EVENTS_URL: 'https://api.teamtag.com/form/events',
			DENTRINO_API_URL: 'https://api.dentrino.ai/public-api/simulations/rehab',
			CLEARCHOICE_API_URL: 'https://www.clearchoice.com/api/v1/cc',
		},
		nonprod: {
			ENTERPRISE_API_GRAPHQL: 'https://apistg.aspendental.com/websitegraphql/graphql/',
			REVSPRING_API_URL: '/api/rssched/',
			CONTENTFUL_GRAPHQL_URL,
			ENTERPRISE_API_URL_V2: 'https://apistg.aspendental.com/api/web/v2',
			BEFFE_GRAPHQL_URL: 'https://beffe.web.gke.us-central1.gcp.stage.aspendental.com/',
			FORM_EVENTS_URL: 'https://aspen-userevents.gcp.stage.aspendental.com/form/events',
			DENTRINO_API_URL: 'https://api.e91efc7.dentrino.ai/public-api/simulations/rehab',
			CLEARCHOICE_API_URL: 'https://wwwstg.clearchoice.com/api/v1/cc',
		},
	},
	features: {
		redirects: [],
		rewrite: {},
		favIcons: {},
		sitemap: {
			additionalSitemapIndexes: [
				'https://www.aspendental.com/dentist/sitemap-index.xml',
				'https://www.aspendental.com/schedule-an-apointment/sitemap-index.xml',
				'https://www.aspendental.com/search/sitemap-index.xml',
			],
		},
		analytics: {
			enabled: true,
			inline: false,
			active: false,
			vwoAccountId: '613301',
			gtmId: 'GTM-NTW7353',
			facebookPixel: false,
			iSpotPixel: false,
		},
		monitoring: {
			bugSnagApiKey: process.env.BUGSNAG_API_KEY || '575c3f1095887b41f64a344c480c1923',
		},
		chat: {
			enabled: true,
			chatBotURL:
				process.env.NODE_ENV === 'production'
					? 'https://aspendental2.amelia.com/Amelia/ui/aspencallcenter/chat?embed=iframe&domainCode=aspencallcenter'
					: 'https://aspendental2.dev.amelia.com/Amelia/ui/aspencallcenter/chat?embed=iframe&domainCode=aspencallcenter',
			isIconButton: false,
		},
		notFoundPage: {
			desktopBackgroundImage:
				'https://images.ctfassets.net/m8zwsu9tyucg/AxWVBlTbpeJHO04OOM3SR/4e3ed05d72259d8aeb71d6c6f4bcafdf/WellNow404.png?q=90&fit=fill&f=face&fm=webp',
			mobileBackgroundImage:
				'https://images.ctfassets.net/m8zwsu9tyucg/4NGVlzvau9wEqmZd5h1pBt/0bd600d7d7c0f569d31fa1848b631806/WellNow404_M.jpg?q=90&fit=fill&f=face&fm=webp',
		},
		livePreview: {
			local: 'https://local.aspendental.com:8443',
			stage: 'https://wwwstg.aspendental.com',
			prod: 'https://www.aspendental.com',
		},
		footer: {
			lite: false,
			alt: false,
			withSimpleLayout: false,
		},
		blogs: {
			root: '/dental-care-resources/',
		},
		migration: {
			paths: ['dental-care-resources'],
		},
		otherApps: {
			facilityDetailsBasePath: 'dentist',
			schedulingPath: 'schedule-an-appointment',
		},
		providers: {
			root: '/providers/',
		},
		facilityDetailsDefaultId: 'Default_AD',
		schema: {},
	},
	featureFlags: {
		featureList: [],
		onPageScheduling: false,
		onPageSchedulingLocations: ['Brockport'],
		salesForceEvergageScript: 'Off',
		hideSearchWithLocationCards: false,
		hideOdpHeaderLinks: false,
		providerHeroTheme: 'dark',
		showHeaderWithLocationNav: false,
		showMobileHeaderLocationBar: true,
		defaultToCdn: false,
		useFacilityBeffeDataSource: false,
		showProviderPageCtaInMeetOurStaff: false,
		loadFacilityReviewsFromBeffe: false,
		showReadMoreForBioInMeetOurStaff: false,
		transitionallyUseBeffe: true,
		useInlineOneTrust: false,
	},
	schemaUrl: '',
	contentfulAppId: 'AspenDental - Marketing',
	themeId: 'aspendental',
	bffBrandType: BffBrandType.AspenDental,
	googleMapBusinessQuery: 'https://www.google.com/maps/search/?api=1&query=Aspen Dental, [address]',
};

export default config;
