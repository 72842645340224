import { createContext } from 'react';

import { useSafeContext } from '@aspendental/shared-utils/react';

import { TCity, TState, TOfficeInfo, TReviews } from '@/types';

const OfficeLocationContext = createContext({
	states: [] as Array<TState>,
	cities: [] as Array<TCity>,
	officeInfo: {} as TOfficeInfo,
	reviews: {} as TReviews,
});
OfficeLocationContext.displayName = 'OfficeLocationContext';

interface IOfficeLocationContextProvider {
	states?: Array<TState>;
	cities?: Array<TCity>;
	officeInfo?: TOfficeInfo;
	reviews?: TReviews;
	children: React.ReactNode;
}

export function OfficeLocationContextProvider({
	children,
	states = [],
	cities = [],
	officeInfo = {} as TOfficeInfo,
	reviews = {} as TReviews,
}: IOfficeLocationContextProvider) {
	return (
		<OfficeLocationContext.Provider value={{ states, cities, officeInfo, reviews }}>
			{children}
		</OfficeLocationContext.Provider>
	);
}

export function useOfficeLocationContext() {
	return useSafeContext(OfficeLocationContext);
}
