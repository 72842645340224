import { SxProps, Theme } from '@mui/material';

import { placeHolderValues } from './Table.utils';

const BORDER_STYLE = `0.063rem solid`;

const containerWrapperStyles = (containerSx: SxProps) => ({
	maxWidth: 'lg',
	paddingX: {
		xs: '1.5rem',
		md: 'none',
	},
	...containerSx,
});

const defaultVariantTableStyles = (border: boolean | null, mobileWidth: boolean) => {
	return {
		border: border && !mobileWidth ? BORDER_STYLE : 'none',
		borderColor: 'neutrals.disabled',
		borderCollapse: mobileWidth ? 'separate' : 'collapse',
		borderSpacing: mobileWidth ? '0 0.5rem' : 'none',
	};
};

const defaultVariantTableRowStyles = (border: boolean | null) => {
	return {
		border: border ? BORDER_STYLE : 'none',
		borderColor: 'neutrals.disabled',
		borderCollapse: 'collapse',
	};
};

const defaultVariantTableCellStyles = (
	placeholders: { [key: string]: boolean },
	border: boolean | null,
	theme: Theme
) => {
	return {
		color: 'text.primary',
		backgroundColor: placeholders[placeHolderValues.HIGHLIGHTED] ? theme.palette.backgrounds.light : '',
		border: border ? BORDER_STYLE : 'none',
		borderColor: 'neutrals.disabled',
		borderCollapse: 'collapse',
	};
};

const defaultVariantTableHeaderCellStyles = (
	placeholders: { [key: string]: boolean },
	border: boolean | null,
	theme: Theme,
	useBackground: boolean
) => {
	const backgroundColor = placeholders[placeHolderValues.HIGHLIGHTED]
		? theme.palette.backgrounds.light
		: theme.palette.backgrounds?.neutral;

	return {
		fontWeight: 'bold',
		backgroundColor: useBackground ? backgroundColor : '',
		border: border ? BORDER_STYLE : 'none',
		borderColor: 'neutrals.disabled',
		borderCollapse: 'collapse',
	};
};

const compareVariantTRBoxStyles = {
	position: 'absolute',
	top: '-1rem',
	left: '1rem',
	paddingTop: '1.875rem',
	borderBottom: '0.063rem solid rgb(167, 173, 176)',
	width: '100%',
};

const compareVariantTCGridStyles = (mobileWidth: boolean) => {
	return {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '1.875rem',
		paddingTop: '3rem !important',
		width: mobileWidth ? '15.063rem' : '100%',
	};
};

const compareVariantTHCellGrid = {
	display: 'flex',
	paddingLeft: '0.5rem !important',
};

const compareVariantTHCellGridBox = (theme: Theme) => {
	return {
		border: 'none',
		padding: '0.563rem 0.75rem !important',
		background: theme.palette.backgrounds?.neutral,
		boxShadow: 'none',
		marginBottom: '1.625rem',
		width: '100%',
	};
};

const compareVariantTHCellGridParentBox = {
	margin: '0 0 0.5rem',
	display: 'flex',
	justifyContent: 'center',
};

const compareVariantTHCellGridNestedBox = (theme: Theme) => {
	return {
		borderRadius: '50%',
		backgroundColor: theme.palette.backgrounds?.white,
		display: 'flex',
		justifyContent: 'center',
		padding: '0.313rem 0.438rem',
		width: '12.18rem',
		height: '12.18rem',
	};
};

const mottoVariantTableStyles = {
	width: { xs: '30.875rem', sm: '100%', marginTop: '2.25rem' },
	borderCollapse: 'separate',
};

const mottoVariantTRStickyColumnStyles = (theme: Theme) => {
	return {
		'& td:first-of-type': {
			position: 'sticky',
			width: { xs: '6.625rem', md: '16.875rem' },
			maxWidth: { xs: '6.625rem', md: '16.875rem' },
			textAlign: 'left',
			padding: { xs: '0.875rem 0.625rem 0.875rem 0', md: '0.875rem 0.625rem' },
			zIndex: 1,
			left: 0,
			backgroundColor: 'white',
			...theme.typography.bodyLargeBold,
		},
	};
};

const mottoVariantTRDefaultColumnStyles = (theme: Theme) => {
	return {
		position: 'relative',
		'& td': {
			width: { xs: '6.625rem', md: '11rem' },
			maxWidth: { xs: '6.625rem', md: '11rem' },
			padding: '0.875rem 0.625rem',
			borderBottom: `0.063rem solid ${theme.palette.neutrals?.primary}`,
			...theme.typography.bodyLargeBook,
		},
		'& td span': {
			fontSize: { xs: '0.938rem', md: '1.25rem' },
			lineHeight: { xs: '1.031rem', md: '2rem' },
		},
		'&:first-of-type td span': {
			fontSize: '0.875rem',
			lineHeight: '1.05rem',
		},
		'&:last-of-type td': {
			display: 'none',
		},
	};
};

const compareVariantTHCellStyles = (placeholders: { [key: string]: boolean }, theme: Theme) => {
	return {
		color: 'text.secondary',
		background: placeholders[placeHolderValues.HIGHLIGHTED] ? theme.palette.backgrounds?.light : '',
		'& svg': {
			filter: placeholders[placeHolderValues.HIGHLIGHTED] ? 'brightness(1) invert(0)' : 'brightness(1)',
		},
	};
};

const compareVariantTHCellImageStyles = {
	height: '1.25rem',
	width: '4.813rem',
	display: 'inline-block',
};

const checkedIconBoxStyles = {
	height: '1.5rem',
	width: '1.5rem',
	display: 'inline-block',
};

const xMarkIconBoxStyles = {
	height: '1.5rem',
	width: '1.5rem',
	display: 'inline-block',
	'& img': {
		height: '1.5rem',
		width: '1.5rem',
	},
	marginTop: '0.5rem',
};

export {
	BORDER_STYLE,
	containerWrapperStyles,
	defaultVariantTableStyles,
	defaultVariantTableRowStyles,
	defaultVariantTableCellStyles,
	defaultVariantTableHeaderCellStyles,
	compareVariantTRBoxStyles,
	compareVariantTCGridStyles,
	compareVariantTHCellGrid,
	compareVariantTHCellGridBox,
	compareVariantTHCellGridParentBox,
	compareVariantTHCellGridNestedBox,
	mottoVariantTableStyles,
	mottoVariantTRStickyColumnStyles,
	mottoVariantTRDefaultColumnStyles,
	compareVariantTHCellStyles,
	compareVariantTHCellImageStyles,
	checkedIconBoxStyles,
	xMarkIconBoxStyles,
};
