import {
	aspenDentalTheme,
	azPetVetTheme,
	chaptertheme,
	mottoTheme,
	teamTAGTheme,
	wellNowTheme,
	clearChoiceTheme,
	lovetTheme,
	livWellNow,
} from '@aspendental/themes';

import { TAGBrand } from '../../src/types';

export function resolveBrandThemeConfig(branding: TAGBrand) {
	if (branding === TAGBrand.AspenDental) {
		return aspenDentalTheme;
	} else if (branding === TAGBrand.Motto) {
		return mottoTheme;
	} else if (branding === TAGBrand.WellNow || branding === TAGBrand.YouWellNow) {
		return wellNowTheme;
	} else if (branding === TAGBrand.LivWellNow) {
		return livWellNow;
	} else if (branding === TAGBrand.TeamTAG) {
		return teamTAGTheme;
	} else if (branding === TAGBrand.AZPetVet) {
		return azPetVetTheme;
	} else if (branding === TAGBrand.Chapter) {
		return chaptertheme;
	} else if (branding === TAGBrand.ClearChoice) {
		return clearChoiceTheme;
	} else if (branding === TAGBrand.Lovet) {
		return lovetTheme;
	}

	return aspenDentalTheme;
}
