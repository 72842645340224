import { Theme } from '@mui/material';

export const getWrapperStyles = (theme: Theme) => ({
	backgroundColor: 'neutrals.white',
	display: 'flex',
	justifyContent: 'center',
	boxShadow: 'none',
	flexDirection: 'column',
	color: 'text.primary',
	borderBottom: '1px solid',
	borderColor: 'neutrals.primary',
	flexBasis: 1,
	transition: 'top 0.3s',
	[theme.breakpoints.up('md')]: {
		display: 'none',
	},
});

export const toolbarHeaderStyles = {
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	paddingRight: '.75rem',
	paddingLeft: '1rem', // the hamburger icon has 14px of paddingX so adding 2px here makes it even on both sides
	height: '4.5rem',
};

export const burgerAndLogoWrapperStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
};

export const iconStyles = {
	padding: 0,
};

export const logoStyles = {
	cursor: 'pointer',
	'&:hover': { opacity: 0.8 },
	maxWidth: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};
