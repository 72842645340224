import { ReactIntegration, getWebInstrumentations, initializeFaro } from '@grafana/faro-react';

export const initGrafanaFaro = (apiKey: string) => {
	return initializeFaro({
		url: `https://faro-collector-prod-us-central-0.grafana.net/collect/${apiKey}`,

		app: {
			name: process.env.SERVICE_NAME,
			version: process.env.APP_VERSION,
			environment: process.env.ENVIRONMENT_NAME,
		},

		instrumentations: [
			// load the mandatory web instrumentation
			...getWebInstrumentations({
				captureConsole: true,
			}),

			new ReactIntegration({
				// In the future, we may choose to integrate with React router instrumentation to
				// get deeper metrics on matched routes, navigation types, etc.
				// Next/router doesn't seem to be supported which won't give us route metrics.
				//
				// Reference: https://github.com/grafana/faro-web-sdk/tree/main/packages/react
				//
				// router: {}
			}),
		],
	});
};
