import { Box, Typography } from '@mui/material';

import TAGLink from '@/components/TAGLink';
import { AdFooterFooterPolicyGroupCollection, Maybe, NavigationLink, Page, Scalars } from '@/types/generated';

import { resolveLinkTitle, resolveLinkUrl } from '../FooterLite.helpers';

import { copyrightStyles, getPolicyLinksStyles } from './FooterLitePolicy.styles';

interface IFooterLitePolicyProps {
	isSmallDevice: boolean;
	policyGroup?: Maybe<AdFooterFooterPolicyGroupCollection>;
	copyright?: Maybe<Scalars['String']>;
}
const FooterLitePolicy = ({ isSmallDevice, policyGroup, copyright }: IFooterLitePolicyProps) => {
	const PolicyLinks = () => {
		if (!policyGroup) return null;
		const groupItems = policyGroup?.items;

		if (!groupItems) return null;
		return (
			<Box
				data-test-id="section_footerlite_policylinks"
				display="flex"
				gap={2}
				justifyContent="start"
				alignItems="start"
				sx={getPolicyLinksStyles}
			>
				{groupItems &&
					groupItems.map(
						(link: Maybe<NavigationLink | Page> | undefined) =>
							link && (
								<TAGLink
									key={link.sys.id}
									href={resolveLinkUrl(link)}
									openInNewTab={(link as NavigationLink)?.openInNewTab || false}
									dataTestId={`link_for_group_${link.sys.id}`}
									noLinkStyle
								>
									<Typography
										color="text.interactive"
										variant="lowestLevelBook"
										title={resolveLinkTitle(link)}
									>
										{resolveLinkTitle(link)}
									</Typography>
								</TAGLink>
							)
					)}
			</Box>
		);
	};
	return (
		<Box
			data-test-id="section_footerlite_policy"
			flexDirection={isSmallDevice ? 'column' : 'row'}
			display={'flex'}
			justifyContent={'space-between'}
			py={2}
			gap={2}
		>
			<PolicyLinks />
			<Typography
				variant="lowestLevelBook"
				color="text.primary"
				sx={copyrightStyles(isSmallDevice)}
				data-test-id="text_footerlite_copyright"
			>
				{copyright || ''}
			</Typography>
		</Box>
	);
};

export default FooterLitePolicy;
