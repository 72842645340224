import { useState, useEffect, useRef } from 'react';

import Hamburger from 'hamburger-react';
import { AppBar, Box, IconButton, Toolbar, useTheme } from '@mui/material';

import { App, Maybe } from '@/types/generated';
import ImageWrapper from '@/components/ImageWrapper';
import TAGLink from '@/components/TAGLink';

import LocationNavBar from '../LocationNavBar';
import NavigationBarItem from '../NavigationBar/NavigationBarItem';

import {
	getWrapperStyles,
	logoStyles,
	iconStyles,
	toolbarHeaderStyles,
	burgerAndLogoWrapperStyles,
} from './HeaderMobile.styles';
import MobileMenu from './MobileMenu';

interface IHeaderMobile {
	headerData: App['header'];
	logoUrl?: Maybe<string>;
	logoHref: string;
}

export default function HeaderMobile({ logoUrl, logoHref = '/', headerData }: IHeaderMobile) {
	const theme = useTheme();
	const appBarRef = useRef<HTMLDivElement>(null);

	const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

	useEffect(() => {
		if (isNavMenuOpen) {
			document.documentElement.style.overflowY = 'hidden';
		} else {
			document.documentElement.style.overflowY = 'inherit';
		}
	}, [isNavMenuOpen]);

	return (
		<>
			<AppBar sx={getWrapperStyles(theme)} ref={appBarRef} data-test-id="header_with_location_nav_mobile">
				<Toolbar disableGutters sx={toolbarHeaderStyles} data-test-id="header_with_location_nav_mobile_actions">
					<Box sx={burgerAndLogoWrapperStyles}>
						<TAGLink href={logoHref}>
							<ImageWrapper
								src={logoUrl}
								width="116px" // todo - add prop to set width from contentful
								height="auto"
								sx={logoStyles}
								data-test-id="img_logo_mobile"
								alt="brand logo"
							/>
						</TAGLink>
						<IconButton sx={iconStyles} data-test-id="header_with_location_nav_burger_button" tabIndex={-1}>
							<Hamburger
								size={24}
								toggled={isNavMenuOpen}
								toggle={setIsNavMenuOpen}
								color={theme.palette.text.interactive}
								label="open menu"
							/>
						</IconButton>
					</Box>
				</Toolbar>
				<LocationNavBar
					schedulingButtonText={headerData?.schedulingButtonText}
					schedulingButtonTextMobile={headerData?.schedulingButtonTextMobile}
					locationBarFindOfficeUrl={headerData?.locationBarFindOfficeUrl}
					isMobile
				/>
				<div ref={appBarRef}></div>
			</AppBar>
			<MobileMenu appBarRef={appBarRef} onClose={() => setIsNavMenuOpen(false)} open={isNavMenuOpen}>
				{headerData?.navigationLinksCollection?.items.map(
					(item, index) =>
						item && (
							<NavigationBarItem
								key={item.sys.id}
								item={item}
								itemIndex={index}
								isMobile
								isUtilityNavigation
							/>
						)
				)}
				{headerData?.secondaryNavigationLinksCollection?.items.map(
					(item, index) =>
						item && (
							<NavigationBarItem
								key={item.sys.id}
								item={item}
								itemIndex={index}
								isMobile
								isUtilityNavigation
							/>
						)
				)}
			</MobileMenu>
		</>
	);
}
