import { FacilityFieldsFragment } from '@/graphql/__generated/sdk';
import { BrandThemeConfig } from '@/types';
import { BffFacility, FacilityBrand, BffBrandType } from '@/types/generated';

import { officePathByBrand } from './officeURLByBrand/officeURLByBrand';

export const getOdpUrl = (
	address: FacilityFieldsFragment['address'] | BffFacility['address'],
	config: BrandThemeConfig
) => {
	const facilityUrl = officePathByBrand(config.themeId, {
		stateStrOptions: {
			stateAbbreviation: address?.stateCode || '',
			state: '',
			city: address?.city || '',
		},
		cityAddressOptions: {
			city: address?.city || undefined,
			address: address?.address1 || undefined,
			zip: address?.zipCode || undefined,
		},
	});

	const facilityBasePath = config.features.otherApps.facilityDetailsBasePath;

	return globalThis.location?.origin && `${globalThis.location.origin}/${facilityBasePath}/${facilityUrl}/`;
};

const getOdpLinks = (odpUrl: string, brand: FacilityBrand | BffBrandType) => {
	switch (brand) {
		case FacilityBrand.AspenDental:
			return [
				{ id: 1, path: odpUrl, title: 'Office Details' },
				{ id: 2, path: `${odpUrl}#dentists`, title: 'Dentists' },
				{ id: 3, path: `${odpUrl}#our-services`, title: 'Services' },
				{ id: 4, path: `${odpUrl}#pricing`, title: 'Pricing' },
				{ id: 5, path: `${odpUrl}#insurance-financing`, title: 'Insurance & financing' },
			];
		case BffBrandType.AzPet:
			return [
				{ id: 1, path: `${odpUrl}#location-services`, title: 'Location Services' },
				{ id: 2, path: `${odpUrl}#meet-our-doctors`, title: 'Doctors' },
				{ id: 3, path: `${odpUrl}#order-prescriptions-online`, title: 'Pharmacy' },
			];
		case BffBrandType.Lovet:
			return [
				{ id: 1, path: `${odpUrl}#location-services`, title: 'Location Services' },
				{ id: 2, path: `${odpUrl}#meet-our-doctors`, title: 'Doctors' },
			];
		default:
			return [{ id: 1, path: odpUrl, title: 'Office Details' }];
	}
};

export function generateODPLinks(office: FacilityFieldsFragment, config: BrandThemeConfig) {
	const odpUrl = getOdpUrl(office.address, config);

	const odpLinks = getOdpLinks(odpUrl, office.brand);

	return odpLinks;
}
