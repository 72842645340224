export const dialogWrapperStyles = {
	'&.MuiModal-root': {
		'.MuiDialog-container': {
			'.MuiPaper-root': {
				borderRadius: '0.5rem',
				position: 'relative',
				'#swipeable > .react-swipeable-view-container > div': {
					'::-webkit-scrollbar': { display: 'none' },
				},
			},
		},
	},
};

export const iconStyles = {
	position: 'absolute',
	top: '0.75rem',
	right: '0.3rem',
	zIndex: '1',
};

export const dialogContentStyles = {
	webkitMaskImage: 'linear-gradient(to bottom, black 90%, transparent 100%)',
	maskImage: 'linear-gradient(to bottom, black 90%, transparent 100%)',
	overflowY: 'hide',
};

export const dialogActionsStyles = {
	backgroundColor: 'backgrounds.neutral',
};
