export const camelCaseToSnake = (val: string) => {
	return val.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const objectKeyToSnake = (obj: Record<string, unknown>) => {
	const newObj: Record<string, unknown> = {};

	for (const key in obj) {
		newObj[camelCaseToSnake(key)] = obj[key];
	}

	return newObj;
};
