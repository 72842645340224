import { useState, ReactNode } from 'react';

import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CloseIcon from '@mui/icons-material/Close';

import TAGTextFieldInput from '@/components/TAGTextFieldInput';
import { Header, Maybe } from '@/types/generated';
import Drawer from '@/components/Drawer/Drawer';
import TAGLink from '@/components/TAGLink';
import TAGButton from '@/components/TAGButton';
import { useAppContext } from '@/context';
import { clickContext } from '@/utils';

import {
	scheduleButtonStyles,
	getSearchInputWrapperStyles,
	getSearchInputStyles,
	popupSchedulingStyles,
	searchIconStyles,
} from './HeaderSearch.styles';
import { getSearchUrl } from './HeaderSearch.helpers';

interface IHeaderSearch {
	locationBarFindOfficeUrl?: Maybe<string>;
	hideFullSiteSearch?: Maybe<boolean>;
	profileLinkUrl?: Maybe<string>;
	showSchedulingButton?: boolean;
	schedulingButtonUrl?: Maybe<string>;
	schedulingButtonText?: Maybe<string>;
	schedulingNavigationLinksCollection?: Header['schedulingNavigationLinksCollection'];
	isSmallScreen?: boolean;
	showInlineNavigations?: () => void;
	usePopupScheduling?: Maybe<boolean>;
	isDarkHeader?: boolean;
	hidePrimaryActionButton: boolean;
}

interface IFullSiteSearch extends IHeaderSearch {
	handleSearchIconClick: () => void;
	showSearch?: boolean;
	showSchedulingButton: boolean;
	usePopupScheduling: boolean;
}

// TODO: this makes no sense, why full site search?
function FullSiteSearch({
	hideFullSiteSearch,
	profileLinkUrl,
	showSchedulingButton,
	schedulingButtonUrl,
	schedulingButtonText,
	schedulingNavigationLinksCollection,
	handleSearchIconClick,
	usePopupScheduling = false,
	isDarkHeader = false,
	hidePrimaryActionButton,
}: IFullSiteSearch) {
	const [open, setOpen] = useState<boolean>(false);
	const { config } = useAppContext();

	const SchedulingButton = () => {
		if (!usePopupScheduling) {
			return (
				<TAGButton
					href={schedulingButtonUrl || '/schedule-an-appointment'}
					size="S"
					variant="primaryAccent1"
					sx={scheduleButtonStyles}
					dataTestId="button_schedule_desktop"
					analyticsContext={clickContext({ brand: config.name, type: 'scheduleClick' })}
				>
					{schedulingButtonText || 'Schedule appointment'}
				</TAGButton>
			);
		}
		return (
			<TAGButton
				onClick={() => setOpen(true)}
				size="S"
				variant="primaryAccent1"
				sx={scheduleButtonStyles}
				dataTestId="button_schedule_menu_desktop"
				analyticsContext={clickContext({ brand: config.name, type: 'scheduleClick' })}
			>
				{schedulingButtonText || 'Schedule appointment'}
			</TAGButton>
		);
	};

	const PopUpSchedulingDrawer = () => {
		const toggleMenu = () => {
			setOpen(false);
		};
		return (
			<>
				<Drawer
					anchor="right"
					open={open}
					onClose={() => setOpen(false)}
					closeButton
					triggerComponent={<SchedulingButton />}
					sx={{ '.MuiPaper-root': { minWidth: '21rem' } }}
				>
					<Box display="flex" flexDirection="column" sx={popupSchedulingStyles.containerStyles}>
						<Typography variant="header3" sx={popupSchedulingStyles.headerStyles}>
							{schedulingButtonText}
						</Typography>

						{schedulingNavigationLinksCollection?.items.map((navItem, index) =>
							navItem ? (
								<Box key={index} sx={popupSchedulingStyles.navigationItemStyles}>
									<Typography
										variant="bodyLargeSemiBold"
										color="text.secondary"
										sx={popupSchedulingStyles.groupLinkLinkTextStyles}
									>
										{navItem.groupLink?.linkText}
									</Typography>
									{navItem.linksCollection?.items.map((link, index) => (
										<TAGLink
											key={index}
											onClick={toggleMenu}
											href={
												link?.isInternal
													? `${link?.pageData?.path as string}`
													: `${link?.externalUrl as string}`
											}
											noLinkStyle
										>
											<Typography variant="bodyLargeSemiBold" color="text.interactive">
												{link?.linkText}
											</Typography>
										</TAGLink>
									))}
								</Box>
							) : null
						)}
					</Box>
				</Drawer>
			</>
		);
	};

	const renderPrimaryActionButton = ({
		showSchedulingButton,
		usePopupScheduling,
	}: {
		showSchedulingButton: boolean;
		usePopupScheduling: boolean;
	}): ReactNode => {
		return showSchedulingButton && usePopupScheduling ? <PopUpSchedulingDrawer /> : <SchedulingButton />;
	};

	return (
		<>
			{!hideFullSiteSearch && (
				<IconButton
					onClick={handleSearchIconClick}
					data-test-id="button_search_icon"
					sx={{ color: isDarkHeader ? 'text.light' : 'text.interactive' }}
				>
					<SearchIcon />
				</IconButton>
			)}

			{profileLinkUrl && (
				<IconButton
					href={profileLinkUrl}
					data-test-id="button_profile_icon"
					sx={{ marginRight: '0.5rem', color: isDarkHeader ? 'text.light' : 'text.interactive' }}
				>
					<PersonOutlineIcon />
				</IconButton>
			)}

			{hidePrimaryActionButton ? null : renderPrimaryActionButton({ showSchedulingButton, usePopupScheduling })}
		</>
	);
}

const inputProps = { 'data-test-id': 'input_header_search' };

interface IInputSearch {
	showInlineNavigations?: () => void;
	setShowSearchInputField: (value: boolean) => void;
	locationBarFindOfficeUrl: IHeaderSearch['locationBarFindOfficeUrl'];
	isSmallScreen: boolean;
}

export function InputSearch({
	showInlineNavigations,
	setShowSearchInputField,
	locationBarFindOfficeUrl,
	isSmallScreen = false,
}: IInputSearch) {
	const [searchQuery, setSearchQuery] = useState('');

	const handleSearchSubmit = () => {
		const trimmedSearchQuery = searchQuery.trim();
		if (locationBarFindOfficeUrl && trimmedSearchQuery) {
			globalThis.location.href = getSearchUrl(locationBarFindOfficeUrl, trimmedSearchQuery);
		}
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			return handleSearchSubmit();
		}
	};

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.currentTarget.value);
	};

	const handleInputIconClick = () => {
		if (isSmallScreen) {
			handleSearchSubmit();
		} else {
			setShowSearchInputField(false);
			setSearchQuery('');
			showInlineNavigations && showInlineNavigations();
		}
	};

	return (
		<TAGTextFieldInput
			fieldPlaceHolder="Location Search"
			onChange={handleSearchInputChange}
			onKeyDown={handleKeyPress}
			value={searchQuery}
			wrapperSx={getSearchInputWrapperStyles(isSmallScreen)}
			inputSx={getSearchInputStyles(isSmallScreen)}
			inputProps={inputProps}
			endAdornment={
				<InputAdornment position="end">
					<IconButton onClick={handleInputIconClick} data-test-id="button_input_icon">
						{isSmallScreen ? (
							<SearchIcon data-test-id="icon_search" sx={searchIconStyles} />
						) : (
							<CloseIcon data-test-id="icon_close" />
						)}
					</IconButton>
				</InputAdornment>
			}
		/>
	);
}

export default function HeaderSearch({
	locationBarFindOfficeUrl,
	hideFullSiteSearch,
	profileLinkUrl,
	showSchedulingButton,
	schedulingButtonUrl,
	schedulingButtonText,
	schedulingNavigationLinksCollection,
	isSmallScreen = false,
	showInlineNavigations,
	usePopupScheduling = false,
	isDarkHeader,
	hidePrimaryActionButton,
}: IHeaderSearch) {
	const [showSearchInputField, setShowSearchInputField] = useState(isSmallScreen);

	return showSearchInputField ? (
		<InputSearch
			isSmallScreen={isSmallScreen}
			setShowSearchInputField={setShowSearchInputField}
			locationBarFindOfficeUrl={locationBarFindOfficeUrl}
			showInlineNavigations={showInlineNavigations}
		/>
	) : (
		<FullSiteSearch
			hideFullSiteSearch={hideFullSiteSearch}
			profileLinkUrl={profileLinkUrl}
			showSchedulingButton={showSchedulingButton ?? false}
			schedulingButtonUrl={schedulingButtonUrl}
			schedulingButtonText={schedulingButtonText}
			schedulingNavigationLinksCollection={schedulingNavigationLinksCollection}
			handleSearchIconClick={() => {
				setShowSearchInputField(true);
			}}
			usePopupScheduling={usePopupScheduling ?? false}
			isDarkHeader={isDarkHeader}
			hidePrimaryActionButton={hidePrimaryActionButton}
		/>
	);
}
