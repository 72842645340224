import { Grid } from '@mui/material';

import { Asset, Maybe } from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { useAppContext } from '@/context';
import { clickContext } from '@/utils';

import { getFooterLiteLogoStyle } from './FooterLiteLogo.styles';

interface IFooterLiteLogoProps {
	logo?: Maybe<Asset>;
	isSmallDevice: boolean;
}

const FooterLiteLogo = ({ logo, isSmallDevice }: IFooterLiteLogoProps) => {
	const { config } = useAppContext();

	if (!logo) return null;

	return (
		<Grid py={1} data-test-id="footer_lite_logo">
			<TAGLink
				href={'/'}
				key={logo.sys.id}
				linkSx={{ lineHeight: 0 }}
				dataTestId={`link_logo_${logo.sys.id}`}
				analyticsContext={clickContext({ brand: config.name, type: 'footerLogoClick' })}
			>
				<img
					src={logo?.url as string}
					alt={logo?.description || ''}
					style={getFooterLiteLogoStyle(isSmallDevice)}
				/>
			</TAGLink>
		</Grid>
	);
};

export default FooterLiteLogo;
