export const getWrapperStyles = (isSmallScreen: boolean, logoPosition: string) => ({
	display: 'flex',
	flexDirection: isSmallScreen ? 'column' : 'row',
	justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
	height: '100%',
	width: '100%',
	pl: isSmallScreen ? 0 : { xs: '5%', lg: logoPosition === 'left' ? '0.5rem' : '12%' },
	pr: isSmallScreen ? 0 : { xs: '5%', lg: logoPosition === 'left' ? '0.5rem' : '12%' },
});

export const topNavigationStyles = {
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-end',
	pr: '5%',
};
