// Bugsnag
export const BUGSNAG_NOTIFY_URL = 'https://bugsnag-notify.aspendental.com';
export const BUGSNAG_SESSIONS_URL = 'https://bugsnag-sessions.aspendental.com';
export const BUGSNAG_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string;

export enum TagTheme {
	AspenDental = 'aspendental',
	AzPetVet = 'azPetVet',
	TeamTag = 'teamTag',
	WellNow = 'wellnow',
	Chapter = 'chapter',
	ClearChoice = 'clearchoice',
	YouWellNow = 'youwellnow',
	LivWellNow = 'livwellnow',
	Lovet = 'lovet',
}
// Add a few options for Cache Control
// CACHE_CONTROL_NO_CACHE: Used for API get requests that should not be cached
// CACHE_CONTROL_SHORT_TTL: Used for HTML Templates that should be cached for a short period of time
//                          Caching Notes:
//                           - 10 seconds for the browser, 60 seconds for the CDN, and 60 seconds for the CDN to revalidate
//                           - 0 seconds for the CDN to serve stale content if the origin is down
// Refer to: https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Expiration.html
export const CACHE_CONTROL_NO_CACHE = 'no-cache, max-age=0, must-revalidate';
export const CACHE_CONTROL_SHORT_TTL = 'public, max-age=10, s-maxage=60, stale-while-revalidate=60, stale-if-error=0';
