const getMinHeaderHeightStyles = (
	showMobileHeaderLocationBar?: boolean | null,
	isWithNavMenu?: boolean,
	showHeaderWithLocationNav?: boolean
) => {
	if (isWithNavMenu) {
		return { minHeight: { xs: showMobileHeaderLocationBar ? '6rem' : '4rem', md: '8.5rem' } };
	} else if (showHeaderWithLocationNav) {
		return { minHeight: { xs: '7.25rem', md: '8.75rem' } };
	} else {
		return { minHeight: { xs: showMobileHeaderLocationBar ? '6rem' : '4rem', md: '6.875rem' } };
	}
};

export const getHeaderWrapperStyles = (
	showMobileHeaderLocationBar?: boolean | null,
	isWithNavMenu?: boolean,
	showHeaderWithLocationNav?: boolean
) => ({
	width: '100vw',
	...getMinHeaderHeightStyles(showMobileHeaderLocationBar, isWithNavMenu, showHeaderWithLocationNav),
	backgroundColor: 'backgrounds.white',
});
