import { resolveTemplatePadding } from '@/utils/resolveTemplatePadding';
import { DisableTemplatePaddingOn } from '@/types';

export const useTemplateContainerFullWidthStyles = (
	disablePaddingOn?: DisableTemplatePaddingOn,
	isMediumScreen?: boolean,
	disabledGutters?: boolean
) => {
	let disabledPadding;
	let gutters;

	if (disablePaddingOn) {
		disabledPadding = resolveTemplatePadding(disablePaddingOn, isMediumScreen as boolean);
	} else {
		disabledPadding = resolveTemplatePadding(null, isMediumScreen as boolean);
	}

	if (disabledGutters) {
		gutters = { px: 0 };
	} else {
		gutters = { px: { xs: 3, md: '2%', lg: '5%' } };
	}

	return {
		maxWidth: '120rem',
		...gutters,
		...disabledPadding,
	};
};
