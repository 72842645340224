import { SxProps } from '@mui/material';

export const getIntrinsicRatioWrapperStyles = (
	paddingBottomHack: string,
	placeholderColor: string,
	sxOverride: SxProps
) => ({
	position: { xs: 'relative', md: 'static' },
	paddingBottom: paddingBottomHack,
	height: '100%',
	backgroundColor: placeholderColor,
	...sxOverride,
});

const elementToStretch: SxProps = {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
};

export const getImageWrapperStyles = (override: SxProps) => ({
	...elementToStretch,
	...override,
});
