export const searchWrapStyles = {
	width: '100%',
	maxWidth: '20rem',
};

export const getSearchInputStyles = (isMobile?: boolean) => ({
	padding: '0 1rem',
	borderRadius: 25,
	backgroundColor: 'backgrounds.white',
	color: 'text.secondary',
	height: isMobile ? '2rem' : '2.5rem',
	'& fieldset': {
		borderColor: 'transparent',
	},
	'&.MuiInputBase-root input:focus': {
		outline: 'transparent',
	},
	'&.MuiOutlinedInput-root.Mui-focused fieldset': {
		borderColor: 'transparent',
	},
});

export const getDropMenuStyles = (isMobile?: boolean) => ({
	'.MuiPaper-root': {
		width: '100%',
		backgroundColor: 'neutrals.white',
		boxShadow: 'none',
		borderRadius: '0 0 0.25rem 0.25rem',
		marginTop: isMobile ? '0.063rem' : '0.7rem',
		maxWidth: isMobile ? '100%' : '25rem',
	},
});

export const menuItemStyles = {
	minHeight: 'unset',
};

export const menuLinkItemStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	textDecoration: 'none',
};

export const secondaryMenuButtonStyles = {
	margin: '1rem 1rem 0.5rem',
	display: 'flex',
	fontWeight: 600,
};
