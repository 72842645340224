import { DentrinoApiResult } from '@/components/DentrinoVirtualTryOn/DentrinoVirtualTryOn.helpers';
import { logError } from '@/utils/miscUtils';

export default async function getResultsFromDentrinoAI(authToken: string, formData: FormData, url: string) {
	try {
		const res = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
			body: formData,
		});
		const data = (await res.json()) as DentrinoApiResult;
		return data;
	} catch (err) {
		if (err instanceof Error) {
			logError(`DENTRINO_ERROR_API_FAILURE: ${err.message}`);
		}
	}
}
