import { forwardRef } from 'react';

import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

import { getUrlType } from './TAGLink.helpers';
const baseAnchorStyles = {
	color: 'inherit',
	textDecoration: 'none',
	'&:hover': {
		opacity: 0.8,
	},
};

const Anchor = styled('a')({ ...baseAnchorStyles });

interface NextLinkComposedProps
	extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
		Omit<NextLinkProps, 'href' | 'as' | 'passHref' | 'onMouseEnter' | 'onClick' | 'onTouchStart'> {
	to: NextLinkProps['href'];
	linkAs?: NextLinkProps['as'];
	dataTestId?: string;
}

export const NextLinkComposed = forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed(
	props,
	ref
) {
	const {
		to,
		linkAs,
		replace,
		scroll,
		shallow,
		prefetch,
		legacyBehavior = true,
		locale,
		children,
		dataTestId,
		...other
	} = props;

	return (
		<NextLink
			href={to}
			prefetch={prefetch}
			as={linkAs}
			replace={replace}
			scroll={scroll}
			shallow={shallow}
			passHref
			locale={locale}
			legacyBehavior={legacyBehavior}
		>
			<Anchor ref={ref} data-test-id={dataTestId || `anchor_next_link_composed_${to.toString()}`} {...other}>
				{children}
			</Anchor>
		</NextLink>
	);
});

export type LinkProps = {
	activeClassName?: string;
	as?: NextLinkProps['as'];
	href: NextLinkProps['href'];
	linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
	noLinkStyle?: boolean;
	openInNewTab?: boolean;
	linkSx?: MuiLinkProps['sx'];
	dataTestId?: string;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
	Omit<MuiLinkProps, 'href'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
	const {
		as,
		className,
		href,
		legacyBehavior,
		linkAs: linkAsProp,
		locale,
		noLinkStyle,
		prefetch,
		replace,
		role: _role, // Link don't have roles.
		scroll,
		shallow,
		openInNewTab,
		children,
		linkSx = {},
		dataTestId,
		...other
	} = props;

	const { isExternalUrl } = getUrlType(href.toString());
	if (typeof href === 'string' && isExternalUrl) {
		const target = isExternalUrl ? (openInNewTab ? '_blank' : '_self') : undefined;
		if (noLinkStyle) {
			return (
				<Anchor
					className={className}
					target={target}
					href={href}
					ref={ref}
					{...other}
					data-test-id={dataTestId || `anchor_external_no_link_style_${href}`}
				>
					{children}
				</Anchor>
			);
		}

		return (
			<MuiLink
				className={className}
				target={target}
				href={href}
				ref={ref}
				sx={{ ...linkSx }}
				{...other}
				data-test-id={dataTestId || `anchor_external_${href}`}
			>
				{children}
			</MuiLink>
		);
	}

	const linkAs = linkAsProp || as;
	const nextjsProps = {
		to: href,
		linkAs,
		replace,
		scroll,
		shallow,
		prefetch,
		legacyBehavior,
		locale,
	};

	if (noLinkStyle) {
		return (
			<NextLinkComposed className={className} ref={ref} dataTestId={dataTestId} {...nextjsProps} {...other}>
				{children}
			</NextLinkComposed>
		);
	}

	return (
		<MuiLink
			component={NextLinkComposed}
			className={className}
			ref={ref}
			sx={{ ...baseAnchorStyles, ...linkSx }}
			dataTestId={dataTestId}
			{...nextjsProps}
			{...other}
		>
			{children}
		</MuiLink>
	);
});
export const DISPLAY_NAME = 'NextLink';

Link.displayName = DISPLAY_NAME;

export default Link;
