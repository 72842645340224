import { ButtonProps } from '@mui/material';

import { ContentfulButtonSize } from '@/types';

export function resolveContentfulButtonSize(
	buttonSize?: ContentfulButtonSize,
	defaultSize?: ButtonProps['size']
): ButtonProps['size'] {
	switch (buttonSize) {
		case 'extraSmall':
			return 'XS';
		case 'small':
			return 'S';
		case 'medium':
			return 'M';
		case 'large':
			return 'L';
		default:
			return defaultSize || 'L';
	}
}
