import { ContentfulBackground } from '@/types';

export function reverseLookupContentfulBgColor(color: string): ContentfulBackground {
	switch (color) {
		case 'backgrounds.transparent':
		case 'backgrounds.white':
			return 'White';
		case 'backgrounds.dark':
			return 'Dark';
		case 'backgrounds.darkTwo':
			return 'Dark Two';
		case 'backgrounds.neutral':
			return 'Neutral';
		case 'backgrounds.neutralGradient':
			return 'Neutral Gradient';
		case 'backgrounds.darkGradient':
			return 'Dark Gradient';
		case 'backgrounds.light':
			return 'Light';
		case 'backgrounds.lightTwo':
			return 'Light Two';
		case 'backgrounds.lightThree':
			return 'Light Three';
		case 'backgrounds.lightGradient':
			return 'Light Gradient';
		case 'backgrounds.lightTwoGradient':
			return 'Light Two Gradient';
		case 'backgrounds.lightThreeGradient':
			return 'Light Three Gradient';
		case 'accent02.light':
			return 'Eucalyptus';
		case 'accent02.main':
			return 'Rose';
		case 'accent02.dark':
			return 'Saffron';
		default:
			return 'Neutral';
	}
}
