export const menuStyles = {
	'.MuiPaper-root': {
		width: '100%',
		maxWidth: '100%',
		height: '100%',
		borderRadius: 0,
		boxShadow: 'none',
		marginTop: '1.4rem',
		backgroundColor: 'primary.main',
	},
	'.MuiList-root': {
		display: 'flex',
		flexDirection: 'column',
		padding: '0.5rem 1rem',
		gap: '1rem',
		boxSizing: 'border-box',
		backgroundColor: 'white',
	},
};
