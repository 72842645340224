import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import router from 'next/router';

import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';

import { checkIconStyles, formControlLabelStyles } from './LanguageDrawerButton.styles';

interface ReasonForVisitButtonProps {
	label: string;
	icon: IconName;
	value: string;
	selected: boolean;
	openDrawer: boolean;
	setOpenDrawer: (bool: boolean) => void;
	handleDrawerClose: () => void;
}

export default function ReasonForVisitButton({
	value,
	icon,
	label,
	selected,
	setOpenDrawer,
}: Readonly<ReasonForVisitButtonProps>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const fontVariant = isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook';

	const handleChange = () => {
		changeLanguage(value);
		setOpenDrawer(false);
	};

	const changeLanguage = (locale: string) => {
		// manual overwrite for paid media AD scheduling, impossible to use next router like the normal path
		if (window.location.href.includes('schedule-an-appt')) {
			const hardCodedEndpoint =
				window.location.origin + '/' + `schedule-an-appt/${locale}/` + window.location.search;
			window.location.href = hardCodedEndpoint;
		} else {
			void router.push(router.pathname, router.asPath, { locale });
		}
	};

	const Icon = ({ icon }: { icon: IconName }) => <TAGSvgIcon icon={icon} size={40} />;

	return (
		<Button
			id={label}
			value={value}
			startIcon={<Icon icon={icon} />}
			className="language-drawer-button"
			disableTouchRipple
			sx={formControlLabelStyles(selected)}
			onClick={handleChange}
			variant="secondaryDefault"
		>
			{selected ? <CheckCircleIcon sx={checkIconStyles} data-test-id="icon_selected" /> : null}
			<Typography variant={fontVariant} color="secondary.main">
				{label}
			</Typography>
		</Button>
	);
}
