import { AppBar, Toolbar, Box, Typography } from '@mui/material';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

import { Maybe } from '@/types';
import { App, NavigationLink, Page } from '@/types/generated';
import ImageWrapper from '@/components/ImageWrapper';
import TAGLink from '@/components/TAGLink';
import HeaderSearch from '@/components/Header/HeaderSearch';
import { clearChoiceConsts } from '@/constants';
import { useAppContext } from '@/context';
import { clickContext } from '@/utils';
import HeaderWithLocationNavDesktop from '@/components/HeaderWithLocationNav/HeaderDesktop';

import HeaderOfficeLocation from '../HeaderOfficeLocation';
import useDisappearingHeader from '../hooks/useDissappearingHeader';
import useStickyNavHeader from '../hooks/useStickyNavHeader';
import NavigationBar from '../NavigationBar';
import { getWrapperStyles, topNavigationStyles } from '../NavigationBar/NavigationBar.styles';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import { officeNameStyles } from '../HeaderOfficeLocation/HeaderOfficeDetails/HeaderOfficeDetails.styles';

import {
	headerStyles,
	logoStyles,
	navbarStyles,
	toolbarHeaderStyles,
	inlineNavigationStyles,
	getLogoContainerStyles,
	getNavMenuDesktopStyles,
	getPhoneIconStyles,
} from './HeaderDesktop.styles';

interface IHeaderDesktop {
	headerData: App['header'];
	inlineNavigation?: boolean;
	logoUrl?: Maybe<string>;
	logoPosition?: 'left' | 'center';
	logoHref: string;
	navigationBar?: boolean;
	withOfficeDetails?: boolean;
	topLinks?: boolean;
	isDarkHeader: boolean;
	hidePrimaryActionButton: boolean;
	hideLocation: boolean;
	showHeaderNavMenu?: boolean;
	locationNavigationLinks?: Array<NavigationLink | Page> | undefined;
	phoneNumber?: Maybe<NavigationLink>;
	withFixedHeader?: boolean;
	showLanguageToggle?: boolean;
	useHeaderLocationCards?: boolean | null;
}

export default function HeaderDesktop({
	logoUrl,
	logoPosition = 'left',
	logoHref = '/',
	navigationBar = false,
	headerData,
	inlineNavigation = true,
	withOfficeDetails,
	topLinks = true,
	isDarkHeader,
	hidePrimaryActionButton,
	hideLocation,
	showHeaderNavMenu = false,
	locationNavigationLinks,
	phoneNumber,
	withFixedHeader = false,
	showLanguageToggle,
	useHeaderLocationCards = false,
}: IHeaderDesktop) {
	const headerElement = useDisappearingHeader({
		hiddenPos: topLinks && showHeaderNavMenu ? '-184px' : showHeaderNavMenu ? '-136px' : '-112px',
		showingPos: '0',
		withFixedHeader,
	});
	const navElement = useStickyNavHeader({ scrollingPosition: '0' });
	const navMenuLinks = headerData?.navigationMenu?.headerMainNavLinksCollection;
	const { config } = useAppContext();
	const showHeaderWithLocationNav = config.featureFlags.showHeaderWithLocationNav;

	if (showHeaderWithLocationNav) {
		return <HeaderWithLocationNavDesktop headerData={headerData} logoHref={logoHref} logoUrl={logoUrl} />;
	}

	return showHeaderNavMenu ? (
		<AppBar
			ref={headerElement}
			sx={headerStyles(isDarkHeader, showHeaderNavMenu)}
			data-test-id="section_header_desktop_with_nav_menu"
		>
			{topLinks && (
				<NavigationBar
					navigationItems={headerData?.topNavigationLinksCollection}
					navigationSx={topNavigationStyles}
					isUtilityNavigation
					isDarkHeader={isDarkHeader}
				/>
			)}
			<Toolbar disableGutters sx={toolbarHeaderStyles(showHeaderNavMenu)}>
				<Box display="flex" justifyContent="flex-start" width={'100%'} gap={1}>
					{headerData && !hideLocation && (
						<HeaderOfficeLocation
							isLargeDevice
							headerData={headerData}
							withOfficeDetails={withOfficeDetails}
							isDarkHeader={isDarkHeader}
							locationNavigationLinks={locationNavigationLinks}
							useHeaderLocationCards={useHeaderLocationCards}
						/>
					)}
					{phoneNumber && (
						<Box
							component="section"
							display="flex"
							alignItems="center"
							data-test-id="phone_number_link_desktop"
							gap={0.5}
						>
							<CallOutlinedIcon
								sx={getPhoneIconStyles({ isDarkHeader })}
								data-test-id="phone_number_icon_desktop"
							/>
							<TAGLink
								noLinkStyle
								href={phoneNumber.externalUrl || '#'}
								analyticsContext={phoneNumber.analyticsContext}
							>
								<Typography
									variant="buttonLabel"
									color={isDarkHeader ? 'text.light' : 'text.interactive'}
									sx={{ ...officeNameStyles }}
								>
									{phoneNumber.linkText}
								</Typography>
							</TAGLink>
						</Box>
					)}
				</Box>
				<Box display="flex" justifyContent="flex-center" width={'100%'} flexShrink={'2'}>
					<TAGLink href={logoHref} analyticsContext={clickContext({ brand: config.name, type: 'logoClick' })}>
						<ImageWrapper
							src={logoUrl}
							width="200px"
							height="unset"
							sx={logoStyles}
							data-test-id="img_logo_desktop_with_nav_menu"
							alt="brand logo"
						/>
					</TAGLink>
				</Box>
				<Box display="flex" justifyContent="flex-end" width={'100%'}>
					<HeaderSearch
						profileLinkUrl={headerData?.profileLinkUrl}
						hideFullSiteSearch={headerData?.hideFullSiteSearch}
						showSchedulingButton
						schedulingButtonUrl={headerData?.schedulingButtonUrl}
						schedulingButtonText={headerData?.schedulingButtonText}
						schedulingNavigationLinksCollection={headerData?.schedulingNavigationLinksCollection}
						locationBarFindOfficeUrl={headerData?.locationBarFindOfficeUrl}
						usePopupScheduling={headerData?.usePopupScheduling}
						isDarkHeader={isDarkHeader}
						hidePrimaryActionButton={hidePrimaryActionButton}
					/>
				</Box>
			</Toolbar>
			<Box sx={getNavMenuDesktopStyles} data-test-id="desktop-header-nav-menu">
				<NavigationMenu mainNavItems={navMenuLinks} />
			</Box>
		</AppBar>
	) : (
		<>
			<AppBar ref={headerElement} sx={headerStyles(isDarkHeader, false)} data-test-id="section_header_desktop">
				{topLinks && (
					<NavigationBar
						navigationItems={headerData?.topNavigationLinksCollection}
						navigationSx={topNavigationStyles}
						isUtilityNavigation
						isDarkHeader={isDarkHeader}
					/>
				)}
				<Toolbar disableGutters sx={toolbarHeaderStyles(false)}>
					<Box display="flex" sx={getLogoContainerStyles(logoPosition)}>
						<Box
							display="flex"
							justifyContent="flex-start"
							width={logoPosition === 'left' ? 'fit-content' : '100%'}
						>
							{headerData && !hideLocation && (
								<HeaderOfficeLocation
									isLargeDevice
									headerData={headerData}
									withOfficeDetails={withOfficeDetails}
									locationNavigationLinks={locationNavigationLinks}
									isDarkHeader={isDarkHeader}
									useHeaderLocationCards={useHeaderLocationCards}
								/>
							)}
						</Box>

						<Box width="min-content">
							<TAGLink
								href={logoHref}
								analyticsContext={
									config.name === 'ClearChoice'
										? {
												...clearChoiceConsts.context,
												...clearChoiceConsts.logoClick,
										  }
										: null
								}
							>
								<ImageWrapper
									src={logoUrl}
									width="200px"
									height="unset"
									sx={logoStyles}
									data-test-id="img_logo_desktop"
									alt="brand logo"
								/>
							</TAGLink>
						</Box>
					</Box>

					<Box display="flex" sx={inlineNavigationStyles}>
						{inlineNavigation && (
							<NavigationBar
								navigationItems={headerData?.navigationLinksCollection}
								navigationSx={getWrapperStyles(false, logoPosition)}
								isDarkHeader={isDarkHeader}
								showLanguageToggle={showLanguageToggle}
							/>
						)}
						<Box display="flex" justifyContent="flex-end" width={logoPosition === 'left' ? 'none' : '100%'}>
							<HeaderSearch
								profileLinkUrl={headerData?.profileLinkUrl}
								hideFullSiteSearch={headerData?.hideFullSiteSearch}
								showSchedulingButton
								schedulingButtonUrl={headerData?.schedulingButtonUrl}
								schedulingButtonText={headerData?.schedulingButtonText}
								schedulingNavigationLinksCollection={headerData?.schedulingNavigationLinksCollection}
								locationBarFindOfficeUrl={headerData?.locationBarFindOfficeUrl}
								usePopupScheduling={headerData?.usePopupScheduling}
								isDarkHeader={isDarkHeader}
								hidePrimaryActionButton={hidePrimaryActionButton}
							/>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>

			{navigationBar && (
				<AppBar ref={navElement} sx={navbarStyles} data-test-id="section_navbar_desktop">
					<NavigationBar
						navigationItems={headerData?.navigationLinksCollection}
						isDarkHeader={isDarkHeader}
					/>
				</AppBar>
			)}
		</>
	);
}
