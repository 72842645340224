import { Box, useMediaQuery, useTheme } from '@mui/material';

import { App } from '@/types/generated';
import FooterAltLogo from '@/components/FooterAlt/FooterAltLogo';

import FooterAltGroups from './FooterAltGroups';
import { getWrapperStyles, getFooterStyles } from './FooterAlt.styles';

export interface IFooter {
	/**
	 * Application footer data, containing the information about the brand and the navigation links
	 *
	 * @type {App['footer']}
	 * @memberof IFooter
	 */
	footerData: App['footer'];
}

export default function FooterAlt({ footerData }: IFooter) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box component="footer" data-test-id="section_footer_alt" sx={getFooterStyles}>
			<Box maxWidth={isMobile ? 'sm' : 'none'} sx={getWrapperStyles({ isMobile: isMobile })}>
				<Box flex="1 1 auto" marginBottom={!isMobile ? '' : '2.5rem'} marginRight={isMobile ? '' : '4rem'}>
					<FooterAltLogo logo={footerData?.logo} isMobile={isMobile} />
				</Box>
				<Box flex="0 1 auto">
					<FooterAltGroups
						isMobile={isMobile}
						navigationGroups={footerData?.navigationGroupsCollection}
						footerAbout={footerData?.about}
						socialNetworks={footerData?.socialNetworksCollection}
						policyGroup={footerData?.footerPolicyGroupCollection}
						copyright={footerData?.copyright}
					/>
				</Box>
			</Box>
		</Box>
	);
}
