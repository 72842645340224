import { DisableTemplatePaddingOn, Maybe } from '@/types';

export const resolveTemplatePadding = (
	disablePaddingOn: DisableTemplatePaddingOn,
	isMediumScreen: boolean,
	mediumPadding: Maybe<string> = '2.5rem',
	largePadding: Maybe<string> = '3.75rem'
) => {
	const defaultPaddingTopAndBottom = isMediumScreen ? mediumPadding : largePadding;

	if (!disablePaddingOn) {
		return {
			paddingTop: defaultPaddingTopAndBottom,
			paddingBottom: defaultPaddingTopAndBottom,
		};
	}

	const removePaddingTop = disablePaddingOn.includes('Top');
	const removePaddingBottom = disablePaddingOn.includes('Bottom');

	const pt = removePaddingTop ? '0px' : defaultPaddingTopAndBottom;
	const pb = removePaddingBottom ? '0px' : defaultPaddingTopAndBottom;

	return {
		paddingTop: pt,
		paddingBottom: pb,
	};
};
