import { HeadingTag, Maybe } from '@/types';

export default function getHeadingVariant(variant: Maybe<HeadingTag>) {
	switch (variant) {
		case 'h1':
			return 'header1';
		case 'h2':
			return 'header2';
		case 'h3':
			return 'header3';
		case 'h4':
			return 'header4';
		case 'normal':
			return 'bodyMediumBook';
		default:
			return 'header3';
	}
}
