import React, { createElement, Fragment, useState } from 'react';

import { Box, Drawer as MUIDrawer } from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import TAGSvgIcon from '../TAGSvgIcon/TAGSvgIcon';

import { wrapper } from './Drawer.styles';

export type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface IDrawer extends DrawerProps {
	anchor: Anchor;
	children?: React.ReactNode;
	closeButton?: boolean;
	height?: string;
	onClick?: React.MouseEventHandler<HTMLUListElement> & React.MouseEventHandler<HTMLDivElement>;
	// eslint-disable-next-line @typescript-eslint/ban-types
	onClose?: ((event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
	open?: boolean | undefined;
	sheetVariant?: 'outlined' | 'elevation';
	triggerComponent?: React.ReactNode;
	width?: string;
	roundedTop?: boolean;
}

export default function Drawer({
	anchor,
	children,
	closeButton = false,
	height,
	onClick,
	onClose,
	open,
	sheetVariant = 'outlined',
	triggerComponent,
	width,
	roundedTop = false,
	...props
}: IDrawer) {
	const [position, setPosition] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor: Anchor, open: boolean) => () => {
		setPosition({ ...position, [anchor]: open });
	};

	const TriggerDivWrapper = () => {
		return createElement(
			'div',
			{
				onClick: onClick !== undefined ? onClick : toggleDrawer(anchor, true),
				anchor,
				style: { cursor: 'pointer', width: 'fit-content', height: 'fit-content' },
				'data-test': 'testingDiv',
				...props,
			},
			triggerComponent
		);
	};
	return (
		<Box data-test-id="section_drawer" sx={wrapper}>
			<Fragment key={anchor}>
				<MUIDrawer
					{...props}
					PaperProps={{
						variant: sheetVariant,
						sx: {
							borderRadius: roundedTop ? '0.625rem 0.625rem 0 0' : null,
							maxWidth: anchor === 'top' || anchor === 'bottom' ? 'auto' : '80%',
							maxHeight: anchor === 'top' || anchor === 'bottom' ? '80%' : 'auto',
							minWidth: 240,
							margin: '2px',
							width,
							height,
						},
					}}
					data-test="testDrawer"
					anchor={anchor}
					elevation={sheetVariant === 'elevation' ? 5 : 0}
					open={open !== undefined ? open : position[anchor]}
					onClose={onClose !== undefined ? onClose : toggleDrawer(anchor, false)}
				>
					<div data-test="testLayout" style={{ display: 'flex', alignItems: 'center' }}>
						{closeButton && (
							<IconButton
								onClick={onClose !== undefined ? onClose : toggleDrawer(anchor, false)}
								sx={{
									position: 'absolute',
									right: 1,
									top: 20,
									zIndex: 3,
									color: 'secondary.main',
								}}
								aria-label="close"
								data-test-id="drawer_close_button"
							>
								<TAGSvgIcon icon="Times" size={28} />
							</IconButton>
						)}
					</div>
					{React.Children.map(
						children,
						(child) =>
							React.isValidElement(child) &&
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							React.cloneElement(child as React.ReactElement<any>, {
								onClose: onClose !== undefined ? onClose : toggleDrawer(anchor, false),
							})
					)}
				</MUIDrawer>
				{TriggerDivWrapper()}
			</Fragment>
		</Box>
	);
}
