import { Grid, Typography } from '@mui/material';

import TAGSvgIcon from '@/components/TAGSvgIcon';
import { FacilityFieldsFragment } from '@/graphql/__generated/sdk';
import { FacilityBrand } from '@/types/generated';

interface IOfficeInformationBlock {
	invert?: boolean;
	distance?: number | null;
	office: FacilityFieldsFragment;
}

export default function OfficeInformationBlock({ invert, office, distance }: IOfficeInformationBlock) {
	return (
		<Grid item container direction={invert ? 'row-reverse' : 'row'} wrap="nowrap" alignItems="center" spacing={4}>
			<Grid item>
				{office.brand === FacilityBrand.WellNow && (
					<TAGSvgIcon icon="WellNowOffice" size={70} fill="currentColor" />
				)}
				{office.brand === FacilityBrand.AspenDental && <TAGSvgIcon icon="DentalOffice" size={70} />}
				{office.brand === FacilityBrand.ClearChoice && <TAGSvgIcon icon="CCOffice" size={70} />}
			</Grid>

			<Grid item container direction="column" spacing={2} wrap="nowrap" data-test-id="section_office_information">
				<Grid item>
					<Typography
						variant="bodyLargeSemiBold"
						component="h2"
						color="primary"
						display="flex"
						data-test-id="text_office_info_name"
					>
						{office.name}
					</Typography>
				</Grid>
				<Grid item>
					{office.address?.address1 && (
						<Typography
							variant="bodySmallBook"
							color="primary"
							display="flex"
							data-test-id="text_office_info_addr_1"
						>
							{office.address.address1}
						</Typography>
					)}
					{office.address?.address2 && (
						<Typography
							variant="bodySmallBook"
							color="primary"
							display="flex"
							data-test-id="text_office_info_addr_2"
						>
							{office.address.address2}
						</Typography>
					)}
					{office.address?.stateCode && office.address?.city && (
						<Typography
							variant="bodySmallBook"
							color="primary"
							display="flex"
							data-test-id="text_office_info_addr_3"
						>
							{office.address?.city}, {office.address?.stateCode}
						</Typography>
					)}
					<Typography
						variant="bodySmallBook"
						color="primary"
						display="flex"
						data-test-id="text_office_info_zip"
					>
						{office.address?.zipCode}
					</Typography>

					{distance && (
						<Typography
							variant="bodySmallBook"
							color="text.tertiary"
							display="flex"
							data-test-id="text_office_info_distance"
						>
							{`${distance} mi`}
						</Typography>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}
