import { useRef, useEffect } from 'react';

export default function useStickyNavHeader({ scrollingPosition }: { scrollingPosition: string }) {
	const element = useRef<HTMLDivElement>(null);
	const lastScrollPos = useRef(globalThis.scrollY);

	useEffect(() => {
		const handleScroll = () => {
			if (element.current) {
				if (window.pageYOffset >= 102) {
					element.current.style.position = 'fixed';
					element.current.style.top = scrollingPosition;
				} else element.current.style.position = 'sticky';
			}

			lastScrollPos.current = globalThis.scrollY;
		};
		globalThis.addEventListener('scroll', handleScroll);

		return () => {
			globalThis.removeEventListener('scroll', handleScroll);
		};
	}, [scrollingPosition]);

	return element;
}
