// we are currently using the following enums a values so they cannot be just types
// future work should refactor code to just use types instead of enums
export enum BffBrandType {
	AspenDental = 'ASPEN_DENTAL',
	AzPet = 'AZ_PET',
	Chapter = 'CHAPTER',
	ClearChoice = 'CLEAR_CHOICE',
	Lovet = 'LOVET',
	WellNow = 'WELL_NOW',
}

export enum FacilityBrand {
	AspenDental = 'ASPEN_DENTAL',
	AzPet = 'AZ_PET',
	Chapter = 'CHAPTER',
	ClearChoice = 'CLEAR_CHOICE',
	WellNow = 'WELL_NOW',
}

// the following enums are used for testing
export enum FacilityServiceBrand {
	AspenDental = 'ASPEN_DENTAL',
	Invisalign = 'INVISALIGN',
	Motto = 'MOTTO',
}

export enum FacilityServiceType {
	ClearAligners = 'CLEAR_ALIGNERS',
	Implants = 'IMPLANTS',
	SpanishSpeaking = 'SPANISH_SPEAKING',
}
