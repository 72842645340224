import { Theme } from '@mui/material';

export const getWrapperStyles = (theme: Theme, showMobileHeaderLocationBar: boolean) => ({
	backgroundColor: 'neutrals.white',
	height: showMobileHeaderLocationBar ? '6rem' : '4rem',
	display: 'flex',
	justifyContent: 'center',
	boxShadow: 'none',
	flexDirection: 'column',
	color: 'text.primary',
	borderBottom: '1px solid',
	borderColor: 'neutrals.primary',
	flexBasis: 1,
	transition: 'top 0.3s',
	[theme.breakpoints.up('md')]: {
		display: 'none',
	},
});

export const toolbarHeaderStyles = {
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	paddingRight: '.75rem',
	paddingLeft: '1rem', // the hamburger icon has 14px of paddingX so adding 2px here makes it even on both sides
};

export const toolbarActionsStyles = {
	width: '100%',
	display: 'flex',
	alignItems: 'center',
};

export const burgerAndLogoWrapperStyles = {
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	width: '100%',
};

export const scheduleButtonStyles = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	padding: '10px 20px',
	height: '40px',
	letterSpacing: 0,
	textAlign: 'center',
	display: 'flex',
};

export const iconStyles = {
	padding: 0,
};

export const logoStyles = {
	cursor: 'pointer',
	'&:hover': { opacity: 0.8 },
	maxWidth: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

export const navMenuOfficeDetailsStyles = {
	display: 'flex',
	flexDirection: 'column',
	padding: '1.375rem 0rem 3rem 1.25rem',
	gap: '1rem',
};

export const menuCTAStyles = {
	alignItems: 'center',
	pt: '2rem',
	px: '1rem',
};

export const menuIconStyles = { verticalAlign: 'middle', fontSize: '1.5rem', maxWidth: '2rem', pr: '0.25rem' };

export const menuLinkStyles = { verticalAlign: 'middle', marginLeft: '5px' };

export const getNavMenuNavigationBarStyles = (withOfficeDetails: boolean) => ({
	pt: withOfficeDetails ? '0rem' : '1rem',
});

export const scheduleApptLinkStyles = {
	textDecoration: 'none',
};

export const locationNameToolbarStyles = (theme: Theme) => ({
	display: 'flex',
	justifyContent: 'center',
	width: 'calc(100% + 10%)',
	background: theme.palette.backgrounds?.dark,
	mt: '0.25rem',
});

export const locationOutlinedIconStyles = (theme: Theme) => ({
	color: theme.palette.text.light,
});
