export const headerStyles = {
	backgroundColor: 'backgrounds.white',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: 'none',
	borderBottom: '1px solid',
	borderColor: 'neutrals.primary',
};

export const toolbarStyles = {
	justifyContent: 'space-between',
	width: '100%',
	padding: '1.25rem 2.5rem',
	maxHeight: '5rem',
};

export const logoWrapStyles = {
	display: 'flex',
	alignItems: 'center',
};

export const logoStyles = {
	cursor: 'pointer',
	paddingRight: '1.5rem',
	'&:hover': { opacity: 0.8 },
};

export const navigationStyles = {
	display: 'flex',
};

export const searchWrapStyles = {
	display: 'flex',
	alignItems: 'center',
};
