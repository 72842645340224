import { z } from 'zod';

export const schema = (brand: string) => {
	const fnSchema = z
		.string({ invalid_type_error: 'Required' })
		.regex(/^[a-z]+$/i, 'First Name is required')
		.max(30);

	const lnSchema = z
		.string({ invalid_type_error: 'Required' })
		.regex(/^[a-z]+$/i, 'Last Name is required')
		.max(30);

	const emailSchema = z.string().email('Valid Email required');

	if (brand === 'ClearChoice') {
		return z.object({
			first_name: fnSchema,
			last_name: lnSchema,
			email: emailSchema,
			phone: z.string().regex(/^\(\d{3}\) \d{3}-\d{4}$/, 'Valid Phone Number Required'),
		});
	}
	return z.object({
		firstName: fnSchema,
		lastName: lnSchema,
		email: emailSchema,
	});
};

export const defaultValues = (brand: string) => {
	if (brand === 'ClearChoice') {
		return {
			first_name: '',
			last_name: '',
			email: '',
			phone: '',
		};
	}
	return {
		firstName: '',
		lastName: '',
		email: '',
	};
};
