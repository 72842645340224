export function resolveEnv(): 'prod' | 'nonprod' {
	const hostName = globalThis?.location?.hostname;

	if (!hostName) return 'nonprod';

	const isNonProd = hostName.includes('localhost') || hostName.includes('stg.') || hostName.includes('stage');

	if (isNonProd) return 'nonprod';

	return 'prod';
}
