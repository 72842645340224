import { SxProps, TypographyProps, useMediaQuery, useTheme } from '@mui/material';

import { TemplateSize, TemplateWidth } from '@/types';
import { resolveContentfulTemplateWidth } from '@/utils/resolveContentfulTemplateWidth';
import { ContentTypeRichText as TContentTypeRichText, Maybe } from '@/types/generated';

import ContentTypeRichText from '../ContentTypeRichText';

import { getWrapperStyles } from './Disclaimer.styles';

export interface IDisclaimer {
	/**
	 * Data for ContentTypeRichText template. It is an alias of disclaimer field.
	 *
	 * @type {Maybe<TContentTypeRichText>}
	 * @memberof IDisclaimer
	 */
	disclaimer?: Maybe<TContentTypeRichText>;
	/**
	 * Specifies the font size of the disclaimer text content. Can be Standard, Medium or Large.
	 *
	 * @type {Maybe<TemplateSize>}
	 * @memberof IDisclaimer
	 * @default 'Standard'
	 * @todo Maybe remove this? Size does not exist on disclaimerV2 model.
	 */
	size?: Maybe<string>;
	/**
	 * Width of the Disclaimer template. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof IDisclaimer
	 * @default 'Full'
	 * @deprecated Use disclaimer.contentMaxWidth instead
	 */
	templateWidth?: Maybe<string>;
	/**
	 * Optional wrapper styles. Useful for adding margins or padding
	 *
	 * @type {SxProps}
	 * @memberof IDisclaimer
	 */
	wrapperSx?: SxProps;
	/**
	 * Optional data-test-id attribute for testing purposes
	 *
	 * @type {string}
	 * @memberof IDisclaimer
	 */
	dataTestId?: string;
	/**
	 * Disables the default side padding of the Container component.
	 *
	 * @type {boolean}
	 * @memberof IDisclaimer
	 */
	disableGutters?: boolean;
}

function resolveTypographyVariant(size: TemplateSize, isSmallScreen: boolean): TypographyProps['variant'] {
	switch (size) {
		case 'Standard':
			return 'lowestLevelBook';
		case 'Medium':
			return isSmallScreen ? 'bodySmallBook' : 'bodyLargeBook';
		case 'Large':
			return isSmallScreen ? 'bodySmallBook' : 'bodyXLBook';
		default:
			return 'lowestLevelBook';
	}
}

export default function Disclaimer({
	disclaimer,
	size,
	templateWidth,
	wrapperSx = {},
	dataTestId = 'section_disclaimer',
	disableGutters = false,
}: IDisclaimer) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (!disclaimer?.content) return null;

	const typographyVariant = resolveTypographyVariant((size as TemplateSize) || 'Standard', isSmallScreen);
	const containerWidth = resolveContentfulTemplateWidth((templateWidth as TemplateWidth) || 'Full');

	return (
		<ContentTypeRichText
			{...disclaimer}
			dataTestId={dataTestId}
			disablePaddingOn={['Top', 'Bottom']}
			disableGutters={disableGutters}
			contentMaxWidth={'none'}
			containerSx={getWrapperStyles(wrapperSx, containerWidth)}
			docProps={{ variant: typographyVariant, color: 'text.secondary' }}
		/>
	);
}
