const searchInputWrapperStyles = {
	maxWidth: '20rem',
};

const searchInputStyles = {
	borderRadius: 25,
	backgroundColor: 'backgrounds.white',
	'& .MuiOutlinedInput-root fieldset': {
		border: 'none',
	},
};

const searchInputWrapperMobileStyles = {
	borderRadius: '0.5rem',
	backgroundColor: 'backgrounds.neutral',
	'& .MuiOutlinedInput-root fieldset': {
		border: 'none',
	},
	height: '2.5rem',
};

const searchInputMobileStyles = {
	padding: '0 0.125rem',
	height: '2.5rem',
	lineHeight: '2.5rem',
};

export const getSearchInputStyles = (isSmallScreen: boolean) => {
	return isSmallScreen ? searchInputMobileStyles : searchInputStyles;
};

export const getSearchInputWrapperStyles = (isSmallScreen: boolean) => {
	return isSmallScreen ? searchInputWrapperMobileStyles : searchInputWrapperStyles;
};

export const searchIconStyles = {
	color: 'text.interactive',
};

export const scheduleButtonStyles = {
	maxWidth: '15.75rem',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

export const popupSchedulingStyles = {
	containerStyles: {
		pt: '1.5rem',
	},
	headerStyles: {
		pl: '1rem',
		pb: '1rem',
	},
	navigationItemStyles: {
		display: 'flex',
		flexDirection: 'column',
		'&:not(:last-of-type)': {
			pb: '1.5rem',
		},
		a: {
			borderBottom: '1px solid',
			borderColor: 'neutrals.primary',
			py: '0.75rem',
			pl: '1rem',
		},
	},
	groupLinkLinkTextStyles: {
		pl: '1rem',
		borderBottom: '1px solid',
		borderBottomColor: 'neutrals.primary',
	},
};

export const locationIconStyles = {
	svgSize: {
		svg: {
			width: '1.5rem',
			height: '1.5rem',
		},
	},
};
