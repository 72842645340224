import { Box } from '@mui/material';

import TAGLink from '@/components/TAGLink';
import { resolveLinkUrl, resolveLinkImage } from '@/components/FooterLite/FooterLite.helpers';
import { Maybe, AdFooterSocialNetworksCollection, Page } from '@/types/generated';
import { optimizeImageUrl } from '@/components/FocalPointImage/FocalPointImage.helpers';

import { getSocialImageStyle } from './FooterAlt.styles';

interface IFooterAltSocialLinks {
	socialNetworks?: Maybe<AdFooterSocialNetworksCollection>;
	isMobile: boolean;
}

export default function FooterAltSocialLinks({ socialNetworks, isMobile }: IFooterAltSocialLinks) {
	if (!socialNetworks) return null;

	const socNetworkItems = socialNetworks?.items as Page[];

	if (!socNetworkItems) return null;

	return (
		<Box
			display="flex"
			alignItems={'center'}
			gap={isMobile ? 3 : 1.5}
			marginY={1.5}
			data-test-id="section_footeralt_social_media_links"
		>
			<SocialNetworkIcons socNetworkItems={socNetworkItems} />
		</Box>
	);
}

export function SocialNetworkIcons({ socNetworkItems }: Readonly<{ socNetworkItems?: Page[] }>) {
	if (!socNetworkItems) return null;
	return (
		<>
			{socNetworkItems.filter(Boolean).map((socialNetworkItem) => {
				const socialNetworkImage = resolveLinkImage(socialNetworkItem);
				return (
					<TAGLink
						href={resolveLinkUrl(socialNetworkItem)}
						key={socialNetworkItem.sys.id}
						dataTestId={`link_social_${socialNetworkItem.sys.id}`}
						linkSx={{ lineHeight: 0 }}
						analyticsContext={socialNetworkItem.analyticsContext}
					>
						<img
							src={optimizeImageUrl(socialNetworkImage?.url)}
							alt={socialNetworkImage?.description || ''}
							style={getSocialImageStyle}
							loading="lazy"
						/>
					</TAGLink>
				);
			})}
		</>
	);
}
