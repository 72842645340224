import { Maybe, Sizes } from '@/types';
import { Button as IButton } from '@/types/generated';

export type ButtonIconSizes = Sizes | 'XSmall';

export const resolveContentfulResponsiveButton = (
	button: IButton,
	isSmallScreen: boolean
): {
	id: string;
	link: string;
	label?: Maybe<string>;
	variant?: Maybe<string>;
	size?: Maybe<string>;
	icon?: Maybe<string>;
	iconSize?: Maybe<ButtonIconSizes>;
	iconPosition?: Maybe<string>;
	externalUrl?: Maybe<string>;
	openInNewTab?: Maybe<boolean>;
	openInNewWindow?: Maybe<boolean>;
	quizSelector?: Maybe<string>;
} => {
	const externalUrl = button.navigationDetails?.externalUrl;
	const openInNewTab = button.navigationDetails?.openInNewTab;
	const openInNewWindow = button.navigationDetails?.openInNewWindow;
	const link = button.buttonLink || '#';

	if (isSmallScreen && button.mobileButton) {
		return {
			id: button.mobileButton.sys?.id || button.sys?.id,
			link,
			label: button.mobileButton.label || button.label,
			variant: button.mobileButton.variant || button.variant,
			size: button.mobileButton.size || button.size,
			icon: button.mobileButton.icon || button.icon,
			iconPosition: button.mobileButton.iconPosition || button.iconPosition,
			externalUrl,
			openInNewTab,
			openInNewWindow,
			quizSelector: button.quizSelector,
			iconSize: button.iconSize as ButtonIconSizes,
		};
	}

	return {
		id: button?.sys?.id,
		link,
		label: button.label,
		variant: button.variant,
		size: button.size,
		icon: button.icon,
		iconPosition: button.iconPosition,
		externalUrl,
		openInNewTab,
		openInNewWindow,
		quizSelector: button.quizSelector,
		iconSize: button.iconSize as ButtonIconSizes,
	};
};
