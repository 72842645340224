import { Theme } from '@mui/material';

export const contentTileItemStyles = {
	height: { xs: '100%', md: '10.25rem' },
	width: { xs: '100%', md: '13.5rem' },
	flexShrink: 0,
	marginRight: { xs: '.125rem', md: '0' },
	overflow: 'hidden',
	borderRadius: '.5rem',
};

export const contentTileDividerClass = (theme: Theme) => {
	return {
		borderLeft: `1px solid ${theme.palette.neutrals.primary}`,
		margin: '0',
		width: '1px',
	};
};

export const contentTilesContainerStyles = {
	display: 'flex',
	flexDirection: { xs: 'row', md: 'column' },
	paddingX: '1.5rem',
	paddingY: { xs: '2rem', md: '0' },
	gap: '.75rem',
	alignSelf: 'flex-start',
	width: { xs: '100%', md: 'auto' },
	marginLeft: 'auto',
};

export const tileContentBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	position: 'absolute',
	bottom: 0,
	left: 0,
	paddingX: '.75rem',
	paddingBottom: '1.25rem',
	background: 'linear-gradient(180deg, rgba(0, 40, 85, 0.00) 0%, rgba(0, 12, 26, 0.90) 71.35%)',
	textWrap: 'balance',
};

export const tileLinkWrapperStyles = {
	position: 'relative',
};

export const contentTileSwipperWrapperStyles = {
	width: '100%',
	height: '100%',
};

export const contentTileSwipperStyles = {
	paddingBottom: '2.5rem',
};
