import { useRef, useEffect } from 'react';
interface IDisappearingHeader {
	hiddenPos: string;
	showingPos: string;
	withFixedHeader?: boolean;
}
export default function useDisappearingHeader({ hiddenPos, showingPos, withFixedHeader = false }: IDisappearingHeader) {
	const element = useRef<HTMLDivElement>(null);
	const lastScrollPos = useRef(globalThis.scrollY);

	useEffect(() => {
		const handleScroll = () => {
			if (withFixedHeader) {
				return;
			}
			if (lastScrollPos.current !== undefined && element.current) {
				if (
					lastScrollPos.current > globalThis.scrollY ||
					globalThis.scrollY < element.current.offsetTop + element.current.offsetHeight
				) {
					element.current.style.top = showingPos;
				} else {
					element.current.style.top = hiddenPos;
				}
			}

			lastScrollPos.current = globalThis.scrollY;
		};
		globalThis.addEventListener('scroll', handleScroll);

		return () => {
			globalThis.removeEventListener('scroll', handleScroll);
		};
	}, [hiddenPos, showingPos, withFixedHeader]);

	return element;
}
