import { forwardRef, Ref } from 'react';

import { Box, BoxProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { incorrectUsageWarning } from '@/utils/miscUtils';

import { optimizeImageUrl } from '../FocalPointImage/FocalPointImage.helpers';

type ImgProps = JSX.IntrinsicElements['img'];

interface IBasicImage extends ImgProps {
	alt: string;
	ref?: Ref<HTMLImageElement>;
}

export const BasicImage: OverridableComponent<
	typeof Box & {
		props: BoxProps & IBasicImage;
		defaultComponent: 'img';
		ref?: Ref<HTMLImageElement>;
	}
> = forwardRef((props: BoxProps & { alt: string; src?: string }, ref: Ref<HTMLImageElement> | null) => {
	const { src, ...otherProps } = props;
	if (src) {
		return <Box ref={ref} component="img" {...otherProps} src={optimizeImageUrl(src)} alt={props.alt} />;
	}
	// if there is no src assuming that image is being handled by css
	return <Box ref={ref} {...props} />;
});
// @ts-ignore
BasicImage.displayName = 'BasicImage';

interface IImageWrapper {
	/**
	 * Custom image element to be used instead of the default one. If you want to use the default image element, do not pass this prop.
	 *
	 * @type {typeof BasicImage}
	 * @memberof IImageWrapper
	 */
	imageEl?: typeof BasicImage;
	boxSx?: BoxProps['sx'];
	[imageProps: string]: unknown;
}

export default function ImageWrapper({ imageEl: ImageElement, ...imageProps }: IImageWrapper) {
	if (ImageElement) {
		const { boxSx, ...rest } = imageProps;

		if (boxSx) {
			incorrectUsageWarning(
				'ImageWrapper',
				`boxSx prop should not be used with custom image element.
				 If you want to use Sx styling use the default rendering method of ImageWrapper. 
				 If you want to pass styles to the custom image element, pass them as "style" props.`
			);
		}

		return <ImageElement {...rest} alt={imageProps.alt as string} />;
	}

	return <BasicImage {...imageProps} alt={imageProps.alt as string} />;
}
