import { getSessionId } from '@aspendental/shared-utils/utils';
import pino, { Logger, LoggerOptions } from 'pino';
const logLevelData = {
	'*': 'silent',
	client: 'info',
	app: 'info',
	'preview-api': 'info',
	'landing-page': 'info',
	home: 'info',
	'blog-detail-page': 'info',
};

const levelToSeverity = {
	trace: 'DEBUG',
	debug: 'DEBUG',
	info: 'INFO',
	warn: 'WARNING',
	error: 'ERROR',
	fatal: 'CRITICAL',
};

const logLevels = new Map<string, string>(Object.entries(logLevelData));

export function getLogLevel(logger: string): string {
	return logLevels.get(logger) || logLevels.get('*') || 'info';
}

export function getLogger(name: string, tagTheme?: TagThemeType): Logger {
	if (name === 'client') {
		return pino({
			name,
			browser: {
				asObject: true,
				transmit: {
					send: (level, logEvent) => {
						const msg = logEvent.messages[0] as string;
						const sessionId = getSessionId();

						const headers = {
							'Access-Control-Allow-Origin': '*',
							'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
							type: 'application/json',
						};
						const blob = new Blob([JSON.stringify({ msg, name, level, sessionId })], headers);

						if (navigator.sendBeacon) {
							navigator.sendBeacon(`${globalThis.location.origin}/api/log/`, blob);
						} else {
							void fetch(`${globalThis.location.origin}/api/log/`, {
								body: JSON.stringify({ msg, name, level, sessionId }),
								method: 'POST',
								keepalive: true,
								headers,
							});
						}
					},
				},
				disabled: process.env.NODE_ENV === 'production',
			} as LoggerOptions['browser'],
		});
	}
	return pino({
		name,
		formatters: {
			level(label, number) {
				return { severity: levelToSeverity[label], level: number };
			},
		},
		messageKey: 'message',
		level: getLogLevel(name),
		transport: {
			target: process.env.NODE_ENV === 'production' ? 'pino/file' : 'pino-pretty',
			options: {
				colorize: true,
				destination: 1,
			},
		},
		msgPrefix: `${tagTheme} `,
	});
}
