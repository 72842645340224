import { FC, HTMLAttributeAnchorTarget, MouseEvent, ReactNode, useEffect, useState } from 'react';

import { Button, ButtonProps } from '@mui/material';

import { addClassName, dataLayerConfig, replaceUrlContentVars } from '@/utils';
import { useAppContext, useFacilityContext, useOfficeLocationContext } from '@/context';
import { MOTTO_ALIGNERS_PATH, SCHEDULE_PATH } from '@/constants';
import { Maybe, AnalyticsContext } from '@/types/generated';
import { getUrlType } from '@/components/TAGLink/TAGLink.helpers';

export interface ITAGButton extends ButtonProps {
	target?: HTMLAttributeAnchorTarget;
	dataTestId?: string;
	analyticsContext?: Maybe<AnalyticsContext>;
}

const getHref = (pathname: string, href: string, facilityCode?: string) => {
	const isUserOnMottoAligners = pathname.includes(MOTTO_ALIGNERS_PATH);
	const isSpanishDirectory = pathname.includes('espanol');
	const isHrefForSchedule = href.includes(SCHEDULE_PATH);
	const isHrefForRegularSchedule = !isUserOnMottoAligners && isHrefForSchedule;
	const isHrefForMottoSchedule = isUserOnMottoAligners && isHrefForSchedule;

	if (isHrefForRegularSchedule) {
		const hasTrailingSlash = href.endsWith('/');
		const slashString = hasTrailingSlash ? '' : '/';
		const localizedHref = isSpanishDirectory && !href.includes('es-US') ? `${href}${slashString}es-US/` : href;
		const updatedHref =
			facilityCode && !localizedHref.includes('fc') ? `${localizedHref}?fc=${facilityCode}` : localizedHref;
		return updatedHref;
	} else if (isHrefForMottoSchedule) {
		const transformedHref = href.endsWith('/') ? href.slice(0, -1) : href;
		return facilityCode && !href.includes('fc')
			? `${transformedHref}/${MOTTO_ALIGNERS_PATH}?fc=${facilityCode}`
			: `${transformedHref}/${MOTTO_ALIGNERS_PATH}`;
	} else {
		//TODO: remove this CCUtmParams href logic as it is temporary until CC scheduling is not legacy
		const { isExternalUrl } = getUrlType(href);
		const CCUtmParams = globalThis?.sessionStorage?.getItem('CCUtmParams');
		return CCUtmParams && isExternalUrl ? `${href}?${CCUtmParams}` : href;
	}
};

const getLabel = (children: ReactNode, pathname: string, href: string | undefined) => {
	const isSpanishDirectory = pathname.includes('espanol');
	const isHrefForSchedule = href?.includes(SCHEDULE_PATH);
	if (isHrefForSchedule && isSpanishDirectory) {
		return <span>Haz una cita</span>;
	}
	return children;
};

const TAGButton: FC<ITAGButton> = ({ children, dataTestId, href, analyticsContext, onClick, ...restProps }) => {
	const { facilityCode, officeInfo } = useFacilityContext();
	const { officeInfo: officeLocationInfo } = useOfficeLocationContext();
	const { config } = useAppContext();
	if (href) {
		href = replaceUrlContentVars(href, officeInfo, officeLocationInfo, config);
	}
	const [updatedHref, setUpdatedHref] = useState<string | undefined>();

	const pathname = globalThis.location?.pathname ?? '';
	const finalChildren = getLabel(children, pathname, href);

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		if (analyticsContext && analyticsContext?.event) {
			dataLayerConfig({
				...analyticsContext,
				link_url: analyticsContext.linkUrl ?? updatedHref,
				website_selected_facility_id: officeInfo?.code || '',
				website_selected_facility_name: officeInfo?.name || '',
			});
		}

		if (onClick) {
			onClick(e);
		}

		return undefined;
	};

	useEffect(() => {
		if (href) {
			const newHref = getHref(pathname, href, facilityCode);
			setUpdatedHref(newHref);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [href, facilityCode]);

	return (
		<Button
			{...restProps}
			href={updatedHref ?? href}
			className={addClassName(href)}
			onClick={(e) => handleClick(e)}
			data-test-id={dataTestId}
		>
			{finalChildren}
		</Button>
	);
};

export default TAGButton;
