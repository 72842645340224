import { Box, Typography } from '@mui/material';

import {
	Maybe,
	AdFooterNavigationGroupsCollection,
	AdFooterAbout,
	NavigationLink,
	AdFooterFooterPolicyGroupCollection,
	Scalars,
	AdFooterSocialNetworksCollection,
} from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { resolveLinkTitle, resolveLinkUrl } from '@/components/FooterLite/FooterLite.helpers';
import {
	getGroupItemStyles,
	getGroupWrapperStyles,
	getNavLinkStyles,
	getNavTitleStyles,
	getFooterConnectBlockStyles,
} from '@/components/FooterAlt/FooterAlt.styles';

import FooterAltSocialLinks from './FooterAltSocialLinks';
import FooterAltPolicy from './FooterAltPolicy';

interface IFooterAltGroups {
	isMobile: boolean;
	navigationGroups?: Maybe<AdFooterNavigationGroupsCollection>;
	footerAbout?: Maybe<AdFooterAbout>;
	socialNetworks?: Maybe<AdFooterSocialNetworksCollection>;
	policyGroup?: Maybe<AdFooterFooterPolicyGroupCollection>;
	copyright?: Maybe<Scalars['String']>;
}

export default function IFooterAltGroups({
	isMobile,
	navigationGroups,
	socialNetworks,
	policyGroup,
	copyright,
}: IFooterAltGroups) {
	const groupItems = navigationGroups?.items || [];

	const GroupBlocks = () => {
		if (!groupItems) return null;
		return (
			<>
				{groupItems.map((group) =>
					group ? (
						<Box
							key={group?.sys.id}
							data-test-id={`section_footeralt_navigation_content_${group.sys.id}`}
							sx={getGroupItemStyles(isMobile)}
						>
							<TAGLink
								dataTestId={`link_group_title_${group.sys.id}`}
								href={resolveLinkUrl(group.navigationGroupLink)}
							>
								<Typography variant="bodyLargeBold" color="neutrals.disabled" sx={getNavTitleStyles()}>
									{resolveLinkTitle(group.navigationGroupLink)}
								</Typography>
							</TAGLink>
							{group.navigationLinksForGroupCollection?.items.map((navigationItem) =>
								navigationItem ? (
									<TAGLink
										key={navigationItem.sys.id}
										href={resolveLinkUrl(navigationItem)}
										openInNewTab={(navigationItem as NavigationLink)?.openInNewTab || false}
										dataTestId={`link_for_group_${navigationItem.sys.id}`}
									>
										<Typography
											color="neutrals.white"
											variant="bodyMediumBook"
											title={resolveLinkTitle(navigationItem)}
											sx={getNavLinkStyles()}
										>
											{resolveLinkTitle(navigationItem)}
										</Typography>
									</TAGLink>
								) : null
							)}
						</Box>
					) : null
				)}
			</>
		);
	};
	const CopyrightBlock = () => {
		if (!copyright) return null;
		return (
			<Typography
				color="text.disabled"
				variant="bodySmallBook"
				data-test-id="text_footeralt_copyright"
				marginTop={!isMobile ? 'auto' : ''}
			>
				{copyright}
			</Typography>
		);
	};
	return (
		<Box data-test-id="section_footer_navigation_content" sx={getGroupWrapperStyles(isMobile)}>
			<GroupBlocks />

			<Box sx={getGroupItemStyles(isMobile)}>
				<Box sx={getFooterConnectBlockStyles(isMobile)}>
					{!isMobile ? (
						<Typography
							marginBottom=".5rem"
							variant="bodyLargeBold"
							color="neutrals.disabled"
							display="block"
							sx={getNavTitleStyles()}
						>
							Connect
						</Typography>
					) : (
						''
					)}
					{isMobile ? <CopyrightBlock /> : ''}

					<FooterAltSocialLinks socialNetworks={socialNetworks} isMobile={isMobile} />
				</Box>
				<FooterAltPolicy isMobile={isMobile} policyGroup={policyGroup} copyright={copyright} />
				{!isMobile ? <CopyrightBlock /> : ''}
			</Box>
		</Box>
	);
}
