import { Container, Divider, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';

import { App, DisclaimerV2, Maybe, Page } from '@/types/generated';
import BreadCrumbs from '@/components/BreadCrumbs';
import Disclaimer from '@/components/Disclaimer';

import { BackToTopArrow } from '../Footer/Footer';
import { breadCrumbsAndTopBtnContainerFooterLiteStyles, breadCrumbsContainerStyles } from '../Footer/Footer.styles';

import { getWrapperStyles, disclaimerWrapperStyles } from './FooterLite.styles';
import FooterLiteLogo from './FooterLiteLogo/FooterLiteLogo';
import FooterLiteContent from './FooterLiteContent/FooterLiteContent';
import FooterLitePolicy from './FooterLitePolicy/FooterLitePolicy';

interface IFooterLiteProps {
	footerData: App['footer'];
	withSubscriptionForm?: boolean;
	breadcrumbsData?: Page;
	/**
	 * The disclaimer template.
	 *
	 * @type {Maybe<DisclaimerV2>}
	 * @memberof IHero
	 */
	disclaimer?: Maybe<DisclaimerV2>;
}
export default function FooterLite({
	footerData,
	withSubscriptionForm = false,
	breadcrumbsData,
	disclaimer,
}: IFooterLiteProps) {
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
	const isMediumDevice = useMediaQuery(theme.breakpoints.up('sm'));
	const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'));
	return (
		<Paper
			component="footer"
			sx={getWrapperStyles({ isSmall: isSmallDevice, isMedium: isMediumDevice && !isLargeDevice })}
			data-test-id="section_footer_lite"
		>
			<Container disableGutters maxWidth={false}>
				<Grid container sx={breadCrumbsAndTopBtnContainerFooterLiteStyles}>
					{breadcrumbsData?.breadcrumbsCollection && (
						<Grid item sx={breadCrumbsContainerStyles}>
							<BreadCrumbs breadcrumbsData={breadcrumbsData} isFooterLite={true} />
						</Grid>
					)}

					<BackToTopArrow isFooterLite={true} />
				</Grid>
				<FooterLiteLogo logo={footerData?.logo} isSmallDevice={isSmallDevice} />
				<FooterLiteContent
					isSmallDevice={isSmallDevice}
					navigationGroups={footerData?.navigationGroupsCollection}
					socialNetworks={footerData?.socialNetworksCollection}
					footerAbout={footerData?.about}
					withSubscriptionForm={withSubscriptionForm}
				/>
				<Divider />
				<FooterLitePolicy
					policyGroup={footerData?.footerPolicyGroupCollection}
					copyright={footerData?.copyright}
					isSmallDevice={isSmallDevice}
				/>
				{disclaimer && (
					<Disclaimer
						disclaimer={disclaimer.disclaimer}
						dataTestId="footer_lite_disclaimer"
						disableGutters
						wrapperSx={disclaimerWrapperStyles}
					/>
				)}
			</Container>
		</Paper>
	);
}
