export const headerStyles = (isDarkHeader: boolean, isWithNavMenu: boolean) => ({
	backgroundColor: isDarkHeader ? 'backgrounds.dark' : 'backgrounds.white',
	height: isWithNavMenu ? 'auto' : '6.875rem',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: 'none',
	transition: 'top 0.3s',
	zIndex: 1000,
	...(!isWithNavMenu && { borderBottom: '1px solid' }),
	borderColor: 'neutrals.primary',
});

export const navbarStyles = {
	position: 'sticky',
	backgroundColor: 'backgrounds.neutral',
	height: '3rem',
	top: '0',
	boxShadow: 'none',
	display: 'block',
	borderColor: '#ccc',
	borderStyle: 'solid',
	borderWidth: '1px 0',
	transition: 'top 0.3s',
	zIndex: 1000,
};

export const toolbarHeaderStyles = (isWithNavMenu: boolean) => ({
	height: isWithNavMenu ? '5.5rem' : 'auto',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	padding: isWithNavMenu ? '0 5%' : '0 5% 1%',
});

export const logoStyles = {
	cursor: 'pointer',
	'&:hover': { opacity: 0.8 },
};

export const inlineNavigationStyles = {
	width: '100%',
	color: 'text.interactive',
	alignItems: 'center',
};

export const getLogoContainerStyles = (logoPosition: 'left' | 'center') => ({
	display: 'flex',
	alignItems: 'center',
	gap: `${logoPosition === 'left' ? '0.625rem' : '0'}`,
	justifyContent: `${logoPosition === 'left' ? 'flex-end' : 'flex-start'}`,
	flexDirection: `${logoPosition === 'left' ? 'row-reverse' : 'row'}`,
	width: `${logoPosition === 'left' ? '50%' : '100%'}`,
});

export const getNavMenuDesktopStyles = {
	width: '100%',
	backgroundColor: 'backgrounds.neutral',
	height: '3rem',
	top: '5.5rem',
	boxShadow: 'none',
	display: 'block',
	borderColor: '#ccc',
	borderStyle: 'solid',
	borderWidth: '1px 0',
	transition: 'top 0.3s',
	zIndex: 1000,
};

export const getPhoneIconStyles = ({ isDarkHeader }: { isDarkHeader: boolean }) => ({
	display: 'flex',
	alignItems: 'center',
	color: isDarkHeader ? 'text.light' : 'text.interactive',
});
