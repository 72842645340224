import React, { SVGProps } from 'react';

import * as brandIcon from '../../assets/SVG/brandIcon/';
import * as functionalIcon from '../../assets/SVG/functionalIcon';
import * as iconNames from '../../assets/SVG';

/**
 * The SVG icon component allows the user to use an SVG image as an icon.
 * Based on Figma design (https://www.figma.com/file/uTqN24vX8X6DD5zZ6XioEL/Aspen-Dental---Component-Library?node-id=9%3A597),
 * all brand icons and most functional icons in Figma are included as IconName type
 * except Tooth, Toothbrush, Tooth-open, Teeth, Ellipsis-halt and Ellipsis-v-alt icon in Figma functional icons section.
 * Use equivalent brand icons for these 6 functional icons (Tooth, Toothbrush, Tooth-open, Teeth, Ellipsis-halt, and Ellipsis-v-alt)
 * If icon is not available in the project, please add it into asset/images/SVG/Icon.tsx and use it.
 */
// TODO: cleanup unused icons

export type IconName = keyof typeof iconNames;
export type BrandIconType = keyof typeof brandIcon;
export type FunctionalIconType = keyof typeof functionalIcon;

interface ISvgIcon extends SVGProps<SVGAElement> {
	/**
	 * What color to use. Functional icons can change color, brand icons cannot change color
	 */
	color?: string | 'inherit';
	/**
	 * Icon props is IconName type, which include "yourself" | "virtual_care_color_alt" |... 103 more.
	 * example: <TAGSvgIcon icon="yourself" />
	 */
	icon: IconName;
	/**
	 * Size is a number type, default value is 32
	 */
	size?: number;
	viewBox?: string;
	/**
	 * 	A [data-test-id] attribute for the TAGSvgIcon used for testing purposes
	 */
	dataTestId?: string;
}

export default function TAGSvgIcon({
	color = 'currentColor',
	icon,
	size = 32,
	viewBox = '0 0 32 32',
	dataTestId = '',
	...props
}: ISvgIcon) {
	if (!icon) return null;
	// @ts-ignore
	if (!iconNames[icon]) return null;

	return React.createElement(
		'svg',
		{
			width: size,
			height: size,
			color,
			viewBox,
			fill: color,
			xmlns: 'http://www.w3.org/2000/svg',
			'data-test-id': dataTestId,
			...props,
		},
		// @ts-ignore
		React.createElement(iconNames[icon] as React.FC<ISvgProps>, {
			width: props.width,
			height: props.height,
			...props,
		})
	);
}
