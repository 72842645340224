import { getGraphQLClient } from '@/graphql/client';
import { getGraphHeaders } from '@/utils/getGraphHeaders';
import { logWarning } from '@/utils/miscUtils';
import { BffBrandType } from '@/types/generated';

export const getBffNearByFacilitiesByLocation = async (
	brand: BffBrandType,
	latitude: number,
	longitude: number,
	url: string,
	appName: string,
	appVersion: string
) => {
	try {
		if (!brand) {
			return null;
		}

		if (!latitude || !longitude) {
			return Promise.reject(`Invalid coordinates of latitude: ${latitude} and longitude: ${longitude}`);
		}

		return await getGraphQLClient(url).bffNearByFacilitiesByLocation(
			{
				brand,
				latitude,
				longitude,
			},
			getGraphHeaders(appName, appVersion)
		);
	} catch (err) {
		logWarning(`Could not find any near by facilities data for: ${brand}`);
	}
};
