import { useState } from 'react';

import LanguageIcon from '@mui/icons-material/Language';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

import LanguageDialog from './LanguageDialog';
import LanguageDrawer from './LanguageDrawer';

interface ILanguageToggle {
	isLargeDevice: boolean;
	isDarkHeader?: boolean;
}

export default function LanguageToggle({ isDarkHeader }: ILanguageToggle) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [openLanguageDialog, setOpenLanguageDialog] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const router = useRouter();
	const { locale } = router;
	const esSelected = locale === 'es-US';
	const currentLanguageSmString = esSelected ? 'ES' : 'EN';
	const currentLanguageString = esSelected ? 'Español' : 'English';
	const preferredLanguage = navigator.language;

	const interpretLanguagePreference = (lang: string) => {
		if (lang === 'es' || lang === 'es-ES' || lang === 'es-MX' || lang === 'es-US') {
			return 'es-US';
		} else {
			return 'en-US';
		}
	};

	const detectLocale = () => {
		if (locale === 'es-US') {
			return 'es-US';
		} else if (locale === 'en-US') {
			return 'en-US';
		} else {
			return interpretLanguagePreference(preferredLanguage);
		}
	};
	detectLocale();

	const openLanguageSelect = () => {
		if (isSmallScreen) {
			setOpenDrawer(true);
			setOpenLanguageDialog(false);
		} else {
			setOpenDrawer(false);
			setOpenLanguageDialog(true);
		}
	};

	return (
		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			{!isDarkHeader && (
				<Button variant="primaryHC" size="S" startIcon={<LanguageIcon />} onClick={() => openLanguageSelect()}>
					{isSmallScreen && (
						<Typography color={isDarkHeader ? 'text.light' : 'secondary.main'} variant="buttonLabel">
							{currentLanguageSmString}
						</Typography>
					)}
					{!isSmallScreen && (
						<Typography color={isDarkHeader ? 'text.light' : 'secondary.main'} variant="buttonLabel">
							{currentLanguageString}
						</Typography>
					)}
				</Button>
			)}
			{isDarkHeader && (
				<Button variant="tertiaryHC" size="S" startIcon={<LanguageIcon />} onClick={() => openLanguageSelect()}>
					{isSmallScreen && (
						<Typography color={'text.light'} variant="buttonLabel">
							{currentLanguageSmString}
						</Typography>
					)}
					{!isSmallScreen && (
						<Typography color={'text.light'} variant="buttonLabel">
							{currentLanguageString}
						</Typography>
					)}
				</Button>
			)}
			<LanguageDialog openLanguageDialog={openLanguageDialog} setOpenLanguageDialog={setOpenLanguageDialog} />
			<LanguageDrawer
				openDrawer={openDrawer}
				handleDrawerClose={() => setOpenDrawer(false)}
				setOpenDrawer={setOpenDrawer}
			/>
		</div>
	);
}
