export const getDropMenuStyles = (isMobile?: boolean) => ({
	'.MuiPaper-root': {
		width: '100%',
		backgroundColor: 'neutrals.neutral',
		borderRadius: '0 0 0.25rem 0.25rem',
		padding: '1rem',
		boxShadow: '0 1px 3px 0px rgb(0, 0, 0, 0.2)',
		boxSizing: 'border-box',
		marginTop: isMobile ? '0.1rem' : '0.8rem',
		maxWidth: isMobile ? '100%' : '25rem',
	},
	'.MuiList-root': {
		gap: '1rem',
		display: 'flex',
		flexDirection: 'column',
	},
});

export const officeInfoBoxStyles = {
	display: 'flex',
	alignItems: 'flex-start',
	gap: '1rem',
	justifyContent: 'space-between',
	marginTop: '0.25rem',
};

export const secondaryMenuButtonStyles = {
	flexGrow: 1,
	fontWeight: 600,
};

export const getCarrotIconStyles = (isMenuOpened: boolean) => ({
	transition: 'transform 0.2s ease-in-out',
	...(isMenuOpened && {
		transform: 'rotate(-180deg)',
	}),
});
