/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */

import { Box } from '@mui/material';
import { NodeData } from '@contentful/rich-text-types';

import TAGSvgIcon from '../TAGSvgIcon';

import { checkedIconBoxStyles, xMarkIconBoxStyles } from './Table.styles';

/* Template specific functions,
these are placeholders which are used in contentful in tables */
const placeHolderValues: { [key: string]: string } = Object.freeze({
	HIGHLIGHTED: ':highlighted:',
	CHECKED: ':checked:',
	XMARK: ':xmark:',
});

/* Helper function to remove placeholders from contentful data */
const removePlaceHolderValues = (str: string) => {
	let text = str;
	for (const key of Object.keys(placeHolderValues)) {
		const code = placeHolderValues[key];
		text = text.replace(code, '');
	}
	return text;
};

const CheckedIcon = () => {
	return (
		<Box sx={checkedIconBoxStyles}>
			<TAGSvgIcon icon={'Check'} />
		</Box>
	);
};

const CheckedIconDefault = () => {
	return <TAGSvgIcon icon="CheckDefault" width="31" height="20" viewBox="0 0 31 20" />;
};

const XMarkIcon = () => {
	return (
		<Box sx={xMarkIconBoxStyles}>
			<TAGSvgIcon icon={'XmarkDefault'} />
		</Box>
	);
};

const XMarkIconDefault = () => {
	return (
		<Box>
			<TAGSvgIcon icon="XmarkDefault" width="20" height="20" viewBox="0 0 20 20" />
		</Box>
	);
};

const hasPlaceHolders = (elements: NodeData[]): { [key: string]: boolean } => {
	const placeholders: { [key: string]: boolean } = {};
	for (const key in placeHolderValues) {
		if (Object.prototype.hasOwnProperty.call(placeHolderValues, key)) {
			if (elements.some((e: NodeData) => e.value?.includes(placeHolderValues[key]))) {
				placeholders[placeHolderValues[key]] = true;
			}
		}
	}
	return placeholders;
};

const getCompareTableHeader = (elements: []) => {
	const titleElem: any | never = elements.find((e: NodeData) => e.value.includes('image*title'));
	const title: string = titleElem.value.replace(':image*title*', '');
	return title;
};

export {
	placeHolderValues,
	hasPlaceHolders,
	removePlaceHolderValues,
	CheckedIcon,
	CheckedIconDefault,
	XMarkIcon,
	XMarkIconDefault,
	getCompareTableHeader,
};
