import { Box, Container, Grid, Icon, Typography, useMediaQuery, useTheme } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {
	App,
	Page,
	Maybe,
	AdFooterSocialNetworksCollection,
	AdFooterStickyFooterButtonsCollection,
	Button,
} from '@/types/generated';
import RichText, { RichTextContent } from '@/components/RichText';
import BreadCrumbs from '@/components/BreadCrumbs';
import { ChatBot, useAppContext } from '@/index';

import TAGLink from '../TAGLink';
import ContentfulButton from '../ContentfulButton';
import { SocialNetworkIcons } from '../FooterAlt/FooterAltSocialLinks';

import {
	getContentWrapperStyles,
	getContactInfoWrapperStyles,
	copyrightStyles,
	getWrapperStyles,
	backToTopBoxWrapperStyles,
	breadCrumbsContainerStyles,
	footerSocialMediaLinksStyles,
	getAboutWrapperStyles,
	breadCrumbsAndTopBtnContainerStyles,
	backToTopLinkWrapperStyle,
	stickyMobileFooterWrapperStyles,
	stickyMobileFooterBottomStyles,
} from './Footer.styles';
import FooterNavigationContent from './FooterNavigationContent';
import FooterPolicy from './FooterPolicy';

export interface IFooter {
	/**
	 * Application footer data, containing the information about the brand and the navigation links
	 *
	 * @type {App['footer']}
	 * @memberof IFooter
	 */
	footerData: App['footer'];
	/**
	 * Whether the footer should display the scheduling content or not
	 *
	 * @type {boolean}
	 * @memberof IFooter
	 */
	withScheduling?: boolean;
	/**
	 * breadcrumbsData to display breadcrumbs
	 *
	 * @type {boolean}
	 * @memberof IFooter
	 */
	breadcrumbsData?: Page;
	/**
	 * Specifies whether the applicaiton is being rendered serverside or not.
	 *
	 * @type {boolean}
	 * @memberof IFooter
	 */
	usesRehydration?: boolean;
	/**
	 * Sets the Footer background color to light if true, otherwise dark.
	 *
	 * @type {boolean}
	 * @memberof IFooter
	 */
	isLightFooter?: boolean;
}

function SocialMediaLinks({
	socialNetworks,
	isLightFooter,
}: {
	socialNetworks?: Maybe<AdFooterSocialNetworksCollection>;
	isLightFooter?: boolean;
}) {
	if (!socialNetworks?.items?.length) return null;

	const socNetworkItems = socialNetworks?.items as Page[];

	return (
		<Box sx={footerSocialMediaLinksStyles} data-test-id="section_footer_social_media_links">
			<Typography variant="bodyMediumBold" color={isLightFooter ? 'text.primary' : 'text.light'}>
				Follow us
			</Typography>
			<Box display="flex" gap={4} justifyContent="center" alignItems="center">
				<SocialNetworkIcons socNetworkItems={socNetworkItems} />
			</Box>
		</Box>
	);
}

export function BackToTopArrow({ isFooterLite = false }: { isFooterLite?: boolean }) {
	return (
		<TAGLink href="#" title="Back to top" dataTestId="link_go_to_top_arrow" linkSx={backToTopLinkWrapperStyle}>
			<Box sx={backToTopBoxWrapperStyles(isFooterLite)}>
				<ExpandLessIcon fontSize="small" color="inherit" />
				<Typography
					variant="buttonLabel"
					fontSize="small"
					color={isFooterLite ? 'text.interactive' : 'text.light'}
				>
					Top
				</Typography>
			</Box>
		</TAGLink>
	);
}

function StickyMobileFooter({
	globalMobileStickyFooterData: { stickyFooterButton, stickyFooterButtonsCollection, logoUrl },
}: {
	globalMobileStickyFooterData: {
		stickyFooterButton?: Maybe<Button>;
		stickyFooterButtonsCollection?: Maybe<AdFooterStickyFooterButtonsCollection> | undefined;
		logoUrl: Maybe<string> | undefined;
	};
}) {
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const { config } = useAppContext();
	const chatBotUrl = config.features.chat.chatBotURL;

	return (
		<>
			{isSmallDevice && (
				<>
					<Box
						data-test-id="section_sticky_mobile_footer"
						sx={stickyMobileFooterWrapperStyles(
							theme,
							stickyFooterButtonsCollection && stickyFooterButtonsCollection.items.length > 1
						)}
					>
						{stickyFooterButtonsCollection?.items.length ? (
							stickyFooterButtonsCollection.items.map((button: Maybe<Button>, index: number) => {
								return button ? (
									button.label?.includes('Chat') ? (
										<ChatBot
											key={`chatbot_${index}`}
											url={chatBotUrl}
											featureList={[]}
											isIconButton
											logoUrl={logoUrl || ''}
											stickyFooterButton={button}
										/>
									) : (
										<ContentfulButton
											key={index}
											dataTestId={`sticky_footer_button_${index}`}
											{...button}
										/>
									)
								) : null;
							})
						) : // TODO: deprecate this singular stickyFooterButton once all content has been
						// migrated to the stickyFooterButtonsCollection
						stickyFooterButton ? (
							<ContentfulButton dataTestId="sticky_footer_button" {...stickyFooterButton} fullWidth />
						) : null}
					</Box>
					<Box sx={stickyMobileFooterBottomStyles()}></Box>
				</>
			)}
		</>
	);
}

export default function Footer({
	footerData,
	withScheduling = false,
	breadcrumbsData,
	usesRehydration = false,
	isLightFooter = false,
}: IFooter) {
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'), { noSsr: !usesRehydration });

	return (
		<>
			<Box component="footer" data-test-id="section_footer" sx={getWrapperStyles(isLightFooter)}>
				{!withScheduling && (
					<Grid container sx={breadCrumbsAndTopBtnContainerStyles}>
						{breadcrumbsData?.breadcrumbsCollection && (
							<Grid item sx={breadCrumbsContainerStyles}>
								<BreadCrumbs breadcrumbsData={breadcrumbsData} isFooterLite={isLightFooter} />
							</Grid>
						)}

						<BackToTopArrow isFooterLite={isLightFooter} />
					</Grid>
				)}

				<Container sx={getContentWrapperStyles(theme)} maxWidth={false}>
					<FooterNavigationContent
						isSmallDevice={isSmallDevice}
						withScheduling={withScheduling}
						navigationGroups={footerData?.navigationGroupsCollection}
						isLightFooter={isLightFooter}
					/>

					{withScheduling && !!footerData?.footerPolicyGroupCollection?.items?.length && (
						<FooterPolicy isMobile={isSmallDevice} policyGroup={footerData?.footerPolicyGroupCollection} />
					)}

					{!withScheduling && (
						<SocialMediaLinks
							socialNetworks={footerData?.socialNetworksCollection}
							isLightFooter={isLightFooter}
						/>
					)}

					{!withScheduling && footerData?.contactNumber && (
						<TAGLink
							href={`tel:${footerData.contactNumber || '#'}`}
							linkSx={getContactInfoWrapperStyles(isSmallDevice)}
							dataTestId="link_contact_number"
						>
							<Icon
								sx={{
									display: 'inline-flex',
									color: isLightFooter ? 'text.interactive' : 'text.light',
								}}
							>
								<PhoneIphoneIcon />
							</Icon>
							<Typography
								variant="header4"
								component={'span'}
								color={isLightFooter ? 'text.interactive' : 'text.light'}
								title="Contact Number"
							>
								{footerData.contactNumber}
							</Typography>
						</TAGLink>
					)}

					<Typography
						variant="bodyMediumBook"
						color={isLightFooter ? 'text.primary' : 'text.light'}
						sx={copyrightStyles}
						data-test-id="text_footer_copyright"
					>
						{footerData?.copyright || ''}
					</Typography>

					{footerData?.about?.json && (
						<Container
							data-test-id="section_footer_about"
							sx={{ ...getAboutWrapperStyles(isSmallDevice), flexDirection: 'column', gap: 0 }}
						>
							<RichText
								content={footerData.about.json as RichTextContent}
								docProps={{
									color: isLightFooter ? 'text.primary' : 'text.light',
									variant: 'captionMedium',
									textAlign: 'center',
								}}
							/>
						</Container>
					)}
				</Container>
			</Box>

			{footerData?.useMobileStickyFooter &&
				(footerData.stickyFooterButton ||
					(footerData?.stickyFooterButtonsCollection?.items &&
						footerData?.stickyFooterButtonsCollection?.items?.length > 0)) && (
					<StickyMobileFooter
						globalMobileStickyFooterData={{
							stickyFooterButton: footerData.stickyFooterButton,
							stickyFooterButtonsCollection: footerData?.stickyFooterButtonsCollection,
							logoUrl: footerData.logo?.url,
						}}
					/>
				)}
		</>
	);
}
