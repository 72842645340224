import { SxProps, Theme } from '@mui/material';

export const getBackgroundImageStyles = (style: SxProps, imagePosition: string, url: string): SxProps<Theme> => ({
	backgroundImage: `url(${url})`,
	backgroundPosition: imagePosition,
	display: 'block',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	height: '100%',
	width: '100%',
	overflow: 'hidden',
	...style,
});

export const getImageStyles = (style: SxProps, imagePosition: string): SxProps<Theme> => ({
	overflow: 'hidden',
	minWidth: '100%',
	minHeight: '100%',
	height: '100%',
	width: '100%',
	objectFit: 'cover',
	objectPosition: imagePosition,
	...style,
});
