import { Theme } from '@mui/material';

export const getWrapperStyles = (isLightFooter: boolean) => ({
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: isLightFooter ? 'backgrounds.light' : 'backgrounds.dark',
	width: '100%',
});

export const backToTopBoxWrapperStyles = (isFooterLite: boolean) => ({
	display: 'flex',
	color: isFooterLite ? 'text.interactive' : 'text.light',
	alignItems: 'center',
	justifyContent: 'center',
});

export const backToTopLinkWrapperStyle = {
	marginLeft: 'auto',
};

export const backToTopIconStyles = {
	backgroundColor: 'secondary.main',
	border: '2px solid',
	borderColor: 'text.light',
	borderRadius: '50%',
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	'&:hover': {
		backgroundColor: 'tertiary.dark',
	},
};

export const getContentWrapperStyles = (theme: Theme) => ({
	maxWidth: '1920px',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	[theme.breakpoints.up('lg')]: {
		padding: '2.5rem 5% 1rem 5%',
	},
	[theme.breakpoints.between('md', 'lg')]: {
		padding: '2.5rem 2% 1rem 2%',
	},
	[theme.breakpoints.down('md')]: {
		padding: '1.375rem 1.25rem 1rem 1.25rem',
	},
});

export const footerSocialMediaLinksStyles = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	gap: 2,
	marginTop: '2.75rem',
};

export const getContactInfoWrapperStyles = (isSmallDevice: boolean) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'neutrals.white',
	fontSize: isSmallDevice ? '0.875rem' : '1.375rem',
	textDecoration: 'none',
	marginY: '2rem',
});

export const getAboutWrapperStyles = (isSmallDevice: boolean) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'neutrals.white',
	fontSize: isSmallDevice ? '0.875rem' : '1.375rem',
	textDecoration: 'none',
	marginTop: '2rem',
});

export const copyrightStyles = { textAlign: 'center', justifyContent: 'center', display: 'flex' };

export const getSocialNetworkImageStyles = (isLightFooter?: boolean) => ({
	width: '2.188rem',
	height: '2.188rem',
	...(isLightFooter && {
		filter: 'brightness(0) saturate(100%) invert(15%) sepia(53%) saturate(4016%) hue-rotate(208deg) brightness(93%) contrast(112%)',
	}),
});

export const breadCrumbsAndTopBtnContainerStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	paddingX: { xs: '1.75rem', md: '2%', lg: '5%' },
	paddingTop: { xs: '1rem', md: '1.25rem' },
	paddingBottom: { xs: '.75rem', md: '.5rem' },
};

export const breadCrumbsAndTopBtnContainerFooterLiteStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	paddingBottom: { xs: '1rem', md: '2rem' },
};

export const breadCrumbsContainerStyles = {
	alignItems: 'center',
	maxWidth: '80%',
};

export const getPolicyGroupStyle = () => ({
	display: 'flex',
	flexFlow: 'row no-wrap',
	alignItems: 'center',
	gap: '3.5rem',
	marginBottom: { xs: '1rem', md: '2rem' },
});

export const policyLinkStyles = {
	justifyContent: 'flex-start',
	lineHeight: '2rem',
	fontWeight: 600,
	fontSize: '1rem',
};

export const stickyMobileFooterWrapperStyles = (theme: Theme, hasMultipleButtons: boolean | null | undefined) => ({
	backgroundColor: theme.palette.background.default,
	px: hasMultipleButtons ? '0.25rem' : '1.25rem',
	py: '0.75rem',
	position: 'fixed',
	zIndex: 10_000,
	bottom: 0,
	left: 0,
	width: '100%',
	boxShadow: '0px 0px 20px 0px #00000033',
	display: 'flex',
	flexDirection: 'row',
	gap: '0.25rem',
	justifyContent: 'center',
	alignItems: 'center',
	'& > :first-child': {
		flex: '1 0 0',
	},
});

export const stickyMobileFooterBottomStyles = () => ({
	height: '5.75rem',
});
