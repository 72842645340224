import { scrollIntoView } from '@/utils';

export function getUrlType(href: string) {
	// @see https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links
	const internal = /^\/(?!\/)/.test(href);
	const isExternalUrl =
		!internal && (href.startsWith('http') || href.startsWith('tel') || href.startsWith('mailto:'));
	const isAnchorUrl = href.indexOf('#') > -1;
	const isFile = /\.[0-9a-z]+$/i.test(href);

	return { isExternalUrl, isAnchorUrl, isFile };
}

export function handleAnchorLinkClick(href: string) {
	const anchorId = href.split('#')[1];
	scrollIntoView(anchorId);
}

export function getHrefPath(href: string, migrationPaths: string[]) {
	// check if the href is only an internal
	const { isExternalUrl, isAnchorUrl } = getUrlType(href);
	//TODO: remove this CCUtmParams href logic as it is temporary until CC scheduling is not legacy
	const CCUtmParams = globalThis?.sessionStorage?.getItem('CCUtmParams');
	const hrefWithUtm = CCUtmParams && isExternalUrl ? `${href}?${CCUtmParams}` : href;

	if (!migrationPaths || migrationPaths.length <= 0) {
		return hrefWithUtm;
	}

	const currentPath = globalThis.location?.pathname?.split('/')?.[1];

	// check if path is in digital marketing for AD migration
	const inDigitalMarketing = migrationPaths.includes(currentPath);

	if (!inDigitalMarketing) {
		return hrefWithUtm;
	}

	// check if the href has paths to the migrated paths
	const pathExistsInDigitalMarketing = href.includes(currentPath) && !href.includes('smile-assessment');

	if (isExternalUrl || isAnchorUrl || pathExistsInDigitalMarketing) {
		return hrefWithUtm;
	}

	return globalThis.location?.origin + hrefWithUtm;
}
