import { Box, Container, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TAGBackground } from '@aspendental/themes';

import { resolveContentfulBgColor, translateTagColorNameForContentful } from '@/utils';
import { pixelsToRemStr, useTemplateContainerFullWidthStyles } from '@/utils';
import {
	ContentTypeRichTextContent,
	Maybe,
	TextBlockSeoContent,
	ContentTypeRichTextSecondaryContent,
	TableFootnote,
	ContentTypeRichTextButtonsCollection,
} from '@/types/generated';
import RichText, { RichTextContent } from '@/components/RichText';
import { DisableTemplatePaddingOn, HeadingTag, ContentfulBackground } from '@/types';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';

import ContentfulButton from '../ContentfulButton';

import { contentWrapperStyles, getButtonsWrapperStyles } from './ContentTypeRichText.styles';

export function resolveRichTextColorForDarkBg(backgroundColor: TAGBackground) {
	const isDark = backgroundColor?.toLowerCase().includes('dark');

	if (isDark) {
		return { color: 'text.light' };
	}

	return {};
}

export interface IContentTypeRichText {
	/**
	 * Background color of the section. This is actually Maybe<TAGBackground>
	 * but the generated type is not correct.
	 *
	 * @type {ContentfulBackground | TAGBackground}
	 * @memberof IContentTypeRichText
	 * @default 'white'
	 */
	backgroundColor?: Maybe<string>; // this is actually Maybe<ContentfulBackground | TAGBackground>
	/**
	 * Whether the content should be centered or not.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IContentTypeRichText
	 */
	centerAlign?: Maybe<boolean>;
	/**
	 * Unused.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IContentTypeRichText
	 * @deprecated use `contentMaxWidth` instead
	 */
	contained?: Maybe<boolean>;
	/**
	 * Unused.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IContentTypeRichText
	 * @deprecated use `contentMaxWidth` instead
	 */
	fullWidth?: Maybe<boolean>;
	/**
	 * Rich Text content.
	 *
	 * @type {Maybe<ContentTypeRichTextContent>}
	 * @memberof IContentTypeRichText
	 */
	content?: Maybe<ContentTypeRichTextContent | TextBlockSeoContent | TableFootnote>;
	/**
	 * Secondary Rich Text content.
	 *
	 * @type {Maybe<ContentTypeRichTextSecondaryContent>}
	 * @memberof IContentTypeRichText
	 */
	secondaryContent?: Maybe<ContentTypeRichTextSecondaryContent>;
	/**
	 * Indicates the max width of the wrapper container.
	 *
	 * @type {Maybe<string>}
	 * @memberof IContentTypeRichText
	 * @default '75rem'
	 */
	contentMaxWidth?: Maybe<string>;
	/**
	 * Optional data-test-id attribute.
	 *
	 * @type {string}
	 * @memberof IContentTypeRichText
	 */
	dataTestId?: string;
	/**
	 * Optional styles to apply to the wrapper container.
	 *
	 * @type {SxProps}
	 * @memberof IContentTypeRichText
	 */
	containerSx?: SxProps;
	/**
	 * Optional props to pass to the RichText component. See RichText component for more details.
	 *
	 * @type {Parameters<typeof RichText>[0]['docProps']}
	 * @memberof IContentTypeRichText
	 */
	docProps?: Parameters<typeof RichText>[0]['docProps'];
	/**
	 * Disables the default side padding of the Container component.
	 *
	 * @type {boolean}
	 * @memberof IContentTypeRichText
	 */
	disableGutters?: boolean;
	/**
	 * Title text that can be set.
	 * @type {Maybe<string>}
	 * @memberof IContentTypeRichText
	 */
	title?: Maybe<string>;
	/**
	 * Title heading that will set the H-tag to appear in the HTML without effecting the style of the element. normal will render a <p> tag.
	 * @type {Maybe<string>}
	 * @memberof IContentTypeRichText
	 */
	titleHeading?: Maybe<string>;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof IAccordions
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * Display SecondaryContent in DOM for SEO purposes
	 *
	 * @type {boolean}
	 * @memberof IContentTypeRichText
	 */
	displaySecondaryContentInDom?: boolean;
	/**
	 * Use for anchor link
	 *
	 * @type {boolean}
	 * @memberof IContentTypeRichText
	 */
	id?: Maybe<string> | undefined;
	/**
	 * Collection of ContentTypeRichText buttons
	 *
	 * @type {Maybe<ContentTypeRichTextButtonsCollection>}
	 * @memberof IContentTypeRichText
	 */
	buttonsCollection?: Maybe<ContentTypeRichTextButtonsCollection>;
}

export default function ContentTypeRichText({
	content,
	secondaryContent,
	title,
	titleHeading = 'h1',
	backgroundColor,
	contentMaxWidth,
	containerSx = {},
	docProps = {},
	centerAlign = false,
	dataTestId = 'section_content_type_rich_text',
	disableGutters = false,
	disablePaddingOn = [],
	displaySecondaryContentInDom = false,
	id = '',
	buttonsCollection,
}: IContentTypeRichText) {
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
	const wrapperStyles = useTemplateContainerFullWidthStyles(disablePaddingOn, isMediumScreen, disableGutters);

	if (!content?.json) return null;
	const textAlign = centerAlign ? 'center' : 'left';

	const colorStyles = resolveRichTextColorForDarkBg((backgroundColor || 'white') as TAGBackground);
	const richText = content.json as RichTextContent;
	const secondaryRichText = secondaryContent?.json as RichTextContent;
	const linksData = content?.links || [];
	const secondaryLinksData = secondaryContent?.links;
	const isDark = backgroundColor?.toLowerCase().includes('dark');
	const headingTag = titleHeading as HeadingTag;

	const contentfulSafeBackgroundColor = translateTagColorNameForContentful(backgroundColor || '') || '';
	const contentfulBackgroundColor = resolveContentfulBgColor(
		contentfulSafeBackgroundColor as ContentfulBackground,
		theme
	);

	return (
		// TODO: move Container to TemplateContainer after finishing WB-596. The issue with not using TemplateContainer is that the transparent option would be converted into White.
		<Container
			disableGutters={disableGutters}
			maxWidth={false}
			sx={{
				...contentWrapperStyles(contentfulBackgroundColor, containerSx),
				...wrapperStyles,
			}}
		>
			<Container
				disableGutters={disableGutters}
				data-test-id={dataTestId}
				sx={{
					textAlign, // need to pass textAlign here as well because of lists
					maxWidth: { md: pixelsToRemStr(contentMaxWidth || '1200px') }, // use "md" breakpoint to override Container media query for max-width
					margin: '0 auto',
					...colorStyles,
					...containerSx,
				}}
				{...(id && { id })}
			>
				{title && (
					<Typography
						variant={getHeadingVariant(headingTag)}
						color={isDark ? 'text.light' : 'text.primary'}
						data-test-id="text_richtext_section_title"
						sx={{ textAlign: textAlign?.toLowerCase() || 'center' }}
						component={headingTag === 'normal' ? 'p' : headingTag}
					>
						{title}
					</Typography>
				)}
				{/*
					Spread docProps before colorStyles, to avoid overriding text.light color for dark backgrounds if docProps.color was defined.
					This will not override text color for non-dark backgrounds.
				*/}
				<RichText
					content={richText}
					links={linksData}
					secondaryContent={secondaryRichText}
					secondaryLinks={secondaryLinksData}
					docProps={{ textAlign, ...docProps, ...colorStyles }}
					isDark={isDark}
					displaySecondaryContentInDom={displaySecondaryContentInDom}
				/>
				{buttonsCollection?.items && buttonsCollection.items?.length > 0 ? (
					<Box
						data-test-id="group_content_type_rich_text_buttons_wrapper"
						sx={getButtonsWrapperStyles(centerAlign)}
					>
						{buttonsCollection?.items.map((button) =>
							button ? (
								<ContentfulButton
									key={button.sys.id}
									{...button}
									defaultVariant={isDark ? 'tertiaryHC' : 'tertiaryDefault'}
									dataTestId={`content_type_rich_text_button_${button.sys.id}`}
								/>
							) : null
						)}
					</Box>
				) : null}
			</Container>
		</Container>
	);
}
