import { Theme } from '@mui/material';

import { videoControlsBottomStyles } from '../VideoContent/VideoContent.helpers';

export const ytVideoSectionWrapperStyles = {
	position: 'relative',
	width: '100%',
	height: '100%',
};

export const ytVideoInnerSectionStyles = (isPlaying: boolean | null, isLoading: boolean) => {
	return {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		zIndex: 4,
		opacity: isPlaying && !isLoading ? 0 : 1,
		transition: 'all .5s .5s ease',
		pointerEvents: isPlaying ? 'none' : 'auto',
	};
};

export const ytImageStyles = (isBorderRadius: boolean) => ({
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0,
	zIndex: 9,
	objectFit: 'cover',
	objectPosition: 'center',
	...(isBorderRadius && { borderRadius: '0.5rem' }),
});

export const videoControlIconStyles = (theme: Theme) => {
	return {
		cursor: 'pointer',
		color: theme.palette.text.light,
		zIndex: 9999,
		pointerEvents: 'auto',
	};
};

export const ytVideoControlStyles = (fromHero: boolean, isExtraLargeScreen: boolean, isWideScreen: boolean) => ({
	position: 'absolute',
	display: 'flex',
	flexDirection: 'row',
	gap: '0.75',
	bottom: videoControlsBottomStyles(fromHero, isWideScreen, isExtraLargeScreen),
	left: '1.25rem',
	zIndex: 10,
	pointerEvents: 'auto',
});

export const youtubeMobileStyles = (
	isExtraSmallScreen: boolean,
	fromHero: boolean,
	isBorderRadius: boolean,
	sixteenByNineVideoRatio: boolean
) => ({
	...(isExtraSmallScreen &&
		!fromHero && {
			position: 'relative',
			width: '100%',
			paddingBottom: sixteenByNineVideoRatio ? '56.25%' : '100%', // 16:9 ratio for ServiceTemplate(which is supposed to be deprecated) or BirdeyeReviews, else 1:1 aspect ratio (height equals width)
			height: 0,
			overflow: 'hidden',
			...(isBorderRadius && { borderRadius: '0.5rem' }),
		}),
});

export const youtubeIframeStyles = (
	isExtraSmallScreen: boolean,
	isBorderRadius: boolean,
	sixteenByNineVideoRatio: boolean
) => ({
	position: 'absolute' as const, //typescript needs this definition for style attribute
	top: isExtraSmallScreen ? '50%' : 0,
	left: isExtraSmallScreen ? '50%' : 0,
	width: '100%',
	height: '100%',
	pointerEvents: 'none' as const, //typescript needs this definition for style attribute
	...(isExtraSmallScreen && {
		transform: sixteenByNineVideoRatio ? 'translate(-50%, -50%) scale(1.0)' : 'translate(-50%, -50%) scale(1.8)', // Adjust the scale as needed to remove black bars for 1:1 ratio
	}),
	...(isBorderRadius && { borderRadius: '0.5rem' }),
});
