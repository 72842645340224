export function getSearchUrl(findOfficeUrl: string, query: string) {
	// Log an error message to the console if an invalid URL is provided.
	try {
		const searchUrl = new URL(findOfficeUrl);
		const searchParams = searchUrl.searchParams;
		// update search params
		searchParams.set('query', query);
		searchParams.delete('verticalUrl');
		searchUrl.search = searchParams.toString();
		return searchUrl.toString();
	} catch (error) {
		console.error('Invalid URL provided:', error);
		return findOfficeUrl;
	}
}
