import { createContext } from 'react';

import { useSafeContext } from '@aspendental/shared-utils/react';

import { TAGBrand, BrandThemeConfig } from '@/types';
import { httpClient } from '@/utils';
import { default as baseConfig } from '@/theme/BaseBrandThemeConfig';

const AppContext = createContext({
	theme: 'AspenDental',
	environment: 'prod',
	config: baseConfig,
	appName: 'tag_unknown',
	appVersion: 'unknown',
	dentrinoClientId: 'unknown',
	dentrinoClientSecret: 'unknown',
});
AppContext.displayName = 'AppContext';

type EnvironmentType = 'local' | 'dev' | 'stage' | 'prod';

interface IAppContextProvider {
	theme: TAGBrand;
	environment: EnvironmentType;
	config: BrandThemeConfig;
	appName: string;
	appVersion: string;
	children: React.ReactNode;
	dentrinoClientId?: string;
	dentrinoClientSecret?: string;
}

export function AppContextProvider({
	theme,
	environment,
	config,
	appName,
	appVersion,
	children,
	dentrinoClientId,
	dentrinoClientSecret,
}: IAppContextProvider) {
	const appContext = {
		theme: theme,
		environment: environment,
		config: config,
		appName: appName,
		appVersion: appVersion,
		dentrinoClientId: dentrinoClientId || '',
		dentrinoClientSecret: dentrinoClientSecret || '',
	};
	const revspringApiUrl =
		environment === 'prod' ? config.services.prod.REVSPRING_API_URL : config.services.nonprod.REVSPRING_API_URL;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
	httpClient.getHTTPClient().defaults.baseURL = revspringApiUrl;
	return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
}

export function useAppContext() {
	return useSafeContext(AppContext);
}
