export const wrapper = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '0.25rem',
};

export const locationIconStyles = ({ isDarkHeader }: { isDarkHeader: boolean }) => ({
	display: 'flex',
	alignItems: 'center',
	color: isDarkHeader ? 'text.light' : 'text.interactive',
});

export const linkStyles = ({ isLightText }: { isLightText: boolean }) => ({
	color: isLightText ? 'text.light' : 'text.interactive',
	'&:hover': { opacity: 0.8 },
});

export const findOfficeMenuStyles = {
	marginTop: '3rem',
	paddingLeft: '1rem',
};
