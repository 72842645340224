import { getSessionId } from '@aspendental/shared-utils/utils';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import packageJSON from '../../package.json';

import { BUGSNAG_KEY, BUGSNAG_NOTIFY_URL, BUGSNAG_SESSIONS_URL } from '@src/constants';

const version = packageJSON.version;

export function initBugsnag(env: Record<string, string>, name: BrandThemeConfig['name']) {
	// @ts-ignore
	if (Bugsnag._client) return;

	const deploymentEnv = env?.ENVIRONMENT_NAME;

	const appEnv = process.env.NODE_ENV === 'development' ? 'local' : deploymentEnv;

	Bugsnag.start({
		endpoints: {
			notify: BUGSNAG_NOTIFY_URL,
			sessions: BUGSNAG_SESSIONS_URL,
		},
		apiKey: BUGSNAG_KEY,
		appVersion: version,
		plugins: [new BugsnagPluginReact()],
		releaseStage: appEnv,
		enabledReleaseStages: appEnv === 'local' ? [] : ['stage', 'prod'],
		onError: function (event) {
			event.addMetadata('user', {
				sessionId: getSessionId(),
				brand: name,
			});
		},
	});
}
