export const getLocationNavigationBarStyles = (isMobile?: boolean) => ({
	position: 'relative',
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	backgroundColor: 'neutrals.neutral',
	padding: isMobile ? '0.25rem 0.75rem' : '0.5rem 2.5rem',
	gap: '0.5rem',
	minHeight: isMobile ? '2.75rem' : '3.75rem',
});

export const searchWrapStyles = {
	display: 'flex',
	alignItems: 'center',
	width: 'fit-content',
	flexGrow: 1,
};
