import { DAYS_OF_WEEK } from '@/constants';

export const getDayOfWeek = () => {
	const today = new Date();
	const todayIndex = today.getDay(); // Sunday = 0, Monday = 1, etc.
	// Adjust the index to match the DAYS_OF_WEEK array (where Monday is 0)
	const adjustedTodayIndex = (todayIndex + 6) % 7;
	return DAYS_OF_WEEK[adjustedTodayIndex];
};

export const getDayOfTomorrow = () => {
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	const tomorrowIndex = tomorrow.getDay(); // Sunday = 0, Monday = 1, etc.
	// Adjust the index to match the DAYS_OF_WEEK array (where Monday is 0)
	const adjustedTomorrowIndex = (tomorrowIndex + 6) % 7;

	return DAYS_OF_WEEK[adjustedTomorrowIndex];
};

export const getDayOfWeekFromDate = (date: Date) => {
	const today = new Date(date);
	const todayIndex = today.getDay();
	return DAYS_OF_WEEK[todayIndex];
};
