import { useState } from 'react';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dynamic from 'next/dynamic';

import TAGLink from '@/components/TAGLink';

import ContentTile, { ITileImage } from '../ContentTile/ContentTile';
import {
	contentTilesContainerStyles,
	contentTileItemStyles,
	contentTileDividerClass,
} from '../ContentTile/ContentTile.styles';

import {
	desktopHeaderSubNavMenuContentBoxStyle,
	desktopHeaderSubNavMenuLinksBoxStyle,
	mobileHeaderSubNavMenuAccordionDetailsStyle,
	mobileHeaderSubNavMenuAccordionLinksGridStyle,
	mobileHeaderSubNavMenuAccordionStyle,
	mobileHeaderSubNavMenuAccordionSummaryIconStyle,
	mobileHeaderSubNavMenuAccordionSummaryStyle,
	mobileHeaderSubNavMenuAccordionSummaryTxtStyle,
	mobileHeaderSubNavMenuGridContainerStyle,
	mobileHeaderSubNavMenuGridItemStyle,
	subNavMenuLinkStyle,
} from './NavigationMenuItem.styles';
import { INavigationSubMenu } from './NavigationSubMenu';
import { MobileMenuLink } from './NavigationMenuItem';

const ContentTileCarousel = dynamic(() => import('../ContentTile/ContentTileCarousel'), { ssr: false });

interface stateObject {
	selectedAccordion: string;
}

export default function NavigationSubMenuContent({
	subNavMenuGroups,
	contentTilesCollection,
	dataTestId = '',
}: INavigationSubMenu) {
	const [state, setState] = useState<stateObject>({
		selectedAccordion: '',
	});

	const theme = useTheme();
	const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'));

	const handleChange = (accordionId: string) => (event: React.SyntheticEvent, expanded: boolean) => {
		event.preventDefault();
		setState({
			...state,
			selectedAccordion: expanded ? accordionId : '',
		});
	};

	return isLargeDevice ? (
		<Box sx={desktopHeaderSubNavMenuContentBoxStyle} data-test-id={`desktop-${dataTestId}`}>
			<Grid sx={desktopHeaderSubNavMenuLinksBoxStyle}>
				{subNavMenuGroups?.items.map((group, index) => (
					<Grid data-test-id={`${dataTestId}-${index}`} item key={index}>
						<Grid container direction="column" spacing={0.5}>
							<Grid data-test-id={`desktop-${index}`} item>
								{group?.linksCollection?.items?.length && group?.linksCollection?.items?.length >= 1 ? (
									<Typography
										variant="mainNavigationDefaultSubCategory"
										title={group?.groupLink?.linkText || ''}
									>
										{group?.groupLink?.linkText}
									</Typography>
								) : (
									<TAGLink
										title={group?.groupLink?.linkText || ''}
										href={
											(group?.groupLink?.isInternal
												? group?.groupLink?.pageData?.path
												: group?.groupLink?.externalUrl) || '#'
										}
										linkSx={subNavMenuLinkStyle}
										analyticsContext={group?.groupLink?.analyticsContext}
									>
										<Typography
											color="text.primary"
											variant="bodyLargeBook"
											title={group?.groupLink?.linkText || ''}
										>
											{group?.groupLink?.linkText}
										</Typography>
									</TAGLink>
								)}
							</Grid>
							{group?.linksCollection?.items.map((navigationItem, index) => (
								<Grid item data-test-id={`desktop-${dataTestId}-item-${index}`} key={index}>
									<TAGLink
										title={navigationItem?.linkText || ''}
										href={navigationItem?.pageData?.path || navigationItem?.externalUrl || '#'}
										openInNewTab={navigationItem?.openInNewTab || false}
										noLinkStyle
										analyticsContext={navigationItem?.analyticsContext}
									>
										<Typography
											color="text.primary"
											variant="bodyLargeBook"
											title={navigationItem?.linkText || ''}
										>
											{navigationItem?.linkText}
										</Typography>
									</TAGLink>
								</Grid>
							))}
						</Grid>
					</Grid>
				))}
			</Grid>
			{contentTilesCollection?.items && contentTilesCollection?.items?.length >= 1 && (
				<>
					<Grid item sx={contentTileDividerClass(theme)} />
					<Grid
						data-test-id="desktop_header_sub_nav_menu_content_tile_grid_container"
						sx={contentTilesContainerStyles}
					>
						{contentTilesCollection?.items.map((contentTile, index) => (
							<Grid
								data-test-id={`desktop_header_sub_nav_menu_content_tile_grid_item_${index}`}
								item
								sx={contentTileItemStyles}
								key={index}
							>
								<ContentTile image={contentTile?.image as ITileImage} {...contentTile} />
							</Grid>
						))}
					</Grid>
				</>
			)}
		</Box>
	) : (
		<Grid
			item
			container
			direction="column"
			spacing={1}
			data-test-id={`mob-${dataTestId}`}
			sx={mobileHeaderSubNavMenuGridContainerStyle}
		>
			{subNavMenuGroups?.items.map((group, index) => (
				<Grid
					data-test-id={`mob-${dataTestId}_${index}`}
					item
					key={index}
					sx={mobileHeaderSubNavMenuGridItemStyle}
				>
					{group?.linksCollection?.items?.length && group?.groupLink?.sys?.id ? (
						<Accordion
							key={group?.groupLink?.sys?.id}
							disableGutters
							square={true}
							elevation={0}
							onChange={
								!group.displayAsExpandedForMobileMenu
									? handleChange(group?.groupLink?.sys?.id)
									: undefined
							}
							expanded={
								group.displayAsExpandedForMobileMenu ||
								state.selectedAccordion === group?.groupLink?.sys?.id
							}
							sx={mobileHeaderSubNavMenuAccordionStyle}
						>
							<AccordionSummary
								sx={mobileHeaderSubNavMenuAccordionSummaryStyle}
								expandIcon={
									!group.displayAsExpandedForMobileMenu ? (
										<ExpandMoreIcon
											fontSize="small"
											sx={mobileHeaderSubNavMenuAccordionSummaryIconStyle}
											data-test-id={`expand-icon-mobile-menu-accordion-${index}`}
										/>
									) : null
								}
							>
								<Typography
									variant="buttonLabel"
									sx={mobileHeaderSubNavMenuAccordionSummaryTxtStyle}
									color={group.displayAsExpandedForMobileMenu ? 'text.secondary' : 'text.primary'}
								>
									{group.groupLink.linkText}
								</Typography>
							</AccordionSummary>
							<AccordionDetails sx={mobileHeaderSubNavMenuAccordionDetailsStyle}>
								<Grid container direction="column" spacing={2}>
									{group.linksCollection?.items?.map((navigationItem, index) => {
										return (
											<Grid
												item
												key={index}
												data-test-id={`mob-${dataTestId}-item-${index}`}
												sx={mobileHeaderSubNavMenuAccordionLinksGridStyle}
											>
												<TAGLink
													href={
														navigationItem?.pageData?.path ||
														navigationItem?.externalUrl ||
														'#'
													}
													openInNewTab={navigationItem?.openInNewTab || false}
													noLinkStyle
													analyticsContext={navigationItem?.analyticsContext}
												>
													<Typography variant="bodyMediumBook" color="text.primary">
														{navigationItem?.linkText}
													</Typography>
												</TAGLink>
											</Grid>
										);
									})}
								</Grid>
							</AccordionDetails>
						</Accordion>
					) : (
						<MobileMenuLink
							text={group?.groupLink?.linkText || ''}
							href={group?.groupLink?.pageData?.path || group?.groupLink?.externalUrl || '#'}
							openInNewTab={group?.groupLink?.openInNewTab || false}
							showArrow={false}
							analyticsContext={group?.groupLink?.analyticsContext}
						/>
					)}
				</Grid>
			))}
			{contentTilesCollection?.items && contentTilesCollection?.items?.length >= 1 && (
				<Grid
					data-test-id="mobile_header_sub_nav_menu_content_tile_grid_container"
					container
					direction="column"
					sx={contentTilesContainerStyles}
				>
					<ContentTileCarousel contentTiles={contentTilesCollection?.items} />
				</Grid>
			)}
		</Grid>
	);
}
