import { ChangeEvent, KeyboardEvent } from 'react';

import {
	FormControl,
	FormHelperText,
	InputBaseProps,
	InputLabel,
	OutlinedInput,
	OutlinedInputProps,
	SxProps,
	Typography,
} from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import TAGSvgIcon, { IconName } from '../TAGSvgIcon';

import { getInputStyles, getWrapperStyles, inputHelperTextStyles } from './TAGTextFieldInput.styles';

export interface ITextFieldInputProps extends InputBaseProps {
	endAdornment?: React.ReactNode | null;
	startAdornment?: React.ReactNode | null;
	fieldDisabled?: boolean;
	fieldError?: boolean;
	fieldHelperText?: string;
	fieldPlaceHolder?: string;
	fieldRequired?: boolean;
	iconName?: IconName;
	inputLabel?: string;
	inputSx?: SxProps;
	wrapperSx?: SxProps;
	value?: string;
	inputProps: OutlinedInputProps['inputProps'];
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function TAGTextFieldInput({
	color = 'primary',
	endAdornment = null,
	startAdornment = null,
	fieldDisabled = false,
	fieldError = false,
	fieldHelperText = '',
	fieldPlaceHolder = '',
	fieldRequired = true,
	iconName = undefined,
	inputLabel = '',
	inputSx = {},
	onChange,
	onKeyDown,
	wrapperSx = {},
	value = '',
	inputProps,
}: ITextFieldInputProps) {
	return (
		<FormControl fullWidth required={fieldRequired} sx={{ ...getWrapperStyles(color, fieldError), ...wrapperSx }}>
			{inputLabel && <InputLabel data-test-id="text_input_label">{inputLabel}</InputLabel>}

			<OutlinedInput
				value={value}
				placeholder={fieldPlaceHolder}
				sx={{ ...getInputStyles(), ...inputSx }}
				disabled={fieldDisabled}
				error={fieldError}
				label={inputLabel}
				onChange={(e: ChangeEvent<HTMLInputElement>) => onChange?.(e)}
				onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onKeyDown?.(e)}
				endAdornment={
					iconName ? (
						fieldError ? (
							<ErrorOutlineOutlinedIcon sx={{ color: 'error.main' }} />
						) : (
							<TAGSvgIcon icon={iconName} size={30} />
						)
					) : (
						endAdornment
					)
				}
				startAdornment={startAdornment}
				inputProps={inputProps}
			/>

			{fieldHelperText && (
				<FormHelperText error={fieldError} data-test-id="text_input_helper_label" sx={inputHelperTextStyles}>
					<Typography variant="lowestLevelBook">{fieldHelperText}</Typography>
				</FormHelperText>
			)}
		</FormControl>
	);
}
