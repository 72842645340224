import { Grid } from '@mui/material';

import { Asset, Maybe } from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { useAppContext } from '@/context';
import { clickContext } from '@/utils';
import ImageWrapper from '@/components/ImageWrapper';

interface IFooterAltLogoProps {
	logo?: Maybe<Asset>;
	isMobile: boolean;
}

const FooterAltLogo = ({ logo, isMobile }: IFooterAltLogoProps) => {
	const { config } = useAppContext();

	if (!logo) return null;

	return (
		<Grid data-test-id="footer_alt_logo">
			<TAGLink
				href={'/'}
				key={logo.sys.id}
				linkSx={{ lineHeight: 0, display: 'flex' }}
				dataTestId={`link_logo_${logo.sys.id}`}
				analyticsContext={clickContext({ brand: config.name, type: 'footerLogoClick' })}
			>
				<ImageWrapper
					src={logo?.url as string}
					width={isMobile ? '140px' : '200px'}
					height="unset"
					data-test-id="img_logo_footeralt"
					alt={logo?.description || ''}
				/>
			</TAGLink>
		</Grid>
	);
};

export default FooterAltLogo;
