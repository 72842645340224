import { FormLabel, Grid, IconButton, RadioGroup, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import TAGSvgIcon from '@/components/TAGSvgIcon';
import Drawer from '@/components/Drawer';

import LanguageDrawerButton from './LanguageDrawerButton';

export default function LanguageDrawer({
	openDrawer,
	setOpenDrawer,
	handleDrawerClose,
}: Readonly<{
	openDrawer: boolean;
	setOpenDrawer: (bool: boolean) => void;
	handleDrawerClose: () => void;
}>) {
	const router = useRouter();
	const { locale } = router;
	const enSelected = locale === 'en-US';
	const esSelected = locale === 'es-US';
	const languageString = esSelected ? 'Idioma' : 'Language';
	const englishLabelString = esSelected ? 'Inglés (English)' : 'English (Inglés)';
	const spanishLabelString = esSelected ? 'Español (Spanish)' : 'Spanish (Español)';

	const handleChange = (event: { target: { value: string } }) => {
		changeLanguage(event.target.value);
		setOpenDrawer(false);
	};

	const changeLanguage = (locale: string) => {
		// manual overwrite for paid media AD scheduling, impossible to use next router like the normal path
		if (window.location.href.includes('schedule-an-appt')) {
			const hardCodedEndpoint =
				window.location.origin + '/' + `schedule-an-appt/${locale}/` + window.location.search;
			window.location.href = hardCodedEndpoint;
		} else {
			void router.push(router.pathname, router.asPath, { locale });
		}
	};

	return (
		<Drawer height="100%" open={openDrawer} anchor="bottom" onClose={handleDrawerClose}>
			<Stack alignItems="center">
				<IconButton
					onClick={() => {
						setOpenDrawer(false);
					}}
				>
					<TAGSvgIcon icon="MinusFunc" size={48} color="#A7ADB0" />
				</IconButton>
			</Stack>

			<Stack alignItems="center" pt={4}>
				<TAGSvgIcon icon="Language" size={40} color="#004BB9" />
				<Grid pt={2}>
					<FormLabel id="select-language">
						<Typography color="primary" variant="header3">
							{languageString}
						</Typography>
					</FormLabel>
				</Grid>
			</Stack>
			<RadioGroup aria-labelledby="select-language" onChange={handleChange} value={locale}>
				<Grid container justifyContent="flex-start" spacing={1.6} pt={3} px={4} pr={4} pb={4}>
					<Grid item key={'en-US'} xs={12} md={6} pl={1}>
						<LanguageDrawerButton
							value="en-US"
							icon="FlagUS"
							label={englishLabelString}
							selected={enSelected}
							openDrawer={openDrawer}
							handleDrawerClose={() => setOpenDrawer(false)}
							setOpenDrawer={setOpenDrawer}
						/>
					</Grid>
					<Grid item key={'es-US'} xs={12} md={6} pl={1}>
						<LanguageDrawerButton
							value="es-US"
							icon="FlagMX"
							label={spanishLabelString}
							selected={esSelected}
							openDrawer={openDrawer}
							handleDrawerClose={() => setOpenDrawer(false)}
							setOpenDrawer={setOpenDrawer}
						/>
					</Grid>
				</Grid>
			</RadioGroup>
		</Drawer>
	);
}
