import {
	Typography,
	FormGroup,
	FormControlLabel,
	Box,
	Grid,
	useMediaQuery,
	useTheme,
	DialogActions,
	DialogContent,
	Theme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import TAGButton from '@/components/TAGButton';
import TAGSvgIcon from '@/components/TAGSvgIcon/TAGSvgIcon';

import {
	getFormGroupStyles,
	getFormControlLabelStyles,
	getFormLabelBoxWrapper,
	getSelectedOptionIconStyles,
	dialogActionsStyles,
} from '../Quiz.styles';
import { IStateObject, TFormData, TOption, TQuestion } from '../Quiz.helpers';

export interface IQuestions {
	state: IStateObject;
	setState: (newState: Partial<IStateObject>) => void;
	showInDialogOrDrawer: boolean;
}

interface IQuestionsWrapper {
	questionToDisplay: TQuestion;
	isSmallScreen: boolean;
	formData: TFormData;
	fieldHasError: boolean;
	activeQuestionIndex: number;
	handleAnswerChange: (option: TOption) => void;
	theme: Theme;
}

interface IActionButtonsWrapper {
	isSmallScreen: boolean;
	activeQuestionIndex: number;
	handleBack: () => void;
	handleNext: () => void;
}

const QuestionsWrapper = ({
	questionToDisplay,
	isSmallScreen,
	formData,
	fieldHasError,
	activeQuestionIndex,
	handleAnswerChange,
	theme,
}: IQuestionsWrapper) => {
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" gap={2}>
			<Grid item>
				<Typography variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}>
					Question {activeQuestionIndex + 1} of {formData.form.form_questions.length}
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="header2" color="text.primary" textAlign="center">
					{questionToDisplay.question}
				</Typography>
			</Grid>

			{questionToDisplay.description ? (
				<Grid item textAlign="center">
					<Typography variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}>
						{questionToDisplay.description}
					</Typography>
				</Grid>
			) : null}

			{questionToDisplay.eyebrow ? (
				<Grid item>
					<Typography variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}>
						{questionToDisplay.eyebrow}
					</Typography>
				</Grid>
			) : null}

			{fieldHasError ? (
				<Grid item>
					<Typography variant="notification" color="error.dark" data-test-id="question_field_error_message">
						{questionToDisplay.validationMessage}
					</Typography>
				</Grid>
			) : null}

			<Grid item width="100%">
				<FormGroup sx={getFormGroupStyles}>
					{questionToDisplay.options.map((option: TOption) => {
						const isOptionSelected =
							questionToDisplay.type === 'SingleAnswer'
								? questionToDisplay.answer === option
								: (questionToDisplay.answer as TOption[])?.some((answer) => answer === option);

						const selectedOptionFontVariant = isSmallScreen ? 'bodyMediumSemiBold' : 'bodyLargeSemiBold';
						const defaultOptionFontVariant = isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook';
						return (
							<FormControlLabel
								key={option.id}
								sx={getFormControlLabelStyles}
								labelPlacement="end"
								label=""
								control={
									<Box
										sx={getFormLabelBoxWrapper(theme, isOptionSelected)}
										onClick={() => handleAnswerChange(option)}
										component="div"
										tabIndex={0}
										data-test-id={`option_${option.id}`}
									>
										<Typography
											color="text.interactive"
											variant={
												isOptionSelected ? selectedOptionFontVariant : defaultOptionFontVariant
											}
											textAlign="center"
										>
											{option.value}
										</Typography>
										{isOptionSelected ? (
											<CheckCircleIcon color="secondary" sx={getSelectedOptionIconStyles} />
										) : null}
									</Box>
								}
							/>
						);
					})}
				</FormGroup>
			</Grid>
		</Grid>
	);
};

const ActionButtonsWrapper = ({
	isSmallScreen,
	activeQuestionIndex,
	handleBack,
	handleNext,
}: IActionButtonsWrapper) => {
	return (
		<Grid container spacing={2} justifyContent="center" alignItems="center">
			{activeQuestionIndex ? (
				<Grid item>
					<TAGButton
						size={isSmallScreen ? 'S' : 'M'}
						variant="tertiaryDefault"
						onClick={handleBack}
						dataTestId="questions_back_button"
						startIcon={<TAGSvgIcon icon={'ChevronLeftFunc'} size={25} />}
					>
						Back
					</TAGButton>
				</Grid>
			) : null}
			<Grid item>
				<TAGButton
					size={isSmallScreen ? 'S' : 'M'}
					variant="secondaryDefault"
					endIcon={<TAGSvgIcon icon={'ChevronRightFunc'} size={25} />}
					onClick={handleNext}
					dataTestId="questions_next_button"
				>
					Next
				</TAGButton>
			</Grid>
		</Grid>
	);
};

export default function Questions({ state, setState, showInDialogOrDrawer = false }: Readonly<IQuestions>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const { activeQuestionIndex, formData, fieldHasError } = state;

	if (!formData) {
		return null;
	}

	const formQuestions = formData.form.form_questions;
	const noOfQuestions = formQuestions.length;

	const questionToDisplay = formQuestions[activeQuestionIndex];

	const handleAnswerChange = (option: TOption) => {
		setState({
			fieldHasError: false,
			formData: {
				form: {
					...formData.form,
					form_questions: formData.form.form_questions.map((formQuestion: TQuestion) => {
						if (formQuestion.question === questionToDisplay.question) {
							const selectedAnswer = formQuestion.answer;
							//  multiple option selection
							if (questionToDisplay.type === 'MultiAnswer') {
								if (
									Array.isArray(selectedAnswer) &&
									selectedAnswer.some((answer) => answer === option)
								) {
									return {
										...formQuestion,
										answer: selectedAnswer.filter((answer: TOption) => answer.id !== option.id),
									};
								} else {
									return {
										...formQuestion,
										answer: Array.isArray(selectedAnswer)
											? option.value === 'None of the above'
												? [option]
												: [...selectedAnswer, option].filter(
														(answer: TOption) => answer.value !== 'None of the above'
												  )
											: [option],
									};
								}
							} else {
								// single option selection
								return {
									...formQuestion,
									answer: (selectedAnswer as TOption)?.id === option.id ? null : option,
								};
							}
						}
						return formQuestion;
					}),
				},
			},
		});
	};

	const handleNext = () => {
		const fieldHasError =
			(questionToDisplay.type === 'SingleAnswer' && !questionToDisplay.answer) ||
			(questionToDisplay.type === 'MultiAnswer' && !(questionToDisplay.answer as TOption[])?.length);
		if (fieldHasError) {
			setState({
				fieldHasError: true,
			});
			return;
		}

		if (activeQuestionIndex + 1 === noOfQuestions) {
			setState({
				formType: 'UserDetails',
				fieldHasError: false,
			});
			return;
		}

		setState({
			activeQuestionIndex: activeQuestionIndex + 1,
			fieldHasError: false,
		});
	};

	const handleBack = () => {
		setState({
			fieldHasError: false,
			activeQuestionIndex: activeQuestionIndex - 1,
		});
	};

	// render as dialog or drawer
	if (showInDialogOrDrawer) {
		return (
			<>
				<DialogContent>
					<QuestionsWrapper
						questionToDisplay={questionToDisplay}
						isSmallScreen={isSmallScreen}
						formData={formData}
						fieldHasError={fieldHasError}
						activeQuestionIndex={activeQuestionIndex}
						handleAnswerChange={handleAnswerChange}
						theme={theme}
					/>
				</DialogContent>
				<DialogActions sx={dialogActionsStyles}>
					<ActionButtonsWrapper
						isSmallScreen={isSmallScreen}
						activeQuestionIndex={activeQuestionIndex}
						handleBack={handleBack}
						handleNext={handleNext}
					/>
				</DialogActions>
			</>
		);
	}

	// render as a section in a page
	return (
		<>
			<QuestionsWrapper
				questionToDisplay={questionToDisplay}
				isSmallScreen={isSmallScreen}
				formData={formData}
				fieldHasError={fieldHasError}
				activeQuestionIndex={activeQuestionIndex}
				handleAnswerChange={handleAnswerChange}
				theme={theme}
			/>
			<ActionButtonsWrapper
				isSmallScreen={isSmallScreen}
				activeQuestionIndex={activeQuestionIndex}
				handleBack={handleBack}
				handleNext={handleNext}
			/>
		</>
	);
}
