import { Box, Typography } from '@mui/material';

import TAGLink from '@/components/TAGLink';
import { AdFooterFooterPolicyGroupCollection, Maybe, NavigationLink, Page, Scalars } from '@/types/generated';
import { resolveLinkTitle, resolveLinkUrl } from '@/components/FooterLite/FooterLite.helpers';

import { getNavLinkStyles, getPolicyGroupStyle } from './FooterAlt.styles';

interface IFooterAltPolicyProps {
	isMobile: boolean;
	policyGroup?: Maybe<AdFooterFooterPolicyGroupCollection>;
	copyright?: Maybe<Scalars['String']>;
}
const FooterAltPolicy = ({ isMobile, policyGroup }: IFooterAltPolicyProps) => {
	const PolicyLinks = () => {
		if (!policyGroup) return null;
		const groupItems = policyGroup?.items;

		if (!groupItems) return null;
		return (
			<Box data-test-id="section_footeralt_policylinks" sx={getPolicyGroupStyle(isMobile)}>
				{groupItems &&
					groupItems.map(
						(link: Maybe<NavigationLink | Page> | undefined) =>
							link && (
								<TAGLink
									key={link.sys.id}
									href={resolveLinkUrl(link)}
									openInNewTab={(link as NavigationLink)?.openInNewTab || false}
									dataTestId={`link_for_group_${link.sys.id}`}
								>
									<Typography
										color="text.light"
										sx={getNavLinkStyles()}
										title={resolveLinkTitle(link)}
									>
										{resolveLinkTitle(link)}
									</Typography>
								</TAGLink>
							)
					)}
			</Box>
		);
	};
	return (
		<Box
			data-test-id="section_footeralt_policy"
			flexDirection={isMobile ? 'row' : 'column'}
			display={'flex'}
			justifyContent={'space-between'}
			gap={2}
		>
			<PolicyLinks />
		</Box>
	);
};

export default FooterAltPolicy;
