import { useCallback, useEffect, useState, useMemo } from 'react';
import React from 'react';

import { Box, ClickAwayListener, Container, Icon, IconButton, Popper, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import TAGLink from '@/components/TAGLink';
import { Header, NavigationLink, Page } from '@/types/generated';
import { generateODPLinks } from '@/utils/generateODPLinks';
import { useFacilityContext } from '@/context/FacilityContextProvider.ctx';
import { FacilityFieldsFragment } from '@/graphql/__generated/sdk';
import { generateLocationLinks, clickContext } from '@/utils';
import TAGButton from '@/components/TAGButton';
import { useAppContext } from '@/context';
import Drawer from '@/components/Drawer';
import GoogleMap from '@/components/GoogleMap';
import { metaData, sys } from '@/constants';

import NoOfficeTitle from '../NoOfficeTitle';

import {
	officeNameStyles,
	getPopperArrowStyles,
	locationContainerStyles,
	locationMenuWrapperStyles,
	locationInfoContainerStyles,
	closeLocationInfoIconStyles,
	getODPLinksWrapperStyles,
	getODPLinkStyles,
	findOfficeButtonStyles,
	getLocationLinksWrapperStyles,
} from './HeaderOfficeDetails.styles';
import OfficeInformationBlock from './OfficeInformationBlock';

const popperModifiers = [
	{ name: 'arrow', enabled: true },
	{ name: 'offset', options: { offset: [0, 35] } },
];

interface IOfficeDetails {
	isLargeDevice: boolean;
	locationBarFindOfficeUrl: Header['locationBarFindOfficeUrl'];
	locationBarWithOutOfficeTitle: Header['locationBarFindOfficeUrl'];
	isDarkHeader: boolean;
	locationNavigationLinks?: Array<NavigationLink | Page> | undefined;
	locationBarWithOfficeTitle: Header['locationBarWithOfficeTitle'];
	useHeaderLocationCards: boolean | null;
}

export default function HeaderOfficeDetails({
	isLargeDevice,
	locationBarFindOfficeUrl,
	locationBarWithOutOfficeTitle,
	isDarkHeader,
	locationNavigationLinks,
	locationBarWithOfficeTitle = 'Find Another Office',
	useHeaderLocationCards = false,
}: IOfficeDetails) {
	const theme = useTheme();
	const { officeInfo, locationState } = useFacilityContext();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const isPopperVisible = Boolean(anchorEl);

	const { config } = useAppContext();

	const updateAnchorEl = useCallback(function updateAnchorStateOnScroll() {
		if (globalThis.scrollY) {
			setAnchorEl(null);
		}
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', updateAnchorEl);

		return () => {
			window.removeEventListener('scroll', updateAnchorEl);
		};
	}, [updateAnchorEl]);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
			event.preventDefault();
			setAnchorEl(anchorEl ? null : event.currentTarget);
			window.removeEventListener('scroll', updateAnchorEl);
		},
		[updateAnchorEl, anchorEl]
	);

	return (
		<>
			<ClickAwayListener onClickAway={() => !useHeaderLocationCards && setAnchorEl(null)}>
				<Box data-test-id="section_header_office_details">
					{officeInfo?.address?.city && officeInfo?.address?.stateCode ? (
						<TAGLink
							onClick={handleClick}
							analyticsContext={clickContext({ brand: config.name, type: 'locationClick' })}
						>
							<Typography
								variant="bodyMediumBold"
								color={isDarkHeader ? 'text.light' : 'text.primary'}
								sx={officeNameStyles}
								data-test-id="text_office_name"
							>
								{officeInfo.address?.city}, {officeInfo.address?.stateCode}
								<Icon>
									{anchorEl ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
								</Icon>
							</Typography>
						</TAGLink>
					) : (
						<NoOfficeTitle
							locationBarFindOfficeUrl={locationBarFindOfficeUrl || ''}
							locationBarWithOutOfficeTitle={locationBarWithOutOfficeTitle || ''}
							isLightText={isDarkHeader}
						/>
					)}
				</Box>
			</ClickAwayListener>

			{useHeaderLocationCards && anchorEl && (
				<Drawer
					sheetVariant="elevation"
					closeButton
					onClose={() => setAnchorEl(null)}
					anchor="left"
					open
					width="37.5rem"
				>
					<GoogleMap
						contentfulMetadata={metaData}
						sys={sys}
						_id={''}
						isLocationCardsInHeader={true}
						locationBarFindOfficeUrl={locationBarFindOfficeUrl}
					/>
				</Drawer>
			)}
			{!useHeaderLocationCards && anchorEl && (
				<Popper
					anchorEl={anchorEl}
					sx={{ zIndex: 10000 }}
					modifiers={popperModifiers}
					open={isLargeDevice && isPopperVisible}
				>
					<Box component="span" sx={getPopperArrowStyles(theme)} data-popper-arrow />
					<Box
						component="section"
						sx={locationMenuWrapperStyles}
						data-test-id="section_popper_office_details"
					>
						<Container sx={locationContainerStyles} disableGutters>
							<IconButton
								color="primary"
								sx={closeLocationInfoIconStyles}
								onClick={() => setAnchorEl(null)}
								data-test-id="button_close_office_menu"
							>
								<CloseIcon />
							</IconButton>
							{officeInfo && (
								<Container disableGutters sx={locationInfoContainerStyles}>
									<OfficeInformationBlock office={officeInfo} distance={locationState?.distance} />
									<TAGButton
										size="M"
										sx={findOfficeButtonStyles}
										variant="secondaryDefault"
										href={locationBarFindOfficeUrl || ''}
										dataTestId="button_find_another_office"
									>
										<Typography variant="bodyMediumBold" display="flex" color="text.interactive">
											{locationBarWithOfficeTitle}
										</Typography>
									</TAGButton>
								</Container>
							)}
							{officeInfo && locationNavigationLinks ? (
								<LocationLinksBlock
									locationNavigationLinks={locationNavigationLinks}
									officeInfo={officeInfo}
								/>
							) : officeInfo ? (
								<ODPLinksBlock officeInfo={officeInfo} />
							) : null}
						</Container>
					</Box>
				</Popper>
			)}
		</>
	);
}

export function ODPLinksBlock({
	officeInfo,
	isSmallScreen = false,
}: {
	officeInfo: FacilityFieldsFragment;
	isSmallScreen?: boolean;
}) {
	const { config } = useAppContext();

	const hideODPLinks = config.featureFlags?.hideOdpHeaderLinks;
	const ODPLinks = useMemo(() => generateODPLinks(officeInfo, config), [officeInfo, config]);

	if (hideODPLinks) {
		return null;
	}

	return (
		<Box sx={getODPLinksWrapperStyles(isSmallScreen)} data-test-id="group_odp_links_block">
			{ODPLinks.map((item) => (
				<React.Fragment key={`odp-frag-${item.id}`}>
					<TAGLink href={item.path} linkSx={getODPLinkStyles(isSmallScreen)}>
						<Typography
							variant={isSmallScreen ? 'bodyMediumBold' : 'bodyLargeSemiBold'}
							color={isSmallScreen ? 'text.primary' : 'text.interactive'}
							data-test-id={`link_odp_${item.id}`}
						>
							{item.title}
						</Typography>
					</TAGLink>
				</React.Fragment>
			))}
		</Box>
	);
}

export function LocationLinksBlock({
	locationNavigationLinks,
	officeInfo,
	isSmallScreen = false,
}: {
	locationNavigationLinks: Array<NavigationLink | Page>;
	officeInfo: FacilityFieldsFragment;
	isSmallScreen?: boolean;
}) {
	const LocationLinks = useMemo(
		() => generateLocationLinks(officeInfo, locationNavigationLinks),
		[officeInfo, locationNavigationLinks]
	);

	return (
		<Box sx={getLocationLinksWrapperStyles(isSmallScreen)} data-test-id="group_location_links_block">
			{LocationLinks.map((item) => (
				<React.Fragment key={`location-frag-${item.id}`}>
					<TAGLink href={item.path} linkSx={getODPLinkStyles(isSmallScreen)}>
						<Typography
							variant={isSmallScreen ? 'bodyMediumBold' : 'bodyLargeSemiBold'}
							color={isSmallScreen ? 'text.primary' : 'text.interactive'}
							data-test-id={`link_location_${item.id}`}
						>
							{item.title}
						</Typography>
					</TAGLink>
				</React.Fragment>
			))}
		</Box>
	);
}
