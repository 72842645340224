export const formControlLabelStyles = (selected: boolean) => ({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '0.5rem',
	cursor: 'pointer',
	minWidth: '10rem',
	height: '6rem',
	margin: 0,
	border: '0.0625rem',
	borderStyle: 'solid',
	borderRadius: '0.5rem',
	borderColor: selected ? 'secondary.main' : 'neutrals.primary',
	'&:hover': {
		borderColor: 'secondary.main',
	},
	...(selected && {
		outline: '0.0625rem',
		outlineStyle: 'solid',
		outlineColor: 'secondary.main',
	}),
});

export const checkIconStyles = {
	height: '1.25rem',
	width: '1.25rem',
	position: 'absolute',
	color: 'secondary.main',
	top: '0.3rem',
	right: '0.3rem',
};

export const radioStyles = {
	'&:hover': {
		backgroundColor: 'backgrounds.default',
	},
};
